import React, { useEffect, useState } from 'react'
import { IonContent, IonPage, IonHeader} from '@ionic/react'
import { connectExchangeGames } from './ExchangeGames.connect'
import { useHistory, useParams } from 'react-router-dom'
import './ExchangeGames.scss'
import ExchangeGamesDesktopList from '../../components/ExchangeGamesDesktopList/ExchangeGamesDesktopList'
// import ExchangeGamesDesktopList from '../../components/ExchangeGamesDesktopList/ExchangeGamesDesktopList';
import topImg from '../../assets/images/exchangeGamesBgTop.svg'
import girl from './collage-casino-images-with-roulette-woman-with-chips-hands-212girl.png'
import wheelOut from './wheelOut.png'
import wheelIn from './wheelIn.png'
import LinesTop from '../../assets/images/exchangeGames-lines.svg'
import LinesBottom from '../../assets/images/exchangeGames-lines2.svg'

import desktopBgImg from '../../assets/images/exchange_games_bg_desktop.jpg'
import mobileBgImg from '../../assets/images/exchange_games_bg_mobile.jpg'

import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';

const ExchangeGames = ({ inplayPopular, actions, auth, elementsParams, exchangeGamesAll }) => {
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  useEffect(() => {
      actions.requestExchangeGamesAll()
  }, [auth])

  const balls = () => {
    return (
      <div className="commonForC">
        {Array(50)
          .fill(1)
          .map((el, i) => (
            <div key={i} className="c"></div>
          ))}
      </div>
    )
  }

  // check window zoom
  // //v1
  // const [browserZoomLevel, setBrowserZoomLevel] = useState(Math.round(window.devicePixelRatio * 100))
  // console.log('browserZoomLevel in useEffect', browserZoomLevel)
  //
  // useEffect(() => {
  //   const updateBrowserZoomLevel = () => {
  //     if(browserZoomLevel !== window.devicePixelRatio * 100) {
  //       setBrowserZoomLevel(Math.round(window.devicePixelRatio * 100))
  //     }
  //   }
  //   window.addEventListener('resize', updateBrowserZoomLevel)
  //   return () => window.removeEventListener('resize', updateBrowserZoomLevel)
  // },[])
  //
  // //v2
  // useEffect(() => {
  //   if (browserZoomLevel !== window.devicePixelRatio * 100) {
  //     setBrowserZoomLevel(Math.round(window.devicePixelRatio * 100))
  //   }
  // },[window.devicePixelRatio])
  // end check window zoom

  return (
    <>
      {width < 1024 ? (
        <IonPage>
          <IonContent>
            <div className="exchangeGames">
              <div className="exchangeGames__bg">
                <div className="exchangeGames__bg-top">
                  {/* <img src={topImg}/> */}

                  <div className="exchangeGames__bg-top_credo">
                    <div className="exchangeGames__bg-top_credo-img">
                      <div
                        className="exchangeGames__bg-top_credo-text"
                        onClick={() => actions.requestExchangeGamesAll()}
                      >
                        {' '}
                        Play Win Enjoy
                      </div>
                    </div>
                  </div>
                  <div className="exchangeGames__bg-top_description">
                    We have something for everyone. With over 30 games to choose
                    from, play the best slots, jackpots and live casino games.
                  </div>
                </div>
                <div className="arrowDownEx"></div>
                <div className="exchangeGames__bg-bottom">
                  <div className="exchangeGames__bg-top_lines">
                    <img
                      className="exchangeGames__bg-bottom_lines-lines"
                      src={LinesTop}
                      alt=""
                    />
                  </div>
                  <div className="exchangeGames__bg-bottom_lines">
                    <img
                      className="exchangeGames__bg-bottom_lines-lines"
                      src={LinesBottom}
                      alt=""
                    />
                  </div>
                  {exchangeGamesAll && (
                    <div className="exchangeGames__allgames">
                      <div className="exchangeGames__allgames-livegames">
                        Live games
                        <ExchangeGamesDesktopList
                          type={'inpl'}
                          itemsData={exchangeGamesAll?.liveGames}
                          auth={auth}
                        />
                      </div>
                      <div className="exchangeGames__allgames-livegames">
                        Virtual games
                        <ExchangeGamesDesktopList
                          type={'inpl'}
                          itemsData={exchangeGamesAll?.virtual}
                          auth={auth}
                        />
                      </div>
                    </div>
                  )}
                  {/*<FooterDesktop />*/}
                </div>
              </div>
            </div>
          </IonContent>
        </IonPage>
      ) : (
        <IonPage>
          <IonContent>
            <div className={`exchangeGames ${(auth && exchangeGamesAll) ? " " : "unauthorized"}`}>
              <div className="exchangeGames__bg">
                <div className="exchangeGames__bg-top">
                  <div class="light x1"></div>
                  <div class="light x2"></div>
                  <div class="light x3"></div>
                  <div class="light x4"></div>
                  <div class="light x5"></div>
                  <div class="light x6"></div>
                  <div class="light x7"></div>
                  <div class="light x8"></div>
                  <div class="light x9"></div>
                  {balls()}
                  <img src={girl} alt="girl" className="girl" />
                  <div>
                    <img className="insideRoulette" src={wheelIn} alt="f"></img>
                    <img
                      className="outsideRoulette"
                      src={wheelOut}
                      alt="f"
                    ></img>
                    {/* <div
                      style={{
                        width: '100%',
                        height: '50%',
                        background: '#142326',
                        position: 'absolute',
                        top: '100%',
                        // right: '0%',
                        // transform: 'translate(-50%, 50%)',
                      }}
                    ></div> */}
                  </div>
                  <div className="exchangeGames__bg-top_credo">
                    <div
                      className="exchangeGames__bg-top_credo-text"
                      onClick={() => actions.requestExchangeGamesAll()}
                    >
                      {' '}
                      Play. Win. Enjoy.
                    </div>
                    <div className="exchangeGames__bg-top_credo-img"></div>
                    <div className={`exchangeGames__bg-top_description`}>
                      We have something for everyone. With over 30 games to
                      choose from, play the best slots, jackpots and live casino
                      games.
                    </div>
                  </div>
                </div>
                <div className="arrowDownEx"></div>
                <div className="exchangeGames__bg-bottom">
                  {exchangeGamesAll && (
                    <div className="exchangeGames__allgames">
                      <div className="exchangeGames__bg-bottom-desktop-background">
                        <img className={`exchangeGames__bg-bottom-desktop-background-img`} src={desktopBgImg} alt="" />
                      </div>
                      <div className="exchangeGames__allgames-livegames">
                        <h2 className="exchangeGames__allgames-title">Live games</h2>
                        <ExchangeGamesDesktopList
                          type={'inpl'}
                          itemsData={exchangeGamesAll?.liveGames}
                        />
                      </div>
                      <div className="exchangeGames__allgames-livegames">
                        <h2 className="exchangeGames__allgames-title">Virtual games</h2>
                        <ExchangeGamesDesktopList
                          type={'inpl'}
                          itemsData={exchangeGamesAll?.virtual}
                        />
                      </div>
                      </div>
                  )}
                    <FooterDesktop />
                  </div>
              </div>
            </div>
          </IonContent>
        </IonPage>
      )}
    </>
  )
}

export default connectExchangeGames()(ExchangeGames)
