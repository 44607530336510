import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { 
        requestSaveOneClickSettings,
        requestSaveMobileStakesExchangeGameSettings,
        requestSaveMobileStakesSettings
      } from '../../store/user/user.thunks';

export const connectSettings = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      user: state.user,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSaveOneClickSettings,
      requestSaveMobileStakesExchangeGameSettings,
      requestSaveMobileStakesSettings
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
