import React, { useEffect, useState, createRef } from 'react'
import {
  IonContent,
  IonPage,
  IonHeader,
} from '@ionic/react'
import { useSelector } from 'react-redux'
import './DepositTimer.scss'
import rupyIcon from '../../assets/images/rupyIcon.svg'
import Toolbar from '../../components/Toolbar/Toolbar'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import lottie from 'lottie-web'
import successAnimation from './successAnimation.json'
import successAnimationSuccess from './successAnimationSuccess.json'

import phonePeLogo from "../../assets/images/depositPageIcons/phonepe.png";
import googlePayLogo from "../../assets/images/depositPageIcons/google-pay.png";
import payTmLogo from "../../assets/images/depositPageIcons/paytm.png";
import bhimLogo from "../../assets/images/depositPageIcons/bhim.png";
// import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { connectDepositTimerQr } from './DepositTimerQr.connect'
import { useDispatch } from 'react-redux'
import QRCode from 'qrcode'

import { useHistory } from 'react-router'
import axios from 'axios'
import RenderTime from './renderTime'
import {
  useIonViewWillEnter,
  useIonViewWillLeave,
  useIonViewDidLeave,
} from '@ionic/react'
import { setReq } from '../../store/deposit/deposit.slice'
import { BASE_DEV_URL } from '../../config/api.config'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'
import poveredByUPI from './poveredUPI.svg'

const DepositTimerQr = ({
  user,
  actions,
  initialOpen,
  elementsParams,
  ...props
}) => {
  const history = useHistory()
  const depositReq = useSelector((state) => state.deposit)
  const token = localStorage.getItem('id_token')
  const [countDownTime, setCountDownTime] = useState(300)
  const [reqResponse, setReqResponse] = useState()
  const [qrCodeImage, setQrCodeImage] = useState(null)
  const dispatch = useDispatch()



  const updatePgDepositRequest = async (id) => {
    console.log('request resent', id)
    if (id) {
      return await axios
        .get(BASE_DEV_URL + `/pg/get_pg_txn_details?transactionId=${id}`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => setReqResponse(response?.data?.result))
        .catch((e) => {
          const errorMsg = getErrorMessage(e)
          throw errorMsg
        })
    }
  }

  let mainTimer
  let clearCountdown

  useEffect(() => {
    if (countDownTime > 0) {
      clearCountdown = setInterval(() => {
        setCountDownTime(countDownTime - 1)
      }, 1000)
      return () => clearInterval(clearCountdown)
    }
    // else {
    //   history.push('/transactions')
    // }
  }, [countDownTime, localStorage.getItem('page')])

  useEffect(() => {
    if (depositReq?.result?.transactionId) {
      if (countDownTime % 5 === 0) {
        updatePgDepositRequest(depositReq?.result?.transactionId)
        console.log('request', countDownTime % 5)
      }
    }
  }, [depositReq, countDownTime])

  useEffect(() => {
    if (depositReq?.result?.transactionId) {
        updatePgDepositRequest(depositReq?.result?.transactionId)
        console.log('request', countDownTime % 5)
      }
  }, [])
  console.log('depositReq', depositReq);
  console.log('reqResponse', reqResponse);

  useEffect( () => {
    (async () => getQrImage())();
  }, [depositReq])

  const getQrImage = async () => {
    try {
      // console.log(await QRCode.toDataURL(text))
      setQrCodeImage(await QRCode.toDataURL(`${depositReq?.result?.qrCode}`))
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (reqResponse?.status) {
      if (reqResponse.status !== 'Pending') {
        setCountDownTime(1)
        setTimeout(() => {
          // history.push('/transactions')
          dispatch(setReq({}))
          clearInterval(mainTimer)
          clearInterval(clearCountdown)
        }, 5000)
      }
    }
    // } else {
    //
    // }
  }, [reqResponse])

  useIonViewWillEnter(async () => {
    setCountDownTime(300)
  })

  useIonViewDidLeave(() => {
    // setCountDownTime(0)
    setQrCodeImage("");
    dispatch(setReq({}))
    clearInterval(mainTimer)
    clearInterval(clearCountdown)
    console.log('ionic leave from timer dep page')
  })

  useIonViewWillLeave(() => {
    // setCountDownTime(0)
    dispatch(setReq({}))
    clearInterval(mainTimer)
    clearInterval(clearCountdown)
    console.log('ionic leave from timer dep page')
  })

  let animationContainer = createRef()
  let animationContainerSuccess = createRef()

  const apiRequestForQrCodeOnMobile = (protocol) => {
    window.location.href = `https://api.gemexch.co/api/open/upi?vpa=${depositReq?.depositMethod?.account?.acNo}&vpaName=${depositReq?.depositMethod?.account?.name}&amount=${depositReq?.result?.amount}&protocol=${protocol}&notes=${depositReq.result?.transactionId}`;
  };

  useEffect(() => {
    if (reqResponse?.status === "Complete") {
      console.log('IN');
      lottie.loadAnimation({
        container: animationContainer.current,
        animationData: successAnimation,
      })
      lottie.loadAnimation({
        container: animationContainerSuccess.current,
        animationData: successAnimationSuccess,
      })
    }
  }, [reqResponse?.status === "Complete"])

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

    return (
      <>
        <IonPage>
          <IonHeader>
            <Toolbar />
          </IonHeader>
          <IonContent className="ChangePassword" fullscreen>
            <div style={ width > 1024 ?{height: '135px'} : {}}></div>
            {reqResponse?.status !== 'Complete' && (
              <div
                className="timer-wrapper"
                style={{
                  background: 'white',
                  borderRadius: '10px',
                  marginTop: '0',
                  height: '60%',
                }}
              >
                <div className="timerDepositAmount">
                  <img
                    className="timerDepositAmount-currency"
                    src={rupyIcon}
                    alt="Rupy"
                  />
                  <span className="timerDepositAmount-sum">
                    {depositReq?.result?.amount
                      ? getCurrencyFormat(depositReq?.result?.amount)
                      : getCurrencyFormat(reqResponse?.amount)}
                  </span>
                  <div style={width > 1024 ? { textAlign: 'center', fontFamily: 'Lato' , fontSize: '25px'} : { textAlign: 'center', fontFamily: 'Lato' }}>
                    Scan and Pay
                  </div>
                </div>

                {qrCodeImage && (
                  <div className={`wallet-depositInfo__qrCodeImage-wrapper`}>
                    <img
                      className={`wallet-depositInfo__qrCodeImage`}
                      src={qrCodeImage}
                      alt="qrCodeImage"
                      style={{ width: '220px', height: '220px' }}
                    />
                  </div>
                )}

                {width > 1024 && (
                  <>
                  <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <div className={`deposit__title deposit__icons-title`}>Pay Using
                      <div className={`wallet-depositInfo__icons-wrapper`}>
                        <div className={`wallet-depositInfo__icon-box`}>
                          {/* <a href={apiRequestForQrCodeOnMobile("phonepe")}> */}
                          <div className={`wallet-depositInfo__icon-inner`}>
                            <img src={phonePeLogo} alt="phonePeLogo" />
                          </div>
                          <span>Phone Pe</span>
                          {/* </a> */}
                        </div>
                        <div className={`wallet-depositInfo__icon-box`}>
                          {/* <a href={apiRequestForQrCodeOnMobile("tez")}> */}
                          <div className={`wallet-depositInfo__icon-inner`}>
                            <img src={googlePayLogo} alt="googlePayLogo" />
                          </div>
                          <span>Google Pay</span>
                          {/* </a> */}
                        </div>
                        <div className={`wallet-depositInfo__icon-box`}>
                          {/* <a href={apiRequestForQrCodeOnMobile("paytm")}> */}
                          <div className={`wallet-depositInfo__icon-inner`}>
                            <img src={payTmLogo} alt="payTmLogo" />
                          </div>
                          <span>Pay TM</span>
                          {/* </a> */}
                        </div>
                        <div className={`wallet-depositInfo__icon-box`}>
                          {/* <a href={apiRequestForQrCodeOnMobile("upi")}> */}
                          <div className={`wallet-depositInfo__icon-inner`}>
                            <img src={bhimLogo} alt="bhimLogo" />
                          </div>
                          <span>BHIM</span>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <span>Please Note: Do not re-send payment on old QR code or any recipient saved due to QR on UPI apps. If paid to old QR, your payment might get lost.</span>
                  </div>
                  </>
                )}
              </div>
            )}

            {reqResponse?.status === 'Complete' && (
              <>
                <div
                  className="timer-wrapper"
                  style={ width < 1024 ? {
                    background: 'white',
                    borderRadius: '10px',
                    marginTop: '0',
                    height: '50%',
                    padding: '5% 0',
                  } : {
                    background: 'white',
                    borderRadius: '10px',
                    marginTop: '0',
                    height: '75%',
                    padding: '5% 0',
                  }}
                >
                  <div
                    className="timerDepositAmount"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <div>
                      <img
                        className="timerDepositAmount-currency"
                        src={rupyIcon}
                        alt="Rupy"
                      />
                      <span className="timerDepositAmount-sum">
                        {depositReq?.result?.amount
                          ? getCurrencyFormat(depositReq?.result?.amount)
                          : getCurrencyFormat(reqResponse?.amount)}
                      </span>
                    </div>

                     <div
                      style={{ position: 'absolute', left: '0', width: '100%' }}
                    >
                      <div
                        className="animation-container"
                        ref={animationContainer}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          top: '-90px',
                          height: '440px',
                          width: '100%'
                        }}
                      />
                      <div
                        className="animation-container"
                        ref={animationContainerSuccess}
                        style={width < 1024 ?{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          top: '50px',
                          width: '50%',
                          transform: 'translateX(50%)'
                         }:
                         {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          top: '0px',
                          width: '25%', padding: '75px',
                          transform: 'translateX(150%)'
                        }}
                      />
                    </div>

                    <div
                      style={{
                        textAlign: 'center',
                        fontFamily: 'Lato',
                        fontSize: '32px',
                        color: '#000000',
                      }}
                    >
                      Success
                    </div>
                  </div>

                </div>
              </>
            )}

            {width < 1024 && (
              <>
              <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div className={`deposit__title deposit__icons-title`}>Pay Using
                  <div className={`wallet-depositInfo__icons-wrapper`}>
                    <div className={`wallet-depositInfo__icon-box`}
                    onClick={() => apiRequestForQrCodeOnMobile("phonepe")}>
                      {/* <a href={apiRequestForQrCodeOnMobile("phonepe")}> */}
                        <div className={`wallet-depositInfo__icon-inner`}>
                          <img src={phonePeLogo} alt="phonePeLogo" />
                        </div>
                        <span>Phone Pe</span>
                      {/* </a> */}
                    </div>
                    <div className={`wallet-depositInfo__icon-box`}
                    onClick={() => apiRequestForQrCodeOnMobile("tez")}>
                      {/* <a href={apiRequestForQrCodeOnMobile("tez")}> */}
                        <div className={`wallet-depositInfo__icon-inner`}>
                          <img src={googlePayLogo} alt="googlePayLogo" />
                        </div>
                        <span>Google Pay</span>
                      {/* </a> */}
                    </div>
                    <div className={`wallet-depositInfo__icon-box`}
                         onClick={() => apiRequestForQrCodeOnMobile("paytm")}>
                      {/* <a href={apiRequestForQrCodeOnMobile("paytm")}> */}
                        <div className={`wallet-depositInfo__icon-inner`}>
                          <img src={payTmLogo} alt="payTmLogo" />
                        </div>
                        <span>Pay TM</span>
                      {/* </a> */}
                    </div>
                    <div className={`wallet-depositInfo__icon-box`}
                    onClick={() => apiRequestForQrCodeOnMobile("upi")}>
                      {/* <a href={apiRequestForQrCodeOnMobile("upi")}> */}
                        <div className={`wallet-depositInfo__icon-inner`}>
                          <img src={bhimLogo} alt="bhimLogo" />
                        </div>
                        <span>BHIM</span>
                      {/* </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'deposit__title deposit__icons-title'}>
              <span>Please Note: Do not re-send payment on old QR code or any recipient saved due to QR on UPI apps. If paid to old QR, your payment might get lost.</span>
              </div>
              </>
            )}
           {width < 1024 && reqResponse?.status === 'Pending' && ( <div className="timer-wrapper-poveredBox">
              <span className="timer-wrapper-poveredBox-title">Povered By</span>
              <img src={poveredByUPI} alt="" />
            </div>
           )}
            {/*{width < 1024 && <FooterDesktop />}*/}
          </IonContent>
        </IonPage>
      </>
    )
  }

export default connectDepositTimerQr()(DepositTimerQr)
