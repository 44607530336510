import "./OpenBetsTable.scss";
import React from "react";
import {tsToDate} from "../../helpers/date.helper";
import { useHistory } from "react-router-dom";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const OpenBetsTable = ({timeSettings, bets, title, redirect, activeButton}) => {

    const history = useHistory();

    const handleClickName = (item) => {
        if (redirect) history.push(`/event-page/${item?.eventTypeId}/${item?.eventId}`);
    };

    const getProfitLiability = (item) => {
        const isFancy = item?.marketType === "INNINGS_RUNS" && item?.marketBettingType === "LINE";
        const profit = isFancy ? (item.size * item?.averagePrice / 100).toFixed(2) : (item.size * item?.averagePrice - item?.size).toFixed(2);

        return item.side === "Back" ? {profit, liability: ""} : {profit: "", liability: profit};
    };

    return (
    <section className="OpenBetsTable">
      <div className="OpenBetsTable__table">
        <ul className="OpenBetsTable__table-header">
          <li>Placed</li>
          <li>Description</li>
          <li>Type</li>
          <li>Odds</li>
          <li>Stake</li>
          <li>Liability</li>
          <li>Potential Profit</li>
        </ul>
        <div className="OpenBetsTable__table-body">
          {bets && Object.keys(bets).length > 0 ? (

            Object.keys(bets).map((group) =>
              bets[group].markets.map((market) => {
                  const marketName = market.name;
                  return market.selections.map((selection) =>
                  selection.map((item, index) => (
                    <ul className="OpenBetsTable__table-body-row" key={item?.apolloBetId + index}>
                      <li className="OpenBetsTable__table-body-row-date">
                        <span>{tsToDate(item.betPlacedDate, timeSettings.timeZoneName).split(" ")[0]}</span>
                        <span
                          className="text-color">{tsToDate(item.betPlacedDate, timeSettings.timeZoneName).split(" ")[1]}</span>
                      </li>
                      <li className="OpenBetsTable__table-body-row-description">
                        <p className="OpenBetsTable__table-body-row-description-name"
                           onClick={() => handleClickName(item)}>
                          {item.eventName}
                        </p>
                        <p><span className="text-bold">{item.selectionName}</span> <span className="text-color">{marketName}</span></p>
                        <p>
                          <span>Bet ID {item.apolloBetId}</span>
                          {activeButton === "matched" &&
                            <span className="OpenBetsTable__table-body-row-description-matched-time" >
                              Matched {tsToDate(item.betPlacedDate, timeSettings.timeZoneName)}
                            </span>
                          }
                        </p>
                      </li>
                      <li className="OpenBetsTable__table-body-row-info">{item.side}</li>
                      <li className="OpenBetsTable__table-body-row-info">{item.price}</li>
                      <li className="OpenBetsTable__table-body-row-info">{item.orderStatus === 'matched' 
                      ? getCurrencyFormatWithZeroAfterDot(item.sizeMatched)
                      : getCurrencyFormatWithZeroAfterDot(item.sizeRemaining)
                      }</li>
                      <li className="OpenBetsTable__table-body-row-info">{getCurrencyFormatWithZeroAfterDot(getProfitLiability(item).liability)}</li>
                      <li className="OpenBetsTable__table-body-row-profit">{getCurrencyFormatWithZeroAfterDot(getProfitLiability(item).profit)}</li>
                    </ul>
                  )),
                );
              }),
            )
          ) : (
            <ul className="OpenBetsTable__table-body-no-bets">
              <li>
                You have no <span className="lowercase">{title}</span>
              </li>
            </ul>
          )}

        </div>
      </div>
    </section>
    );
};

export default OpenBetsTable;
