import React from 'react'

const SicBoRules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          Super Sic Bo is an exciting game of chance played with three regular
          dice with face value 1 to 6. The objective of Super Sic Bo is to
          predict the outcome of the shake of the three dice.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <div>
          <div>
            <ul>
              <li>Game round starts when Betting Timer reaches 0.</li>
              <li>
                After betting time has expired, the dice are shaken in a dice
                shaker.
              </li>
              <li>The Dealer reveals the result and game round is over.</li>
            </ul>
          </div>
        </div>
        <div>
          <h4>Betting Instructions</h4>
          <div>
            <ul>
              <li>
                If a game round is in progress when you enter the game, please
                wait for the next one, and then, place your bets.
              </li>
              <li>After the timer reaches 0, the game round begins.</li>
              <li>
                To place a bet, click on the box, enter the stake amount and
                submit by clicking on Place Bet button.
              </li>
              <li>
                You can place several bets on different runners in different
                markets simultaneously.
              </li>
              <li>
                The timer shows how much time you have left to place your bets.
              </li>
              <li>
                Winnings are paid for the winning bets at the end of each game
                round.
              </li>
              <li>To skip a turn, simply do not place any bets.</li>
            </ul>
          </div>
        </div>
        <div>
          <h4>Main Bets</h4>
          <div>
            <ul>
              <li>
                Dice Total: Predict what will be the total value of the three
                dices.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h4>Side Bets</h4>
          <div>
            <ul>
              <li>
                Odd (Lose if any Triple): Predict if the Dice Total value will
                be odd.
              </li>
              <li>
                Even (Lose if any Triple): Predict if the Dice Total value will
                be even.
              </li>
              <li>
                Small (Lose if any Triple): Predict if the Dice Total value will
                be in between 4 to 10.
              </li>
              <li>
                Big (Lose if any Triple): Predict if the Dice Total value will
                be in between 11 to 17.
              </li>
              <li>
                Any Triple: Predict if all three dice will have same value.
              </li>
              <li>
                Triple: Predict if all three dice will have same specific value.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h3>Payout</h3>
        <p>Payout depends on the type of bet placed.</p>
        <table>
          <tbody>
            <tr>
              <th>
                <b>4</b>
              </th>
              <th>1: 29</th>
            </tr>
            <tr>
              <th>
                <b>5</b>
              </th>
              <th>1: 19</th>
            </tr>
            <tr>
              <th>
                <b>6</b>
              </th>
              <th>1: 15</th>
            </tr>
            <tr>
              <th>
                <b>7</b>
              </th>
              <th>1: 12</th>
            </tr>
            <tr>
              <th>
                <b>8</b>
              </th>
              <th>1: 8</th>
            </tr>
            <tr>
              <th>
                <b>9</b>
              </th>
              <th>1: 6</th>
            </tr>
            <tr>
              <th>
                <b>10</b>
              </th>
              <th>1: 6</th>
            </tr>
            <tr>
              <th>
                <b>11</b>
              </th>
              <th>1: 6</th>
            </tr>
            <tr>
              <th>
                <b>12</b>
              </th>
              <th>1: 6</th>
            </tr>
            <tr>
              <th>
                <b>13</b>
              </th>
              <th>1: 8</th>
            </tr>
            <tr>
              <th>
                <b>14</b>
              </th>
              <th>1: 12</th>
            </tr>
            <tr>
              <th>
                <b>15</b>
              </th>
              <th>1: 15</th>
            </tr>
            <tr>
              <th>
                <b>16</b>
              </th>
              <th>1: 19</th>
            </tr>
            <tr>
              <th>
                <b>17</b>
              </th>
              <th>1: 29</th>
            </tr>
            <tr>
              <th>
                <b>Odd</b>
              </th>
              <th>1: 0.98</th>
            </tr>
            <tr>
              <th>
                <b>Even</b>
              </th>
              <th>1: 0.98</th>
            </tr>
            <tr>
              <th>
                <b>Small</b>
              </th>
              <th>1: 0.98</th>
            </tr>
            <tr>
              <th>
                <b>Big</b>
              </th>
              <th>1: 0.98</th>
            </tr>
            <tr>
              <th>
                <b>Any Triple</b>
              </th>
              <th>1: 19</th>
            </tr>
            <tr>
              <th>
                <b>Triple</b>
              </th>
              <th>1: 79</th>
            </tr>
          </tbody>
        </table>
        <p>
          Please note that any malfunction voids the game round and all eventual
          payouts for the round. Bets will be returned.
        </p>
      </div>
    </div>
  )
}
export default SicBoRules
