import './RaceInplayitem.scss'
import React from 'react';
import { IonItem } from "@ionic/react";
import OddButton from "../OddButton/oddbutton";
import BetPlacing from "../BetPlacing/BetPlacing";
import { connectRaceInplayitem } from './RaceInplayitem.connect'
import { useParams } from "react-router-dom";

const InPlayItem = ({ className = "", type, raceOdds, itemData, betPlacing, exposure, exposureRace }) => {
    const { eventId } = useParams();
    const item = itemData;

    const renderBetPlacing = () => {
        const status = raceOdds?.status;
        if (betPlacing.market === raceOdds.id && betPlacing.runner === item.id && status.indexOf('OPEN') !== -1 && item.status.indexOf('ACTIVE') !== -1) return <BetPlacing />
        return null
    }

    const getOdds = () => {
        if (!item)
            return

        const status = raceOdds?.status;
        const back = item.back;
        const lay = item.lay;
        const runner = {
            id: item.id,
            name: item.name
        }

        if (status) {

            if (status.indexOf('OPEN') !== -1) {

                if (item.status.indexOf('ACTIVE') !== -1) {
                    return <div className="inplay-item__back">
                        <div className="inplay-item__back-inner odds">
                            <div className={`inplay-item__back-inner`}>
                                <OddButton market={raceOdds} type={type} isBack={true} oddData={{ ...(back && back[0]), runner }} showEmpty centered />
                                <OddButton market={raceOdds} type={type} isBack={false} oddData={{ ...(lay && lay[0]), runner }} showEmpty centered />
                            </div>
                        </div>
                    </div>
                }

                if (item.status.indexOf('REMOVED') !== -1) {
                    return <div className="inplay-item__back">
                        <div className="inplay-item__back-inner">
                            <div className={`inplay-item__back-inner`}>
                                <div className="inplay-item__back-inner__status grey">
                                    <span>Non runner {item.adjustmentFactor}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            }
            else if (status.indexOf('CLOSED') !== -1) {

                if (item.status.indexOf('WINNER') !== -1) {
                    return <div className="inplay-item__back">
                        <div className="inplay-item__back-inner">
                            <div className={`inplay-item__back-inner`}>
                                <div className="inplay-item__back-inner__status">
                                    <span>Winner</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                if (item.status.indexOf('REMOVED') !== -1) {
                    return <div className="inplay-item__back">
                        <div className="inplay-item__back-inner">
                            <div className={`inplay-item__back-inner`}>
                                <div className="inplay-item__back-inner__status grey">
                                    <span>Non runner {item.adjustmentFactor}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                if (item.status.indexOf('LOSER') !== -1) {
                    return <div className="inplay-item__back">
                        <div className="inplay-item__back-inner">
                            <div className={`inplay-item__back-inner`}>
                                <div className="inplay-item__back-inner__status">
                                </div>
                            </div>
                        </div>
                    </div>
                }

            }
            else if (status.indexOf('SUSPENDED') !== -1) {

                if (item.status.indexOf('REMOVED') !== -1) {
                    return <div className="inplay-item__back">
                        <div className="inplay-item__back-inner">
                            <div className={`inplay-item__back-inner`}>
                                <div className="inplay-item__back-inner__status grey">
                                    <span>Non runner {item.adjustmentFactor}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                else {
                    return <div className="inplay-item__back">
                        <div className="inplay-item__back-inner odds">
                            <div className={`inplay-item__back-inner`}>
                                <div className="inplay-item__status">
                                    SUSPENDED
                                </div>
                            </div>
                        </div>
                    </div>
                }

            }
        }
    }

    const getScore = () => {
        if (!item) return
        else if (item.metadata) {
            // const status = raceOdds?.status;
            const stallDraw = item.metadata?.STALL_DRAW
            return <div className="inplay-item__score">
                <div className="inplay-item__score">
                    <div className="score-content racing">
                        <span className="score-content--1">{item.metadata?.CLOTH_NUMBER}</span>
                        <span className="score-content--2"> {stallDraw && `(${item.metadata?.STALL_DRAW})`}</span>
                    </div>
                </div>
            </div>
        }
        else return false;
    }

    const renderExposure = () => {
        let number = 0, calced
        const isFancy = raceOdds.mtype == "INNINGS_RUNS" && raceOdds.btype == "LINE"
        const pnlId = `${raceOdds.id}-${item.id}`
        if (raceOdds.event?.id != eventId) {
            if (exposureRace && exposureRace.pnlBySelection && exposureRace.pnlBySelection[pnlId]) number = exposureRace.pnlBySelection[pnlId].pnl
        }  else if (exposure && exposure.pnlBySelection && exposure.pnlBySelection[pnlId]) number = exposure.pnlBySelection[pnlId].pnl
        if (betPlacing.market === raceOdds.id) {
            if (betPlacing.runner === item.id) {
                // const profit = isFancy ? (betPlacing.stake * betPlacing.priceActual / 100).toFixed(2) : (betPlacing.stake * betPlacing.priceActual - betPlacing.stake).toFixed(2)
                const profit = isFancy ? -betPlacing.stake : (betPlacing.stake * betPlacing.priceActual - betPlacing.stake).toFixed(2)
                calced = (isFancy ? 0 : number) + Number(profit)
            } else {
                calced = number - Number(betPlacing.stake)
            }
        }
        const preCalced = isFancy && number == 0 ? 'Max exposure: ' : ''
        if (number || calced) return <div className="SportEvent__market__title__exposure">
            {number ? <div className={`${number > 0 ? 'positive' : 'negative'}`}>{number.toFixed(2)}</div> : null}
            {preCalced}
            {(calced || number && calced == 0) && <div className={`${calced > 0 ? 'positive' : 'negative'}`}>» {calced.toFixed(2)}</div>}
        </div>
        return null
    }

    const getName = () => {
        if (!item)
            return

        const leftLabel = [
            { name: item.name },
            { name: item.metadata?.JOCKEY_NAME }
        ]

        if (!leftLabel) {
            return (
                <div className="inplay-item__players">
                </div>
            );
        }
        return (
            <div className="inplay-item__players">
                <span key={0} className="inplay-item__player">
                    <span>{item.name}</span>
                </span>
                <span key={1} className="inplay-item__player">
                    <span className="jockey">{item.metadata?.JOCKEY_NAME}</span>
                </span>
                {renderExposure()}
            </div>
        )
    }

    return (
        <>
            <IonItem className={`RaceInplayItem inplay-item ${className}`}>
                {getScore()}
                {getName()}
                {getOdds()}
            </IonItem>
            {renderBetPlacing()}
        </>
    )
}

export default connectRaceInplayitem()(InPlayItem)
