import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestSportbookStart, requestSportbookEnd } from '../../store/sportbookLive/sportbookLive.thunks';
import { setUrlForSportbook } from '../../store/betbyWidget/urlForSportbook.slice';
import { requestSportPageEvent } from '../../store/sportPageEvent/sportPageEvent.thunks';
import { requestInplayPopular } from '../../store/inplayPopular/inplayPopular.thunks';
import { requestLiveCasino } from '../../store/liveCasino/liveCasino.thunks';
import { requestLiveCasinoLobby } from '../../store/liveCasino/liveCasino.thunks';
import { requestLiveCasinoSearch } from '../../store/liveCasinoSearch/liveCasinoSearch.thunks';
import { requestLiveCasinoID } from '../../store/liveCasino/liveCasino.thunks';
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { setQuickButtonSlots } from '../../store/slotsGames/slotsGames.slice';



export const connectLiveCasinoLobby = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      sportPageEvent: state.sportPageEvent,
      inplayPopular: state.inplayPopular,
      liveCasino: state.liveCasino,
      liveCasinoSearch: state.liveCasinoSearch,
      auth: state.auth,
      elementsParams: state.elementsParams,
      quickButtonSlots: state.slotsGames.quickButtonSlots,
      slotCasinoPopUp: state.slotCasinoPopUp
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSportPageEvent,
      requestInplayPopular,
      requestLiveCasino,
      requestLiveCasinoLobby,
      requestLiveCasinoSearch,
      requestLiveCasinoID,
      setLoginNew,
      setQuickButtonSlots,

    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
