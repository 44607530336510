import React from 'react'

const TheTrapRules = () => {
  return (
    <div className="rulesContainer">
      <h2>GAME OBJECTIVES</h2>
      <p>
        The objective of The Trap is to predict that which player will have the
        total score of more then 15 first. It is played with a regular 52 card
        deck.
      </p>

      <h2>GAME RULES</h2>
      <ul>
        <li>
          Ace = 1 point, 2 = 2, 3 = 3, 4 = 4, 5 = 5, 6 = 6, 7 = 7, 8 = 8, 9 = 9,
          10 = 10, jack = 11, queen = 12 and king = 13 points.
        </li>
        <li>There are two sides, Player A and Player B.</li>
        <li>
          First card that will open in the game would be for Player A, next card
          will open in the game for Player B. Likewise till the end of the game.
        </li>
        <li>
          Any side that crosses a total score of 15 would be the winner of the
          game.
        </li>
        <li>
          For example: the total score should be 16 or above. But if at any
          stage your score is at 13, 14, 15 then you will get into the trap
          which ideally means you lose.
        </li>
        <li>
          Hence there are only two conditions from which a player can win,
          either the opponent player has to be trapped in the score of 13, 14 or
          15 or player’s total score should be above 15.
        </li>
      </ul>

      <h3>Main Bets</h3>
      <ol>
        <li>Player A</li>
        <li>Player B</li>
      </ol>

      <h3>Side Bets</h3>
      <ol>
        <li>High</li>
        <li>Low</li>
        <li>Picture Card (J, Q, k)</li>
        <li>Normal Card (Ace - 10)</li>
      </ol>
    </div>
  )
}

export default TheTrapRules
