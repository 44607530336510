import React from 'react'
import "./MuflisTeenpattiLastResult.scss"

const MuflisTeenpattiLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const renderCombination = () => {
    const winner = data && data?.marketId && data?.marketId?.marketRunner.filter(runner => runner?.status === "WINNER")[0]
    if (winner && winner?.resDesc) {
      return (
        <div className='muflis-teenpatt-last-result--combination'>
          <span>{winner?.resDesc}</span>
        </div>
      )
    }
  }

  return (
      <div className="muflis-teenpatt-last-result">
        <div className="muflis-teenpatt-last-result__player">
          <div>Player A</div>
          <div
            className={data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
              ? 'muflis-teenpatt-last-result__player-cards-isWinner'
              : 'muflis-teenpatt-last-result__player-cards'}>
            <img
              className="muflis-teenpatt-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[0])}
              alt=""
            />
            <img
              className="muflis-teenpatt-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[1])}
              alt=""
            />
            <img
              className="muflis-teenpatt-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[2])}
              alt=""
            />
          </div>
        </div>
        <div className="muflis-teenpatt-last-result__player">
          <div>Player B</div>
          <div
            className={data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
              ? 'muflis-teenpatt-last-result__player-cards-isWinner'
              : 'muflis-teenpatt-last-result__player-cards'}>
            <img
              className="muflis-teenpatt-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[0])}
              alt=""
            />
            <img
              className="muflis-teenpatt-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[1])}
              alt=""
            />
            <img
              className="muflis-teenpatt-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[2])}
              alt=""
            />
          </div>
        </div>
        {renderCombination()}
      </div>
  )
}
export default MuflisTeenpattiLastResult
