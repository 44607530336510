import "./SicboPL.scss";
import React, { useEffect, useState } from 'react'
import onePointDice from "../../../assets/images/exchangeGames/sicbo/one-point-dice.svg"
import twoPointDice from "../../../assets/images/exchangeGames/sicbo/two-point-dice.svg"
import threePointDice from "../../../assets/images/exchangeGames/sicbo/three-point-dice.svg"
import fourPointDice from "../../../assets/images/exchangeGames/sicbo/four-point-dice.svg"
import fivePointDice from "../../../assets/images/exchangeGames/sicbo/five-point-dice.svg"
import sixPointDice from "../../../assets/images/exchangeGames/sicbo/six-point-dice.svg"

const SicboPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {};
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    let cardSrcSicbo
    if (card === "1") {
      cardSrcSicbo = onePointDice
    }
    if (card === "2") {
      cardSrcSicbo = twoPointDice
    }
    if (card === "3") {
      cardSrcSicbo = threePointDice
    }
    if (card === "4") {
      cardSrcSicbo = fourPointDice
    }
    if (card === "5") {
      cardSrcSicbo = fivePointDice
    }
    if (card === "6") {
      cardSrcSicbo = sixPointDice
    }
    return <img className={`sicbo-betting__card`} src={cardSrcSicbo} alt="" />
  }

    return (
      <div class="sicbo-betting">
        <div class="sicbo-betting__games">
          {roundMarkets && cardSrc(roundMarkets[0]?.indexCard[0])}
          {roundMarkets && cardSrc(roundMarkets[0]?.indexCard[1])}
          {roundMarkets && cardSrc(roundMarkets[0]?.indexCard[2])}
        </div>
        <div class="sicbo-betting__info-column">
          <div class="sicbo-betting__info-row">
            <div class="sicbo-betting__info-row-title">
              <span>Total Even-Odd Number: </span>
            </div>
            <div class="sicbo-betting__info-row-results">
              <span>{winnerData && winnerData['Total Even-Odd Number']  || 'No'}</span>
            </div>
          </div>
          <div class="sicbo-betting__info-row">
            <div class="sicbo-betting__info-row-title">
              <span>Total High-Low Number: </span>
            </div>
            <div class="sicbo-betting__info-row-results">
              <span>{winnerData && winnerData['Total High-Low Number']  || 'No'}</span>
            </div>
          </div>
          <div class="sicbo-betting__info-row">
            <div class="sicbo-betting__info-row-title">
              <span>Any Triple : </span>
            </div>
            <div class="sicbo-betting__info-row-results">
              <span>{winnerData && winnerData['Any Triple']   || 'No'}</span>
            </div>
          </div>
          <div class="sicbo-betting__info-row">
            <div class="sicbo-betting__info-row-title">
              <span>Triple: </span>
            </div>
            <div class="sicbo-betting__info-row-results">
              <span>{winnerData && winnerData['Triple']  || 'No'}</span>
            </div>
          </div>
          <div class="sicbo-betting__info-row">
            <div class="sicbo-betting__info-row-title">
              <span>All Dice Total: </span>
            </div>
            <div class="sicbo-betting__info-row-results">
              <span>{winnerData && winnerData['All Dice Total']  || 'No'}</span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default SicboPL;

