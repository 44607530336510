import livecasino from '../assets/images/menu-icons/livecasino-icon.svg'
import sportsbook from '../assets/images/menu-icons/sportsbook-icon.svg'
import election from '../assets/images/menu-icons/election-icon.svg'
import bannerСasino from '../assets/images/banners/sport-banner-casino.png'
import bannerExchange from '../assets/images/banners/sport-banner-exchange.png'
import bannerHorse from '../assets/images/banners/sport-banner-horse.svg'
import bannerGreyhound from '../assets/images/banners/sport-banner-greyhound.svg'



import StarIcon from '../assets/images/sideBarStar.svg'
import CricketIcon from '../assets/images/sideBarCricket.svg'
import FootballIcon from '../assets/images/sideBarFooltball.svg'
import TennisIcon from '../assets/images/sideBarTennis.svg'
import HorseIcon from '../assets/images/sideBarHorse.svg'
import GreyhoundIcon from '../assets/images/sideBarGreyhound.svg'
import ExchangeIcon from '../assets/images/sideBarExchange.svg'
import SportsbookIcon from '../assets/images/sideBarSportsbook.svg'
import LivecasinoIcon from '../assets/images/sideBarLivecasino.svg'
import SlotsgamesIcon from '../assets/images/sideBarSlotsgames.svg'

const leftSideMenuMapper = {
  'Favoutites': {
    img: '',
  },

  Cricket: {
    id: 4,
    name: 'Cricket',
    img: CricketIcon,
    isSvg: true,
  },

  Football: {
    id: 1,
    name: 'Football',
    img: FootballIcon,
    isSvg: true,
  },

  Tennis: {
    id: 2,
    name: 'Tennis',
    img: TennisIcon,
    isSvg: true,
  },

  Election: {
    id: 2378962,
    name: 'Election',
    img: election,
    isSvg: true,
  },

  'Indian Card Games': {
    id: 1444001,
    name: 'Indian Card Games',
    img: ExchangeIcon,
    // banner: ExchangeIcon,
    bannerClass: 'exchange',
    isSvg: true,
    bannerLink: '/menu-event/1444001',
  },

  'Casino': {
    id: 1444000,
    name: 'Live Casino',
    img: LivecasinoIcon,
    isSvg: true,
  },

  'Casino Live': {
    name: 'Live Casino',
    id: 77777,
    img: "",
    banner: bannerСasino,
    bannerClass: 'casino',
    bannerLink: '/casino_live/1444000',
  },

  'Horse Racing': {
    id: 7,
    name: 'Horse Racing',
    img: HorseIcon,
    isSvg: true,
    banner: bannerHorse,
    bannerClass: 'greyhound',
    bannerLink: '/menu-event/7',
  },

  'Greyhound Racing': {
    id: 4339,
    name: 'Greyhound Racing',
    img: GreyhoundIcon,
    isSvg: true,
    banner: bannerGreyhound,
    bannerClass: 'horse',
    bannerLink: '/menu-event/4339',
  },

  Sportsbook: {
    id: 1444005,
    name: 'Sportsbook',
    img: sportsbook,
    isSvg: true,
  },

  'Slots games': {
    id: 1444002,
    name: 'Slots games',
    img: SlotsgamesIcon,
    isSvg: true,
  },
}

export default leftSideMenuMapper
