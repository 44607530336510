import { createSlice } from '@reduxjs/toolkit'

export const sportPageEventSlice = createSlice({
  name: 'sportPageEvent',
  initialState: null,
  reducers: {
    getSportPageEventSuccess: (state, action) => action.payload,
    getSportPageEventError: (state) => state,
    getSportPageEventDetailedSuccess: (state, action) => action.payload,
  },
})

export const { getSportPageEventSuccess, getSportPageEventError, getSportPageEventDetailedSuccess } =
  sportPageEventSlice.actions

export default sportPageEventSlice.reducer
