import { createSlice } from '@reduxjs/toolkit'

export const kycPopoverSlice = createSlice({
    name: 'kycPopoverShow',
    initialState: null,
    reducers: {
        setKycPopoverShow: (state, action) => action.payload,
    },
})

export const {
    setKycPopoverShow,
} = kycPopoverSlice.actions

export default kycPopoverSlice.reducer
