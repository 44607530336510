import React from 'react'
import './LiveTeenpattiCards.scss'
import { useSelector } from 'react-redux'
import { connectLiveTeenpatti } from '../../LiveTeenpatti.connect' 
import { useParams } from 'react-router-dom'
import RoundTimer from '../../../helpers/RoundTimer/RoundTimer'


const LiveTeenpattiCards = ({ exchangeGames }) => {

  // console.log('asa', exchangeGames);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }


      return (
        <div className='LiveTeenpattiCardsWrapper'>
          <div className="LiveTeenpattiCardsContent">
            <div className='LiveTeenpattiCardsContent-name'>Player A</div>
          <div className="LiveTeenpattiCardsContent-total">
            <div className="LiveTeenpattiCardsContent-total__cards">
              <img
                className="LiveTeenpattiCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0]
                )}
                alt=""
              />
              <img
                className="LiveTeenpattiCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1]
                )}
                alt=""
              />
              <img
                className="LiveTeenpattiCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[2]
                )}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="LiveTeenpattiCardsContent">
            {/* <div className='LiveTeenpattiCardsContent-name'>Player B</div> */}
          <div className="LiveTeenpattiCardsContent-total">
            <div className="LiveTeenpattiCardsContent-total__cards">
              <img
                className="LiveTeenpattiCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0]
                )}
                alt=""
              />
              <img
                className="LiveTeenpattiCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1]
                )}
                alt=""
              />
              <img
                className="LiveTeenpattiCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[2]
                )}
                alt=""
              />
            </div>
          </div>
          <div className='LiveTeenpattiCardsContent-name b' style={{paddingLeft: '50px'}}>Player B</div>
        </div>

      
        </div>
      )
  }


export default connectLiveTeenpatti()(LiveTeenpattiCards)
