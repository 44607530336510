import { createSlice } from '@reduxjs/toolkit'

export const transferStatementSlice = createSlice({
    name: 'transferStatement',
    initialState: null,
    reducers: {
        getTransferStatementSuccess: (state, action) => action.payload,
        getTransferStatementError: (state) => state,
    },
})

export const { getTransferStatementSuccess, getTransferStatementError } = transferStatementSlice.actions

export default transferStatementSlice.reducer
