import { createSlice } from '@reduxjs/toolkit'

export const promotionsSlice = createSlice({
    name: 'promotions',
    initialState: null,
    reducers: {
        getPromotionsSuccess: (state, action) => action.payload,
        getPromotionsError: (state) => state,
    },
})

export const {
    getPromotionsSuccess,
    getPromotionsError,
} = promotionsSlice.actions

export default promotionsSlice.reducer
