import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestContinueSession, requestLogout } from '../../store/auth/auth.thunks'
import { setContinueSessionModalShow } from '../../store/modals/continueSessionModal.slice'
import { requestRecaptcha } from '../../store/recaptcha/recaptcha.thunks'
import { getRecaptchaSuccess } from '../../store/recaptcha/recaptcha.slice'

export const connectSessionPopover = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      recaptcha: state.recaptcha,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestLogout,
      requestContinueSession,
      setContinueSessionModalShow,
      requestRecaptcha,
      getRecaptchaSuccess,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
