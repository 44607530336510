import http from "../../services/httpAuth";
import getErrorMessage from "../../helpers/getErrorMessage.helper";
import {getRoundMarketsSuccess, getRoundMarketsError} from "./round-markets.slice";

export const requestRoundMarkets = (roundId) => async (dispatch) => {
    console.log("requestRoundMarkets", roundId)
    try {
        // const response = await http.get(`https://fawk.app/api/exchange/odds/roundmarkets/${roundId}`);
        const response = await http.get(`/exchange-games/round/${roundId}`);
        if (response.data.success) {
            dispatch(getRoundMarketsSuccess(response.data.result));
        }
        console.log("response", response)
        return response;
    } catch (e) {
        dispatch(getRoundMarketsError());
        // const errorMsg = getErrorMessage(e);
        // throw errorMsg;
    }
};
