import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { setServerTimeSuccess } from './timeSettings.slice'

export const requestServerTime = () => async (dispatch) => {
  try {
    const response = await http.get('server-time')
    if (response.data.success) {
      dispatch(setServerTimeSuccess(response.data.result))
    }
    return response
  } catch (e) {
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
