import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  getSportPageEventError,
  getSportPageEventSuccess,
  getSportPageEventDetailedSuccess,
} from './sportPageEvent.slice'

export const requestSportPageEvent = (eventId) => async (dispatch, getState) => {
  const checkAuth = getState().auth

  if(checkAuth) {
    try {
      const response = await httpAuth.get(`/exchange/odds/eventType/${eventId}`)
      if (response.data.success) {
        dispatch(getSportPageEventSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getSportPageEventError())
      // const errorMsg = getErrorMessage(e)
            // throw errorMsg
    }
  } else {
    try {
      const response = await httpNotAuth.get(`/exchange/odds/eventType/${eventId}`)
      if (response.data.success) {
        dispatch(getSportPageEventSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getSportPageEventError())
      // const errorMsg = getErrorMessage(e)
            // throw errorMsg
    }
  }
}

export const requestSportPageEventDetailed = (eventId, id) => async (dispatch, getState) => {
  const checkAuth = getState().auth

  if(checkAuth) {
    try {
      const response = await httpAuth.get(`/exchange/odds/group/${eventId}/${id}`)
      if (response.data.success) {
        dispatch(getSportPageEventSuccess(response.data.result))
        // console.log('newresp', response);
      }
      return response
    } catch (e) {
      dispatch(getSportPageEventError())
      // const errorMsg = getErrorMessage(e)
            // throw errorMsg
    }
  } else {
    try {
      const response = await httpNotAuth.get(`/exchange/odds/group/${eventId}/${id}`)
      if (response.data.success) {
        dispatch(getSportPageEventSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getSportPageEventError())
      // const errorMsg = getErrorMessage(e)
            // throw errorMsg
    }
  }
}
