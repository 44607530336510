import "./MatkaCards.scss";
import React from "react";
import {connectMatka} from "../../Matka.connect";

const MatkaCards = ({exchangeGames}) => {
    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };
    const getMatkaResult = () => {
        if (exchangeGames &&
          exchangeGames[0].indexCard &&
          Array.isArray(exchangeGames[0].indexCard) &&
          exchangeGames[0].indexCard.length === 3) {
            const c1 = exchangeGames[0].indexCard[0].slice(1);
            const c2 = exchangeGames[0].indexCard[1].slice(1);
            const c3 = exchangeGames[0].indexCard[2].slice(1);
            return ((c1 + c2 + c3) % 10)
        }
    };
    return (
        <div className="matka-cards">
            <div className="matka-cards__player">
              <img className="matka-cards__player-card" src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[0])}/>
              <img className="matka-cards__player-card" src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[1])}/>
              <img className="matka-cards__player-card" src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[2])}/>
              <span>=</span>
              { !getMatkaResult() ?
                <img className="matka-cards__player-card" src={cardSrc()} alt="back" />
                :
                <div className="matka-cards__player-card-result">{getMatkaResult()}</div>
              }
            </div>
        </div>
    );
};
export default connectMatka()(MatkaCards);
