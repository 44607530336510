import "./Baccarat.scss";
import React, { useEffect, useState } from 'react'

const BaccaratPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   const winObj = {
     winnerPair: '',
     either: '',
     perfect: '',
     bigSmall: ''
   };

   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
         if ((market.marketHeader === 'Player Pair' || market.marketHeader === 'Banker Pair' ) && runner.status === 'WINNER') {
           winObj.winnerPair = winObj.winnerPair.length <= 3 ? market.marketHeader.split(' ')[0] : winObj.winnerPair + ` , ${market.marketHeader.split(' ')[0]}`;
         }
         if (market.marketHeader === 'Either Pair') {
           winObj.either = runner.status === 'WINNER' ? 'Yes' : 'No';
         }
         if (market.marketHeader === 'Perfect Pair') {
           winObj.perfect = runner.status === 'WINNER' ? 'Yes' : 'No';
         }
         if ((market.marketHeader === 'Small' || market.marketHeader === 'Big' ) && runner.status === 'WINNER') {
           winObj.bigSmall = market.marketHeader;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="baccarat-betting">
        <div className="baccarat-betting__games">
          <div
            className={`baccarat-betting__games-item ${roundMarkets[0].marketRunner[0].cards.length > 2 ? 'rotate-card':''}`}>
            <span className="baccarat-betting__games-item-title">Player</span>
            <div
              className={`baccarat-betting__games-item-cards ${roundMarkets[0].marketRunner[0].status === 'WINNER' ? 'winnerCup':''}`} >
              {roundMarkets && roundMarkets[0].marketRunner[0].cards.map(card => <img className="baccarat-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
          <div
            className={`baccarat-betting__games-item ${roundMarkets[0].marketRunner[1].cards.length > 2 ? 'rotate-card-banker':''}`} >
            <span className="baccarat-betting__games-item-title">Banker</span>
            <div
              className={`baccarat-betting__games-item-cards ${roundMarkets[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}`} >
              {roundMarkets && roundMarkets[0].marketRunner[1].cards.map(card => <img className="baccarat-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
        </div>
        <div className="baccarat-betting__info-column">
          <div className="baccarat-betting__info-row">
            <div className="baccarat-betting__info-row-title">
              <span>Winner:</span>
            </div>
            <div className="baccarat-betting__info-row-results">
              <span>{winnerData && winnerData['Match Odds']  || 'No Winner' }</span>
            </div>
          </div>
          <div className="baccarat-betting__info-row">
            <div className="baccarat-betting__info-row-title">
              <span>Winner Pair:</span>
            </div>
            <div className="baccarat-betting__info-row-results">
              <span>{winnerData &&  winnerData['winnerPair'] || 'No Winner' }</span>
            </div>
          </div>
          <div className="baccarat-betting__info-row">
            <div className="baccarat-betting__info-row-title">
              <span>Perfect:</span>
            </div>
            <div className="baccarat-betting__info-row-results">
              <span>{winnerData &&  winnerData['perfect']  || 'No Winner'}</span>
            </div>
          </div>
          <div className="baccarat-betting__info-row">
            <div className="baccarat-betting__info-row-title">
              <span>Either:</span>
            </div>
            <div className="baccarat-betting__info-row-results">
              <span>{winnerData &&  winnerData['either']   || 'No Winner'}</span>
            </div>
          </div>
          <div className="baccarat-betting__info-row">
            <div className="baccarat-betting__info-row-title">
              <span>Big/Small:</span>
            </div>
            <div className="baccarat-betting__info-row-results">
              <span>{winnerData &&  winnerData['bigSmall']  || 'No Winner' }</span>
            </div>
          </div>
        </div>
      </div>
    );
};

export default BaccaratPL;

