
import { setHeaderHeight, setMainContainerHeight, setScrollPosition, setClientWidth } from './elementsParams.slice'

export const headerHeightThunk = (style) => async (dispatch) => {
  if (style) {
    style === 'transform: none;' ? dispatch(setHeaderHeight('non-collapsed')) : dispatch(setHeaderHeight('collapsed'))
  }
}

export const mainContainerHeightThunk = (height) => async (dispatch) => {
  if(height) {
    dispatch(setMainContainerHeight(height))
  }
}

export const scrollPositionThunk = (scrollValue) => async (dispatch) => {
  if (scrollValue) {
    dispatch(setScrollPosition(scrollValue))
  }
}

export const setClientWidthThunk = (width) => async (dispatch) => {
  if(width) {
    dispatch(setClientWidth(width))
  }
}
