import React from 'react'
import CasinoWar1 from './images/CasinoWar1.png'

const CasinoWarRules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          The objective of Casino War is to predict that which Player(s) will
          have the higher card than Dealer’s card according to the rules of the
          game. It is played with regular 52 card deck.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <ul>
          <li>
            Casino War is a 52 cards game, in which 7 hands are dealt; one for
            the dealer and others for each 6 players.
          </li>
          <li>
            The ranking of cards is, from lowest to highest: Ace, 2, 3, 4, 5, 6,
            7,8, 9, 10, Jack, Queen and King where Ace is "1" and King is "13".
          </li>
          <li>
            If a player and the dealer has same card with different suit, winner
            will be decided based on below suit ranking.
          </li>
        </ul>
        <img style={{ width: '50%' }} src={CasinoWar1} />
        <div>
          <h3>Betting Instructions</h3>
          <ul>
            <li>
              If a game round is in progress when you enter the game, please
              wait for the next one, and then, place your bets.
            </li>
            <li>After the timer reaches 0, the game round begins.</li>
            <li>
              To place a bet, click on the box, enter the stake amount and
              submit by clicking on Place Bet button.
            </li>
            <li>
              You can place several bets on different runners in different
              markets simultaneously.
            </li>
            <li>
              The timer shows how much time you have left to place your bets.
            </li>
            <li>
              Winnings are paid for the winning bets at the end of each game
              round.
            </li>
            <li>To skip a turn, simply do not place any bets.</li>
          </ul>
        </div>
        <div>
          <h3>Main Bets</h3>
          <ol>
            <li>Player 1</li>
            <li>Player 2</li>
            <li>Player 3</li>
            <li>Player 4</li>
            <li>Player 5</li>
            <li>Player 6</li>
          </ol>
        </div>
      </div>
    </div>
  )
}
export default CasinoWarRules
