import React from 'react'
import './TeenpattiT20Cards.scss'
import { connectTeenpattiT20 } from '../../TeenpattiT20.connect'

const TeenpattiT20Cards = ({ exchangeGames }) => {
  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  return (
    <div className="teenpattiT20CardsContent">
      <div className="teenpattiT20CardsContent__players-wrapper">
        <div className="teenpattiT20CardsContent__player">
          <span className="teenpattiT20CardsContent__player-name">Player A</span>
          <div className="teenpattiT20CardsContent__player-cards">
            <img
              className="teenpattiT20CardsContent__player-cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0])} alt="" />
            <img
              className="teenpattiT20CardsContent__player-cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1])} alt="" />
            <img
              className="teenpattiT20CardsContent__player-cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[2])} alt="" />
          </div>
        </div>
        <div className="teenpattiT20CardsContent__player teenpattiT20CardsContent__player-second">
          <span className="teenpattiT20CardsContent__player-name">Player B</span>
          <div className="teenpattiT20CardsContent__player-cards">
            <img
              className="teenpattiT20CardsContent__player-cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0])} alt="" />
            <img
              className="teenpattiT20CardsContent__player-cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1])}alt="" />
            <img
              className="teenpattiT20CardsContent__player-cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[2])}alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connectTeenpattiT20()(TeenpattiT20Cards)
