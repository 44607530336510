import React from 'react'
// import RouletteRuslesLastRes from './images/RulesLastRes.png'
// import rouletteScreen from './images/rouletteScreen.png'
// import roulettePnL from './images/roulettePnL.png'
// import roulettePnLDet from './images/roulettePnLDet.png'

const RouletteRules = () => {
  return (
    <div className='rulesContainer'>
      <div>
        <h2>GAME OBJECTIVES</h2>
        <p>
          The objective in Roulette is to predict the number on which the ball
          will land by placing one or more bets that cover that particular
          number. The wheel in European Roulette includes the numbers 1-36 plus
          a single 0 (zero).
        </p>
      </div>

      <div>
        <h2>GAME RULES</h2>
        <ul>
          <li>Game round starts when Betting Timer reaches 0.</li>
          <li>
            After betting time has expired, the ball is spun within the Roulette
            wheel. The ball will eventually come to rest in one of the numbered
            pockets within the wheel. You win if vou have placed a bet that
            covers that particular number.
          </li>
        </ul>
      </div>

      <div>
        <h3>Betting Instructions</h3>
        <ul>
          <li>
            If a game round is in progress when you enter the game, please wait
            for the next one, and then, place your bets.
          </li>
          <li>After the timer reaches 0, the game round begins.</li>
          <li>
            To place a bet, click on the box, enter the stake amount and submit
            by clicking on Place Bet button.
          </li>
          <li>
            You can place several bets on different runners in different markets
            simultaneously.
          </li>
          <li>
            The timer shows how much time you have left to place your bets.
          </li>
          <li>
            Winnings are paid for the winning bets at the end of each game
            round.
          </li>
          <li>To skip a turn, simply do not place any bets.</li>
        </ul>
      </div>

      <div>
        <h3>Main Bets</h3>

        <ol>
          <li>Number</li>
        </ol>
      </div>

      <div>
        <h3>Side Bets</h3>
        <ol>
          <li>Red</li>
          <li>Black</li>
          <li>Odd</li>
          <li>Even</li>
          <li>1-18 (Low)</li>
          <li>19-36 (High)</li>
          <li>1st 12 (1st Dozen 1-12)</li>
          <li>2nd 12 (2nd Dozen 13-24)</li>
          <li>3rd 12 (3rd Dozen 25-36)</li>
          <li>2to 1 (1st Column 3,6,9……36)</li>
          <li>2to 1 (2nd Column 2,5,8,….35)</li>
          <li>2to 1 (3rd Column 1,4,7,….34)</li>
        </ol>
      </div>

      <div>
        <h3>Payout</h3>
        <p>Payout depends on the type of bet placed.</p>

        <table className="rulesTable">
                <tbody>
                  <tr>
                    <th>Number</th>
                    <th>1: 34</th>
                  </tr>
                  <tr>
                    <th>Red</th>
                    <th>1: 1</th>
                  </tr>
                  <tr>
                    <th>Odd</th>
                    <th>1: 1</th>
                  </tr>
                  <tr>
                    <th>Even</th>
                    <th>1: 1</th>
                  </tr>
                  <tr>
                    <th>1-18 (Low)</th>
                    <th>1: 1</th>
                  </tr>
                  <tr>
                    <th>19-36 (High)</th>
                    <th>1: 1</th>
                  </tr>
                  <tr>
                    <th>1st 12 (1st Dozen 1-12)</th>
                    <th>1: 2</th>
                  </tr>
                  <tr>
                    <th>2nd 12 (2nd Dozen 13-24)</th>
                    <th>1: 2</th>
                  </tr>
                  <tr>
                    <th>3rd 12 (3rd Dozen 25-36)</th>
                    <th>1: 2</th>
                  </tr>
                  <tr>
                    <th>2to 1 (1st Column 3,6,9……36)</th>
                    <th>1: 2</th>
                  </tr>
                  <tr>
                    <th>2to 1 (2nd Column 2,5,8,….35)</th>
                    <th>1: 2</th>
                  </tr>
                  <tr>
                    <th>2to 1 (3rd Column 1,4,7,….34)</th>
                    <th>1: 2</th>
                  </tr>
                  
                </tbody>
              </table>
              <p>Please note that any malfunction voids the game round and all eventual payouts for the round. Bets will be returned.</p>

      </div>
{/* 
      <div>
        <h2>LAST RESULTS</h2>
        <p>Last 9 round results can be found at the bottom</p>
        <img className='rulesImage' src={RouletteRuslesLastRes} alt='Last Results'></img>

        <p>Detailed last result pop up</p>
        <img className='rulesImage' src={rouletteScreen} alt='Last Results'></img>
      </div>

      <div>
        <h2>BETTING HISTORY</h2>
        <p>Betting history menu returns the list of all bets made in all of the games.</p>
        <img className='rulesImage' src={roulettePnL} alt='Last Results'></img>

        <p>Detailed view of each played round is accessible by clicking on an entry from the list</p>
        <img className='rulesImage' src={roulettePnLDet} alt='Last Results'></img>
      </div> */}

    </div>
  )
}
export default RouletteRules
