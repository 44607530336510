import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestLogout } from '../../store/auth/auth.thunks'
import { setKycPopoverShow } from '../../store/modals/kycPopover.slice'
import { changeWalletStatus } from '../../store/balance/balance.thunks'
import { requestBonusWageringInfoSuccess } from '../../store/bonusWageringInfo/bonusWageringInfo.thunks'
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'

export const connectRightSideMenu = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      user: state.user,
      balance: state.balance,
      B2C: state.B2C, 
      auth: state.auth,   
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestLogout,
      setKycPopoverShow,
      changeWalletStatus,
      requestBonusWageringInfoSuccess,
      setLoginNew
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
