import { createSlice } from '@reduxjs/toolkit'

export const sportPageAllEventsSlice = createSlice({
  name: 'sportPageAllEvents',
  initialState: null,
  reducers: {
    getSportPageAllEventsSuccess: (state, action) => action.payload,
    getSportPageAllEventsError: (state) => state,
  },
})

export const { getSportPageAllEventsSuccess, getSportPageAllEventsError } =
  sportPageAllEventsSlice.actions

export default sportPageAllEventsSlice.reducer
