import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestRulesPage } from '../../store/rulesPage/rulesPage.thunks';

export const connectRulesPage = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      rulesPage: state.rulesPage,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestRulesPage,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
