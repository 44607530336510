import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setInfoDetailed } from '../../store/openBets/openBets.slice'
import { setResetUnmatched, setUnmatchedChanged } from '../../store/openBetsList/openBetsList.slice';

export const connectOpenBetsList = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      user: state.user,
      sportData: state.sportEvent.sportData,
      sportEventStats: state.sportEvent.sportStats,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setInfoDetailed,
      setResetUnmatched,
      setUnmatchedChanged,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
