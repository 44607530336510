import { createSlice } from '@reduxjs/toolkit'

export const rulesPopUpSlice = createSlice({
  name: 'rulesPopUp',
  initialState: false,
  reducers: {
    setRulesPopUp: (state, action) => action.payload,
  },
})

export const { setRulesPopUp } = rulesPopUpSlice.actions

export default rulesPopUpSlice.reducer
