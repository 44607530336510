import React from "react";
import "./CasinoWarCards.scss";
import {connectCasinoWar, connectPoker20} from "../../CasinoWar.connect";

const CasinoWarCards = ({exchangeGames}) => {
    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };

    return (
    <div className="casino-war-cards">
      <img className="casino-war-cards-total__cards-card" src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[0])} alt="card" />
    </div>
    );
};

export default connectCasinoWar()(CasinoWarCards);
