import React from 'react'
import "./TeenpattiTestLastResult.scss"

const TeenpattiTestLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const descriptionResult = () => {
    if (data && data.rName === 'T'){
      if(data.marketId.marketRunner[0].resDesc === ''){
        return "HIGH CARD"
      } else if (data.marketId.marketRunner[0].resDesc === 'COLOR') {
        return "FLUSH"
      } else{
        return data.marketId.marketRunner[0].resDesc
      }
    }
    if (data && data.rName === 'L'){
      if(data.marketId.marketRunner[1].resDesc === ''){
        return "HIGH CARD"
      } else if (data.marketId.marketRunner[1].resDesc === 'COLOR') {
        return "FLUSH"
      } else {
        return data.marketId.marketRunner[1].resDesc
      }
    }
    if (data && data.rName === 'D'){
      if(data.marketId.marketRunner[2].resDesc === ''){
        return "HIGH CARD"
      } else if (data.marketId.marketRunner[2].resDesc === 'COLOR') {
        return "FLUSH"
      } else {
        return data.marketId.marketRunner[2].resDesc
      }
    }
  }

  return (
      <>
      <div className='tptWrapper'>
        <div className='tptColumn'>
          <div className='tptTitle'>Tiger</div>
          <div
            className={data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
                ? 'popUpTPT-content__results-cards-isWinner'
                : 'popUpTPT-content__results-cards'}>
            <img
              className="popUpTPT-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[0])}
              alt=""
            />
            <img
              className="popUpTPT-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[1])}
              alt=""
            />
            <img
              className="popUpTPT-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[2])}
              alt=""
            />
          </div>
      </div>

      <div className='tptColumn'>
        <div className='tptTitle'>Lion</div>
        <div
          className={data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
              ? 'popUpTPT-content__results-cards-isWinner'
              : 'popUpTPT-content__results-cards'}>
          <img
            className="popUpTPT-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[0])}
            alt=""
          />
          <img
            className="popUpTPT-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[1])}
            alt=""
          />
          <img
            className="popUpTPT-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[2])}
            alt=""
          />
        </div>
      </div>

      <div className='tptColumn'>
        <div className='tptTitle'>Dragon</div>
        <div
          className={data && data?.marketId?.marketRunner[2]?.status === 'WINNER'
              ? 'popUpTPT-content__results-cards-isWinner'
              : 'popUpTPT-content__results-cards'}>
          <img
            className="popUpTPT-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[2]?.cards[0])}
            alt=""
          />
          <img
            className="popUpTPT-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[2]?.cards[1])}
            alt=""
          />
          <img
            className="popUpTPT-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[2]?.cards[2])}
            alt=""
          />
        </div>
      </div>
      </div>
        { <div className="popUpTPT-content__results-description">
          <div className="popUpTPT-content__results-description-text">{descriptionResult()}</div>
        </div>}
      </>
  )
}
export default TeenpattiTestLastResult
