import './TransactionPopup.scss'
import React from 'react'
import { connectTransactionPopup } from './TransactionPopup.connect'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import Toolbar from '../Toolbar/Toolbar'
import TransactionPopupResult from './TransactionPopupResult.js'
import visa from '../../assets/images/transaction-popup-images/visa.svg'
import master from '../../assets/images/transaction-popup-images/master.svg'
import skrill from '../../assets/images/transaction-popup-images/skrill.svg'
import bitcoin from '../../assets/images/transaction-popup-images/bitcoin-logo.svg'
import paypal from '../../assets/images/transaction-popup-images/paypal.svg'
import closeIcon from '../../assets/images/transaction-popup-images/close-icon.svg'

const TransactionPopup = () => {
  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="transaction-popup" fullscreen>
        <div className="transactionPopupFormContainer">
          <div className="transactionPopupFormBox">
            <div className="transactionPopupFormBox__close-button">
              <img  className="transactionPopupFormBox__close-icon" src={closeIcon} alt="" />
            </div>
            <div className="transactionPopupFormBox__title-wrapper">
              <h2 className="transactionPopupFormBox__title">Deposit / Withdraw Wallet</h2>
              <p className="transactionPopupFormBox__subtitle">Current balance: 1000.00</p>
            </div>
            <div className="transactionPopupFormBox__form-wrapper">
              <form action="" className="transactionPopupFormBox__form">
                <ul className="transactionPopupFormBox__form-tabs-wrapper">
                  <li className="transactionPopupFormBox__form-tabs-item active">
                    <span>Deposit </span>
                  </li>
                  <li className="transactionPopupFormBox__form-tabs-item">
                    <span>Withdraw </span>
                  </li>
                </ul>
                <div className="transactionPopupFormBox__form-input-wrapper">
                  <label className="transactionPopupFormBox__form-input-label">
                    <p className="transactionPopupFormBox__form-input-label-text">Amount</p>
                    <input className="transactionPopupFormBox__form-input active" type="number" placeholder="Enter Amount" />
                  </label>
                  <span className="transactionPopupFormBox__form-input-warning">The amount is less than the allowable</span>
                </div>
                <div className="transactionPopupFormBox__form-bottom-desbscription">
                  <p>Accept, process & disburse digital payment for your business.</p>
                  <p>Fast $ Secure Payment</p>
                </div>
                <div className="transactionPopupFormBox__form-logos-wrapper">
                  <ul className="transactionPopupFormBox__form-logos-list">
                    <li className="transactionPopupFormBox__form-logos-item">
                      <img src={visa} alt="" />
                    </li>
                    <li className="transactionPopupFormBox__form-logos-item">
                      <img src={master} alt="" />
                    </li>
                    <li className="transactionPopupFormBox__form-logos-item">
                      <img src={skrill} alt="" />
                    </li>
                    <li className="transactionPopupFormBox__form-logos-item">
                      <img src={bitcoin} alt="" />
                    </li>
                    <li className="transactionPopupFormBox__form-logos-item">
                      <img src={paypal} alt="" />
                    </li>
                  </ul>
                </div>
                <div className="transactionPopupFormBox__form-buttons-wrapper">
                  <button className="transactionPopupFormBox__form-button form-submit-button">
                    <span>Deposit</span>
                  </button>
                  <button className="transactionPopupFormBox__form-button form-cancel-button">
                    <span>Close</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <TransactionPopupResult />
      </IonContent>
    </IonPage>
  )
}

export default connectTransactionPopup()(TransactionPopup)
