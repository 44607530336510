import React from 'react'
import './SixPlayerPokerCards.scss'
import { connectSixPlayerPoker } from '../../SixPlayerPoker.connect'

const SixPlayerPokerCards = ({ exchangeGames }) => {
  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  return (
    <div className="pokerCardsContent">
      <div className="pokerCardsContent-total pokerCardsContent-board">
        <span className="pokerCardsContent-total__name">Board</span>
        <div className="pokerCardsContent-total__cards">
          <img
            className="pokerCardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[0])} alt=""/>
          <img
            className="pokerCardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[1])} alt=""/>
          <img
            className="pokerCardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[2])} alt=""/>
          <img
            className="pokerCardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[3])} alt=""/>
          <img
            className="pokerCardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[4])} alt=""/>
        </div>
      </div>
    </div>
  )
}

export default connectSixPlayerPoker()(SixPlayerPokerCards)
