import { createSlice } from '@reduxjs/toolkit'

export const liveCasinoSearchSlice = createSlice({
    name: 'liveCasinoSearch',
    initialState: null,
    reducers: {
        getLiveCasinoSearchSuccess: (state, action) => action.payload,
        getLiveCasinoSearchError: (state) => state,
    },
})

export const {
    getLiveCasinoSearchSuccess,
    getLiveCasinoSearchError,
} = liveCasinoSearchSlice.actions

export default liveCasinoSearchSlice.reducer
