import React from "react";
import {connectAndarBahar} from "../../AndarBahar.connect";
import "./AndarBaharCards.scss";

const AndarBaharCards = ({exchangeGames}) => {
    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };

    const renderCards = (market, runner) => {
        const cards = exchangeGames && exchangeGames[market]?.marketRunner[runner]?.cards;

        if (Array.isArray(cards) && cards.length > 0) {
            return (runner === 1 ? cards.slice(-4).reverse() : cards.slice(-4)).map(card =>
        <img key={card} className="andar-bahar-cards__player-cards-card"
             src={cardSrc(card)} alt={card} />);
        }

        return <img className="andar-bahar-cards__player-cards-card"
                src={cardSrc()} alt="back" />;
    };
    return (
        <div className="andar-bahar-cards">
          <div className="andar-bahar-cards__player">
            <div className="andar-bahar-cards__player-title">
              <span>Bahar</span>
            </div>
            <div className="andar-bahar-cards__player-cards">
              {renderCards(0, 0)}
            </div>
          </div>
          <div className="andar-bahar-cards__player index-card">
              <img className="andar-bahar-cards__player-card"
                   src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[0])} alt="back" />
          </div>
          <div className="andar-bahar-cards__player second-player">
            <div className="andar-bahar-cards__player-title">
              <span>Andar</span>
            </div>
            <div className="andar-bahar-cards__player-cards">
              {renderCards(0, 1)}
            </div>
          </div>
        </div>
    );
};
export default connectAndarBahar()(AndarBaharCards);
