
import './OneClickToggle.scss';
import React from 'react'
import {
  IonToggle,
} from '@ionic/react'

export default ({
    className,
    ...props
}) => {

    return <IonToggle className={`oneClickToggle ${className}`} {...props} />
}