import React, { useState, useEffect } from 'react';
// import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { useHistory } from 'react-router';
import { connectRaceList } from './connect';
// import {
//     GET_BALANCE_INTERVAL
// } from '../../config/app.config'
import RaceEventDropdown from '../RaceEventDropdown';

const RaceListEventDetails = ({ eventTypeId, exclude, eventsShow, initialOpen, menuEvent, balanceSettings, actions, showJockeyName }) => {

    const history = useHistory();
    const [raceOpen, setOpen] = useState(Number.isInteger(initialOpen) ? initialOpen : null);

    const toggleOpen = (id, index) => {
        if (raceOpen == id || raceOpen == index) setOpen(null)
        else setOpen(id)
    }

    useEffect(() => {
        // let mainTimer;
        setOpen(Number.isInteger(initialOpen) ? initialOpen : null);
        // if (eventTypeId == 7 || eventTypeId == 4339) {
        //     if (!menuEvent) actions.requestMenuEventRace(eventTypeId)
        //     mainTimer = setIntervalAsync(async() => {
        //         console.log('exclude', exclude) // TODO: Remove it later_____________________________!!!
        //         if (history.location.pathname.indexOf(`/menu-event/${eventTypeId}`) !== -1
        //                 || history.location.pathname.indexOf(`/event-page/${eventTypeId}`) !== -1
        //             ) await actions.requestMenuEventRace(eventTypeId);
        //     }, GET_BALANCE_INTERVAL);
        //
        //     // return () => clearIntervalAsync(mainTimer);
        // }
        // return () => clearIntervalAsync(mainTimer);
    }, [eventTypeId]);

    return menuEvent && menuEvent[`${eventTypeId}`]
            ?
            menuEvent[`${eventTypeId}`].filter(item => item.id != exclude).slice(0, eventsShow).map((item, index) =>
                <RaceEventDropdown
                    key={'race_event' + index}
                    flag={item.countryCode?.toLowerCase()}
                    race={item}
                    isOpen={raceOpen == item.id || raceOpen == index}
                    setOpen={() => toggleOpen(item.id, index)}
                    oddId={item.id}
                    className=""
                    openEventPage={() => history.push(`/event-page/${eventTypeId}/${item.id}`)}
                    balanceSetting={balanceSettings && balanceSettings.settings[eventTypeId]}
                    showRunnersLengthInHeader={true}
                    showJockeyName={showJockeyName}
                />

            )
            :
            <></>
            // return menuEvent && menuEvent[`${eventTypeId}`] && menuEvent[`${eventTypeId}`].filter(item => item.id != exclude).slice(0, eventsShow).map((item, index) =>
            //   <RaceEventDropdown
            //     key={'race_event' + index}
            //     flag={item.countryCode?.toLowerCase()}
            //     race={item}
            //     isOpen={raceOpen == item.id || raceOpen == index}
            //     setOpen={() => toggleOpen(item.id, index)}
            //     oddId={item.id}
            //     className=""
            //     openEventPage={() => history.push(`/event-page/${eventTypeId}/${item.id}`)}
            //     balanceSetting={balanceSettings && balanceSettings.settings[eventTypeId]}
            //   />
            // )
}

export default connectRaceList()(RaceListEventDetails)
