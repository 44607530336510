import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getSlotsGamesSuccess, getSlotsGamesError, mainGames, allSlots, fishingGames, bingoGames, multiplayerGames, virtualGames, scratchGames, getSlotsGamesIDSuccess, getSlotsGamesIDError, getSlotsGamesButtonsSuccess, } from './slotsGames.slice'
import axios from 'axios'
import { BASE_DEV_URL } from '../../config/api.config'

export const requestSlotsGames = () => async (dispatch, getState) => {
    const checkAuth = getState().auth
    if(checkAuth) {
        try {
            const response = await httpAuth.get(`casino/v2/slotgames-lobby`)
           if (response.data.success) {
            dispatch(mainGames(response.data.result))
            dispatch(getSlotsGamesButtonsSuccess(response.data.result))
        }
        return response
        } catch (e) {
            dispatch(getSlotsGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    } else {
        try {
            const response = await httpNotAuth.get(`casino/v2/slotgames-lobby`)
           if (response.data.success) {
            dispatch(mainGames(response.data.result))
            dispatch(getSlotsGamesButtonsSuccess(response.data.result))
        }
        return response
        } catch (e) {
            dispatch(getSlotsGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }
}

export const requestSlotsGamesLobby = (id) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    
    if(checkAuth) {
        try {
            const response = await httpAuth.get(`/casino/v2/slot-games/${id}`)
           if (response.data.success) {
               if(id === '51'){
                dispatch(allSlots(response.data.result))
               } else if (id === '52'){
                dispatch(fishingGames(response.data.result))
               } else if (id === '53'){
                dispatch(bingoGames(response.data.result))
               } else if (id === '54'){
                dispatch(multiplayerGames(response.data.result))
               } else if (id === '55'){
                dispatch(virtualGames(response.data.result))
               } else if (id === '56'){
                dispatch(scratchGames(response.data.result))
               }
            }
            return response
        } catch (e) {
            dispatch(getSlotsGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    } else {
        try {
            const response = await httpNotAuth.get(`/casino/v2/slot-games/${id}`)
           if (response.data.success) {
               if(id === '51'){
                dispatch(allSlots(response.data.result))
               } else if (id === '52'){
                dispatch(fishingGames(response.data.result))
               } else if (id === '53'){
                dispatch(bingoGames(response.data.result))
               } else if (id === '54'){
                dispatch(multiplayerGames(response.data.result))
               } else if (id === '55'){
                dispatch(virtualGames(response.data.result))
               } else if (id === '56'){
                dispatch(scratchGames(response.data.result))
               }
            }
            return response
        } catch (e) {
            dispatch(getSlotsGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }
}

export const requestSlotsGamesID = (id) => async (dispatch) => {
    const token = localStorage.getItem('id_token')
    try {
        const response = await axios.get(BASE_DEV_URL + `/casino/live/table-w/${id}`, {
            headers: {
              Authorization: `${token}`,
            },
          })
       if (response.data.success) {
        // window.open(response?.data?.result?.url)

        dispatch(getSlotsGamesIDSuccess(response.data.result))
    }
    return response
    } catch (e) {
        dispatch(getSlotsGamesIDError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}

export const requestJILIGamesID = (id) => async (dispatch) => {
  const token = localStorage.getItem('id_token')
  try {
    const response = await axios.get(BASE_DEV_URL + `/casino/live/table/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    })
    if (response.data.success) {
    //   window.open(response?.data?.result?.url)
      dispatch(getSlotsGamesIDSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getSlotsGamesIDError())
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}
