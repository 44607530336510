import React, {  useEffect, useRef } from 'react'
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { connectLiveCasinoAndSlots } from './LiveCasino&SlotPage.connect'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getLiveCasinoIDSuccess } from '../../store/liveCasino/liveCasino.slice'
import { getSlotsGamesIDSuccess } from '../../store/slotsGames/slotsGames.slice'

import './LiveCasinoAndSlotPage.scss'
import goBackIcon from '../../assets/images/goback.svg'

const LiveCasinoAndSlotPage = ({ liveCasino, slotsGames, elementsParams,actions, ...props }) => {
  const history = useHistory()
  const refIframe = useRef(null)
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  const dispatch = useDispatch()

  const endCasino = () => {
    history.goBack()
  }

  const whichGame = () => {
    if(slotsGames && slotsGames.slotsGamesID) return "to Slots"
    if(liveCasino && liveCasino.liveCasinoID) return "to Live Casino"
  }

  useEffect(() => {
    return () => {
      if(!history.location.pathname.includes('/live_casino_slot/')) {
        dispatch(getLiveCasinoIDSuccess(null))
        dispatch(getSlotsGamesIDSuccess(null))
      }
    }
  }, [])

  return (
    width < 1024 ? (<IonPage className="CasinoGames casinoLive__slot" style={width >=1024 ? {paddingTop: `135px`}: {}}>
      <IonHeader>
        <IonToolbar className="CasinoGames__toolbar">
          <div className="CasinoGames__toolbar__wrapper">
            <div className="ion-toolbar__left">
              <IonButton
                className="menu-btn"
                mode="md"
                fill="clear"
                onClick={endCasino}
              >
                {/* <IonIcon className="menu-icon2 go-back-icon2" /> */}
                <IonIcon className="menu-icon go-back-icon" src={goBackIcon} />
              </IonButton>
              <div className="CasinoGames__toolbar__title">Go Back {whichGame()}</div>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {slotsGames && slotsGames.slotsGamesID && 
        <iframe
          className="CasinoGames__content__video"
          ref={refIframe}
          allowFullScreen
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          scrolling="yes"
          title="casino live"
          src={slotsGames.slotsGamesID.url}
          >
        </iframe>
        }
        {liveCasino && liveCasino.liveCasinoID && 
        <iframe
          className="CasinoGames__content__video"
          ref={refIframe}
          allowFullScreen
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          scrolling="yes"
          title="casino live"
          src={liveCasino.liveCasinoID.url}
          >
        </iframe>
         } 
      </IonContent>
    </IonPage>)
      : (
        <IonPage className="CasinoGames casinoLive__slot"
          style={{overflow: "hidden!important"}}
        >
        <div style={{height: '135px'}}></div>
        <IonHeader>
          <IonToolbar className="CasinoGames__toolbar">
            <div className="CasinoGames__toolbar__wrapper">
              <div className="ion-toolbar__left">
                <IonButton
                  className="menu-btn"
                  mode="md"
                  fill="clear"
                  onClick={endCasino}
                >
                  {/* <IonIcon className="menu-icon2 go-back-icon2" /> */}
                  <IonIcon className="menu-icon go-back-icon" src={goBackIcon} />
                </IonButton>
                <div className="CasinoGames__toolbar__title">Go Back {whichGame()}</div>
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <div>
          {slotsGames && slotsGames.slotsGamesID && 
          <iframe
            className="CasinoGames__content__video"
            ref={refIframe}
            allowFullScreen
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            // scrolling="yes"
            title="casino live"
            src={slotsGames.slotsGamesID.url}
            >
          </iframe>
          }
          {liveCasino && liveCasino.liveCasinoID && 
          <iframe
            className="CasinoGames__content__video"
            ref={refIframe}
            allowFullScreen
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            // scrolling="yes"
            title="casino live"
            src={liveCasino.liveCasinoID.url}
            >
          </iframe>
          } 
        </div>
    </IonPage>)
    
  )
}

export default (connectLiveCasinoAndSlots()(LiveCasinoAndSlotPage))
