import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getLiveCasinoSuccess, getLiveCasinoError, allGames, topGames, liveGames, tableGames, pokerGames, blackjackGames, baccaratGames, rouletteGames, showsGames, virtualGames, getLiveCasinoIDSuccess, getLiveCasinoIDError} from './liveCasino.slice'
import axios from 'axios'
import { BASE_DEV_URL } from '../../config/api.config'

export const requestLiveCasino = (id) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    
    if(checkAuth) {
        try {
            const response = await httpAuth.get(`/casino/v2/games/${id}`)
           if (response.data.success) {
               if(id === '11'){
                dispatch(allGames(response.data.result))
               } else if (id === '12'){
                dispatch(topGames(response.data.result))
               } else if (id === '14'){
                dispatch(liveGames(response.data.result))
               } else if (id === '13'){
                dispatch(tableGames(response.data.result))
               } else if (id === '15'){
                dispatch(pokerGames(response.data.result))
               } else if (id === '16'){
                dispatch(blackjackGames(response.data.result))
               } else if (id === '17'){
                dispatch(baccaratGames(response.data.result))
               } else if (id === '18'){
                dispatch(rouletteGames(response.data.result))
               } else if (id === '19'){
                dispatch(showsGames(response.data.result))
               } else if (id === '20'){
                dispatch(virtualGames(response.data.result))
               } 
            }
            return response
        } catch (e) {
            dispatch(getLiveCasinoError())
            const errorMsg = getErrorMessage(e)
            throw errorMsg
        }
    } else {
        try {
            const response = await httpNotAuth.get(`/casino/v2/games/${id}`)
           if (response.data.success) {
               if(id === '11'){
                dispatch(allGames(response.data.result))
               } else if (id === '12'){
                dispatch(topGames(response.data.result))
               } else if (id === '14'){
                dispatch(liveGames(response.data.result))
               } else if (id === '13'){
                dispatch(tableGames(response.data.result))
               } else if (id === '15'){
                dispatch(pokerGames(response.data.result))
               } else if (id === '16'){
                dispatch(blackjackGames(response.data.result))
               } else if (id === '17'){
                dispatch(baccaratGames(response.data.result))
               } else if (id === '18'){
                dispatch(rouletteGames(response.data.result))
               } else if (id === '19'){
                dispatch(showsGames(response.data.result))
               } else if (id === '20'){
                dispatch(virtualGames(response.data.result))
               } 
            }
            return response
        } catch (e) {
            dispatch(getLiveCasinoError())
            const errorMsg = getErrorMessage(e)
            throw errorMsg
        }
    }
}

export const requestLiveCasinoLobby = () => async (dispatch, getState) => {
    const checkAuth = getState().auth

    if(checkAuth) {
        try {
            const response = await httpAuth.get(`/casino/v2/casino-lobby`)
           if (response.data.success) {
            dispatch(getLiveCasinoSuccess(response.data.result))
        }
        return response
        } catch (e) {
            dispatch(getLiveCasinoError())
        }
    } else {
        try {
            const response = await httpNotAuth.get(`/casino/v2/casino-lobby`)
           if (response.data.success) {
            dispatch(getLiveCasinoSuccess(response.data.result))
        }
        return response
        } catch (e) {
            dispatch(getLiveCasinoError())
        }
    }
}

export const requestLiveCasinoID = (id) => async (dispatch) => {
    const token = localStorage.getItem('id_token')
    // const history = useHistory()
    try {
        // const response = await http.get(`/casino/live/table/?gameId=EZUGI-lobby`)
        const response = await axios.get(BASE_DEV_URL + `/casino/live/table-w/${id}`, {
            headers: {
              Authorization: `${token}`,
            },
          })
       if (response.data.success) {

        // useHistory().push(response?.data?.result?.url)
        // window.open(response?.data?.result?.url)
        dispatch(getLiveCasinoIDSuccess(response.data.result))
    }
    return response
    } catch (e) {
        dispatch(getLiveCasinoIDError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}

