import React from 'react'
import "./BaccaratLastResult.scss"

const BaccaratLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  return (
      <div className="baccarat-last-result">
        <div className="baccarat-last-result__player baccarat-last-result__player-first">
          <div className="baccarat-last-result__player-name">Player</div>
          <div
            className={data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
              ? 'baccarat-last-result__player-cards-isWinner'
              : 'baccarat-last-result__player-cards'}>
            <img
              className="baccarat-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[0])}
              alt=""
            />
            <img
              className="baccarat-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[1])}
              alt=""
            />
            {data && data?.marketId?.marketRunner[0]?.cards[2] &&
              <img
                className="baccarat-last-result__player-cards-card rotated"
                src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[2])}
                alt=""
              />
            }
          </div>
        </div>
        <div className="baccarat-last-result__player">
          <div className="baccarat-last-result__player-name">Banker</div>
          <div
            className={data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
              ? 'baccarat-last-result__player-cards-isWinner'
              : 'baccarat-last-result__player-cards'}>
            <img
              className="baccarat-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[0])}
              alt=""
            />
            <img
              className="baccarat-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[1])}
              alt=""
            />
            {data && data?.marketId?.marketRunner[1]?.cards[2] &&
              <img
                className="baccarat-last-result__player-cards-card rotated"
                src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[2])}
                alt=""
              />
            }
          </div>
        </div>
      </div>
  )
}
export default BaccaratLastResult
