import React, { useEffect, useState } from "react";
import "./SportMarketStandard.scss";
import OddButton from "../OddButton/oddbutton";
import BetPlacing from "../BetPlacing/BetPlacing";
import { connectSportMarketStandard } from "./SportMarketStandard.connect";
import timerIcon from "../../assets/images/timer.svg";
import { useHistory } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import {
  addfavoritesSportMarkets,
  clearfavoritesSportMarkets,
  addFavoritesAll,
  clearFavoritesAll
} from "../../store/favorites/favorites.slice";

import StarIcon from "../../assets/images/sideBarStar.svg";
import StarIconFilled from "../../assets/images/sideBarStarFilled.svg";
import fasterBetAcceptanceIcon from "../../assets/images/fasterBetAcceptanceIcon.svg";
import noCommissionIcon from "../../assets/images/noCommissionIcon.svg";
import SportMarketStandardPopUp from "./SportMarketStandardPopUp/SportMarketStandardPopUp";
import { getCurrencyFormat } from "../../helpers/getCurrencyFormat.helper";
import { getPlacingText } from "../../helpers/betPlacing.helper";
import { toastr } from "react-redux-toastr";
import { requestBetPlacingStart } from "../../store/betPlacing/betPlacing.thunks";
import { setSportLadderId, setSportLadderShow, setSportLadderTitle } from "../../store/sportEvent/sportEvent.slice";
import { requestSportLadder } from "../../store/sportEvent/sportEvent.thunks";

const SportMarketStandard = ({
                               data,
                               betPlacing,
                               exposure,
                               actions,
                               isRacing = false,
                               showHeader = true,
                               showJockeyName = false,
                               sortRunners = false,
                               showWinner = false,
                               full,
                               elementsParams
                             }) => {
  const dispatch = useDispatch();
  const [besSettings, setBesSettings] = useState(null);
  const [cashOutLoading, setCashOutLoading] = useState(null);
  const isFancy = data.mtype == "INNINGS_RUNS" && data.btype == "LINE";

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth;

  useEffect(() => {
    console.log(data)
    if (isFancy && width >= 1024) {
      dispatch(requestBetPlacingStart(data?.id, data?.runners[0]?.id, true))
        .then((res) => setBesSettings(res?.data?.result))
    }
  }, [data.id, isFancy, width]);

  // console.log('sendCahsOutRequest', data);
  const sendCahsOutRequest = (marketId, delay) => {
    // console.log('delay', delay);
    actions.setDelay(delay);
    actions.setCashOutId(marketId);
    actions.setIsCashOut(true);
    actions.setBetPlacingPopoverStage(false);
    actions.setShowBetPlacingPopover(true);
    // actions.requestCashOut(marketId).then((res) => {
    //   actions.setShowBetPlacingPopover(false);
    //   const sizeMatched = res.data.result?.result?.placed?.[0].sizeMatched;
    //   if (sizeMatched > 0) {
    //     toastr.success("", "Cash-out successful");
    //   }
    //   // cancel()
    // }).catch((e) => {
    //   // cancel()
    //   actions.setShowBetPlacingPopover(false);
    //   if (e[0] && e[0][0] && e[0][0].description) toastr.error("", e[0][0].description);
    //   else toastr.error("", "Something went wrong");
    // });
  };

  const renderBetPlacing = (runner) => {
    const status = data.status;
    const statusLabel = data.statusLabel;
    if (
      betPlacing.market === data.id &&
      betPlacing.runner === runner.id &&
      status &&
      !status.localeCompare("OPEN") &&
      (!statusLabel || betPlacing.btype != "casino") &&
      width < 1024
    ) {
      return <BetPlacing />;
    }
    return null;
  };

  const [clicked, setClicked] = useState(null);
  const [winner, setWinner] = useState();

  const renderOdds = (item, runner) => {
    const status = data.status;
    const statusLabel = data.statusLabel;

    if (status && !!status.localeCompare("OPEN")) {
      return (
        <div className="SportEvent__market__status">{status.toUpperCase()}</div>
      );
    }
    if (!isFancy && width >= 1024) {
      return (
        <>
          {
            <OddButton
              market={data}
              isBack={true}
              oddData={{ ...item.back[2], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={2}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
          {
            <OddButton
              market={data}
              isBack={true}
              oddData={{ ...item.back[1], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={1}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
          <OddButton
            market={data}
            isBack={true}
            oddData={{ ...item.back[0], runner }}
            showEmpty
            centered
            showBlock={isRacing ? true : false}
            marketNum={0}
            setClicked={setClicked}
            clicked={clicked}
          />
          <OddButton
            market={data}
            isBack={false}
            oddData={{ ...item.lay[0], runner }}
            showEmpty
            centered
            showBlock={isRacing ? true : false}
            marketNum={0}
            setClicked={setClicked}
            clicked={clicked}
          />
          {
            <OddButton
              market={data}
              isBack={false}
              oddData={{ ...item.lay[1], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={1}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
          {
            <OddButton
              market={data}
              isBack={false}
              oddData={{ ...item.lay[2], runner }}
              showEmpty
              centered
              showBlock={isRacing ? true : false}
              mainBlock={false}
              marketNum={2}
              setClicked={setClicked}
              clicked={clicked}
            />
          }
        </>
      );
    }
    return (
      <>
        {statusLabel &&
        statusLabel == "Ball Running" &&
        data.mtype == "INNINGS_RUNS" ? (
          <div className="SportEvent__market__statuslabel">{statusLabel}</div>
        ) : null}
        {isFancy && (
          <OddButton
            market={data}
            isBack={false}
            oddData={{ ...item.lay[0], runner }}
            showEmpty
            centered
            isFancy={isFancy}
          />
        )}
        <OddButton
          market={data}
          isBack={true}
          oddData={{ ...item.back[0], runner }}
          showEmpty
          centered
          isFancy={isFancy}
        />
        {!isFancy && (
          <OddButton
            market={data}
            isBack={false}
            oddData={{ ...item.lay[0], runner }}
            showEmpty
            centered
          />
        )}
      </>
    );
  };

  const openLadder = (name, id) => {
    if (
      exposure &&
      exposure?.pnlBySelection &&
      exposure.pnlBySelection[data.id]
    ) {
      dispatch(setSportLadderShow(true))
      dispatch(setSportLadderTitle(name))
      dispatch(setSportLadderId(id))
      dispatch(requestSportLadder())
    }
  };

  const renderInningIcon = () => {
    if (data.mtype == "INNINGS_RUNS")
      return (
        <span
          onClick={() => openLadder(data.name, data.id)}
          className={`SportMarketStandard__inningIcon ${
            exposure &&
            exposure.pnlBySelection &&
            exposure.pnlBySelection[data.id]
              ? ""
              : "disabled"
          }`}
        />
      );
    return null;
  };

  const renderExposure = (runnerId) => {
    let number = 0,
      calced;
    // const isFancy = data.mtype == "INNINGS_RUNS" && data.btype == "LINE"
    const pnlId = isFancy ? data.id : `${data.id}-${runnerId}`;
    if (exposure && exposure.pnlBySelection && exposure.pnlBySelection[pnlId])
      number = exposure.pnlBySelection[pnlId].pnl;
    if (betPlacing.market === data.id) {
      if (betPlacing.runner === runnerId) {
        if (betPlacing.isBack) {
          // const profit = isFancy ? (betPlacing.stake * betPlacing.priceActual / 100).toFixed(2) : (betPlacing.stake * betPlacing.priceActual - betPlacing.stake).toFixed(2)
          const profit = isFancy
            ? betPlacing.stake
            : (
              betPlacing.stake * betPlacing.priceActual -
              betPlacing.stake
            ).toFixed(2);
          calced = (isFancy ? 0 : number) + Number(profit) * (isFancy ? -1 : 1);
        } else {
          const profit = isFancy
            ? betPlacing.stake * (betPlacing.priceActual / 100)
            : (
              betPlacing.stake * betPlacing.priceActual -
              betPlacing.stake
            ).toFixed(2);
          calced = (isFancy ? 0 : number) + Number(profit) * -1;
        }
      } else {
        calced = betPlacing.isBack
          ? number - Number(betPlacing.stake)
          : number - Number(betPlacing.stake) * -1;
        // calced = number - Number(betPlacing.stake)
      }
    }

    const preCalced = isFancy && number == 0 ? "Max exposure: " : "";
    if (number || calced)
      return (
        <div className="SportEvent__market__title__exposure">
          {number ? (
            <div className={`${number > 0 ? "positive" : "negative"}`}>
              {getCurrencyFormat(number)}
            </div>
          ) : null}
          {preCalced}
          {(calced || (number && calced == 0)) && (
            <div className={`${calced > 0 ? "positive" : "negative"}`}>
              » {getCurrencyFormat(calced)}
            </div>
          )}
        </div>
      );
    return null;
  };

  // get eventTypeId
  const history = useHistory();
  const eventTypeId = history?.location?.pathname?.split("/")[2];
  // const eventId = history?.location?.pathname?.split("/")[3];
  // end get eventTypeId

  const [activeIcon, setActiveIcon] = useState();

  const toggleStar = () => {
    setActiveIcon((prevCheck) => !prevCheck);
  };

  const favoritesState = useSelector((state) => state.favorites);

  localStorage.setItem("favorites", JSON.stringify(favoritesState));

  // const isFavorite = () => {
  //   if (
  //     favoritesState?.sportMarkets?.length > 0 &&
  //     data &&
  //     favoritesState?.sportMarkets?.filter((el) => el?.id === data?.id).length
  //   ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  const isFavorite = () => {
    if (
      favoritesState?.favoritesAll?.length > 0 &&
      data &&
      favoritesState?.favoritesAll?.filter((el) => el?.id === data?.id).length
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isFavorite()) {
      setActiveIcon(true);
    } else setActiveIcon(false);
  }, [favoritesState, data]);

  // const handleFavorite = (id, data) => {
  //   // console.log('favitem', item);
  //   if (activeIcon) {
  //     console.log('if active clear')
  //      dispatch(clearfavoritesSportMarkets(data))
  //   } else {
  //     dispatch(addfavoritesSportMarkets(data))
  //   }
  // }

  const handleFavorite = (id, item) => {
    if (activeIcon) {
      console.log("if active clear");
      dispatch(clearFavoritesAll(item));
    } else {
      console.log("else add");
      dispatch(addFavoritesAll(item));
    }
  };

  const gotoEvent = () => {
    if (data.eventTypeId == "77777")
      history.push(`/casino_live/${data.event.id}`);
    else history.push(`/event-page/${data.eventTypeId}/${data.event.id}`);
  };
  const [openPopUp, setOpenPopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState(null);
  // console.log('popUpTitle', popUpTitle && popUpTitle);

  // let finish = new Date(data?.start)

  const calcMinutes = (time) => {
    return ((new Date(time).getTime() - new Date().getTime()) / 60000).toFixed(0);
  };


  if (width >= 1024) {
    return (
      <section
        className={`SportMarketStandard ${
          data?.runners?.length < 2 ? "short" : ""
        } ${
          data?.eventTypeId === "1" ||
          data?.eventTypeId === "2378962" ||
          data?.eventTypeId === "2" ||
          data?.eventTypeId === "4"
            ? "moreOdds"
            : ""
        } `}
      >
        {showHeader && !isFancy && (
          <div className="SportMarketStandard__header">
            <div
              className={`SportEvent__market__title ${
                data?.betDelay >= 1 ? "withDelay" : ""
              }`}
            >
              {history.location.pathname === "/favouritesPage" ? (
                <img style={{ marginLeft: "0", cursor: "pointer" }}
                     onClick={() => {
                       // dispatch(clearfavoritesSportMarkets(data))
                       dispatch(clearFavoritesAll(data));
                       // console.log('data', data && data)
                     }}
                     className="starImg"
                     src={StarIconFilled}
                     alt="star icon"
                />
              ) : (
                <img style={{ marginLeft: "0", cursor: "pointer" }}
                     onClick={() => {
                       toggleStar(data);
                       handleFavorite(data.id, data);
                       console.log("data", data && data);
                     }}
                     className="starImg"
                     src={activeIcon ? StarIconFilled : StarIcon}
                     alt="star icon"
                />
              )}
              <span style={{ cursor: "pointer" }} className="SportEvent__market__title-name" onClick={gotoEvent}>
              {data?.name === "BOOKMAKER" ? "Bookmaker" : data?.id?.includes("BR") && data?.name.includes("1st innings o") ? data?.name.replace("1st innings o", "O") : data?.id?.includes("BR") && data?.name.includes("1st innings -") ? data?.name : data?.name}
              </span>
              {data?.runners?.length > 0 && !data?.id.endsWith("_BR") && !data?.id.endsWith("SB") && !data?.id.endsWith("_BR") &&
                <button className="cashOutButton" onClick={() => sendCahsOutRequest(data.id, data.betDelay)}> CASH
                  OUT</button>}


              {data?.betDelay >= 1 ? (
                data && data?.id?.includes("_SB") ?
                  (<div className="SportMarketStandard__title-timer">
                    <div className="hover-before">
                      <img
                        className="SportMarketStandard__title-timer-fasterBetAcceptanceIcon"
                        src={fasterBetAcceptanceIcon}
                        alt="faster Bet Acceptance Icon"
                      />
                    </div>

                    <div className="hover-after">
                      <img
                        className="SportMarketStandard__title-timer-noCommissionIcon"
                        src={noCommissionIcon}
                        alt="no Commission Icon"
                      />
                    </div>
                  </div>) :
                  (<div className="SportMarketStandard__title-timer">
                    <img
                      className="SportMarketStandard__title-timer-img"
                      src={timerIcon}
                      alt="timer icon"
                    />
                    <span className="SportMarketStandard__title-timer-delay">
                  {data.betDelay}s
                </span>
                  </div>)
              ) : null}
            </div>

            {data?.status !== "SUSPENDED" &&
              (data?.eventTypeId === "1" ||
                data?.eventTypeId === "2" ||
                data?.eventTypeId === "4" ||
                data?.eventTypeId === "7" ||
                data?.eventTypeId === "4339" ||
                data?.eventTypeId === "2378962"
              ) &&
              <div className="SportMarketStandard__header__action-wrapper">
                <div className="SportMarketStandard__header__action">Back</div>
                <div className="SportMarketStandard__header__action">Lay</div>
              </div>
            }
          </div>
        )}
        <div className="SportMarketStandard__markets">
          {data?.status === "SUSPENDED" &&
            (data?.eventTypeId === "1" ||
              data?.eventTypeId === "2" ||
              data?.eventTypeId === "4" ||
              data?.eventTypeId === "7" ||
              data?.eventTypeId === "2378962" ||
              data?.eventTypeId === "4339") && (
              <div className="SportMarketStandard__markets-suspended">
                <span>SUSPENDED</span>
              </div>
            )}
          {/*{console.log('event data ', sortRunners ? data?.runners?.slice().sort((a, b) => a.sort - b.sort).slice(0, (full ? undefined : 3)) :  data?.runners)}*/}
          {(sortRunners
              ? data?.runners
                ?.slice()
                .sort((a, b) => a.sort - b.sort)
                .slice(0, full ? undefined : 3)
              : data?.runners
          )?.map((item) => {
            // console.log('runner', item)
            const runner = {
              id: item?.id,
              name: item?.name
            };
            return (
              <div key={item?.id} className="SportEvent__market">
                <div
                  className={`SportEvent__market__row ${data.status.toUpperCase()}  ${
                    item?.status
                  } ${item?.metadata?.JOCKEY_NAME ? "showJockeyName" : ""}`}
                >
                  <div className="SportEvent__market__title">
                    <div
                      className={`SportEvent__market__title__text ${
                        data?.betDelay ? "withDelay" : ""
                      }`}
                    >
                      <div>
                        <p>
                          {item.metadata?.CLOTH_NUMBER && (
                            <span className={`SportEvent__market-item-number`}>
                              {item.metadata?.CLOTH_NUMBER &&
                                item.metadata?.CLOTH_NUMBER}
                            </span>
                          )}
                          <span className="SportEvent__market-item-name">
                            {item?.name}
                          </span>
                          {data?.tabGroupName === "Fancy" && data?.betDelay && (
                            <div className="SportMarketStandard__title-timer">
                              <img
                                className="SportMarketStandard__title-timer-img"
                                src={timerIcon}
                                alt="timer icon"
                              />
                              <span className="SportMarketStandard__title-timer-delay">
                                {data.betDelay}s
                              </span>
                            </div>
                          )}
                        </p>
                        {showJockeyName && item?.metadata?.JOCKEY_NAME && (
                          <p>
                            {item.metadata?.STALL_DRAW && (
                              <span
                                className={`SportEvent__market-item-number`}
                              >
                                (
                                {item.metadata?.STALL_DRAW &&
                                  item.metadata?.STALL_DRAW}
                                )
                              </span>
                            )}
                            <span
                              className={`jockeyName__name  ${
                                !item.metadata?.STALL_DRAW &&
                                showJockeyName &&
                                item?.metadata?.JOCKEY_NAME
                                  ? "STALL_DRAW-missed"
                                  : ""
                              } `}
                            >
                              {showJockeyName &&
                                item?.metadata?.JOCKEY_NAME &&
                                item?.metadata?.JOCKEY_NAME}
                              {showJockeyName &&
                                !item?.metadata?.JOCKEY_NAME &&
                                eventTypeId &&
                                eventTypeId === "7" &&
                                "Non Runner"}
                            </span>
                          </p>
                        )}
                      </div>
                      {renderExposure(runner?.id)}
                    </div>
                    {item?.status === "WINNER" && (
                      <span className={`SportEvent__market-showStatus`}>
                        Winner
                      </span>
                    )}
                    {item?.status === "REMOVED" && eventTypeId && eventTypeId === "4339" &&
                      <span className={`SportEvent__market-showStatus greyhound-removed`}>Removed</span>}
                    {item?.status === "REMOVED" &&
                      eventTypeId &&
                      eventTypeId === "7" &&
                      data?.status !== "SUSPENDED" && (
                        <span
                          className={`SportEvent__market-showStatus horse-removed`}
                        >
                          Non Runner {item?.adjustmentFactor?.toFixed(2)}%

                          <span
                            style={{
                              display: "inline-block",
                              marginLeft: "8px"
                            }}>
                            {new Date(item?.removalDate).toLocaleString("en-GB", {
                              weekday: "short",
                              day: "numeric",
                              month: "short",
                              hour: "numeric",
                              minute: "numeric"
                            }).replaceAll(",", " ")}
                          </span>
                        </span>
                      )}
                    {renderInningIcon()}
                  </div>
                  {/*{renderOdds(item, runner)}*/}
                  {isRacing && item && item?.status === "ACTIVE"
                    ? renderOdds(item, runner)
                    : ""}
                  {/*{item.status === "REMOVED" &&*/}
                  {/*  <div className="SportEvent__market-showStatus-wrapper">*/}
                  {/*    <span className={`SportEvent__market-showStatus removed`}>{item?.metadata?.JOCKEY_NAME} {item?.adjustmentFactor?.toFixed(2)}%</span>*/}
                  {/* </div>*/}
                  {/*}*/}
                  {!isRacing && (
                    <div
                      className={`SportEvent__market__odds-wrapper ${
                        isFancy ? "fancy" : ""
                      }`}
                    >
                      {renderOdds(item, runner)}
                      {isFancy && (
                        <div className="SportEvent__market__odds-wrapper-max-bets">
                          {besSettings && (
                            <span>Max Bet: {getCurrencyFormat(besSettings?.maxBet)}</span>
                          )}
                          {besSettings && (
                            <span>Max Mkt: {getCurrencyFormat(besSettings?.maxWinPerMarket)}</span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {renderBetPlacing(runner)}
              </div>
            )
          })}
        </div>
      </section>
    )
  }

  return (
    <section
      className={`SportMarketStandard ${
        data?.runners?.length < 2 ? 'short' : ''
      }`}
    >
      {showHeader && !(data?.mtype == 'INNINGS_RUNS' && data?.btype == 'LINE') && (
        <div className="SportMarketStandard__header">
          {/* <div className="SportEvent__market__title">{data?.name}</div> */}
          <div
            className={`SportEvent__market__title ${
              data?.betDelay >= 1 ? 'withDelay' : ''
            }`}
          >
            <span className="SportEvent__market__title-name">
            {data?.name === "BOOKMAKER" ? "Bookmaker" : data?.name === "MINI BOOKMAKER" ? "Mini Bookmaker" : data?.id?.includes("BR") && data?.name.includes("1st innings o") ? data?.name.replace("1st innings o", "O") : data?.id?.includes("BR") && data?.name.includes("1st innings -") ? data?.name : data?.name.includes("Winner (incl. super over") ? data?.name.replace("Winner (incl. super over)", "Who will win the match ?") : data?.name.includes("1x2") ? data?.name.replace("1x2", "Who will win the match ?") : data?.name}
            </span>
            {data?.runners?.length === 2 && !data?.id.endsWith("_BR") && !data?.id.endsWith("SB") && !data?.id.endsWith("_BR") &&
              <button className="cashOutButton" onClick={() => sendCahsOutRequest(data.id, data.betDelay)}> CASH
                OUT</button>}
            {!data?.name.includes('Winner (incl. super over') || !data?.name === '1x2' ? null :
            data?.betDelay >= 1 ? (
              data && data?.id?.includes('_SB') ?
                (<div className="SportMarketStandard__title-timer">
                  <img
                    className="SportMarketStandard__title-timer-fasterBetAcceptanceIcon"
                    src={fasterBetAcceptanceIcon}
                    alt="faster Bet Acceptance Icon"
                    onClick={() => {
                      setOpenPopUp(true)
                      setPopUpTitle("Faster bet acceptance")
                    }}
                  />
                  <img
                    className="SportMarketStandard__title-timer-noCommissionIcon"
                    src={noCommissionIcon}
                    alt="no Commission Icon"
                    onClick={() => {
                      setOpenPopUp(true)
                      setPopUpTitle("No commission")
                    }}
                  />
                </div>) :
                (<div className="SportMarketStandard__title-timer">
                <img
                  className="SportMarketStandard__title-timer-img"
                  src={timerIcon}
                  alt="timer icon"
                />
                <span className="SportMarketStandard__title-timer-delay">
                  {data.betDelay}s
                </span>
              </div>)
            ) : null}
          </div>
          {data?.name === '1x2' || data?.name.includes('Winner (incl. super over') || data?.name.includes('To Win The Toss') ?
          null
          :
          <>
            <div className="SportMarketStandard__header__action">Back</div>
            <div className="SportMarketStandard__header__action">Lay</div>
          </>}
        </div>
      )}

      {/*{(data?.name.includes('Winner (incl. super over') || data?.name.includes('1x2')) && calcMinutes(data?.start) >= 0 && calcMinutes(data?.start) <= 59 && <div className='SportMarketStandard__timer'>Starts in {calcMinutes(data?.start)} mins</div>}*/}
      {/*{(data?.name.includes('Winner (incl. super over') || data?.name.includes('1x2')) && calcMinutes(data?.start) > 59 && <div className='SportMarketStandard__timer'>{new Date(data?.start).toLocaleString("en-GB")}</div>}*/}
      {/* {data?.name.includes('1x2') && calcMinutes(data?.start) >= 0 && calcMinutes(data?.start) <= 59 && <div className='SportMarketStandard__timer'>Starts in {calcMinutes(data?.start)} mins</div>}
      {data?.name.includes('1x2') && calcMinutes(data?.start) > 59 && <div className='SportMarketStandard__timer'>{new Date(data?.start).toLocaleString("en-GB")}</div>} */}

      {data?.name.includes('Winner (incl. super over') || data?.name === '1x2' || data?.name.includes('To Win The Toss') ?
      <>
      <div className="SportMarketStandard__winner">
        {(sortRunners
          ? data?.runners
              ?.slice()
              .sort((a, b) => a.sort - b.sort)
              .slice(0, full ? undefined : 3)
          : data?.runners
        )?.map((item) => {
          const runner = {
            id: item?.id,
            name: item?.name,
          }
          return (

        <div key={runner.id}>
          <div style={data?.name.includes('Winner (incl. super over') ? {width: '145px'} : data?.name.includes('To Win The Toss') ?  {width: '145px'} : {}}className='SportMarketStandard__winner-block'>
            <div className='SportMarketStandard__winner-block-name'>{runner.name}</div>
            {!item?.back[0]?.price ?
              <div className={`SportMarketStandard__winner-block${data?.name.includes('Winner (incl. super over') ? '-suspended' : data?.name.includes('To Win The Toss') ? '-suspended' : '-suspended1'}`}>
                <span>SUSPENDED</span>
              </div>
            :
            <div className={`SportMarketStandard__winner-block${data?.name.includes('Winner (incl. super over') ? '-odds' : data?.name.includes('To Win The Toss') ? '-odds' : '-odds1'}`} onClick={() => setWinner(item)}>
              <OddButton
                market={data}
                isBack={true}
                oddData={{...item.back[0], runner}}
                showEmpty
                centered
              />
            </div>
            }
          </div>
          <div className={`SportMarketStandard__winner-block${data?.name.includes('Winner (incl. super over') ? '-exposure' : data?.name.includes('To Win The Toss') ? '-exposure' : '-exposure1'}`}>
            {renderExposure(runner?.id)}
          </div>
        </div>
          )}
          )}
      </div>
      {renderBetPlacing(winner)}
      </>
      : <div className="SportMarketStandard__markets">
        {/*{console.log('event data ', data)}*/}
        {(sortRunners
          ? data?.runners
              ?.slice()
              .sort((a, b) => a.sort - b.sort)
              .slice(0, full ? undefined : 3)
          : data?.runners
        )?.map((item) => {
          const runner = {
            id: item?.id,
            name: item?.name,
          }
          return (
            <div key={item?.id} className="SportEvent__market">
              <div
                className={`SportEvent__market__row ${data.status.toUpperCase()}  ${
                  item?.status
                } ${item?.metadata?.JOCKEY_NAME ? 'showJockeyName' : ''}`}
              >
                <div className="SportEvent__market__title">
                  <div className={`SportEvent__market__title__text `}>
                    <div>
                      <p>
                        {item.metadata?.CLOTH_NUMBER && (
                          <span className={`SportEvent__market-item-number`}>
                            {item.metadata?.CLOTH_NUMBER &&
                              item.metadata?.CLOTH_NUMBER}
                          </span>
                        )}
                        <span>{item?.name}</span>
                        {item.status === 'REMOVED' && (
                          <span>{item?.status}</span>
                        )}
                      </p>
                      {showJockeyName && item?.metadata?.JOCKEY_NAME && (
                        <p>
                          {item.metadata?.STALL_DRAW && (
                            <span className={`SportEvent__market-item-number`}>
                              (
                              {item.metadata?.STALL_DRAW &&
                                item.metadata?.STALL_DRAW}
                              )
                            </span>
                          )}
                          <span
                            className={`jockeyName__name ${
                              !item.metadata?.STALL_DRAW &&
                              showJockeyName &&
                              item?.metadata?.JOCKEY_NAME
                                ? 'STALL_DRAW-missed'
                                : ''
                            } `}
                          >
                            {showJockeyName &&
                              item?.metadata?.JOCKEY_NAME &&
                              item?.metadata?.JOCKEY_NAME}
                          </span>
                        </p>
                      )}
                    </div>
                    {renderExposure(runner?.id)}
                  </div>
                  {item?.status === 'WINNER' && (
                    <span className={`SportEvent__market-showStatus`}>
                      Winner
                    </span>
                  )}
                  {data?.tabGroupName === 'Fancy' && data?.betDelay && (
                    <div className="SportMarketStandard__title-timer">
                      <img
                        className="SportMarketStandard__title-timer-img"
                        src={timerIcon}
                        alt="timer icon"
                      />
                      <span className="SportMarketStandard__title-timer-delay">
                        {data.betDelay}s
                      </span>
                    </div>
                  )}
                  {renderInningIcon()}
                </div>
                {/*{renderOdds(item, runner)}*/}
                {isRacing && item && item?.status === 'ACTIVE'
                  ? renderOdds(item, runner)
                  : ''}
                {!isRacing && renderOdds(item, runner)}
              </div>
              {renderBetPlacing(runner)}
            </div>
          )
        })}
      </div>}

      {openPopUp &&
        <SportMarketStandardPopUp
          onDidDismiss={() => setOpenPopUp(false)}
          title={popUpTitle}
        />}
    </section>
  )
}

export default connectSportMarketStandard()(SportMarketStandard)
