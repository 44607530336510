import React from 'react'
import "./AmarAkbarAnthonyLastResult.scss"

const AmarAkbarAnthonyLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  return (
    <div className="BollywoodCasinoLastRes__results">
      <div className='BollywoodCasinoLastRes__results-cards'>
        <img className="BollywoodCasinoLastRes__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[0])}/>
      </div>
  </div>
  )
}
export default AmarAkbarAnthonyLastResult
