import React, { useEffect } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { IonItemGroup, IonList, IonLabel, IonItem } from "@ionic/react";
import './RaceEventDropdown.scss';
import { useHistory, useParams } from 'react-router';
import {
    GET_INPLAY_POPULAR_INTERVAL
} from '../../config/app.config';
import { getFormattedDate, tsToDate } from '../../helpers/date.helper'

import { connectRaceOdds, connectRaceEventDropdown } from './connect';
import RaceInPlayItem from '../RaceInPlayItem/RaceInplayitem';
import { timeOpens } from '../../helpers/date.helper'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import SportMarketStandard from '../SportMarketStandard/SportMarketStandard'

const RaceEventDropdownHeader = ({ className = "", race, flag, isOpen, setOpen, openEventPage, timeSettings = {}}) => {

    const time = new Date(race.bettableTime);
    const timeTs = time.getTime()
    return <div className={`RaceEventDropdown__header ${isOpen ? "open" : ""} ${className}`}>
        <div className="RaceEventDropdown__header__title" onClick={openEventPage}>
            <IonLabel className={`f32 flag-row ${className}`}>
                <div className={`flag ${flag}`}></div>
                <div className="RaceEventDropdown__header__event">
                    <span className="RaceEventDropdown__header__event__venue">{race.venue}</span>
                    <span className="RaceEventDropdown__header__event__time">{getFormattedDate(race.startTime, timeSettings.timeZoneName).time}</span>
                </div>
            </IonLabel>
        </div>
        {timeTs > Date.now()
        &&
        <span className={`RaceEventDropdown__header__time`}>
                ꀔ
                </span>}
        <div className={`RaceEventDropdown__header__arrowContainer ${timeTs > Date.now() ? "with-time" : ""}`} onClick={setOpen}>
            <span className={`RaceEventDropdown__header__arrow ${isOpen ? "open" : ""}`}>
                &nbsp;
            </span>
        </div>
    </div>
}

export const RaceEventDropdownList = connectRaceOdds()(({ isRacing, children, oddId, lastActivityDate, actions, elementsParams, race, raceOdds, isOpen, full, balanceSetting, betPlacing, timeSettings, showPageHeader = false, showRunnersLengthInHeader= false, setRacingEventHeader, showJockeyName, showWinner, ...props }) => {

    //window width
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
    //end window width

    const history = useHistory();
    const { id, eventTypeId } = useParams();
    useEffect(() => {
        let mainTimer
        if (oddId) {
            if (!raceOdds) actions.requestRaceOdds((id || eventTypeId), oddId);
            mainTimer = setIntervalAsync(async () => {
                if (isOpen && 
                    (history.location.pathname.indexOf(`/menu-event/${eventTypeId}`) !== -1 
                        || history.location.pathname.indexOf(`/event-page/${eventTypeId}`) !== -1)
                    ) await actions.requestRaceOdds((id || eventTypeId), oddId);
            }, GET_INPLAY_POPULAR_INTERVAL);
            if (!isOpen) {
                clearIntervalAsync(mainTimer);
            }
        }
        return () => clearIntervalAsync(mainTimer);
    }, [id, isOpen, oddId, history]);

    useEffect(() => {
        if(showPageHeader) {
            if(eventTypeId == 7) {
                setRacingEventHeader('Horse Racing')
            }
            if(eventTypeId == 4339) {
                setRacingEventHeader('Greyhound Racing')
            }
        }
    },[raceOdds, eventTypeId])

    useEffect(() => {
        if (isOpen && history.location.pathname.indexOf(`/menu-event/${eventTypeId}`) !== -1 || history.location.pathname.indexOf(`/event-page/${eventTypeId}`) !== -1) actions.requestRaceExposure(oddId);
    }, [oddId, eventTypeId, history.location.pathname, lastActivityDate, isOpen]);

     const altName = () => raceOdds?.[`${eventTypeId} ${oddId}`]?.altName ? <div className="EventHeader__details__additional">{raceOdds[`${eventTypeId} ${oddId}`].altName}</div> : null

    return <IonItemGroup className={`RaceEventDropdown__list ${props.className}`}>
        {width > 1024 && showPageHeader && raceOdds && raceOdds[`${eventTypeId} ${oddId}`]?.event?.name &&
            <div className="racing-header">
                <div style={{ display: 'flex' }}>
                 <h1>
                    {(tsToDate(raceOdds[`${eventTypeId} ${oddId}`]?.start, timeSettings?.timeZoneName).split(" ")[1]).split(":", 2).join(":") }&nbsp;
                    {raceOdds[`${eventTypeId} ${oddId}`]?.event?.name?.replace("${HH:mm}", "")}
                 </h1>
                </div>
                
                { altName()}
            </div>
        }
        {width > 1024 ? <div className="RaceEventDropdown__list__desc">
             <div className="RaceEventDropdown__top-table-header">
                {
                    raceOdds && raceOdds[`${eventTypeId} ${oddId}`]
                      ? raceOdds[`${eventTypeId} ${oddId}`].groupById == oddId && race && showRunnersLengthInHeader &&
                      <>
                          <p>Top 3 of {raceOdds[`${eventTypeId} ${oddId}`].runners?.length} runners {timeOpens(race.bettableTime, true)}</p>
                          <div>
                              <span>Back</span>
                              <span>Lay</span>
                          </div>
                      </>
                      : ""
                    
                }
                {
                    raceOdds && raceOdds[`${eventTypeId} ${oddId}`] && !showRunnersLengthInHeader &&
                      <>
                          <p>Race odds</p>
                          <div>
                              <span>Back</span>
                              <span>Lay</span>
                          </div>
                      </>
                }
            </div>

        </div> :
        <div style={{marginBottom: '4px'}}></div>
        }
        <IonList className="RaceEventDropdown__list__ionlist">
            {
                raceOdds && raceOdds[`${eventTypeId} ${oddId}`]
                  ?
                  <>
                      {width > 1024
                        ?
                            raceOdds && raceOdds[`${eventTypeId} ${oddId}`] &&
                            <SportMarketStandard data={raceOdds[`${eventTypeId} ${oddId}`]}
                                                 key={raceOdds?.id}
                                                 betPlacing={betPlacing}
                                                 showHeader={false}
                                                 showJockeyName={showJockeyName}
                                                 sortRunners={true}
                                                 showWinner={showWinner}
                                                 full={full}
                                                 isRacing={isRacing}/>
                        :
                            raceOdds[`${eventTypeId} ${oddId}`].runners.slice().sort((a, b) => a.sort - b.sort).slice(0, (full ? undefined : 3)).map(runner => {
                                  return <RaceInPlayItem key={runner.id}
                                                         className={runner.status == "ACTIVE" || runner.status == "WINNER" ? "active" : "inactive"}
                                                         raceOdds={raceOdds[`${eventTypeId} ${oddId}`]} itemData={runner}
                                                         balanceSetting={balanceSetting} />
                          }
                        )
                      }
                  </>
                  :
                  <LoaderContent />
            }
        </IonList>
    </IonItemGroup>
})

const RaceEventDropdown = ({ race, oddId, flag, isOpen, setOpen, openEventPage, balanceSetting, timeSettings, betPlacing, showRunnersLengthInHeader, showJockeyName, ...props }) => {

    return <IonItem {...props} className={`RaceEventDropdown ${props.className}`}>
        <div className="RaceEventDropdown__container">

            <RaceEventDropdownHeader
              flag={flag}
              race={race}
              isOpen={isOpen}
              openEventPage={openEventPage}
              setOpen={setOpen}
              timeSettings={timeSettings}
            />
            {isOpen && <RaceEventDropdownList race={race} isOpen oddId={oddId} balanceSetting={balanceSetting} showRunnersLengthInHeader={showRunnersLengthInHeader} showJockeyName />}
        </div>
    </IonItem>
}

export default connectRaceEventDropdown()(RaceEventDropdown)
