import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestSearchResults } from '../../store/search/search.thunks'

export const connectSearchDesktop = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      search: state.search,
      menu: state.menu
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSearchResults
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}