import { connect } from 'react-redux'

export const connectRouletteLiveResult = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      exchangeGames: state.exchangeGames,
    }
  }

  return connect(mapStateToProps)(component)
}
