import "./CasinoWarPL.scss";
import React, { useEffect, useState } from 'react'

const CasinoWarPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {
     winners: ''
   };
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER' && runner.name !== 'Dealer' ) {
           winObj['winners'] = [...winObj['winners'], runner.name.slice(6, 8)];
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="casino-war-betting">
      <div className="casino-war-betting__games">
      <div className="casino-war-betting__games-item">
      <span className={`casino-war-betting__games-item-title ${roundMarkets[0].marketRunner[0]?.status === 'WINNER' ? 'winnerCup':''}`}>
        1
      </span>
    <div className="casino-war-betting__games-item-cards">
      {roundMarkets && roundMarkets[0].marketRunner[0].cards.map( card =>
        <img className="hi-low-last-result__player-cards-card"
          key={card}
          src={cardSrc(card)}
          alt={card}
        />
      )}
    </div>
</div>
    <div className="casino-war-betting__games-item">
      <span className={`casino-war-betting__games-item-title ${roundMarkets[1].marketRunner[0]?.status === 'WINNER' ? 'winnerCup':''}`}>
        2
      </span>
        <div className="casino-war-betting__games-item-cards">
          {roundMarkets && roundMarkets[1].marketRunner[0].cards.map( card =>
            <img className="hi-low-last-result__player-cards-card"
                 key={card}
                 src={cardSrc(card)}
                 alt={card}
            />
          )}
        </div>
    </div>
    <div className="casino-war-betting__games-item">
      <span className={`casino-war-betting__games-item-title ${roundMarkets[2].marketRunner[0]?.status === 'WINNER' ? 'winnerCup':''}`}>
        3
      </span>
        <div className="casino-war-betting__games-item-cards">
          {roundMarkets && roundMarkets[2].marketRunner[0].cards.map( card =>
            <img className="hi-low-last-result__player-cards-card"
                 key={card}
                 src={cardSrc(card)}
                 alt={card}
            />
          )}
        </div>
    </div>
    <div className="casino-war-betting__games-item">
      <span className={`casino-war-betting__games-item-title ${roundMarkets[3].marketRunner[0]?.status === 'WINNER' ? 'winnerCup':''}`}>
        4
      </span>
        <div className="casino-war-betting__games-item-cards">
          {roundMarkets && roundMarkets[3].marketRunner[0].cards.map( card =>
            <img className="hi-low-last-result__player-cards-card"
                 key={card}
                 src={cardSrc(card)}
                 alt={card}
            />
          )}
        </div>
    </div>
    <div className="casino-war-betting__games-item">
      <span className={`casino-war-betting__games-item-title ${roundMarkets[4].marketRunner[0]?.status === 'WINNER' ? 'winnerCup':''}`}>
        5
      </span>
        <div className="casino-war-betting__games-item-cards">
          {roundMarkets && roundMarkets[4].marketRunner[0].cards.map( card =>
            <img className="hi-low-last-result__player-cards-card"
                 key={card}
                 src={cardSrc(card)}
                 alt={card}
            />
          )}
        </div>
    </div>
    <div className="casino-war-betting__games-item">
      <span className={`casino-war-betting__games-item-title ${roundMarkets[5].marketRunner[0]?.status === 'WINNER' ? 'winnerCup':''}`}>
        6
      </span>
        <div className="casino-war-betting__games-item-cards">
          {roundMarkets && roundMarkets[5].marketRunner[0].cards.map( card =>
            <img className="hi-low-last-result__player-cards-card"
                 key={card}
                 src={cardSrc(card)}
                 alt={card}
            />
          )}
        </div>
    </div>
    <div className="casino-war-betting__games-item">
      <span className="casino-war-betting__games-item-title dealer">
        D
      </span>
        <div className="casino-war-betting__games-item-cards">
          {roundMarkets && roundMarkets[0].indexCard.map( card =>
            <img className="hi-low-last-result__player-cards-card"
                 key={card}
                 src={cardSrc(card)}
                 alt={card}
            />
          )}
        </div>
    </div>
</div>
    <div className="casino-war-betting__info-column">
        <div className="casino-war-betting__info-row">
            <div className="casino-war-betting__info-row-title">
                <span className="casino-war-betting__info-title-text">Winner:</span>
            </div>
            <div className="casino-war-betting__info-row-results">
              { winnerData && winnerData['winners'].length > 1 &&
                <span className="casino-war-betting__info-results-text">
                  Player {winnerData['winners'].slice(0, -1)} and {winnerData['winners'].slice(-1)}
                </span>
              }
              { winnerData && winnerData['winners'].length === 1 &&
              <span className='casino-war-betting__info-results-text'>
                Player {winnerData['winners']}
              </span>
              }
              { winnerData &&  winnerData['winners'].length < 1 &&
                <span className='casino-war-betting__info-results-text'>
                  No Winner
                </span>
              }
            </div>
</div>
</div>
</div>


);
};

export default CasinoWarPL;

