import React from 'react'
import { connectBaccarat } from '../../Baccarat.connect'
import './BaccaratCards.scss'

const BaccaratCards = ({ exchangeGames }) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }
      return (
        <div className='baccarat-cards'>
          <div className="baccarat-cards__player first-player">
            <div className='baccarat-cards__player-name'>Player</div>
            <div className="baccarat-cards__player-cards">
                {exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[2] &&
                  <img className="baccarat-cards__player-cards-card rotated"
                       src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[2])} alt="" />
                }
                <img className="baccarat-cards__player-cards-card"
                  src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0])} alt=""
                />
                <img
                  className="baccarat-cards__player-cards-card"
                  src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1])} alt=""
                />
              </div>
          </div>
          <div className="baccarat-cards__player">
            <div className='baccarat-cards__player-name'>Banker</div>
            <div className="baccarat-cards__player-cards">
              <img
                className="baccarat-cards__player-cards-card"
                src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0])} alt=""
              />
              <img
                className="baccarat-cards__player-cards-card"
                src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1])} alt=""
              />
              {exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[2] &&
                <img className="baccarat-cards__player-cards-card rotated"
                     src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[2])} alt="" />
              }
            </div>
          </div>
        </div>
      )
  }
export default connectBaccarat()(BaccaratCards)
