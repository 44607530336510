import React, { useEffect, useRef, useState } from "react";
import http from '../../services/httpAuth'
import { timeToDate, tsToDate } from '../../helpers/date.helper'
import { connectRacingPage } from './RacingPage.connect'
import SportMarketStandard from '../../components/SportMarketStandard/SportMarketStandard'
import { IonContent } from '@ionic/react'
import './RacingPage.scss'
import UpcomingRacesAccordion from '../../components/UpcomingRacesAccordion/UpcomingRacesAccordion'
// import getErrorMessage from '../../helpers/getErrorMessage.helper'
// import { requestRacingPageData } from '../../store/racingPage/racingPage.thunks'
// import { useSelector } from 'react-redux'
// import {useHistory} from "react-router";

const RacingPage = ({timeSettings, navigationHeaderMenu, betPlacing, actions, racingPage}) => {

  //states
  const [raceOdds, setRaceOdds] = useState(null)
  const [upcomingRaces, setUpcomingRaces] = useState([])
  const [isAccordionOpen, setIsAccordionOpen] = useState(1)
  const [allRunnerSuspend, setAllRunnerSuspend] = useState(false)
  const headerRef = useRef(null)

  //end states

  // get all suspend
  useEffect(() => {
    if (!raceOdds?.runners?.filter((item) => item.status !== "SUSPENDED").length >= 1 ) {
      setAllRunnerSuspend(true)
    }
  }, [raceOdds])
  // end get all suspend

  // request api
  const raceDataRequest = async (Id) => {
    
    if(!Id) {
      Id = sessionStorage.getItem("navigationID")
    }
    try {
      const response = await http.get(`/exchange/odds/topRaces/${Id}`)
      if (response.data.success) {
        setRaceOdds(response.data.result[0])
        setUpcomingRaces(response.data.result.slice(1))
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    raceDataRequest(navigationHeaderMenu.navigationID)
  },[navigationHeaderMenu.navigationID])
  // useEffect(() => {
  //   console.log('my response try useffect')
  //   actions.requestRacingPageData(navigationHeaderMenu.navigationID).then((response) => console.log('??response', response))
  // }, [navigationHeaderMenu.navigationID])
  // end request api

  // save page id if page refreshed
  useEffect(() => {
    if(navigationHeaderMenu.navigationID) {
      sessionStorage.setItem("navigationID", navigationHeaderMenu.navigationID);
    }
    if(navigationHeaderMenu.navigationName) {
      sessionStorage.setItem("navigationName", navigationHeaderMenu.navigationName);
    }
    headerRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth'});
  },[navigationHeaderMenu.navigationID])

  // useEffect(() => {
  // }, [navigationHeaderId, navigationName])
  // end save page id if page refreshed

  // console.log('raceOdds ??!!', raceOdds)
  // console.log('Date.parse(raceOdds?.start) ??!!', Date.parse(1645701420000))

  return (
    <div className='race-page' style={{position: 'relative'}}>
    <span style={{position: 'absolute', top: '-135px'}}  ref={headerRef}></span>

      <div className="race-page__header">
        <h2 className="race-page__header-title">{navigationHeaderMenu.navigationName || sessionStorage.getItem("navigationName")}</h2>
        <span className="race-page__header-subtitle">Betting from 1min before start </span>
      </div>
      <div className="race-page__content">
        <div className={`race-page__content-rase-odds-table-wrapper ${allRunnerSuspend === true ? "all-suspended" : ""}`}>
          <div className="race-page__content-header">
            <div className={`${raceOdds?.inPlay === false ? "not-in-play" : "in-play" } race-page__content-header-next-race`}>
              <span>Next Race:&nbsp;</span>
              {/*<span>{(tsToDate(Date.parse(raceOdds?.start), timeSettings?.timeZoneName).split(" ")[1]).split(":", 2).join(":") }&nbsp;</span>*/}
              <span>{(tsToDate(raceOdds?.start, timeSettings?.timeZoneName).split(" ")[1]).split(":", 2).join(":") }&nbsp;</span>
              <span>{raceOdds?.event.name.replace("${HH:mm}","")}</span>
            </div>
            <span className="race-page__header-subtitle">Opens in</span>
          </div>
          <div>
            <div className="race-page__content-rase-odds-table-header">
              <p>Race Odds</p>
              <div>
                <span>Back</span>
                <span>Lay</span>
              </div>
            </div>
            <div className={`${raceOdds?.isBettable === false ? "unbettable" : ""} race-page__content-rase-odds-table`}>
              {allRunnerSuspend === true && <div className="race-page__content-all-suspend-cover"><span>SUSPENDED</span></div>}
              {raceOdds && <SportMarketStandard data={raceOdds} key={raceOdds?.id} betPlacing={betPlacing} showHeader={false} showJockeyName={true}/> }
            </div>
          </div>
        </div>
        <div className={`race-page__content-upcoming-races-table-wrapper ${allRunnerSuspend === true ? "all-suspended" : ""}`}>
          <div className="race-page__content-header">
            <span className="race-page__content-header-next-race">Upcoming Races</span>
          </div>
          <div className="race-page__content-upcoming-races-table">
            <div>
              {
                upcomingRaces && upcomingRaces.map((item, index) => {
                  return  <UpcomingRacesAccordion
                              key={item?.id + index}
                              item={item}
                              index={index}
                              isAccordionOpen={isAccordionOpen}
                              setIsAccordionOpen={setIsAccordionOpen}
                              showHeader={false}
                              showJockeyName={true}
                          />
                  // const slicedItem = {...item, runners: item.runners.slice(0, 3)}
                  // let flag = slicedItem.event.countryCode?.toLowerCase()
                  // return (
                  //   <div key={slicedItem?.id + index} className="race-page__content-upcoming-races-table-row">
                  //     <div className="race-page__content-upcoming-races-table-row-header f32"
                  //       onClick={() => isAccordeonOpen === slicedItem?.id ? setIsAccordeonOpen(null) : setIsAccordeonOpen(slicedItem?.id)}
                  //     >
                  //       <div className={`flag ${flag}`}></div>
                  //       <div className="race-page__content-upcoming-races-table-row-header-text">
                  //         <span>{slicedItem?.event.name.replace("${HH:mm}","")}</span>
                  //         <span>{(tsToDate(Date.parse(slicedItem?.event.openDate), timeSettings?.timeZoneName).split(" ")[1]).split(":", 2).join(":") }</span>
                  //       </div>
                  //       <div className="race-page__content-upcoming-races-table-row-header-arrowContainer">
                  //         <span className={`race-page__content-upcoming-races-table-row-header-arrow ${isAccordeonOpen === slicedItem?.id ? "active" : ""}`}></span>
                  //       </div>
                  //     </div>
                  //       <div className={`race-page__content-upcoming-races-table-row-inner ${isAccordeonOpen === slicedItem?.id ? "open" : ""}`}>
                  //         <div className="race-page__content-rase-odds-table-header">
                  //           <p>Top 3 of {item.runners.length} runners</p>
                  //           <div>
                  //             <span>Back</span>
                  //             <span>Lay</span>
                  //           </div>
                  //         </div>
                  //         <div className="race-page__content-upcoming-races-table-row-items-wrapper">
                  //           {allRunnerSuspend === true && <div className="race-page__content-all-suspend-cover"><span>SUSPENDED</span></div>}
                  //           {<SportMarketStandard data={slicedItem} key={slicedItem?.id} betPlacing={betPlacing} showHeader={false} showJockeyName={true} />}
                  //         </div>
                  //       </div>
                  //   </div>
                  // )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connectRacingPage()(RacingPage);
