import React from 'react'
// import upDownScreen from './images/7upDownScreen.png'
// import upDownLastResult from './images/7upDownLastRes.png'
// import upDownRulesPnL from './images/7upDownRulesPnL.png'
// import upDownRulesPnLDet from './images/7upDownRulesPnLDet.png'

const SevenUpDownRules = () => {
  return (
    <div className='rulesContainer'>
      <div>
        <h2>GAME OBJECTIVES</h2>
        <p>
        Predict whether the next card dealt will be above 7 (7 Up), below 7 (7 Down), or 7.
        </p>
      </div>

      <div>
        <h2>GAME RULES</h2>
          <p>To begin a game round after the timer reaches 0, the Dealer deals the card for this round, only one card is dealt per round. Once the card is drawn this is the resulting state and the round ends.</p>
          <p>Players that bet on the winning side will win the bet based on the payout table below (betting on the other side will result in losing the bet). The game is played with eight decks of cards that are shuffled and placed in a shoe, once the cutting card comes out of the shoe shuffling procedure will start.
          </p>
      </div>
      <div>
        <h3>Card Ranking</h3>
        <p>The ranking of cards is, from lowest to highest: Ace, 2,3,4,5,6,7,8,9,10, Jack, Queen, King where Ace is "1" and King is "13".</p>
      </div>

      <div>
        <h3>Betting Instructions</h3>
        <ul>
          <li>
            If a game round is in progress when you enter the game, please wait
            for the next one, and then, place your bets.
          </li>
          <li>After the timer reaches 0, the game round begins.</li>
          <li>
            To place a bet, click on the box, enter the stake amount and submit
            by clicking on Place Bet button.
          </li>
          <li>
            You can place several bets on different runners in different markets
            simultaneously.
          </li>
          <li>
            The timer shows how much time you have left to place your bets.
          </li>
          <li>
            Winnings are paid for the winning bets at the end of each game
            round.
          </li>
          <li>To skip a turn, simply do not place any bets.</li>
        </ul>
      </div>

      <div>
        <h3>Main Bets</h3>
        <p>Dragon Tiger have several betting possibilities:</p>

        <ol>
          <li>Number</li>
          <li>7</li>
          <li>7 DOWN</li>
        </ol>
      </div>

      <div>
        <h3>Side Bets</h3>
          <p>Placing a bet on the side bet does not require placing a bet on main bet (but can be done in addition to the main bet).</p>
          <p>Red or Black - Predict if the card dealt in the round will be Red or Black.</p>
          <p>Odd or Even - Predict if the card dealt in the round will be Odd or Even.</p>
          <p>Club/Diamond/Heart/Spade - Predict the card dealt in the round will be of which suit</p>
      </div>

      <div>
        <h3>Payout</h3>
        <p>Payout depends on the type of bet placed.</p>

        <table className="rulesTable">
                <tbody>
                  <tr>
                    <th>7 UP</th>
                    <th>1: 0.98</th>
                  </tr>
                  <tr>
                    <th>7</th>
                    <th>1: 1.5</th>
                  </tr>
                  <tr>
                    <th>7 DOWN</th>
                    <th>1: 0.98</th>
                  </tr>
                  <tr>
                    <th>RED / BLACK</th>
                    <th>1: 0.98</th>
                  </tr>
                  <tr>
                    <th>ODD</th>
                    <th>1: 0.8</th>
                  </tr>
                  <tr>
                    <th>EVEN</th>
                    <th>1: 1.1</th>
                  </tr>
                  <tr>
                    <th>Club/Diamond/Heart/Spade</th>
                    <th>1: 2.75</th>
                  </tr>
                  
                </tbody>
              </table>
              {/*<p>In case the winning card is 7 then bets on 7 Up or 7 Down will lose 50% of the bet amount.</p>*/}
              <p>Please note that any malfunction voids the game round and all eventual payouts for the round. Bets will be returned.</p>

      </div>
{/* 
      <div>
        <h2>LAST RESULTS</h2>
        <p>Last 9 round results can be found at the bottom</p>
        <img className='rulesImage' src={upDownLastResult} alt='Last Results'></img>

        <p>Detailed last result pop up</p>
        <img className='rulesImage' src={upDownScreen} alt='Last Results'></img>
      </div>

      <div>
        <h2>BETTING HISTORY</h2>
        <p>Betting history menu returns the list of all bets made in all of the games.</p>
        <img className='rulesImage' src={upDownRulesPnL} alt='Last Results'></img>

        <p>Detailed view of each played round is accessible by clicking on an entry from the list</p>
        <img className='rulesImage' src={upDownRulesPnLDet} alt='Last Results'></img>
      </div> */}

    </div>
  )
}
export default SevenUpDownRules
