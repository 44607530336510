import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestRoundMarkets } from '../../store/roundMarkets/round-markets.thunks'

export const connectRoundResult = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      roundMarkets: state.roundMarkets,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = { requestRoundMarkets }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
