import { createSlice } from '@reduxjs/toolkit'

export const depositSlice = createSlice({
  name: 'deposit',
  initialState: {},
  reducers: {
    setReq: (state, action) => action.payload,
  },
})

export const { setReq } = depositSlice.actions

export default depositSlice.reducer
