import { createSlice } from '@reduxjs/toolkit'

export const accountReportSlice = createSlice({
    name: 'accountReport',
    initialState: null,
    reducers: {
        getAccountReportSuccess: (state, action) => action.payload,
        getAccountReportError: (state) => state,
    },
})

export const { getAccountReportSuccess, getAccountReportError } = accountReportSlice.actions

export default accountReportSlice.reducer
