import "./HighLowPL.scss";
import React, { useEffect, useState } from 'react'

const HighLowPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

  console.log("winnerData", winnerData)
 const getWinner = () => {
   let winObj = {};
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="high-low-betting">
        <div className="high-low-betting__games">
          <div className="high-low-betting__games-item">
            <span className="high-low-betting__games-item-title">
                  Joker
            </span>
            <div className="high-low-betting__games-item-cards">
              <img className="hi-low-last-result__player-cards-card"
                   src={cardSrc(roundMarkets[0].indexCard[0])}
                   alt={"card"}
              />
              {/*<img src="assets/smallCards/{{bettingData[0].indexCard[0]}}.png">*/}
            </div>
          </div>
          <div className="high-low-betting__games-item">
            <span className="high-low-betting__games-item-title">
                Ante
            </span>
            <div className="high-low-betting__games-item-cards">
              <img className="hi-low-last-result__player-cards-card"
                   src={cardSrc(roundMarkets[0].indexCard[1])}
                   alt={"card"}
              />
            </div>
          </div>
        </div>
        {winnerData && <div className="high-low-betting__info-column">
          <div className="high-low-betting__info-row">
            <div className="high-low-betting__info-row-title">
              <span>Winner: </span>
            </div>
            <div className="high-low-betting__info-row-results">
              <span>{winnerData['Match odds']  || 'No Winner'}</span>
            </div>
          </div>
          <div className="high-low-betting__info-row">
            <div className="high-low-betting__info-row-title">
              <span>Color: </span>
            </div>
            <div className="high-low-betting__info-row-results">
              <span>{winnerData['Colour']  || 'No Winner'}</span>
            </div>
          </div>
          <div className="high-low-betting__info-row">
            <div className="high-low-betting__info-row-title">
              <span>Line: </span>
            </div>
            <div className="high-low-betting__info-row-results">
              <span>{winnerData['Range']  || 'No Winner'}</span>
            </div>
          </div>

        </div>}
      </div>
);
};

export default HighLowPL;

