import "./BettingProfitAndLossItem.scss";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import { connectBettingProfitAndLoseItem } from "./BettingProfitAndLossItem.connect";
import { getCurrencyFormat } from "../../helpers/getCurrencyFormat.helper";

const BettingProfitAndLossItem = ({data, user, actions, setSelectedItemDetails, elementsParams}) => {

    //get window with
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
    //end get window with

    const history = useHistory();
    const [title, setTitle] = useState("");
    const [startTime, setStartTime] = useState(new Date().toDateString());
    const [settledTime, setSettledTime] = useState(new Date().toDateString());
    const [comm, setComm] = useState(0);
    const [netWin, setNetWin] = useState(0);

    const fetchPNLData = (providerId) => {
        switch (providerId) {
        case 1: {
            user && actions
                    .requestOrderListSuccess(user.memberCode, "settled", data?.marketId)
                    .then(() => goToDetailsPage())
                    .catch((e) => console.log("requestOrderListError = ", e));

            break;
        }

        case 7: {
            actions
                    .requestBetHistorySuccess(data?.marketId)
                    .then(() => goToDetailsPage())
                    .catch((e) => console.log("requestBetHistoryError = ", e));

            break;
        }
        }
    };

    const selectPNLItem = () => {
        fetchPNLData(data?.providerId);
    };

    const goToDetailsPage = () => {
        if (width < 1024) {
            history.push({
                pathname: "/betting_profit_and_loss_details",
                state: {itemData: data},
            });
        }
        else {
           setSelectedItemDetails(data);
        }

    };

    const getValueColor = (value) => {
        return  value >= 0 ? "pnl-item-value--green" : "pnl-item-value--red";
    };

    const parseData = (data) => {
        const eventName = data.eventName;
        const marketName = data.marketName;
        const settledTime = data.settledTime;
        const marketTime = data.marketTime;
        const memberComm = data.memberComm;
        // const memberWin = data.memberWin;
        const memberWin = data.pnl;
        setTitle(`${eventName} - ${marketName}`);
        setStartTime(new Intl.DateTimeFormat("en-GB", {dateStyle: "short", timeStyle: "medium"}).format(new Date(marketTime)));
        setSettledTime(new Intl.DateTimeFormat("en-GB", {dateStyle: "short", timeStyle: "medium"}).format(new Date(settledTime)));
        setComm(memberComm);
        setNetWin(memberWin);
    };

    useEffect(() => {
        parseData(data);
    }, [data]);

    if (width < 1024) {
        return (
          <div className="betting-pnl-item">
              <div className="betting-pnl-item__left-side">
                <span className="betting-pnl-item__title" onClick={() => selectPNLItem()}>
                    {title}
                </span>
                  <span className="start-time">
                    <span className="start-time__title">
                        Start Time:
                    </span>
                    <span className="start-time__value">
                        {startTime}
                    </span>
                </span>
                  <span className="settled-time">
                    <span className="settled-time__title">
                        Settled Time:
                    </span>
                    <span className="settled-time__value">
                        {settledTime}
                    </span>
                </span>
              </div>
              <div className="betting-pnl-item__right-side">
                <span className="comm">
                    <span className="comm__title">
                        Comm:
                    </span>
                    <span className={`comm__value ${getValueColor(-1 * comm)}`}>
                        {-1 * getCurrencyFormat(comm)}
                    </span>
                </span>
                  <span className="net-win">
                    <span className="net-win__title">
                        Net Win:
                    </span>
                    <span className={`net-win__value ${getValueColor(netWin)}`}>
                        {getCurrencyFormat(netWin)}
                    </span>
                </span>
              </div>
          </div>
        )
    }

    return (
        <ul className="betting-pnl-item">
            <li>
                <span className="betting-pnl-item__cell__value betting-pnl-item__title" onClick={() => selectPNLItem()}>{title}</span>
            </li>
            <li>
                <span className="betting-pnl-item__cell__value start-time__value">{startTime}</span>
            </li>
            <li>
                <span className="betting-pnl-item__cell__value settled-time__value">{settledTime}</span>
            </li>
            <li>
                <span className={`betting-pnl-item__cell__value comm__value ${getValueColor(-1 * comm)}`}>{(-1 * comm).toFixed(2)}</span>
            </li>
            <li>
                <span className={`betting-pnl-item__cell__value net-win__value ${getValueColor(netWin)}`}>{getCurrencyFormat(netWin)}</span>
            </li>
        </ul>
    );
};

export default connectBettingProfitAndLoseItem()(BettingProfitAndLossItem);
