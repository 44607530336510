import "./HiLowCards.scss";
import React from "react";
import {connectHiLow} from "../../HiLow.connect";

const HiLowCards = ({exchangeGames}) => {
    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };

    return (
    <div className="hi-low-cards">
      <div className="hi-low-cards__player">
        <div className="hi-low-cards__player-title">
          <span>Joker</span>
        </div>
        <img className="hi-low-cards__player-card"
             src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[0])} alt="lay" />;
      </div>
      <div className="hi-low-cards__player">
        <div className="hi-low-cards__player-title">
          <span>Ante</span>
        </div>
        <img className="hi-low-cards__player-card"
             src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[1])} alt="back" />;
      </div>
    </div>
    );
};
export default connectHiLow()(HiLowCards);
