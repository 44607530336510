import React from 'react'
import './Poker20Cards.scss'
import { connectPoker20 } from '../../Poker20.connect'

const Poker20Cards = ({ exchangeGames }) => {
  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  return (
    <div className="poker20CardsContent">
      <div className="poker20CardsContent-player-wrapper">
        <div className="poker20CardsContent-total poker20CardsContent-player">
          <span className="poker20CardsContent-total__name">Player A</span>
          <div className="poker20CardsContent-total__cards">
            <img
              className="poker20CardsContent-total__cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0])} alt="" />
            <img
              className="poker20CardsContent-total__cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1])}alt="" />
          </div>
        </div>
        <div className="poker20CardsContent-total poker20CardsContent-player">
          <span className="poker20CardsContent-total__name">Player B</span>
          <div className="poker20CardsContent-total__cards">
            <img
              className="poker20CardsContent-total__cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0])} alt=""/>
            <img
              className="poker20CardsContent-total__cards-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1] )} alt=""/>
          </div>
        </div>
      </div>
      <div className="poker20CardsContent-total poker20CardsContent-board">
        <span className="poker20CardsContent-total__name">Board</span>
        <div className="poker20CardsContent-total__cards">
          <img
            className="poker20CardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[0])} alt=""/>
          <img
            className="poker20CardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[1])} alt=""/>
          <img
            className="poker20CardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[2])} alt=""/>
          <img
            className="poker20CardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[3])} alt=""/>
          <img
            className="poker20CardsContent-total__cards-card"
            src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[4])} alt=""/>
        </div>
      </div>
    </div>
  )
}

export default connectPoker20()(Poker20Cards)
