import { createSlice } from '@reduxjs/toolkit'

function actionTypeEndsSuccess(type) {
  return type.substring(type.length - 7) === 'Success'
}

export const ajaxStatusSlice = createSlice({
  name: 'ajax',
  initialState: 0,
  reducers: {
    statusStartRequest: (state) => state + 1,
    statusErrorRequest: (state) => state - 1,
  },
  extraReducers: (builder) => {
    return builder.addMatcher(
      (action) => actionTypeEndsSuccess(action.type),
      (state) => {
        if (state > 0) {
          return state - 1
        } else {
          return state
        }
      }
    )
  },
})

export const {
  statusStartRequest,
  statusErrorRequest,
} = ajaxStatusSlice.actions

export default ajaxStatusSlice.reducer
