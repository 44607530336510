import React, { useState, useEffect } from 'react';
import { connectSearch } from './Search.connect';
import Toolbar from '../../components/Toolbar/Toolbar';
import { IonContent, IonPage, IonHeader, IonList, IonLabel, IonItem } from '@ionic/react';
import './Search.scss';
import { useHistory } from "react-router"

const Search = ({
    search,
    menu
}) => {
    const history = useHistory();
    const [menus, setMenus] = useState([]);
    const [result, setResult] = useState([]);

    useEffect(() => {
        if (menu && (!menus || menus.length === 0)) {
            setMenus(menu)
        }
    }, [menu])

    useEffect(() => {
        if (history.location.pathname === '/search') {
            setMenus(menu)
        }
    }, [history.location.pathname])

    const gotoEvent = (item) => {
        if (item.eventTypeId == '77777') history.push(`/casino_live/${item.id}`)
        else history.push(`/event-page/${item.eventTypeId}/${item.id}`)
    }

    useEffect(() => {
        const result = [];
        if (menus)
            menus.forEach(menuItem => {
                menuItem.children
                    .forEach(eventGroup => {
                        eventGroup.children
                            .filter(event => event.name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1 && result.push({ ...event, eventTypeId: menuItem.id }))
                    })
            })
        setResult(result)
    }, [menus, search])

    const renderResults = () => {
        if (search.value.length > 2) {
            return result && result.length ?
                <IonList className="Search__results">
                    {
                        result.map((eventElement, id) =>
                            <IonItem className="Search__results__item" key={id} onClick={() => gotoEvent(eventElement)}>
                                <IonLabel>{eventElement.name}</IonLabel>
                                {/* <IonNote className="Search__results__item__type">event</IonNote> */}
                            </IonItem>)
                    }
                </IonList>
                : <IonLabel className="Search__empty">{result && search.value && search.value.length > 2 && `No events found matching for '${search.value}'`}</IonLabel>
        }
        else return ''
    }

    return (
        <IonPage>
            <IonHeader>
                <Toolbar key="search" search />
            </IonHeader>
            <IonContent className="Search" fullscreen >
                {renderResults()}
            </IonContent>
        </IonPage>
    )
}

export default connectSearch()(Search)