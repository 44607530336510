import React, { useEffect } from 'react'
import './BetbyWidget.scss'
import preloaderGif from '../../assets/images/widget/preload-widget.gif'
import { connectBetbyWidget } from './BetbyWidget.connect'
import useScript from '../../hooks'
import { useHistory } from 'react-router-dom'
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { CONFIG } from '../../config/api.config'

function isNotEmptyObject(obj) {
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      return true;
    }
  }
  return false;
}


const BetbyWidget = ({ betbyWidgetLive, actions, pathname, auth }) => {
  const history = useHistory()

  // useScript('https://tanktech.sptpub.com/bt-renderer.min.js');

  //redirect on page sportsbook after click widget
  const updateUrlforSportbook = (urlSport) => {
    actions.setUrlForSportbook(urlSport)
    history.push(`/sportsbook/1444005`)
  }
  
  useEffect(() => {
    if (history.location.pathname.indexOf(`/default-page`) !== -1 && !betbyWidgetLive) {
      actions.requestBetbyWidgetStart(1444005, 'SPORTSBOOK')
    }
  }, [])

  useIonViewWillEnter(() => {
    if (history.location.pathname.indexOf(`/default-page`) !== -1 && !betbyWidgetLive) {
      actions.requestBetbyWidgetStart(1444005, 'SPORTSBOOK')
    }
  });
  
  useIonViewWillLeave(() => {
    if (isNotEmptyObject(window.BTrendererVarWidget)) {
        window.BTrendererVarWidget.kill();
     }
     actions.requestBetbyWidgetEnd()
  });
  
  const initBetbyWidget = (i = 0) => {
    if (i < 10) {
      try {
        window.BTrendererVarWidget = new window.BTRenderer().initialize({
          brand_id: '1928062748156104704',
          key: betbyWidgetLive.frame_key,
          themeName: CONFIG.betbyThemeName,
          lang: "en",
          widgetName: "promo",
          target: document.getElementById('betbyWidget'),

          widgetParams: {
            placeholder: "operator_page",
            onBannerClick: args => { updateUrlforSportbook(args.url) },
            withSportsList: true,
            onSportClick: args => { updateUrlforSportbook(args.url) },
          },
        })

      } catch (e) {
        setTimeout(() => initBetbyWidget(i + 1), 300)
      }
    } else {
      console.log('betbyWidget error')
    }
    return window.BTrendererVarWidget;
  }

  useEffect(() => {
    if (betbyWidgetLive) {
      initBetbyWidget()
    }
    else if (isNotEmptyObject(window.BTrendererVarWidget)) {
      window.BTrendererVarWidget.kill();
    } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betbyWidgetLive])

  return (
    <>
      {auth && (
        <div className="widget-container">
          <div className="widget-preloader">
            <img src={preloaderGif} />
          </div>

          <div id="betbyWidget"></div>
        </div>
      )}
    </>
  )
}

export default connectBetbyWidget()(BetbyWidget)
