import './LegalAgePopover.scss'
import { IonButton, IonPopover } from '@ionic/react'
import React from 'react'
import { connectLegalAgePopover } from './LegalAgePopover.connect'

const LegalAgePopover = ({
  open,
  actions
}) => {
  const handleNo = async () => {
    actions.requestLogout()
    actions.setLegalAgePopoverShow(false)
  }

  const handleYes = async () => {
    actions.setLegalAgePopoverShow(false)
  }
  return (
    <IonPopover isOpen={open} backdropDismiss={false} cssClass="LegalAgePopover">
      <div className="LegalAgePopover__content">
        <span className="LegalAgePopover__text">Are you 18+?</span>
      </div>
      <div className="LegalAgePopover__buttons">
        <IonButton
          onClick={handleNo}
          mode="md"
          class="LegalAgePopover__btn LegalAgePopover__btn--no"
        >
          No
      </IonButton>
        <IonButton
          onClick={handleYes}
          mode="md"
          class="LegalAgePopover__btn"
        >
          Yes
      </IonButton>
      </div>

    </IonPopover>
  )
}

export default connectLegalAgePopover()(LegalAgePopover)
