import { createSlice } from '@reduxjs/toolkit'

export const menuEventSlice = createSlice({
  name: 'menuEvent',
  initialState: null,
  reducers: {
    getEventSuccess: (state, action) => {
      return {
        ...state,
        [action.payload[0] && action.payload[0].eventTypeId]: action.payload
      }
    },
    getEventError: (state) => null,
  },
})

export const { getEventSuccess, getEventError } = menuEventSlice.actions

export default menuEventSlice.reducer