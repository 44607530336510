import React, { useEffect, useState } from 'react'
import {
  IonContent,
  IonPage,
  IonHeader,
  withIonLifeCycle,
} from '@ionic/react'
import { connectSportbook } from './Sportbook.connect'
import { useHistory, useParams } from 'react-router-dom'
import Toolbar from '../../components/Toolbar/Toolbar'
import useScript from '../../hooks'
import './Sportbook.scss'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'

import {
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import { CONFIG } from '../../config/api.config'

function isNotEmptyObject(obj) {
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      return true
    }
  }
  return false
}

const Sportbook = ({ sportbookLive, urlSportbook, actions, B2C, auth, elementsParams, ...props }) => {
  const history = useHistory()
  const { casinoId } = useParams()
  const [authorisation, setAuthorisation] = useState("")
  // useScript('https://tanktech.sptpub.com/bt-renderer.min.js');

  useIonViewWillEnter(() => {
    // if (!sportbookLive)
    actions.requestSportbookStart(casinoId, 'SPORTSBOOK')
  })

  useIonViewWillLeave(() => {
    try {
      setAuthorisation(null)
      if(window.BTrendererVar && Object.keys(window.BTrendererVar).length>0){
        window.BTrendererVar.kill()
      }
    } catch (e) {

    }
  })

  const initSportsbook = (i = 0) => {
    if (i < 10) try {
      setAuthorisation(sportbookLive.frame_key)
      if(window.BTrendererVar && Object.keys(window.BTrendererVar).length>0){
        console.log("killing first")
        window.BTrendererVar.kill();
        window.BTrendererVar={};
        console.log(window.BTrendererVar,"--old after kill BTrendererVar object")
      }
      window.BTrendererVar = new window.BTRenderer().initialize({
        brand_id: '1928062748156104704',
        key: sportbookLive.frame_key,
        themeName: CONFIG.betbyThemeName,
        lang: 'en',
        target: document.getElementById('betby-a'),
        minFrameHeight: 700,
        betSlipOffsetTop: 0,
        betslipZIndex: 999,
        url: urlSportbook ? `${urlSportbook}` : null,
        // cssUrls: [
        //   'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
        // ],
        // fontFamilies: ['Montserrat, sans-serif', 'Roboto, sans-serif'],

        onRouteChange: function() {
          if (!localStorage.getItem('page').includes('sportsbook')) {
            window.BTrendererVar.kill();
          }
        },
        onLogin: function() {

        },
        onRegister: function() {

        },
        onSessionRefresh: function() {
          actions.requestSportbookStart(casinoId, 'SPORTSBOOK')
        },
        onTokenExpired: function() {
          return actions.requestSportbookStart(casinoId, 'SPORTSBOOK')
        },

        onRecharge: function() {

          if(B2C && auth) {
            console.log("clicked deposit")
            // history.push("/deposit")
          } else {
            console.log("not B2C or not Auth")
          }
        }
      })
    } catch (e) {
      setTimeout(() => initSportsbook(i + 1), 300)
    }
    else console.log('sportsbook !!')
    return window.BTrendererVar
  }

  // for correct work of desktopVersion
  useEffect(() => {
    if (width > 1024) {
      if (localStorage.getItem('page').includes('sportsbook')) {
        actions.requestSportbookStart(casinoId, 'SPORTSBOOK')
      } else {
        window.BTrendererVar.kill();
      }
    }
  }, [localStorage.getItem('page')])

  useEffect(() => {

    if (sportbookLive) {
      if (authorisation !== sportbookLive.frame_key) {
        initSportsbook()
      }
    } else if (isNotEmptyObject(window.BTrendererVar)) {
      // bt.kill();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportbookLive])

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const [renderFooter, setRenderFooter] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setRenderFooter(true)
    }, 1000)
  }, [])

  return (
    <IonPage className="CasinoGames CasinoGames-sportsbook auth">
      {width < 1024 ? (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      ) : (
        <div style={{ height: '135px' }}></div>
      )
      }
      <IonContent fullscreen style={{ height: '81%' }}>
        <div id="betby-a"></div>

        { width > 1024 && renderFooter &&
            <FooterDesktop />
        }
      </IonContent>
    </IonPage>
  )
}

export default connectSportbook()(Sportbook)
