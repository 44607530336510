import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestSportbookStart, requestSportbookEnd } from '../../store/sportbookLive/sportbookLive.thunks';
import { setUrlForSportbook } from '../../store/betbyWidget/urlForSportbook.slice';
import { requestSportPageEvent } from '../../store/sportPageEvent/sportPageEvent.thunks';
import { requestInplayPopular } from '../../store/inplayPopular/inplayPopular.thunks';
import { requestExchangeGamesAll } from '../../store/exchangeGamesAll/exchangeGamesAll.thunks'


export const connectExchangeGames = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      sportPageEvent: state.sportPageEvent,
      inplayPopular: state.inplayPopular,
      auth: state.auth,
      exchangeGamesAll: state.exchangeGamesAll,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSportPageEvent,
      requestInplayPopular,
      requestExchangeGamesAll,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
