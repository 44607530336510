import httpAuth from '../../services/httpAuth'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getPromotionsError, getPromotionsSuccess } from './promotions.slice'

export const requestPromotions = () => async (dispatch) => {
    try {
        const response = await httpAuth.get(`/banners`)
        // const response = await axios.get(`https://lotus.com/banners`)
        if (response.data.success) {
            // console.log("response.data.result", response.data.result)
            dispatch(getPromotionsSuccess(response.data.result))
        }
        return response
    } catch (e) {
        dispatch(getPromotionsError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}

