import { createSlice } from '@reduxjs/toolkit'

export const lastResultsExchSlice = createSlice({
    name: 'lastResultsExch',
    initialState: null,
    reducers: {
        getLastResultsExchSuccess: (state, action) => action.payload,
        getLastResultsExchError: (state) => state,
    },
})

export const {
    getLastResultsExchSuccess,
    getLastResultsExchError,
} = lastResultsExchSlice.actions

export default lastResultsExchSlice.reducer
