import { createSlice } from '@reduxjs/toolkit'

export const racePageSlice = createSlice({
    name: 'racePage',
    initialState: {
        exposure: null,
    },
    reducers: {
        getExposureSuccess: (state, action) => ({ ...state, exposure: action.payload }),
        getExposureError: (state) => ({ ...state, exposure: null }),
    },
})

export const {
    getExposureError,
    getExposureSuccess,
    getOrdersError,
    getOrdersSuccess,
} = racePageSlice.actions

export default racePageSlice.reducer
