import "./Race2020PL.scss";
import React, { useEffect, useState } from 'react'
import spades from "../../../assets/images/exchangeGames/dragon-tiger/Spades.png"
import hearts from "../../../assets/images/exchangeGames/dragon-tiger/Hearts.png"
import clubs from "../../../assets/images/exchangeGames/dragon-tiger/Clubs.png"
import diamonds from "../../../assets/images/exchangeGames/dragon-tiger/Diamonds.png"

const Race2020PL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   const winObj = {
     others: '',
   };

   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
         if (market.marketHeader !== 'Match Odds' && runner.status === 'WINNER' && runner.name !== 'No Winner') {
           winObj.others = winObj.others.length <= 3 ?
             `${runner.name[0]} : ${market.marketHeader}`
             :
             `${winObj.others} | ${runner.name[0]} : ${market.marketHeader}`;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="race-betting">
        <div className="race-betting__games">
          <div className="race-betting__games-item">
            <div className="race-betting__games-item-img">
                <img src={spades} alt={"card"}/>
            </div>
            <div className="race-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[0].cards.length ?  roundMarkets[0].marketRunner[0].cards.map( card =>
                  <img key={card}
                       src={cardSrc(card)}
                       alt={card}
                  />
                )
                :
                <img src={cardSrc(null)} alt={"card"}
                />
              }
            </div>
            <div
              className={`race-betting__games-item-winner race-betting__games-item-cards ${roundMarkets[0].marketRunner[0].status === 'WINNER' ? 'winnerCup':''}`}>
              <img src={cardSrc("S13")} alt="card"/>
            </div>
          </div>
          <div className="race-betting__games-item">
            <div className="race-betting__games-item-img">
              <img src={hearts} alt={"card"}/>
            </div>
            <div className="race-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[1].cards.length ?  roundMarkets[0].marketRunner[1].cards.map( card =>
                  <img key={card}
                       src={cardSrc(card)}
                       alt={card}
                  />
                )
                :
                <img src={cardSrc(null)} alt={"card"}
                />
              }
            </div>
            <div
              className={`race-betting__games-item-winner race-betting__games-item-cards ${roundMarkets[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}`}>
              <img src={cardSrc("H13")} alt="card"/>
            </div>
          </div>
          <div className="race-betting__games-item">
            <div className="race-betting__games-item-img">
              <img src={clubs} alt={"card"}/>
            </div>
            <div className="race-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[2].cards.length ?  roundMarkets[0].marketRunner[2].cards.map( card =>
                  <img key={card}
                       src={cardSrc(card)}
                       alt={card}
                  />
                )
                :
                <img src={cardSrc(null)} alt={"card"}
                />
              }
            </div>
            <div
              className={`race-betting__games-item-winner race-betting__games-item-cards ${roundMarkets[0].marketRunner[2].status === 'WINNER' ? 'winnerCup':''}`}>
            <img src={cardSrc("C13")} alt="card"/>
            </div>
          </div>
          <div className="race-betting__games-item">
            <div className="race-betting__games-item-img">
              <img src={diamonds} alt={"card"}/>
            </div>
            <div className="race-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[3].cards.length ?  roundMarkets[0].marketRunner[3].cards.map( card =>
                  <img key={card}
                       src={cardSrc(card)}
                       alt={card}
                  />
                )
                :
                <img src={cardSrc(null)} alt={"card"}
                />
              }
            </div>
            <div
              className={`race-betting__games-item-winner race-betting__games-item-cards ${roundMarkets[0].marketRunner[3].status === 'WINNER' ? 'winnerCup':''}`}>
              <img src={cardSrc("D13")} alt="card"/>
            </div>
          </div>
          <div className="race-betting__winner">
            <span className="race-betting__winner-text">WINNER</span>
          </div>
        </div>
        <div className="race-betting__info-column">
          <div className="race-betting__info-row">
            <div className="race-betting__info-row-title">
              <span className="race-betting__info-title-text">Winner:</span>
            </div>
            <div className="race-betting__info-row-results">
              <span className="race-betting__info-results-text">{ winnerData && winnerData['Match Odds'] }</span>
            </div>
          </div>
        </div>
      </div>
);
};

export default Race2020PL;

