import React from 'react'
import { useEffect, useState } from 'react';
import './OpenBetsItem.scss';
import { tsToDate } from '../../helpers/date.helper'
import { useHistory } from "react-router-dom";
import { getFormattedDate } from '../../helpers/date.helper'
import { useDispatch, useSelector } from 'react-redux';
import { setInfo } from '../../store/openBets/openBets.slice';

const OpenBetsItem = ({
  timeSettings,
  bet,
  isEvent,
  isUnmatched = false,
  cancel,
  redirect = false,
}) => {
  const history = useHistory()

  const setInfoDetailed = useSelector((state) => state.openBetsList.detailed)
  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth

  const getPriseFor_SB = (marketId, price ) => {
    return (marketId && marketId.slice(marketId.length - 3) === '_SB' ? ((price - 1) * 100).toFixed(2).replace('.00', '') : price);
}

  const getTitle = () => {
    const isBack = bet.side == 'Back'
    const isFancy = bet.marketType == "INNINGS_RUNS" && bet.marketBettingType == "LINE"
    const text = isFancy ? (`${bet.line} runs - ${isBack ? 'Yes' : 'No'}`) : (bet.side)
    const profit = isFancy ? (bet.size * bet.averagePrice / 100).toFixed(2) : (bet.size * bet.averagePrice - bet.size).toFixed(2)
    //TODO: maybe change averagePrice to price ?
    const averagePrice = getPriseFor_SB(bet.marketId, bet.averagePrice)
    // return <div className={`OpenBetsItem__title ${isBack ? '' : 'lay'}`}>{text} {!isFancy && bet.selectionName} for {bet.orderStatus === 'matched' ? bet.sizeMatched : bet.sizeRemaining} @ {averagePrice} {isBack ? 'to win' : 'liability'} {profit}.</div>
    return <div className={`OpenBetsItem__title ${isBack ? '' : 'lay'}`}>{text} {!isFancy && bet.selectionName} for {bet.orderStatus === 'matched' ? bet.sizeMatched : bet.sizeRemaining} @ {averagePrice} {isBack ? 'to win' : 'liability'} {profit}.</div>
  }

  const getName = () => {
    if (bet.eventName.indexOf('${HH:mm}') != -1) {
      const formattedOpenDate = getFormattedDate(bet.marketTime, timeSettings.timeZoneName)
      return bet.eventName.replace(/\${HH:mm}/, formattedOpenDate.time)
    } else {
      return bet.eventName
    }
  }
  return width > 1024 ?
  <div className="OpenBetsItem" onClick={() => { if (redirect) history.push(`/event-page/${bet.eventTypeId}/${bet.eventId}`) }}>
    {getTitle()}
    <div className='OpenBetsItem__matchName'>{bet.competitionName}</div>
    <div className="OpenBetsItem__info">
      {!isEvent &&
        <div>
          <strong>{getName()}</strong>
        </div>
      }
      <div>
        <strong>{bet.marketName}</strong>
      </div>
      {setInfoDetailed && <div>
        <strong>Placed</strong>
        : {tsToDate(bet.betPlacedDate, timeSettings.timeZoneName)}
      </div>}
    </div>
    {isUnmatched && <button className="OpenBetsItem__button" onClick={() => cancel(bet.apolloBetId)}>Cancel</button>}
  </div>
  :
  <div className="OpenBetsItem" onClick={() => { if (redirect) history.push(`/event-page/${bet.eventTypeId}/${bet.eventId}`) }}>
    {getTitle()}
    <div className="OpenBetsItem__info">
      {!isEvent &&
        <div>
          <strong>{getName()}</strong>
        </div>
      }
      <div>
        <strong>{bet.marketName}</strong>
      </div>
      <div>
        <strong>Placed</strong>
        : {tsToDate(bet.betPlacedDate, timeSettings.timeZoneName)}
      </div>
    </div>
    {isUnmatched && <button className="OpenBetsItem__button" onClick={() => cancel(bet.apolloBetId)}>Cancel</button>}
  </div>
}

export default (OpenBetsItem)
