import { createSlice } from '@reduxjs/toolkit'

export const balanceInformationHeightSlice = createSlice({
  name: 'balanceInformationHeight',
  initialState: 27,
  reducers: {
    setBalanceInformationHeight: (state, action) => action.payload,
  },
})

export const { setBalanceInformationHeight } = balanceInformationHeightSlice.actions

export default balanceInformationHeightSlice.reducer
