import React from 'react'

const QueenRules = () => {
  return (
    <div className="rulesContainer">
      <h2>GAME OBJECTIVES</h2>
      <p>
        In Queen, cards are dealt to each player till their total (cards total +
        place value) is more than or equal to 10. The objective is to predict
        which player will be the winner.
      </p>

      <h2>GAME RULES</h2>
      <p>
        The game is played with only 21 cards from a single 52-card deck
        (excluding Jokers) 2,3,4,5,6 of all 4 suits and one Q of any suit is
        used in queen. Cards are shuffled after each game round.
      </p>

      <h3>Betting Instructions</h3>
      <ul>
        <li>
          If a game round is in progress when you enter the game, please wait
          for the next one, and then, place your bets.
        </li>
        <li>After the timer reaches 0, the game round begins.</li>
        <li>
          To place a bet, click on the box, enter the stake amount and submit by
          clicking on Place Bet button.
        </li>
        <li>
          You can place several bets on different runners in different markets
          simultaneously.
        </li>
        <li>
          The timer in the game window shows how much time you have left to
          place your bets.
        </li>
        <li>
          Winnings are paid for the winning bets at the end of each game round.
        </li>
        <li>To skip a turn, simply do not place any bets.</li>
      </ul>

      <h3>Main Bets</h3>
      <ol>
        <li>Total 0</li>
        <li>Total 1</li>
        <li>Total 2</li>
        <li>Total 3</li>
      </ol>
    </div>
  )
}
export default QueenRules
