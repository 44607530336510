import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetSearchResults, setSearchValue } from '../../store/search/search.slice';
import { requestSearchResults } from '../../store/search/search.thunks';
import { setNotifications, requestSaveOneClickSettings } from '../../store/user/user.thunks';

export const connectTransactionPopup = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      balance: state.balance,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
