import React from 'react'

const BollywoodRules = () => {
  return (
    <div className="rulesContainer">
      <h3>
        <span>GAME OBJECTIVES</span>
      </h3>
      <div>
        <p>
          The game of Bollywood Casino is a game of one card. The objective is
          to predict that of which section the dealt card will be. The sections
          are Don, Amar Akbar Anthony, Sahib Bibi aur Ghulam, Dharam veer, Kis
          Kisko Pyaar Karoon and Ghulam.
        </p>
      </div>
      <h3>
        <span>GAME RULES</span>
      </h3>
      <p>
        The game of Bollywood Casino is played with a total of 32 cards from two
        standard 52-cards Deck. The cards are the four Jacks, Queens, Kings and
        Aces from each Deck.
      </p>
      <ul>
        <li>If the card is &#9824;A Don wins.</li>
        <li> If the card is ♥A, ♣A or ♦A, Amar Akbar Anthony wins.</li>
        <li>If the card is ♠K, ♠Q or ♠J, Sahib Bibi aur Ghulam wins.</li>
        <li>If the card is ♦K or ♣K, Dharam Veer wins.</li>
        <li>If the card is ♥K, ♣Q, ♦Q or ♥Q, Kis Kisko Pyaar Karoon wins.</li>
        <li>If the card is ♥J, ♣J or ♦J, Ghulam wins.</li>
      </ul>
      <h4>
        <span>Betting Instructions</span>
      </h4>
      <ul>
        <li>
          If a game round is in progress when you enter the game, please wait
          for the next one, and then, place your bets.
        </li>
        <li>After the timer reaches 0, the game round begins.</li>
        <li>
          To place a bet, click on the box, enter the stake amount and submit by
          clicking on Place Bet button.
        </li>
        <li>
          You can place several bets on different runners in different markets
          simultaneously.
        </li>
        <li>
          The timer in the game window shows how much time you have left to
          place your bets.
        </li>
        <li>
          Winnings are paid for the winning bets at the end of each game round.
        </li>
        <li>To skip a turn, simply do not place any bets.</li>
      </ul>
      <h4>
        <span>Main Bets</span>
      </h4>
      <ol>
        <li>Don</li>
        <li>Amar Akbar Anthony</li>
        <li>Sahib Bibi aur Ghulam</li>
        <li>Dharam Veer</li>
        <li>Kis Kisko Pyaar Karoon</li>
        <li>Ghulam</li>
      </ol>
      <h4>
        <span>Side Bets</span>
      </h4>
      <ul>
        <li>Pair K-Q</li>
        <li>Pair J-A</li>
        <li>Black</li>
        <li>Red</li>
        <li>Cards - J</li>
        <li>Cards - Q</li>
        <li>Cards - K</li>
        <li>Cards - A</li>
      </ul>
      <h3>
        <span>PAYOUTS</span>
      </h3>
      <p>Payout depends on the type of bet placed.</p>
      <table>
        <thead>
          <tr>
            <th>Hands</th>
            <th>Payout</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Pair K-Q</th>
            <th>1: 0.97</th>
          </tr>
          <tr>
            <th>Pair J-A</th>
            <th>1: 0.97</th>
          </tr>
          <tr>
            <th>Black</th>
            <th>1: 0.97</th>
          </tr>
          <tr>
            <th>Red</th>
            <th>1: 0.97</th>
          </tr>
          <tr>
            <th>Cards - J</th>
            <th>1: 2.75</th>
          </tr>
          <tr>
            <th>Cards - Q</th>
            <th>1: 2.75</th>
          </tr>
          <tr>
            <th>Cards - K</th>
            <th>1: 2.75</th>
          </tr>
          <tr>
            <th>Cards - A</th>
            <th>1: 2.75</th>
          </tr>
        </tbody>
      </table>
      <p>In the event of a tie, bets are refunded.</p>
    </div>
  )
}
export default BollywoodRules
