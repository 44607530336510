import React, { useEffect, useState } from 'react'
import { connectBonusStatementPage } from './BonusStatementPage.connect'
import {
  IonBackdrop,
  IonButton,
  IonContent,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  useIonViewDidLeave,
} from '@ionic/react'
import Toolbar from '../../components/Toolbar/Toolbar'
import './BonusStatementPage.scss'
import { timeToDate } from '../../helpers/date.helper'
import { useHistory } from 'react-router'
import arrowRed from '../../assets/images/arrow-red.svg'
import arrowGrean from '../../assets/images/arrow-grean.svg'
import DatePicker from "react-date-picker";
import "react-calendar/dist/Calendar.css";
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'


import { GET_STATS_INTERVAL } from '../../config/app.config'
import { loadModule } from '@sentry/utils'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'

const BonusStatementPage = ({
  accountStatement,
  user,
  actions,
  bonusStatement,
  elementsParams,
}) => {
  const history = useHistory()
  const [filterOpen, setfilterOpen] = useState(false)
  // const [selectedDateFrom, setSelectedDateFrom] = useState(new Date(Date.now() - 604800000).toISOString().slice(0,10))
  // const [selectedDateTo, setSelectedDateTo] = useState(new Date().toISOString().slice(0,10))
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [validationError, setValidationError] = useState("");
  const [selectPNLItem, setSelectPNLItem] = useState(null);



  const [selectedFilter, setSelectedFilter] = useState()
  const [showOption, setShowOption] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')

  const [choosenFrom, setChoosenFrom] = useState(false)
  const [choosenTo, setChoosenTo] = useState(false)

  const dateValidation = (from, to) => {
    if (from > to) return setValidationError("'To' date must be greater than 'From' date.");
    else if (setValidationError) setValidationError("");
    if (new Date().setHours(23, 59, 59, 999) < to) return setValidationError("'To' date must not be greater than today's date.");
    else if (setValidationError) setValidationError("");

    if (!validationError) {
      actions
        .requestAccountStatementSuccess(user.memberCode, Date.parse(selectedDateFrom), Date.parse(selectedDateTo))
        .catch((e) => console.log("getWithTimeLimit = ", e));
    }
  };

  const getWithTimeLimit = () => {
    setSelectPNLItem(null);
    const DateFrom = selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0);
    const DateTo = selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999);
    dateValidation(DateFrom, DateTo);
  };


  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth


  useEffect(() => {
    let DateFrom = selectedDateFrom && Date.parse(selectedDateFrom)
    let DateTo = selectedDateTo && Date.parse(selectedDateTo)



    if (history.location.pathname.indexOf(`/bonus_statement`) !== -1)
      actions.requestBonusStatementSuccess(
        user.memberCode,
        DateFrom,
        DateTo,
        selectedFilter
      )

    // const mainTimer = setInterval(() => {
    //     if (history.location.pathname.indexOf(`/bonus_statement`) !== -1) actions.requestBonusStatementSuccess(user.memberCode, DateFrom, DateTo, selectedFilter)
    // }, 1000);
    // return () => clearInterval(mainTimer);
  }, [
    history.location.pathname,
    selectedDateFrom,
    selectedDateTo,
    selectedFilter,
  ])


  let todayDate = new Date()

  const getMonth = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      month: '2-digit',
    })}`
  }

  const getDate = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      day: '2-digit',
    })}`
  }

  const displayAccountStatement = () => {
    return (
      bonusStatement &&
      bonusStatement.data &&
      bonusStatement.data.map((value, index) => {
        let dateItem = new Date(value.date)

        const fetchPNLData = (providerId = 1) => {
          switch (providerId) {
            case 1: {
              user &&
                actions
                  .requestOrderListSuccess(
                    user.memberCode,
                    'settled',
                    value.description.market
                  )
                  .then((data) => goToDetailsPage(data.data.result))
                  .catch((e) => console.log('requestOrderListError = ', e))

              break
            }

            case 7: {
              actions
                .requestBetHistorySuccess(value.description.market)
                .then(() => goToDetailsPage())
                .catch((e) => console.log('requestBetHistoryError = ', e))

              break
            }
          }
        }

        const selectPNLItem = () => {
          fetchPNLData(value.providerId)
        }
        // console.log('value', accountStatement.data)
        const goToDetailsPage = (data) => {
          console.log('value', data)
          history.push({
            pathname: '/account_statement_details',
            state: { itemData: data?.[0] },
          })
        }

        return (
          <div className="account-statement-page__list-row" key={index}>
            <div className="account-statement-page__content-group">
              <div className="account-statement-page__item">
                <div>
                  {value.action === 'TRANSFER_UP' ||
                    value.action === 'TRANSFER_DOWN' ||
                    value.action === 'Credit' ||
                    value.action === 'OPEN' ? (
                    <div className="account-statement-page__item__remark">
                      {value.actionDisplayName}
                    </div>
                  ) : (
                    <div
                      className="account-statement-page__item__remark"
                      onClick={() => selectPNLItem()}
                    >
                      {value.description !== null &&
                        value.description.eventName}
                    </div>
                  )}
                  <div className="account-statement-page__item__right-side account-statement-page__item__balanse">
                    Bal. {getCurrencyFormat(value.balance)}
                  </div>
                </div>
                <div>
                  <div className="account-statement-page__item__title">
                    Date
                  </div>
                  <div className="account-statement-page__item__right-side data">
                    <span className="date">
                      {`${('0' + dateItem.getDate()).slice(-2)}/${(
                        '0' +
                        (dateItem.getMonth() + 1)
                      ).slice(-2)}/${dateItem.getFullYear()}`}
                    </span>
                    <span className="time">
                      {`${dateItem.getHours() < 10
                          ? ('0' + dateItem.getHours()).slice(-2)
                          : dateItem.getHours()
                        }:${dateItem.getMinutes() < 10
                          ? ('0' + dateItem.getMinutes()).slice(-2)
                          : dateItem.getMinutes()
                        }:${dateItem.getSeconds() < 10
                          ? ('0' + dateItem.getSeconds()).slice(-2)
                          : dateItem.getSeconds()
                        }`}
                    </span>
                  </div>
                </div>

                <div>
                  <div className="account-statement-page__item__title">
                    Description
                  </div>
                  <div className="account-statement-page__item__right-side">
                    {value.actionDisplayName}
                  </div>
                </div>
                <div>
                  <div className="account-statement-page__item__title">P&L</div>
                  <div
                    className={`account-statement-page__item__right-side ${value.pnl >= 0 ? 'green' : 'red'
                      }`}
                  >
                    {value.pnl >= 0 ? (
                      <img src={arrowGrean} />
                    ) : (
                      <img src={arrowRed} />
                    )}
                    {getCurrencyFormat(value.pnl)}
                  </div>
                </div>

                <div>
                  <div className="account-statement-page__item__title">
                    Credit Limit
                  </div>
                  <div className="account-statement-page__item__right-side">
                    {value.creditLimit ? getCurrencyFormat(value.creditLimit) : '-'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="account-statement-page" fullscreen>
        <div className="page-title-wrapper">
          <h2 className="page-title">{selectedFilter === "S" ? 'Sports Bonus' : selectedFilter === "C" ? 'Casino Bonus' : 'Bonus'} Statement</h2>
        </div>

        <div className="account-statement-page__filter-content">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <button
              style={{ width: 'max-content' }}
              onClick={() => setfilterOpen((prevValue) => !prevValue)}
              className={`filter-button ${filterOpen && 'filter-button--active'
                }`}
            >
              Filter
            </button>

            {!filterOpen && selectedDateFrom || selectedDateTo ? <div className='filtringDetails'>
              {selectedDateFrom && <span className='filtringDetails_detail'> From: {selectedDateFrom.toString().slice(0, 10)}</span>} <br />
              {selectedDateTo && <span>To: {selectedDateTo.toString().slice(0, 10)}</span>}
              {/* {selectedDateTo && <div className='filtringDetails_detail'>To: {selectedDateTo.slice(0, 10)}</div>} */}
              {/* {selectedFilter && <div className='filtringDetails_detail'>Type: {selectedFilter == 2
                  ? 'Sports Bonus'
                  : selectedFilter == 3
                  ? 'Casino Bonus'
                  : ''}</div>} */}
              <div className='filtringDetails_remove' onClick={() => {
                setSelectedDateFrom('')
                setSelectedDateTo('')
                setSelectedFilter()
              }}>Clear filter</div>


            </div> : ''}
          </div>

          {filterOpen && (
            // <div className="account-statement-page__filter-data-piker">
            //   <IonItem>
            //     <IonLabel position="floating">Date from:</IonLabel>
            //     <IonDatetime
            //       displayFormat="MM /DD/YYYY"
            //       id="befg1"
            //       value={selectedDateFrom}
            //       onIonChange={(e) => {setSelectedDateFrom(e.detail.value)
            //       console.log(e.detail.value)}}
            //     ></IonDatetime>
            //   </IonItem>

            //   <IonItem>
            //     <IonLabel position="floating">Date to:</IonLabel>
            //     <IonDatetime
            //       displayFormat="MM/DD/YYYY"
            //       id="befg2"
            //       value={selectedDateTo}
            //       onIonChange={(e) => setSelectedDateTo(e.detail.value)}
            //     ></IonDatetime>
            //   </IonItem>
            // {/* <div className='custom-select' style={{width: '200px'}}> */}
            <div className="account-statement-page__filter-data-piker">
              <div className="account-statement-page__filters-date-input"
                // style={{marginRight: "15px"}}
                onClick={() => {
                  // setChoosenFrom(true)
                }}>
                <p style={choosenFrom
                  ? { fontSize: '12px', transition: 'linear 0.2s' }
                  : {}}>Date from:</p>
                <div>
                  <DatePicker
                    onChange={setSelectedDateFrom}
                    value={selectedDateFrom}
                    locale={'en-US'}
                    showLeadingZeros={true}
                    clearIcon={null}
                    calendarIcon={null}
                    format={'dd/MM/yyyy'}
                    dayPlaceholder={getDate(todayDate)}
                    monthPlaceholder={getMonth(todayDate)}
                    yearPlaceholder={todayDate.getFullYear()}
                  />
                </div>
              </div>
              <div className="account-statement-page__filters-date-input"
                // style={{marginRight: "15px"}}
                onClick={() => {
                  // setChoosenTo(true)
                }}>
                <p style={choosenTo
                  ? { fontSize: '12px', transition: 'linear 0.2s' }
                  : {}}>Date to:</p>
                <div>
                  <DatePicker
                    onChange={setSelectedDateTo}
                    value={selectedDateTo}
                    locale={'en-US'}
                    showLeadingZeros={true}
                    clearIcon={null}
                    calendarIcon={null}
                    format={'dd/MM/yyyy'}
                    dayPlaceholder={getDate(todayDate)}
                    monthPlaceholder={getMonth(todayDate)}
                    yearPlaceholder={todayDate.getFullYear()}
                  />
                </div>
              </div>

              <label for="walletCode">Wallet Type:</label>

              <select
                className="selectType"
                name="walletCode"
                id="walletCode"
                onChange={(e) => setSelectedFilter(e.target.value)}
                value={selectedFilter}
              >
                <option value="B">General Bonus</option>
                <option value="S">Sports Bonus</option>
                <option value="C">Casino Bonus</option>
              </select>

              {/* <div className="status-dropdown">
      <div className={showOption ? "status-dropdown-selected active" : "status-dropdown-selected"} onClick={() => setShowOption(prevState => !prevState)}>
        {selectedOption}
      </div>
      {showOption && (

        <ul className="status-dropdown-options">
         <li className="status-dropdown-option" value="1" onClick={handleOptionClick}>General Bonus</li>
         <li className="status-dropdown-option" value="2" onClick={handleOptionClick}>Sports Bonus</li>
         <li className="status-dropdown-option" value="3" onClick={handleOptionClick}>Casino Bonus</li>
        </ul>
      )}
    </div>
     */}

              {/* </div> */}

              {/* <IonButton
                size="large"
                onClick={() => setfilterOpen((prevValue) => !prevValue)}
              >
                Apply
              </IonButton> */}
              <button className="account-statement-page__date-search-button"
                // disabled={!(selectedDateFrom && selectedDateTo)}
                onClick={() => {
                  getWithTimeLimit()
                  setfilterOpen((prevValue) => !prevValue)
                }}
              >
                <span>Apply</span>
              </button>
              {validationError && (<div className="account-statement-page__filter-content-error">{validationError}</div>)}
            </div>
          )}
        </div>

        {bonusStatement && !bonusStatement.data ? (
          <div className="account-statement-page__content">
            <div className="account-statement-page__content--empty">
              No data to display. Try changing filters and search again
            </div>
          </div>
        ) : (
          !filterOpen && (
            <div className="account-statement-page__content">
              {displayAccountStatement()}
            </div>
          )
        )}
        {/*{width < 1024 && <FooterDesktop />}*/}
      </IonContent>
    </IonPage>
  )
}

export default connectBonusStatementPage()(BonusStatementPage)

// const AccountStatementPage1 = ({accountStatement, user, actions}) => {
//     const history = useHistory();
//     const [filterOpen, setfilterOpen] = useState(false);
//     const [selectedDateFrom, setSelectedDateFrom] = useState("");
//     const [selectedDateTo, setSelectedDateTo] = useState("");
//
//
//
//
//     useEffect(() => {
//         let DateFrom = selectedDateFrom && Date.parse(selectedDateFrom);
//         let DateTo = selectedDateTo && Date.parse(selectedDateTo);
//
//         if (history.location.pathname.indexOf("/account_statement") !== -1) actions.requestAccountStatementSuccess(user.memberCode, DateFrom, DateTo);
//
//     // const mainTimer = setInterval(() => {
//     //     if (history.location.pathname.indexOf(`/account_statement`) !== -1) actions.requestAccountStatementSuccess(user.memberCode, DateFrom, DateTo)
//     // }, GET_STATS_INTERVAL);
//     // return () => clearInterval(mainTimer);
//     }, [actions, history.location.pathname, selectedDateFrom, selectedDateTo, user.memberCode]);
//
//
//
//
//     const displayAccountStatement = () => {
//         return accountStatement && accountStatement.data && accountStatement.data.map((value, index) => {
//             let dateItem = new Date(value.date);
//
//
//             const fetchPNLData = (providerId = 1) => {
//         // console.log('value.description.marketId', value.description.market)
//         //
//                 switch (providerId) {
//                 case 1: {
//                     user && actions
//               .requestOrderListSuccess(user.memberCode, "settled", value.description.market)
//               .then((data) => goToDetailsPage(data.data.result))
//               .catch((e) => console.log("requestOrderListError = ", e));
//
//                     break;
//                 }
//
//                 case 7: {
//                     actions
//               .requestBetHistorySuccess(value.description.market)
//               .then(() => goToDetailsPage())
//               .catch((e) => console.log("requestBetHistoryError = ", e));
//
//                     break;
//                 }
//                 }
//             };
//
//             const selectPNLItem = () => {
//         //  console.log('value.description.market', value.description.market)
//                 fetchPNLData(value.providerId);
//             };
//       // console.log('value', accountStatement.data)
//             const goToDetailsPage = (data) => {
//                 console.log("value", data);
//                 history.push({
//                     pathname: "/account_statement_details",
//                     state: {itemData: data[0]},
//                 });
//             };
//
//             return (
//         <div className="account-statement-page__list-row" key={index}>
//           <div className="account-statement-page__content-group">
//             <div className="account-statement-page__item" >
//               <div>
//                 {value.action === "TRANSFER_UP" || value.action === "TRANSFER_DOWN" || value.action === "Credit" || value.action === "OPEN"
//                   ? <div className="account-statement-page__item__remark" >{value.actionDisplayName}</div>
//                   : <div className="account-statement-page__item__remark" onClick={() => selectPNLItem()} >{value.description !== null && value.description.eventName}</div>}
//                 <div className="account-statement-page__item__right-side account-statement-page__item__balanse">
//                   Bal. {value.balance}
//                 </div>
//               </div>
//               <div>
//                 <div className="account-statement-page__item__title">Date</div>
//                 <div className="account-statement-page__item__right-side data">
//                                     <span className="date">
//                                         {`${("0" + dateItem.getDate()).slice(-2)}/${("0" + (dateItem.getMonth() + 1)).slice(-2)}/${dateItem.getFullYear()}`}
//                                     </span>
//                   <span className="time">
//                                         {`${dateItem.getHours() < 10 ? ("0" + dateItem.getHours()).slice(-2) : dateItem.getHours()}:${dateItem.getMinutes() < 10 ? ("0" + dateItem.getMinutes()).slice(-2) : dateItem.getMinutes()}:${dateItem.getSeconds() < 10 ? ("0" + dateItem.getSeconds()).slice(-2) : dateItem.getSeconds()}`}
//                                     </span>
//                 </div>
//               </div>
//
//               <div>
//                 <div className="account-statement-page__item__title">Description</div>
//                 <div className="account-statement-page__item__right-side">
//                   {value.actionDisplayName}
//                 </div>
//               </div>
//               <div>
//                 <div className="account-statement-page__item__title">P&L</div>
//                 <div className={`account-statement-page__item__right-side ${value.pnl >= 0 ? "green" : "red"}`}>
//                   {/*{value.pnl >= 0*/}
//                   {/*  // ? <img src={arrowGrean} />*/}
//                   {/*  // : <img src={arrowRed} />}*/}
//                   {/*{value.pnl}*/}
//                 </div>
//               </div>
//
//               <div>
//                 <div className="account-statement-page__item__title">Credit Limit</div>
//                 <div className="account-statement-page__item__right-side">
//                   {value.creditLimit ? value.creditLimit : "-"}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//             );
//         });
//     };
//
//     return (
//     <IonPage>
//       <IonHeader>
//         <Toolbar />
//       </IonHeader>
//       <IonContent className="account-statement-page" fullscreen>
//         <div className="account-statement-page-wrapper">
//           <div className="page-title-wrapper account-statement-page-title-wrapper">
//             <h2 className="page-title account-statement-page-title">Account Statement</h2>
//           </div>
//
//           <div className="account-statement-page__filter-content">
//             <div>
//               <button onClick={() => setfilterOpen((prevValue) => !prevValue)} className={`filter-button ${filterOpen && "filter-button--active"}`}>Filter</button>
//             </div>
//
//             {filterOpen && <div className="account-statement-page__filter-data-piker">
//
//               <IonItem >
//                 {/*<IonLabel position="floating">Date from:</IonLabel>*/}
//                 <IonDatetime displayFormat="MM /DD/YYYY" id="befg1" value={selectedDateFrom} onIonChange={e => setSelectedDateFrom(e.detail.value)}></IonDatetime>
//               </IonItem>
//
//               <IonItem>
//                 {/*<IonLabel position="floating">Date to:</IonLabel>*/}
//                 <IonDatetime displayFormat="MM/DD/YYYY" id="befg2" value={selectedDateTo} onIonChange={e => setSelectedDateTo(e.detail.value)}></IonDatetime>
//               </IonItem>
//
//               {/*<IonButton size="large" onClick={() => setfilterOpen((prevValue) => !prevValue)}>*/}
//                 Apply
//               {/*</IonButton>*/}
//             </div>}
//
//           </div>
//
//
//
//
//
//           {
//             (accountStatement && !accountStatement.data) ?
//               <div className="account-statement-page__content">
//                 <div className="account-statement-page__content--empty">
//                   No data to display. Try changing filters and search again
//                 </div>
//               </div>
//               :
//               (!filterOpen && <div className="account-statement-page__content">
//                 <AccountStatementTable accountStatement={accountStatement}/>
//
//                 {displayAccountStatement()}
//               </div>)
//           }
//         </div>
//       </IonContent>
//     </IonPage>
//     );
// };
//
