import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./styles/CasinoMeter.scss"
import CardsInfo from '../helpers/CardsInfo/CardsInfo'
import LastResults from '../helpers/LastResults/LastResults'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
// import BetPlacing from '../../BetPlacing/BetPlacing'
import { connectCasinoMeter } from './CasinoMeter.connect'
import * as cards from './CasinoMeterHelpers/helpers'

const CasinoMeter = ({exchangeGames, sportEvent, betPlacing, actions, exposure, eventId, headerHeight, auth, elementsParams}) => {

  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  const openBets = useSelector((state) => (state.openBets))
  const myBets = openBets?.matched[0]
  const [selected, setSelected] = useState("")
  const selectedUpdate = myBets && myBets.length  > 0 ?  myBets.marketId.split('.')[1] : ''
  const placedBet = myBets?.name === "Casino Meter" && myBets?.markets[0]?.selections[0][0].selectionName

  useEffect(() => {
    if (placedBet !== selected) {
      setSelected(placedBet)
    }
    if (selectedUpdate !== (exchangeGames && exchangeGames[0] && exchangeGames[0].roundId ? exchangeGames[0].roundId : "")) {
      setSelected("")
    }
    if (myBets && myBets?.markets[0]?.selections[0][0].selectionName) {
      setSelected( myBets?.markets[0]?.selections[0][0].selectionName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUpdate, placedBet, myBets])

  // sport event data
  const dataOdd = sportEvent.sportData
  // end sport event data

  // request Exchange Game data
  useEffect(() => {
    const timer = setIntervalAsync(async () => {
      await actions
            .requestExchangeGames(eventId)
            .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)
  }, [])
  // End request Exchange Game data

  // Show betslip
  // const renderBetPlacing = () => {
  //   if (betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }
  // End Show betslip

  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)
    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing

  //get market price
  const getMarketPrice = (marketIndex, runnersIndex) => {
    return (
      dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0].price
    )
  }
  //end get market price

  // get exposure
  const getExposure = (marketIndex, runnerIndex) => {
    if(auth) {
      let id = dataOdd[marketIndex].runners[runnerIndex].id
      let objFinder =  exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl

      return (
        <div
          className={
            objFinder > 0
              ? 'exposure-box-modern exposure-modern--pos'
              : 'exposure-box-modern exposure-modern--neg'
          }
        >
          {objFinder?.toFixed(2)}
        </div>
      )
    } else return
  }
  // end get exposure

  // lock market
  const marketStatus = (marketIndex) => {
    return (!dataOdd[marketIndex].isBettable && 'lock-ui')
  }
  // end lock market

  // get winner
  const getWinner = (marketIndex, runnerIndex) => {
    return false
    // return exchangeGames &&
    //   exchangeGames[marketIndex] &&
    //   exchangeGames[marketIndex]?.marketRunner[runnerIndex] &&
    //   exchangeGames[marketIndex]?.marketRunner[runnerIndex]?.status
  }
  // get winner

  return (
    <>
      <CardsInfo/>
      <div className="casino-meter-table" >
        {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
        <div class="casino-meter-table__row casino-meter-table__row-first">
          <div class="casino-meter-table__row-header">
            <span>Low</span>
          </div>
          <div class="casino-meter-table__row-body">
            <div
              class={`casino-meter-table__player 
                ${marketStatus(0)} ${getWinner(0,0)} casino-meter-table__player-low
                ${placedBet && placedBet !== "Low (A-9 without ♠9)" ? "lock-ui" : ""}
              `}
              onClick={() => {
                oddPush(0, 0)
                // setSelected("Low (A-9 without ♠9)")
              }}
            >
              <div className="markets__section-body">
                <div>
                  <img src={cards['A']} alt="card A" />
                  <img src={cards['O2']} alt="card 2" />
                  <img src={cards['O3']} alt="card 3" />
                  <img src={cards['O4']} alt="card 4" />
                  <img src={cards['O5']} alt="card 5" />
                </div>
                <div>
                  <img src={cards['O6']} alt="card 6" />
                  <img src={cards['O7']} alt="card 7" />
                  <img src={cards['O8']} alt="card 8" />
                  {selected === "Low (A-9 without ♠9)"
                    ? <img src={cards['s934']} alt="new card 9" />
                    : <img src={cards['O9']} alt="card 9" />
                  }
                  {selected === "High (10-K without ♠10)" &&
                    <img src={cards['OS10']} alt="new card 9" />
                  }
                </div>
                <div class="casino-meter-table__player-item">
                  {/*{getExposure(0,0)}*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="casino-meter-table__row">
          <div class="casino-meter-table__row-header">
            <span>High</span>
          </div>
          <div class="casino-meter-table__row-body">
            <div
              className={`casino-meter-table__player ${marketStatus(1)} ${getWinner(1,0)} ${placedBet && placedBet !== "High (10-K without ♠10)" ? "lock-ui" : ""}`}
              onClick={() => {
                oddPush(1, 0)
                // setSelected("High (10-K without ♠10)")
                }}
              >
              <div className="markets__section-body">
                <div>
                  {selected === "Low (A-9 without ♠9)" &&
                    <img src={cards['OS9']} alt="new card 9" />
                  }
                  {selected === "High (10-K without ♠10)"
                    ? <img src={cards['s1034']} alt="new card 10" />
                    : <img className="card-ten"  src={cards['O10']} alt="card 10" />
                  }
                  <img src={cards['J']} alt="card J" />
                  <img src={cards['Q']} alt="card Q" />
                  <img src={cards['K']} alt="card K" />
                </div>
          <div class="casino-meter-table__player-item ">
            {/*{getExposure(1,0)}*/}
          </div>
              </div>
            </div>
          </div>
        </div>
        <div className="casino-meter-table-info">
          <span>Max Win: Stake x 50</span>
          <span>Max Lose: -(Stake x 50)</span>
        </div>
      </div>
      <LastResults/>
    </>
  )
}

export default connectCasinoMeter()(CasinoMeter)
