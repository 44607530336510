import React from 'react'
import "./TrapLastResult.scss"
import { useSelector } from 'react-redux'

const TrapLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const dataCardsA = data && data?.marketId?.marketRunner[0]?.cards
  const dataCardsB = data && data?.marketId?.marketRunner[1]?.cards

  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth

  return (
      <>
      {width > 1024
        ? (
        <div style={{display: 'flex', marginTop: '40px'}}>
        <div style={{display: 'flex',
          flexDirection: 'column',
          marginBottom: '54px',
          marginTop: '-20px'}}>
          <div className="popUpTrap-content__player-title">Player A</div>
          <div
            className={data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
                ? 'popUpTrap-content__results-cards-isWinner'
                : 'popUpTrap-content__results-cards'}>

            <img
              className="popUpTrap-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[0])}
              alt=""
            />
            {dataCardsA?.map((item, index) => {
              if (index > 0){
              return (
              <img
              className="popUpTrap-content__results-cards-card"
              src={cardSrc(item)}
              alt=""
              />
              )}
            })}
          </div>
        </div>

        <div style={{display: 'flex',
          flexDirection: 'column',
          marginBottom: '54px',
          marginLeft: '70px',
          marginTop: '-20px'}}>
          <div className="popUpTrap-content__player-title">Player B</div>
          <div
            className={data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
                ? 'popUpTrap-content__results-cards-isWinner'
                : 'popUpTrap-content__results-cards'}>
            <img
              className="popUpTrap-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[0])}
              alt=""
            />
            {dataCardsB?.map((item, index) => {
              if (index > 0){
              return (
              <img
              className="popUpTrap-content__results-cards-card"
              src={cardSrc(item)}
              alt=""
              />
              )}
            })}
          </div>
        </div>
        </div>
        )

      : (
        <>
          <div className="popUpTrap-content__wrapper">
            <div className="popUpTrap-content__player-title">Player A</div>
            <div
              className={data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
                ? 'popUpTrap-content__results-cards-isWinner'
                : 'popUpTrap-content__results-cards'}>

              <img
                className="popUpTrap-content__results-cards-card"
                src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[0])}
                alt=""
              />
              {dataCardsA?.map((item, index) => {
                if (index > 0){
                  return (
                    <img
                      className="popUpTrap-content__results-cards-card"
                      src={cardSrc(item)}
                      alt=""
                    />
                  )}
              })}
            </div>
          </div>

          <div className="popUpTrap-content__wrapper">
            <div className="popUpTrap-content__player-title">Player B</div>
            <div
              className={data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
                ? 'popUpTrap-content__results-cards-isWinner'
                : 'popUpTrap-content__results-cards'}
            >
              <img
                className="popUpTrap-content__results-cards-card"
                src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[0])}
                alt=""
              />
              {dataCardsB?.map((item, index) => {
                if (index > 0){
                  return (
                    <img
                      className="popUpTrap-content__results-cards-card"
                      src={cardSrc(item)}
                      alt=""
                    />
                  )}
              })}
            </div>

          </div>
      </>
      )
      }
    </>
  )
}
export default TrapLastResult
