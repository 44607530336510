import { createSlice } from '@reduxjs/toolkit'

export const openBetsSlice = createSlice({
    name: 'openBets',
    initialState: null,
    reducers: {
        getOpenBetsSuccess: (state, action) => action.payload,
        getOpenBetsError: (state) => null,
        
    },
})

export const {
    getOpenBetsError,
    getOpenBetsSuccess,
    setInfoDetailed,
} = openBetsSlice.actions

export default openBetsSlice.reducer
