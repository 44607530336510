import React from 'react'
import './RouletteLiveResult.scss'
import { connectRouletteLiveResult } from './RouletteLiveResult.connect'

const RouletteLiveResult = ({exchangeGames}) => {

  const ruletteNumber = {
    0: { color: 'zero', left: 26, right: 32 },
    32: { color: 'red', left: 0, right: 15 },
    15: { color: 'black', left: 32, right: 19 },
    19: { color: 'red', left: 15, right: 4 },
    4: { color: 'black', left: 19, right: 21 },
    21: { color: 'red', left: 4, right: 2 },
    2: { color: 'black', left: 21, right: 25 },
    25: { color: 'red', left: 2, right: 17 },
    17: { color: 'black', left: 25, right: 34 },
    34: { color: 'red', left: 17, right: 6 },
    6: { color: 'black', left: 34, right: 27 },
    27: { color: 'red', left: 6, right: 13 },
    13: { color: 'black', left: 27, right: 36 },
    36: { color: 'red', left: 13, right: 11 },
    11: { color: 'black', left: 36, right: 30 },
    30: { color: 'red', left: 11, right: 8 },
    8: { color: 'black', left: 30, right: 23 },
    23: { color: 'red', left: 8, right: 10 },
    10: { color: 'black', left: 23, right: 5 },
    5: { color: 'red', left: 10, right: 24 },
    24: { color: 'black', left: 5, right: 16 },
    16: { color: 'red', left: 24, right: 33 },
    33: { color: 'black', left: 16, right: 1 },
    1: { color: 'red', left: 33, right: 20 },
    20: { color: 'black', left: 1, right: 14 },
    14: { color: 'red', left: 20, right: 31 },
    31: { color: 'black', left: 14, right: 9 },
    9: { color: 'red', left: 31, right: 22 },
    22: { color: 'black', left: 9, right: 18 },
    18: { color: 'red', left: 22, right: 29 },
    29: { color: 'black', left: 18, right: 7 },
    7: { color: 'red', left: 29, right: 28 },
    28: { color: 'black', left: 7, right: 12 },
    12: { color: 'red', left: 28, right: 35 },
    35: { color: 'black', left: 12, right: 3 },
    3: { color: 'red', left: 35, right: 26 },
    26: { color: 'black', left: 3, right: 0 },
  }

  let winNumber;

  if (exchangeGames && exchangeGames[0].indexCard) {
    winNumber = parseInt(exchangeGames[0].indexCard[0])
  }

  return (
    <>
        {
         (winNumber && winNumber !== '' || winNumber === 0)  &&
          <div className={`rouletteLiveResult__winNumber`}>
            <div className={`rouletteLiveResult__winNumber-item rouletteLiveResult__winNumber-left ${ruletteNumber[ruletteNumber[winNumber].left].color}`}>
              <span>{ruletteNumber[winNumber].left}</span>
            </div>
            <div className={`rouletteLiveResult__winNumber-item rouletteLiveResult__winNumber-center ${ruletteNumber[winNumber].color}`}>
              <span>{winNumber}</span>
            </div>
            <div className={`rouletteLiveResult__winNumber-item rouletteLiveResult__winNumber-right ${ruletteNumber[ruletteNumber[winNumber].right].color}`}>
              <span>{ruletteNumber[winNumber].right}</span>
            </div>
          </div>
        }
    </>
  )
}

export default connectRouletteLiveResult()(RouletteLiveResult)
