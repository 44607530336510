import { createSlice } from '@reduxjs/toolkit'

export const eventPageSlice = createSlice({
    name: 'eventPage',
    initialState: {
        exposure: null,
        orders: null,
    },
    reducers: {
        getExposureSuccess: (state, action) => ({ ...state, exposure: action.payload }),
        getExposureError: (state) => ({ ...state, exposure: null }),
        getOrdersSuccess: (state, action) => ({ ...state, orders: action.payload }),
        getOrdersError: (state) => ({ ...state, orders: null }),
    },
})

export const {
    getExposureError,
    getExposureSuccess,
    getOrdersError,
    getOrdersSuccess,
} = eventPageSlice.actions

export default eventPageSlice.reducer
