import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getOrderCountError, getOrderCountSuccess } from './orderCount.slice'

export const requestOrderCount = () => async (dispatch, getState) => {
    const auth = getState().auth
    if(auth) {
        try {
            const userId = getState().user.memberCode;
            const response = await http.get(`/account/${userId}/order-count`)
            if (response.data.success) {
                dispatch(getOrderCountSuccess(response.data.result))
            }
            return response
        } catch (e) {
            dispatch(getOrderCountError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }
}
