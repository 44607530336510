export const isSignedUpWithNumberInBranchHelper = () =>{
  try {
    let userData = JSON.parse(localStorage.getItem('user_data'))

    return userData?.isBranchUser && userData?.wa && userData?.loginName.includes('91_');
  }
  catch (e) {
    return false;
  }

  return false;
}
