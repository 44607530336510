import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  getSportPageAllEventsError,
  getSportPageAllEventsSuccess,
} from './sportPageAllEvents.slice'


export const requestSportPageAllEvents = () => async (dispatch, getState) => {
  let allArr 
  const checkAuth = getState().auth

  if(checkAuth) {
    try {
      const response = await httpAuth.get(`/exchange/odds/eventType/1`)
      const response1 = await httpAuth.get(`/exchange/odds/eventType/2`)
      const response2 = await httpAuth.get(`/exchange/odds/eventType/4`)
  
      // const response3 = await http.get(`/exchange/odds/eventType/1`)
  
      if (response.data.success) {
        allArr = response.data.result.concat(response1.data.result).concat(response2.data.result)
        dispatch(getSportPageAllEventsSuccess(allArr))
      }
      return 
    } catch (e) {
      dispatch(getSportPageAllEventsError())
      // const errorMsg = getErrorMessage(e)
            // throw errorMsg
    }
  } else {
    try {
      const response = await httpNotAuth.get(`/exchange/odds/eventType/1`)
      const response1 = await httpNotAuth.get(`/exchange/odds/eventType/2`)
      const response2 = await httpNotAuth.get(`/exchange/odds/eventType/4`)
  
      // const response3 = await http.get(`/exchange/odds/eventType/1`)
  
      if (response.data.success) {
        allArr = response.data.result.concat(response1.data.result).concat(response2.data.result)
        dispatch(getSportPageAllEventsSuccess(allArr))
      }
      return 
    } catch (e) {
      dispatch(getSportPageAllEventsError())
      // const errorMsg = getErrorMessage(e)
            // throw errorMsg
    }
  }
  
  
}
