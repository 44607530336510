import React from 'react'
import "./QueenLastResult.scss"

const QueenLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }
  const getSumOfCards = (cards, bonusScore) => {
    let sum = 0;
    if (Array.isArray(cards)) {
      for (const card of cards) {
        sum += parseInt(card.substring(1));
      }
      if (sum === 0) {
        return
      }
      if (bonusScore) {
        sum = sum + bonusScore;
        return sum;
      } else {
        return sum;
      }
    }
  }

  return (
    <div className="pop-upLastResult-content-queen__results">
      <div className="pop-upLastResult-content-queen__results-item">
        <div className="pop-upLastResult-content-queen__results-name">Total 0</div>
        <div
          className={
            data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
              ? 'pop-upLastResult-content-queen__results-cards-isWinner'
              : 'pop-upLastResult-content-queen__results-cards'
          }
        >
          {data && data?.marketId?.marketRunner[0].cards[0] &&
            <img
              className="pop-upLastResult-content-queen__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0].cards[0])}
              alt=""
            />}
          {data && data?.marketId?.marketRunner[0].cards[1] &&
            <img
              className="pop-upLastResult-content-queen__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0].cards[1])}
              alt=""
            />}
          <span className="pop-upLastResult-content-queen__results-cards-card-score" >
           {getSumOfCards(data && data?.marketId?.marketRunner[0].cards)}
          </span>
        </div>
      </div>
      <div className="pop-upLastResult-content-queen__results-item">
        <div className="pop-upLastResult-content-queen__results-name">Total 1</div>
        <div
          className={
            data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
              ? 'pop-upLastResult-content-queen__results-cards-isWinner'
              : 'pop-upLastResult-content-queen__results-cards'
          }
        >
          {data && data?.marketId?.marketRunner[1].cards[0] &&
            <img
              className="pop-upLastResult-content-queen__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1].cards[0])}
              alt=""
            />}
          {data && data?.marketId?.marketRunner[1].cards[1] &&
            <img
              className="pop-upLastResult-content-queen__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1].cards[1])}
              alt=""
            />}
          <span className="pop-upLastResult-content-queen__results-cards-card-score" >
            {getSumOfCards(data && data?.marketId?.marketRunner[1].cards)}
          </span>
        </div>
      </div>
      <div className="pop-upLastResult-content-queen__results-item">
        <div className="pop-upLastResult-content-queen__results-name">Total 2</div>
        <div
          className={
            data && data?.marketId?.marketRunner[2]?.status === 'WINNER'
              ? 'pop-upLastResult-content-queen__results-cards-isWinner'
              : 'pop-upLastResult-content-queen__results-cards'
          }
        >
          {data && data?.marketId?.marketRunner[2].cards[0] &&
            <img
              className="pop-upLastResult-content-queen__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[2].cards[0])}
              alt=""
            />}
          {data && data?.marketId?.marketRunner[2].cards[1] &&
            <img
              className="pop-upLastResult-content-queen__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[2].cards[1])}
              alt=""
            />}
          <span className="pop-upLastResult-content-queen__results-cards-card-score" >
           {getSumOfCards(data && data?.marketId?.marketRunner[2].cards)}
          </span>
        </div>
      </div>
      <div className="pop-upLastResult-content-queen__results-item">
        <div className="pop-upLastResult-content-queen__results-name">Total 3</div>
        <div
          className={
            data && data?.marketId?.marketRunner[3]?.status === 'WINNER'
              ? 'pop-upLastResult-content-queen__results-cards-isWinner'
              : 'pop-upLastResult-content-queen__results-cards'
          }
        >
          {data && data?.marketId?.marketRunner[3].cards[0] &&
            <img
              className="pop-upLastResult-content-queen__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[3].cards[0])}
              alt=""
            />}
          {data && data?.marketId?.marketRunner[3].cards[1] &&
            <img
              className="pop-upLastResult-content-queen__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[3].cards[1])}
              alt=""
            />}
          <span className="pop-upLastResult-content-queen__results-cards-card-score" >
           {getSumOfCards(data && data?.marketId?.marketRunner[3].cards)}
          </span>
        </div>
      </div>
    </div>
  )
}
export default QueenLastResult
