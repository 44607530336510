import React from 'react'
import "./Race20LastResult.scss"
import smallSpades from "../../../../../assets/images/exchangeGames/race20/small-spades.svg"
import smallHearts from "../../../../../assets/images/exchangeGames/race20/small-hearts.svg"
import smallClubs from "../../../../../assets/images/exchangeGames/race20/small-clubs.svg"
import smallDiamonds from "../../../../../assets/images/exchangeGames/race20/small-diamonds.svg"

const Race20LastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return <img src={`https://stream.fawk.app/assets/smallCards/${card}.png`} alt="" />
    } else {
      return <img src="`https://stream.fawk.app/assets/smallCards/BACK.png`" alt="" />
    }
  }

  const renderPlayerItem = (icon, runnerIndex) => {
    return (
      <div className="pop-upLastResult-content__results-race20-item">
        <div className={`pop-upLastResult-content__results-race20-icon`}>
          <img src={icon} alt="" />
        </div>
        <div
          className={`pop-upLastResult-content__results-race20-cards ${data && data?.marketId?.marketRunner[runnerIndex]?.status }`}
        >
          {data && data.marketId && data.marketId.marketRunner &&
            (data.marketId.marketRunner[runnerIndex]?.cards.length
                ?
              data.marketId.marketRunner[runnerIndex]?.cards?.map((item) => cardSrc(item))
                :
              <img src="https://stream.fawk.app/assets/smallCards/BACK.png" alt="" />
            )
           }
        </div>
      </div>
    )
  }

  return (
    <div className={`pop-upLastResult-content__results-race20-items-wrapper`}>
      {renderPlayerItem(smallSpades, 0)}
      {renderPlayerItem(smallHearts, 1)}
      {renderPlayerItem(smallClubs, 2)}
      {renderPlayerItem(smallDiamonds, 3)}
    </div>
  )
}
export default Race20LastResult
