import "./MatkaPL.scss";
import React, { useEffect, useState } from 'react'
import cup from "../../../assets/images/TrophyTeenPattiTest.svg"

const MatkaPL = ({roundMarkets}) => {
const getMatkaResult = () => {
    const c1 = parseInt(roundMarkets[0].indexCard[0].slice(1));
    const c2 = parseInt(roundMarkets[0].indexCard[1].slice(1));
    const c3 = parseInt(roundMarkets[0].indexCard[2].slice(1));
    const mod10 = (c1 + c2 + c3) % 10;
    return  mod10 ;
  }

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="worli-matka-betting">
        <div className="worli-matka-betting__games">
          <div className="worli-matka-betting__games-item">
            <div className="worli-matka-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].indexCard.map( card =>
                <img key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
          <div className="worli-matka-betting__games-item matka-index">
            <img className="worli-matka-betting__games-item-cup" src={cup} alt="cup"/>
              <div className="worli-matka-betting__games-item-score">
                { getMatkaResult() }
              </div>
          </div>
        </div>
        <div className="worli-matka-betting__info-column">
          <div className="worli-matka-betting__info-row">
            <div className="worli-matka-betting__info-row-title">
              <span>Pana:</span>
            </div>
            <div className="worli-matka-betting__info-row-results">
              <span>{ roundMarkets[0]?.pana }</span>
            </div>
          </div>
          <div className="worli-matka-betting__info-row">
            <div className="worli-matka-betting__info-row-title">
              <span>Ocada:</span>
            </div>
            <div className="worli-matka-betting__info-row-results">
              <span>{ roundMarkets[0]?.ocada }</span>
            </div>
          </div>
        </div>
      </div>
    );
};

export default MatkaPL;

