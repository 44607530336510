import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestCasinoStart, requestCasinoEnd } from '../../store/casinoLive/casinoLive.thunks';

export const connectCasinoLive = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      casinoLive: state.casinoLive,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestCasinoStart,
      requestCasinoEnd,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
