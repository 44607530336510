import React from 'react'

import CasinoMeter1 from './images/CasinoMeter1.png'
import CasinoMeter2 from './images/CasinoMeter2.png'

const CasinoMeterRules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          The objective of Casino Meter is to predict which zone (Low Zone &
          High Zone) will have higher run score (total) of cards.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <div>
          <p>
            Casino Meter is played with a standard 52 cards deck. In this game
            11 cards will be dealt which will be divided into two zones. Low
            Zone & High Zone.
          </p>
          <p>
            The higher run score will be the winner, the winner will get 1.15
            rate of betting amount. Therefore, if a Player bets at the rate of
            100 Rs per run, & if he loses by 1 run then he will have to pay 100
            Rs, but if he wins by 1 (one) run, he will receive 115 Rs.
          </p>
          <p>
            The winning/losing runs for user according to his bet (either on Low
            Zone or High Zone) is shown by the ‘Run Position'.
          </p>
          <p>
            When user does not place any bet, Run Position will be not shown to
            the user.
          </p>
          <p></p>
          <p>Note: the difference of maximum 50 runs will be calculated.</p>
        </div>
        <div>
          <div>
            <h5>Low Zone:</h5>
            <div>
              <img style={{ width: '75%' }} src={CasinoMeter1} alt="Low" />
            </div>
            <p>
              The Players who bet on Low Zone will have all the cards from Ace
              to 8 of all suits plus 3 cards of 9, Heart, Club & Diamond.
            </p>
          </div>
          <div>
            <h5>High Zone:</h5>
            <div>
              <img style={{ width: '75%' }} src={CasinoMeter2} alt="High" />
            </div>
            <p>
              The Players who bet on High Zone will have all the cards of J, Q,
              K of all suits plus 3 cards of 10, Heart, Club & Diamond.
            </p>
          </div>
          <div>
            <h5>Value of Cards:</h5>
            <p>Ace to 10 - 1 to 10 Runs</p>
            <p>J - 11 Runs</p>
            <p>Q - 12 Runs</p>
            <p>K - 13 Runs</p>
          </div>
          <h3>Betting Instructions</h3>
          <div>
            <ul>
              <li>
                If a game round is in progress when you enter the game, please
                wait for the next one, and then, place your bets.
              </li>
              <li>After the timer reaches 0, the game round begins.</li>
              <li>
                To place a bet, click on the box, enter the stake amount and
                submit by clicking on Place Bet button.
              </li>
              <li>
                You can place several bets on different runners in different
                markets simultaneously.
              </li>
              <li>
                The timer in the game window shows how much time you have left
                to place your bets.
              </li>
              <li>
                Winnings are paid for the winning bets at the end of each game
                round.
              </li>
              <li>To skip a turn, simply do not place any bets.</li>
            </ul>
          </div>
        </div>
        <div>
          <h3>Main Bets</h3>
          <div>
            <ol>
              <li>Low Zone</li>
              <li>High Zone</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CasinoMeterRules
