import "./TheTrapPL.scss";
import React, { useEffect, useState } from 'react'

const TheTrapPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {
     highLow: '',
     pictureCard: ''
   };
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       if (market.marketHeader === 'High/Low' &&  market.indexCard[0].includes('7')) {
         winObj.highLow =  winObj.highLow.length <= 2 ? "None" : winObj.highLow + `, ${"None"}`;
       }
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
         if (market.marketHeader === 'High/Low' && runner.status === 'WINNER' && !market.indexCard[0].includes('7')) {
           winObj.highLow =  winObj.highLow.length <= 2 ? runner.name : winObj.highLow + `, ${runner.name}`;
         }
         if (market.marketHeader === 'Picture Card' && runner.status === 'WINNER') {
           if (runner.name === 'Picture Card (J, Q, K)') {
             winObj.pictureCard =  winObj.pictureCard.length <= 1 ? 'Yes' : winObj.pictureCard + `, Yes`;
           } else {
             winObj.pictureCard =  winObj.pictureCard.length <= 1 ? 'No' : winObj.pictureCard + `, No`;
           }
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets[0].roundId]);

  const getSumOfCards = (cards) => {
    return cards.reduce((acc, item) => acc + parseInt(item.substring(1)), 0);
  }

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="the-trap-betting">
        <div className="the-trap-betting__games">
          <div className="the-trap-betting__games-item">
            <span
              className={`the-trap-betting__games-item-title ${roundMarkets[0].marketRunner[0].status === 'WINNER' ? 'winnerCup':''}`}>
              Player A  ({getSumOfCards(roundMarkets[0].marketRunner[0].cards)})
            </span>
            <div className="the-trap-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[0]?.cards.map( card =>
                <img className="the-trap-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
          <div className="the-trap-betting__games-item">
            <span
              className={`the-trap-betting__games-item-title second ${roundMarkets[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}`}>
                Player B  ({getSumOfCards(roundMarkets[0].marketRunner[1].cards)})
            </span>
            <div className="the-trap-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[1]?.cards.map( card =>
                <img className="the-trap-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
        </div>
        {winnerData && <div className="the-trap-betting__info-column">
          <div className="the-trap-betting__info-row">
            <div className="the-trap-betting__info-row-title">
              <span>Winner: </span>
            </div>
            <div className="the-trap-betting__info-row-results">
              <span>{winnerData['Match Odds'] || 'No Winner'}</span>
            </div>
          </div>
          <div className="the-trap-betting__info-row">
            <div className="the-trap-betting__info-row-title">
              <span>High/Low: </span>
            </div>
            <div className="the-trap-betting__info-row-results">
              <span>{winnerData['highLow'] || 'No Winner'}</span>
            </div>
          </div>
          <div className="the-trap-betting__info-row">
            <div className="the-trap-betting__info-row-title">
              <span>Picture Card: </span>
            </div>
            <div className="the-trap-betting__info-row-results">
              <span className="muflis-teenpatti-betting__info-results-text">
                {winnerData['pictureCard'] || 'No Winner'}
              </span>
            </div>
          </div>
        </div>}
      </div>

    );
};

export default TheTrapPL;

