import React from 'react'

const HighLowRules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          The objective of High Low/ Higher Lower/ Hi-Low is to predict whether
          the Ante card (Player's card) will be higher, lower or of the same
          value as the Joker card of the game round. It is played with six card
          decks that are stored in a shuffling machine.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <div>
          <ul>
            <li>A round begins when the timer reaches 0.</li>
            <li>
              The Dealer deals one card from the shuffling machine which is the
              Ante card of the round.
            </li>
            <li>
              After the game round is over, the Joker card is returned into the
              shuffling machine. The Ante card of the round becomes the Joker
              card for the next round.
            </li>
          </ul>
          <p>
            If the Joker card is a 2, the <strong>Lower</strong> will be
            disabled for betting. If the Joker card is an Ace, then the{' '}
            <strong>Higher</strong> will be disabled for betting.
          </p>
        </div>
        <div>
          <h3>Card Ranking</h3>
          <p>
            The ranking of cards is, from lowest to highest: 2,3,4,5,6,7,8,9,10,
            Jack, Queen, King, Ace where Ace is "14".
          </p>
        </div>
        <div>
          <h3>Betting Instructions</h3>
          <ul>
            <li>
              If a game round is in progress when you enter the game, please
              wait for the next one, and then, place your bets.
            </li>
            <li>After the timer reaches 0, the game round begins.</li>
            <li>
              To place a bet, click on the box, enter the stake amount and
              submit by clicking on Place Bet button.
            </li>
            <li>
              You can place several bets on different runners in different
              markets simultaneously.
            </li>
            <li>
              The timer shows how much time you have left to place your bets.
            </li>
            <li>
              Winnings are paid for the winning bets at the end of each game
              round.
            </li>
            <li>To skip a turn, simply do not place any bets.</li>
          </ul>
        </div>
        <div>
          <h3>Main Bets</h3>
          <ol>
            <li>
              High Card: Predict if the Ante Card will be higher than the Joker
              Card
            </li>
            <li>
              Snap: Predict if the Ante Card will be same as the Joker Card
            </li>
            <li>
              Low Card: Predict if the Ante Card will be lower than the Joker
              Card
            </li>
          </ol>
        </div>
        <div>
          <h3>Side Bets</h3>
          <ol>
            <li>
              Black: Predict if the Ante Card will be of a Black suit (Spades or
              Clubs)
            </li>
            <li>
              Red: Predict if the Ante Card will be of a Red suit (Hearts or
              Diamonds)
            </li>
            <li>
              2, 3, 4, 5: Black: Predict if the Ante Card will be a 2, 3, 4 or 5
              (Independent of the suit)
            </li>
            <li>
              6, 7, 8, 9: Black: Predict if the Ante Card will be a 6, 7, 8, or
              9 (Independent of the suit)
            </li>
            <li>
              J, Q, K, A: Black: Predict if the Ante Card will be a Jack, Queen,
              King or Ace (Independent of the suit)
            </li>
          </ol>
        </div>
        <div>
          <h4>Payout</h4>
          <p>Payout depends on the type of bet placed.</p>
          <table>
            <tbody>
              <tr>
                <th>High Card</th>
                <th>Varies according to Joker Card</th>
              </tr>
              <tr>
                <th>Low Card</th>
                <th>Varies according to Joker Card</th>
              </tr>
              <tr>
                <th>Snap</th>
                <th>1: 11.06</th>
              </tr>
              <tr>
                <th>Black</th>
                <th>1: 0.95</th>
              </tr>
              <tr>
                <th>Red</th>
                <th>1: 0.95</th>
              </tr>
              <tr>
                <th>2, 3, 4, 5</th>
                <th>1: 1.89</th>
              </tr>
              <tr>
                <th>6, 7, 8, 9</th>
                <th>1: 1.89</th>
              </tr>
              <tr>
                <th>Jack, Queen, King, Ace</th>
                <th>1: 1.89</th>
              </tr>
            </tbody>
          </table>
          <p>
            Please note that any malfunction voids the game round and all
            eventual payouts for the round. Bets will be returned.
          </p>
        </div>
      </div>
    </div>
  )
}
export default HighLowRules
