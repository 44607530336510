import React, { useEffect } from "react";
import { useParams } from 'react-router-dom'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import {connectCasino32card} from "./Casino32card.connect";
// import BetPlacing from "../../BetPlacing/BetPlacing";
import CardsInfo from "../helpers/CardsInfo/CardsInfo";
import {GET_INPLAY_POPULAR_INTERVAL} from "../../../config/app.config";
import "./Casino32card.scss";

const Casino32card = ({
  sportEvent,
  betPlacing,
  actions,
  exchangeGames,
  exposure,
  activeTab,
  headerHeight,
  auth,
  elementsParams
 }) => {
    const dataOdd = sportEvent.sportData;
    const {eventId} = useParams();
  
    // window width
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  //get price
    const getMarketPrice = (marketIndex, runnersIndex, side = "back") => {
        if (dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex][side][0] &&
      dataOdd[marketIndex].runners[runnersIndex][side][0].price) {

            return dataOdd[marketIndex].runners[runnersIndex][side][0].price;
        }
    };
  //end get price
  // get exposure
    const getExposure = (marketIndex, runnerIndex) => {
        if(auth) {
          const id = sportEvent.sportData[marketIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex].id;
          let objFinder = exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl;
          return (
            <div
              className={
                objFinder > 0
                  ? "casino-exposure-box-modern casino-exposure-modern--pos"
                  : "casino-exposure-box-modern casino-exposure-modern--neg"
              }
            >
              {objFinder}
            </div>
          );
        } else return
    };
  // end get exposure
  // Show betslip
    // const renderBetPlacing = () => {
    //     if (betPlacing.runner && width < 1024) {
    //         return <BetPlacing  headerHeight={headerHeight}/>;
    //     }
    //     return null;
    // };
  // End Show betslip
  // Bet placing
    const select = (dataOdd, item, isBack) => {
        const runner = {name: item.name, id: item.id};
        const oddData = isBack
      ? {...item.back[0], runner}
      : {...item.lay[0], runner};
        const marketId = dataOdd && dataOdd.id;
        const runnerId = oddData && oddData.runner && oddData.runner.id;
        if (!marketId || !runnerId) return;
        actions.setMarket(marketId);
        actions.setRunner(runnerId);
        actions.setRunnerName(oddData && oddData.runner && oddData.runner.name);
        actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name);
        actions.setIsBack(isBack);
        actions.setPrice(oddData && oddData.price);
        actions.setPriceActual(oddData && oddData.price);
        actions.setDelay(dataOdd && dataOdd.betDelay);
        actions.setMtype(dataOdd && dataOdd.mtype);
        actions.setBtype(dataOdd && dataOdd.btype);
        actions.setLine(oddData && oddData.line);
        actions.setFromOneClick(false);
        actions.setStake(null);

        actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log("err = ", err));
    };
    const oddPush = (marketIndex, runnerIndex, isBack = true) => {
        return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
        );
    };
  // End Bet placing
  // lock market
    const resultStatus = (marketIndex, runner) => {
        if (exchangeGames && exchangeGames[marketIndex]?.marketRunner[runner]?.status === "WINNER") {
            return "lock-ui winner";
        }
        return !dataOdd[marketIndex].isBettable && "lock-ui";
    };
  // end lock market

    useEffect(() => {
        const timer = setIntervalAsync(async() => {
          await actions
                .requestExchangeGames(eventId)
                .catch((e) => console.log("requestInplayPopular error = ", e));
        }, GET_INPLAY_POPULAR_INTERVAL);
        return () => clearIntervalAsync(timer);

    }, []);

    return (
    <>
      <CardsInfo />
        <div className={`casino-32-card ${eventId === "56966" ? "virtual" : ""} ${activeTab === 0 ? "fullHeight" : ""}`}>
          <div className="casino-32-card-table">
            {/* <>{ width < 1024 ? renderBetPlacing() : "" }</> */}
            <div className="casino-32-card-table__players">
              <div className={`casino-32-card-table__player ${resultStatus(0, 0)}`}>
                <div className="casino-32-card-table__player-title">
                  <span>Player 8</span>
                  {getExposure(0,0)}
                </div>
                <div className="casino-32-card-table__player-sells">
                  <div className={`casino-32-card-table__player-sells-item back ${resultStatus(0, 0)}`} onClick={() => {oddPush(0,0);}}>
                    <span>{getMarketPrice(0,0)}</span>
                  </div>
                  <div className={`casino-32-card-table__player-sells-item lay ${resultStatus(0, 0)}`} onClick={() => {oddPush(0,0, false);}}>
                  <span>{getMarketPrice(0,0, "lay")}</span>
                  </div>
                </div>
              </div>
              <div className={`casino-32-card-table__player ${resultStatus(0, 1)}`}>
                <div className="casino-32-card-table__player-title">
                  <span>Player 9</span>
                  {getExposure(0,1)}
                </div>
                <div className="casino-32-card-table__player-sells">
                  <div className={`casino-32-card-table__player-sells-item back ${resultStatus(0, 1)}`} onClick={() => {oddPush(0,1);}}>
                    <span>{getMarketPrice(0,1)}</span>
                  </div>
                  <div className={`casino-32-card-table__player-sells-item lay ${resultStatus(0, 1)}`} onClick={() => {oddPush(0,1, false);}}>
                    <span>{getMarketPrice(0,1, "lay")}</span>
                  </div>
                </div>
              </div>
              <div className={`casino-32-card-table__player ${resultStatus(0, 2)}`}>
                <div className="casino-32-card-table__player-title">
                  <span>Player 10</span>
                  {getExposure(0,2)}
                </div>
                <div className="casino-32-card-table__player-sells">
                  <div className={`casino-32-card-table__player-sells-item back ${resultStatus(0, 2)}`} onClick={() => {oddPush(0,2);}}>
                    <span>{getMarketPrice(0,2)}</span>
                  </div>
                  <div className={`casino-32-card-table__player-sells-item lay ${resultStatus(0, 2)}`} onClick={() => {oddPush(0,2, false);}}>
                    <span>{getMarketPrice(0,2, "lay")}</span>
                  </div>
                </div>
              </div>
              <div className={`casino-32-card-table__player ${resultStatus(0, 3)}`}>
                <div className="casino-32-card-table__player-title">
                  <span>Player 11</span>
                  {getExposure(0,3)}
                </div>
                <div className="casino-32-card-table__player-sells">
                  <div className={`casino-32-card-table__player-sells-item back ${resultStatus(0, 3)}`} onClick={() => {oddPush(0,3);}}>
                    <span>{getMarketPrice(0,3)}</span>
                  </div>
                  <div className={`casino-32-card-table__player-sells-item lay ${resultStatus(0, 3)}`} onClick={() => {oddPush(0,3, false);}}>
                    <span>{getMarketPrice(0,3, "lay")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<LastResults/>*/}
        </div>
      </>
    );
};

export default connectCasino32card()(Casino32card);
