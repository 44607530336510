import "./HiLow.scss";
import * as hiLowImg from "./HiLowHelpers/helpers";
import {useEffect, useState} from "react";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
// import BetPlacing from "../../BetPlacing/BetPlacing";
import CardsInfo from "../helpers/CardsInfo/CardsInfo";
import {GET_INPLAY_POPULAR_INTERVAL} from "../../../config/app.config";
import LastResults from "../helpers/LastResults/LastResults";
import React from "react";
import {connectHiLow} from "./HiLow.connect";
import {useParams} from "react-router-dom";

const HiLow = ({
  sportEvent,
  betPlacing,
  actions,
  exchangeGames,
  exposure,
  activeTab,
  headerHeight,
  auth,
  elementsParams,
  heightForBetslip
 }) => {
    const dataOdd = sportEvent.sportData;
    const {eventId} = useParams();

  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidthh
  // end window width

  //get price
    const getMarketPrice = (marketIndex, runnersIndex, side = "back") => {
        if (dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex][side][0] &&
      dataOdd[marketIndex].runners[runnersIndex][side][0].price) {

            return dataOdd[marketIndex].runners[runnersIndex][side][0].price;
        }
    };
  //end get price

  // get exposure
    const getExposure = (marketIndex, runnerIndex) => {
      if(auth) {
        const id = sportEvent.sportData[marketIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex].id;
        let objFinder = exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl;
        // let objFinder = 200;
        if (objFinder) {
            return ( <div
            className={ objFinder > 0
                ? "hi-low-exposure-box-modern hi-low-exposure-modern--pos"
                : "hi-low-exposure-box-modern hi-low-exposure-modern--neg"
            }
          >
            {objFinder}
          </div>);
        }
      } else return
    };
  // end get exposure

  // Show betslip
    // const renderBetPlacing = () => {
    //     if (betPlacing.runner && width < 1024) {
    //       return <BetPlacing heightForBetslip={heightForBetslip}  headerHeight={headerHeight} />;
    //     }
    //     return null;
    // };
  // End Show betslip

  // Bet placing
    const select = (dataOdd, item, isBack) => {
        const runner = {name: item.name, id: item.id};
        const oddData = isBack
      ? {...item.back[0], runner}
      : {...item.lay[0], runner};
        const marketId = dataOdd && dataOdd.id;
        const runnerId = oddData && oddData.runner && oddData.runner.id;
        if (!marketId || !runnerId) return;
        actions.setMarket(marketId);
        actions.setRunner(runnerId);
        actions.setRunnerName(oddData && oddData.runner && oddData.runner.name);
        actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name);
        actions.setIsBack(isBack);
        actions.setPrice(oddData && oddData.price);
        actions.setPriceActual(oddData && oddData.price);
        actions.setDelay(dataOdd && dataOdd.betDelay);
        actions.setMtype(dataOdd && dataOdd.mtype);
        actions.setBtype(dataOdd && dataOdd.btype);
        actions.setLine(oddData && oddData.line);
        actions.setFromOneClick(false);
        actions.setStake(null);

        actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log("err = ", err));
    };
    const oddPush = (marketIndex, runnerIndex, isBack = true) => {
        return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
        );
    };
  // End Bet placing

  // lock market
    const resultStatus = (marketIndex, runner) => {
        if (exchangeGames && exchangeGames[marketIndex]?.marketRunner[runner]?.status === "WINNER") {
            return "lock-ui winner";
        }
       // return  "lock-ui winner "
        return !dataOdd[marketIndex].isBettable && "lock-ui";
    };
  // end lock market

    //request data
    useEffect(() => {
        const timer = setIntervalAsync(async () => {
          await actions
              .requestExchangeGames(eventId)
              .catch((e) => console.log("requestInplayPopular error = ", e));
        }, GET_INPLAY_POPULAR_INTERVAL);
        return () => clearIntervalAsync(timer);

    }, []);
  //end request data

    return (
    <>
      <CardsInfo />
        <div className={`hi-low ${eventId === "56969" ? "virtual" : ""} ${activeTab === 0 ? "fullHeight" : ""}`}>
          <div className="hi-low-table">
            {/* <>{ width < 1024 ? renderBetPlacing() : "" }</> */}
            <div className="hi-low-table__players-wrapper">
              <div className="hi-low-table__players-group-first">
                <div className={`hi-low-table__player ${resultStatus(0, 0)}`} onClick={() => {oddPush(0,0);}}>
                    <span className="hi-low-table__player-title">High Card</span>
                    <div className="hi-low-table__player-price">
                      <span className="hi-low-table__player-price-text">{getMarketPrice(0,0)}</span>
                      {getExposure(0,0)}
                    </div>
                </div>
                <div className={`hi-low-table__player ${resultStatus(0, 1)}`} onClick={() => {oddPush(0,1);}}>
                  <span className="hi-low-table__player-title">Snap</span>
                  <div className="hi-low-table__player-price">
                    {getMarketPrice(0,1)}
                    {getExposure(0,1)}
                  </div>
                </div>
                <div className={`hi-low-table__player ${resultStatus(0, 2)}`} onClick={() => {oddPush(0,2);}}>
                  <span className="hi-low-table__player-title">Low Card</span>
                  <div className="hi-low-table__player-price">
                    {getMarketPrice(0,2)}
                    {getExposure(0,2)}
                  </div>
                </div>
              </div>
              <div className="hi-low-table__players-group-second">
                <div className={`hi-low-table__player ${resultStatus(1, 0)}`} onClick={() => {oddPush(1,0);}}>
                  <div className="hi-low-table__player-images">
                    <img src={hiLowImg.hearts} alt="hearts" />
                    <img src={hiLowImg.diamonds} alt="diamonds" />
                  </div>
                  <div className="hi-low-table__player-price">
                    {getMarketPrice(1,0)}
                    {getExposure(1,0)}
                  </div>
                </div>
                <div className={`hi-low-table__player ${resultStatus(1, 1)}`} onClick={() => {oddPush(1,1);}}>
                  <div className="hi-low-table__player-images">
                    <img src={hiLowImg.spades} alt="spades" />
                    <img src={hiLowImg.clubs} alt="clubs"/>
                  </div>
                  <div className="hi-low-table__player-price">
                    {getMarketPrice(1,1)}
                    {getExposure(1,1)}
                  </div>
                </div>
              </div>
              <div className="hi-low-table__players-group-cards">
                <div className={`hi-low-table__player ${resultStatus(2, 0)}`} onClick={() => {oddPush(2,0);}}>
                  <div className="hi-low-table__player-images">
                    <img src={hiLowImg.c2} alt="c2" />
                    <img src={hiLowImg.c3} alt="c3"/>
                    <img src={hiLowImg.c4} alt="c4"/>
                    <img src={hiLowImg.c5} alt="c5"/>
                  </div>
                  <div className="hi-low-table__player-price">
                    {getMarketPrice(2,0)}
                    {getExposure(2,0)}
                  </div>
                </div>
                <div className={`hi-low-table__player ${resultStatus(2, 1)}`} onClick={() => {oddPush(2,1);}}>
                  <div className="hi-low-table__player-images">
                    <img src={hiLowImg.c6} alt="c6" />
                    <img src={hiLowImg.c7} alt="c7"/>
                    <img src={hiLowImg.c8} alt="c8"/>
                    <img src={hiLowImg.c9} alt="c9"/>
                  </div>
                  <div className="hi-low-table__player-price">
                    {getMarketPrice(2,1)}
                    {getExposure(2,1)}
                  </div>
                </div>
                <div className={`hi-low-table__player ${resultStatus(2, 2)}`}
                     onClick={() => {oddPush(2,2);}}
                >
                  <div className="hi-low-table__player-images">
                    <img src={hiLowImg.J} alt="J" />
                    <img src={hiLowImg.Q} alt="Q"/>
                    <img src={hiLowImg.K} alt="K"/>
                    <img src={hiLowImg.A} alt="A"/>
                  </div>
                  <div className="hi-low-table__player-price">
                    {getMarketPrice(2,2)}
                    {getExposure(2,2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LastResults/>
        </div>
    </>
    );
};

export default connectHiLow()(HiLow);
