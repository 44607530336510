import { createSlice } from '@reduxjs/toolkit'

export const UrlForSportbookSlice = createSlice({
  name: 'urlForSportbook',
  initialState: null,
  reducers: {
    setUrlForSportbook: (state, action) => action.payload,
  },
})

export const { setUrlForSportbook } = UrlForSportbookSlice.actions

export default UrlForSportbookSlice.reducer
