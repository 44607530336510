import React, { useState } from "react";
import "./MatchStats.scss";
import { connectMatchStats } from "./MatchStats.connect";
import { IonSlides, IonSlide } from "@ionic/react";

const matchStats = {
  requiredScoreMessage: "{{team}} need {{requiredScore}} runs",
  requiredRunsFromBallsMessage: " from {{remainingBalls}} balls",
  teamWonMessage: "Match won by {{team}}",
  oversAdjusted: " - reduced to {{oversNum}} overs",
  battingBowling: {
    title: "Stats",
    batsmen: "Batsmen",
    R: "R",
    B: "B",
    "4s": "4s",
    "6s": "6s",
    SR: "SR",
    bowler: "Bowler",
    O: "O",
    M: "M",
    W: "W",
    ECO: "ECO"
  },
  overs: "Overs",
  runRate: "CRR",
  partnership: "P'SHIP",
  projScr: "PROJ SCR",
  requiredRunRate: "RRR",
  lastWicket: "LAST WKT",
  optToBowl: "opt to bowl",
  optToBat: "opt to bat",
  BetweenInnings: "Innings Break",
  Review: "REVIEW",
  Drinks: "Drinks",
  Rain: "Rain",
  BadLight: "Bad light stopped play",
  Injury: "Injury timeout",
  Other: "Delay",
  InPlay: "In play",
  oversSummary: { over: "Ov", runs: " runs", run: " run", to: " to ", and: " and " },
  accordion: { closeTitle: "Hide Cricket Match Centre", openTitle: "Show Cricket Match Centre" }
};

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
    clickable: true
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  }
};

const MatchStats = ({
                      stats,
                      statsInVideoTab
                    }) => {
  const [sliderKey, setSliderKey] = useState("");
  const [currentOverNull, setCurrentOverNull] = useState("");
  /**
   * left section
   */
  const renderBalls = (balls) => {
    if (!balls.length) {
      return null;
    } else {
      return balls.map((ball, index) => {
        const ballClass = "INFIELDBOUNDARY" === ball.type ? ball.type + "_" + ball.value : ball.type;
        return <div key={index} className={`cricket-match-stats-over__ball ${ballClass}`}>
          {ball.value}
        </div>;
      });
    }
  };

  const getBatsNames = (batsNames) => {
    if (Array.isArray(batsNames)) return batsNames.join(" and ");
  };

  const renderOver = (over) => {
    if (!over) return null;
    const runs = over.runs > 1 ? "runs" : "run";
    return <div className="cricket-match-stats-over">
      <p title={`${over?.bowlerName} to ${over?.batsNames}`}
         className="cricket-match-stats-over__title">{over?.bowlerName} to {getBatsNames(over?.batsNames)}</p>
      <div className="cricket-match-stats-over__balls">
        {renderBalls(over?.balls)}
      </div>

      <div className="cricket-match-stats-over__runs">
        <div>Ov {over?.overNumber}</div>
        <span className="cricket-match-stats-over__runs__delimeter">|</span>
        <div>{over?.runs} {runs}</div>
        <span className="cricket-match-stats-over__runs__delimeter">|</span>
        <div>{over?.score}-{over?.wickets}</div>
      </div>
    </div>;
  };

  /**
   * right section
   */
  const getProjectedScoreOrRunRate = () => {
    if (stats?.currentInning > 1) {
      const e = stats?.battingTeam?.runRate;
      return { label: matchStats?.runRate, value: e || 0 === e ? e.toFixed(2) : null };
    }
    const t = stats?.battingTeam?.projScr;
    return { label: matchStats?.projScr, value: t || 0 === t ? t.toFixed(0) : null };
  };

  const getBatsmans = (batsmans) => {
    if (!batsmans.length) {
      return null;
    } else {
      return batsmans.map((batsman, index) => {
        return <div key={index} className="cricket-match-stats__scores__content">
          <div
            className="cricket-match-stats__scores__item athlete">{batsman?.name} {(batsman?.isOnStrike) ? "*" : ""}</div>
          <div className="cricket-match-stats__scores__item">{batsman?.batsmanRuns}</div>
          <div className="cricket-match-stats__scores__item">{batsman?.balls}</div>
          <div className="cricket-match-stats__scores__item">{batsman?.fours}</div>
          <div className="cricket-match-stats__scores__item">{batsman?.sixes}</div>
          <div className="cricket-match-stats__scores__item">{batsman?.strikeRate?.toFixed(2)}</div>
        </div>;
      });
    }
  };


  const generateKey = (currentOver) => {
    console.log("generating key");

    if (currentOverNull === "") {
      if (currentOver === null) {
        setCurrentOverNull("true");
        console.log("returning currentOverNull");
        return "currentOverNull";
      } else {
        console.log("returning currentOverNotNull");
        setCurrentOverNull("false");
        return "currentOverNotNull";
      }
    } else {
      if (currentOverNull === "true" && currentOver != null) {
        console.log("returning currentOverNotNull");
        setCurrentOverNull("false");
        return "currentOverNotNull";
      } else if (currentOverNull === "false" && currentOver == null) {
        console.log("returning currentOverNull");
        setCurrentOverNull("true");
        return "currentOverNull";
      } else {
        if (currentOverNull === "true") {
          console.log("returning currentOverNull");
          return "currentOverNull";
        } else {
          console.log("returning currentOverNotNull");
          return "currentOverNotNull";
        }
      }
    }
  };

  return <section className={`cricket-match-stats ${statsInVideoTab ? "statsInVideoTab" : ""}`}>
    <IonSlides key={generateKey(stats?.currentOver, stats?.lastOver, stats?.battingTeam)} pager={true}
               options={slideOpts} className="cricket-match-stats-slides">
      {stats?.currentOver && (
        <IonSlide>
          <div className="cricket-match-stats-overs">
            {stats?.currentOver && renderOver(stats?.currentOver)}
            {stats?.lastOver && renderOver(stats?.lastOver)}
          </div>
        </IonSlide>
      )}
      <IonSlide>
        <div className="cricket-match-stats__second">
          {stats?.battingTeam !== null &&
            (<div className="cricket-match-stats__statistics">
              <div className="cricket-match-stats__statistics__group">
                <div
                  className="cricket-match-stats__statistics__group__label">{getProjectedScoreOrRunRate().label}</div>
                <div
                  className="cricket-match-stats__statistics__group__value">{getProjectedScoreOrRunRate().value}</div>
              </div>
              <div className="cricket-match-stats__statistics__group">
                <div className="cricket-match-stats__statistics__group__label">P'SHIP</div>
                <div className="cricket-match-stats__statistics__group__value">
                  {stats?.battingTeam?.partnerShipScore}
                  {stats?.battingTeam?.partnerShipBalls && <span>&nbsp;({stats?.battingTeam?.partnerShipBalls})</span>}
                </div>
              </div>
              <div className="cricket-match-stats__statistics__group">
                <div
                  className="cricket-match-stats__statistics__group__label">{stats?.battingTeam?.requiredRunRate === null ? "CRR" : "RRR"}</div>
                <div
                  className="cricket-match-stats__statistics__group__value">{stats?.battingTeam?.requiredRunRate === null ? stats?.battingTeam?.runRate : stats?.battingTeam?.requiredRunRate?.toFixed(2)}</div>
              </div>
              <div className="cricket-match-stats__statistics__group">
                <div className="cricket-match-stats__statistics__group__label">LAST WKT</div>
                <div
                  className="cricket-match-stats__statistics__group__value">{stats?.lastBatsmanOut ? `${stats?.lastBatsmanOut?.name} ${stats?.lastBatsmanOut?.batsManRuns} (${stats?.lastBatsmanOut?.balls})` : stats?.lastBatsman}</div>
              </div>
            </div>)
          }

          {stats?.bowler && stats?.batsmen?.length > 0 &&
            <>
              <div className="cricket-match-stats__scores">
                <div className="cricket-match-stats__scores__header">
                  <div className="cricket-match-stats__scores__item athlete">Batsmen</div>
                  <div className="cricket-match-stats__scores__item">R</div>
                  <div className="cricket-match-stats__scores__item">B</div>
                  <div className="cricket-match-stats__scores__item">4s</div>
                  <div className="cricket-match-stats__scores__item">6s</div>
                  <div className="cricket-match-stats__scores__item">SR</div>
                </div>
                {getBatsmans(stats.batsmen)}
              </div>

              <div className="cricket-match-stats__scores">
                <div className="cricket-match-stats__scores__header">
                  <div className="cricket-match-stats__scores__item athlete">Bowler</div>
                  <div className="cricket-match-stats__scores__item">O</div>
                  <div className="cricket-match-stats__scores__item">M</div>
                  <div className="cricket-match-stats__scores__item">R</div>
                  <div className="cricket-match-stats__scores__item">W</div>
                  <div className="cricket-match-stats__scores__item">Eco</div>
                </div>
                <div className="cricket-match-stats__scores__content">
                  <div className="cricket-match-stats__scores__item athlete">{stats?.bowler?.name}</div>
                  <div className="cricket-match-stats__scores__item">{stats?.bowler?.overs}</div>
                  <div className="cricket-match-stats__scores__item">{stats?.bowler?.maidens}</div>
                  <div className="cricket-match-stats__scores__item">{stats?.bowler?.bowlerRuns}</div>
                  <div className="cricket-match-stats__scores__item">{stats?.bowler?.wickets}</div>
                  <div className="cricket-match-stats__scores__item">{stats?.bowler?.economy?.toFixed(2)}</div>
                </div>
              </div>
            </>
          }
        </div>
      </IonSlide>
    </IonSlides>
  </section>;
};

export default connectMatchStats()(MatchStats);
