import bannerSportsbook from '../assets/images/banners/sport-banner-sportsbook.svg'
export const headerType = {
    'INPLAY': 'inplay',
    'POPULAR': 'popular',
    'FUTURE': 'future'
}


export const headerMap = {
    inplay: {
        icon: '\uF144',
        title: 'In Play',
        showBets: true,
        banner: bannerSportsbook,
        bannerClass: 'sportsbook',
        bannerLink: '/sportsbook/1444005',
    },
    popular: {
        icon: '\ue903',
        title: 'Upcoming Events'
    },

    future: {
        // icon: '',
        title: 'Future Matches'
    }


}

