const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'Jule',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export const getMonthName = (month) => {
    const index = Number(month)
    return months[index]
}
export const getZonedTime = (timezone, time) => {
    let date = time ? new Date(time) : new Date()
    if (timezone === 'system') date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    if (timezone === 'india') date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 330)

    return date
}

export const formatTime = (time) => time < 10 ? `0${time}` : time;

export const timeOpens = (startTime, isDate) => {
    const time = isDate ? new Date(startTime) : startTime
    if (time > Date.now()) {
        const fullMinutes = Math.round((time - Date.now()) / 1000 / 60)
        const fullHours = Math.floor(fullMinutes / 60);
        const days = Math.floor(fullMinutes / 60 / 24);
        const hours = fullHours - days * 24;
        const minutes = fullMinutes - fullHours * 60;
        return `(Opens in ${days ? `${days} day${days > 1 ? "s" : ''}` : ""} ${hours ? `${hours} hour${hours > 1 ? "s" : ''}` : ""} ${minutes} minute${minutes > 1 ? "s" : ""})`
    }
    else {
        return '';
    }
};

export const getFormattedDate = (dateStr, timezone) => {
    if(!dateStr)
        return
        
    const dateObj = getZonedTime(timezone, dateStr)
    const date = isToday(dateObj, timezone) ? 'Today' : isTomorrow(dateObj, timezone) ? 'Tomorrow': dateObj.toLocaleDateString(
        'en-gb'
    );
    const time = Intl.DateTimeFormat('en', { hour: "numeric", minute: "numeric", hour12: false }).format(dateObj)

    return {
        date: date,
        time: time.replace(/24/, '00')
    }
}

export const isToday = (someDate, timezone) => {
    const today = getZonedTime(timezone)
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}

export const isTomorrow = (someDate, timezone) => {
    const today = getZonedTime(timezone)
    const tomorrow = new Date(today.setDate(today.getDate() + 1))
    return someDate.getDate() === tomorrow.getDate() &&
        someDate.getMonth() === tomorrow.getMonth() &&
        someDate.getFullYear() === tomorrow.getFullYear()
}

export const getOrdinalNum = (n) => {
    return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
}

export const timeToDate = (time) => {
    const d = new Date(time);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d);

    return {
        time: new Date(`${da} ${mo} ${ye}`).getTime(),
        dateString: `${getOrdinalNum(da)} ${mo} ${ye}`,
        hours: new Intl.DateTimeFormat('en', { timeStyle: 'short', hour12: false, }).format(d)
    }
}

export const tsToDate = (ts, timezone) => {
    const date = getZonedTime(timezone, ts)

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${formatTime(date.getMinutes())}:${formatTime(date.getSeconds())}`
}

export const daysFromTo = (from, to) => {
    if(!from && !to) return 
    if(!from) return
    if(!to) return 

    let differenceInTime = from.getTime() - to.getTime();
    let differenceInDays = differenceInTime / (1000*3600*24)
    return -differenceInDays + 1
}