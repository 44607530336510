import { createSlice } from '@reduxjs/toolkit'

export const openBetsListSlice = createSlice({
    name: 'openBetsList',
    initialState: {
        detailed: false,
        event: false,
        placedBet: false,
        reset: false,
        unmatchedChanged: false,
    },
    reducers: {
        setInfoDetailed: (state, action) => ({ ...state, detailed: action.payload }),
        setThisEvent: (state, action) => ({ ...state, event: action.payload }),
        setPlacedBet: (state, action) => ({ ...state, placedBet: action.payload }),
        setResetUnmatched: (state, action) => ({ ...state, reset: action.payload }),
        setUnmatchedChanged: (state, action) => ({ ...state, unmatchedChanged: action.payload }),



    },
})

export const {
    setInfoDetailed,
    setThisEvent,
    setPlacedBet,
    setResetUnmatched,
    setUnmatchedChanged,
} = openBetsListSlice.actions

export default openBetsListSlice.reducer
