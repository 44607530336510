import React from 'react'
import './MuflisTeenpattiCards.scss'
import { connectMuflisTeenpatti } from '../../MuflisTeenpatti.connect'

const MuflisTeenpattiCards = ({ exchangeGames }) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }
      return (
        <div className='muflis-teenpatti-cards'>
          <div className="muflis-teenpatti-cards__player">
            <div className='muflis-teenpatti-cards__player-name'>Player A</div>
            <div className="muflis-teenpatti-cards__player-cards">
                <img className="muflis-teenpatti-cards__player-cards-card"
                  src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0])} alt=""
                />
                <img
                  className="muflis-teenpatti-cards__player-cards-card"
                  src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1])} alt=""
                />
                <img
                  className="muflis-teenpatti-cards__player-cards-card"
                  src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[2])} alt=""
                />
              </div>
          </div>
          <div className="muflis-teenpatti-cards__player">
            <div className="muflis-teenpatti-cards__player-cards">
              <img
                className="muflis-teenpatti-cards__player-cards-card"
                src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0])} alt=""
              />
              <img
                className="muflis-teenpatti-cards__player-cards-card"
                src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1])} alt=""
              />
              <img
                className="muflis-teenpatti-cards__player-cards-card"
                src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[2])} alt=""
              />
            </div>
            <div className='muflis-teenpatti-cards__player-name second-player'>Player B</div>
          </div>
        </div>
      )
  }

export default connectMuflisTeenpatti()(MuflisTeenpattiCards)
