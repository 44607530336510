import React from 'react'
import './BollywoodCasinoCards.scss'
import { useSelector } from 'react-redux'
import { connectBollywoodCasino } from '../../BollywoodCasino.connect' 
import { useParams } from 'react-router-dom'
import RoundTimer from '../../../helpers/RoundTimer/RoundTimer'


const BollywoodCasinoCards = ({ exchangeGames }) => {

  // console.log('asa', exchangeGames);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }


      return (
        <div className='BollywoodCasinoCardsWrapper'>
          <div className='BollywoodCasinoCardsContent'>
          <div className="BollywoodCasinoCardsContent-total">
            <div className="BollywoodCasinoCardsContent-total__cards">
              <img
                className="BollywoodCasinoCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.indexCard[0]
                )}
                alt=""
              />
            </div>
          </div>
          </div>
        </div>
      )
  }


export default connectBollywoodCasino()(BollywoodCasinoCards)
