import { createSlice } from '@reduxjs/toolkit'

export const balanceSettingsSlice = createSlice({
  name: 'balanceSettings',
  initialState: null,
  reducers: {
    getBalanceSettingsSuccess: (state, action) => action.payload,
    getBalanceSettingsError: (state) => state,
  },
})

export const {
  getBalanceSettingsSuccess,
  getBalanceSettingsError,
} = balanceSettingsSlice.actions

export default balanceSettingsSlice.reducer
