import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { IonContent,  IonPage, IonHeader, useIonViewWillLeave } from '@ionic/react'
import './Withdrawal.scss'
import PopUPDelete from './DeletePopUp'
import Toolbar from '../../components/Toolbar/Toolbar'
import QuestionImg from '../../assets/images/questionImg.svg'
import deleteButton from '../../assets/images/deleteButton.svg'
import PopUPWithdrawalHelp from './WithdrawalHelpPopUp'
import { connectWithdrawal } from './Withdrawal.connect'
import { BASE_DEV_URL } from '../../config/api.config'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import getErrorMessage from '../../helpers/getErrorMessage.helper'

const Withdrawal = ({ user, actions, balance, elementsParams, ...props }) => {
  const history = useHistory()
  const token = localStorage.getItem('id_token')

  const [withdrawAccount, setWithdrawAccount] = useState('')
  const [message, setMessage] = useState('')
  const [digits, setDigits] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [infoIFSC, setInfoIFSC] = useState({})
  const [withdrawalAmount, setwithdrowalAmount] = useState(0)
  const [withdrawalIFSC, setWithdrawalIFSC] = useState()
  const [withdrawalAccNumber, setWithdrawalAccNumber] = useState()
  const [withdrawalAccName, setWithdrawalAccName] = useState()
  const [isRulesAccept, setIsRulesAccept] = useState(false)
  const [isNamesMatch, setisNamesMatch] = useState(false)
  const [isEnoughMoney, setIsEnoughMoney] = useState(false)
  const [itemIFSC, setItemIFSC] = useState()
  const [nameMessage, setNameMessage] = useState('')
  const [openPopUpDelete, setOpenPopUpDelete] = useState(false)
  const [openPopUpHelp, setOpenPopUpHelp] = useState(false)
  const [inputesFromHistory, setInputesFromHistory] = useState(false)
  const [inputesCustom, setInputesCustom] = useState(false)
  const [confirmAccNumber, setConfirmAccNumber] = useState('');
  const [submitBtnDisabled, setSubmitBtnDisabled ] = useState(false)

  const data = {
    amount: withdrawalAmount,
    acNo: withdrawalAccNumber,
    IFSC: withdrawalIFSC,
    bankName: infoIFSC?.BANK,
    branchName: infoIFSC?.BRANCH,
    accountName: withdrawalAccName,
  }

  const withrawRequest = async () => {
    if (
      !isRulesAccept &&
      isEnoughMoney &&
      withdrawalIFSC?.length === 11 &&
      withdrawalAccNumber &&
      isNamesMatch &&
      withdrawalAccName

    ) {
      console.log(
        'rules accepted',
        isRulesAccept,
        'amount',
        withdrawalAmount,
        'IFSC',
        withdrawalIFSC,
        'accNumber',
        withdrawalAccNumber,
        'Accname',
        withdrawalAccName
      )
      setSubmitBtnDisabled(true)

      data.IFSC = data?.IFSC?.toString()?.toUpperCase();

      return await axios
        .post(BASE_DEV_URL + '/payments/userid/withdraw-request', data, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) =>
          setTimeout(() => {
          setSubmitBtnDisabled(false)
           history.push('/thanksWithdrawal')
          }, 500)
        )
        // .catch((error) => toastr.error('Error', error?.response?.data?.result))
        .catch((error) => {
          setSubmitBtnDisabled(false)
          toastr.error('Error', error?.response?.data?.error)
          const errorMsg = getErrorMessage(error)
          throw errorMsg
        })
    }
  }

  const codeIFSC = async (e) => {
    return await axios
      .get(BASE_DEV_URL + `/payments/userid/ifsc?ifsc=${e}`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => setInfoIFSC(response.data.result))
      .catch((e) => {
        setInfoIFSC()
        const errorMsg = getErrorMessage(e)
        throw errorMsg
      })
  }
  const [prevAccounts, setPrevAccounts] = useState()

  const codeAcc = async () => {
    return await axios
      .get(BASE_DEV_URL + '/payments/userid/account-list', {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) =>
        response.data.result
          ? setPrevAccounts(response.data.result)
          : (toastr.info('', 'You have no accounts yet'), setPrevAccounts())
      )
      .catch((e) => {
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
      })
  }

  useEffect(() => {
    setTimeout(() => {
      if (itemIFSC?.IFSCCode?.length === 11) {
        codeIFSC(itemIFSC?.IFSCCode)
      }
    })
  }, [itemIFSC?.IFSCCode])

  useEffect(() => {
    if (prevAccounts) {
      setWithdrawalIFSC()
      setWithdrawalAccName()
      setisNamesMatch(false)
      setWithdrawalAccNumber()
      setWithdrawalIFSC(itemIFSC?.IFSCCode)
      setWithdrawalAccName(itemIFSC?.accountName)
      setisNamesMatch(true)
      setWithdrawalAccNumber(itemIFSC?.accountNo)
    }
  }, [itemIFSC])

  const onValidationAmount = (e) => {
    if (balance?.availBalance < e) {
      setMessage('You don`t have enough money on your balance')
      // setTimeout(() => {
      //   setMessage('')
      // }, 3000)
      // setIsEnoughMoney(false)
    } else if (e < 0) {
      setwithdrowalAmount(e)
      setMessage("Amount can't be negative")
      setIsEnoughMoney(false)
    } else if (e < 1) {
      setwithdrowalAmount(e)
      setMessage("Amount can't be empty")
      setIsEnoughMoney(false)
    } else if (e < 300) {
      setwithdrowalAmount(e)
      setMessage("Amount can't be less than 300")
      setIsEnoughMoney(false)
    } else {
      setwithdrowalAmount(e)
      setMessage('')
      setIsEnoughMoney(true)
    }
  }

  const onValidationIFSC = (e) => {
    setWithdrawalIFSC(e)
    if (e.length === 11) {
      codeIFSC(e)
      setDigits()
    } else {
      setInfoIFSC()
      setDigits('IFSC should be 11 digits')
    }
  }

  const onNewValidationIFSC = (e) => {
    setWithdrawalIFSC(e)
    if (e.length === 11) {
      codeIFSC(e)
      setDigits()
    } else {
      setInfoIFSC()
      setDigits('IFSC should be 11 digits')
    }
    // if(e.length >= 1 && e.length !== 11){
    //   setDigits('IFSC should be 11 digits')
    // } else{
    //   setDigits()
    // }
  }

  const onAccNumber = (e) => {
    setWithdrawalAccNumber(e)
  }

  const onAccName = (e) => {
    if (e.length < 32) {
      setWithdrawalAccName(e)
      setNameMessage('')
    } else {
      setNameMessage('Account name should be less than 32 digits')
    }
  }

  const onNewAccNumber = (e) => {
    setWithdrawalAccNumber(e)
  }

  const onNewAccName = (e) => {
    if (e.length < 32) {
      setWithdrawalAccName(e)
      setNameMessage('')
    } else {
      setNameMessage('Account name should be less than 32 digits')
    }
  }

  const onValidationAccnumber = (e) => {
    setConfirmAccNumber(e)
    // console.log('onValidationAccnumber', e)
    if (e.length > 0 && e !== withdrawalAccNumber) {
      setAccountNumber('Account number should match')
      setisNamesMatch(false)
      // console.log('isNamesMatch', isNamesMatch)
    } else {
      setAccountNumber('')
      setisNamesMatch(true)
      // console.log(isNamesMatch)
    }
  }

  useEffect(() => {
    setWithdrawalAccNumber()
    setWithdrawalAccName()
    setWithdrawalIFSC()
    setisNamesMatch(false)
    setWithdrawAccount('')
    setItemIFSC({})
    setIsRulesAccept(false)
    setInfoIFSC()
    setDigits()
  }, [inputesCustom])

  useIonViewWillLeave(() => {
    setInputesCustom(false)
    setInputesFromHistory(false)
  })

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  // Fields check
  let [withdrawalAmountCheck, setWithdrawalAmountCheck] = useState('');
  let [withdrawalIFSCCheck, setWithdrawalIFSCCheck] = useState('');
  let [withdrawalAccNumberCheck, setWithdrawalAccNumberCheck] = useState('');
  let [withdrawalAccNameCheck, setWithdrawalAccNameCheck] = useState('');
  let [confirmAccNumberCheck, setConfirmAccNumberCheck] = useState('');
  let [isRulesAcceptCheck, setIsRulesAcceptCheck] = useState('');

  const fieldCheck = () => {
    !withdrawalAmount ? setWithdrawalAmountCheck(false) : setWithdrawalAmountCheck('')
    !withdrawalIFSC ? setWithdrawalIFSCCheck(false) : setWithdrawalIFSCCheck('')
    !withdrawalAccNumber ? setWithdrawalAccNumberCheck(false) : setWithdrawalAccNumberCheck('')
    !withdrawalAccName ? setWithdrawalAccNameCheck(false) : setWithdrawalAccNameCheck('')
    confirmAccNumber.length < 1 ? setConfirmAccNumberCheck(false) : setConfirmAccNumberCheck('')
    !isRulesAccept ? setIsRulesAcceptCheck(false) : setIsRulesAcceptCheck('')
  }
  // End Fields check

  return (
    <IonPage className={openPopUpHelp && 'scrollOff ion-page-invisible'}>
      {width < 1024 ? (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      ) : (
        <div style={{height: "135px"}}></div>
      )}
      { width < 1024 ? <IonContent className="ChangePassword" fullscreen>
        <form action="">
          <div className="withdraw-screen">
            <div className="withdraw-desktop">
              <div className="withdraw-desktop__leftpage">
                <h2 className="page-title">Withdraw funds</h2>
                <div
                  className="page-help"
                  onClick={() => setOpenPopUpHelp(true)}
                >
                  <span className="page-help__text">Help</span>
                  <img className="page-help__img" src={QuestionImg} alt="" />
                </div>
              </div>
              <div className="withdraw-desktop__rightpage">
                <div className="page-subtitle notes">
                  <p>1. This form is for withdrawing the amount from the main wallet only.</p>
                  <p>2. The bonus wallet amount cannot be withdrawn by this form.</p>
                  <p>3. Do not put Withdraw request without betting with deposit amount. Such activity will be identified as Suspicious</p>
                  <p>4. If multiple users are using same withdraw account then all the linked users will be blocked.</p>
                  <p>5. Paytm account numbers always start with 91</p>
                </div>
                <div className="page-subtitle subtitle-hidden">
                  Please fill in all required fields
                </div>
                <div className="buttons-flex">
                  {width >= 1024 ? (
                    <button
                      type="button"
                      onClick={() => {
                        setInputesCustom(true)
                        setInputesFromHistory(false)
                        setWithdrawAccount()
                      }}
                      className="pushable"
                    >
                      <span className="shadow"></span>
                      <span className="edge refreshBtn"></span>
                      <span className="front refreshBtn">Use New Account</span>
                    </button>
                  ) : (
                    <div
                      className="withdraw-account__newitem "
                      onClick={() => {
                        setInputesCustom(true)
                        setInputesFromHistory(false)
                        setWithdrawAccount()
                        setIsRulesAccept(true)
                      }}
                    >
                      <div className="withdraw-account__newitem-title">
                        Use New Account
                      </div>
                    </div>
                  )}
                  {width >= 1024 ? (
                    <button
                      type="button"
                      onClick={() => {
                        setInputesCustom(false)
                        setInputesFromHistory(true)
                        codeAcc()
                      }}
                      className="pushable"
                    >
                      <span className="shadow"></span>
                      <span className="edge refreshBtn"></span>
                      <span className="front refreshBtn">
                        Use Previous Account
                      </span>
                    </button>
                  ) : (
                    <div
                      className="withdraw-account__newitem"
                      onClick={() => {
                        setInputesCustom(false)
                        setInputesFromHistory(true)
                        codeAcc()
                      }}
                    >
                      <div className="withdraw-account__newitem-title">
                        Use Previous Account
                      </div>
                    </div>
                  )}
                </div>
                <div className="withdraw-account">
                  {/* TODO: map items and dispay data from api if exists */}

                  <div className="withdraw-account__flex">
                    {inputesFromHistory && prevAccounts?.length > 0
                      ? prevAccounts.map((item) => {
                          return (
                            <label
                              htmlFor={item.accountId}
                              className={
                                withdrawAccount === item.accountId
                                  ? 'withdraw-account__item-checked'
                                  : 'withdraw-account__item'
                              }
                              onClick={() => {
                                setWithdrawAccount(item.accountId)
                                setInputesFromHistory(true)
                                setInputesCustom(false)
                              }}
                            >
                              <div
                                className="withdraw-account__item-content"
                                onClick={() => setItemIFSC(item)}
                              >
                                <div className="withdraw-account__item-content__account">
                                  {item.accountName}
                                </div>
                                <div className="withdraw-account__item-content__account">
                                  ********
                                  {item.accountNo
                                    ? item.accountNo.slice(-4)
                                    : ''}
                                </div>
                                <div className="withdraw-account__item-content__IFSC">
                                  {item.bankName}
                                  <input
                                    className="check-account"
                                    type="radio"
                                    name="choosenaccount"
                                    checked={withdrawAccount === item.accountId}
                                    id={item.accountId}
                                  />
                                  <img
                                    className="delete-account"
                                    src={deleteButton}
                                    alt=""
                                    onClick={() => setOpenPopUpDelete(true)}
                                  />
                                </div>
                              </div>
                            </label>
                          )
                        })
                      : null}
                  </div>
                </div>

                {/* PREVIOUS ACCOUNTS */}

                {inputesFromHistory && itemIFSC && prevAccounts && (
                  <div className="withdraw-desktop__rightpage-inputcontent">
                    <div className="withdraw-input-content">
                      <div className="page-subtitle">Amount*</div>
                      <input
                        id="Amount"
                        className="withdraw-textarea"
                        type="number"
                        placeholder="0"
                        value={withdrawalAmount ? withdrawalAmount : null}
                        onChange={(e) => onValidationAmount(e.target.value)}
                      />
                      <span className="withdraw-textarea__currency">INR</span>
                      <div className="withdraw-amaount-buttons">
                        {width >= 1024 ? (
                          <>
                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 1000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 1000)
                                }
                              }}
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge refreshBtn"></span>
                              <span className="front refreshBtn">+1,000</span>
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 5000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 5000)
                                }
                              }}
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge refreshBtn"></span>
                              <span className="front refreshBtn">+5,000</span>
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 10000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 10000)
                                }
                              }}
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge refreshBtn"></span>
                              <span className="front refreshBtn">+10,000</span>
                            </button>
                          </>
                        ) : (
                          <>
                            <div
                              className="withdraw-amaount-buttons__value"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 1000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 1000)
                                }
                              }}
                            >
                              +1,000
                            </div>

                            <div
                              className="withdraw-amaount-buttons__value"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 5000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 5000)
                                }
                              }}
                            >
                              +5,000
                            </div>
                            <div
                              className="withdraw-amaount-buttons__value"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 10000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 10000)
                                }
                              }}
                            >
                              +10,000
                            </div>
                          </>
                        )}
                      </div>
                      <div className="validation-message">{message}</div>
                    </div>
                    <div className="withdraw-input-content">
                      <div className="page-subtitle">IFSC Code*</div>
                      <input
                        className="withdraw-textarea"
                        type="text"
                        // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                        onChange={(e) => onValidationIFSC(e.target.value)}
                        // defaultValue={itemIFSC?.IFSCCode}
                        disabled={true}
                        value={itemIFSC?.IFSCCode}
                      />
                      <div className="validation-message">{digits}</div>
                      {infoIFSC ? (
                        <div className="withdraw-textarea__description">
                          {infoIFSC?.BANK}, {infoIFSC?.BRANCH}, {infoIFSC?.CITY}
                        </div>
                      ) : null}
                    </div>
                    <div className="withdraw-input-content ">
                      <div className="page-subtitle">Account number*</div>
                      <input
                        id="account"
                        className="withdraw-textarea hidden-accnumber"
                        type="text"
                        placeholder="*************"
                        disabled={true}
                        onChange={(e) => onAccNumber(e.target.value)}
                        value={itemIFSC?.accountNo}
                      />
                    </div>
                    <div className="withdraw-input-content">
                      <div className="page-subtitle">
                        Confirm account number*
                      </div>
                      <input
                        className="withdraw-textarea"
                        type="text"
                        placeholder="Enter your account: E.g.123456789"
                        disabled={true}
                        onChange={(e) => onValidationAccnumber(e.target.value)}
                        value={itemIFSC?.accountNo}
                      />
                      <div className="validation-message">{accountNumber}</div>
                    </div>
                    <div className="withdraw-input-content">
                      <div className="page-subtitle">Account name*</div>
                      <input
                        className="withdraw-textarea"
                        type="text"
                        placeholder="Account Name"
                        onChange={(e) => onAccName(e.target.value)}
                        disabled={true}
                        value={itemIFSC?.accountName}
                        maxLength={32}
                      />
                      <div className="validation-message">{nameMessage}</div>
                    </div>
                    <div className="rulesAccept">
                      {/* <input
                      className="rulesAccept__checkbox"
                      type="checkbox"
                      id="confirmWithdraw"
                      checked={isRulesAccept}
                      onChange={() => setIsRulesAccept(!isRulesAccept)}
                    /> */}
                      <div
                        className={`rulesAccept__checkboxW ${
                          !isRulesAccept ? 'rulesAccept__checkbox_checked' : ''
                        }`}
                        id="confirmWithdraw"
                        onClick={() => setIsRulesAccept(!isRulesAccept)}
                      />
                      <label htmlFor="confirmWithdraw"></label>
                      <span className="rulesAccept__text">
                        I have read and agree with &nbsp;
                        <span
                          className="rulesAccept__text-underline"
                          onClick={() =>
                            history.push('/termsOfWithdrowalPayment')
                          }
                        >
                          the terms of payment and withdrawal policy.
                        </span>
                      </span>
                    </div>
                    {width >= 1024 ? (
                      <div className="submitBtnW">
                        <button
                          type="button"
                          onClick={withrawRequest}
                          className={` 
                          ${
                            isRulesAccept &&
                            isEnoughMoney &&
                            withdrawalIFSC?.length === 11 &&
                            isNamesMatch &&
                            withdrawalAccName &&
                            withdrawalAmount
                              ? 'submitValidBtn'
                              : 'withdrawalNotValidBtn'
                          }`}
                          disabled={submitBtnDisabled}
                        >
                          {isRulesAccept &&
                          isEnoughMoney &&
                          withdrawalIFSC?.length === 11 &&
                          isNamesMatch &&
                          withdrawalAccName &&
                          withdrawalAmount ? (
                            <>
                              <span className="shadow"></span>
                              <span className="edge"></span>
                              <span className="front">Submit</span>
                            </>
                          ) : (
                            <div className="withdraw-submitNew__text">
                              Submit
                            </div>
                          )}
                        </button>
                      </div>
                    ) : (
                      <button
                        className={
                          !isRulesAccept &&
                          isEnoughMoney &&
                          withdrawalIFSC?.length === 11 &&
                          isNamesMatch &&
                          withdrawalAccName &&
                          withdrawalAmount
                            ? 'withdraw-submit'
                            : 'withdrawalNotValidBtn'
                        }
                        onClick={withrawRequest}
                        type="button"
                        disabled={submitBtnDisabled}
                      >
                        <div className="withdraw-submit__text">Submit</div>
                      </button>
                    )}
                  </div>
                )}

                {/* CREATE NEW ACCOUNT INPUTS */}

                {inputesCustom && (
                  <div className="withdraw-desktop__rightpage-inputcontent">
                    <div className="withdraw-input-content">
                      <div className="page-subtitle">Amount*</div>
                      <input
                        id="Amount"
                        className="withdraw-textarea"
                        type="number"
                        placeholder="0"
                        value={withdrawalAmount ? withdrawalAmount : null}
                        onChange={(e) => onValidationAmount(e.target.value)}
                      />
                      <span className="withdraw-textarea__currency">INR</span>
                      <div className="withdraw-amaount-buttons">
                        {width >= 1024 ? (
                          <>
                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 1000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 1000)
                                }
                              }}
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge refreshBtn"></span>
                              <span className="front refreshBtn">+1,000</span>
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 5000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 5000)
                                }
                              }}
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge refreshBtn"></span>
                              <span className="front refreshBtn">+5,000</span>
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 10000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 10000)
                                }
                              }}
                              className="pushable"
                            >
                              <span className="shadow"></span>
                              <span className="edge refreshBtn"></span>
                              <span className="front refreshBtn">+10,000</span>
                            </button>
                          </>
                        ) : (
                          <>
                            <div
                              className="withdraw-amaount-buttons__value"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 1000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 1000)
                                }
                              }}
                            >
                              +1,000
                            </div>

                            <div
                              className="withdraw-amaount-buttons__value"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 5000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 5000)
                                }
                              }}
                            >
                              +5,000
                            </div>
                            <div
                              className="withdraw-amaount-buttons__value"
                              onClick={() => {
                                if (
                                  balance?.availBalance <
                                  +withdrawalAmount + 10000
                                ) {
                                  // setIsEnoughMoney(false)
                                  setMessage(
                                    'You don`t have enough money on your balance'
                                  )
                                  setTimeout(() => {
                                    setMessage('')
                                  }, 3000)
                                } else {
                                  setMessage('')
                                  setIsEnoughMoney(true)
                                  setwithdrowalAmount(+withdrawalAmount + 10000)
                                }
                              }}
                            >
                              +10,000
                            </div>
                          </>
                        )}
                      </div>
                      <div className="validation-message">{message}</div>
                    </div>
                    <div className="withdraw-input-content">
                      <div className="page-subtitle">IFSC Code*</div>
                      <input
                        className="withdraw-textarea"
                        type="text"
                        // onReset={() => setInputedIFSC()}
                        // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                        onChange={(e) => onNewValidationIFSC(e.target.value)}
                        // defaultValue={itemIFSC?.IFSCCode}
                        // value={itemIFSC?.IFSCCode ? itemIFSC?.IFSCCode : inputedIFSC}
                      />
                      <div className="validation-message">{digits}</div>
                      {infoIFSC ? (
                        <div className="withdraw-textarea__description">
                          {infoIFSC?.BANK}, {infoIFSC?.BRANCH}, {infoIFSC?.CITY}
                        </div>
                      ) : null}
                    </div>
                    <div className="withdraw-input-content">
                      <div className="page-subtitle">Account number*</div>
                      <input
                        id="account"
                        className="withdraw-textarea hidden-accnumber"
                        type="text"
                        placeholder="*************"
                        onChange={(e) => onAccNumber(e.target.value)}
                        // defaultValue={itemIFSC?.accountNo}
                      />
                    </div>
                    <div className="withdraw-input-content">
                      <div className="page-subtitle">
                        Confirm account number*
                      </div>
                      <input
                        className="withdraw-textarea"
                        type="text"
                        placeholder="Enter your account: E.g.123456789"
                        disabled={!withdrawalAccNumber}
                        onChange={(e) => onValidationAccnumber(e.target.value)}
                        // defaultValue={itemIFSC?.accountNo}
                      />
                      <div className="validation-message">{accountNumber}</div>
                    </div>
                    <div className="withdraw-input-content">
                      <div className="page-subtitle">Account name*</div>
                      <input
                        className="withdraw-textarea"
                        type="text"
                        placeholder="Account Name"
                        onChange={(e) => onAccName(e.target.value)}
                        // defaultValue={itemIFSC?.accountName}
                        maxLength={32}
                      />
                      <div className="validation-message">{nameMessage}</div>
                    </div>
                    <div className="rulesAccept">
                      {/* <input
                      className="rulesAccept__checkbox"
                      type="checkbox"
                      id="confirmWithdraw"
                      checked={isRulesAccept}
                      onChange={() => setIsRulesAccept(!isRulesAccept)}
                    /> */}
                      <div
                        className={`rulesAccept__checkboxW ${
                          !isRulesAccept ? 'rulesAccept__checkbox_checked' : ''
                        }`}
                        id="confirmWithdraw"
                        onClick={() => setIsRulesAccept(!isRulesAccept)}
                      />
                      <label htmlFor="confirmWithdraw"></label>
                      <span className="rulesAccept__text">
                        I have read and agree with &nbsp;
                        <span
                          className="rulesAccept__text-underline"
                          onClick={() =>
                            history.push('/termsOfWithdrowalPayment')
                          }
                        >
                          the terms of payment and withdrawal policy.
                        </span>
                      </span>
                    </div>
                    {width >= 1024 ? (
                      <div className="submitBtnW">
                        <button
                          type="button"
                          onClick={withrawRequest}
                          className={` 
                          ${
                            isRulesAccept &&
                            isEnoughMoney &&
                            withdrawalIFSC?.length === 11 &&
                            isNamesMatch &&
                            withdrawalAccName &&
                            withdrawalAmount
                              ? 'submitValidBtn'
                              : 'withdrawalNotValidBtn'
                          }`}
                          disabled={submitBtnDisabled}
                        >
                          {isRulesAccept &&
                          isEnoughMoney &&
                          withdrawalIFSC?.length === 11 &&
                          isNamesMatch &&
                          withdrawalAccName &&
                          withdrawalAmount ? (
                            <>
                              <span className="shadow"></span>
                              <span className="edge"></span>
                              <span className="front">Submit</span>
                            </>
                          ) : (
                            <div className="withdraw-submitNew__text">
                              Submit
                            </div>
                          )}
                        </button>
                      </div>
                    ) : (
                      <button
                        className={
                          !isRulesAccept &&
                          isEnoughMoney &&
                          withdrawalIFSC?.length === 11 &&
                          isNamesMatch &&
                          withdrawalAccName &&
                          withdrawalAmount
                            ? 'withdraw-submit'
                            : 'withdrawalNotValidBtn'
                        }
                        onClick={withrawRequest}
                        type="button"
                        disabled={submitBtnDisabled}
                      >
                        <div className="withdraw-submit__text">Submit</div>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
          {/*{width < 1024 && <FooterDesktop />}*/}
      </IonContent> :
      <div className="ChangePassword" fullscreen>
      <form action="">
        <div className="withdraw-screen">
          <div className="withdraw-desktop">
            <div className="withdraw-desktop__leftpage">
              <div>
              <h2 className="page-title">Withdraw funds</h2>
                <div className="page-subtitle notes">
                  <p>1. This form is for withdrawing the amount from the main wallet only.</p>
                  <p>2. The bonus wallet amount cannot be withdrawn by this form.</p>
                  <p>3. Do not put Withdraw request without betting with deposit amount. Such activity may be identified as Suspicious</p>
                  <p>4. If multiple users are using same withdraw account then all the linked users will be blocked.</p>
                  <p>5. Paytm account numbers always start with 91</p>
                </div>
              </div>
              <div
                className="page-help"
                onClick={() => setOpenPopUpHelp(true)}
              >
                <span className="page-help__text">Help</span>
                <img className="page-help__img" src={QuestionImg} alt="" />
              </div>
            </div>
            <div className="withdraw-desktop__rightpage">
              <div className="page-subtitle subtitle-hidden">
                Please fill in all required fields
              </div>
              <div className="buttons-flex">
                {width >= 1024 ? (
                  <button
                    type="button"
                    onClick={() => {
                      setInputesCustom(true)
                      setInputesFromHistory(false)
                      setWithdrawAccount()
                    }}
                    className="pushable"
                  >
                    <span className="shadow"></span>
                    <span className="edge refreshBtn"></span>
                    <span className="front refreshBtn">Use New Account</span>
                  </button>
                ) : (
                  <div
                    className="withdraw-account__newitem "
                    onClick={() => {
                      setInputesCustom(true)
                      setInputesFromHistory(false)
                      setWithdrawAccount()
                    }}
                  >
                    <div className="withdraw-account__newitem-title">
                      Use New Account
                    </div>
                  </div>
                )}
                {width >= 1024 ? (
                  <button
                    type="button"
                    onClick={() => {
                      setInputesCustom(false)
                      setInputesFromHistory(true)
                      codeAcc()
                    }}
                    className="pushable"
                  >
                    <span className="shadow"></span>
                    <span className="edge refreshBtn"></span>
                    <span className="front refreshBtn">
                      Use Previous Account
                    </span>
                  </button>
                ) : (
                  <div
                    className="withdraw-account__newitem"
                    onClick={() => {
                      setInputesCustom(false)
                      setInputesFromHistory(true)
                      codeAcc()
                    }}
                  >
                    <div className="withdraw-account__newitem-title">
                      Use Previous Account
                    </div>
                  </div>
                )}
              </div>
              <div className="withdraw-account">
                <div className="withdraw-account__flex">
                  {inputesFromHistory && prevAccounts?.length > 0
                    ? prevAccounts.map((item) => {
                        return (
                          <label
                            htmlFor={item.accountId}
                            className={
                              withdrawAccount === item.accountId
                                ? 'withdraw-account__item-checked'
                                : 'withdraw-account__item'
                            }
                            onClick={() => {
                              setWithdrawAccount(item.accountId)
                              setInputesFromHistory(true)
                              setInputesCustom(false)
                            }}
                          >
                            <div
                              className="withdraw-account__item-content"
                              onClick={() => setItemIFSC(item)}
                            >
                              <div className="withdraw-account__item-content__account">
                                {item.accountName}
                              </div>
                              <div className="withdraw-account__item-content__account">
                                ********
                                {item.accountNo
                                  ? item.accountNo.slice(-4)
                                  : ''}
                              </div>
                              <div className="withdraw-account__item-content__IFSC">
                                {item.bankName}
                                <input
                                  className="check-account"
                                  type="radio"
                                  name="choosenaccount"
                                  checked={withdrawAccount === item.accountId}
                                  id={item.accountId}
                                />
                                <img
                                  className="delete-account"
                                  src={deleteButton}
                                  alt=""
                                  onClick={() => setOpenPopUpDelete(true)}
                                />
                              </div>
                            </div>
                          </label>
                        )
                      })
                    : null}
                </div>
              </div>

              {/* PREVIOUS ACCOUNTS */}

              {inputesFromHistory && itemIFSC && prevAccounts && (
                <div className="withdraw-desktop__rightpage-inputcontent">
                  <div className="withdraw-input-content ">
                    <div className={`page-subtitle ${!withdrawalAmount && withdrawalAmountCheck === false ? "withdraw__empty-input" : ""}`}>
                      <span>Amount*</span>
                      {!withdrawalAmount && withdrawalAmountCheck === false ? <span className={`input-error-message`}>Please fill required fields</span> : ''}
                    </div>
                    <input
                      id="Amount"
                      className="withdraw-textarea"
                      type="number"
                      placeholder="0"
                      value={withdrawalAmount ? withdrawalAmount : null}
                      onChange={(e) => onValidationAmount(e.target.value)}
                    />
                    <span className="withdraw-textarea__currency">INR</span>
                    <div className="withdraw-amaount-buttons">
                      {width >= 1024 ? (
                        <>
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 1000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 1000)
                              }
                            }}
                            className="pushable"
                          >
                            <span className="shadow"></span>
                            <span className="edge refreshBtn"></span>
                            <span className="front refreshBtn">+1,000</span>
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 5000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 5000)
                              }
                            }}
                            className="pushable"
                          >
                            <span className="shadow"></span>
                            <span className="edge refreshBtn"></span>
                            <span className="front refreshBtn">+5,000</span>
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 10000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 10000)
                              }
                            }}
                            className="pushable"
                          >
                            <span className="shadow"></span>
                            <span className="edge refreshBtn"></span>
                            <span className="front refreshBtn">+10,000</span>
                          </button>
                        </>
                      ) : (
                        <>
                          <div
                            className="withdraw-amaount-buttons__value"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 1000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 1000)
                              }
                            }}
                          >
                            +1,000
                          </div>

                          <div
                            className="withdraw-amaount-buttons__value"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 5000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 5000)
                              }
                            }}
                          >
                            +5,000
                          </div>
                          <div
                            className="withdraw-amaount-buttons__value"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 10000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 10000)
                              }
                            }}
                          >
                            +10,000
                          </div>
                        </>
                      )}
                    </div>
                    <div className="validation-message">{message}</div>
                  </div>
                  <div className="withdraw-input-content">
                    <div className="page-subtitle">IFSC Code*</div>
                    <input
                      className="withdraw-textarea"
                      type="text"
                      // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                      onChange={(e) => onValidationIFSC(e.target.value)}
                      // defaultValue={itemIFSC?.IFSCCode}
                      disabled={true}
                      value={itemIFSC?.IFSCCode}
                    />
                    <div className="validation-message">{digits}</div>
                    {infoIFSC ? (
                      <div className="withdraw-textarea__description">
                        {infoIFSC?.BANK}, {infoIFSC?.BRANCH}, {infoIFSC?.CITY}
                      </div>
                    ) : null}
                  </div>
                  <div className="withdraw-input-content account-number-input-wrapper">
                    <div className="page-subtitle">Account number*</div>
                    <input
                      id="account"
                      className="withdraw-textarea hidden-accnumber"
                      type="text"
                      placeholder="*************"
                      disabled={true}
                      onChange={(e) => onAccNumber(e.target.value)}
                      value={itemIFSC?.accountNo}
                    />
                  </div>
                  <div className="withdraw-input-content">
                    <div className="page-subtitle">
                      Confirm account number*
                    </div>
                    <input
                      className="withdraw-textarea"
                      type="text"
                      placeholder="Enter your account: E.g.123456789"
                      disabled={true}
                      onChange={(e) => onValidationAccnumber(e.target.value)}
                      value={itemIFSC?.accountNo}
                    />
                    <div className="validation-message">{accountNumber}</div>
                  </div>
                  <div className="withdraw-input-content">
                    <div className="page-subtitle">Account name*</div>
                    <input
                      className="withdraw-textarea"
                      type="text"
                      placeholder="Account Name"
                      onChange={(e) => onAccName(e.target.value)}
                      disabled={true}
                      value={itemIFSC?.accountName}
                      maxLength={32}
                    />
                    <div className="validation-message">{nameMessage}</div>
                  </div>
                  <div className={`rulesAccept ${!isRulesAccept && isRulesAcceptCheck === false ? "deposit__empty-input" : ""}`}>
                    {!isRulesAccept && isRulesAcceptCheck === false
                      ? <span className={`input-error-message`}>Please accept the rules.</span>
                      : ''}
                    <div
                      className={`rulesAccept__checkboxW ${
                        !isRulesAccept ? 'rulesAccept__checkbox_checked' : ''
                      }`}
                      id="confirmWithdraw"
                      onClick={() => setIsRulesAccept(!isRulesAccept)}
                    />
                    <label htmlFor="confirmWithdraw"></label>
                    <span className="rulesAccept__text">
                      I have read and agree with &nbsp;
                      <span
                        className="rulesAccept__text-underline"
                        onClick={() =>
                          history.push('/termsOfWithdrowalPayment')
                        }
                      >
                        the terms of payment and withdrawal policy.
                      </span>
                    </span>
                  </div>
                  {width >= 1024 ? (
                    <div className="submitBtnW">
                      <button
                        type="button"
                        onClick={
                          !isRulesAccept &&
                          isEnoughMoney &&
                          withdrawalIFSC?.length === 11 &&
                          withdrawalAccNumber &&
                          isNamesMatch &&
                          withdrawalAccName &&
                          withdrawalAmount ? withrawRequest :  fieldCheck}
                        className={` 
                        ${
                          !isRulesAccept &&
                          isEnoughMoney &&
                          withdrawalIFSC?.length === 11 &&
                          isNamesMatch &&
                          withdrawalAccName &&
                          withdrawalAmount
                            ? 'submitValidBtn'
                            : 'withdrawalNotValidBtn'
                        }`}
                        disabled={submitBtnDisabled}
                      >
                        {!isRulesAccept &&
                        isEnoughMoney &&
                        withdrawalIFSC?.length === 11 &&
                        isNamesMatch &&
                        withdrawalAccName &&
                        withdrawalAmount ? (
                          <>
                            <span className="shadow"></span>
                            <span className="edge"></span>
                            <span className="front">Submit</span>
                          </>
                        ) : (
                          <div className="withdraw-submitNew__text">
                            Submit
                          </div>
                        )}
                      </button>
                    </div>
                  ) : (
                    <button
                      className={
                        isRulesAccept &&
                        isEnoughMoney &&
                        withdrawalIFSC?.length === 11 &&
                        isNamesMatch &&
                        withdrawalAccName &&
                        withdrawalAmount
                          ? 'withdraw-submit'
                          : 'withdrawalNotValidBtn'
                      }
                      onClick={withrawRequest}
                      type="button"
                      disabled={submitBtnDisabled}
                    >
                      <div className="withdraw-submit__text">Submit</div>
                    </button>
                  )}
                </div>
              )}

              {/* CREATE NEW ACCOUNT INPUTS */}
              {inputesCustom && (
                <div className="withdraw-desktop__rightpage-inputcontent">
                  <div className="withdraw-input-content">
                    <div className={`page-subtitle ${!withdrawalAmount && withdrawalAmountCheck === false ? "withdraw__empty-input" : ""}`}>
                      <span>Amount*</span>
                      {!withdrawalAmount && withdrawalAmountCheck === false ? <span className={`input-error-message`}>Please fill required fields</span> : ''}
                    </div>

                    <input
                      id="Amount"
                      className="withdraw-textarea"
                      type="number"
                      placeholder="0"
                      value={withdrawalAmount ? withdrawalAmount : null}
                      onChange={(e) => onValidationAmount(e.target.value)}
                    />
                    <span className="withdraw-textarea__currency">INR</span>
                    <div className="withdraw-amaount-buttons">
                      {width >= 1024 ? (
                        <>
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 1000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 1000)
                              }
                            }}
                            className="pushable"
                          >
                            <span className="shadow"></span>
                            <span className="edge refreshBtn"></span>
                            <span className="front refreshBtn">+1,000</span>
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 5000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 5000)
                              }
                            }}
                            className="pushable"
                          >
                            <span className="shadow"></span>
                            <span className="edge refreshBtn"></span>
                            <span className="front refreshBtn">+5,000</span>
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 10000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 10000)
                              }
                            }}
                            className="pushable"
                          >
                            <span className="shadow"></span>
                            <span className="edge refreshBtn"></span>
                            <span className="front refreshBtn">+10,000</span>
                          </button>
                        </>
                      ) : (
                        <>
                          <div
                            className="withdraw-amaount-buttons__value"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 1000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 1000)
                              }
                            }}
                          >
                            +1,000
                          </div>

                          <div
                            className="withdraw-amaount-buttons__value"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 5000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 5000)
                              }
                            }}
                          >
                            +5,000
                          </div>
                          <div
                            className="withdraw-amaount-buttons__value"
                            onClick={() => {
                              if (
                                balance?.availBalance <
                                +withdrawalAmount + 10000
                              ) {
                                // setIsEnoughMoney(false)
                                setMessage(
                                  'You don`t have enough money on your balance'
                                )
                                setTimeout(() => {
                                  setMessage('')
                                }, 3000)
                              } else {
                                setMessage('')
                                setIsEnoughMoney(true)
                                setwithdrowalAmount(+withdrawalAmount + 10000)
                              }
                            }}
                          >
                            +10,000
                          </div>
                        </>
                      )}
                    </div>
                    <div className="validation-message">{message}</div>
                  </div>
                  <div className="withdraw-input-content">
                    <div className={`page-subtitle ${!withdrawalIFSC && withdrawalIFSCCheck === false ? "withdraw__empty-input" : ""}`}>
                      <span>IFSC Code*</span>
                      {!withdrawalIFSC && withdrawalIFSCCheck === false ? <span className={`input-error-message`}>Please fill required fields</span> : ''}
                    </div>
                    <input
                      className="withdraw-textarea"
                      type="text"
                      // onReset={() => setInputedIFSC()}
                      // onChange={(e) => setWithdrawalIFSC(e.target.value)}
                      onChange={(e) => onNewValidationIFSC(e.target.value)}
                      // defaultValue={itemIFSC?.IFSCCode}
                      // value={itemIFSC?.IFSCCode ? itemIFSC?.IFSCCode : inputedIFSC}
                    />
                    <div className="validation-message">{digits}</div>
                    {infoIFSC ? (
                      <div className="withdraw-textarea__description">
                        {infoIFSC?.BANK}, {infoIFSC?.BRANCH}, {infoIFSC?.CITY}
                      </div>
                    ) : null}
                  </div>
                  <div className="withdraw-input-content account-number-input-wrapper">
                    <div className={`page-subtitle ${!withdrawalAccNumber && withdrawalAccNumberCheck === false ? "withdraw__empty-input" : ""}`}>
                      <span>Account number*</span>
                      {!withdrawalAccNumber && withdrawalAccNumberCheck === false ? <span className={`input-error-message`}>Please fill required fields</span> : ''}
                    </div>
                    <input
                      id="account"
                      className="withdraw-textarea hidden-accnumber"
                      type="text"
                      placeholder="*************"
                      onChange={(e) => onAccNumber(e.target.value)}
                      // defaultValue={itemIFSC?.accountNo}
                    />
                  </div>
                  <div className="withdraw-input-content ">
                    <div className={`page-subtitle ${confirmAccNumber.length < 1 && confirmAccNumberCheck === false ? "withdraw__empty-input" : ""}`}>
                      <span>Confirm account number*</span>
                      {confirmAccNumber.length < 1 && confirmAccNumberCheck === false ? <span className={`input-error-message`}>Please fill required fields</span> : ''}
                    </div>
                    <input
                      className="withdraw-textarea"
                      type="text"
                      placeholder="Enter your account: E.g.123456789"
                      disabled={!withdrawalAccNumber}
                      onChange={(e) => onValidationAccnumber(e.target.value)}
                      // defaultValue={itemIFSC?.accountNo}
                    />
                    <div className="validation-message">{accountNumber}</div>
                  </div>
                  <div className="withdraw-input-content">
                    <div className={`page-subtitle ${!withdrawalAccName && withdrawalAccNameCheck  === false ? "withdraw__empty-input" : ""}`}>
                      <span>Account name*</span>
                      {!withdrawalAccName && withdrawalAccNameCheck === false ? <span className={`input-error-message`}>Please fill required fields</span> : ''}
                    </div>
                    <input
                      className="withdraw-textarea"
                      type="text"
                      placeholder="Account Name"
                      onChange={(e) => onAccName(e.target.value)}
                      // defaultValue={itemIFSC?.accountName}
                      maxLength={32}
                    />
                    <div className="validation-message">{nameMessage}</div>
                  </div>
                  <div className={`rulesAccept ${!isRulesAccept && isRulesAcceptCheck === false ? "deposit__empty-input" : ""}`}>
                    {!isRulesAccept && isRulesAcceptCheck === false ? <span className={`input-error-message`}>Please accept the rules.</span> : ''}
                    {/* <input
                    className="rulesAccept__checkbox"
                    type="checkbox"
                    id="confirmWithdraw"
                    checked={isRulesAccept}
                    onChange={() => setIsRulesAccept(!isRulesAccept)}
                  /> */}
                    <div
                      className={`rulesAccept__checkboxW ${
                        !isRulesAccept ? 'rulesAccept__checkbox_checked' : ''
                      }`}
                      id="confirmWithdraw"
                      onClick={() => setIsRulesAccept(!isRulesAccept)}
                    />
                    <label htmlFor="confirmWithdraw"></label>
                    <span className="rulesAccept__text">
                      I have read and agree with &nbsp;
                      <span
                        className="rulesAccept__text-underline"
                        onClick={() =>
                          history.push('/termsOfWithdrowalPayment')
                        }
                      >
                        the terms of payment and withdrawal policy.
                      </span>
                    </span>
                  </div>
                  {width >= 1024 ? (
                    <div className="submitBtnW">
                      <button
                        type="button"
                        onClick={
                          !isRulesAccept &&
                          isEnoughMoney &&
                          withdrawalIFSC?.length === 11 &&
                          withdrawalAccNumber &&
                          isNamesMatch &&
                          withdrawalAccName &&
                          withdrawalAmount ? withrawRequest :  fieldCheck}
                        className={` 
                        ${
                          !isRulesAccept &&
                          isEnoughMoney &&
                          withdrawalIFSC?.length === 11 &&
                          withdrawalAccNumber &&  
                          isNamesMatch &&
                          withdrawalAccName &&
                          withdrawalAmount
                            ? 'submitValidBtn'
                            : 'withdrawalNotValidBtn'
                        }`}
                        disabled={submitBtnDisabled}
                      >
                        {!isRulesAccept &&
                        isEnoughMoney &&
                        withdrawalIFSC?.length === 11 &&
                        withdrawalAccNumber &&
                        isNamesMatch &&
                        withdrawalAccName &&
                        withdrawalAmount ? (
                          <>
                            <span className="shadow"></span>
                            <span className="edge"></span>
                            <span className="front">Submit</span>
                          </>
                        ) : (
                          <div className="withdraw-submitNew__text">
                            Submit
                          </div>
                        )}
                      </button>
                    </div>
                  ) : (
                    <button
                      className={
                        isRulesAccept &&
                        isEnoughMoney &&
                        withdrawalIFSC?.length === 11 &&
                        isNamesMatch &&
                        withdrawalAccName &&
                        withdrawalAmount
                          ? 'withdraw-submit'
                          : 'withdrawalNotValidBtn'
                      }
                      onClick={withrawRequest}
                      type="button"
                      disabled={submitBtnDisabled}
                    >
                      <div className="withdraw-submit__text">Submit</div>
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <FooterDesktop />
    </div>
      }
      <PopUPDelete
        update={codeAcc}
        data={withdrawAccount}
        open={openPopUpDelete}
        onDidDismiss={() => setOpenPopUpDelete(false)}
      />
      <PopUPWithdrawalHelp
        // data={data}
        // content={editPoUP}
        open={openPopUpHelp}
        onDidDismiss={() => setOpenPopUpHelp(false)}
      />
    </IonPage>
  )
}

export default connectWithdrawal()(Withdrawal)
