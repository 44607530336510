import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setSportLadderShow } from '../../store/sportEvent/sportEvent.slice'

export const connectLadderPopover = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      ladderTitle: state.sportEvent.ladderTitle,
      ladderId: state.sportEvent.ladderId,
      ladderData: state.sportEvent.sportLadder,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setSportLadderShow,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
