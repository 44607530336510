import { createSlice } from '@reduxjs/toolkit'

export const LoginNewSlice = createSlice({
  name: 'loginNew',
  initialState: {
    tab: 1
  },
  reducers: {
    setLoginNew: (state, action) => action.payload,
    setTab: (state, action) => ({ ...state, tab: action.payload }),

  },
})

export const { setLoginNew, setTab } = LoginNewSlice.actions

export default LoginNewSlice.reducer
