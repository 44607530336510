import React, { useEffect, useState } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import redRectangle from "../../../assets/images/exchangeGames/roulette/red-rectangle-802.svg"
import blackRectangle from "../../../assets/images/exchangeGames/roulette/black-rectangle-803.svg"
import redRectangleMobile from "../../../assets/images/exchangeGames/roulette/rectangle_red_mob.svg"
import blackRectangleMobile from "../../../assets/images/exchangeGames/roulette/rectangle_black_mob.svg"
import "./styles/index.scss"
import { connectRoulette } from './Roulette.connect'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
// import BetPlacing from '../../BetPlacing/BetPlacing'
import Chip from './RouletteHelpers/Chip'
import CardsInfo from '../helpers/CardsInfo/CardsInfo'
import LastResults from '../helpers/LastResults/LastResults'
import { useParams } from 'react-router'

const Roulette = connectRoulette()(
  ({
     betPlacing,
     actions,
     exchangeGames,
     sportEvent,
     openBets,
     headerHeight,
     elementsParams
  }) => {

  const {eventId} = useParams();
  const dataOdd = sportEvent.sportData
  const [oldExchangeGames, setOldExchangeGames] = useState(null);

    // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width


  // request game data
  useEffect(() => {
    const timer = setIntervalAsync(async () => {
      await actions
                .requestExchangeGames(eventId)
                .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)
  }, [])
  // End request game data

  // Show betslip
  // const renderBetPlacing = () => {
  //   if (
  //     betPlacing.runner &&
  //     width < 1024
  //   ) {
  //     // return (
  //     //   <div className={headerHeight === 'non-collapsed'
  //     //     ? 'nonCollapsed'
  //     //     : 'collapsed'}>
  //     //     <BetPlacing />
  //     //   </div>
  //   // )
  //     return <BetPlacing headerHeight={headerHeight} />
  //   }
  //   return null
  // }
  // End Show betslip

  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    return (
      dataOdd[marketIndex]?.isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing

  //  Bet Chip
  const oddsMarketResult = dataOdd
  const myBets = openBets?.matched[0]

  const betChip = (marketIndex, runnerIndex) => {
    if (myBets?.markets.length > 0 && oddsMarketResult && oddsMarketResult[marketIndex] && oddsMarketResult[marketIndex]?.runners[runnerIndex]) {
      let betsResult = {}
      const chip = myBets?.markets.map((item, index) => {
        if (item.selections[0][0].selectionName === oddsMarketResult[marketIndex].runners[runnerIndex].name) {
          // let num = item.selections[0][0].stake
          let num = item.selections[0].reduce((acc, item) => acc + item?.stake, 0)
          if (betsResult[item.selections[0][0].selectionName]) {
            betsResult[item.selections[0][0].selectionName] = num + betsResult[item.selections[0][0].selectionName]
          } else {
            betsResult[item.selections[0][0].selectionName] = num
          }
          return <div className='chip-wrapper' key={index}><Chip bet={betsResult[item.selections[0][0].selectionName]}/></div>
        }
        return ''
      });
      return chip
    }
    return ''
  }
  // End  Bet Chip

    const resultStatus = (marketIndex, runner) => {
      if (exchangeGames && exchangeGames[marketIndex]?.marketRunner[runner]?.status === "WINNER") {
        return eventId === "98792" ?  "winner-virtual" : "lock-ui winner";
      }
      return !dataOdd[marketIndex]?.isBettable && "lock-ui";
    };
    // end lock market

  return (
    <>
      <CardsInfo/>
      {/*{*/}
      {/*  width < 1024*/}
      {/*    ?*/}
      {/*    <div className="gc-game-title">*/}
      {/*      <div className="game-title">ROULETTE</div>*/}
      {/*      <div className="game-round-id">*/}
      {/*        #{exchangeGames && exchangeGames[0]?.roundId}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    :*/}
      {/*    ""*/}
      {/*}*/}
      <div className={`roulette-table ${eventId === "98792" ? "virtual" : ""}`}>
        <div className="roulette-table__body">
          {/* { width < 1024 ? renderBetPlacing() : '' } */}
          <div className="roulette-table__body-tr zero-column">
            <div className={`roulette-table__body-cell zero-cell ${resultStatus(5, 0)}`} onClick={() => {oddPush(5,0)}}>
              {betChip(5,0)}
              <span className="roulette-table__body-num">0</span>
            </div>
          </div>
          <div className="roulette-table__body-tr column-nums column-1-12">
            <div className="roulette-table__body-td num-cell-wrapper">
              <div className="roulette-table__body-td-inner-wrapper num-cell-inner-wrapper">
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 3)}`}  onClick={() => {oddPush(5,3)}} >
                  {betChip(5,3)}
                  <span className="roulette-table__body-num">3</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5)}`} onClick={() => {oddPush(5,6)}} >
                  {betChip(5,6)}
                  <span className="roulette-table__body-num">6</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 9)}`} onClick={() => {oddPush(5,9)}} >
                  {betChip(5,9)}
                  <span className="roulette-table__body-num">9</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 12)}`} onClick={() => {oddPush(5,12)}} >
                  {betChip(5,12)}
                  <span className="roulette-table__body-num">12</span>
                </div>
              </div>
              <div className="roulette-table__body-td-inner-wrapper num-cell-inner-wrapper">
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 2)}`} onClick={() => {oddPush(5,2)}} >
                  {betChip(5,2)}
                  <span className="roulette-table__body-num">2</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 5)}`} onClick={() => {oddPush(5,5)}} >
                  {betChip(5,5)}
                  <span className="roulette-table__body-num">5</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 8)}`} onClick={() => {oddPush(5,8)}} >
                  {betChip(5,8)}
                  <span className="roulette-table__body-num">8</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 11)}`} onClick={() => {oddPush(5,11)}} >
                  {betChip(5,11)}
                  <span className="roulette-table__body-num">11</span>
                </div>
              </div>
              <div className="roulette-table__body-td-inner-wrapper num-cell-inner-wrapper">
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 1)}`} onClick={() => {oddPush(5,1)}}>
                  {betChip(5,1)}
                  <span className="roulette-table__body-num">1</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 4)}`} onClick={() => {oddPush(5,4)}}>
                  {betChip(5,4)}
                  <span className="roulette-table__body-num">4</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 7)}`} onClick={() => {oddPush(5,7)}}>
                  {betChip(5,7)}
                  <span className="roulette-table__body-num">7</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 10)}`} onClick={() => {oddPush(5,10)}}>
                  {betChip(5,10)}
                  <span className="roulette-table__body-num">10</span>
                </div>
              </div>
            </div>
            <div className="roulette-table__body-td multiple-bid-wrapper">
              <div className="roulette-table__body-td-inner-wrapper inner-wrapper-1-12">
                <div className={`roulette-table__body-cell multiple-cell  ${resultStatus(3, 0)}`}  onClick={() => {oddPush(3,0)}}>
                  {betChip(3,0)}
                  <span className="roulette-table__body-num">1<sup>st</sup> 12</span>
                </div>
              </div>
              <div className="roulette-table__body-td-inner-wrapper inner-wrapper-1-18-even">
                <div className={`roulette-table__body-cell multiple-cell  ${resultStatus(2, 0)}`} onClick={() => {oddPush(2,0)}}>
                  {betChip(2,0)}
                  <span className="roulette-table__body-num">1-18</span>
                </div>
                <div className={`roulette-table__body-cell multiple-cell  ${resultStatus(1, 1)}`}  onClick={() => {oddPush(1,1)}}>
                  {betChip(1,1)}
                  <span className="roulette-table__body-num">EVEN</span>
                </div>
              </div>
            </div>
          </div>
          <div className="roulette-table__body-tr column-nums column-2-12">
            <div className="roulette-table__body-td num-cell-wrapper">
              <div className="roulette-table__body-td-inner-wrapper num-cell-inner-wrapper">
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 15)}`} onClick={() => {oddPush(5,15)}}>
                  {betChip(5,15)}
                  <span className="roulette-table__body-num">15</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 18)}`} onClick={() => {oddPush(5,18)}}>
                  {betChip(5,18)}
                  <span className="roulette-table__body-num">18</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 21)}`} onClick={() => {oddPush(5,21)}}>
                  {betChip(5,21)}
                  <span className="roulette-table__body-num">21</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 24 )}`} onClick={() => {oddPush(5,24)}}>
                  {betChip(5,24)}
                  <span className="roulette-table__body-num">24</span>
                </div>
              </div>
              <div className="roulette-table__body-td-inner-wrapper num-cell-inner-wrapper">
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 14)}`} onClick={() => {oddPush(5,14)}}>
                  {betChip(5,14)}
                  <span className="roulette-table__body-num">14</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 17)}`} onClick={() => {oddPush(5,17)}}>
                  {betChip(5,17)}
                  <span className="roulette-table__body-num">17</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 20)}`} onClick={() => {oddPush(5,20)}}>
                  {betChip(5,20)}
                  <span className="roulette-table__body-num">20</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 23)}`} onClick={() => {oddPush(5,23)}}>
                  {betChip(5,23)}
                  <span className="roulette-table__body-num">23</span>
                </div>
              </div>
              <div className="roulette-table__body-td-inner-wrapper num-cell-inner-wrapper">
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 13)}`} onClick={() => {oddPush(5,13)}}>
                  {betChip(5,13)}
                  <span className="roulette-table__body-num">13</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 16)}`} onClick={() => {oddPush(5,16)}}>
                  {betChip(5,16)}
                  <span className="roulette-table__body-num">16</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 19)}`} onClick={() => {oddPush(5,19)}}>
                  {betChip(5,19)}
                  <span className="roulette-table__body-num">19</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 22)}`} onClick={() => {oddPush(5,22)}}>
                  {betChip(5,22)}
                  <span className="roulette-table__body-num">22</span>
                </div>
              </div>
            </div>
            <div className="roulette-table__body-td multiple-bid-wrapper">
              <div className="roulette-table__body-td-inner-wrapper inner-wrapper-2-12">
                <div className={`roulette-table__body-cell multiple-cell  ${resultStatus(3, 1)}`} onClick={() => {oddPush(3,1)}}>
                  {betChip(3,1)}
                  <span className="roulette-table__body-num">2<sup>nd</sup> 12</span>
                </div>
              </div>
              <div className="roulette-table__body-td-inner-wrapper inner-wrapper-red-black">
                <div className={`roulette-table__body-cell multiple-cell rectangle-icon-wrapper ${resultStatus(0, 1)}`} onClick={() => {oddPush(0,1)}}>
                  {betChip(0,1)}
                  {
                    width > 1024
                      ?
                      <img  className="roulette-table__body-color-rectangle red-rectangle" src={redRectangle} alt="" />
                      :
                      <img  className="roulette-table__body-color-rectangle red-rectangle" src={redRectangleMobile} alt="" />
                  }
                </div>
                <div className={`roulette-table__body-cell multiple-cell rectangle-icon-wrapper ${resultStatus(0, 0)}`} onClick={() => {oddPush(0,0)}}>
                  {betChip(0,0)}
                  {
                    width > 1024
                      ?
                      <img  className="roulette-table__body-color-rectangle black-rectangle" src={blackRectangle} alt="" />
                      :
                      <img  className="roulette-table__body-color-rectangle black-rectangle" src={blackRectangleMobile} alt="" />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="roulette-table__body-tr column-nums column-3-12">
            <div className="roulette-table__body-td num-cell-wrapper">
              <div className="roulette-table__body-td-inner-wrapper num-cell-inner-wrapper">
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 27)}`} onClick={() => {oddPush(5,27)}}>
                  {betChip(5,27)}
                  <span className="roulette-table__body-num">27</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 30)}`} onClick={() => {oddPush(5,30)}}>
                  {betChip(5,30)}
                  <span className="roulette-table__body-num">30</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 33)}`} onClick={() => {oddPush(5,33)}}>
                  {betChip(5,33)}
                  <span className="roulette-table__body-num">33</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 36)}`} onClick={() => {oddPush(5,36)}}>
                  {betChip(5,36)}
                  <span className="roulette-table__body-num">36</span>
                </div>
              </div>
              <div className="roulette-table__body-td-inner-wrapper num-cell-inner-wrapper">
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 26)}`} onClick={() => {oddPush(5,26)}}>
                  {betChip(5,26)}
                  <span className="roulette-table__body-num">26</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 29)}`} onClick={() => {oddPush(5,29)}}>
                  {betChip(5,29)}
                  <span className="roulette-table__body-num">29</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 32)}`} onClick={() => {oddPush(5,32)}}>
                  {betChip(5,32)}
                  <span className="roulette-table__body-num">32</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 35)}`} onClick={() => {oddPush(5,35)}}>
                  {betChip(5,35)}
                  <span className="roulette-table__body-num">35</span>
                </div>
              </div>
              <div className="roulette-table__body-td-inner-wrapper num-cell-inner-wrapper">
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 25)}`} onClick={() => {oddPush(5,25)}}>
                  {betChip(5,25)}
                  <span className="roulette-table__body-num">25</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 28)}`} onClick={() => {oddPush(5,28)}}>
                  {betChip(5,28)}
                  <span className="roulette-table__body-num">28</span>
                </div>
                <div className={`roulette-table__body-cell num-cell black-num ${resultStatus(5, 31)}`} onClick={() => {oddPush(5,31)}}>
                  {betChip(5,31)}
                  <span className="roulette-table__body-num">31</span>
                </div>
                <div className={`roulette-table__body-cell num-cell red-num ${resultStatus(5, 34)}`} onClick={() => {oddPush(5,34)}}>
                  {betChip(5,34)}
                  <span className="roulette-table__body-num">34</span>
                </div>
              </div>
            </div>
            <div className="roulette-table__body-td multiple-bid-wrapper">
              <div className="roulette-table__body-td-inner-wrapper inner-wrapper-3-12">
                <div className={`roulette-table__body-cell multiple-cell  ${resultStatus(3, 2)}`} onClick={() => {oddPush(3,2)}}>
                  {betChip(3,2)}
                  <span className="roulette-table__body-num">3<sup>rd</sup> 12</span>
                </div>
              </div>
              <div className="roulette-table__body-td-inner-wrapper inner-wrapper-odd-19-36">
                <div className={`roulette-table__body-cell multiple-cell  ${resultStatus(1, 0)}`}  onClick={() => {oddPush(1,0)}}>
                  {betChip(1,0)}
                  <span className="roulette-table__body-num">ODD</span>
                </div>
                <div className={`roulette-table__body-cell multiple-cell  ${resultStatus(2, 1)}`}  onClick={() => {oddPush(2,1)}}>
                  {betChip(2,1)}
                  <span className="roulette-table__body-num">19-36</span>
                </div>
              </div>
            </div>
          </div>
          <div className="roulette-table__body-tr column-2to1 ">
            <div className="roulette-table__body-td multiple-bid-wrapper wrapper-2to1">
              <div className={`roulette-table__body-cell multiple-cell ${resultStatus(4, 0)}`}  onClick={() => {oddPush(4,0)}}>
                {betChip(4,0)}
                <span className="roulette-table__body-num">2<sup>to</sup> 1</span>
              </div>
              <div className={`roulette-table__body-cell multiple-cell ${resultStatus(4, 1)}`}  onClick={() => {oddPush(4,1)}}>
                {betChip(4,1)}
                <span className="roulette-table__body-num">2<sup>to</sup> 1</span>
              </div>
              <div className={`roulette-table__body-cell multiple-cell ${resultStatus(4, 2)}`}  onClick={() => {oddPush(4,2)}}>
                {betChip(4,2)}
                <span className="roulette-table__body-num">2<sup>to</sup> 1</span>
              </div>
            </div>
          </div>
        </div>
        <LastResults/>
      </div>
    </>
  )
})

export default Roulette
