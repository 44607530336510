import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
    getRulesPageError,
    getRulesPageSuccess,
} from './rulesPage.slice'

export const requestRulesPage = (eventTypeId, competitionId) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    
    if(checkAuth) {
        try {
            const userCode = getState().user?.uplines?.SMA?.userCode;
            const response = await httpAuth.get(`/rules/event/${userCode}/${eventTypeId}/${competitionId}`)
            if (response.data.success) {
                dispatch(getRulesPageSuccess(response.data.result))
            }
            return response
        } catch (e) {
            dispatch(getRulesPageError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    } else {
        try {
            const userCode = getState().user?.uplines?.SMA?.userCode;
            const response = await httpNotAuth.get(`/rules/event/${userCode}/${eventTypeId}/${competitionId}`)
            if (response.data.success) {
                dispatch(getRulesPageSuccess(response.data.result))
            }
            return response
        } catch (e) {
            dispatch(getRulesPageError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }
}
