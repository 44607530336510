import './MoreRulesPopover.scss'
import { IonButton, IonPopover, IonItem, IonLabel, IonCheckbox } from '@ionic/react'
import React, { useState } from 'react'
import { connectMoreRulesPopover } from './MoreRulesPopover.connect'

const MoreRulesPopover = ({
  open,
  actions
}) => {
  const handleYes = async () => {
    actions.setMoreRulesPopoverShow(false)
  }

  const [checked, setChecked] = useState(true);

  return (
    <IonPopover isOpen={open} backdropDismiss={false} cssClass="MoreRulesPopover">
      <div className="MoreRulesPopover__content">
        <div className="MoreRulesPopover__title">Multiple Account & Bet Limit Rule Acceptance</div>
        <div className="MoreRulesPopover__subtitle">Multiple Account & Bet Limit Rule Acceptance</div>
        <div className="MoreRulesPopover__text">Members are not permitted to hold multiple accounts. This includes holding an account with any associated site operating on the same platform as this site.</div>
        <div className="MoreRulesPopover__text">Where maximum bet or maximum market limits are imposed, the Site reserves to the right to void bets from accounts found to be exceeding these limits by using multiple accounts across this and any other associated sites.</div>
        <div className="MoreRulesPopover__text">Please confirm your acceptance and understanding of this rule.</div>
        <IonItem className="MoreRulesPopover__item">
          <IonCheckbox checked={checked} onIonChange={e => setChecked(e.detail.checked)} color="success" mode="md" />
          <IonLabel className={`MoreRulesPopover__label ${checked ? '' : 'MoreRulesPopover__label--disabled'}`}>Agree</IonLabel>
        </IonItem>
      </div>
      <IonButton
        onClick={handleYes}
        mode="md"
        class="MoreRulesPopover__btn"
        disabled={!checked}
      >
        Accept and Continue
      </IonButton>
    </IonPopover>
  )
}

export default connectMoreRulesPopover()(MoreRulesPopover)
