import "./CasinoWarLast Result.scss";
import React, { useEffect } from 'react'

const CasinoWarLastResult = ({data }) => {

  const assert = (condition, error) => {
    if (!condition) {
      // console.trace(error);
      throw error;
    }
  }

  const parseGeneralCard = (str) => {
    if (str) {
      const suit = str[0];
      assert(suit === 'C' || suit === 'D' || suit === 'H' || suit === 'S', 'invalid suit');

      const rank = parseInt(str.slice(1));
      assert(rank >= 1 && rank <= 13, 'invalid rank');

      return {rank, suit};
    }
  }

  const getCasinoWarWinners = (player) => {
    let player1Status = false;
    const player1Card = parseGeneralCard(data?.marketId?.cards[player])?.rank;
    const dealerCard = parseGeneralCard(data?.marketId?.cards[6])?.rank;
    const player1Suit = parseGeneralCard(data?.marketId?.cards[player])?.suit;
    const dealerSuit = parseGeneralCard(data?.marketId?.cards[6])?.suit;

    if (player1Card > dealerCard) {
      player1Status = true;
    } else if (player1Card < dealerCard) {
      player1Status = false;
    } else if (player1Card === dealerCard) {
      const order = ['D', 'C', 'H', 'S'];

      const dealerRank = order.indexOf(player1Suit);
      const playerRank = order.indexOf(dealerSuit);

      if (dealerRank < playerRank) {
        player1Status = false;
      }
      if (dealerRank > playerRank) {
        player1Status = true;
      }
    }

    return player1Status
  }

    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };

    return (
      <div className="casino-war-last-result">
        <div className={`casino-war-last-result__player ${getCasinoWarWinners(0) && "isWinner"}`}>
          <div>1</div>
          <div className="casino-war-last-result__player-cards">
            <img
              className="casino-war-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.cards[0])}
              alt=""
            />
          </div>
        </div>
        <div className={`casino-war-last-result__player ${getCasinoWarWinners(1) && "isWinner"}`}>
          <div>2</div>
          <div className="casino-war-last-result__player-cards">
            <img
              className="casino-war-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.cards[1])}
              alt=""
            />
          </div>
        </div>
        <div className={`casino-war-last-result__player ${getCasinoWarWinners(2) && "isWinner"}`}>
          <div>3</div>
          <div className="casino-war-last-result__player-cards">
            <img
              className="casino-war-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.cards[2])}
              alt=""
            />
          </div>
        </div>
        <div className={`casino-war-last-result__player ${getCasinoWarWinners(3) && "isWinner"}`}>
          <div>4</div>
          <div className="casino-war-last-result__player-cards">
            <img
              className="casino-war-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.cards[3])}
              alt=""
            />
          </div>
        </div>
        <div className={`casino-war-last-result__player ${getCasinoWarWinners(4) && "isWinner"}`}>
          <div>5</div>
          <div className="casino-war-last-result__player-cards">
            <img
              className="casino-war-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.cards[4])}
              alt=""
            />
          </div>
        </div>
        <div className={`casino-war-last-result__player ${getCasinoWarWinners(5) && "isWinner"}`}>
          <div>6</div>
          <div className="casino-war-last-result__player-cards">
            <img
              className="casino-war-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.cards[5])}
              alt=""
            />
          </div>
        </div>
        <div className={`casino-war-last-result__player dealer `}>
          <div>D</div>
          <div className="casino-war-last-result__player-cards">
            <img
              className="casino-war-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.cards[6])}
              alt=""
            />
          </div>
        </div>
      </div>
    );
};
export default CasinoWarLastResult;
