import "./Casino32cardCards.scss";
import React from "react";
import { useSelector } from "react-redux";
import {connectCasino32card} from "../../Casino32card.connect";

const Casino32cardCards = ({exchangeGames}) => {

  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth

    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };
    const getSumOfCards = (market, runner) => {
        const cards = exchangeGames && exchangeGames[market]?.marketRunner[runner]?.cards;
        return Array.isArray(cards) &&
      cards.reduce((sum, card) => sum += +card.substr(1, card.length), 0);
    };

    const renderCards = (market, runner) => {
        const cards = exchangeGames && exchangeGames[market]?.marketRunner[runner]?.cards;

        if (Array.isArray(cards) && cards.length > 0) {
            return cards.map(card =>
        <img key={card} className="casino-32-card-cards__player-cards-card"
             src={cardSrc(card)} alt={card} />);
        }

        return <img className="casino-32-card-cards__player-cards-card"
                src={cardSrc()} alt="back" />;
    };

    if (width < 1024) {
        return (
        <div className="casino-32-card-cards">
          <div className="casino-32-card-cards__player">
            <div className="casino-32-card-cards__player-title">
              <span>P 8 = </span>
              {getSumOfCards(0, 0) !== 0 &&
                <span className="casino-32-card-cards__player-title-total">{8 + getSumOfCards(0, 0)}</span>}
            </div>
            <div className="casino-32-card-cards__player-cards">
              {renderCards(0, 0)}
            </div>
          </div>
          <div className="casino-32-card-cards__player">
            <div className="casino-32-card-cards__player-title">
              <span>P 9 = </span>
              {getSumOfCards(0, 1) !== 0 &&
                <span className="casino-32-card-cards__player-title-total">{9 + getSumOfCards(0, 1)}</span>}
            </div>
            <div className="casino-32-card-cards__player-cards">
              {renderCards(0, 1)}
            </div>
          </div>
          <div className="casino-32-card-cards__player">
            <div className="casino-32-card-cards__player-title">
              <span>P 10 = </span>
              {getSumOfCards(0, 2) !== 0 &&
                <span className="casino-32-card-cards__player-title-total">{10 + getSumOfCards(0, 2)}</span>}
            </div>
            <div className="casino-32-card-cards__player-cards">
              {renderCards(0, 2)}
            </div>
          </div>
          <div className="casino-32-card-cards__player">
            <div className="casino-32-card-cards__player-title">
              <span>P 11 = </span>
              {getSumOfCards(0, 3) !== 0 &&
                <span className="casino-32-card-cards__player-title-total">{11 + getSumOfCards(0, 3)}</span>}
            </div>
            <div className="casino-32-card-cards__player-cards">
              {renderCards(0, 3)}
            </div>
          </div>
        </div>
        );
    }
    return (
      <div className="casino-32-card-cards">
        <div className="casino-32-card-cards__player">
          <div className="casino-32-card-cards__player-default-card">8</div>
          <span>+</span>
          <div className="casino-32-card-cards__player-cards">
            {renderCards(0, 0)}
          </div>
          <span>=</span>
          <div className="casino-32-card-cards__player-title">
            {getSumOfCards(0, 0) !== 0 &&
              <span className="casino-32-card-cards__player-title-total">{8 + getSumOfCards(0, 0)}</span>
            }
          </div>
        </div>
        <div className="casino-32-card-cards__player">
          <div className="casino-32-card-cards__player-default-card">9</div>
          <span>+</span>
          <div className="casino-32-card-cards__player-cards">
            {renderCards(0, 1)}
          </div>
          <span>=</span>
          <div className="casino-32-card-cards__player-title">
            {getSumOfCards(0, 1) !== 0 &&
            <span className="casino-32-card-cards__player-title-total">{9 + getSumOfCards(0, 1)}</span>
            }
          </div>
        </div>
        <div className="casino-32-card-cards__player">
          <div className="casino-32-card-cards__player-default-card">10</div>
          <span>+</span>
          <div className="casino-32-card-cards__player-cards">
            {renderCards(0, 2)}
          </div>
          <span>=</span>
          <div className="casino-32-card-cards__player-title">
            {getSumOfCards(0, 2) !== 0 &&
              <span className="casino-32-card-cards__player-title-total">{10 + getSumOfCards(0, 2)}</span>
            }
          </div>
        </div>
        <div className="casino-32-card-cards__player">
          <div className="casino-32-card-cards__player-default-card">11</div>
          <span>+</span>
          <div className="casino-32-card-cards__player-cards">
            {renderCards(0, 3)}
          </div>
          <span>=</span>
          <div className="casino-32-card-cards__player-title">
            {getSumOfCards(0, 3) !== 0 &&
              <span className="casino-32-card-cards__player-title-total">{11 + getSumOfCards(0, 3)}</span>
            }
          </div>
        </div>
      </div>
    );
};
export default connectCasino32card()(Casino32cardCards);
