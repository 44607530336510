import React from 'react'

const ThirtyTwoCardRules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          The objective of 32 Card Casino is to predict the winning player of
          the game round. 32 Card Casino is played with a deck of 32 cards which
          does not include A,2,3,4,5 ranked cards. To win, you need to predict
          if total of the base value of the Player and cards dealt with the
          Player will be higher or lower than other three Players. You can
          either back or lay any Player. On Draw between one or more Players,
          the market will be reopened for the next cards and users can place new
          bets.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <ul>
          <li>
            When the game round starts, no cards are dealt but the market is
            opened for initial Betting.
          </li>
          <li>After the market is suspended, each player is dealt one card.</li>
          <li>
            If there is a <strong>tie</strong> between any two players, the
            market is reopened for betting to decide the winner. This will
            continue till the winner is decided.
          </li>
        </ul>
        <div>
          <h3>Betting Instructions</h3>
          <ul>
            <li>
              If a game round is in progress when you enter the game, please
              wait for the next one, and then, place your bets.
            </li>
            <li>After the timer reaches 0, the game round begins.</li>
            <li>
              To place a bet, click on the box, enter the stake amount and
              submit by clicking on Place Bet button.
            </li>
            <li>
              You can place several bets on different runners in different
              markets simultaneously.
            </li>
            <li>
              The timer shows how much time you have left to place your bets.
            </li>
            <li>
              Winnings are paid for the winning bets at the end of each game
              round.
            </li>
            <li>To skip a turn, simply do not place any bets.</li>
          </ul>
        </div>
        <div>
          <h3>Main Bets</h3>
          <ol>
            <li>
              Player 8: Have a base value of 8 which is added to card(s)' total
              value.
            </li>
            <li>
              Player 9: Have a base value of 9 which is added to card(s)' total
              value.
            </li>
            <li>
              Player 10: Have a base value of 10 which is added to card(s)'
              total value.
            </li>
            <li>
              Player 11: Have a base value of 11 which is added to card(s)'
              total value.
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}
export default ThirtyTwoCardRules
