import React from 'react'
import './QueenCards.scss'
import { connectQueen } from '../../Queen.connect'

const QueenCards = ({ exchangeGames }) => {
  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const getSumOfCards = (cards, bonusScore) => {
    let sum = 0;
    if (Array.isArray(cards)) {
      for (const card of cards) {
        sum += parseInt(card.substring(1));
      }
      if (bonusScore) {
        sum = sum + bonusScore;
        return sum;
      } else {
        return sum;
      }
    }
  }

  return (
      <div className="queenCardsContent">
        <div className="queenCardsContent-total">
          <div className="queenCardsContent-total__name">Total 0
            { exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards.length !== 0 &&
                <span className="queenCardsContent-total__name-score">  <span> = </span> {getSumOfCards(exchangeGames[0]?.marketRunner[0]?.cards, 0)}</span>
            }
          </div>
          <div className="queenCardsContent-total__cards">
            {exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards.length ? exchangeGames[0]?.marketRunner[0]?.cards.map( (card, index) =>
              <img key={card + index}
                className="queenCardsContent-total__cards-card"
                src={cardSrc(card)} alt=""
              />)
              :
              <img className="queenCardsContent-total__cards-card" src={cardSrc(null)} alt="card" />
            }
          </div>
        </div>
        <div className="queenCardsContent-total">
          <div className="queenCardsContent-total__name">
            Total 1
            { exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards.length !== 0 &&
              <span className="queenCardsContent-total__name-score">  <span> = </span> {getSumOfCards(exchangeGames[0]?.marketRunner[1]?.cards, 1)}</span>
            }
          </div>
          <div className="queenCardsContent-total__cards">
            {exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards.length ? exchangeGames[0]?.marketRunner[1]?.cards.map( (card, index) =>
                <img key={card + index}
                     className="queenCardsContent-total__cards-card"
                     src={cardSrc(card)} alt=""
                />)
              :
              <img className="queenCardsContent-total__cards-card" src={cardSrc(null)} alt="card" />
            }
          </div>
        </div>
        <div className="queenCardsContent-total">
          <div className="queenCardsContent-total__name">
            Total 2
            { exchangeGames && exchangeGames[0]?.marketRunner[2]?.cards.length !== 0 &&
              <span className="queenCardsContent-total__name-score">  <span> = </span> {getSumOfCards(exchangeGames[0]?.marketRunner[2]?.cards, 2)}</span>
            }
          </div>
          <div className="queenCardsContent-total__cards">
            {exchangeGames && exchangeGames[0]?.marketRunner[2]?.cards.length ? exchangeGames[0]?.marketRunner[2]?.cards.map( (card, index) =>
                <img key={card + index}
                     className="queenCardsContent-total__cards-card"
                     src={cardSrc(card)} alt=""
                />)
              :
              <img className="queenCardsContent-total__cards-card" src={cardSrc(null)} alt="card" />
            }
          </div>
        </div>
        <div className="queenCardsContent-total">
          <div className="queenCardsContent-total__name">
            Total 3
            { exchangeGames && exchangeGames[0]?.marketRunner[3]?.cards.length !== 0 &&
              <span className="queenCardsContent-total__name-score">  <span> = </span> {getSumOfCards(exchangeGames && exchangeGames[0]?.marketRunner[3]?.cards, 3)}</span>
            }
          </div>
          <div className="queenCardsContent-total__cards">
            {exchangeGames && exchangeGames[0]?.marketRunner[3]?.cards.length ? exchangeGames[0]?.marketRunner[3]?.cards.map( (card, index) =>
                <img key={card + index}
                     className="queenCardsContent-total__cards-card"
                     src={cardSrc(card)} alt=""
                />)
              :
              <img className="queenCardsContent-total__cards-card" src={cardSrc(null)} alt="card" />
            }
          </div>
        </div>
      </div>
  )
}

export default connectQueen()(QueenCards)
