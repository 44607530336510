import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getCompetitionSuccess, getCompetitionError } from './competitions.slice'

export const requestCompetition = (eventId,competitionId) => async (dispatch, getState) => {
  const checkAuth = getState().auth
  
  if(checkAuth) {
    try {
      dispatch(getCompetitionSuccess(null))
      const response = await httpAuth.get(`/exchange/odds/group/${eventId}/${competitionId}?includeOutrights=true&includeToQualify=true`)
      if (response.data.success) {
        dispatch(getCompetitionSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getCompetitionError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  } else {
    try {
      dispatch(getCompetitionSuccess(null))
      const response = await httpNotAuth.get(`/exchange/odds/group/${eventId}/${competitionId}?includeOutrights=true&includeToQualify=true`)
      if (response.data.success) {
        dispatch(getCompetitionSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getCompetitionError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }
}