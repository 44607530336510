import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getBetbyWidgetError, getBetbyWidgetSuccess } from './betbyWidget.slice'

export const requestBetbyWidgetStart = (id = '1444005', type = "SPORTSBOOK") => async (dispatch) => {
  try {
    const response = await httpAuth.post(`/casino/live/session/open`, { id, type })
    if (response.data.success) {
      dispatch(getBetbyWidgetSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getBetbyWidgetError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}

export const requestBetbyWidgetEnd = () => async (dispatch) => {
  try {
    const response = await httpAuth.post(`/casino/live/session/close`)
    if (response.data.success) {
      dispatch(getBetbyWidgetSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getBetbyWidgetError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
