import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {getBonusWageringInfoError, getBonusWageringInfoSuccess} from './bonusWageringInfo.slice'

export const requestBonusWageringInfoSuccess = (walletType) => async (dispatch) => {
    try {
        const response = await http.get(`/account/wagering-details?walletType=${walletType}`)
        if (response.data.success) {
            dispatch(getBonusWageringInfoSuccess(response.data.result))
        }
        return response
    } catch (e) {
        dispatch(getBonusWageringInfoError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}
