import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestAuth } from '../../store/auth/auth.thunks'
import { requestBalance } from '../../store/balance/balance.thunks'
import { requestRecaptcha } from '../../store/recaptcha/recaptcha.thunks'
import { getRecaptchaSuccess } from '../../store/recaptcha/recaptcha.slice'
import { setVersion } from '../../store/version/version.slice'

export const connectLogin = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      recaptcha: state.recaptcha,
      version: state.version,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestAuth,
      requestRecaptcha,
      getRecaptchaSuccess,
      setVersion,
      requestBalance,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
