import { createSlice } from '@reduxjs/toolkit'

export const bonusStatementSlice = createSlice({
    name: 'bonusStatement',
    initialState: null,
    reducers: {
        getBonusStatementSuccess: (state, action) => action.payload,
        getBonusStatementError: (state) => state,
    },
})

export const { getBonusStatementSuccess, getBonusStatementError } = bonusStatementSlice.actions

export default bonusStatementSlice.reducer
