import { createSlice } from '@reduxjs/toolkit'

export const competitionsSlice = createSlice({
  name: 'competitions',
  initialState: null,
  reducers: {
    getCompetitionSuccess: (state, action) => action.payload,
    getCompetitionError: (state) => state,
  },
})

export const { getCompetitionSuccess, getCompetitionError } = competitionsSlice.actions

export default competitionsSlice.reducer