import React from 'react'
import "./RouletteLastResult.scss"

const RouletteLastResult = ({data}) => {

  return (
    <div className={`rouletteLastResult__content-item ${data?.runnerName}`}>
      <span>{data && data?.marketId && data?.marketId?.indexCard[0] && data?.marketId?.indexCard[0]}</span>
    </div>
  )
}
export default RouletteLastResult
