import "./TrioPL.scss";
import React, { useEffect, useState } from 'react'

const TrioPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {};
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
         if (runner.status === 'WINNER' && market.marketHeader == 'Total - 21 over/under') {
           winObj['total'] = `${runner.name.replace('21', '')} (${runner.total})`;
         }
       });
     });
   }

   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="trio-betting">
        <div className="trio-betting__games-item">
          <div className="trio-betting__games-item-cards">
            {roundMarkets && roundMarkets[0].indexCard.map( card =>
              <img className="hi-low-last-result__player-cards-card"
                   key={card}
                   src={cardSrc(card)}
                   alt={card}
              />
            )}
          </div>
        </div>
        {winnerData && <div className="trio-betting__info-column">
          <div className="trio-betting__info-row">
            <div className="trio-betting__info-row-title">
              <span>Total - 21 Yes/No :</span>
            </div>
            <div className="trio-betting__info-row-results">
              <span>{winnerData['total'] || winnerData['Total - 21 over/under']}</span>
            </div>
          </div>
          <div className="trio-betting__info-row">
            <div className="trio-betting__info-row-title">
              <span>1 2 4/J Q K: </span>
            </div>
            <div className="trio-betting__info-row-results">
        <span>
          {winnerData['3 Card Judgement (1,2,4)'] === 'Yes' ? '1 2 4' : ' '}
          {(winnerData['3 Card Judgement (1,2,4)'] === 'Yes' && winnerData['3 Card Judgement (J,Q,K)'] === 'Yes') ? '|' : ''}
          {winnerData['3 Card Judgement (J,Q,K)'] === 'Yes' ? 'J Q K' : ' '}
          {(winnerData['3 Card Judgement (1,2,4)'] === 'No' && winnerData['3 Card Judgement (J,Q,K)'] === 'No') ? 'No Winner' : ''}
        </span>
            </div>
          </div>
          <div className="trio-betting__info-row">
            <div className="trio-betting__info-row-title">
              <span>Color:</span>
            </div>
            <div className="trio-betting__info-row-results">
              <span>{winnerData['Color'] || 'No Winner'}</span>
            </div>
          </div>
          <div className="trio-betting__info-row">
            <div className="trio-betting__info-row-title">
              <span>Odd/Even:</span>
            </div>
            <div className="trio-betting__info-row-results">
              <span>{winnerData['Odd Even'] || 'No Winner'}</span>
            </div>
          </div>
          <div className="trio-betting__info-row">
            <div className="trio-betting__info-row-title">
              <span>Pattern: </span>
            </div>
            <div className="trio-betting__info-row-results">
              <span>{winnerData['Pattern'] || 'No Pattern'}</span>
            </div>
          </div>
        </div>}
      </div>
    );
};

export default TrioPL;

