import React, { useEffect, useState } from 'react'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import {
  clearIntervalAsync,
  setIntervalAsync,
} from 'set-interval-async/dynamic'
import './defaultpage.scss'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../config/app.config'
import { connectDefaultPage } from './defaultpage.connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import ToolbarDesktop from '../../components/ToolbarDesktop/ToolbarDesktop'
import Promotion from '../../components/Promotion/promotion'
import InPlayPopular from '../../components/InPlay/inPlayPopular'
import { headerType } from '../../helpers/InPlayPopular.helper'
import LegalAgePopover from '../../components/LegalAgePopover/LegalAgePopover'
import KycPopover from '../../components/KycPopover/KycPopover'
import MoreRulesPopover from '../../components/MoreRulesPopover/MoreRulesPopover'
import { useHistory } from 'react-router'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import BannerPopUp from '../../components/BannerPopUp/BannerPopUp'
import { useSelector } from 'react-redux'
import LeftSideMenuDesktop from '../../components/LeftSideMenuDesktop/LeftSideMenuDesktop'
import BalanceInformation from '../../components/BalanceInformation/BalanceInformation'
import BetSettingsDesktop from '../../components/BetSettingsDesktop/BetSettingsDesktop'
import BetSlipDesktop from '../../components/BetSlipDesktop/BetSlipDesktop'
import BetPlacing from '../../components/BetPlacing/BetPlacing'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import whatsAppImg from '../../assets/images/whatsApp.svg'
import WhatsappBanner from '../WhatsappBanner/WhatsappBanner'
import { isSignedUpWithNumberInBranchHelper } from '../../helpers/isSignedUpWithNumberInBranch.helper'
import Bonuses from '../bonuses/Bonuses'
import BonusBanner from '../bonuses/BonusBanner'

const DefaultPage = ({
  auth,
  lastActivityDate,
  inplayPopular,
  promotions,
  balanceSettings,
  legalAgePopoverShow,
  kycPopoverShow,
  moreRulesPopoverShow,
  timeSettings,
  betPlacing,
  orderCount,
  actions,
  elementsParams,
  ...props
}) => {
  const history = useHistory()
  const [displayedBack, setDisplayedBack] = useState(false)
  const bannerOpen = useSelector((state) => state.bannerPopUp)

  // let pathname = history.location.pathname
  const pathname = props.localPathnameNow

  useEffect(() => {
    if (history.location.pathname === '/default-page' && auth)
      actions.requestOrderCount()
    // if (history.location.pathname === '/') actions.requestOrderCount();
  }, [actions, lastActivityDate])

  const getInplayPopular = () => {
    actions
      .requestInplayPopular()
      .catch((e) => console.log('requestInplayPopular error = ', e))
  }
  // const getPromotion = () => {
  // actions.requestPromotions().catch(e => console.log('requestInplayPopular error = ', e))
  // }

  // useEffect(() => {
  // getPromotion()
  //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    // if (!inplayPopular && history.location.pathname === '/') getInplayPopular()
    if (!inplayPopular && history.location.pathname === '/default-page')
      getInplayPopular()
    else {
      const timer = setIntervalAsync(async () => {
        // if (history.location.pathname === '/') getInplayPopular()
        if (history.location.pathname === '/default-page')
          await getInplayPopular()
        // else if (inplayPopular) actions.getInplayPopularSuccess(null)
      }, GET_INPLAY_POPULAR_INTERVAL)
      return () => clearIntervalAsync(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inplayPopular, history.location.pathname])

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  const [renderFooter, setRenderFooter] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (window.location.pathname.includes('login')) {
        actions.setLoginNew(true)
      }

      setRenderFooter(true)
    }, 1500)
  }, [])

  useEffect(() => {
    if (window?.location?.pathname?.includes('register')) {
      setTimeout(() => {
        // window.history.pushState({},"", "/");
        actions.setLoginNew(true)
        actions.setTab(2)
      }, 500)
    }
  }, [])

  let userData
  useEffect(() => {
    userData = JSON.parse(localStorage.getItem('user_data'))
    if (width >= 1024) {
      window.history.pushState(null, document.title)
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title)
      })
    }
  }, [history.location.pathname])

  return (
    <>
      {width < 1024 ? (
        <>
          {bannerOpen && <BannerPopUp />}
          <IonPage>
            <IonHeader>
              <Toolbar inplayPopular={inplayPopular} />
            </IonHeader>
            <IonContent className="default-page__content">
              <Promotion />
              { <BonusBanner />}
              {isSignedUpWithNumberInBranchHelper() && (
                  <WhatsappBanner
                    link={JSON.parse(localStorage.getItem('user_data'))?.wa}
                  />
                )}
              {inplayPopular && inplayPopular.inPlayEvents ? (
                <>
                  <InPlayPopular
                    pathname={pathname}
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={inplayPopular && inplayPopular.inPlayEvents}
                    type={headerType['INPLAY']}
                    orderCount={(orderCount && orderCount.count) || 0}
                    promotions={promotions}
                  />
                  <InPlayPopular
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={inplayPopular && inplayPopular.popularEvents}
                    type={headerType['POPULAR']}
                  />
                </>
              ) : (
                <LoaderContent />
              )}
              <LegalAgePopover open={legalAgePopoverShow} />
              <KycPopover open={kycPopoverShow} />
              <MoreRulesPopover open={moreRulesPopoverShow} />
              {renderFooter && <FooterDesktop />}
              {/* <div style={{position: 'absolute', left: '10px', bottom: '20px', zIndex: '11111'}}>
              <img src={whatsAppImg} alt="" />
             </div> */}
            </IonContent>
          </IonPage>
        </>
      ) : (
        <>
          {bannerOpen && <BannerPopUp />}
          <div style={{ height: '100%' }}>
            <div className="default-page__content">
              <Promotion />
              {auth && <BonusBanner />}
              {isSignedUpWithNumberInBranchHelper() && (
                  <WhatsappBanner
                    link={JSON.parse(localStorage.getItem('user_data'))?.wa}
                  />
                )}
              {inplayPopular && inplayPopular.inPlayEvents ? (
                <>
                  <InPlayPopular
                    pathname={pathname}
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={inplayPopular && inplayPopular.inPlayEvents}
                    type={headerType['INPLAY']}
                    orderCount={(orderCount && orderCount.count) || 0}
                    promotions={promotions}
                  />
                  <InPlayPopular
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={inplayPopular && inplayPopular.popularEvents}
                    type={headerType['POPULAR']}
                  />
                </>
              ) : (
                <LoaderContent />
              )}
              <LegalAgePopover open={legalAgePopoverShow} />
              <KycPopover open={kycPopoverShow} />
              <MoreRulesPopover open={moreRulesPopoverShow} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default connectDefaultPage()(DefaultPage)
