import './TopBaner.scss'
import React from 'react'
import TopBannerSlider from './TopBannerSlider/TopBannerSlider';
import TopBannerOne from './TopBannerSlider/TopBannerOne';
import { useHistory } from 'react-router';

const TopBaner = ({inPlaySlidingBanners, auth}) => {

  const history = useHistory()

  return (
    <div className={`top-baner-wrapper ${history.location.pathname.indexOf('default') !== -1 && 'topBannerPadding'}`}>
        {inPlaySlidingBanners.length === 1 
        ? <TopBannerOne inPlaySlidingBanners={inPlaySlidingBanners} auth={auth}/> 
        : <TopBannerSlider inPlaySlidingBanners={inPlaySlidingBanners} auth={auth}/>}
    </div>
  )
}

export default TopBaner
