import './SportPageItem.scss'
import React, { useEffect, useState } from 'react'
import { IonImg, IonItem } from '@ionic/react'
import OddButton from '../OddButton/oddbutton'
import BetPlacing from '../BetPlacing/BetPlacing'
import broadcastImg from '../../assets/images/inplay-broadcast-icon.svg'
import broadcastImg2 from '../../assets/images/inplay-logo-iconSport.svg'
import { headerType } from '../../helpers/InPlayPopular.helper'
import { getFormattedDate } from '../../helpers/date.helper'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  addfavoritesItem,
  clearfavoritesItem,
  addfavoritesItemPop,
  clearfavoritesItemPop,
  addfavoritesItemFuture,
  clearfavoritesItemFuture,
  addFavoritesAll,
  clearFavoritesAll,
} from '../../store/favorites/favorites.slice'
import StarIcon from '../../assets/images/sideBarStar.svg'
import StarIconFilled from '../../assets/images/sideBarStarFilled.svg'
import { requestSportData } from '../../store/sportEvent/sportEvent.thunks'

import { formatTime } from '../../helpers/date.helper'
import { useParams } from 'react-router'
import { setIsOpenSport } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setIsSportsGroupName } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setIsMoreDetailed } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setIsEventDetailed } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setIsAnimatedMenu } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setSportsName } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'

const SportPageItem = ({
  type,
  sportItemData,
  timeSettings,
  displayedBack,
  setDisplayedBack,
  betPlacing,
  actions,

}) => {
  const history = useHistory()
  const [item, setItem] = useState(null)
  const [competition, setCompetition] = useState()

  const dispatch = useDispatch()

  const isNavigationID = useSelector(
    (state) => state.navigationHeaderMenu.navigationID
  )

  const isOpenSport = useSelector(
    (state) => state.navigationHeaderMenu.openSport
  )
  const sportsGroupName = useSelector(
    (state) => state.navigationHeaderMenu.sportsGroupName
  )

  const eventDetailed = useSelector(
    (state) => state.navigationHeaderMenu.moreDetailed
  )

  const altName = useSelector(
    (state) => state.navigationHeaderMenu.altName
  )

  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX)
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 1) {
      setDisplayedBack(true)
    }

    if (touchStart - touchEnd < -1) {
      setDisplayedBack(false)
    }

    setTouchEnd(0)
    setTouchStart(0)
  }

  useEffect(() => {
    setItem(sportItemData)
  }, [sportItemData])

  const gotoEvent = () => {
    if (item.eventTypeId == '77777'){
      history.push(`/casino_live/${item.event.id}`)

    } else{
      history.push(`/event-page/${sportItemData.eventTypeId}/${item.event.id}`)
      dispatch(setIsOpenSport(true))
      dispatch(setIsEventDetailed(true))
      dispatch(setIsAnimatedMenu(true))
      dispatch(setIsMoreDetailed(true))
      dispatch(setIsSportsGroupName(item.competition.name))
      dispatch(setSportsName(item.event.name))
    }
  }

  // console.log('item', item);




  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth

  const getOdds = () => {
    if (!item) return

    let newOdds = {
      back: [
        {
          runner: {
            id: item?.runners[0]?.id,
            name: item?.runners[0]?.name,
          },
          price: item?.runners[0]?.back[0]?.price,
          size: item?.runners[0]?.back[0]?.size,
          line: item?.runners[0]?.back[0]?.line,
        },
        {
          runner: {
            id: item?.runners[1]?.id,
            name: item?.runners[1]?.name,
          },
          price: item?.runners[1]?.back[0]?.price,
          size: item?.runners[1]?.back[0]?.size,
          line: item?.runners[1]?.back[0]?.line,
        },
        {
          runner: {
            id: item?.runners[2]?.id || null,
            name: item?.runners[2]?.name || null,
          },
          price: item?.runners[2]?.back[0]?.price || null,
          size: item?.runners[2]?.back[0]?.size || null,
          line: item?.runners[2]?.back[0]?.line || null,
        },
      ],
      lay: [
        {
          runner: {
            id: item?.runners[0]?.id,
            name: item?.runners[0]?.name,
          },
          price: item?.runners[0]?.lay[0]?.price,
          size: item?.runners[0]?.lay[0]?.size,
          line: item?.runners[0]?.lay[0]?.line,
        },
        {
          runner: {
            id: item?.runners[1]?.id,
            name: item?.runners[1]?.name,
          },
          price: item?.runners[1]?.lay[0]?.price,
          size: item?.runners[1]?.lay[0]?.size,
          line: item?.runners[1]?.lay[0]?.line,
        },
        {
          runner: {
            id: item?.runners[2]?.id || null,
            name: item?.runners[2]?.name || null,
          },
          price: item?.runners[2]?.lay[0]?.price || null,
          size: item?.runners[2]?.lay[0]?.size || null,
          line: item?.runners[2]?.lay[0]?.line || null,
        },
      ],
    }

    const status = item?.status

    const odds = newOdds
    const back = odds?.back
    const lay = odds?.lay

    if (status && !!status.localeCompare('OPEN')) {
      return (
        <div
          className={`inplay-item__back-inner ${
            displayedBack
              ? 'inplay-item__status-inner-right'
              : 'inplay-item__status-inner-left'
          }`}
          onTouchMove={(e) => handleTouchMove(e)}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={(e) => handleTouchEnd(e)}
        >
          <div className="inplay-item__status">{status.toUpperCase()}</div>
        </div>
      )
    }

    if (width < 1024) {
      return (
        <div
          className={`inplay-item__back-inner  ${
            displayedBack
              ? 'inplay-item__back-inner-right'
              : 'inplay-item__back-inner-left'
          }`}
          onTouchMove={(e) => handleTouchMove(e)}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={(e) => handleTouchEnd(e)}
        >
          {/*{*/}
          {/*  sportItemData && sportItemData.eventTypeId == "2"*/}
          {/*    ?*/}
          {/*    <>*/}
          {/*      {item && back && back[0] && back[0].price && <OddButton market={item} isBack={true} oddData={back && back[0]} centered/>}*/}
          {/*      {item && back && back[2] && back[2].price && <OddButton market={item} isBack={true} oddData={back && back[2]} centered/>}*/}
          {/*      {item && back && back[1] && back[1].price && <OddButton market={item} isBack={true} oddData={back && back[1]} centered/>}*/}
          {/*      {item && lay && lay[0] && lay[0].price && <OddButton market={item} isBack={false} oddData={lay && lay[0]} centered/>}*/}
          {/*      {item && lay && lay[2] && lay[2].price && <OddButton market={item} isBack={false} oddData={lay && lay[2]} centered/>}*/}
          {/*      {item && lay && lay[1] && lay[1].price && <OddButton market={item} isBack={false} oddData={lay && lay[1]} centered/>}*/}
          {/*    </>*/}
          {/*    :*/}
              <>
                <OddButton market={item} isBack={true} oddData={back && back[0]} centered/>
                <OddButton market={item} isBack={true} oddData={back && back[2]} centered/>
                <OddButton market={item} isBack={true} oddData={back && back[1]} centered/>
                <OddButton market={item} isBack={false} oddData={lay && lay[0]} centered/>
                <OddButton market={item} isBack={false} oddData={lay && lay[2]} centered/>
                <OddButton market={item} isBack={false} oddData={lay && lay[1]} centered/>
              </>
          {/*}*/}
        </div>
      )
    }

    return (
      <div
        className={`inplay-item__back-inner  ${
          displayedBack
            ? 'inplay-item__back-inner-right'
            : 'inplay-item__back-inner-left'
        }`}
        onTouchMove={(e) => handleTouchMove(e)}
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchEnd={(e) => handleTouchEnd(e)}
      >
        {
          sportItemData && sportItemData.eventTypeId == "2"
          ?
            // <>
            //   {item && back && back[0] && back[0].price && <OddButton market={item} isBack={true} oddData={back && back[0]} centered/>}
            //   {item && back && back[2] && back[2].price && <OddButton market={item} isBack={true} oddData={back && back[2]} centered/>}
            //   {item && back && back[1] && back[1].price && <OddButton market={item} isBack={true} oddData={back && back[1]} centered/>}
            //   {item && lay && lay[0] && lay[0].price && <OddButton market={item} isBack={false} oddData={lay && lay[0]} centered/>}
            //   {item && lay && lay[2] && lay[2].price && <OddButton market={item} isBack={false} oddData={lay && lay[2]} centered/>}
            //   {item && lay && lay[1] && lay[1].price && <OddButton market={item} isBack={false} oddData={lay && lay[1]} centered/>}
            // </>
            <>
              {/*{item && back && back[0] && back[0].price && <OddButton market={item} isBack={true} oddData={back && back[0]} centered/>}*/}
              {/*{item && lay && lay[0] && lay[0].price && <OddButton market={item} isBack={false} oddData={lay && lay[0]} centered/>}*/}
              {/*/!*{item && back && back[2] && back[2].price && <OddButton market={item} isBack={true} oddData={back && back[2]} centered/>}*!/*/}
              {/*/!*{item && lay && lay[2] && lay[2].price && <OddButton market={item} isBack={false} oddData={lay && lay[2]} centered/>}*!/*/}
              {/*{item && back && back[1] && back[1].price && <OddButton market={item} isBack={true} oddData={back && back[1]} centered/>}*/}
              {/*{item && lay && lay[1] && lay[1].price && <OddButton market={item} isBack={false} oddData={lay && lay[1]} centered/>}*/}

              {<OddButton market={item} isBack={true} oddData={back && back[0]} centered/>}
              {<OddButton market={item} isBack={false} oddData={lay && lay[0]} centered/>}
              {<OddButton market={item} isBack={true} oddData={back && back[1]} centered/>}
              {<OddButton market={item} isBack={false} oddData={lay && lay[1]} centered/>}
            </>
          :
            // <>
            //   <OddButton market={item} isBack={true} oddData={back && back[0]} centered/>
            //   <OddButton market={item} isBack={true} oddData={back && back[2]} centered/>
            //   <OddButton market={item} isBack={true} oddData={back && back[1]} centered/>
            //   <OddButton market={item} isBack={false} oddData={lay && lay[0]} centered/>
            //   <OddButton market={item} isBack={false} oddData={lay && lay[2]} centered/>
            //   <OddButton market={item} isBack={false} oddData={lay && lay[1]} centered/>
            // </>
          <>
            <OddButton market={item} isBack={true} oddData={back && back[0]} centered/>
            <OddButton market={item} isBack={false} oddData={lay && lay[0]} centered/>
            <OddButton market={item} isBack={true} oddData={back && back[2]} centered/>
            <OddButton market={item} isBack={false} oddData={lay && lay[2]} centered/>
            <OddButton market={item} isBack={true} oddData={back && back[1]} centered/>
            <OddButton market={item} isBack={false} oddData={lay && lay[1]} centered/>
          </>
        }
      </div>
    )
  }

  const timeZoneName = useSelector((state) => state.timeSettings.timeZoneName)
  const menu = useSelector((state) => state.menu)

  const getZonedTime = (timezone, time) => {
    let date = time ? new Date(time) : new Date()
    if (timezone === 'system')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    if (timezone === 'india')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 330)

    return date
  }
  const tsToDate = (ts, timezone) => {
    const date = getZonedTime(timezone, ts)

    return `${
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate()
    }/${
      (date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${formatTime(
      date.getMinutes()
    )}:${formatTime(date.getSeconds())}`
  }
  const monthName = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ]

  const getTime = () => {
    const now = new Date(item?.start)
    if (timeZoneName === 'system')
      return (
        <>
          <span>
            {now.getUTCDate().toString().length === 1
              ? '0' + now.getUTCDate()
              : now.getUTCDate()}
            /{monthName[now.getUTCMonth()]}/{now.getUTCFullYear()}
          </span>
          <span>
            {formatTime(now.getUTCHours())}:{formatTime(now.getUTCMinutes())}:
            {formatTime(now.getUTCSeconds())}
          </span>
        </>
      )
    else if (timeZoneName === 'device')
      return (
        <>
          <span>
            {now.getUTCDate().toString().length === 1
              ? '0' + now.getUTCDate()
              : now.getUTCDate()}
            /{monthName[now.getUTCMonth()]}/{now.getUTCFullYear()}
          </span>
          <span>
            {formatTime(now.getHours())}:{formatTime(now.getMinutes())}:
            {formatTime(now.getSeconds())}
          </span>
        </>
      )
    else {
      now.setMinutes(now.getMinutes() + now.getTimezoneOffset() + 330)
      return (
        <>
          <span>
            {now.getUTCDate().toString().length === 1
              ? '0' + now.getUTCDate()
              : now.getUTCDate()}
            /{monthName[now.getUTCMonth()]}/{now.getUTCFullYear()}
          </span>
          <span>
            {formatTime(now.getHours())}:{formatTime(now.getMinutes())}:
            {formatTime(now.getSeconds())}
          </span>
        </>
      )
    }
  }

  // const openSideBar = () => {
  //   const aaa = menu && menu?.children?.map((game) => {
  //     console.log('game', game?.name);
  //   })
  //   return aaa
  // }

  const getName = () => {
    if (!item) return
    // console.log('name', item?.event);

    const players = item.runners

    if (!players) {
      return <div className="inplay-item__players" onClick={() => {
        gotoEvent()
        // dispatch(requestSportData(1, 155))
      }}></div>
    }
    const hasVideo = item.hasVideo

    const startDate = new Date(item?.start)
    // console.log(item?.event?.name, startDate)
    // console.log(startDate.toISOString())
    // console.log('item', item)
    // console.log(tsToDate(startDate, timeZoneName))
    const optimizedDate = tsToDate(startDate, timeZoneName)

    return (
      <div className="inplay-item__players" onClick={() => {
        gotoEvent()
        }}>
        <span key={item?.id} className="inplay-item__player">
          <span className="inplay-item__player-name">{item?.event?.name}</span>
          <span className="inplay-item__player-date">
            {new Date(item?.start) > new Date() ? (
              <>
                <span>
                  {/* {startDate
                    .toISOString()
                    .slice(0, 10)
                    .replace(/^(\d{4}).(\d{2}).(\d{2})/, '$3.$2.$1')
                    .replaceAll('.', '/')} */}
                  {optimizedDate.slice(0, 10)}
                </span>
                {/* {getTime()} */}
                {/* <span>{startDate.toString().slice(16, 24)}</span> */}
                <span>{optimizedDate.slice(11, 20).split(':', 2).join(':')}</span>
              </>
            ) : (
              <span className="inplay-item__player-inPlay">
                <img src={broadcastImg2} alt="In-Play icon" /> In-Play
              </span>
            )}
          </span>
        </span>

        {hasVideo && (
          <IonImg className="inplay-item__broadcast-icon" src={broadcastImg} />
        )}
      </div>
    )
  }

  const renderBetPlacing = () => {
    const status = item?.status
    if (
      betPlacing.market &&
      betPlacing.market === (item && item.id) &&
      status &&
      !status.localeCompare('OPEN') &&
      width < 1024
    )
      return <BetPlacing />
    return null
  }

  const favoritesState = useSelector((state) => state.favorites)

  localStorage.setItem('favorites', JSON.stringify(favoritesState))

  // const isFavorite = () => {
  //   if (
  //     favoritesState?.inPlay?.length > 0 &&
  //     item &&
  //     item?.event &&
  //     favoritesState?.inPlay?.filter((el) => el?.event?.id === item?.event?.id)
  //       .length
  //   ) {
  //     return true
  //   } else if (
  //     favoritesState?.future?.length > 0 &&
  //     item &&
  //     item?.event &&
  //     favoritesState?.future?.filter((el) => el?.event?.id === item?.event?.id)
  //       ?.length
  //   ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }
  const isFavorite = () => {
    if (
      favoritesState?.favoritesAll?.length > 0 &&
      item &&
      favoritesState?.favoritesAll?.filter((el) => el?.id === item?.id).length
    ) {
      return true
    } else {
      return false
    }
  }
  const [activeIcon, setActiveIcon] = useState()

  const toggleStar = () => {
    setActiveIcon((prevCheck) => !prevCheck)
  }


  useEffect(() => {
    if (isFavorite()) {
      setActiveIcon(true)
    } else setActiveIcon(false)
  }, [favoritesState, item])

  // const handleFavorite = (id, item) => {
  //   // console.log('favitem', item);
  //   if (activeIcon) {
  //     // console.log('if active clear')
  //     if (item.inPlay) {
  //       dispatch(clearfavoritesItem(item))
  //     } else {
  //       dispatch(clearfavoritesItemFuture(item))
  //     }
  //   } else {
  //     // console.log('else add')
  //     if (item.inPlay) {
  //       dispatch(addfavoritesItem(item))
  //     } else {
  //       dispatch(addfavoritesItemFuture(item))
  //     }
  //   }
  // }

  const handleFavorite = (id, item) => {
    if (activeIcon) {
      console.log('if active clear')
      dispatch(clearFavoritesAll(item))
    } else {
      console.log('else add')
      dispatch(addFavoritesAll(item))
    }
  }

  return (
    <div className="sportPageItem">
      <IonItem className="inplay-item">
        {history.location.pathname === '/favouritesPage' ? (
          <img
            onClick={() => {
              // if (item.isInPlay) {
              //   dispatch(clearfavoritesItem(item))
              // } else {
              //   dispatch(clearfavoritesItemFuture(item))
              // }
              dispatch(clearFavoritesAll(item?.market))
            }}
            className="starImg"
            src={StarIconFilled}
            alt="star icon"
          />
        ) : (
          <img
            onClick={() => {
              toggleStar(item)
              handleFavorite(item.event.id, item)
            }}
            className="starImg"
            src={activeIcon ? StarIconFilled : StarIcon}
            alt="star icon"
          />
        )}
        <div className="inplay-item__content">
          <div className="inplay-item__row">
            <div className="inplay-item__desktop">
              {/* {!item?.isInPlay && (
              <div className="inplay-item__score" onClick={gotoEvent}>
                {getScore()}
              </div>
            )} */}

              {getName()}
            </div>

            <div className="inplay-item__back">
              <div className="inplay-item__back-inner">{getOdds()}</div>
            </div>
          </div>
          {renderBetPlacing()}
        </div>
      </IonItem>
    </div>
  )
}

export default SportPageItem
