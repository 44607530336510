import React from 'react'
import Baccarat1 from './images/Baccarat1.png'


const BaccaratRules = () => {
  return (
    <div className="rulesContainer">
      <div>
                <h3>GAME OBJECTIVES</h3>
                <p>
                    The objective in Baccarat is to predict whose hand will win by having the value closest to 9.
                </p>
            </div>
            <div>
                <h3 >GAME RULES</h3>
                <div>
                    <ul >
                        <li>The game is hosted by a dealer and is played with eight standard 52-card decks. Card values are as follows:
                            Aces are the lowest cards and are worth 1 point each.
                            <ul>
                                <li>Cards from 2 to 9 are worth their numerical face values.</li>
                                <li>10s and face cards (jacks, queens, and kings) are each worth 0.</li>
                            </ul>
                        </li>
                        <li>
                            Only the numeric value of each card is relevant in the main Baccarat game; each card's suit (hearts, spades,
                            clubs, or diamonds) is irrelevant.
                        </li>
                        <li>
                            Prior to each deal, you can place your bet on whether the Player or the
                            Banker will win the round by having the hand with a value closest to 9.
                        </li>
                        <li>
                            If you believe that both the Player and Banker will hold hands of equal
                            value, you also have the option to bet on Tie.
                        </li>
                        <li>
                            In addition, you can also bet on Player/Banker Pair (P/B Pair), which will
                            pay if the first two cards dealt to the Player/Banker constitute a pair.
                        </li>
                        <li>
                            The dealer begins by dealing two cards each to the Player and to the
                            Banker.
                        </li>
                        <li>Two hands are dealt in Baccarat: one hand to the Plaver and one hand to
                            the Banker.
                        </li>
                        <li>
                            If the Player and Banker hold hands of equal value, the round ends in a
                            tie. The Tie bet wins.
                        </li>
                        <li>
                            Each hand's value is calculated by dropping the tens digit in a hand
                            otherwise worth 10 or more. For example, a hand consisting of a 7 and a
                            9 is only worth 6 in Baccarat (because 16-10=6). Similarly, a face card
                            plus a 9 will be worth 9.
                        </li>
                        <li>
                            If the Plaver or the Banker receives an initial two-card hand worth 8 or a
                            9 (a "natural" 8 or 9), no additional cards will be dealt to either hand.
                        </li>
                        <li>
                            If the Player and Banker receive initial two-card hands worth 0-7, the
                            "Third Card Rule" is consulted to determine if a third card is to be dealt to
                            either hand or both hands. The Player always goes first.
                        </li>
                    </ul>
                    <div >
                        <img style={{width: '50%'}} src={Baccarat1} alt="baccarat"/>
                    </div>
                    <ul>
                        <li>D- Draw; S - Stand</li>
                        <li>If the Plaver's hand stands on a 6 or 7, then a Banker hand totalling 3, 4 </li>
                        <li>or 5 must draw, while a Banker hand totalling 6 must stand.</li>
                    </ul>
                    <p>Whoever gets the hand closest to a total of 9 wins.</p>
                </div>
                <div>
                    <h3 >Betting Instructions</h3>
                    <ul>
                        <li>If a game round is in progress when you enter the game, please wait for the next one, and then, place your
                            bets.
                        </li>
                        <li>After the timer reaches 0, the game round begins.</li>
                        <li>To place a bet, click on the box, enter the stake amount and submit by clicking on Place Bet button.</li>
                        <li>You can place several bets on different runners in different markets simultaneously.</li>
                        <li>The timer shows how much time you have left to place your bets.</li>
                        <li>Winnings are paid for the winning bets at the end of each game round.</li>
                        <li>To skip a turn, simply do not place any bets.</li>
                    </ul>
                </div>
                <div >
                    <h3 >Main Bets</h3>
                    <ol >
                        <li>Player</li>
                        <li>Banker</li>
                        <li>Tie</li>
                    </ol>
                </div>
                <div >
                    <h3>Side Bets</h3>
                    <ol >
                        <li>Player Pair: Predict if the first two cards dealt to the Plaver constitute a pair.</li>
                        <li>Banker Pair: Predict if the first two cards dealt to the Banker constitute a pair.</li>
                        <li>Perfect Pair: Predict if two identical cards (in terms of value and suit) are dealt as the first two cards to either the Banker or the Plaver.</li>
                        <li>Either Pair: Predict if the first two cards dealt to either Plaver or Banker constitute a pair.</li>
                        <li>Big: Predict if the total number of cards in the round is more than 4.</li>
                        <li>Small: Predict if the total number of cards in the round is 4.</li>
                    </ol>
                </div>
                <div>
                    <h4 >Payout</h4>
                    <p >Payout depends on the type of bet placed.</p>
                    <table>
                        <tbody>
                        <tr>
                            <th>Player</th>
                            <th>1: 1</th>
                        </tr>
                        <tr>
                            <th>Banker</th>
                            <th>1: 1</th>
                        </tr>
                        <tr>
                            <th>Tie</th>
                            <th>1: 8</th>
                        </tr>
                        <tr>
                            <th>Player Pair</th>
                            <th>1: 11</th>
                        </tr>
                        <tr>
                            <th>Banker Pair</th>
                            <th>1: 11</th>
                        </tr>
                        <tr>
                            <th>Perfect Pair</th>
                            <th>1: 25</th>
                        </tr>
                        <tr>
                            <th>Either Pair</th>
                            <th>1: 5</th>
                        </tr>
                        <tr>
                            <th>Big</th>
                            <th>1: 0.54</th>
                        </tr>
                        <tr>
                            <th>Small</th>
                            <th>1: 1.5</th>
                        </tr>
                        </tbody>
                    </table>
                    <p >Please note that any malfunction voids the game round and all eventual payouts
                        for the round. Bets will be returned.</p>
                </div>
            </div>
    </div>
  )
}
export default BaccaratRules
