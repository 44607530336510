import React from 'react'
import "./DragonTigerLastResult.scss"


const DragonTigerLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
        return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
        return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
};

  return (
      <div className="dragon-tiger-last-result">
        <div className="dragon-tiger-last-result__player">
          <div>Dragon</div>
          <div
            className={data && data?.marketId?.marketRunner[0]?.status === "WINNER"
              ? "dragon-tiger-last-result__player-cards-isWinner"
              : "dragon-tiger-last-result__player-cards"}>
            <img
              className="dragon-tiger-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[0])}
              alt=""
            />
          </div>
        </div>
        <div className="dragon-tiger-last-result__player">
          <div>Tiger</div>
          <div
            className={data && data?.marketId?.marketRunner[1]?.status === "WINNER"
              ? "dragon-tiger-last-result__player-cards-isWinner"
              : "dragon-tiger-last-result__player-cards"}>
            <img
              className="dragon-tiger-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[0])}
              alt=""
            />
          </div>
        </div>
      </div>
  )
}
export default DragonTigerLastResult
