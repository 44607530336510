import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

export const connectRacingPage = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      timeSettings: state.timeSettings,
      navigationHeaderMenu: state.navigationHeaderMenu,
      betPlacing: state.betPlacing,
      racingPage: state.racingPage
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
