import "./PokerSixPlayerPL.scss";
import React, { useEffect, useState } from 'react'

const PokerSixPlayerPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {};
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="poker6-player-betting">
        <div className="poker6-player-betting__games">
          <div
            className={`poker6-player-betting__games-item ${roundMarkets[0].marketRunner[0].status === 'WINNER' ? 'winnerCup':''}`}>
            <span className="poker6-player-betting__games-item-title">A</span>
            <div className="poker6-player-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[0].cards.map(card => <img className="poker6-player-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
          <div
            className={`poker6-player-betting__games-item ${roundMarkets[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}`}>
            <span className="poker6-player-betting__games-item-title">B</span>
            <div className="poker6-player-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[1].cards.map(card => <img className="poker6-player-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
          <div
            className={`poker6-player-betting__games-item ${roundMarkets[0].marketRunner[2].status === 'WINNER' ? 'winnerCup':''}`}>
            <span className="poker6-player-betting__games-item-title">C</span>
            <div className="poker6-player-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[2].cards.map(card => <img className="poker6-player-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
          <div
            className={`poker6-player-betting__games-item ${roundMarkets[0].marketRunner[3].status === 'WINNER' ? 'winnerCup':''}`}>
            <span className="poker6-player-betting__games-item-title">D</span>
            <div className="poker6-player-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[3].cards.map(card => <img className="poker6-player-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
          <div
            className={`poker6-player-betting__games-item ${roundMarkets[0].marketRunner[4].status === 'WINNER' ? 'winnerCup':''}`}>
            <span className="poker6-player-betting__games-item-title">E</span>
            <div className="poker6-player-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[4].cards.map(card => <img className="poker6-player-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
          <div
            className={`poker6-player-betting__games-item ${roundMarkets[0].marketRunner[5].status === 'WINNER' ? 'winnerCup':''}`}>
            <span className="poker6-player-betting__games-item-title">F</span>
            <div className="poker6-player-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[5].cards.map(card => <img className="poker6-player-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
        </div>
        <div className="poker6-player-betting__info-column">
          <div className="poker6-player-betting__info-column-wrapper">
            <div className="poker6-player-betting__info-row">
              <div className="poker6-player-betting__info-row-title">
                <span>Winner:</span>
              </div>
              <div className="poker6-player-betting__info-row-results">
                <span>{winnerData && winnerData['Hands']  || 'No Winner' }</span>
              </div>
            </div>
            <div className="poker6-player-betting__info-row">
              <div className="poker6-player-betting__info-row-title">
                <span>Pattern:</span>
              </div>
              <div className="poker6-player-betting__info-row-results">
                <span>{winnerData && winnerData['Patterns'] || 'No Pattern' }</span>
              </div>
            </div>
          </div>
          <div className="poker6-player-betting__info-column-item-board">
            <span className="poker6-player-betting__games-item-title">Board</span>
            <div className="poker6-player-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].indexCard.map(card => <img className="poker6-player-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
        </div>
      </div>
    );
};

export default PokerSixPlayerPL;

