import { createSlice } from '@reduxjs/toolkit'

export const continueSessionModalSlice = createSlice({
    name: 'continueSessionModalShow',
    initialState: null,
    reducers: {
        setContinueSessionModalShow: (state, action) => action.payload,
    },
})

export const {
    setContinueSessionModalShow,
} = continueSessionModalSlice.actions

export default continueSessionModalSlice.reducer
