import { createSlice } from '@reduxjs/toolkit'

export const twitterSettingsSlice = createSlice({
    name: 'twitterSettings',
    initialState: null,
    reducers: {
        getTwitterSettingsSuccess: (state, action) => action.payload,
        getTwitterSettingsError: (state) => state,
    },
})

export const { getTwitterSettingsSuccess, getTwitterSettingsError } = twitterSettingsSlice.actions

export default twitterSettingsSlice.reducer
