import "./HiLowLastResult.scss";
import React from "react";

const HiLowLastResult = ({data}) => {
    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };

  const getWinnerPattern = (data) => {
    const winner = data.marketId.marketRunner.filter((runner) => {
      return runner.status === 'WINNER';
    })
    if (winner.length > 0) {
      return winner[0].name || winner[0].resDesc || '';
    } else {
      return '';
    }
  }

    return (
      <div className="hi-low-last-result-wrapper">
        <div className="hi-low-last-result">
          <div className="hi-low-last-result__player">
            <div className="hi-low-last-result__player-title">Joker</div>
            <div
              className={data && data?.marketId?.marketRunner[0]?.status === "WINNER"
                ? "hi-low-last-result__player-cards-isWinner"
                : "hi-low-last-result__player-cards"}>
              <img
                className="hi-low-last-result__player-cards-card"
                src={cardSrc(data && data?.marketId?.indexCard[0])}
                alt=""
              />
            </div>
          </div>
          <div className="hi-low-last-result__player">
            <div className="hi-low-last-result__player-title">Ante</div>
            <div
              className={data && data?.marketId?.marketRunner[2]?.status === "WINNER"
                ? "hi-low-last-result__player-cards-isWinner"
                : "hi-low-last-result__player-cards"}>
              <img
                className="hi-low-last-result__player-cards-card"
                src={cardSrc(data && data?.marketId?.indexCard[1])}
                alt=""
              />
            </div>
          </div>
        </div>
        {data && getWinnerPattern(data) &&
          <div className="hi-low-last-result-winner-pattern">
            <span>{getWinnerPattern(data)}</span>
          </div>
        }
      </div>
    );
};
export default HiLowLastResult;
