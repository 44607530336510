import { createSlice } from '@reduxjs/toolkit'

export const androidVersionSlice = createSlice({
  name: 'androidVersion',
  initialState: '',
  reducers: {
    setAndroidVersion: (state, action) => action.payload,
    // setPopUpOpen: (state, action) => ({...state, popUpOpen: action.payload}),

  },
})

export const { setAndroidVersion } = androidVersionSlice.actions

export default androidVersionSlice.reducer
