import { createSlice } from '@reduxjs/toolkit'

export const orderCountSlice = createSlice({
    name: 'orderCount',
    initialState: null,
    reducers: {
        getOrderCountSuccess: (state, action) => action.payload,
        getOrderCountError: (state) => state,
    },
})

export const { getOrderCountSuccess, getOrderCountError } = orderCountSlice.actions

export default orderCountSlice.reducer