import { createSlice } from '@reduxjs/toolkit'

export const BetbyWidgetSlice = createSlice({
  name: 'betbyWidgetLive',
  initialState: null,
  reducers: {
    getBetbyWidgetSuccess: (state, action) => action.payload,
    getBetbyWidgetError: (state) => null,
  },
})

export const { getBetbyWidgetSuccess, getBetbyWidgetError } = BetbyWidgetSlice.actions

export default BetbyWidgetSlice.reducer
