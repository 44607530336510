export const showDeposit = () =>{
  try {
    let userData = JSON.parse(localStorage.getItem('user_data'))
    return !!userData.showDeposit;
  }
  catch (e) {
    return false;
  }

  return false;
}
