import React from 'react'

const MatkaRules = () => {
  return (
    <div className="rulesContainer">
      <div>
                <h3>GAME OBJECTIVES</h3>
                <p>
                    In Matka, the winner is declared on the basis of last digit of the sum of all the cards which is known as the
                    Total Pana. The objective is to predict which Total Pana will be the winner.
                </p>
            </div>
            <div>
                <h3>GAME RULES</h3>
                <div>
                    <h4>Single</h4>
                    <div class="game-rules__main-matka-rules-item-inner">
                        <h5>Total Pana</h5>
                        <ul>
                            <li>Sum of all three Panas is the Total Pana.</li>
                            <li>Rate paid is 9.5 .</li>
                        </ul>
                    </div>
                    <div>
                        <h5>Odd Even</h5>
                        <ul>
                            <li><b>Odd Pana</b> is considered when <b>Total Pana</b> is Odd.</li>
                            <li><b>Even Pana</b> is considered when <b>Total Pana</b> is Even.</li>
                        </ul>
                    </div>
                    <div>
                        <h5>Line</h5>
                        <ul >
                            <li><b>Line1 Pana</b> is considered when <b>Total Pana</b> is 1, 2, 3, 4, or 5.</li>
                            <li><b>Line2 Pana </b>is considered when <b>Total Pana</b> is 6, 7, 8, 9, or 0.</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h4 >SP (Single Pana)</h4>
                    <div >
                        <h5>SP</h5>
                        <ul >
                            <li>Each <b>Single Pana</b> has 12 combinations.</li>
                            <li><b>Single Pana</b> {`is always in X < Y < Z format and all three numbers of Pana are unique.`}</li>
                            <li>Rate Paid for <b>Single Pana:</b> 140</li>
                            <li>
                                <span>For example:</span>
                                <ul >
                                    <li>for every number, there are 12 combinations so when you bet 100 you are actually betting 1200</li>
                                    <li>If the Total Pana is equal to the number you selected, Your payout will be<br/>140*100 = 14000 -
                                        1200 = 12800
                                    </li>
                                    <li>If the Total Pana is not equal to the number you selected, you will lose 1200.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div >
                        <h5>ALL SP</h5>
                        <ul>
                            <li><b>All SP</b> has 120 combinations.</li>
                            <li>If all three numbers of Pana are unique, it is <b>All SP</b></li>
                            <li>Rate Paid for <b>All SP:</b> 140</li>
                            <li>
                                <span>For example:</span>
                                <ul >
                                    <li>for every number, there are 120 combinations so when you bet 100 you are actually betting 12000
                                    </li>
                                    <li>If you win, your payout will be<br/>140*100 = 14000 - 12000 = 2000</li>
                                    <li>If you lose, you will lose 12000.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h4 >DP (Double Pana)</h4>
                    <div >
                        <h5>DP</h5>
                        <ul >
                            <li>Each <b>Double Pana</b> has 9 combinations</li>
                            <li><b>Double Pana</b>{` is always in X = Y < Z or X < Y = Z format and Pana has two same numbers.`}</li>
                            <li>Rate Paid for <b>Double Pana</b>: 240</li>
                            <li>
                                <span>For example:</span>
                                <ul >
                                    <li>for every number, there are 9 combinations so when you bet 100 you are actually betting 900</li>
                                    <li>If the Total Pana is equal to the number you selected, Your payout will be <br/>240*100 = 24000 - 900 = 23100</li>
                                    <li>If the Total Pana is not equal to the number you selected, you will lose 900.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h5>ALL DP</h5>
                        <ul >
                            <li>All <b>DP</b> has 90 combinations</li>
                            <li>If any two numbers of Pana out of three are same, it is <b>DP</b></li>
                            <li>Rate Paid for <b>All SP</b>: 240</li>
                            <li>
                                <span>For example:</span>
                                <ul>
                                    <li>for every number, there are 90 combinations so when you bet 100 you are actually betting 9000</li>
                                    <li>If you win, your payout will be <br/>240*100 = 24000 - 9000 = 15000</li>
                                    <li>If you lose, you will lose 9000.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div >
                    <h4 >All Trio </h4>
                    <div >
                        <ul >
                            <li>All trio has 10 combinations</li>
                            <li>If all three numbers of Pana are the same, it is <b>All Trio</b></li>
                            <li>Rate Paid for <b>All Trio</b>: 700</li>
                            <li>
                                <span>For example:</span>
                                <ul >
                                    <li>There are 10 combinations in All Trio so when you bet 100 you are actually betting 1000</li>
                                    <li>If you win, your payout will be <br/>700*100 = 70000 - 1000 = 69000</li>
                                    <li>If you lose, you will lose 1000.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h4>Common SP</h4>
                    <div >
                        <ul >
                            <li>Each <b>Common SP</b> has 36 combinations</li>
                            <li>In common SP, All three numbers are unique and contain a selected number</li>
                            <li>Rate Paid for <b>Common SP</b>: 140</li>
                            <li>
                                <span>For example:</span>
                                <ul >
                                    <li>for every number, there are 36 combinations so when you bet 100 you are actually betting 3600</li>
                                    <li>If a combination is a winner from your selected combinations, Your payout will be <br/>140*100 = 14000 - 3600 = 10400</li>
                                    <li>If the winner combination does not belong to your selected combinations, you will lose 3600.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h4 >Common DP</h4>
                    <div >
                        <ul>
                            <li>Each <b>Common DP</b> has 18 combinations</li>
                            <li>In common DP, the selected number needs to be repeated otherwise any number repeated with the selected number</li>
                            <li>Rate Paid for <b>Common DP</b>: 240</li>
                            <li>
                                <span>For example:</span>
                                <ul >
                                    <li>for every number, there are 18 combinations so when you bet 100 you are actually betting 1800</li>
                                    <li>If a combination is a winner from your selected combinations, Your payout will be <br/>240*100 = 24000 - 1800 = 22200</li>
                                    <li>If the winner combination does not belong to your selected combinations, you will lose 1800.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div >
                    <h4 >Betting Instructions</h4>
                    <div>
                        <ul>
                            <li>If a game round is in progress when you enter the game, please wait for the next one, and then, place your bets.</li>
                            <li>After the timer reaches 0, the game round begins.</li>
                            <li>To place a bet, click on the box, enter the stake amount and submit by clicking on Place Bet button.</li>
                            <li>You can place several bets on different runners in different markets simultaneously.</li>
                            <li>The timer shows how much time you have left to place your bets.</li>
                            <li>Winnings are paid for the winning bets at the end of each game round.</li>
                            <li>To skip a turn, simply do not place any bets.</li>
                        </ul>
                    </div>
                    <div>
                        <h5>Main Bets</h5>
                        <ul >
                            <li>Total Pana</li>
                        </ul>
                    </div>
                    <div>
                        <h5>Side Bets</h5>
                        <ul >
                            <li>Odd Even</li>
                            <li>Line</li>
                            <li>SP</li>
                            <li>ALL SP</li>
                            <li>DP</li>
                            <li>ALL DP</li>
                            <li>ALL TRIO</li>
                            <li>Common SP</li>
                            <li>Common DP</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h4 >Payout</h4>
                    <p>Payout depends on the type of bet placed.</p>
                    <div >
                        <table>
                            <tbody>
                            <tr>
                                <th><b>Total Pana</b></th>
                                <th>1: 8.5</th>
                            </tr>
                            <tr>
                                <th><b>Odd Even</b></th>
                                <th>1: 0.95</th>
                            </tr>
                            <tr>
                                <th><b>Line</b></th>
                                <th>1: 0.95</th>
                            </tr>
                            <tr>
                                <th>SP</th>
                                <th>1: 128</th>
                            </tr>
                            <tr>
                                <th>ALL SP</th>
                                <th>1: 20</th>
                            </tr>
                            <tr>
                                <th>DP</th>
                                <th>1: 231</th>
                            </tr>
                            <tr>
                                <th>ALL DP</th>
                                <th>1: 150</th>
                            </tr>
                            <tr>
                                <th>ALL TRIO</th>
                                <th>1: 690</th>
                            </tr>
                            <tr>
                                <th>Common SP</th>
                                <th>1: 104</th>
                            </tr>
                            <tr>
                                <th>Common DP</th>
                                <th>1: 222</th>
                            </tr>
                            </tbody>
                        </table>
                        <p >Please note that any malfunction voids the game round and all eventual payouts for the round. Bets will be returned.</p>
                    </div>
                </div>
    </div>
    </div>
  )
}
export default MatkaRules
