import { createSlice } from '@reduxjs/toolkit'

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: null,
  reducers: {
    getForgotPasswordSuccess: (state, action) => action.payload,
    getForgotPasswordError: (state) => state,

  },
})

export const {
  getForgotPasswordSuccess,
  getForgotPasswordError,
} = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer
