import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getExchangeGamesAllSuccess, getExchangeGamesAllError } from './exchangeGamesAll.slice'


export const requestExchangeGamesAll = () => async (dispatch, getState) => {
  // const checkAuth = getState().auth

  try {
    const response = await httpNotAuth.get(`/exchange/odds/exchangeGames`)
    if (response.data.success) {
      dispatch(getExchangeGamesAllSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getExchangeGamesAllError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
