import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getOddsError, getOddsSuccess } from './raceOdds.slice'

export const requestRaceOdds = (eventId, oddId) => async (dispatch, getState) => {
  const checkAuth = getState().auth

  if(checkAuth) {
    try {
      const response = await httpAuth.get(`/exchange/odds/race/${eventId}/${oddId}`)
      if (response.data.success) {
        dispatch(getOddsSuccess(response.data.result[0] || []))
      }
      return response
    } catch (e) {
      dispatch(getOddsError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  } else {
    try {
      const response = await httpNotAuth.get(`/exchange/odds/race/${eventId}/${oddId}`)
      if (response.data.success) {
        dispatch(getOddsSuccess(response.data.result[0] || []))
      }
      return response
    } catch (e) {
      dispatch(getOddsError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }
  
}