import React, { useEffect, useState } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./Poker.scss"
import { connectPoker } from './Poker.connect'
import CardsInfo from '../helpers/CardsInfo/CardsInfo'
import LastResults from '../helpers/LastResults/LastResults'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
// import BetPlacing from '../../BetPlacing/BetPlacing'
import { useParams } from 'react-router'

const Poker = ({exchangeGames, sportEvent, betPlacing, actions, exposure, activeTab, headerHeight, auth, elementsParams}) => {

  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  const {eventId} = useParams()

  // sport event data
  const dataOdd = sportEvent.sportData
  // end sport event data

  // request Exchange Game data

  useEffect(() => {
    const timer = setIntervalAsync(async() => {
      await actions
            .requestExchangeGames(eventId)
            .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)
  }, [])
  // End request Exchange Game data

  // Show betslip
  // const renderBetPlacing = (runner) => {
  //   if (betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }
  // End Show betslip

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing

  //get market price
  const getMarketPrice = (marketIndex, runnersIndex) => {
      return (
        dataOdd &&
        dataOdd[marketIndex] &&
        dataOdd[marketIndex].runners[runnersIndex] &&
        dataOdd[marketIndex].runners[runnersIndex].back[0] &&
        dataOdd[marketIndex].runners[runnersIndex].back[0].price
      )
  }
  //end get market price

  // get exposure
  const exposurePnl = (id) => {
    if(auth) {
      const objFinder =
      exposure?.pnlBySelection[
        Object.keys(exposure?.pnlBySelection).filter((item) =>
          item.includes(id)
        )
      ]?.pnl
      if (exchangeGames && exchangeGames[0]?.marketRunner[0]?.status === "WINNER" ||
          exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === "WINNER"){
            return null
          } else{
            return (
              <div
                className={
                  objFinder > 0
                    ? 'exposure-box-poker exposure-poker--pos'
                    : 'exposure-box-poker exposure-poker--neg'
                }
              >
                {/*{exposure?.pnlBySelection[*/}
                {/*  Object.keys(exposure?.pnlBySelection).filter((item) =>*/}
                {/*    item.includes(id)*/}
                {/*  )*/}
                {/*]?.pnl}*/}
              </div>
      )}
    } else return
  }
  // end get exposure

  // get exposure
  const getExposure = (marketIndex, runnerIndex) => {
    if(auth) {
      const id = sportEvent.sportData[marketIndex] &&
        sportEvent.sportData[marketIndex].runners[runnerIndex] &&
        sportEvent.sportData[marketIndex].runners[runnerIndex].id;
      let objFinder = exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl;
      if (objFinder) {
        return (
          <div className={objFinder > 0
            ? "exposure-box-poker exposure-poker--pos"
            : "exposure-box-poker exposure-poker--neg"}
          >{objFinder}</div>
        );
      }
    } else return
  };
  // end get exposure

  // lock market
  const resultStatus = (marketIndex) => {
    return !dataOdd[marketIndex].isBettable && 'lock-ui'
  }
  // end lock market

  return (
    <>
      <CardsInfo/>
      {/*{*/}
      {/*  width < 1024*/}
      {/*    ?*/}
      {/*    <div className="gc-game-title">*/}
      {/*      <div className="game-title">POKER 20-20</div>*/}
      {/*      <div className="game-round-id">*/}
      {/*        #{exchangeGames && exchangeGames[0]?.roundId}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    :*/}
      {/*    ""*/}
      {/*}*/}

      <div className={`PokerWrapper ${eventId === "67563" ? "virtual" : ""}  ${activeTab === 0 ? "fullHeight" : ""}`}>
        <div className='Poker-table'>
          {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
         {/* Player A */}
          <div className='Poker-table-box'>
            <div className='Poker-table-box__playerInfo'>
              <div className='Poker-table-box__playerInfo-title'>Player A</div>
              <div className={`Poker-table-box__playerInfo-exp ${exchangeGames && exchangeGames[0]?.marketRunner[0]?.status === 'WINNER'
              ? 'pokerTrophy'
              : ''}`}>
                {getExposure(0, 0 )}
              </div>
            </div>
            <div className='Poker-table-box__odds'>
              <div className={`Poker-table-box__odds-back ${dataOdd[0]?.status === 'SUSPENDED'
              ? 'backClose'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[0], true)
                  : null
              }>
                <div className='Poker-table-box__odds-back-price'>
                  {dataOdd && dataOdd[0]?.runners[0]?.back[0]?.price || 0}
                </div>
              </div>
              <div className={`Poker-table-box__odds-lay ${dataOdd[0]?.status === 'SUSPENDED'
              ? 'layClose'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[0], false)
                  : null
              }>
                <div className='Poker-table-box__odds-lay-price'>
                  {dataOdd && dataOdd[0]?.runners[0]?.lay[0]?.price || 0}
                </div>
              </div>
            </div>
          </div>


          {/* Player A - Player B */}
          <div className='Poker-table-boxInfo'>
            <div className='Poker-table-boxInfo__playerInfo'>
              <div className='Poker-table-boxInfo__playerInfo-title'>Player A</div>
              <div className='Poker-table-boxInfo__playerInfo-cards'>
                <img className='pokerPlayerCards' src=
                  {cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0])} alt="" />
                <img className='pokerPlayerCards' src=
                  {cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1])} alt="" />
              </div>
            </div>
            <div className='Poker-table-boxInfo__playerInfo'>
              <div className='Poker-table-boxInfo__playerInfo-title'>Player B</div>
              <div className='Poker-table-boxInfo__playerInfo-cards'>
                <img className='pokerPlayerCards' src=
                  {cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0])} alt="" />
                <img className='pokerPlayerCards' src=
                  {cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1])} alt="" />
              </div>
            </div>
          </div>


          {/* Player B */}
          <div className='Poker-table-box'>
            <div className='Poker-table-box__playerInfo'>
              <div className='Poker-table-box__playerInfo-title'>Player B</div>
              <div className={`Poker-table-box__playerInfo-exp ${exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === 'WINNER'
              ? 'pokerTrophy'
              : ''}`}>
                {getExposure(0, 1 )}
              </div>
            </div>
            <div className='Poker-table-box__odds'>
              <div className={`Poker-table-box__odds-back ${dataOdd[0]?.status === 'SUSPENDED'
              ? 'backClose'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[1], true)
                  : null
              }>
                <div className='Poker-table-box__odds-back-price'>
                  {dataOdd && dataOdd[0]?.runners[1]?.back[0]?.price || 0}
                </div>
              </div>
              <div className={`Poker-table-box__odds-lay ${dataOdd[0]?.status === 'SUSPENDED'
              ? 'layClose'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[1], false)
                  : null
              }>
                <div className='Poker-table-box__odds-lay-price'>
                  {dataOdd && dataOdd[0]?.runners[1]?.lay[0]?.price || 0}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LastResults/>
      </div>
    </>
  )
}

export default connectPoker()(Poker)
