import React, { useEffect, useState } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./styles/index.scss"
import CardsInfo from '../helpers/CardsInfo/CardsInfo'
import LastResults from '../helpers/LastResults/LastResults'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
import BetPlacing from '../../BetPlacing/BetPlacing'
import whiteClubs from "../../../assets/images/exchangeGames/dragon-tiger/white-clubs.svg"
import whiteDiamonds from "../../../assets/images/exchangeGames/dragon-tiger/white-diamonds.svg"
import whiteHearts from "../../../assets/images/exchangeGames/dragon-tiger/white-hearts.svg"
import whiteSpades from "../../../assets/images/exchangeGames/dragon-tiger/white-spades.svg"
import transparentSpades from "../../../assets/images/exchangeGames/dragon-tiger/Spades.png"
import transparentClubs from "../../../assets/images/exchangeGames/dragon-tiger/Clubs.png"
import transparentHearts from "../../../assets/images/exchangeGames/dragon-tiger/Hearts.png"
import transparentDiamonds from "../../../assets/images/exchangeGames/dragon-tiger/Diamonds.png"
import { connectDragonTiger } from './DragonTiger.connect'


const DragonTiger = ({exchangeGames, sportEvent, betPlacing, actions, exposure, eventId, headerHeight, auth, activeTab, elementsParams}) => {

  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  // sport event data
  const dataOdd = sportEvent.sportData
  // end sport event data

  // request Exchange Game data
  useEffect(() => {
    const timer = setIntervalAsync(async () => {
      await actions
              .requestExchangeGames(eventId)
              .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)
  }, [])
  // End request Exchange Game data

  // Show betslip
  // const renderBetPlacing = () => {
  //   if (betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight} />
  //   }
  //   return null
  // }
  // End Show betslip

  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing

  //get market price
  const getMarketPrice = (marketIndex, runnersIndex, back = true) => {
    if(!back) {
      return (
        dataOdd &&
        dataOdd[marketIndex] &&
        dataOdd[marketIndex].runners[runnersIndex] &&
        dataOdd[marketIndex].runners[runnersIndex].lay[0] &&
        dataOdd[marketIndex].runners[runnersIndex].lay[0].price
      )
    }
    return (
      dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0].price
    )
  }
  //end get market price

  // get exposure
  const getExposure = (marketIndex, runnerIndex) => {
    if(auth) {
      let id = dataOdd[marketIndex].runners[runnerIndex].id
      let objFinder =  exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl

      return (
        <div
          className={objFinder > 0 ? 'exposure-box-modern exposure-modern--pos' : 'exposure-box-modern exposure-modern--neg'}
        >
          {objFinder && objFinder}
        </div>
      )
    } else return
  }
  // end get exposure

  // lock market
  const marketStatus = (marketIndex) => {
    return (!dataOdd[marketIndex].isBettable && 'lock-ui')
  }
  // end lock market

  // get winner
  const getWinner = (marketIndex, runnerIndex) => {
    return exchangeGames &&
      exchangeGames[marketIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex]?.status
  }
  // get winner

  // console.log('dataOdd', dataOdd)

  return (
    <div className={`dragon-tiger__main ${eventId === '98794' ? 'virtual' : ''} ${activeTab === 0 ? "fullHeight" : ""}`}>
      <CardsInfo/>
      <div className="dragon-tiger__main-wrapper" >
        {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
        <div className="dragon-tiger">
          <div className="dragon-tiger__row dragon-tiger__row-first">
            <div className="dragon-tiger__row-title">Match Odds</div>
            <div className="dragon-tiger__row-cells-wrapper">
              <div className={`dragon-tiger__cell ${getWinner(0,0)} ${marketStatus(0)}`}
                   onClick={() => {oddPush(0,0)}}
              >
                <span className="dragon-tiger__text">Dragon</span>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(0,0)}
                  </span>
                  {getExposure(0,0)}
                </div>
              </div>
              <div className={`dragon-tiger__cell dragon-tiger__cell-small ${getWinner(0,2)} ${marketStatus(0)}`}
                   onClick={() => {oddPush(0,2)}}
              >
                <span className="dragon-tiger__text">Tie</span>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(0,2)}
                  </span>
                  {getExposure(0,2)}
                </div>
              </div>
              <div className={`dragon-tiger__cell ${getWinner(0,1)} ${marketStatus(0)}`}
                   onClick={() => {oddPush(0,1)}}
              >
                <span className="dragon-tiger__text">Tiger</span>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(0,1)}
                  </span>
                  {getExposure(0,1)}
                </div>
              </div>
              <div className={`dragon-tiger__cell dragon-tiger__cell-small dragon-tiger__cell-small-suited-tie ${getWinner(0,3)} ${marketStatus(0)}`}
                   onClick={() => {oddPush(0,3)}}
              >
                <span className="dragon-tiger__text">Suited Tie</span>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(0,3)}
                  </span>
                  {getExposure(0,3)}
                </div>
              </div>
            </div>
          </div>
          <div className="dragon-tiger__row dragon-tiger__row-second">
            <div className="dragon-tiger__row-cells-wrapper">
              <div className="dragon-tiger__row-cells-wrapper-group">
                <div className="dragon-tiger__row-title">Dragon Odd Even</div>
                <div className={`dragon-tiger__cell ${getWinner(1,0)} ${marketStatus(1)}`}
                     onClick={() => {oddPush(1,0)}}
                >
                  <span className="dragon-tiger__text">EVEN</span>
                  <div className="dragon-tiger__cell-info">
                    <span className="dragon-tiger__text bold">
                      {getMarketPrice(1,0)}
                    </span>
                    {getExposure(1,0)}
                  </div>
                </div>
                <div className={`dragon-tiger__cell ${getWinner(1,1)} ${marketStatus(1)}`}
                     onClick={() => {oddPush(1,1)}}
                >
                  <span className="dragon-tiger__text">ODD</span>
                  <div className="dragon-tiger__cell-info">
                    <span className="dragon-tiger__text bold">
                     {getMarketPrice(1,1)}
                    </span>
                    {getExposure(1,1)}
                  </div>
                </div>
              </div>
              <div className="dragon-tiger__row-cells-wrapper-group">
                <div className="dragon-tiger__row-title">Dragon Color</div>
                <div className={`dragon-tiger__cell ${getWinner(3,0)} ${marketStatus(3)}`}
                     onClick={() => {oddPush(3,0)}}
                >
                  <div className="dragon-tiger__cell-img-row">
                    <img src={transparentHearts} alt="Hearts"/>
                    <img src={transparentDiamonds} alt="Diamonds"/>
                  </div>
                  <div className="dragon-tiger__cell-info">
                    <span className="dragon-tiger__text bold">
                      {getMarketPrice(3,0)}
                    </span>
                    {getExposure(3,0)}
                  </div>
                </div>
                <div className={`dragon-tiger__cell ${getWinner(3,1)} ${marketStatus(3)}`}
                     onClick={() => {oddPush(3,1)}}
                >
                  <div className="dragon-tiger__cell-img-row">
                    <img src={transparentSpades} alt="Spades"/>
                    <img src={transparentClubs} alt="Clubs"/>
                  </div>
                  <div className="dragon-tiger__cell-info">
                    <span className="dragon-tiger__text bold">
                      {getMarketPrice(3,1)}
                    </span>
                    {getExposure(3,1)}
                  </div>
                </div>
              </div>
            </div>
            <div className="dragon-tiger__row-cells-wrapper">
              <div className="dragon-tiger__row-cells-wrapper-group">
                <div className="dragon-tiger__row-title">Tiger Odd Even</div>
                <div className={`dragon-tiger__cell ${getWinner(2,0)} ${marketStatus(2)}`}
                     onClick={() => {oddPush(2,0)}}
                >
                  <span className="dragon-tiger__text">EVEN</span>
                  <div className="dragon-tiger__cell-info">
                    <span className="dragon-tiger__text bold">
                     {getMarketPrice(2,0)}
                    </span>
                    {getExposure(2,0)}
                  </div>
                </div>
                <div className={`dragon-tiger__cell ${getWinner(2,1)} ${marketStatus(2)}`}
                     onClick={() => {oddPush(2,1)}}
                >
                  <span className="dragon-tiger__text">ODD</span>
                  <div className="dragon-tiger__cell-info">
                    <span className="dragon-tiger__text bold">
                     {getMarketPrice(2,1)}
                    </span>
                    {getExposure(2,1)}
                  </div>
                </div>
              </div>
              <div className="dragon-tiger__row-cells-wrapper-group">
                <div className="dragon-tiger__row-title">Tiger Color</div>
                <div className={`dragon-tiger__cell ${getWinner(4,0)} ${marketStatus(4)}`}
                     onClick={() => {oddPush(4,0)}}
                >
                  <div className="dragon-tiger__cell-img-row">
                    <img src={transparentHearts} alt="Hearts"/>
                    <img src={transparentDiamonds} alt="Diamonds"/>
                  </div>
                  <div className="dragon-tiger__cell-info">
                    <span className="dragon-tiger__text bold">
                      {getMarketPrice(4,0)}
                    </span>
                    {getExposure(4,0)}
                  </div>
                </div>
                <div className={`dragon-tiger__cell ${getWinner(4,1)} ${marketStatus(4)}`}
                     onClick={() => {oddPush(4,1)}}
                >
                  <div className="dragon-tiger__cell-img-row">
                    <img src={transparentSpades} alt="Spades"/>
                    <img src={transparentClubs} alt="Clubs"/>
                  </div>
                  <div className="dragon-tiger__cell-info">
                    <span className="dragon-tiger__text bold">
                      {getMarketPrice(4,1)}
                    </span>
                    {getExposure(4,1)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dragon-tiger__row dragon-tiger__row-third">
            <div className="dragon-tiger__row-cells-wrapper">
              <div className="dragon-tiger__row-title">Dragon Suit</div>
              <div className={`dragon-tiger__cell ${getWinner(5,0)} ${marketStatus(5)}`}
                   onClick={() => {oddPush(5,0)}}
              >
                <div className="dragon-tiger__cell-card">
                  <img src={whiteSpades} alt="spades"/>
                </div>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(5,0)}
                  </span>
                  {getExposure(5,0)}
                </div>
              </div>
              <div className={`dragon-tiger__cell ${getWinner(5,1)} ${marketStatus(5)}`}
                   onClick={() => {oddPush(5,1)}}
              >
                <div className="dragon-tiger__cell-card">
                  <img src={whiteHearts} alt="hearts"/>
                </div>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                   {getMarketPrice(5,1)}
                  </span>
                  {getExposure(5,1)}
                </div>
              </div>
              <div className={`dragon-tiger__cell ${getWinner(5,2)} ${marketStatus(5)}`}
                   onClick={() => {oddPush(5,2)}}
              >
                <div className="dragon-tiger__cell-card">
                  <img src={whiteClubs} alt="clubs"/>
                </div>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(5,2)}
                  </span>
                  {getExposure(5,2)}
                </div>
              </div>
              <div className={`dragon-tiger__cell ${getWinner(5,3)} ${marketStatus(5)}`}
                   onClick={() => {oddPush(5,3)}}
              >
                <div className="dragon-tiger__cell-card">
                  <img src={whiteDiamonds} alt="diamonds"/>
                </div>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(5,3)}
                  </span>
                  {getExposure(5,3)}
                </div>
              </div>
            </div>
            <div className="dragon-tiger__row-cells-wrapper">
              <div className="dragon-tiger__row-title">Tiger Suit</div>
              <div className={`dragon-tiger__cell ${getWinner(6,0)} ${marketStatus(6)}`}
                   onClick={() => {oddPush(6,0)}}
              >
                <div className="dragon-tiger__cell-card">
                  <img src={whiteSpades} alt="spades"/>
                </div>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(6,0)}
                  </span>
                  {getExposure(6,0)}
                </div>
              </div>
              <div className={`dragon-tiger__cell ${getWinner(6,1)} ${marketStatus(6)}`}
                   onClick={() => {oddPush(6,1)}}
              >
                <div className="dragon-tiger__cell-card">
                  <img src={whiteHearts} alt="hearts"/>
                </div>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                   {getMarketPrice(6,1)}
                  </span>
                  {getExposure(6,1)}
                </div>
              </div>
              <div className={`dragon-tiger__cell ${getWinner(6,2)} ${marketStatus(6)}`}
                   onClick={() => {oddPush(6,2)}}
              >
                <div className="dragon-tiger__cell-card">
                  <img src={whiteClubs} alt="clubs"/>
                </div>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(6,2)}
                  </span>
                  {getExposure(6,2)}
                </div>
              </div>
              <div className={`dragon-tiger__cell ${getWinner(6,3)} ${marketStatus(6)}`}
                   onClick={() => {oddPush(6,3)}}
              >
                <div className="dragon-tiger__cell-card">
                  <img src={whiteDiamonds} alt="diamonds"/>
                </div>
                <div className="dragon-tiger__cell-info">
                  <span className="dragon-tiger__text bold">
                    {getMarketPrice(6,3)}
                  </span>
                  {getExposure(6,3)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LastResults/>
    </div>
  )
}

export default connectDragonTiger()(DragonTiger)
