import { createSlice } from '@reduxjs/toolkit'

export const balanceSlice = createSlice({
  name: 'balance',
  initialState: {},
  reducers: {
    getBalanceSuccess: (state, action) => action.payload,
    getBalanceError: (state) => state,
  },
})

export const { getBalanceSuccess, getBalanceError } = balanceSlice.actions

export default balanceSlice.reducer
