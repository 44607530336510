import './inplay-popular-header.scss'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { headerMap, headerType } from '../../helpers/InPlayPopular.helper'
import { useHistory } from 'react-router-dom'
import MostPopularBanners from '../MostPopularBanners/MostPopularBanners'

const InPlayPopularHeader = ({ type, orderCount }) => {
  const history = useHistory()
  const getHeaderColor = () => {
    switch (type) {
      case headerType['INPLAY']: {
        return 'inplay-header-color'
      }

      case headerType['POPULAR']: {
        return 'popular-header-color'
      }

      case headerType['FUTURE']: {
        return 'future-header-color'
      }
      default:
        return 'popular-header-color'
    }
  }

  const getIconClass = () => {
    switch (type) {
      case headerType['INPLAY']: {
        return 'inplay-header-icon'
      }

      case headerType['POPULAR']: {
        return 'popular-header-icon'
      }

      case headerType['FUTURE']: {
        return ''
      }

      default:
        return 'popular-header-icon'
    }
  }


  {/*PP-827 remove banner */}
  // const getBanner = () => {
  //   if (headerType['POPULAR'] == type) {
  //     return <MostPopularBanners />
  //   } else return null
  // }

  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth

  return (
    <>
      <div className={`inplay-popular-header ${getHeaderColor()}`}>
        <div className="inplay-popular-header__logo">
          <i className={`inplay-popular-header__logo-icon ${getIconClass()}`} />
          <span className="inplay-popular-header__logo-text">
            {headerMap[type]?.title}
          </span>
        </div>
        {width < 1024 ? (
          <>
            {headerMap[type]?.showBets && (
              <div
                className="inplay-popular-header__bets"
                onClick={(e) => {
                  history.push('/my-open-bets')
                }}
              >
                <span className="inplay-popular-header__bets-text">
                  Open Bets
                </span>
                <span className="inplay-popular-header__bets-count">
                  {orderCount}
                </span>
              </div>
            )}
          </>
        ) : null}
      </div>
      {/*PP-827*/}
      {/*{getBanner(headerMap[type])}*/}
    </>
  )
}

export default InPlayPopularHeader
