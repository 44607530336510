import { createSlice } from '@reduxjs/toolkit'

export const editStakesPopUpSlice = createSlice({
    name: 'editStakesPopUp',
    initialState: {
        isOpen: false,
    },
    reducers: {
        setIsOpen: (state, action) => ({ ...state, isOpen: action.payload }),
    },
})

export const {
    setIsOpen,
} = editStakesPopUpSlice.actions

export default editStakesPopUpSlice.reducer
