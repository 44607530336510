import { createSlice } from '@reduxjs/toolkit'

export const inplayPopularSlice = createSlice({
    name: 'inplayPopular',
    initialState: null,
    reducers: {
        getInplayPopularSuccess: (state, action) => action.payload,
        getInplayPopularError: (state) => state,
    },
})

export const {
    getInplayPopularSuccess,
    getInplayPopularError,
} = inplayPopularSlice.actions

export default inplayPopularSlice.reducer
