import './pop-upWithdrawalHelp.scss'
// import { IonButton, IonImg, IonPopover } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setDetailedDepositTransaction } from '../../../store/detailedDepositTransaction/detailedDepositTransaction.slice'
import { useHistory } from 'react-router'

import Step1Withdrawal from '../../../assets/images/depositHelp/Step1Withdrawal.svg'
import Step2Withdrawal from '../../../assets/images/depositHelp/Step2Withdrawal.svg'

import { createPortal } from "react-dom";

const modalRoot = document.querySelector("#modal-root");

const PopUPWithdrawalHelp = ({ content, open, onDidDismiss, data }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = localStorage.getItem('id_token')
  const [helpStep, setHelpStep] = useState(1)

  // const [remark, setRemark] = useState('')
  // const requestId = data.requestId
  // const [utr, setUTR] = useState(data.utr)
  // const [amount, setAmount] = useState(data.amount)

  // console.log('data', data)
  // console.log('requestId', data.requestId)
  // console.log('utr', data.utr)
  // console.log('amount', data.amount)

  // console.log(parseInt('222', 10))

  // const canceledData = {
  //   remark,
  //   requestId,
  // }

  // const editedData = {
  //   requestId,
  //   UTR: utr,
  //   amount,
  // }

  // const cancelOneTransactionRequest = async () => {
  //   return await axios
  //     .post(
  //       `https://dapi.gemexch.bet/api/payments/userid/cancel-request?requestId=${data.requestId}`,
  //       canceledData,
  //       {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => console.log(response.data.result))
  //     .then(history.push('/transactions'))
  // }

  // const editOneTransactionRequest = async () => {
  //   return await axios
  //     .post(
  //       `https://dapi.gemexch.bet/api/payments/userid/update-deposit-request?requestId=${data.requestId}`,
  //       editedData,
  //       {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => console.log(response.data.result))
  //     .then(onDidDismiss(false))
  // }

  return createPortal(
    <>
      
        { open && <div className="editPopUpWithdrawalHelp">
          <div
            className="pop-upWithdrawalHelp_wrapper"
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                onDidDismiss(false)
                setHelpStep(1)
              }
            }}
          >
            <div className={ helpStep === 1  ? 'pop-upWithdrawalHelp-third' : "pop-upWithdrawalHelp"}>
              <div className="pop-upWithdrawalHelp-content">
                <div className="pop-upWithdrawalHelp-content_title">
                  Help
                </div>
                
                {helpStep === 1 &&
                <> 
                <div className='pop-upWithdrawalHelp-content_title-big'>Step 1</div>
                <div className='pop-upWithdrawalHelp-content_title-desc'>1. Enter Amount to be withdrawn.</div>
                <div className='pop-upWithdrawalHelp-content_title-desc'>2. Enter you Account no and IFSC details.</div>
                <div className='pop-upWithdrawalHelp-content_title-desc'>3.Click submit button.</div>
                <div>
                  <img src={Step1Withdrawal} alt=" Step 1" />
                </div>
                <div
                  className="pop-upWithdrawalHelp-content__buttons-send"
                  onClick={() => {
                    setHelpStep(2)
                  }
              }
                  
                >
                  Next
                </div>
                <div className='thirdHelperWithdrawal'></div>
                </>
                }
               
                {helpStep === 2 &&
                <> 
                <div className='pop-upWithdrawalHelp-content_title-big'>Step 2</div>
                <div>
                  <img src={Step2Withdrawal} alt="Step 2" />
                </div>
                <div
                  className="pop-upWithdrawalHelp-content__buttons-got"
                  onClick={() => {
                    onDidDismiss(false)
                    setHelpStep(1)
                  }}
                >
                  Got It
                </div>
                </>
                }
              </div>

              {/* <div className="pop-upHelp-content__buttons">
                { helpStep === 5 ?
                  <div
                  className="pop-upHelp-content__buttons-send"
                  onClick={() => {
                    onDidDismiss(false)
                    setHelpStep(1)
                  }
              }
                  
                >
                  Got It
                </div>
                  :
                  <div
                  className="pop-upHelp-content__buttons-send"
                  onClick={() => setHelpStep(helpStep + 1)}            
                >
                  Next
                </div>}
              </div> */}
            </div>
          </div>
        </div>}
    </>,
    modalRoot
  )
}

export default PopUPWithdrawalHelp
