import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'

import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getMenuError, getMenuSuccess } from './menu.slice'

export const requestMenu = () => async (dispatch, getState) => {
  const checkAuth = getState().auth
  
  if(checkAuth) {
    try {
      const response = await httpAuth.get(`/exchange/odds/sma-menu`)

      if (response.data.success) {
        const menu = await response.data.result.filter((item, index, self) =>
          index === self.findIndex((t) => t.id === item.id)
        )
        dispatch(getMenuSuccess(menu))
      }
      return response
    } catch (e) {
      dispatch(getMenuError())
      //  const errorMsg = getErrorMessage(e)
      //  throw errorMsg
    }
  } else {
    try {
      const response = await httpNotAuth.get(`/exchange/odds/sma-menu`)
      if (response.data.success) {
        const menu = await response.data.result.filter((item, index, self) =>
          index === self.findIndex((t) => t.id === item.id)
        )
        dispatch(getMenuSuccess(menu))
      }
      return response
    } catch (e) {
      dispatch(getMenuError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }
}
