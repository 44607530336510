import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {getAccountStatementError, getAccountStatementSuccess} from './accountStatement.slice'

export const requestAccountStatementSuccess = (memberCode, dateFrom, dateTo) => async (dispatch) => {
    const currentTime = (new Date()).getTime();
    const lastEightDayTime = currentTime - (7 * 24 * 60 * 60 * 1000);
// console.log('lastEightDayTime', lastEightDayTime)
// console.log('currentTime', currentTime)
    try {
        const response = await httpAuth.get(`/account/${memberCode}/reports/statement?from=${dateFrom ? dateFrom : lastEightDayTime}&to=${dateTo || currentTime}`)
        if (response.data.success) {
            dispatch(getAccountStatementSuccess(response.data.result))
        }
        return response
    } catch (e) {
        dispatch(getAccountStatementError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}
