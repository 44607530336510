import { createSlice } from '@reduxjs/toolkit'

export const rulesPageSlice = createSlice({
    name: 'rulesPage',
    initialState: null,
    reducers: {
        getRulesPageSuccess: (state, action) => action.payload,
        getRulesPageError: (state) => null,
    },
})

export const {
    getRulesPageError,
    getRulesPageSuccess,
} = rulesPageSlice.actions

export default rulesPageSlice.reducer
