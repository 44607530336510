import { createSlice } from '@reduxjs/toolkit'

export const exchangeGamesSlice = createSlice({
    name: 'exchangeGames',
    initialState: null,
    reducers: {
        getExchangeGamesSuccess: (state, action) => action.payload,
        getExchangeGamesError: (state) => state,
    },
})

export const {
    getExchangeGamesSuccess,
    getExchangeGamesError,
} = exchangeGamesSlice.actions

export default exchangeGamesSlice.reducer
