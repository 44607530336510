import './index.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import gift from '../../assets/images/menu-icons/gift1.svg'
import close from '../../assets/images/menu-icons/closePopUp.svg'
import arrowOpen from '../../assets/images/arrow-open-pop.svg'
import { setBonusPopUp } from '../../store/bonusPopUp/bonusPopUp.slice'
import { changeWalletStatus } from '../../store/balance/balance.thunks'
import { requestBonusWageringInfoSuccess } from '../../store/bonusWageringInfo/bonusWageringInfo.thunks'
import { useHistory } from 'react-router'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'

const BonusPopUp = ({scrollPosition}) => {

  const dispatch = useDispatch();
  const { balance, user } = useSelector((state) => state);
  const {walletType} = useSelector((state) => state.bonusPopUp)
  const bonusWageringInfo = useSelector((state) => state.bonusWageringInfo)
  const [bonusToggle, setBonusToggle] = useState(false);
  const [openInfo, setOpenInfo] = useState(true);
  const [isRules, setIsRules] = useState();

  useEffect(() => {
    console.log(balance?.useBWallet);
    if (walletType === 'B'){
      setBonusToggle(balance?.useBWallet ? balance?.useBWallet : false)
    } else if (walletType === 'S'){
      setBonusToggle(balance?.useSWallet ? balance?.useSWallet : false)
    } else if (walletType === 'C'){
      setBonusToggle(balance?.useCWallet ? balance?.useCWallet : false)
    } else {
      setBonusToggle(false)
    }
  }, []);


  const expSum = (i) => {
    try{
      let sum = 0;
      // bonusWageringInfo && bonusWageringInfo.result && bonusWageringInfo.result.map((item) => {
      //   sum += item.EXPOSURE
      // })
      if(bonusWageringInfo && bonusWageringInfo?.wagerRemaining){
        sum += bonusWageringInfo.wagerRemaining
      }
      return Math.abs(sum)
    } catch (e) {
      return 0;
    }

  }

  useEffect(() => {
    dispatch(requestBonusWageringInfoSuccess(walletType))
  }, []);

  const bonusWallet = {
    amount: 500,
    offer: 500,
    required: 500,
    remaining: 500,
    validity: 500,
  }

  function createMarkup(info) {
    try{
    if(typeof info === "string") {
      return { __html: info?.replaceAll('\\n', '') }
    } else return { __html: "<div></div>" };
    } catch (e) {
      return { __html: "<div></div>" };
    }
  }

  const changeBonusToggle = (bool) => {
    setBonusToggle(bool)
    dispatch(changeWalletStatus(user.memberCode, bool, walletType));
  }

  return (
    <div className="bonus-pop-up"
    style={{top: scrollPosition + "px"}}
    onClick={(e) => {
        if (e.currentTarget === e.target) {
            dispatch(setBonusPopUp(false));
            setIsRules(false)
        }
      }}>
      <div className="bonus-pop-up__content">
        <div className="bonus-pop-up__header">
          <div className='bonus-pop-up__header-title'>
            <div className="bonus-pop-up__header-title-icon">
              {/*<img src={gift} />*/}
            </div>
            <span>{`${walletType === 'B' ? 'Bonus' : walletType === 'S' ? 'Sport' : 'Casino'} Information`}</span>
          </div>
          <img
            src={close}
            onClick={() => {dispatch(setBonusPopUp(false));
               setIsRules(false)}}
            className="bonus-pop-up__header-closeButton"
          />
        </div>
        {isRules ?
        <>
        <div className='bonusRules' dangerouslySetInnerHTML={createMarkup(bonusWageringInfo?.promoDetails?.info)}/>
        <div className='backArrow' onClick={() => setIsRules(false)}>
        <img
            src={arrowOpen} alt='Go Back'/>
            Go Back
        </div>
        </>
        : <div className="bonus-pop-up__info">
          <p className="bonus-pop-up__info-item">
            <span>Balance:</span>
            <span className="info-item-color">
              {/* {walletType === 'B'
                ? balance?.bWallet?.availBalance?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  }) || 0.0
                : walletType === 'C'
                ? balance?.cWallet?.availBalance?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  }) || 0.0
                : balance?.sWallet?.availBalance?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  }) || 0.0} */}
              {walletType === 'B'
                ? getCurrencyFormat(balance?.bWallet?.availBalance) || 0.0
                : walletType === 'C'
                ? getCurrencyFormat(balance?.cWallet?.availBalance) || 0.0
                : getCurrencyFormat(balance?.sWallet?.availBalance) || 0.0}
            </span>
          </p>
          <p className="bonus-pop-up__info-item">
            <span>Net Exposure:</span>
            <span>
            {/* {walletType === 'B'
                ? balance?.bWallet?.deductedExposure?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  }) || 0.0
                : walletType === 'C'
                ? balance?.cWallet?.deductedExposure?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })|| 0.0
                : balance?.sWallet?.deductedExposure?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })|| 0.0} */}
            {walletType === 'B'
                ? getCurrencyFormat(balance?.bWallet?.deductedExposure) || 0.0
                : walletType === 'C'
                ? getCurrencyFormat(balance?.cWallet?.deductedExposure) || 0.0
                : getCurrencyFormat(balance?.sWallet?.deductedExposure) || 0.0}
            </span>
          </p>
          <p className="bonus-pop-up__info-item newTogglerBonusPopUp">
            <span className="newTogglerBonusPopUp__oneClick">Use Bonus</span>
            <label className="switcher">
              <input
                type="checkbox"
                checked={bonusToggle}
                onClick={() => changeBonusToggle(!bonusToggle)}
              />
              <span className="slider round"></span>
            </label>
          </p>
          <div className="bonus-pop-up__info-list">
            <div className="bonus-pop-up__info-list-title">
              <span>Bonus Wallet</span>
              <img
                onClick={() => setOpenInfo((prevState) => !prevState)}
                src={arrowOpen}
                className={openInfo ? '' : 'close'}
              />
            </div>
            <ul
              className={`bonus-pop-up__info-list-block ${
                openInfo ? 'active' : ''
              }`}
            >
              <li>
                <span>Wagering Remaining</span>
                <span>
                  {/* {expSum().toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })} */}
                  {getCurrencyFormat(expSum())}
                </span>
              </li>
              <li>
                <span>Bonus Amount</span>
                <span>
                  {/* {bonusWageringInfo?.bonus?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })} */}
                  {getCurrencyFormat(bonusWageringInfo?.bonus)}
                </span>
              </li>
              <li>
                <span>Bonus Issued</span>
                <span className="info-item-color" style={{maxWidth: 'unset'}}>
                  {bonusWageringInfo?.issued?.replace('T', ' ').substring(0, 19)
                                              .replace(
                                                /^(\d{4}).(\d{2}).(\d{2})/,
                                                '$3-$2-$1')
                  }
                </span>
              </li>
              <li>
                <span>Bonus Expiry</span>
                <span className="info-item-color" style={{maxWidth: 'unset'}}>
                  {bonusWageringInfo?.expiry?.replace('T', ' ')
                    .replace(
                      /^(\d{4}).(\d{2}).(\d{2})/,
                      '$3-$2-$1')
                  }
                </span>
              </li>
              <div className="bonusTermsAndConds"  onClick={() => setIsRules(true)}>
                <span >
                  Terms and Conditions
                </span>
              </div>
            </ul>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default BonusPopUp;
