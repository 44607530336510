import { createSlice } from '@reduxjs/toolkit'

/*
 * Create actions and reducers
 */
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    settings: {
      presetStakeSettings: {},
      oneClickSettings: {},
    },
  },
  reducers: {
    setUserDataSuccess: (state, action) => ({ ...state, ...action.payload }),
    resetUserData: (state) => ({
      settings: {
        presetStakeSettings: {},
        oneClickSettings: {},
      },
    }),
    setNotificationSettings: (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        notifications: action.payload
      }
    }),
    setUserSettingsOneClickStakes: (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        oneClickSettings: action.payload
      }
    }),
    setUserSettingsMobileStakesExchangeGame: (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        presetStakeSettings: {
          ...state.settings.presetStakeSettings,
          mobileStakesExchangeGameSettings: action.payload
        }
      }
    }),
    setUserSettingsMobileStakes: (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        presetStakeSettings: {
          ...state.settings.presetStakeSettings,
          mobileStakesSettings: action.payload
        }
      }
    }),
    setUserSettingsStakes: (state, action) => ({
      ...state,
      settings: {
        ...state.settings,
        presetStakeSettings: {
          ...state.settings.presetStakeSettings,
          settings: action.payload
        }
      }
    }),
  },
})

export const {
  setUserDataSuccess,
  setUserSettingsOneClickStakes,
  setUserSettingsMobileStakesExchangeGame,
  setUserSettingsMobileStakes,
  setUserSettingsStakes,
  setNotificationSettings,
  resetUserData,
  getTestSuccess,
  getTestError,
} = userSlice.actions

export default userSlice.reducer
