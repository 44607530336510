import React, { useState, useEffect } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonHeader,
} from '@ionic/react'
import './TermsOfWithdrowalPayment.scss'
import Toolbar from '../../components/Toolbar/Toolbar'

import { connectTermsOfWithdrowalPayment } from './TermsOfWithdrowalPayment.connect'
import { useHistory } from 'react-router'
import { CONFIG } from '../../config/api.config'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'



const TermsOfWithdrowalPayment = ({ user, actions, elementsParams, ...props }) => {
 const history = useHistory()
  console.log("TermsOfWithdrowalPaymentPage")
  const [TermsOfWithdrowalPaymentAccount, setTermsOfWithdrowalPaymentAccount] = useState('');
  // console.log(localStorage.getItem('pageStack'));

  // const findPartOfRoute = (route, array) => {
  //   const res = array.filter(item => route.indexOf(item) !== -1);
  //   return !!res.length;
  // }

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  return (
    <IonPage>
      {width < 1024 && (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      )}
      <IonContent className="ChangePassword" >
        <div className="terms-withdrow__container">
          <div className="terms-withdrow__container-title">The terms of payment and withdrawal policy</div>
          <div className="terms-withdrow__container-p1">Limits The maximum deposit limit on your account will vary depending on how long you have been a customer with us and whether you have successfully passed our security checks. Please contact the <span className="terms-support">{CONFIG.email}</span> for further information.</div>
          <div className="terms-withdrow__container-p2">Security Please note you may be asked to supply proof of identity and address before making a withdrawal from your account. Credit and Debit card payments undergo a 3D Secure process. To find out more, please click here.</div>
          <div className="terms-withdrow__container-p3">Withdrawals Withdrawals will be directed to the same source. If your query is not covered by the information contained here, please email <span className="terms-support">{CONFIG.email}</span> where staff will be on hand to answer your query.</div>
          <div className="terms-withdrow__container-buttonback" onClick={() => history.goBack()} >Back</div>
        </div>
        {/*{width < 1024 &&*/}
        {/*  <FooterDesktop />*/}
        {/*}*/}
      </IonContent>
      {width > 1024 && <FooterDesktop/>}

    </IonPage>
  )
}

export default connectTermsOfWithdrowalPayment()(TermsOfWithdrowalPayment)
