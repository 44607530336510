import React from 'react'

const MulfisRules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          The objective of Muflis Teenpatti is to predict that which player will
          have the weaker combination of cards. It is the opposite of Teenpatti.
          It is played with regular 52 card deck
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <div>
          <h3>Main Bet:</h3>
          <div>
            <ul>
              <li>
                In regular Teenpatti C2, S3, H5 is the lowest game, but in this
                game it is the best game.
              </li>
              <li>
                In regular Teenpatti S12, S13, S1 is the highest game, but here
                it is the worst game.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3>Top 9:</h3>
          <p>Here 2 conditions apply:</p>
          <div>
            <h5>Condition 1:</h5>
            <ul>
              <li>
                Game must not have, Pair, Flush, Straight, Trio or Straight
                Flush.
              </li>
            </ul>
          </div>
          <p></p>
          <div>
            <h5>Condition 2:</h5>
            <ul>
              <li>
                . If your game has the highest card of 9, you will receive
                triple(x3) amount of your bet stake.
              </li>
              <li>
                . If your game has the highest card of 8, you will receive
                quadruple(x4) amount of your bet stake.
              </li>
              <li>
                . If your game has the highest card of 7, you will receive
                triple(x5) amount of your bet stake.
              </li>
              <li>
                . If your game has the highest card of 6, you will receive
                triple(x8) amount of your bet stake.
              </li>
              <li>
                . If your game has the highest card of 5, you will receive
                triple(x30) amount of your bet stake.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3>M (Muflis) Baccarat:</h3>
          <div>
            <ul>
              <li>
                Baccarat is where you take the last digit of the total of the 3
                cards of the game.
              </li>
              <li>Value of cards are:</li>
              <li>Ace = 1 point</li>
              <li>2 = 2 point</li>
              <li>3 = 3 point</li>
              <li>4 = 4 point</li>
              <li>5 = 5 point</li>
              <li>6 = 6 point</li>
              <li>7 = 7 point</li>
              <li>8 = 8 point</li>
              <li>9 = 9 point</li>
              <li>
                10 , jack , queen, king , all have zero points value( suit or
                color of the card doesn't matter in point value).
              </li>
            </ul>
          </div>
          <div>
            <p></p>
            <h5>Example 1:</h5>
            <ul>
              <li>if game is</li>
              <li>2 ,5 ,8</li>
              <li>2 + 5 + 8 = 15</li>
              <li>Here last digit is 5. So baccarat value is 5.</li>
            </ul>
          </div>
          <p></p>
          <div>
            <h5>Example 2:</h5>
            <ul>
              <li>if game is</li>
              <li>1, 4, K</li>
              <li>1 + 4 + 0 = 5</li>
              <li>
                If answer is in one digit , then that one digit is considered as
                baccarat value.
              </li>
              <li>
                M baccarat is comparison of baccarat value of both the game.
              </li>
              <li>But here lower value baccarat will win.</li>
              <li>
                If baccarat value is tie of both the game then, game having
                lowest card will win.
              </li>
              <li>ace is highest card & 2 is lowest card.</li>
              <li>
                If lowest card of both game is equal then color will be
                compared.
              </li>
              <li>Diamond color is lowest. Then club then heart then spade.</li>
            </ul>
          </div>
          <div>
            <p></p>
            <h5>Example:</h5>
            <ul>
              <li>
                if baccarat value is tie & lowest card of game A is H2 and
                lowest card of game B is D2 then game B will win.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3>Betting Instructions</h3>
          <div>
            <ul>
              <li>
                If a game round is in progress when you enter the game, please
                wait for the next one, and then, place your bets.
              </li>
              <li>After the timer reaches 0, the game round begins.</li>
              <li>
                To place a bet, click on the box, enter the stake amount and
                submit by clicking on Place Bet button.
              </li>
              <li>
                You can place several bets on different runners in different
                markets simultaneously.
              </li>
              <li>
                The timer in the game window shows how much time you have left
                to place your bets.
              </li>
              <li>
                Winnings are paid for the winning bets at the end of each game
                round.
              </li>
              <li>To skip a turn, simply do not place any bets.</li>
            </ul>
          </div>
        </div>
        <div>
          <h3>Main Bets</h3>
          <ol>
            <li>Player A</li>
            <li>Player B</li>
          </ol>
        </div>
        <div>
          <h3>Side Bets</h3>
          <ol>
            <li>Top 9 A</li>
            <li>Top 9 B</li>
            <li>M Baccarat A</li>
            <li>M Baccarat B</li>
          </ol>
        </div>
      </div>
    </div>
  )
}
export default MulfisRules
