import { createSlice } from '@reduxjs/toolkit'

export const isDetailedBalanceOpenSlice = createSlice({
  name: 'isDetailedBalanceOpen',
  initialState: false,
  reducers: {
    setIsDetailedBalanceOpen: (state, action) => action.payload,
  },
})

export const { setIsDetailedBalanceOpen } = isDetailedBalanceOpenSlice.actions

export default isDetailedBalanceOpenSlice.reducer
