import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './CasinoMeterCards.scss'
import { connectCasinoMeter } from '../../CasinoMeter.connect'
import * as cards from '../helpers'

const CasinoMeterCards = ({ exchangeGames, exposure }) => {
  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const openBets = useSelector((state) => (state.openBets))
  const myBets = openBets?.matched[0]
  const [selected, setSelected] = useState("")
  const [oldBets, setOldBets] = useState(null)
  const selectedUpdate = oldBets && oldBets.length  > 0 ?  oldBets.marketId.split('.')[1] : ''
  const placedBet = oldBets?.name === "Casino Meter" && oldBets?.markets[0]?.selections[0][0].selectionName



 useEffect(() => {
  if (myBets) {
    setOldBets(myBets)
  }
  if (!myBets && oldBets) {
    setTimeout(() => {
      setOldBets(myBets)
    }, 3500)
  }

}, [myBets]);

  const getCardNumber = (card) => {
    return card.substring(1)
  }
  const getSumOfCards = (cards) => {
    let sum = 0;
    for (const card of cards) {
      sum += parseInt(getCardNumber(card));
    }
    return sum;
  }

  const getSumCardsLowHigh = (cards, placed) => {
    let sum = getSumOfCards(cards);
    if (placedBet === placed) {
      exchangeGames[0].indexCard.includes("S10") && (sum += 10);
      exchangeGames[0].indexCard.includes("S9") && (sum += 9);
    } else if (!placedBet) {
      exchangeGames[0].indexCard.includes("S10") && placed === 'Low (A-9 without ♠9)' && (sum += 10);
      exchangeGames[0].indexCard.includes("S9") && placed === 'High (10-K without ♠10)' && (sum += 9);
    }

    return sum;
  }

  const getRunPosition = (market) => {

    let lowSum = 0;
    let highSum = 0;
    let lowPosition = 0;
    let highPosition = 0;

    for (const card of market.marketRunner[0].cards) {
      if (card !== 'S9') {
        lowSum += (parseInt(getCardNumber(card)));
      }
    }

    for (const card of market.marketRunner[1].cards) {
      if (card !== 'S10') {
        highSum += (parseInt(getCardNumber(card)));
      }
    }

    if (placedBet === "High (10-K without ♠10)") {
      exchangeGames[0].indexCard.includes("S10") && (lowSum += 10);
      exchangeGames[0].indexCard.includes("S9") && (lowSum += 9);
    }
    if (placedBet === "Low (A-9 without ♠9)") {
      exchangeGames[0].indexCard.includes("S10") && (highSum += 10);
      exchangeGames[0].indexCard.includes("S9") && (highSum += 9);
    }

    lowPosition = lowSum;
    highPosition = highSum;


    // if (market.indexCard.includes('S9')) {
    // highPosition += 9;
    // lowPosition += 9;
    // }
    //
    // if (market.indexCard.includes('S10')) {
    // lowPosition += 10;
    // highPosition += 10;
    // }

    let lowRunPosition = lowSum - highPosition;
    if (lowRunPosition < -50) {
      lowRunPosition = -50;
    }
    if (lowRunPosition > 50) {
      lowRunPosition = 50;
    }

    let highRunPosition = highSum - lowPosition;
    if (highRunPosition < -50) {
      highRunPosition = -50;
    }
    if (highRunPosition > 50) {
      highRunPosition = 50;
    }

    const res = { lowRunPosition, highRunPosition };
    return res
  }

  useEffect(() => {
    if (placedBet !== selected) {
      setSelected(placedBet)
    }
    if (selectedUpdate !== (exchangeGames && exchangeGames[0] && exchangeGames[0].roundId ? exchangeGames[0].roundId : "")) {
      setSelected("")
    }
    if (oldBets && oldBets?.markets[0]?.selections[0][0].selectionName) {
      setSelected( oldBets?.markets[0]?.selections[0][0].selectionName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUpdate, placedBet, oldBets])

  return (
    <div className="CasinoMeterCardsContent">
      <div className="CasinoMeterCardsContent__players-wrapper">
        {selected === "Low (A-9 without ♠9)" && (
          <div className='CasinoMeterCardsContent__player-position'>
            My position :  <span className="CasinoMeterCardsContent__player-position-score">{exchangeGames && exchangeGames[0] && getRunPosition(exchangeGames[0]).lowRunPosition} </span>
          </div>
        )}
        {selected === "High (10-K without ♠10)" && (
          <div className='CasinoMeterCardsContent__player-position'>
            My position :  <span className="CasinoMeterCardsContent__player-position-score"> {exchangeGames && exchangeGames[0] && getRunPosition(exchangeGames[0]).highRunPosition} </span>
          </div>
        )}
        <div className="CasinoMeterCardsContent__player">
            <span className="CasinoMeterCardsContent__player-name">
              Low :
              <span className="info__column-score">
                {exchangeGames && exchangeGames[0].marketRunner && (
                  // getSumCardsLowHigh(exchangeGames[0].marketRunner[0].cards, 'Low (A-9 without ♠9)')
                  getSumOfCards(exchangeGames[0].marketRunner[0].cards)
                )}
            </span>
          </span>
          <div className="CasinoMeterCardsContent__player-cards">
          {exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards.map(item => {
            return (
              <img
              className="CasinoMeterCardsContent__player-cards-card"
              src={cardSrc(item)} alt="" />
            )
          })}
          {exchangeGames && exchangeGames[0] && exchangeGames[0].indexCard.includes("S9") && (placedBet === "High (10-K without ♠10)" || !placedBet) && (
            <img src={cards["OS9"]} alt="card S9" className="CasinoMeterCardsContent__player-cards-card joker"/>
            )}
          {(exchangeGames && exchangeGames[0] && exchangeGames[0].indexCard.includes("S10") && (placedBet === "High (10-K without ♠10)"))
            // || (selected === "High (10-K without ♠10)" && exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards?.length)
            && (
            <img src={cards["OS10"]} alt="card S10" className="CasinoMeterCardsContent__player-cards-card joker"/>
          )}
          </div>
        </div>

        <div className="CasinoMeterCardsContent__player CasinoMeterCardsContent__player-second">
        <span className="CasinoMeterCardsContent__player-name">
            High :
            <span className="info__column-score">
              {exchangeGames && exchangeGames[0].marketRunner && (
                // getSumCardsLowHigh(exchangeGames[0].marketRunner[1].cards, 'High (10-K without ♠10)')
                getSumOfCards(exchangeGames[0].marketRunner[1].cards)
              )}
            </span>
          </span>
          <div className="CasinoMeterCardsContent__player-cards">
            {exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards.map(item => {
              return (
                <img
                  className="CasinoMeterCardsContent__player-cards-card"
                  src={cardSrc(item)} alt="" />
              )
            })}
          {(exchangeGames && exchangeGames[0] && exchangeGames[0].indexCard.includes("S9") && (placedBet === "Low (A-9 without ♠9)" ))
            // || (selected === "Low (A-9 without ♠9)" && exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards?.length)
            && (
            <img src={cards["OS9"]} alt="card S9" className="CasinoMeterCardsContent__player-cards-card joker"/>
          )}
          {exchangeGames && exchangeGames[0] && exchangeGames[0].indexCard.includes("S10") && (placedBet === "Low (A-9 without ♠9)"|| !placedBet) && (
            <img src={cards["OS10"]} alt="card S10" className="CasinoMeterCardsContent__player-cards-card joker"/>
          )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default connectCasinoMeter()(CasinoMeterCards)
