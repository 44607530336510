import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { BASE_DEV_URL } from "../../config/api.config";
import { ID_TOKEN } from "../../config/app.config";
import axios from "axios";
import Card from "./Card";
import { useIonViewDidEnter, useIonViewWillEnter } from "@ionic/react";
import "./index.css";
import { getWidth } from "react-slick/lib/utils/innerSliderUtils";
import ReactGA from "react-ga4";

function BonusBanner() {
  const history = useHistory();

  const [activeBonus, setActiveBonus] = useState(null);

  const gotoEvent = (e) => {
    e.preventDefault();
    console.log("clicked");

    history.push("/bonuses");
  };

  const getBonuses = () => {
    const token = localStorage.getItem(`${ID_TOKEN}`);
    if(token != null){
      axios.get(`${BASE_DEV_URL}/account/bonuses?bonusType=alive`, {
        headers: {
          Authorization: token
        }
      })
        .then(function(response) {
          console.log(response);
          if (response.data.success) {
            if (response.data.result.length !== 0) {
              const temp = response.data.result.filter(
                (bonus) => bonus.STS_ID === 20
              );
              temp.length !== 0
                ? setActiveBonus(temp[0])
                : setActiveBonus(response.data.result[0]);
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      setTimeout(() => {
        getBonuses();
      }, 3000);
    }

  };

  // useEffect(() => {
  //   if (width >=1024;
  // )
  //   {
  //     token = localStorage.getItem("id_token");
  //     paymentGateways();
  //   }
  // }, []);

  useEffect(() => {
    getBonuses();
  },[]);

  return (
    activeBonus && (
      <>
        <div
          onClick={(e) => {
            ReactGA.event({
              label: "HP_Bonus_Button",
              category: "User-Click",
              action: "Your_Bonuses"
            });
            gotoEvent(e)
          }}
          className="you-bonus-wrrap"
        >
          <div className="shimmer-animation"></div>
          <span className="you-bonus-text">Your Bonuses</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="you-bonus-arrow"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
        <div className="active-bonus">
          <Card activeBonusAvailable={false} x={activeBonus} />
        </div>
        {/* <div>{JSON.stringify(activeBonus)}</div> */}
      </>
    )
  )
}

export default BonusBanner
