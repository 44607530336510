import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router"
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { connectTransferStatementPage } from "./TransferStatementPage.connect";
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import Toolbar from "../../components/Toolbar/Toolbar";
import './TransferStatementPage.scss'
import { timeToDate } from "../../helpers/date.helper";
import {
    GET_STATS_INTERVAL,
} from '../../config/app.config';
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'

const TransferStatementPage = ({ transferStatement, user, elementsParams, actions }) => {
  const history = useHistory()
  useEffect(() => {
    if (history.location.pathname.indexOf(`/transfer_statement`) !== -1) actions.requestTransferStatementSuccess(user.memberCode)
    const mainTimer = setIntervalAsync(async () => {
      if (history.location.pathname.indexOf(`/transfer_statement`) !== -1) await actions.requestTransferStatementSuccess(user.memberCode)
    }, GET_STATS_INTERVAL);
    return () => clearIntervalAsync(mainTimer);
  }, [history.location.pathname]);

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const getAmountClass = (amount) => {
    return `transfer-statement-page__content-row-amount ${
      amount <= 0 ? 'green' : 'red'
    }`
  }

  const getDescription = (descriptionValue) => {
      return descriptionValue === 'TRANSFER_IN' ? 'Withdraw' : 'Deposit'
      // if (B2C){
      //
      // } else {
      //   return  descriptionValue === 'TRANSFER_IN' ? 'Transfer In' : 'Transfer Out'
      // }
    }

  const displayTransferStatement = () => {
    return Object.entries(transferStatement).map(([key, value], index) => {
      return (
        <div className="transfer-statement-page__list-row" key={index}>
          <div className="transfer-statement-page__content-row-header">
            {timeToDate(Number(key)).dateString}
          </div>
          <div className="transfer-statement-page__content-group">
            {value.map((v) => {
              return (
                <div className="transfer-statement-page__row" key={v.date}>
                                        <span className="transfer-statement-page__content-row-date">{timeToDate(v.date).hours}</span>
                                        <span className="transfer-statement-page__content-row-description">{getDescription(v.description)}</span>
                                        <span className={getAmountClass(v.amount)}>{Math.abs(v.amount.toFixed(2))}</span>
                </div>
              )
            })}
          </div>
        </div>
      )
    })
  }

  return (
    <>
      {width < 1024 ? (
        <IonPage>
          <IonHeader>
            <Toolbar />
          </IonHeader>

          <IonContent className="transfer-statement-page" fullscreen>
            <div className="page-title-wrapper">
              <h2 className="page-title">Transfer Statement</h2>
            </div>

            {!transferStatement ? (
              <div className="transfer-statement-page__content">
                <div className="transfer-statement-page__content--empty">
                  There have been no transfers in the last 14 days
                </div>
              </div>
            ) : (
              <div className="transfer-statement-page__content">
                <div className="transfer-statement-page__content-header">
                  <span className="transfer-statement-page__content-row-date">
                    Date
                  </span>
                  <span className="transfer-statement-page__content-row-description">
                    Description
                  </span>
                  <span className="transfer-statement-page__content-row-amount">
                    Amount
                  </span>
                </div>
                {displayTransferStatement()}
              </div>
            )}
          </IonContent>
              {/*<FooterDesktop />*/}
        </IonPage>
      ) : (
        <div className="transfer-statement-page">
          <div className="page-title-wrapper">
            <h2 className="page-title">Transfer Statement</h2>
          </div>

          {!transferStatement ? (
            <div className="transfer-statement-page__content">
              <div className="transfer-statement-page__content--empty">
                There have been no transfers in the last 14 days
              </div>
            </div>
          ) : (
            <div className="transfer-statement-page__content">
              <div className="transfer-statement-page__content-header">
                <span className="transfer-statement-page__content-row-date">
                  Date
                </span>
                <span className="transfer-statement-page__content-row-description">
                  Description
                </span>
                <span className="transfer-statement-page__content-row-amount">
                  Amount
                </span>
              </div>
              {displayTransferStatement()}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default connectTransferStatementPage()(TransferStatementPage)
