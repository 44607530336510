import React, { useEffect } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./styles/index.scss"
import { connectPoker20 } from './Poker20.connect'
import CardsInfo from '../helpers/CardsInfo/CardsInfo'
import LastResults from '../helpers/LastResults/LastResults'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
// import BetPlacing from '../../BetPlacing/BetPlacing'

const Poker20 = ({exchangeGames, sportEvent, betPlacing, actions, exposure, headerHeight, auth, elementsParams}) => {
  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  // sport event data
  const dataOdd = sportEvent.sportData
  // end sport event data

  // request Exchange Game data

  useEffect(() => {
    const timer = setIntervalAsync(async() => {
      await actions
              .requestExchangeGames('67567')
              .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)
  }, [])
  // End request Exchange Game data

  // Show betslip
  // const renderBetPlacing = () => {
  //   // if (betPlacing.runner && dataOdd[0].isBettable && width < 1024) {
  //   if (betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }
  // End Show betslip

  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing

  //get market price
  const getMarketPrice = (marketIndex, runnersIndex) => {
      return (
        dataOdd &&
        dataOdd[marketIndex] &&
        dataOdd[marketIndex].runners[runnersIndex] &&
        dataOdd[marketIndex].runners[runnersIndex].back[0] &&
        dataOdd[marketIndex].runners[runnersIndex].back[0].price
      )
  }
  //end get market price

  // get exposure
  const getExposure = (marketIndex, runnerIndex) => {
    if(auth) {
      let id = dataOdd[marketIndex].runners[runnerIndex].id
      let objFinder =  exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl

      return (
        <div
          className={
            objFinder > 0
              ? 'exposure-box-modern exposure-modern--pos'
              : 'exposure-box-modern exposure-modern--neg'
          }
        >
          {objFinder}
        </div>
      )
    } else return
  }
  // end get exposure

  // lock market
  const resultStatus = (marketIndex) => {
    return !dataOdd[marketIndex].isBettable && 'lock-ui'
  }
  // end lock market

  // get winner
  const getWinner = (marketIndex, runnerIndex) => {
    return exchangeGames &&
      exchangeGames[marketIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex]?.status
  }
  // get winner


  return (
    <>
      <CardsInfo/>
      {/*{*/}
      {/*  width < 1024*/}
      {/*    ?*/}
      {/*    <div className="gc-game-title">*/}
      {/*      <div className="game-title">POKER 20-20</div>*/}
      {/*      <div className="game-round-id">*/}
      {/*        #{exchangeGames && exchangeGames[0]?.roundId}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    :*/}
      {/*    ""*/}
      {/*}*/}
      <div className="poker-20-table" >
        {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
        <div className="poker-20-table__body">
          {
            (width < 1024) &&
            <div className="poker-20-table__body-column poker-20-table__body-column__title">
              <div className="poker-20-table__body-column__title-name">Winner</div>
              <div className="poker-20-table__body-column__title-name">One Pair</div>
              <div className="poker-20-table__body-column__title-name">Two Pair</div>
              <div className="poker-20-table__body-column__title-name">Three of a kind</div>
              <div className="poker-20-table__body-column__title-name">Straight</div>
              <div className="poker-20-table__body-column__title-name">Flush</div>
              <div className="poker-20-table__body-column__title-name">Full House</div>
              <div className="poker-20-table__body-column__title-name">Four of a kind</div>
              <div className="poker-20-table__body-column__title-name">Straight Flush</div>
            </div>
          }

          <div className="poker-20-table__body-column">
            <div className="poker-20-table__body-column-header">
              Player&nbsp;A
            </div>
            <div className={`poker-20-table__body-column-td ${resultStatus(0)} ${getWinner(0,0)}`} onClick={() => {oddPush(0,0)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Winner
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(0,0)}</span>
                {getExposure(0,0)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(1)} ${getWinner(1,1)}`} onClick={() => {oddPush(1,1)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                One Pair
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(1,1)}</span>
                {getExposure(1,1)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(1)} ${getWinner(1,2)}`} onClick={() => {oddPush(1,2)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Two Pair
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(1,2)}</span>
                {getExposure(1,2)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(1)} ${getWinner(1,3)}`} onClick={() => {oddPush(1,3)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Three of a kind
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(1,3)}</span>
                {getExposure(1,3)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(1)} ${getWinner(1,4)}`} onClick={() => {oddPush(1,4)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Straight
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(1,4)}</span>
                {getExposure(1,4)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(1)} ${getWinner(1,5)}`} onClick={() => {oddPush(1,5)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Flush
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(1,5)}</span>
                {getExposure(1,5)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(1)} ${getWinner(1,6)}`} onClick={() => {oddPush(1,6)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Full House
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(1,6)}</span>
                {getExposure(1,6)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(1)} ${getWinner(1,7)}`} onClick={() => {oddPush(1,7)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Four of a Kind
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(1,7)}</span>
                {getExposure(1,7)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(1)} ${getWinner(1,8)}`} onClick={() => {oddPush(1,8)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Straight Flush
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(1,8)}</span>
                {getExposure(1,8)}
              </div>
            </div>
          </div>
          <div className="poker-20-table__body-column">
            <div className="poker-20-table__body-column-header">
              Player&nbsp;B
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(0)} ${getWinner(0,1)}`} onClick={() => {oddPush(0,1)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Winner
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(0,1)}</span>
                {getExposure(0,1)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(2)} ${getWinner(2,1)}`} onClick={() => {oddPush(2,1)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                One Pair
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(2,1)}</span>
                {getExposure(2,1)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(2)} ${getWinner(2,2)}`} onClick={() => {oddPush(2,2)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Two Pair
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(2,2)}</span>
                {getExposure(2,2)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(2)} ${getWinner(2,3)}`} onClick={() => {oddPush(2,3)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Three of a kind
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(2,3)}</span>
                {getExposure(2,3)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(2)} ${getWinner(2,4)}`} onClick={() => {oddPush(2,4)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Straight
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(2,4)}</span>
                {getExposure(2,4)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(2)} ${getWinner(2,5)}`} onClick={() => {oddPush(2,5)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Flush
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(2,5)}</span>
                {getExposure(2,5)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(2)} ${getWinner(2,6)}`} onClick={() => {oddPush(2,6)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Full House
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(2,6)}</span>
                {getExposure(2,6)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(2)} ${getWinner(2,7)}`} onClick={() => {oddPush(2,7)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Four of a Kind
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(2,7)}</span>
                {getExposure(2,7)}
              </div>
            </div>
            <div className={`poker-20-table__body-column-td  ${resultStatus(2)} ${getWinner(2,8)}`} onClick={() => {oddPush(2,8)}}>
              <div className={`poker-20-table__body-column-td-title`}>
                Straight Flush
              </div>
              <div className={`poker-20-table__body-column-td-odds`}>
                <span>{getMarketPrice(2,8)}</span>
                {getExposure(2,8)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LastResults/>
    </>
  )
}

export default connectPoker20()(Poker20)
