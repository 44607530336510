import React, { useEffect } from 'react';
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./MuflisTeenpatti.scss"
import { connectMuflisTeenpatti } from './MuflisTeenpatti.connect'
// import BetPlacing from '../../BetPlacing/BetPlacing';
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
import LastResults from '../helpers/LastResults/LastResults';
import CardsInfo from '../helpers/CardsInfo/CardsInfo';

const MuflisTeenpatti = ({
  sportEvent,
  betPlacing,
  actions,
  exchangeGames,
  exposure,
  headerHeight,
  auth, 
  elementsParams
 }) => {
  const dataOdd = sportEvent.sportData;

  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  //get price
  const getMarketPrice = (marketIndex, runnersIndex) => {
    if (dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0].price) {

      return dataOdd[marketIndex].runners[runnersIndex].back[0].price
    }
  }
  //end get price

  // get exposure
  const getExposure = (marketIndex, runnerIndex) => {
    if(auth) {
      const id = sportEvent.sportData[marketIndex] &&
      sportEvent.sportData[marketIndex].runners[runnerIndex] &&
      sportEvent.sportData[marketIndex].runners[runnerIndex].id
      let objFinder =  exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl;
      if ( sportEvent.sportData[marketIndex]?.name == "Top 9" && !objFinder) {
        objFinder =  exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes('-0'))]?.pnl;
      }
      // const objFinder =  200;
      return (
        <div
          className={
            objFinder > 0
              ? 'exposure-box-modern exposure-modern--pos'
              : 'exposure-box-modern exposure-modern--neg'
          }
        >
          {objFinder}
        </div>
      )
    } else return
  }
  // end get exposure
  // Show betslip
  // const renderBetPlacing = () => {
  //   if (betPlacing?.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }
  // End Show betslip
  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id };
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }
  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    console.log("oddPush")
    return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing
  // lock market
  const resultStatus = (marketIndex) => {
    return !dataOdd[marketIndex].isBettable && 'lock-ui'
  }
  // end lock market
  
  useEffect(() => {
      const timer = setIntervalAsync(async() => {
        await actions
                  .requestExchangeGames('67600')
                  .catch((e) => console.log('requestInplayPopular error = ', e))
      }, GET_INPLAY_POPULAR_INTERVAL)
      return () => clearIntervalAsync(timer)

  }, [])

  return (
    <>
      <CardsInfo />
        <div className="muflis-teenpatti">
          <div className="teenpatti-muflis-table">
            <div className="teenpatti-muflis-table__body">
                {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
                <div className="teenpatti-muflis-table__body-players">
                  <div className="teenpatti-muflis-table__body-players-header">
                    <span>Player A</span>
                  </div>
                  <div className="teenpatti-muflis-table__body-player-row">
                    <div className={`teenpatti-muflis-table__body-player-wrapper ${resultStatus(0)}`} onClick={() => {oddPush(0,0)}}>
                      <div className="teenpatti-muflis-table__player-title">
                        <span className="teenpatti-muflis-table__body-player-info-text">Winner</span>
                      </div>
                      <div className="teenpatti-muflis-table__player-price">
                        <span className="teenpatti-muflis-table__body-player-info-text bold">
                          {getMarketPrice(0,0)}
                        </span>
                        {getExposure(0,0)}
                      </div>
                    </div>
                    <div className={`teenpatti-muflis-table__body-player-wrapper ${resultStatus(1)}`} onClick={() => {oddPush(1,0)}}>
                      <div className="teenpatti-muflis-table__player-title">
                        <span className="teenpatti-muflis-table__body-player-info-text">Top 9</span>
                      </div>
                      <div className="teenpatti-muflis-table__player-price">
                        <span className="teenpatti-muflis-table__body-player-info-text bold">
                         {getMarketPrice(1,0)}
                        </span>
                        {getExposure(1,0)}
                      </div>
                     </div>
                    <div className={`teenpatti-muflis-table__body-player-wrapper ${resultStatus(3)}`} onClick={() => {oddPush(3,0)}}>
                      <div className="teenpatti-muflis-table__player-title">
                        <span className="teenpatti-muflis-table__body-player-info-text">M Baccarat A</span>
                      </div>
                    <div className="teenpatti-muflis-table__player-price">
                      <span className="teenpatti-muflis-table__body-player-info-text bold">
                        {getMarketPrice(3,0)}
                      </span>
                      {getExposure(3,0)}
                  </div>
                 </div>
                  </div>
                </div>
                <div class="teenpatti-muflis-table__body-players">
                  <div class="teenpatti-muflis-table__body-players-header">
                    <span class="teenpatti-muflis-table__body-players-header-name">Player B</span>
                  </div>
                  <div class="teenpatti-muflis-table__body-player-row">
                    <div className={`teenpatti-muflis-table__body-player-wrapper ${resultStatus(0)}`} onClick={() => {oddPush(0,1)}}>
                      <div className="teenpatti-muflis-table__player-title">
                        <span className="teenpatti-muflis-table__body-player-info-text">Winner</span>
                      </div>
                      <div className="teenpatti-muflis-table__player-price">
                        <span className="teenpatti-muflis-table__body-player-info-text bold">
                          {getMarketPrice(0,1)}
                        </span>
                        {getExposure(0,1)}
                      </div>
                    </div>
                    <div className={`teenpatti-muflis-table__body-player-wrapper ${resultStatus(2)}`} onClick={() => {oddPush(2,0)}}>
                      <div className="teenpatti-muflis-table__player-title">
                        <span className="teenpatti-muflis-table__body-player-info-text">Top 9</span>
                      </div>
                      <div className="teenpatti-muflis-table__player-price">
                        <span className="teenpatti-muflis-table__body-player-info-text bold">
                         {getMarketPrice(2,0)}
                        </span>
                        {getExposure(2,0)}
                      </div>
                    </div>
                    <div className={`teenpatti-muflis-table__body-player-wrapper ${resultStatus(3)}`} onClick={() => {oddPush(3,1)}}>
                      <div className="teenpatti-muflis-table__player-title">
                        <span className="teenpatti-muflis-table__body-player-info-text">M Baccarat B</span>
                      </div>
                      <div className="teenpatti-muflis-table__player-price">
                      <span className="teenpatti-muflis-table__body-player-info-text bold">
                        {getMarketPrice(3,1)}
                      </span>
                        {getExposure(3,1)}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <LastResults/>
        </div>
      </>
    )
}

export default connectMuflisTeenpatti()(MuflisTeenpatti)
