import './MostPopularBanner.scss'
import React from 'react'
import { connectPromotions } from '../Promotion/Promotion.connect';
import MostPopularSlider from './MostPopularSlider/MostPopularSlider';

const MostPopularBanners = ({promotions}) => {
  return (
    <>
    { promotions?.popularSlidingBanners && promotions.popularSlidingBanners.length >= 1 ? <div className='mostPopular-baner'>
      <div className='mostPopular-baner__wrapper'>
      {<MostPopularSlider banners={promotions.popularSlidingBanners} />}
      </div>
    </div> : null}
    </>
  )
}

export default connectPromotions()(MostPopularBanners)
