import React, { useEffect, useState } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./styles/TeenpattiT20.scss"
import CardsInfo from '../helpers/CardsInfo/CardsInfo'
import LastResults from '../helpers/LastResults/LastResults'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
// import BetPlacing from '../../BetPlacing/BetPlacing'
import { connectTeenpattiT20 } from './TeenpattiT20.connect'

const TeenpattiT20 = ({exchangeGames, sportEvent, betPlacing, actions, exposure, eventId, lastResultsExch, activeTab, headerHeight, auth, elementsParams}) => {

  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  // sport event data
  const dataOdd = sportEvent.sportData
  // end sport event data

  // request Exchange Game data

  useEffect(() => {
    const timer = setIntervalAsync(async() => {
      await actions
                .requestExchangeGames(eventId)
                .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)
  }, [])
  // End request Exchange Game data

  // Show betslip
  // const renderBetPlacing = () => {
  //   if (betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }
  // End Show betslip

  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing

  //get market price
  const getMarketPrice = (marketIndex, runnersIndex) => {
    return (
      dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0].price
    )
  }
  //end get market price

  // get exposure
  const getExposure = (marketIndex, runnerIndex) => {
    if(auth) {
      let id = dataOdd[marketIndex].runners[runnerIndex].id
      let objFinder =  exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl

      return (
        <div
          className={
            objFinder > 0
              ? 'exposure-box-modern exposure-modern--pos'
              : 'exposure-box-modern exposure-modern--neg'
          }
        >
          {objFinder?.toFixed(2)}
        </div>
      )
    } else return
  }
  // end get exposure

  // lock market
  const marketStatus = (marketIndex) => {
    return (!dataOdd[marketIndex].isBettable && 'lock-ui')
  }
  // end lock market

  // get winner
  const getWinner = (marketIndex, runnerIndex) => {
    return exchangeGames &&
      exchangeGames[marketIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex]?.status
  }
  // get winner


  return (
    <>
      <CardsInfo/>
      <div className={`teenpatti-T20-table ${eventId === '56769' ? 'virtual' : ''} ${activeTab === 0 ? "fullHeight" : ""}`}>
        {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
        <div class="teenpatti-T20-table__row teenpatti-T20-table__row-first">
          <div class="teenpatti-T20-table__row-header">
            <span>Match Odds</span>
          </div>
          <div class="teenpatti-T20-table__row-body">
            <div class={`teenpatti-T20-table__player ${marketStatus(0)} ${getWinner(0,0)} `} onClick={() => {oddPush(0,0)}}>
              <div class="teenpatti-T20-table__player-item">
                <span class="teenpatti-T20-table__player-name">Player A</span>
                {getExposure(0,0)}
              </div>
              <div class="teenpatti-T20-table__player-price">
                <span>{getMarketPrice(0,0)}</span>
              </div>
            </div>
            <div className={`teenpatti-T20-table__player ${marketStatus(0)} ${getWinner(0,1)}`} onClick={() => {oddPush(0, 1)}}>
              <div class="teenpatti-T20-table__player-item">
                <span class="teenpatti-T20-table__player-name">Player B</span>
                {getExposure(0,1)}
              </div>
              <div class="teenpatti-T20-table__player-price">
                <span>{getMarketPrice(0,1)}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="teenpatti-T20-table__row">
          <div class="teenpatti-T20-table__row-header">
            <span>Pair Plus</span>
          </div>
          <div class="teenpatti-T20-table__row-body">
            <div className={`teenpatti-T20-table__player ${marketStatus(1)} ${getWinner(1,0)}`} onClick={() => {oddPush(1, 0)}}>
              <div class="teenpatti-T20-table__player-item">
                <span class="teenpatti-T20-table__player-name">Player A+</span>
                {getExposure(1,0)}
              </div>
              <div class="teenpatti-T20-table__player-price">
                <span>{getMarketPrice(1,0)}</span>
              </div>
            </div>
            <div className={`teenpatti-T20-table__player ${marketStatus(2)} ${getWinner(1,1)}`} onClick={() => {oddPush(2, 0)}}>
              <div class="teenpatti-T20-table__player-item">
                <span class="teenpatti-T20-table__player-name">Player B+</span>
                {getExposure(2,0)}
              </div>
              <div class="teenpatti-T20-table__player-price">
                <span>{getMarketPrice(2,0)}</span>
              </div>
            </div>
          </div>
        </div>
        <LastResults/>
      </div>
    </>
  )
}

export default connectTeenpattiT20()(TeenpattiT20)
