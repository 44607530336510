import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {getBonusStatementError, getBonusStatementSuccess} from './bonusStatement.slice'

export const requestBonusStatementSuccess = (memberCode, dateFrom, dateTo, walletCode) => async (dispatch) => {
    const currentTime = (new Date()).getTime();
    const lastEightDayTime = currentTime - (7 * 24 * 60 * 60 * 1000);
// console.log('lastEightDayTime', lastEightDayTime)
// console.log('currentTime', currentTime)
    try {
        const response = await httpAuth.get(`/account/${memberCode}/reports/bonus-statement?from=${dateFrom ? dateFrom : lastEightDayTime}&to=${dateTo || currentTime}&walletCode=${walletCode}`)
        if (response.data.success) {
            dispatch(getBonusStatementSuccess(response.data.result))
        }
        return response
    } catch (e) {
        dispatch(getBonusStatementError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}
