import { createSlice } from '@reduxjs/toolkit'

export const liveCasinoSlice = createSlice({
    name: 'liveCasino',
    initialState: null,
    reducers: {
        getLiveCasinoSuccess: (state, action) => action.payload,
        getLiveCasinoError: (state) => state,

        getLiveCasinoIDSuccess: (state, action) => ({...state, liveCasinoID: action.payload}),
        getLiveCasinoIDError: (state) => state,

        allGames: (state, action) => ({...state, allGames: action.payload}),
        topGames: (state, action) => ({...state, topGames: action.payload}),
        liveGames: (state, action) => ({...state, liveGames: action.payload}),
        tableGames: (state, action) => ({...state, tableGames: action.payload}),
        pokerGames: (state, action) => ({...state, pokerGames: action.payload}),
        blackjackGames: (state, action) => ({...state, blackjackGames: action.payload}),
        baccaratGames: (state, action) => ({...state, baccaratGames: action.payload}),
        rouletteGames: (state, action) => ({...state, rouletteGames: action.payload}),
        showsGames: (state, action) => ({...state, showsGames: action.payload}),
        virtualGames: (state, action) => ({...state, virtualGames: action.payload}),
    },
})

export const {
    getLiveCasinoSuccess,
    getLiveCasinoError,
    getLiveCasinoIDSuccess,
    getLiveCasinoIDError,
    allGames,
    topGames,
    liveGames,
    tableGames,
    pokerGames,
    blackjackGames,
    baccaratGames,
    rouletteGames,
    showsGames,
    virtualGames,
} = liveCasinoSlice.actions

export default liveCasinoSlice.reducer
