import "./AmarAkbarAnthonyPL.scss";
import React, { useEffect, useState } from 'react'

const AmarAkbarAnthonyPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {
     winners: ""
   };
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="amar-akbar-anthony-betting">
        <div className="amar-akbar-anthony-betting__games-item">
          <div className="amar-akbar-anthony-betting__games-item-cards">
            {roundMarkets && roundMarkets[0].indexCard.map( card =>
              <img
                   key={card}
                   src={cardSrc(card)}
                   alt={card}
              />
            )}
          </div>
        </div>
        {winnerData && <div className="amar-akbar-anthony-betting__info-column">
          <div className="amar-akbar-anthony-betting__info-row">
            <div className="amar-akbar-anthony-betting__info-row-title">
              <span>Winner:</span>
            </div>
            <div className="amar-akbar-anthony-betting__info-row-results">
              <span>{winnerData['Match Odds']  || 'No Winner'}</span>
            </div>
          </div>
          <div className="amar-akbar-anthony-betting__info-row">
            <div className="amar-akbar-anthony-betting__info-row-title">
              <span>Odd/Even: </span>
            </div>
            <div className="amar-akbar-anthony-betting__info-row-results">
            <span>
              {winnerData['Odd Even']  || 'No Winner'}
            </span>
            </div>
          </div>
          <div className="amar-akbar-anthony-betting__info-row">
            <div className="amar-akbar-anthony-betting__info-row-title">
              <span>Color:</span>
            </div>
            <div className="amar-akbar-anthony-betting__info-row-results">
              <span>{winnerData['Color'] || 'No Winner'}</span>
            </div>
          </div>
          <div className="amar-akbar-anthony-betting__info-row">
            <div className="amar-akbar-anthony-betting__info-row-title">
              <span>Under/Over: </span>
            </div>
            <div className="amar-akbar-anthony-betting__info-row-results">
              <span>{winnerData['Over Under 7'] || "No Winner"}</span>
            </div>
          </div>
        </div>}
      </div>
);
};

export default AmarAkbarAnthonyPL;

