import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  getBalanceSettingsError,
  getBalanceSettingsSuccess,
} from './balanceSettings.slice'

export const requestBalanceSettings = (memberCode) => async (dispatch) => {
  try {
    const response = await httpAuth.get(`/account/${memberCode}/balance-settings`)
    if (response.data.success) {
      dispatch(getBalanceSettingsSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getBalanceSettingsError())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
