import './promotion.scss'
import React from 'react'
import { IonItemGroup } from '@ionic/react';
import TopBaner from '../TopBaner/TopBaner';
import QuickButtons from './QuickButtons/QuickButtons';
import { connectPromotions } from './Promotion.connect';
import { useEffect } from 'react';
import SportsButtons from './SportButtons/SportsButtons'

const Promotion = ({ inplayPopular, promotions, actions, auth }) => {

  useEffect(() => {
      actions.requestPromotions()
  }, [])

  return (
    <>
        {promotions ? <IonItemGroup className="promotion-content">
        {promotions.inPlaySlidingBanners && promotions.inPlaySlidingBanners.length >= 1 ? <TopBaner inPlaySlidingBanners={promotions.inPlaySlidingBanners} /> : ''}
        {promotions.sportsButtons && promotions.sportsButtons.length >=1 ? <SportsButtons sportsButtons={promotions.sportsButtons}/>  : "" }
        {promotions.quickButtons && promotions.quickButtons.length >= 1 ? <QuickButtons quickButtons={promotions.quickButtons} auth={auth} /> : ''}
      </IonItemGroup> : ''}
    </>
  );
}

export default connectPromotions()(Promotion)
