import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getSlotsGamesSearchSuccess, getSlotsGamesSearchError } from './slotsGamesSearch.slice'

export const requestSlotsGamesSearch = (name) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    
    if(checkAuth) {
        try {
            const response = await httpAuth.get(`/casino/v2/slot-games/search?query=${name}`)
           if (response.data.success) {
                dispatch(getSlotsGamesSearchSuccess(response.data.result))
            }
            return response
        } catch (e) {
            dispatch(getSlotsGamesSearchError())
            const errorMsg = getErrorMessage(e)
            throw errorMsg
        }
    } else {
        try {
            const response = await httpNotAuth.get(`/casino/v2/slot-games/search?query=${name}`)
           if (response.data.success) {
                dispatch(getSlotsGamesSearchSuccess(response.data.result))
            }
            return response
        } catch (e) {
            dispatch(getSlotsGamesSearchError())
            const errorMsg = getErrorMessage(e)
            throw errorMsg
        }
    }
}

