import { createSlice } from '@reduxjs/toolkit'

export const BonusPopUp = createSlice({
  name: 'bonusPopUp',
  initialState: {
    isPopUpOpen: false,
    walletType: '',
  },
  reducers: {
    // setBonusPopUp: (state, action) => action.payload,
    setBonusPopUp: (state, action) => ({ ...state, isPopUpOpen: action.payload }),
    setWalletType: (state, action) => ({ ...state, walletType: action.payload }),
  },
})

export const { setBonusPopUp, setWalletType } = BonusPopUp.actions

export default BonusPopUp.reducer
