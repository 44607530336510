import React, { useState, useEffect, useRef } from 'react'
import Poligon from '../../../assets/images/poligonIcon.svg'

import {
  IonContent,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonListHeader,
  IonList,
  IonItem,
  IonPage,
  IonHeader,
} from '@ionic/react'
import './TimeSettingsDesktop.scss'
// import Toolbar from '../../components/Toolbar/Toolbar'

import { connectTimeSettingsDesktop } from './TimeSettingsDesktop.connect'
import { formatTime } from '../../../helpers/date.helper'
import { document } from 'ionicons/icons'

const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const TimeSettingsDesktop = ({
  timeZoneName = '',
  serverTime,
  actions, ...props }) => {
  const [gmtDevice, setGmtDevice] = useState()
  const [timeCounter, setTimeCounter] = useState(0)
  const timeUTCRef = useRef(null)
  const [timeUTC, setTimeUTC] = useState(false);
  const [gmtTime, setGmtTime] = useState()
  const [showTime, setShowTime] = useState()

  const onTimeUTC = () => {
    setTimeUTC(!timeUTC)
  }

  const onChangeGMT = (e) => {
    setGmtTime(e)
    console.log(gmtDevice, 'Device')
}


  useEffect(() => {
    const timer = setInterval(() => {
      setTimeCounter(timeCounter => timeCounter + 1);
    }, 1000);
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCounter]);

  useEffect(() => {
    const now = new Date()
    let offset = `${now}`.replace(/^[\w]+ [\w]+ [0-9]{2} [0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2} ([\w]+)([+-][0-9]{2})([0-9]{2})()/, '$1 $2:$3$4').replace(/^([\w]+ [+-][0-9]{2}:[0-9]{2}) (.*)/, '$1')
    setGmtDevice(offset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const now = new Date()
    let offset = `${now}`.replace(/^[\w]+ [\w]+ [0-9]{2} [0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2} ([\w]+)([+-][0-9]{2})([0-9]{2})()/, '$1 $2:$3$4').replace(/^([\w]+ [+-][0-9]{2}:[0-9]{2}) (.*)/, '$1').slice(3, 11)
    setShowTime(offset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    actions.requestServerTime().then((res) => res)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getTime = () => {
    const now = new Date()
    if (timeZoneName === 'system')
      return (
      <span className="date-small">{monthName[now.getUTCMonth()]} {now.getUTCDate()}th, {now.getUTCFullYear()} <span className="date-big">{formatTime(now.getUTCHours())}:{formatTime(now.getUTCMinutes())}:{formatTime(now.getUTCSeconds())}</span></span>
      )
    else if (timeZoneName === 'device')
      return (
      <span className="date-small">{monthName[now.getMonth()]} {now.getDate()}th, {now.getFullYear()} <span className="date-big">{formatTime(now.getHours())}:{formatTime(now.getMinutes())}:{formatTime(now.getSeconds())}</span></span>
      )
    else {
      now.setMinutes(now.getMinutes() + now.getTimezoneOffset() + 330)
      return <span className="date-small">{monthName[now.getMonth()]} {now.getDate()}th, {now.getFullYear()} <span className="date-big">{formatTime(now.getHours())}:{formatTime(now.getMinutes())}:{formatTime(now.getSeconds())}</span></span>
    }
  }

// close time dropdown after click somewhere
  const contentDateRef = useRef(null)
  const closeTime = (e) => !e.path?.includes(contentDateRef.current) ? setTimeUTC(false) : ""

  useEffect(() => {
    window.addEventListener('click', closeTime)
    return () => window.removeEventListener('click', closeTime)
  },[])
// END close time dropdown after click somewhere

   return (
        <div className='headerDesktop-content__date' onClick={() => {onTimeUTC()} } ref={contentDateRef}>
            {getTime()}
            <span className='date-small__yellow'>
              <span className='date-small__yellow-symbol'>(</span>
              <span className='date-small__yellow-time'>{gmtTime ? gmtTime : showTime}</span>
              <span className='date-small__yellow-arrow'></span>
              <span className='date-small__yellow-symbolright'>)</span>
            </span>
            <div ref={timeUTCRef} className={`timeutc ${timeUTC ? "active" : "inactive"}`}>
              <div className='poligon'>
                <img src={Poligon} alt="" />
              </div>
              <IonRadioGroup value={timeZoneName} onIonChange={e => actions.setTimeZoneName(e.detail.value)}>
                <div className='timeList'>
                  <IonItem className="TimeSettings__option TimeSettings__option--first" onClick={() => onChangeGMT(' +00:00 ')}>
                  <div className='timeList-text'>System time - (GMT +00:00)</div>
                  <IonRadio className='radioTime' value="system" mode="md" />
                  </IonItem>

                  <IonItem className="TimeSettings__option" onClick={() => onChangeGMT(gmtDevice.slice(3, 11)) }>
                  <div className='timeList-text'>Your device time - ({gmtDevice})</div>
                  <IonRadio className='radioTime' value="device" mode="md" />
                  </IonItem>

                  <IonItem className="TimeSettings__option TimeSettings__option--last" onClick={() => onChangeGMT(' +05:30 ')}>
                  <div className='timeList-text'>India Standard time - (GMT +05:30)</div>
                  <IonRadio className='radioTime' value="india" mode="md" />
                  </IonItem>
                </div>
              </IonRadioGroup>
          </div>
        </div>
  )
}

export default connectTimeSettingsDesktop()(TimeSettingsDesktop)
