import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { useHistory } from 'react-router'

import axios from 'axios'
import { BASE_DEV_URL } from '../../config/api.config'


export const requestJiliSlot = (amount) => async (dispatch) => {
    const token = localStorage.getItem('id_token')
    const dataPost =  [{
        line: null,
        marketId: "7.7",
        oddsType: null,
        price: 1,
        selectionId: 1,
        side: 1,
        totalSize: Number(amount),
    }]
    
    try {
        const response = await axios.post(BASE_DEV_URL + `/casino/live/funds/deposit`, dataPost, {
            headers: {
              Authorization: `${token}`,
            },
          })
       if (response.data.success) {
        console.log('response', response);
    }
    return response
    } catch (e) {
        // dispatch(getLiveCasinoIDError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}

