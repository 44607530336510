import React from 'react'
import './TeenpattiTestCards.scss'
import { useSelector } from 'react-redux'
import { connectTeenpattiTest } from '../../TeenpattiTest.connect'
import { useParams } from 'react-router-dom'
import RoundTimer from '../../../helpers/RoundTimer/RoundTimer'


const TeenpattiTestCards = ({ exchangeGames }) => {

  // console.log('asa', exchangeGames);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }
      return (
        <div className='TPTCardsWrapper'>
        <div className="TPTCardsContent">
          <div className='TPTCardsContent-name'>Tiger</div>
          <div className="TPTCardsContent-total">
            <div className="TPTCardsContent-total__cards">
              <img
                className="TPTCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0]
                )}
                alt=""
              />
              <img
                className="TPTCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1]
                )}
                alt=""
              />
              <img
                className="TPTCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[2]
                )}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="TPTCardsContent">
          <div className='TPTCardsContent-name'>Lion</div>
          <div className="TPTCardsContent-total">
            <div className="TPTCardsContent-total__cards">
              <img
                className="TPTCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0]
                )}
                alt=""
              />
              <img
                className="TPTCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1]
                )}
                alt=""
              />
              <img
                className="TPTCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[2]
                )}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="TPTCardsContent">
          <div className='TPTCardsContent-name'>Dragon</div>
          <div className="TPTCardsContent-total">
            <div className="TPTCardsContent-total__cards">
              <img
                className="TPTCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[2]?.cards[0]
                )}
                alt=""
              />
              <img
                className="TPTCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[2]?.cards[1]
                )}
                alt=""
              />
              <img
                className="TPTCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[2]?.cards[2]
                )}
                alt=""
              />
            </div>
          </div>
        </div>
        </div>
      )
  }


export default connectTeenpattiTest()(TeenpattiTestCards)
