import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { connectAmarAkbarAnthony } from './AmarAkbarAnthony.connect';
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
// import BetPlacing from '../../BetPlacing/BetPlacing';
import LastResults from '../helpers/LastResults/LastResults';
import CardsInfo from '../helpers/CardsInfo/CardsInfo';
import Heart from '../../../assets/images/exchangeGames/bollywoodCasino/heart.svg'
import HeartSusp from '../../../assets/images/exchangeGames/bollywoodCasino/heartSusp.svg'
import Diamond from '../../../assets/images/exchangeGames/bollywoodCasino/diamond.svg'
import DiamondSusp from '../../../assets/images/exchangeGames/bollywoodCasino/diamondSusp.svg'
import Club from '../../../assets/images/exchangeGames/bollywoodCasino/club.svg'
import Spade from '../../../assets/images/exchangeGames/bollywoodCasino/spade.svg'
import trophy from '../../../assets/images/TrophyTeenPattiTest.svg'
import "./AmarAkbarAnthony.scss"

const AmarAkbarAnthony = ({
  sportEvent,
  betPlacing,
  actions,
  exchangeGames,
  exposure,
  activeTab,
  headerHeight,
  auth,
  elementsParams
 }) => {

  const {eventId} = useParams()

  // const dataOdds = useSelector((state) => state.sportEvent.sportData)
  // const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // const exposurePnl = (id) => {
  //   if(auth) {
  //     const objFinder =
  //     exposure?.pnlBySelection[
  //       Object.keys(exposure?.pnlBySelection).filter((item) =>
  //         item.includes(id)
  //       )
  //     ]?.pnl
  //     return (
  //       <div
  //         className={
  //           objFinder > 0
  //             ? 'exposure-box-AAA exposure-AAA--pos'
  //             : 'exposure-box-AAA exposure-AAA--neg'
  //         }
  //       >
  //         {exposure?.pnlBySelection[
  //           Object.keys(exposure?.pnlBySelection).filter((item) =>
  //             item.includes(id)
  //           )
  //         ]?.pnl}
  //       </div>
  //     )
  //   } else return
  // }
  //
  // const exposurePnlSp = (id) => {
  //   if(auth) {
  //     const objFinder =
  //     exposure?.pnlBySelection[
  //       Object.keys(exposure?.pnlBySelection).filter((item) =>
  //         item.includes(id)
  //       )
  //     ]?.pnl
  //     return (
  //       <div
  //         className={
  //           objFinder > 0
  //             ? 'exposure-box-AAASp exposure-AAA--pos'
  //             : 'exposure-box-AAASp exposure-AAA--neg'
  //         }
  //       >
  //         {exposure?.pnlBySelection[
  //           Object.keys(exposure?.pnlBySelection).filter((item) =>
  //             item.includes(id)
  //           )
  //         ]?.pnl}
  //       </div>
  //     )
  //   } else return
  // }
  //
  // const exposureTrue = (id) => {
  //   if(auth) {
  //     const exp =
  //     exposure?.pnlBySelection[
  //       Object.keys(exposure?.pnlBySelection).filter((item) =>
  //         item.includes(id)
  //       )
  //     ]?.pnl
  //     return (
  //       exp
  //     )
  //   } else return
  // }
  //
  // useEffect(() => {
  //     const timer = setIntervalAsync(async() => {
  //       await actions
  //                 .requestExchangeGames(eventId)
  //                 .catch((e) => console.log('requestInplayPopular error = ', e))
  //     }, GET_INPLAY_POPULAR_INTERVAL)
  //     return () => clearIntervalAsync(timer)
  //
  // }, [])
  //
  // const renderBetPlacing = (runner) => {
  //   if (
  //     betPlacing.runner && width < 1024
  //   ) {
  //     return <BetPlacing  headerHeight={headerHeight} />
  //   }
  //   return null
  // }
  //
  // const select = (dataOdd, item, isBack) => {
  //   const runner = { name: item.name, id: item.id }
  //   const oddData = isBack
  //     ? { ...item.back[0], runner }
  //     : { ...item.lay[0], runner }
  //   const marketId = dataOdd && dataOdd.id
  //   const runnerId = oddData && oddData.runner && oddData.runner.id
  //   console.log(marketId, runnerId)
  //   if (!marketId || !runnerId) return
  //   actions.setMarket(marketId)
  //   actions.setRunner(runnerId)
  //   actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
  //   actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
  //   actions.setIsBack(isBack)
  //   actions.setPrice(oddData && oddData.price)
  //   actions.setPriceActual(oddData && oddData.price)
  //   actions.setDelay(dataOdd && dataOdd.betDelay)
  //   actions.setMtype(dataOdd && dataOdd.mtype)
  //   actions.setBtype(dataOdd && dataOdd.btype)
  //   actions.setLine(oddData && oddData.line)
  //   actions.setFromOneClick(false)
  //   actions.setStake(null)
  //
  //   actions
  //     .requestBetPlacingStart(marketId, runnerId)
  //     .then((response) => {})
  //     .catch((err) => console.log('err = ', err))
  // }

  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  // sport event data
  const dataOdd = sportEvent.sportData
  // end sport event data

  // request Exchange Game data
  useEffect(() => {
    const timer = setIntervalAsync(async () => {
      await actions
        .requestExchangeGames(eventId)
        .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)
  }, [])
  // End request Exchange Game data

  // Show betslip
  // const renderBetPlacing = () => {
  //   if (betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight} />
  //   }
  //   return null
  // }
  // End Show betslip

  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing

  //get price
  const getMarketPrice = (marketIndex, runnersIndex, side = "back") => {
    if (dataOdd &&
        dataOdd[marketIndex] &&
        dataOdd[marketIndex].runners[runnersIndex] &&
        dataOdd[marketIndex].runners[runnersIndex][side][0] &&
        dataOdd[marketIndex].runners[runnersIndex][side][0].price) {

      return dataOdd[marketIndex].runners[runnersIndex][side][0].price;
    }
  };
  //end get price

  // get exposure
  const getExposure = (marketIndex, runnerIndex) => {
    if(auth) {
      let id = dataOdd[marketIndex].runners[runnerIndex].id
      let objFinder =  exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl

      return (
        <div
          className={`exposure-box-modern ${objFinder > 0 ? "exposure-modern--pos" : "exposure-modern--neg"} `}
        >
          {objFinder && objFinder}
        </div>
      )
    } else return
  }
  // end get exposure

  // lock market
  const marketStatus = (marketIndex) => {
    return (!dataOdd[marketIndex].isBettable && 'lock-ui')
  }
  // end lock market

  // get winner
  const getWinner = (marketIndex, runnerIndex) => {
    return exchangeGames &&
      exchangeGames[marketIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex]?.status
  }
  // get winner

  return (
      <div className={`AmarAkbarAnthony__main ${eventId === "98795" ? "virtual" : ""} ${activeTab === 0 ? "fullHeight" : ""}`}>
        <CardsInfo />
        <div className="AmarAkbarAnthony__main-wrapper">
          {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
          <div className="AmarAkbarAnthony-table">
            <div className={`AmarAkbarAnthony-table__row AmarAkbarAnthony-table__big-items-wrapper`}>
              <div className={`AmarAkbarAnthony-table__row-item AmarAkbarAnthony__big-item ${getWinner(0,0)} ${marketStatus(0)}`}>
                <div className={`AmarAkbarAnthony-table__row-item-name-exposure`}>
                  <span className={`AmarAkbarAnthony-table__row-item-name`}>Amar</span>
                  {getExposure(0,0)}
                </div>
                <div className={`AmarAkbarAnthony-table__row-item-back-lay-wrapper`}>
                  <div className={`AmarAkbarAnthony-table__row-item-back`} onClick={() => {oddPush(0,0);}}>
                      <span className={`AmarAkbarAnthony-table__row-item-price`}>
                        {getMarketPrice(0,0)}
                      </span>
                  </div>
                  <div className={`AmarAkbarAnthony-table__row-item-lay`} onClick={() => {oddPush(0,0, false);}}>
                      <span className={`AmarAkbarAnthony-table__row-item-price`}>
                        {getMarketPrice(0,0, "lay")}
                      </span>
                  </div>
                </div>
              </div>
              <div className={`AmarAkbarAnthony-table__row-item AmarAkbarAnthony__big-item ${getWinner(0,1)} ${marketStatus(0)}`}>
                <div className={`AmarAkbarAnthony-table__row-item-name-exposure`}>
                  <span className={`AmarAkbarAnthony-table__row-item-name`}>Akbar</span>
                  {getExposure(0,1)}
                </div>
                <div className={`AmarAkbarAnthony-table__row-item-back-lay-wrapper`}>
                  <div className={`AmarAkbarAnthony-table__row-item-back`} onClick={() => {oddPush(0,1);}}>
                    <span className={`AmarAkbarAnthony-table__row-item-price`}>
                      {getMarketPrice(0,1)}
                    </span>
                  </div>
                  <div className={`AmarAkbarAnthony-table__row-item-lay`} onClick={() => {oddPush(0,1, false);}}>
                    <span className={`AmarAkbarAnthony-table__row-item-price`}>
                      {getMarketPrice(0,1, "lay")}
                    </span>
                  </div>
                </div>
              </div>
              <div className={`AmarAkbarAnthony-table__row-item AmarAkbarAnthony__big-item ${getWinner(0,2)} ${marketStatus(0)}`}>
                <div className={`AmarAkbarAnthony-table__row-item-name-exposure`}>
                  <span className={`AmarAkbarAnthony-table__row-item-name`}>Anthony</span>
                  {getExposure(0,2)}
                </div>
                <div className={`AmarAkbarAnthony-table__row-item-back-lay-wrapper`}>
                  <div className={`AmarAkbarAnthony-table__row-item-back`} onClick={() => {oddPush(0,2);}}>
                    <span className={`AmarAkbarAnthony-table__row-item-price`}>
                      {getMarketPrice(0,2)}
                    </span>
                  </div>
                  <div className={`AmarAkbarAnthony-table__row-item-lay`} onClick={() => {oddPush(0,2, false);}}>
                    <span className={`AmarAkbarAnthony-table__row-item-price`}>
                      {getMarketPrice(0,2, "lay")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={`AmarAkbarAnthony-table__row AmarAkbarAnthony-table__small-items-wrapper`}>
              <div className="AmarAkbarAnthony-table__small-items-wrapper-inner">
                <div  className={`AmarAkbarAnthony-table__row-item-small ${getWinner(1,1)} ${marketStatus(1)}`}
                      onClick={() => {oddPush(1,1);}}
                >
                  <span className={`AmarAkbarAnthony-table__row-item-name`}>EVEN</span>
                  <div>
                    <span className={`AmarAkbarAnthony-table__row-item-price`}>
                      {getMarketPrice(1,1)}
                    </span>
                    {getExposure(1,1)}
                  </div>
                </div>
                <div  className={`AmarAkbarAnthony-table__row-item-small ${getWinner(1,0)} ${marketStatus(1)}`}
                      onClick={() => {oddPush(1,0);}}
                >
                  <span className={`AmarAkbarAnthony-table__row-item-name`}>ODD</span>
                  <div>
                    <span className={`AmarAkbarAnthony-table__row-item-price`}>
                      {getMarketPrice(1,0)}
                    </span>
                    {getExposure(1,0)}
                  </div>
                </div>
              </div>
              <div className="AmarAkbarAnthony-table__small-items-wrapper-inner">
                <div  className={`AmarAkbarAnthony-table__row-item-small ${getWinner(2,0)} ${marketStatus(2)}`}
                      onClick={() => {oddPush(2,0);}}
                >
                <span className={`AmarAkbarAnthony-table__row-item-name AmarAkbarAnthony-table__colors`}>
                  <img src={Spade} alt="Spade" />
                  <img src={Club} alt="Club" />
                </span>
                  <div>
                    <span className={`AmarAkbarAnthony-table__row-item-price`}>
                      {getMarketPrice(2,0)}
                    </span>
                    {getExposure(2,0)}
                  </div>
                </div>
                <div  className={`AmarAkbarAnthony-table__row-item-small ${getWinner(2,1)} ${marketStatus(2)}`}
                      onClick={() => {oddPush(2,1);}}
                >
                <span className={`AmarAkbarAnthony-table__row-item-name  AmarAkbarAnthony-table__colors`}>
                  <img src={Heart} alt="Heart" />
                  <img src={Diamond} alt="Diamond" />
                </span>
                  <div>
                    <span className={`AmarAkbarAnthony-table__row-item-price`}>
                      {getMarketPrice(2,1)}
                    </span>
                    {getExposure(2,1)}
                  </div>
                </div>
              </div>
              <div className="AmarAkbarAnthony-table__small-items-wrapper-inner">
                <div  className={`AmarAkbarAnthony-table__row-item-small AmarAkbarAnthony-table__row-item-small-under ${getWinner(3,0)} ${marketStatus(3)}`}
                      onClick={() => {oddPush(3,0);}}
                >
                  <div className={`AmarAkbarAnthony-table__row-item-name-wrapper`}>
                    <span className={`AmarAkbarAnthony-table__row-item-name`}>Under 7</span>
                    {width < 1024 && getExposure(3,0) }
                  </div>
                  <div>
                    <span className={`AmarAkbarAnthony-table__row-item-price`}>
                      {getMarketPrice(3,0)}
                    </span>
                    {width > 1024 && getExposure(3,0)}
                  </div>
                </div>
                <div  className={`AmarAkbarAnthony-table__row-item-small AmarAkbarAnthony-table__row-item-small-over ${getWinner(3,1)} ${marketStatus(3)}`}
                      onClick={() => {oddPush(3,1);}}
                >
                  <div className={`AmarAkbarAnthony-table__row-item-name-wrapper`}>
                    <span className={`AmarAkbarAnthony-table__row-item-name `}>Over 7</span>
                    {width < 1024 && getExposure(3,1)}
                  </div>
                  <div>
                      <span className={`AmarAkbarAnthony-table__row-item-price`}>
                        {getMarketPrice(3,1)}
                      </span>
                    {width > 1024 && getExposure(3,1) }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LastResults/>
      </div>
    )
}

export default connectAmarAkbarAnthony()(AmarAkbarAnthony)
