import React from 'react'

const Race2020Rules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          The objective of Race 20-20 is to predict that which King of a suit
          will get five cards first. It is played with a regular 52 card deck
          and all cards are used in the game except the four King cards.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <div>
          <div>
            <ul>
              <li>
                When the game round starts, the markets are opened for Betting.
              </li>
              <li>After the markets are suspended, dealer deals cards.</li>
              <li>The winnings are paid to users according to their bets.</li>
            </ul>
          </div>
          <h4>Main Bets</h4>
          <div>
            <ol>
              <li>King of Spades</li>
              <li>King of Hearts</li>
              <li>King of Clubs</li>
              <li>King of Diamonds</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Race2020Rules
