import React, { useEffect } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import './index.scss'
import { useSelector } from 'react-redux'
import { connectTwoCardTeenPatti } from './TwoCardTeenPatti.connect'
// import BetPlacing from '../../BetPlacing/BetPlacing'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
import LastResults from '../helpers/LastResults/LastResults'
import CardsInfo from '../helpers/CardsInfo/CardsInfo'

const TwoCardTeenPatti = connectTwoCardTeenPatti()(
  ({ betPlacing, actions, exposure, headerHeight, auth, elementsParams }) => {
    
    const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

    const dataOdd = useSelector((state) => state.sportEvent.sportData)

    const exposurePnl = (id) => {
      if(auth) {
        const objFinder =
        exposure?.pnlBySelection[
          Object.keys(exposure?.pnlBySelection).filter((item) =>
            item.includes(id)
          )
        ]?.pnl
        return (
          <div
            className={
              objFinder > 0
                ? 'exposure-box-modern exposure-modern--pos'
                : 'exposure-box-modern exposure-modern--neg'
            }
          >
            {exposure?.pnlBySelection[
              Object.keys(exposure?.pnlBySelection).filter((item) =>
                item.includes(id)
              )
            ]?.pnl}
          </div>
        )
      } else return
    }

    const exposurePnlColor = (id) => {
      if(auth) {
        const objFinder =
        exposure?.pnlBySelection[
          Object.keys(exposure?.pnlBySelection).filter((item) =>
            item.includes(id)
          )
        ]?.pnl
        return (
          <div
            className={
              objFinder > 0
                ? 'exposure-box-modernPlus exposure-modernPlus--pos'
                : 'exposure-box-modernPlus exposure-modernPlus--neg'
            }
          >
            {exposure?.pnlBySelection[
              Object.keys(exposure?.pnlBySelection).filter((item) =>
                item.includes(id)
              )
            ]?.pnl}
          </div>
        )
      } else return
    }

    const requestExchInfo = (id) => {
      actions
        .requestExchangeGames('67660')
        .catch((e) => console.log('requestInplayPopular error = ', e))
    }

    useEffect(() => {
      const timer = setIntervalAsync(async() => {
        await actions
              .requestExchangeGames('67660')
              .catch((e) => console.log('requestInplayPopular error = ', e))
      }, GET_INPLAY_POPULAR_INTERVAL)
      return () => clearIntervalAsync(timer)
    }, [])

    // const renderBetPlacing = (runner) => {
    //   if (betPlacing.runner && width < 1024 && betPlacing.price !== undefined) {
    //     return <BetPlacing headerHeight={headerHeight}/>
    //   }
    //   return null
    // }

    const select = (dataOdd, item, isBack) => {
      const runner = { name: item.name, id: item.id }
      const oddData = isBack
        ? { ...item.back[0], runner }
        : { ...item.lay[0], runner }
      if (oddData && oddData.price == "0") {
        return
      }
      const marketId = dataOdd && dataOdd.id
      const runnerId = oddData && oddData.runner && oddData.runner.id
      if (!marketId || !runnerId) return
      actions.setMarket(marketId)
      actions.setRunner(runnerId)
      actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
      actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
      actions.setIsBack(isBack)
      actions.setPrice(oddData && oddData.price)
      actions.setPriceActual(oddData && oddData.price)
      actions.setDelay(dataOdd && dataOdd.betDelay)
      actions.setMtype(dataOdd && dataOdd.mtype)
      actions.setBtype(dataOdd && dataOdd.btype)
      actions.setLine(oddData && oddData.line)
      actions.setFromOneClick(false)
      actions.setStake(null)

      actions
        .requestBetPlacingStart(marketId, runnerId)
        .then((response) => {})
        .catch((err) => console.log('err = ', err))
    }

    const oddPush = (marketIndex, runnerIndex, isBack = true) => {
      return dataOdd[marketIndex].isBettable
        ? select(
            dataOdd[marketIndex],
            dataOdd[marketIndex]?.runners[runnerIndex],
            isBack
          )
        : null
    }

    return (
      <div>
        <CardsInfo />
        {/* <div className="gc-game-title">
          <div className="game-title">2 CARD TEENPATTI</div>
          <div className="game-round-id">
            #{exchangeGames && exchangeGames[0]?.roundId}
          </div>
        </div> */}
        <div className="teenpatti2card-table__body">
          {/* <>{width < 1024 ? renderBetPlacing() : ""}</> */}
          <div className="teenpatti2card-table__body-colunm">
            <div className="teenpatti2card-table__body-player-wrapper">
              <div className="teenpatti2card-table__body-player-title">
                <span className="teenpatti2card-table__body-text">
                  Player A
                </span>
              </div>
              <div className="teenpatti2card-table__body-player-square-row">
                <div
                  className={`teenpatti2card-table__body-player-square teenpatti2card-table__body-player-square-blue ${
                    !dataOdd[0].isBettable && 'lock-ui'
                  }`}
                  onClick={() => oddPush(0, 0)}
                >
                  <span className="teenpatti2card-table__body-text bold">
                    {dataOdd[0]?.runners[0]?.back[0]?.price}
                  </span>
                </div>
                <div
                  className={`teenpatti2card-table__body-player-square teenpatti2card-table__body-player-square-red ${
                    !dataOdd[0].isBettable && 'lock-ui'
                  }`}
                  onClick={() => oddPush(0, 0, false)}
                >
                  <span className="teenpatti2card-table__body-text bold">
                    0
                  </span>
                </div>
                {exposurePnl(dataOdd[0]?.runners[0]?.id)}
              </div>
            </div>

            <div className="teenpatti2card-table__body-player-wrapper">
              <div className="teenpatti2card-table__body-player-title">
                <span className="teenpatti2card-table__body-text">
                  Player B
                </span>
              </div>
              <div className="teenpatti2card-table__body-player-square-row">
                <div
                  className={`teenpatti2card-table__body-player-square teenpatti2card-table__body-player-square-blue ${
                    !dataOdd[0].isBettable && 'lock-ui'
                  }`}
                  onClick={
                    dataOdd[0].isBettable
                      ? () => select(dataOdd[0], dataOdd[0]?.runners[1], true)
                      : null
                  }
                >
                  <span className="teenpatti2card-table__body-text bold">
                    {dataOdd[0]?.runners[1]?.back[0]?.price}
                  </span>
                </div>
                <div
                  className={`teenpatti2card-table__body-player-square teenpatti2card-table__body-player-square-red ${
                    !dataOdd[0].isBettable && 'lock-ui'
                  }`}
                  onClick={
                    dataOdd[0].isBettable
                      ? () => select(dataOdd[0], dataOdd[0]?.runners[1], false)
                      : null
                  }
                >
                  <span className="teenpatti2card-table__body-text bold">
                    0
                  </span>
                </div>
                {exposurePnl(dataOdd[0]?.runners[1]?.id)}
              </div>
            </div>
          </div>
          <div className="teenpatti2card-table__body-colunm">
            <div className="teenpatti2card-table__body-player-wrapper teenpatti2card-table__body-player-baccarat"
                 onClick={
                   dataOdd[1].isBettable
                     ? () => select(dataOdd[1], dataOdd[1]?.runners[0], true)
                     : null
                 }
            >
              <div className="teenpatti2card-table__body-player-title">
                <span className="teenpatti2card-table__body-text">
                  Mini Baccarat A
                </span>
              </div>
              <div
                className={`teenpatti2card-table__body-player-title teenpatti2card-table__body-player-title-baccarat ${
                  !dataOdd[1].isBettable && 'lock-ui'
                }`}
              >
                <span className="teenpatti2card-table__body-text bold">
                  {dataOdd[1]?.runners[0]?.back[0]?.price}
                </span>
                {exposurePnl(dataOdd[1]?.runners[0]?.id)}
              </div>
            </div>

            <div className="teenpatti2card-table__body-player-wrapper teenpatti2card-table__body-player-baccarat"
                 onClick={
                   dataOdd[1].isBettable
                     ? () => select(dataOdd[1], dataOdd[1]?.runners[1], true)
                     : null
                 }
            >
              <div className="teenpatti2card-table__body-player-title">
                <span className="teenpatti2card-table__body-text">
                  Mini Baccarat B
                </span>
              </div>
              <div
                className={`teenpatti2card-table__body-player-title teenpatti2card-table__body-player-title-baccarat ${
                  !dataOdd[1].isBettable && 'lock-ui'
                }`}
              >
                <span className="teenpatti2card-table__body-text bold">
                  {dataOdd[1]?.runners[1]?.back[0]?.price}
                </span>
                {exposurePnl(dataOdd[1]?.runners[1]?.id)}
              </div>
            </div>
          </div>
          <div className="teenpatti2card-table__body-colunm">
            <div className="teenpatti2card-table__body-player-wrapper ">
              <div className="teenpatti2card-table__body-player-title">
                <span className="teenpatti2card-table__body-text">Total A</span>
              </div>
              <div className="teenpatti2card-table__body-player-square-row">
                <div
                  className={`teenpatti2card-table__body-player-square teenpatti2card-table__body-player-square-blue ${
                    !dataOdd[2].isBettable && 'lock-ui'
                  }`}
                  onClick={
                    dataOdd[2].isBettable
                      ? () => select(dataOdd[2], dataOdd[2]?.runners[1], true)
                      : null
                  }
                >
                  <span className="teenpatti2card-table__body-text-title">
                    15
                  </span>
                  <span className="teenpatti2card-table__body-text bold">
                    {dataOdd[2]?.runners[1]?.back[0]?.price}
                  </span>
                </div>
                <div
                  className={`teenpatti2card-table__body-player-square teenpatti2card-table__body-player-square-red ${
                    !dataOdd[2].isBettable && 'lock-ui'
                  }`}
                  onClick={
                    dataOdd[2].isBettable
                      ? () => select(dataOdd[2], dataOdd[2]?.runners[1], false)
                      : null
                  }
                >
                  <span className="teenpatti2card-table__body-text-title">
                    14
                  </span>
                  <span className="teenpatti2card-table__body-text bold">
                    {dataOdd[2]?.runners[1]?.lay[0]?.price}
                  </span>
                </div>
                {exposurePnl(dataOdd[2]?.runners[1]?.id)}
              </div>
            </div>

            <div className="teenpatti2card-table__body-player-wrapper">
              <div className="teenpatti2card-table__body-player-title">
                <span className="teenpatti2card-table__body-text">Total B</span>
              </div>
              <div className="teenpatti2card-table__body-player-square-row">
                <div
                  className={`teenpatti2card-table__body-player-square teenpatti2card-table__body-player-square-blue ${
                    !dataOdd[3]?.isBettable && 'lock-ui'
                  }`}
                  onClick={
                    dataOdd[3]?.isBettable
                      ? () => select(dataOdd[3], dataOdd[3]?.runners[1], true)
                      : null
                  }
                >
                  <span className="teenpatti2card-table__body-text-title">
                    15
                  </span>
                  <span className="teenpatti2card-table__body-text bold">
                    {dataOdd[3]?.runners[1]?.back[0]?.price}
                  </span>
                </div>
                <div
                  className={`teenpatti2card-table__body-player-square teenpatti2card-table__body-player-square-red ${
                    !dataOdd[3]?.isBettable && 'lock-ui'
                  }`}
                  onClick={
                    dataOdd[3]?.isBettable
                      ? () => select(dataOdd[3], dataOdd[3]?.runners[1], false)
                      : null
                  }
                >
                  <span className="teenpatti2card-table__body-text-title">
                    14
                  </span>
                  <span className="teenpatti2card-table__body-text bold">
                    {dataOdd[3]?.runners[1]?.lay[0]?.price}
                  </span>
                </div>
                {exposurePnl(dataOdd[3]?.runners[1]?.id)}
              </div>
            </div>
          </div>
          <div
            className={`teenpatti2card-table__body-button ${
              !dataOdd[4]?.isBettable && 'lock-ui'
            }`}
            onClick={
              dataOdd[4]?.isBettable
                ? () => select(dataOdd[4], dataOdd[4]?.runners[0], true)
                : null
            }
          >
            <div className="teenpatti2card-table__body-player-title ">
              <span
                style={{fontWeight: '700'}}
                className="teenpatti2card-table__body-text">
                Color Plus
              </span>
            </div>
            {exposurePnlColor(dataOdd[4]?.runners[0]?.id)}
          </div>
        </div>
        <LastResults />
      </div>
    )
  }
)

export default TwoCardTeenPatti
