import { createSlice } from '@reduxjs/toolkit'

export const accountStatementSlice = createSlice({
    name: 'accountStatement',
    initialState: null,
    reducers: {
        getAccountStatementSuccess: (state, action) => action.payload,
        getAccountStatementError: (state) => state,
    },
})

export const { getAccountStatementSuccess, getAccountStatementError } = accountStatementSlice.actions

export default accountStatementSlice.reducer
