import isEmptyObject from './isEmptyObject.helper'

export default (e) =>
  e &&
  e.response &&
  e.response.data.error &&
  isEmptyObject(e.response.data.error)
    ? e.response.data.error
    : e.response && e.response.data && e.response.data.message
    ? e.response.data.message
    : e.response && e.response.data
    ? e.response.data
    : e.statusText
    ? e.statusText
    : e
