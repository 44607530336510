import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setDetailedDepositTransaction } from '../../store/detailedDepositTransaction/detailedDepositTransaction.slice'

export const connectDetailedDepositTransaction =
  (wrappedProps) => (component) => {
    function mapStateToProps(state) {
      return {
        user: state.user,
        elementsParams: state.elementsParams
      }
    }

    function mapDispatchToProps(dispatch) {
      const actions = {
        setDetailedDepositTransaction,
      }

      return { actions: bindActionCreators(actions, dispatch) }
    }

    return connect(mapStateToProps, mapDispatchToProps)(component)
  }
