import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setShowBetPlacingPopover, setBetPlacingPopoverStage } from '../../../store/betPlacing/betPlacing.slice'
import { isConfirmBetPopUpOpenThunk, requestSetOrder } from '../../../store/betPlacing/betPlacing.thunks'
import { setIsCashOut, setCashOutId } from '../../../store/betPlacing/betPlacing.slice'
import { requestCashOut } from '../../../store/betPlacing/betPlacing.thunks'
export const connectConfirmBetPopUp = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      betPlacing: state.betPlacing,
      sportEventStats: state.sportEvent.sportStats,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setBetPlacingPopoverStage,
      setShowBetPlacingPopover,
      requestSetOrder,
      isConfirmBetPopUpOpenThunk,
      setIsCashOut,
      requestCashOut,
      setCashOutId,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
