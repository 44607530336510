import { createSlice } from '@reduxjs/toolkit'

export const sportbookLiveSlice = createSlice({
  name: 'sportbookLive',
  initialState: null,
  reducers: {
    getSportbookLiveSuccess: (state, action) => action.payload,
    getSportbookLiveError: (state) => null,
  },
})

export const { getSportbookLiveSuccess, getSportbookLiveError } = sportbookLiveSlice.actions

export default sportbookLiveSlice.reducer
