import "./MulfisTeenpattiPL.scss";
import React, { useEffect, useState } from 'react'

const MulfisTeenpattiPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {
     winners: "",
     top9: '',
   };
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
         if (market.marketHeader === 'Top 9' && runner.status === 'WINNER') {
           winObj.top9 = winObj.top9.length <= 3 ?
             `${runner.name.slice(7, 8)}: Card ${runner.resDesc.slice(5, 6)}`
             :
             `${winObj.top9} | ${runner.name.slice(7, 8)}: Card ${runner.resDesc.slice(5, 6)}`;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="mulfis-teenpatti-betting">
        <div className="mulfis-teenpatti-betting__games">
          <div className="mulfis-teenpatti-betting__games-item">
            <span
              className={`mulfis-teenpatti-betting__games-item-title ${roundMarkets[0].marketRunner[0].status === 'WINNER' ? 'winnerCup':''}`}>
              Player A
            </span>
            <div className="mulfis-teenpatti-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[0]?.cards.map( card =>
                <img className="mulfis-teenpatti-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
          <div className="mulfis-teenpatti-betting__games-item">
            <span
              className={`mulfis-teenpatti-betting__games-item-title second ${roundMarkets[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}`}>
                Player B
            </span>
            <div className="mulfis-teenpatti-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[1]?.cards.map( card =>
                <img className="mulfis-teenpatti-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
        </div>
        {winnerData && <div className="mulfis-teenpatti-betting__info-column">
          <div className="mulfis-teenpatti-betting__info-row">
            <div className="mulfis-teenpatti-betting__info-row-title">
              <span>Winner:</span>
            </div>
            <div className="mulfis-teenpatti-betting__info-row-results">
              <span>{winnerData['Match Odds'] || 'No Winner'}</span>
            </div>
          </div>
          <div className="mulfis-teenpatti-betting__info-row">
            <div className="mulfis-teenpatti-betting__info-row-title">
              <span>Top 9: </span>
            </div>
            <div className="mulfis-teenpatti-betting__info-row-results">
              <span>{winnerData['top9'] || 'No Winner'}</span>
            </div>
          </div>
          <div className="mulfis-teenpatti-betting__info-row">
            <div className="mulfis-teenpatti-betting__info-row-title">
              <span>M Baccarat: </span>
            </div>
            <div className="mulfis-teenpatti-betting__info-row-results">
              <span className="muflis-teenpatti-betting__info-results-text">
                {winnerData['Muflis Baccarat']} ( A: {roundMarkets[2].marketRunner[0].total} | B: {roundMarkets[2].marketRunner[1].total} )
                </span>
            </div>
          </div>
        </div>}
      </div>

    );
};

export default MulfisTeenpattiPL;

