import { createSlice } from '@reduxjs/toolkit'

export const detailedDepositTransactionSlice = createSlice({
  name: 'detailedDepositTransaction',
  initialState: {},
  reducers: {
    setDetailedDepositTransaction: (state, action) => action.payload,
  },
})

export const { setDetailedDepositTransaction } =
  detailedDepositTransactionSlice.actions

export default detailedDepositTransactionSlice.reducer
