import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: null,
  reducers: {
    getMenuSuccess: (state, action) => action.payload,
    getMenuError: (state) => state,
  },
})

export const { getMenuSuccess, getMenuError } = menuSlice.actions

export default menuSlice.reducer
