import React, { useState } from "react";
import "./bonusmodels.scss";

const BonusConditionModal = ({onDismissed=()=>{},onAgree=()=>{},promo}) =>{
  const [agree,setAgree] = useState(false);

  function createMarkup() {
    try {
      if (typeof promo === "string") {
        return { __html: promo?.replaceAll('\\n', '') }
      } else return { __html: "<div></div>" };
    } catch (e) {
      console.log(e);
    }
  }
  // function createMarkup() {
  //   return { __html: promo?.replaceAll('\\n', '') }
  // }
  return(
    <div className="promo-details-pop-up">
      <div className="promo-details-pop-up-main bonus-modal">
        <div onClick={() => onDismissed()} className="promo-details-pop-up-main-close"></div>
        <div className="promo-details-pop-up-main-content">
          {/*<div className='promo-details-pop-up-main-img'>*/}
          {/*  <img src={promoBonus} alt='promoBonus'></img>*/}
          {/*</div>*/}
          <div className={"bonus-container"}>
            <h3>You are opting for bonus</h3>
            <div>
              <h6>Conditions:</h6>
              <div className={"conditions"}>
                <div dangerouslySetInnerHTML={createMarkup()} />
              </div>
            </div>
            <div>
              <div
                className="bonus-modal__confirm"
                onClick={() => setAgree(!agree)}
              >
                <div
                  className={`bonus-modal__confirm-checkbox ${
                    agree ? 'checked' : ''
                  }`}
                ></div>
                <div className="bonus-modal__confirm-title">
                  I agree to above condition of bonus.
                </div>
              </div>
            </div>
            <div className={"modal-footer"}>
              <button onClick={() => onDismissed()} className={"DesktopBetPlacing__bottomBtns-remove"}>Cancel</button>
              <button onClick={onAgree} disabled={!agree} className={"DesktopBetPlacing__bottomBtns-placeBet placeBet"}>Sumbit</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
 export default BonusConditionModal;
