import "./LeftSideMenu.scss";
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonImg
} from "@ionic/react";
import { connectLeftSideMenu } from "./leftsidemenu.connect";
import leftSideMenuMapper from "../../helpers/getLeftSideMenuMapper.helper";
import { useHistory } from "react-router";
import SlotsgamesIcon from "../../assets/images/sideBarSlotsgames.svg";
import downloadApk from "../../assets/images/menu-icons/apk_icon.svg";
import instaButton from "../../assets/images/socials/instagram.svg";
import { CONFIG } from "../../config/api.config";
import ReactGA from "react-ga4";

const LeftSideMenu = ({ menu, auth, version, actions }) => {
  const history = useHistory();
  const getMapMenu = (menuState) => {

    const collectionPages = Object.assign([], menuState);
    collectionPages.push({
      "type": "EVENT_TYPE",
      "name": "Blastoff",
      "id": "67722",
      "sortOrder": -2,
      "redirect":"/casino_live/67722/FAWK",
      "isSvg": true,
      "children": [
        {
          "children": [],
          "name": "Blastoff",
          "id": "67722",
          "type": "LIVE_CASINO",
          "providerId": 7,
        }
      ]
    });

    return collectionPages.map(menuItem => {
      const menuHelperItem = Object.entries(leftSideMenuMapper).map(([key, value]) => value)
        .find(menuItemWrapper => menuItem.id == menuItemWrapper.id);
      return {
        ...menuItem,
        img: menuHelperItem ? menuHelperItem.img : "",
        isSvg: menuHelperItem ? menuHelperItem.isSvg : "",
        name: menuHelperItem ? menuHelperItem.name : menuItem.name
      };
    }).sort((a, b) => a.sortOrder - b.sortOrder);
  };

  const [menuItemsMap, setMenuItemsMap] = useState([]);

  useEffect(() => {
    setMenuItemsMap(getMapMenu(menu || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  const getMenuItems = () => {
    return menuItemsMap.map((value, index) => {
      return (
        <IonItem className="left-side-menu-item" key={index} onClick={(e) => {

          ReactGA.event({
            label: value.name,
            category: "User-Click",
            action: value.name + " Left_Menu_Click"
          })

          let page;
          if (value.id == "1444000") {
            actions.setSlotCasinoPopUp(true)
            page = "/livecasino-lobby";
          }
          else if (value.id == "1444005") page = `/sportsbook/${value.id}`;
          else if (value.id == "1444001") page = `/exchange-games`;
          else if (value.redirect != undefined) {
            page = value.redirect;
          }
          else page = `/menu-event/${value.id}`;

          if (!page) {
            actions.setLoginNew(true);
            history.push({ pathname: "/default-page" });
          } else {
            history.push({
              pathname: page,
              state: { id: value.id }
            });
          }
        }}>
          {value.isSvg
            ? <IonImg className="left-side-menu-item__icon eventIcon svg" src={value.img} />
            :
            <span className={`left-side-menu-item__icon eventIcon ${value.name.replace(/\s/g, "")}`}>{value.img}</span>
          }
          <IonLabel className="left-side-menu-item__label">{value.name}</IonLabel>
        </IonItem>
      );
    });
  };

  return (
    <IonMenu side="start" id="LeftSide" menuId="LeftSide" contentId="LeftSide" type="push" swipeGesture={false}
             className="left-side-menu">
      <IonContent>
        <IonList className="left-side-menu-list">
          <IonItem className="left-side-menu-item" key="mymarket" onClick={(e) => {
            auth ? history.push("/my_markets/") : actions.setLoginNew(true);
          }}>
            <span className="left-side-menu-item__icon"></span>
            <IonLabel className="left-side-menu-item__label">My markets</IonLabel>
          </IonItem>
          {getMenuItems()}
          <IonItem className="left-side-menu-item" onClick={() => {
            history.push("/slotsgames-lobby")
            actions.setSlotCasinoPopUp(true)
            }}>
            <IonImg className="left-side-menu-item__icon eventIcon svg" src={SlotsgamesIcon} />
            <IonLabel className="left-side-menu-item__label">Slots Games</IonLabel>
          </IonItem>
          {/*<IonItem className="left-side-menu-item" onClick={() => {*/}
          {/*  ReactGA.event({*/}
          {/*    label: "Download_App",*/}
          {/*    category: "User-Click",*/}
          {/*    action: "Left_Menu_Download_App"*/}
          {/*  });*/}
          {/*  window.location.href = CONFIG.downloadAppLink*/}
          {/*}}>*/}
          {/*  <IonImg className="left-side-menu-item__icon eventIcon svg" src={downloadApk} />*/}
          {/*  <IonLabel className="left-side-menu-item__label">Download APK</IonLabel>*/}
          {/*</IonItem>*/}
        </IonList>
        {/*<div className="socials">*/}
        {/*  <IonLabel className="left-side-menu-item__label_Whatsapp">Get Instant ID on whatsapp</IonLabel>*/}
        {/*  <a href={CONFIG.whatsappLink} target={"_blank"}>*/}
        {/*    <div  className="whatsapp-button" />*/}
        {/*  </a>*/}
        {/*  /!*<a href={CONFIG.telegramSupportLink} target={"_blank"}>*!/*/}
        {/*  /!*  <div  className="telegram-support-button" />*!/*/}
        {/*  /!*</a>*!/*/}
        {/*  <a href={`https://instagram.com/_u/${CONFIG.instagram}`} target={"_blank"}>*/}
        {/*    <div   className="instagram-button"  />*/}
        {/*  </a>*/}
        {/*  <a href={`https://telegram.me/${CONFIG.telegram}`} target={"_blank"}>*/}
        {/*    <div  className="telegram-button" />*/}
        {/*  </a>*/}
        {/*  <a href={CONFIG.whatsappSupportLink} target={"_blank"}>*/}
        {/*    <div  className="whatsapp-care-button" />*/}
        {/*  </a>*/}

        {/*</div>*/}


      </IonContent>
    </IonMenu>
  );
};

export default connectLeftSideMenu()(LeftSideMenu);
