  import { createSlice } from '@reduxjs/toolkit'

export const B2CSlice = createSlice({
  name: 'B2C',
  initialState: false,
  reducers: {
    setB2C: (state, action) => action.payload,
  },
})

export const { setB2C } = B2CSlice.actions

export default B2CSlice.reducer
