import React from 'react'
import './AmarAkbarAnthonyCards.scss'
import { connectAmarAkbarAnthony } from '../../AmarAkbarAnthony.connect' 

const AmarAkbarAnthonyCards = ({ exchangeGames }) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

      return (
        <div className='AmarAkbarAnthonyCardsWrapper'>
          <img className="AmarAkbarAnthonyCardsContent-total__cards-card" src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[0])} alt=""/>
        </div>
      )
  }

export default connectAmarAkbarAnthony()(AmarAkbarAnthonyCards)
