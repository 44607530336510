import { createSlice } from '@reduxjs/toolkit'

export const navigationHeaderMenuSlice = createSlice({
    name: 'navigationHeaderMenu',
    initialState: {
        isOpen: false,
        navigationID: '',
        navigationName: '',
        reset: false,
        animated: false,
        moreDetailed: false,
        eventDetailed: false,
        openSport: false,
        detailedGroup: '',
        detailedGroupName: '',
        sportsGroupName: '',
        altName: '',
        animatedText: false,
        searchValue: '',
        renderIonContent: false,
        sportsName: '',
    },
    reducers: {
        setIsNavigationOpen: (state, action) => ({ ...state, isOpen: action.payload }),
        setIsNavigationID: (state, action) => ({ ...state, navigationID: action.payload }),
        setIsNavigationName: (state, action) => ({ ...state, navigationName: action.payload }),
        setIsNavigationReset: (state, action) => ({ ...state, navigationReset: action.payload }),
        setIsAnimatedMenu: (state, action) => ({ ...state, animatedMenu: action.payload }),
        setIsMoreDetailed: (state, action) => ({ ...state, moreDetailed: action.payload }),
        setIsOpenSport: (state, action) => ({ ...state, openSport: action.payload }),
        setIsDetailedGroup: (state, action) => ({ ...state, detailedGroup: action.payload }),
        setIsDetailedGroupName: (state, action) => ({ ...state, detailedGroupName: action.payload }),
        setIsSportsGroupName: (state, action) => ({ ...state, sportsGroupName: action.payload }),
        setIsAltName: (state, action) => ({ ...state, altName: action.payload }),
        setIsEventDetailed: (state, action) => ({ ...state, eventDetailed: action.payload }),
        setIsAnimatedText: (state, action) => ({ ...state, animatedText: action.payload }),
        setSearchValues: (state, action) => ({ ...state, searchValue: action.payload }),
        setRenderIonContent: (state, action) => ({ ...state, renderIonContent: action.payload }),
        setSportsName: (state, action) => ({ ...state, sportsName: action.payload }),


    },
})

export const {
    setIsNavigationOpen,
    setIsNavigationID,
    setIsNavigationName,
    setIsNavigationReset,
    setIsAnimatedMenu,
    setIsMoreDetailed,
    setIsOpenSport,
    setIsDetailedGroup,
    setIsDetailedGroupName,
    setIsSportsGroupName,
    setIsAltName,
    setIsEventDetailed,
    setIsAnimatedText,
    setSearchValues,
    setRenderIonContent,
    setSportsName,
} = navigationHeaderMenuSlice.actions

export default navigationHeaderMenuSlice.reducer
