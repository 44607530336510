import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setBetPlacingPopoverStage, setBtype, setDelay, setEventName, setFromOneClick, setIsBack, setLine, setMarket, setMtype, setPrice, setPriceActual, setRunner, setRunnerName, setShowBetPlacingPopover, setStake} from "../../../store/betPlacing/betPlacing.slice";
import {requestBetPlacingStart} from "../../../store/betPlacing/betPlacing.thunks";
import {requestExchangeGames} from "../../../store/exchangeGames/exchangeGames.thunks";
import {requestLastResultsExch} from "../../../store/lastResultsExch/lastResultsExch.thunks";


export const connectMatka = (wrappedProps) => (component) => {
    function mapStateToProps (state) {
        return {
            sportEvent: state.sportEvent,
            betPlacing: state.betPlacing,
            presetStakeSettings: state.user.settings.presetStakeSettings,
            oneClick: state.user.settings.oneClickSettings.active,
            balanceSettings: state.balanceSettings,
            sportEventStats: state.sportEvent.sportStats,
            exchangeGames: state.exchangeGames,
            exposure: state.eventPage.exposure,
            lastResultsExch: state.lastResultsExch,
            headerHeight: state.elementsParams.headerHeight,
            auth: state.auth,
            elementsParams: state.elementsParams
        };
    }

    function mapDispatchToProps (dispatch) {
        const actions = {
            setMarket,
            setRunner,
            setRunnerName,
            setEventName,
            setIsBack,
            setPrice,
            setPriceActual,
            setDelay,
            setStake,
            setMtype,
            setBtype,
            setLine,
            setShowBetPlacingPopover,
            setBetPlacingPopoverStage,
            setFromOneClick,
            requestBetPlacingStart,
            requestExchangeGames,
        };

        return {actions: bindActionCreators(actions, dispatch)};
    }

    return connect(mapStateToProps, mapDispatchToProps)(component);
};
