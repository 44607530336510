import { createSlice } from '@reduxjs/toolkit'

export const legalAgePopoverSlice = createSlice({
    name: 'legalAgePopoverShow',
    initialState: null,
    reducers: {
        setLegalAgePopoverShow: (state, action) => action.payload,
    },
})

export const {
    setLegalAgePopoverShow,
} = legalAgePopoverSlice.actions

export default legalAgePopoverSlice.reducer
