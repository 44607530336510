import React from 'react'
import "./TeenpattiT20LastResult.scss"

const TeenpattiT20LastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }
  const getWinnerPattern = (data) => {
    const winner = data.marketId.marketRunner.filter((runner) => {
      return runner.status === 'WINNER';
    })
    if (winner.length > 0) {
      return winner[0].patternCode || winner[0].resDesc || '';
    } else {
      return '';
    }
  }

  return (
    <div className={`pop-upLastResult-content__results-teenpattiT20-wrapper`}>


      <div className="pop-upLastResult-content__results-teenpattiT20-items-wrapper">
        <div className={`pop-upLastResult-content__results-teenpattiT20-item ${(data && data?.marketId?.marketRunner[0]?.status === 'WINNER') ? "isWinner" : ""}`}>
          <span className="pop-upLastResult-content__results-name">Player A</span>
          <div className="pop-upLastResult-content__results--cards-inner">
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0].cards[0])}
              alt=""
            />
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0].cards[1])}
              alt=""
            />
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0].cards[2])}
              alt=""
            />
          </div>
        </div>
        <div className={`pop-upLastResult-content__results-teenpattiT20-item ${(data && data?.marketId?.marketRunner[1]?.status === 'WINNER') ? "isWinner" : ""}`}>
          <span className="pop-upLastResult-content__results-name">Player B</span>
          <div className="pop-upLastResult-content__results--cards-inner">
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1].cards[0])}
              alt=""
            />
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1].cards[1])}
              alt=""
            />
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1].cards[2])}
              alt=""
            />
          </div>
        </div>
      </div>
      {data && getWinnerPattern(data) &&
        <div className="pop-upLastResult-content__results-teenpattiT20-winner-pattern">
          <span>{getWinnerPattern(data)}</span>
        </div>
      }
    </div>
  )
}
export default TeenpattiT20LastResult
