import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getTwitterSettingsError, getTwitterSettingsSuccess } from './twitterSettings.slice'

export const requestTwitterSettings = () => async (dispatch) => {
    try {
        const response = await http.get(`/config/twitter-config`)
        if (response.data.success) {
            dispatch(getTwitterSettingsSuccess(response.data.result))
        }
        return response
    } catch (e) {
        dispatch(getTwitterSettingsError())
    //     const errorMsg = getErrorMessage(e)
    //     throw errorMsg
    }
}
