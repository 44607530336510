import React from 'react'
import './TrapCards.scss'
import { useSelector } from 'react-redux'
import { connectTrap } from '../../Trap.connect'
import { useParams } from 'react-router-dom'
import RoundTimer from '../../../helpers/RoundTimer/RoundTimer'


const TrapCards = ({ exchangeGames }) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }



  const dataCardsA = exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards
  const dataCardsB = exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards


  const scoreA1 = Number(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0]?.slice(1)) || 0
  const scoreA2 = Number(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1]?.slice(1)) || 0
  const scoreA3 = Number(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[2]?.slice(1)) || 0
  const scoreA4 = Number(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[3]?.slice(1)) || 0

  const scoreB1 = Number(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0]?.slice(1)) || 0
  const scoreB2 = Number(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1]?.slice(1)) || 0
  const scoreB3 = Number(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[2]?.slice(1)) || 0
  const scoreB4 = Number(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[3]?.slice(1)) || 0


  const playerAscore = [scoreA1, scoreA2, scoreA3, scoreA4]
  const playerBscore = [scoreB1, scoreB2, scoreB3, scoreB4]


  const summArrayElements = (arr) => {
    let x = 0;
    return arr.map(i => x += i, x).reverse()[0]
  }

      return (
        <div className='TrapCardsWrapper'>
        <div className="TrapCardsContent">
          <div className='TrapCardsContent-info'>
            <div className='TrapCardsContent-name'>Player A</div>
            <div className='TrapCardsContent-separation'>|</div>
            <div className='TrapCardsContent-counter'>{summArrayElements(playerAscore)}</div>
          </div>
          <div className="TrapCardsContent-total">
            <div className="TrapCardsContent-total__cards">
              <img
                className="TrapCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0]
                )}
                alt=""
              />
              {dataCardsA?.map((item, index) => {
                if (index > 0){
                return (
                <img
                className="TrapCardsContent-total__cards-card"
                src={cardSrc(item)}
                alt=""
              />
              )}
              })}
            </div>
          </div>
        </div>

        <div className="TrapCardsContent">
          <div className='TrapCardsContent-info'>
            <div className='TrapCardsContent-name'>Player B</div>
            <div className='TrapCardsContent-separation'>|</div>
            <div className='TrapCardsContent-counter'>{summArrayElements(playerBscore)}</div>
          </div>
          <div className="TrapCardsContent-total">
            <div className="TrapCardsContent-total__cards">
              <img
                className="TrapCardsContent-total__cards-card"
                src={cardSrc(
                  exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0]
                )}
                alt=""
              />
              {dataCardsB?.map((item, index) => {
                if (index > 0){
                return (
                <img
                className="TrapCardsContent-total__cards-card"
                src={cardSrc(item)}
                alt=""
              />
              )}
              })}
            </div>
          </div>
        </div>


        </div>
      )
  }


export default connectTrap()(TrapCards)
