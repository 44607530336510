import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestMenuEvent,
        requestMenuEventRace } from '../../store/menuEvent/menuEvent.thunks';
import { getEventSuccess, getEventError } from '../../store/menuEvent/menuEvent.slice';

export const connectMenuEvent = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
        menu: state.menu,
        menuEvent: state.menuEvent,
        elementsParams: state.elementsParams

    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
        requestMenuEvent,
        requestMenuEventRace,
        getEventSuccess,
        getEventError,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}