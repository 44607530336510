import React, { useEffect, useState } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import './LastResults.scss'
import { useSelector } from 'react-redux'
import { connectTwoCardTeenPatti } from '../../TwoCardTeenPatti/TwoCardTeenPatti.connect'
import LastResultPopUp from '../LastResultPopUp/LastResultPopUp'
import { useHistory } from 'react-router'

const LastResults = connectTwoCardTeenPatti()(
  ({
    lastResultsExch,
    actions,
    exchangeGames,
    sportEvent,
    isGame = true
  }) => {
const [data, setData] = useState()
const [isLastResultPopUpOpen, setIsLastResultPopUpOpen] = useState(false)
const history = useHistory();
const eventTypeId = history?.location?.pathname?.split("/")[2];
const eventId = history?.location?.pathname?.split("/")[3];

  // window width
  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth
  // end window width

  useEffect(() => {
    if (eventTypeId === "1444001" && eventId) {
      requestExchInfo(eventId)
      const timer = setIntervalAsync(async() => {
        await actions
                  .requestLastResultsExch(eventId)
                  .catch((e) => console.log('requestInplayPopular error = ', e))
      }, 10000)
      return () => clearIntervalAsync(timer)
    }
  }, [eventTypeId, eventId]);

  const requestExchInfo = (id) => {
    actions
      .requestLastResultsExch(id)
      .catch((e) => console.log('requestInplayPopular error = ', e))
  }

  // get Sicbo point Sum
  const getSicboSum = (diceArray) => {
    let sicboSum = null
    diceArray.map((item) => sicboSum += parseInt(item))
    return sicboSum
  }
  // End get Sicbo point Sum

    if (eventTypeId !== "1444001" || (isGame && width > 1024) || !lastResultsExch || lastResultsExch.length < 1) {
      return <></>
    }

    return (
    <>
      <div className={`resultsList ${!isGame && "not-game"} ${eventId ==="98566" && "sicbo"}`} >
        <ul>
        {lastResultsExch && lastResultsExch.map((item, index) => {
          return <li key={item.marketId._id + index} className={`displayResult ${item?.runnerName} ${eventId && eventId === '98566' && "displayResult-sicbo"}`} onClick = {() => {
            setIsLastResultPopUpOpen(true)
            setData(item)
            }}>
            <div>
              {eventId && eventId !== '98788' && eventId !== '98566' && eventId !== '90100' && eventId !== '90101' && <span>{item.rName}</span>}
              {eventId && eventId === '98788' && <span>{item.marketId.indexCard[0]}</span>}
              {
                eventId && eventId === '98566' &&
                  <div className={`sicbo-lastResult-item`}>
                    <p>{item && item?.marketId && item?.marketId?.indexCard && getSicboSum(item.marketId.indexCard)}</p>
                    <ul>
                      <li className={`sicbo-lastResult-item__point-cell ${(item.marketId.indexCard[0] === item.marketId.indexCard[1]) || (item.marketId.indexCard[0] === item.marketId.indexCard[2]) ? "double-dice" : ""}`}>
                        {item.marketId.indexCard[0]}
                      </li>
                      <li className={`sicbo-lastResult-item__point-cell ${(item.marketId.indexCard[1] === item.marketId.indexCard[0]) || (item.marketId.indexCard[1] === item.marketId.indexCard[2]) ? "double-dice" : ""}`}>
                        {item.marketId.indexCard[1]}
                      </li>
                      <li className={`sicbo-lastResult-item__point-cell ${(item.marketId.indexCard[2] === item.marketId.indexCard[0]) || (item.marketId.indexCard[2] === item.marketId.indexCard[1]) ? "double-dice" : ""}`}>
                        {item.marketId.indexCard[2]}
                      </li>
                    </ul>
                  </div>
              }
              {((eventId && eventId === '90100') || (eventId && eventId === '90101')) && <span className={`displayResult__race20-button`}>{item.rName}</span>}
            </div>
          </li>
        })
        }
        </ul>
      </div>
      <LastResultPopUp
        open={isLastResultPopUpOpen}
        onDidDismiss={() => setIsLastResultPopUpOpen(false)}
        data={data}
        eventName={exchangeGames ? exchangeGames[0]?.matchName : ''}
        exchangeGames={exchangeGames}
      />
    </>
  )
}
)
 export default LastResults
