import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestSportStats } from '../../store/sportEvent/sportEvent.thunks';

export const connectScorecard = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      sportEventStats: state.sportEvent.sportStats,
      sportData: state.sportEvent.sportData,
      raceOdds: state.raceOdds,
      timeSettings: state.timeSettings,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSportStats,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
