import React from 'react'
import './DragonTigerCards.scss'
import { connectDragonTiger } from '../../DragonTiger.connect'



const DragonTigerCards = ({ exchangeGames }) => {

  const cardSrc = (card) => {
    if (card) {
        return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
        return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
};


  return (
      <div className="dragon-tiger-cards">
        <div className="dragon-tiger-cards__player">
          <div className="dragon-tiger-cards__player-title">
            <span>Dragon</span>
          </div>
          <img className="dragon-tiger-cards__player-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0])} alt="lay" />
        </div>
        <div className="dragon-tiger-cards__player">
          <div className="dragon-tiger-cards__player-title">
            <span>Tiger</span>
          </div>
          <img className="dragon-tiger-cards__player-card"
              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0])} alt="back" />
        </div>
      </div>
  )
}

export default connectDragonTiger()(DragonTigerCards)
