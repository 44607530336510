import { createSlice } from '@reduxjs/toolkit'

export const orderListSlice = createSlice({
    name: 'orderList',
    initialState: null,
    reducers: {
        getOrderListSuccess: (state, action) => action.payload,
        getOrderListError: (state) => state,
    },
})

export const { getOrderListSuccess, getOrderListError } = orderListSlice.actions

export default orderListSlice.reducer
