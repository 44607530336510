import React from 'react'
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButton,
  IonIcon } from '@ionic/react'
import './CasinoGames.scss'
import { menuController } from '@ionic/core'
import menuIcon from '../../assets/images/burger-menu.svg'
import { useHistory } from "react-router-dom";
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'


const CasinoGames = () => {
  const history = useHistory()
  const startCasino = () => {
    history.push('/casino_live/1444000')
  }
  return (
    <IonPage className="CasinoGames">
      <IonHeader>
        <IonToolbar className="CasinoGames__toolbar">
          <div className="CasinoGames__toolbar__wrapper">
            <div className="ion-toolbar__left">
              <IonButton
                className="menu-btn"
                mode="md"
                fill="clear"
                onClick={() => {
                  menuController.open('start')
                }}
              >
                <IonIcon className="menu-icon" src={menuIcon} />
              </IonButton>
              <div className="CasinoGames__toolbar__title">Casino</div>
            </div>
            <div className="ion-toolbar__right">
              <IonButton
                className="menu-btn"
                mode="md"
                fill="clear"
                onClick={() => {
                  menuController.open('end')
                }}
              >
                <span className="CasinoGames__right-icon">...</span>
              </IonButton>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="CasinoGames__content">
          <div className="exchange-game" onClick={startCasino}>
            <div className="exchange-game__header">Live Casino</div>
            <div className="exchange-game__body img-live-casino"></div>
          </div>
        </div>
         {/*<FooterDesktop />*/}
      </IonContent>
    </IonPage>
  )
}

export default (CasinoGames)
