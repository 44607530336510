import { createSlice } from '@reduxjs/toolkit'

export const recaptchaSlice = createSlice({
  name: 'recaptcha',
  initialState: '',
  reducers: {
    getRecaptchaSuccess: (state, action) => action.payload,
    
    getRecaptchaError: (state) => null,
  },
})

export const { getRecaptchaSuccess, getRecaptchaError } = recaptchaSlice.actions

export default recaptchaSlice.reducer
