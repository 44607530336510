import { createSlice } from '@reduxjs/toolkit'

export const versionSlice = createSlice({
  name: 'version',
  initialState: '',
  reducers: {
    setVersion: (state, action) => action.payload,
    // setPopUpOpen: (state, action) => ({...state, popUpOpen: action.payload}),

  },
})

export const { setVersion } = versionSlice.actions

export default versionSlice.reducer
