import { createSlice } from '@reduxjs/toolkit'

export const messageSlice = createSlice({
  name: 'message',
  initialState: null,
  reducers: {
    setMessageSuccess: (state, action) =>  action.payload,
    getMessageError: (state) => state,
  },
})

export const { setNotifications, setMessageSuccess, getMessageError } = messageSlice.actions

export default messageSlice.reducer
