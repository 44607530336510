import React from 'react';
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./TeenpattiTest.scss"
import { useSelector } from 'react-redux';
import { connectTeenpattiTest } from './TeenpattiTest.connect';
import { useState, useEffect } from 'react';
// import BetPlacing from '../../BetPlacing/BetPlacing';
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
import LastResults from '../helpers/LastResults/LastResults';
import CardsInfo from '../helpers/CardsInfo/CardsInfo';

const TeenpattiTest = ({
                         betPlacing,
                         actions,
                         exchangeGames,
                         exposure,
                         lastResultsExch,
                         headerHeight,
                         auth,
                         elementsParams
                       }) => {

  const dataOdds = useSelector((state) => state.sportEvent.sportData)
  const dataCards = exchangeGames && exchangeGames[0]?.marketRunner[2]?.cards
  const statusTiger = lastResultsExch && lastResultsExch[0]?.marketId?.marketRunner[0]
  const statusLion = lastResultsExch && lastResultsExch[0]?.marketId?.marketRunner[1]
  const statusDragon = lastResultsExch && lastResultsExch[0]?.marketId?.marketRunner[2]

  const roundID = lastResultsExch && lastResultsExch[0]?.marketId?.roundId
  const roundId = exchangeGames && exchangeGames[0]?.roundId

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const exposurePnl = (id) => {
    if(auth) {
      const objFinder =
        exposure?.pnlBySelection[
          Object.keys(exposure?.pnlBySelection).filter((item) =>
            item.includes(id)
          )
          ]?.pnl
      return (
        <div
          className={
            objFinder > 0
              ? 'exposure-box-teenpattitest exposure-teenpattitest--pos'
              : 'exposure-box-teenpattitest exposure-teenpattitest--neg'
          }
        >
          {exposure?.pnlBySelection[
            Object.keys(exposure?.pnlBySelection).filter((item) =>
              item.includes(id)
            )
            ]?.pnl}
        </div>
      )
    } else return
  }

  const expChecked = (id) => {
    if(auth) {
      const aaa =
        exposure?.pnlBySelection[
          Object.keys(exposure?.pnlBySelection).filter((item) =>
            item.includes(id)
          )
          ]?.pnl
      return aaa
    } else return
  }

  // const exposureName = (id) => {
  //   const objName =
  //     exposure?.pnlBySelection[
  //       Object.keys(exposure?.pnlBySelection).filter((item) =>
  //         item.includes(id)
  //       )
  //     ]?.marketId.slice(36)
  //     console.log('aaaa', objName);
  //   return (
  //     <div></div>
  //   )
  // }

  useEffect(() => {
    const timer = setIntervalAsync(async() => {
      await actions
        .requestExchangeGames('67630')
        .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)

  }, [])

  // const renderBetPlacing = (runner) => {
  //   if (betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }

  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  return (
    <>
      <CardsInfo />
      <div className='teenpattiTestWrapper'>

        {/* <div className="gc-game-title">
              <div className="game-title">Teenpatti Test</div>
              <div className="game-round-id">#{exchangeGames && exchangeGames[0]?.roundId}</div>
          </div> */}
        <div className="teenPattiTest-table">
          {/* <>{width < 1024 ? renderBetPlacing() : ""}</> */}

          <div></div>

          {/* Names */}

          <div className="teenPattiTest-table__column teenPattiTest-table__player-names">
            <div className="teenPattiTest-table__cell teenPattiTest-table__player-name">
              <span className="teenPattiTest-table__title">Tiger</span>
            </div>
            <div className="teenPattiTest-table__cell teenPattiTest-table__player-name">
              <span className="teenPattiTest-table__title">Lion</span>
            </div>
            <div className="teenPattiTest-table__cell teenPattiTest-table__player-name">
              <span className="teenPattiTest-table__title">Dragon</span>
            </div>
          </div>

          {/* Winner */}

          <div className="teenPattiTest-table__column teenPattiTest-table__bets-column">
            <div className="teenPattiTest-table__bets-column-header">
              <span className="teenPattiTest-table__title teenPattiTest-table__bet-title">Winner</span>
            </div>
            <div className="teenPattiTest-table__bets-column-bets-wrapper">

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusTiger?.status === 'WINNER'
              && statusTiger.resDesc === ''
              && dataOdds[0]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[0]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[0].runners[0].id)
                  ? 'blockUp'
                  :  dataOdds[0]?.status ===  'SUSPENDED'
                    ? 'block'
                    :''}`}


                   onClick={
                     dataOdds[0].isBettable
                       ? () => select(dataOdds[0], dataOdds[0]?.runners[0], true)
                       : null
                   }>

                                <span
                                  style = {expChecked(dataOdds[0].runners[0].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[0]?.runners[0]?.back[0]?.price}
                                </span>
                {exposurePnl(dataOdds[0]?.runners[0]?.id)}

              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusLion?.status === 'WINNER'
              && statusLion.resDesc === ''
              && dataOdds[0]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[0]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[0].runners[1].id)
                  ? 'blockUp'
                  : dataOdds[0]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}


                   onClick={
                     dataOdds[0].isBettable
                       ? () => select(dataOdds[0], dataOdds[0]?.runners[1], true)
                       : null
                   }>

                                <span
                                  style = {expChecked(dataOdds[0].runners[1].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[0]?.runners[1]?.back[0]?.price}
                                </span>
                {exposurePnl(dataOdds[0]?.runners[1]?.id)}

              </div>


              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusDragon?.status === 'WINNER'
              && statusDragon.resDesc === ''
              && dataOdds[0]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[0]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[0].runners[2].id)
                  ? 'blockUp'
                  : dataOdds[0]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}



                   onClick={
                     dataOdds[0].isBettable
                       ? () => select(dataOdds[0], dataOdds[0]?.runners[2], true)
                       : null
                   }>

                                <span
                                  style = {expChecked(dataOdds[0].runners[2].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[0]?.runners[2]?.back[0]?.price}
                                </span>
                {exposurePnl(dataOdds[0]?.runners[2]?.id)}


              </div>
            </div>
          </div>
          {/* Pair */}

          <div style={{display: 'none'}} className="teenPattiTest-table__column teenPattiTest-table__bets-column">
            <div className="teenPattiTest-table__bets-column-header">
              <span className="teenPattiTest-table__title teenPattiTest-table__bet-title">Pair</span>
            </div>
            <div className="teenPattiTest-table__bets-column-bets-wrapper">

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusTiger?.status === 'WINNER'
              && statusTiger.resDesc === 'PAIR'
              && dataOdds[1]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[1]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[1].runners[0].id)
                  ? 'blockUp'
                  : dataOdds[1]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[1].isBettable
                       ? () => select(dataOdds[1], dataOdds[1]?.runners[0], true)
                       : null
                   }>
                                <span
                                  style={expChecked(dataOdds[1]?.runners[0].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[1]?.runners[0]?.back[0]?.price || 5}
                                </span>
                {exposurePnl(dataOdds[1]?.runners[0]?.id)}


              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusLion?.status === 'WINNER'
              && statusLion.resDesc === 'PAIR'
              && dataOdds[1]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[1]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[1].runners[1].id)
                  ? 'blockUp'
                  : dataOdds[1]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[1].isBettable
                       ? () => select(dataOdds[1], dataOdds[1]?.runners[1], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[1].runners[1].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[1]?.runners[1]?.back[0]?.price || 5}
                                </span>
                {exposurePnl(dataOdds[1]?.runners[1]?.id)}


              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusDragon?.status === 'WINNER'
              && statusDragon.resDesc === 'PAIR'
              && dataOdds[1]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[1]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[1].runners[2].id)
                  ? 'blockUp'
                  : dataOdds[1]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[1].isBettable
                       ? () => select(dataOdds[1], dataOdds[1]?.runners[2], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[1].runners[2].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[1]?.runners[2]?.back[0]?.price || 5}
                                </span>
                {exposurePnl(dataOdds[1]?.runners[2]?.id)}


              </div>
            </div>
          </div>

          {/* Flush */}

          <div style={{display: 'none'}} className="teenPattiTest-table__column teenPattiTest-table__bets-column">
            <div className="teenPattiTest-table__bets-column-header">
              <span className="teenPattiTest-table__title teenPattiTest-table__bet-title">Flush</span>
            </div>
            <div className="teenPattiTest-table__bets-column-bets-wrapper">

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusTiger?.status === 'WINNER'
              && statusTiger.resDesc === 'COLOR'
              && dataOdds[2]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[2]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[2].runners[0].id)
                  ? 'blockUp'
                  : dataOdds[2]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[2].isBettable
                       ? () => select(dataOdds[2], dataOdds[2]?.runners[0], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[2].runners[0].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[2]?.runners[0]?.back[0]?.price || 12}
                                </span>
                {exposurePnl(dataOdds[2]?.runners[0]?.id)}

              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusLion?.status === 'WINNER'
              && statusLion.resDesc === 'COLOR'
              && dataOdds[2]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[2]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[2].runners[1].id)
                  ? 'blockUp'
                  : dataOdds[2]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[2].isBettable
                       ? () => select(dataOdds[2], dataOdds[2]?.runners[1], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[2].runners[1].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[2]?.runners[1]?.back[0]?.price || 12}
                                </span>
                {exposurePnl(dataOdds[2]?.runners[1]?.id)}

              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusDragon?.status === 'WINNER'
              && statusDragon.resDesc === 'COLOR'
              && dataOdds[2]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[2]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[2].runners[2].id)
                  ? 'blockUp'
                  : dataOdds[2]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[2].isBettable
                       ? () => select(dataOdds[2], dataOdds[2]?.runners[2], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[2].runners[2].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[2]?.runners[2]?.back[0]?.price || 12}
                                </span>
                {exposurePnl(dataOdds[2]?.runners[2]?.id)}

              </div>
            </div>
          </div>

          {/* Straight */}

          <div style={{display: 'none'}} className="teenPattiTest-table__column teenPattiTest-table__bets-column">
            <div className="teenPattiTest-table__bets-column-header">
              <span className="teenPattiTest-table__title teenPattiTest-table__bet-title">Straight</span>
            </div>
            <div className="teenPattiTest-table__bets-column-bets-wrapper">

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusTiger?.status === 'WINNER'
              && statusTiger.resDesc === 'STRAIGHT'
              && dataOdds[3]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[3]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[3].runners[0].id)
                  ? 'blockUp'
                  : dataOdds[0]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[3].isBettable
                       ? () => select(dataOdds[3], dataOdds[3]?.runners[0], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[3].runners[0].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[3]?.runners[0]?.back[0]?.price || 21}
                                </span>
                {exposurePnl(dataOdds[3]?.runners[0]?.id)}

              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusLion?.status === 'WINNER'
              && statusLion.resDesc === 'STRAIGHT'
              && dataOdds[3]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[3]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[3].runners[1].id)
                  ? 'blockUp'
                  : dataOdds[3]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[3].isBettable
                       ? () => select(dataOdds[3], dataOdds[3]?.runners[1], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[3].runners[1].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[3]?.runners[1]?.back[0]?.price || 21}
                                </span>
                {exposurePnl(dataOdds[3]?.runners[1]?.id)}

              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusDragon?.status === 'WINNER'
              && statusDragon.resDesc === 'STRAIGHT'
              && dataOdds[3]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[3]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[3].runners[2].id)
                  ? 'blockUp'
                  : dataOdds[3]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[3].isBettable
                       ? () => select(dataOdds[3], dataOdds[3]?.runners[2], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[3].runners[2].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[3]?.runners[2]?.back[0]?.price || 21}
                                </span>
                {exposurePnl(dataOdds[3]?.runners[2]?.id)}

              </div>
            </div>
          </div>

          {/* Trio */}

          <div style={{display: 'none'}} className="teenPattiTest-table__column teenPattiTest-table__bets-column">
            <div className="teenPattiTest-table__bets-column-header">
              <span className="teenPattiTest-table__title teenPattiTest-table__bet-title"> Trio</span>
            </div>
            <div className="teenPattiTest-table__bets-column-bets-wrapper">

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusTiger?.status === 'WINNER'
              && statusTiger.resDesc === 'TRIO'
              && dataOdds[4]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[4]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[4].runners[0].id)
                  ? 'blockUp'
                  : dataOdds[4]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[4].isBettable
                       ? () => select(dataOdds[4], dataOdds[4]?.runners[0], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[4].runners[0].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[4]?.runners[0]?.back[0]?.price || 126}
                                </span>
                {exposurePnl(dataOdds[4]?.runners[0]?.id)}

              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusLion?.status === 'WINNER'
              && statusLion.resDesc === 'TRIO'
              && dataOdds[4]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[4]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[4].runners[1].id)
                  ? 'blockUp'
                  : dataOdds[4]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[4].isBettable
                       ? () => select(dataOdds[4], dataOdds[4]?.runners[1], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[4].runners[1].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[4]?.runners[1]?.back[0]?.price || 126}
                                </span>
                {exposurePnl(dataOdds[4]?.runners[1]?.id)}

              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusDragon?.status === 'WINNER'
              && statusDragon.resDesc === 'TRIO'
              && dataOdds[4]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[4]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[4].runners[2].id)
                  ? 'blockUp'
                  : dataOdds[4]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[4].isBettable
                       ? () => select(dataOdds[4], dataOdds[4]?.runners[2], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[4].runners[2].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[4]?.runners[2]?.back[0]?.price || 126}
                                </span>
                {exposurePnl(dataOdds[4]?.runners[2]?.id)}

              </div>
            </div>
          </div>

          {/* Straight Flush */}

          <div style={{display: 'none'}} className="teenPattiTest-table__column teenPattiTest-table__bets-column">
            <div className="teenPattiTest-table__bets-column-header">
              <span className="teenPattiTest-table__title teenPattiTest-table__bet-title">Straight Flush</span>
            </div>
            <div className="teenPattiTest-table__bets-column-bets-wrapper">

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusTiger?.status === 'WINNER'
              && statusTiger.resDesc === 'Straight Flush'
              && dataOdds[5]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[5]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[5].runners[0].id)
                  ? 'blockUp'
                  : dataOdds[5]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[5].isBettable
                       ? () => select(dataOdds[5], dataOdds[5]?.runners[0], true)
                       : null
                   }>

                                <span
                                  style = {expChecked(dataOdds[5].runners[0].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[5]?.runners[0]?.back[0]?.price || 151}
                                </span>
                {exposurePnl(dataOdds[5]?.runners[0]?.id)}

              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusLion?.status === 'WINNER'
              && statusLion.resDesc === 'Straight Flush'
              && dataOdds[5]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[5]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[5].runners[1].id)
                  ? 'blockUp'
                  : dataOdds[5]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[5].isBettable
                       ? () => select(dataOdds[5], dataOdds[5]?.runners[1], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[5].runners[1].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[5]?.runners[1]?.back[0]?.price || 151}
                                </span>
                {exposurePnl(dataOdds[5]?.runners[1]?.id)}
              </div>

              <div className={`teenPattiTest-table__cell teenPattiTest-table__bet-cell ${statusDragon?.status === 'WINNER'
              && statusDragon.resDesc === 'Straight Flush'
              && dataOdds[5]?.status ===  'SUSPENDED'
              && dataCards?.length == 3
              && roundID === roundId
                ? 'winnerTPT'
                : dataOdds[5]?.status ===  'SUSPENDED'
                && expChecked(dataOdds[5].runners[2].id)
                  ? 'blockUp'
                  : dataOdds[5]?.status ===  'SUSPENDED'
                    ? 'block'
                    : ''}`}

                   onClick={
                     dataOdds[5].isBettable
                       ? () => select(dataOdds[5], dataOdds[5]?.runners[2], true)
                       : null
                   }>
                                <span
                                  style = {expChecked(dataOdds[5].runners[2].id) ? {marginTop: '-10px'} : {}}
                                  className="teenPattiTest-table__price">
                                  {dataOdds[5]?.runners[2]?.back[0]?.price || 151}
                                </span>
                {exposurePnl(dataOdds[5]?.runners[2]?.id)}
              </div>
            </div>
          </div>
        </div>

        <LastResults/>
      </div>

    </>
  )
}

export default connectTeenpattiTest()(TeenpattiTest)
