import './TransactionPopup.scss'
import React, { useState } from 'react'
import successicon from '../../assets/images/transaction-popup-images/success-icon.svg'
import declinedicon from '../../assets/images/transaction-popup-images/declined-icon.svg'
import closeIcon from '../../assets/images/transaction-popup-images/close-icon.svg'

const TransactionPopupResult = () => {
  const [transactionTrue, setTransactionTrue] = useState(true)
    return (
      <div className="transactionPopupFormContainer transactionPopupResult">
        <div className="transactionPopupFormBox">
          <div className="transactionPopupFormBox__close-button">
            <img  className="transactionPopupFormBox__close-icon" src={closeIcon} alt="" />
          </div>
          <div className={`transactionPopupFormBox__result-icon-wrapper ${transactionTrue ? 'transaction-succesful' : 'transaction-declined'} `}>
            {
              transactionTrue
                ? <img className="transactionPopupFormBox__result-icon success-icon" src={successicon} alt="" />
                : <img className="transactionPopupFormBox__result-icon declined-icon" src={declinedicon} alt="" />
            }
          </div>
          <div className="transactionPopupFormBox__title-wrapper">
            <h2 className="transactionPopupFormBox__title">Deposit  succesful</h2>
            <p className="transactionPopupFormBox__subtitle active">$ 2000.00 were transfer on ypur account/ We wish you successful trading!</p>
          </div>
          <div className="transactionPopupFormBox__form-wrapper">
            <form action="" className="transactionPopupFormBox__form">
              <div className="transactionPopupFormBox__transaction-result-wrapper">
                <span className="transactionPopupFormBox__transaction-result-text result-descr">Withdraw</span>
                <div className="transactionPopupFormBox__transaction-result-line"></div>
                <span className="transactionPopupFormBox__transaction-result-text result-sum active">2000.00</span>
              </div>
              <div className="transactionPopupFormBox__form-buttons-wrapper">
                <button className="transactionPopupFormBox__form-button form-submit-button">
                  <span>Continue</span>
                </button>
                <button onClick={() => {setTransactionTrue(false)}} className="transactionPopupFormBox__form-button form-back-button">
                  <span>Close</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
}

export default TransactionPopupResult;