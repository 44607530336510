import React from 'react'

const AmarAkbarAnthonyRules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          The objective of Amar Akbar Anthony is to predict that the winning
          card of the game round is of which set of cards (Amar, Akbar or
          Anthony). It is played with eight decks of cards that are stored in a
          shuffling machine.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <div>
          <div>
            <ul>
              <li>
                The user can place a bet on either AMAR, AKBAR, ANTHONY, or one
                of side bets.
              </li>
              <li>
                To begin a game round the Dealer deals the first and only card
                of the round.
              </li>
              <li>The dealt card is moved to the winning set.</li>
            </ul>
          </div>
        </div>
        <div>
          <h4>Card Sets</h4>
          <div>
            <ul>
              <li>
                If the card is an ACE, 2, 3, 4, 5 or 6 of any suit then AMAR
                wins.
              </li>
              <li>
                If the card is a 7, 8, 9 or 10 of any suit then AKBAR wins.
              </li>
              <li>
                If the card is a JACK, QUEEN or KING of any suit then ANTHONY
                wins.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h4>Betting Instructions</h4>
          <div>
            <ul>
              <li>
                If a game round is in progress when you enter the game, please
                wait for the next one, and then, place your bets.
              </li>
              <li>After the timer reaches 0, the game round begins.</li>
              <li>
                To place a bet, click on the box, enter the stake amount and
                submit by clicking on Place Bet button.
              </li>
              <li>
                You can place several bets on different runners in different
                markets simultaneously.
              </li>
              <li>
                The timer shows how much time you have left to place your bets.
              </li>
              <li>
                Winnings are paid for the winning bets at the end of each game
                round.
              </li>
              <li>To skip a turn, simply do not place any bets.</li>
            </ul>
          </div>
        </div>
        <div>
          <h4>Main Bets</h4>
          <div>
            <ul>
              <li>Amar</li>
              <li>Akbar</li>
              <li>Anthony</li>
            </ul>
          </div>
        </div>
        <div>
          <h4>Side Bets</h4>
          <div>
            <ul>
              <li>Odd</li>
              <li>Even</li>
              <li>Red</li>
              <li>Black</li>
              <li>Under 7</li>
              <li>Over 7</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h3>Payout</h3>
        <p>Payout depends on the type of bet placed.</p>
        <table>
          <tbody>
            <tr>
              <th>
                <b>Odd</b>
              </th>
              <th>1: 0.8</th>
            </tr>
            <tr>
              <th>
                <b>Even</b>
              </th>
              <th>1: 1.1</th>
            </tr>
            <tr>
              <th>
                <b>Black</b>
              </th>
              <th>1: 0.97</th>
            </tr>
            <tr>
              <th>
                <b>Red</b>
              </th>
              <th>1: 0.97</th>
            </tr>
            <tr>
              <th>
                <b>Under 7</b>
              </th>
              <th>1: 1</th>
            </tr>
            <tr>
              <th>
                <b>Over 7</b>
              </th>
              <th>1: 1</th>
            </tr>
          </tbody>
        </table>
        <p>
          Please note that any malfunction voids the game round and all eventual
          payouts for the round. Bets will be returned.
        </p>
      </div>
    </div>
  )
}
export default AmarAkbarAnthonyRules
