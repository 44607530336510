import "./CardsCasinoPL.scss";
import React, { useEffect, useState } from 'react'
import trophy from "../../../assets/images/TrophyTeenPattiTest.svg"

const CardsCasinoPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {
   };
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);


  const getSumOfCards = (cards, bonusScore) => {
    let sum = 0;
    for (const card of cards) {
      sum += parseInt(card.substring(1));
    }
    if (bonusScore) {
      sum = sum + bonusScore;
      return sum;
    } else {
      return sum;
    }
  }

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="cards32-casino-betting">
        <div className="cards32-casino-betting__games">
          <div className="cards32-casino-betting__games-item">
            <p className="cards32-casino-betting__games-item-title">
              Player 8 -
              <span className="points-value">{ getSumOfCards(roundMarkets && roundMarkets[0]?.marketRunner[0].cards, 8)}</span>
            </p>
            <div className="cards32-casino-betting__games-item-cards">
              {roundMarkets && roundMarkets[0]?.marketRunner[0].cards.map( card =>
                  <img key={card}
                       src={cardSrc(card)}
                       alt={card}
                  />)
              }
              {roundMarkets[0]?.marketRunner[0].status === 'WINNER' &&
                <img className="winner" src={trophy} alt={'cup'} />
              }
            </div>
          </div>
          <div className="cards32-casino-betting__games-item">
            <p className="cards32-casino-betting__games-item-title">
              Player 9 -
              <span className="points-value">{ getSumOfCards( roundMarkets && roundMarkets[0].marketRunner[1].cards, 9)}</span>
            </p>
            <div
              className="cards32-casino-betting__games-item-cards {{bettingData[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}}">
              {roundMarkets && roundMarkets[0].marketRunner[1].cards.map( card =>
                <img key={card}
                     src={cardSrc(card)}
                     alt={card}
                />)
              }
              {roundMarkets[0]?.marketRunner[1].status === 'WINNER' && <img className="winner" src={trophy} alt={'cup'} />}
            </div>
          </div>
          <div className="cards32-casino-betting__games-item">
            <p className="cards32-casino-betting__games-item-title">
              Player 10 -
              <span className="points-value">{ getSumOfCards( roundMarkets && roundMarkets[0].marketRunner[2].cards, 10)}</span>
            </p>
            <div
              className="cards32-casino-betting__games-item-cards {{bettingData[0].marketRunner[2].status === 'WINNER' ? 'winnerCup':''}}">
              {roundMarkets && roundMarkets[0].marketRunner[2].cards.map( card =>
                <img key={card}
                     src={cardSrc(card)}
                     alt={card}
                />)
              }
              {roundMarkets[0]?.marketRunner[2].status === 'WINNER' && <img className="winner" src={trophy} alt={'cup'} />}
            </div>
          </div>
          <div className="cards32-casino-betting__games-item">
            <p className="cards32-casino-betting__games-item-title">
              Player 11 -
              <span className="points-value">{ getSumOfCards( roundMarkets && roundMarkets[0].marketRunner[3].cards, 11)}</span>
            </p>
            <div
              className="cards32-casino-betting__games-item-cards {{bettingData[0].marketRunner[3].status === 'WINNER' ? 'winnerCup':''}}">
              {roundMarkets && roundMarkets[0].marketRunner[3].cards.map( card =>
                <img key={card}
                     src={cardSrc(card)}
                     alt={card}
                />)}
              {roundMarkets[0]?.marketRunner[3].status === 'WINNER' && <img className="winner" src={trophy} alt={'cup'} />}
            </div>
          </div>
        </div>
        <div className="cards32-casino-betting__info-column">
          <div className="cards32-casino-betting__info-row">
            <div className="cards32-casino-betting__info-row-title">
              <span>Winner:</span>
            </div>
            <div className="cards32-casino-betting__info-row-results">
              <span>{winnerData && winnerData['Match odds']  || 'No Winner'}</span>
            </div>
          </div>
        </div>
      </div>
    );
};

export default CardsCasinoPL;

