import './pop-upDelete.scss'
// import { IonButton, IonImg, IonPopover } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setDetailedDepositTransaction } from '../../../store/detailedDepositTransaction/detailedDepositTransaction.slice'
import { useHistory } from 'react-router'
import { BASE_DEV_URL } from '../../../config/api.config'
import getErrorMessage from '../../../helpers/getErrorMessage.helper'

const PopUPDelete = ({ content, open, onDidDismiss, data, update }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const token = localStorage.getItem('id_token')


  const deleteWithdrawalMethod = async () => {
    return await axios.delete(BASE_DEV_URL + `/payments/userid/delete-bank?accountId=${data}`,
    {
      headers: {
        'Authorization': `${token}`
      }
    })
    .then(response => update())
    .then(onDidDismiss(false))
    .catch((e) => {
      // const errorMsg = getErrorMessage(e)
            // throw errorMsg
    })
  }
  
  // const [remark, setRemark] = useState('')
  // const requestId = data.requestId
  // const [utr, setUTR] = useState(data.utr)
  // const [amount, setAmount] = useState(data.amount)

  // console.log('data', data)
  // console.log('requestId', data.requestId)
  // console.log('utr', data.utr)
  // console.log('amount', data.amount)

  // console.log(parseInt('222', 10))

  // const canceledData = {
  //   remark,
  //   requestId,
  // }

  // const editedData = {
  //   requestId,
  //   UTR: utr,
  //   amount,
  // }

  // const cancelOneTransactionRequest = async () => {
  //   return await axios
  //     .post(
  //       `https://dapi.gemexch.bet/api/payments/userid/cancel-request?requestId=${data.requestId}`,
  //       canceledData,
  //       {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => console.log(response.data.result))
  //     .then(history.push('/transactions'))
  // }

  // const editOneTransactionRequest = async () => {
  //   return await axios
  //     .post(
  //       `https://dapi.gemexch.bet/api/payments/userid/update-deposit-request?requestId=${data.requestId}`,
  //       editedData,
  //       {
  //         headers: {
  //           Authorization: `${token}`,
  //         },
  //       }
  //     )
  //     .then((response) => console.log(response.data.result))
  //     .then(onDidDismiss(false))
  // }

  return (
    <>
      
        { open && <div className="editPopUpDelete">
          <div
            className="pop-upDelete_wrapper"
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                onDidDismiss(false)
              }
            }}
          >
            <div className= "pop-upDelete">
              <div className="pop-upDelete-content">
                <div className="pop-upDelete-content_title">
                Are you sure you want to delete your account?
                </div>
              </div>

              <div className="pop-upDelete-content__buttons">
                <div
                className="pop-upDelete-content__buttons-delete"
                onClick={() => {
                  deleteWithdrawalMethod()
                }}>
                Delete</div>

                <div
                  className="pop-upDelete-content__buttons-cancel"
                  onClick={() => onDidDismiss(false)}>
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default PopUPDelete
