import './BetPlacing.scss'
import React, { useContext, useEffect, useState } from 'react'
import { IonToggle, IonButton, IonInput } from "@ionic/react";
import { connectBetPlacing } from './BetPlacing.connect'
import { toastr } from 'react-redux-toastr'
import { BetPopoverContext } from '../../context'
import { getPlacingText } from '../../helpers/betPlacing.helper'
import { useHistory, useParams } from 'react-router-dom'
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat.helper'

const BetPlacing = ({ betPlacing, presetStakeSettings, actions, oneClick, headerHeight, sportEvent, auth, messages, notifications, heightForBetslip}) => {
    const cancelBetPopover = useContext(BetPopoverContext);
    const [incremented, setIncremented] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false)
    const [styles, setStyles] = useState({ transform: 'translateY(-100%)' });
    const history = useHistory()
    const { eventTypeId } = useParams();

    let dataOdd = sportEvent?.sportData

    useEffect(() => {
        setTimeout(() => setStyles({ transform: 'translateY(0px)' }), 200)
    }, [])

    const profit = () => {
        const type = betPlacing.isBack ? 'Profit' : 'Liability'
        const isFancy = betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE'
        const stake = betPlacing.stake ? (isFancy ? getCurrencyFormat(betPlacing.stake * betPlacing.priceActual / 100) : getCurrencyFormat(betPlacing.stake * betPlacing.priceActual - betPlacing.stake)) : '0.00'
        return `${type}: ${stake}`
    }
    const cancel = () => {
        setStyles({ transform: 'translateY(-100%)', transition: '300ms ease' })

        setTimeout(() => {
            actions.setMarket(null)
            actions.setRunner(null)
            actions.setIsBack(null)
            actions.setPrice(null)
            actions.setPriceActual(null)
            actions.setStake(null)
        }, 300)
    }

    //close betslip if selected market is closed
    useEffect(() => {
        if( dataOdd && dataOdd[0]?.competition?.name === 'Live Games' && !dataOdd.filter((item) => item.id === betPlacing?.market)[0]?.isBettable ) {
            // cancel()
        }
    }, [dataOdd])

    // console.log('betPlacing', betPlacing);
    // console.log('params?.eventTypeId',eventTypeId);
    // console.log('betPlacing.eventTypeId', betPlacing.eventTypeId);


    //close betslip if selected market is closed
    const submit = async () => {
        if (betPlacing.confirmation) actions.setBetPlacingPopoverStage(false)
        else {
            actions.setBetPlacingPopoverStage(true)
            actions.requestSetOrder(betPlacing.eventTypeId ? betPlacing : {...betPlacing, eventTypeId: eventTypeId})
                .then((res) => {
                    const text = getPlacingText(betPlacing, res)
                    if (text) {
                        text.indexOf('Unmatched') === -1 ? toastr.success('', text) : toastr.error('', text)
                      }
                    cancelBetPopover()
                    // actions.setShowBetPlacingPopover(false)
                })
                .catch((e) => {
                    cancelBetPopover()
                    // actions.setShowBetPlacingPopover(false)
                    if (e[0] && e[0][0] && e[0][0].description) toastr.error('', e[0][0].description)
                    else toastr.error('', 'Something went wrong');
                })
        }
        actions.setShowBetPlacingPopover(true)
    }

    // const submit = async () => {
    //         console.log("submit")
    //         setShowConfirm(false)
    //         setIsLoading(true)
    //         actions.setBetPlacingPopoverStage(true)
    //         actions.requestSetOrder(betPlacing.eventTypeId ? betPlacing : {...betPlacing, eventTypeId: params?.eventTypeId})
    //             .then((res) => {
    //                 const text = getPlacingText(betPlacing, res)
    //                 if (text) {
    //                     text.indexOf('Unmatched') === -1 ? toastr.success('', text) : toastr.error('', text)
    //                   }
    //                 cancelBetPopover()
    //                 setIsLoading(false)
    //             })
    //             .catch((e) => {
    //                 cancelBetPopover()
    //                 // actions.setShowBetPlacingPopover(false)
    //                 if (e[0] && e[0][0] && e[0][0].description) toastr.error('', e[0][0].description)
    //                 else toastr.error('', 'Something went wrong');
    //                 setIsLoading(false)
    //             })
    //     actions.setShowBetPlacingPopover(true)
    // }

    const submitHandler = (isPopUp = false) => {
        if (isPopUp) return  submit();
        betPlacing.confirmation ? setShowConfirm(true) : submit();
    };

    const ripple = (event) => {
        const elem = event.currentTarget

        var rect = event.target.getBoundingClientRect();
        let x = event.clientX - elem.offsetLeft
        let y = rect.height - rect.bottom + event.clientY
        let rippleElement = document.createElement('span')
        rippleElement.style.left = x + 'px'
        rippleElement.style.top = y + 'px'
        elem.appendChild(rippleElement)
        setTimeout(function () {
            rippleElement.remove();
        }, 500)
    }

    const changeOddRipple = (event, mod, isChanging = 1) => {
        const ev = {...event}
        ripple(event)
        changeOdd(mod, isChanging)
    }

    const changeOdd = (mod, isChanging = 1) => {
        let step, dec
        const odd = Number(betPlacing.priceActual)
        switch (true) {
            case (odd < 2): {
                step = 0.01
                dec = 2
                break
            }
            case (odd < 3): {
                step = 0.02
                dec = 2
                break
            }
            case (odd < 4): {
                step = 0.05
                dec = 2
                break
            }
            case (odd < 6): {
                step = 0.1
                dec = 2
                break
            }
            case (odd < 10): {
                step = 0.2
                dec = 2
                break
            }
            case (odd < 20): {
                step = 0.5
                dec = 1
                break
            }
            case (odd < 30): {
                step = 1
                dec = 0
                break
            }
            case (odd < 50): {
                step = 2
                dec = 0
                break
            }
            case (odd < 100): {
                step = 5
                dec = 0
                break
            }
            default: {
                step = 10
                dec = 0
            }
        }
        let newVal = +(odd + step * mod * isChanging).toFixed(2)
        if (newVal < 1.01) newVal = 1.01
        else if (newVal > 1000) newVal = 1000
        else if (!Number.isInteger(newVal / step)) newVal = newVal - newVal % step + step
        newVal = Number(newVal.toFixed(dec))
        actions.setPriceActual(newVal)
    }
    // const renderOdd = () => {
    //     if (betPlacing.btype == 'innings_runs' && betPlacing.btype == 'line') return <div>{betPlacing.price}</div>
    //     return <>
    //         <IonButton className="BetPlacing__changeodd BetPlacing__changeodd--minus" onClick={() => changeOdd(-1)}><div className="BetPlacing__changeodd__text">-</div></IonButton>
    //         <IonInput type="number" className="BetPlacing__input BetPlacing__odd__input" value={betPlacing.price} onIonChange={e => actions.setPrice(e.detail.value)} onBlur={() => changeOdd(1, 0)}></IonInput>
    //         <IonButton className="BetPlacing__changeodd BetPlacing__changeodd--plus" onClick={() => changeOdd(1)}><div className="BetPlacing__changeodd__text">+</div></IonButton>
    //     </>
    // }


    const getPriseFor_SB = (marketId, price) => {
        return (marketId && marketId.slice(marketId.length - 3) === '_SB' ? ((price - 1) * 100).toFixed(2).replace('.00', '') : price);
    }

    // another variant
    const renderOdd = () => {
        if (betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE') return <div className="BetPlacing__odd__text BetPlacing__odd__text--small">
            <div className="small1">{betPlacing.priceActual}</div>
            <div className="small2">{betPlacing.line}</div>
        </div>
        if (betPlacing.mtype == 'MATCH_ODDS_SB' || betPlacing.mtype == 'BOOKMAKER') return <div className="BetPlacing__odd__text">{getPriseFor_SB( betPlacing.market, betPlacing.priceActual )}</div>
        return <>
            <button className="BetPlacing__changeodd BetPlacing__changeodd--minus" onClick={(event) => changeOddRipple(event, -1)} mode="md"><div className="BetPlacing__changeodd__text">-</div></button>
            <IonInput type="number" className="BetPlacing__input BetPlacing__odd__input" value={betPlacing.priceActual} onIonChange={e => {
                actions.setPriceActual(e.detail.value)
                }} onBlur={() => changeOdd(1, 0)}></IonInput>
            <button className="BetPlacing__changeodd BetPlacing__changeodd--plus" onClick={(event) => changeOddRipple(event, 1)} mode="md"><div className="BetPlacing__changeodd__text">+</div></button>
        </>
    }

    const renderOddTitle = () => {
        if (betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE') return <div className="BetPlacing__row BetPlacing__row f10">
            <div className="small1">ODDS (H-J)</div>
            <div className="small2">RUNS</div>
        </div>
        return <div className="BetPlacing__row f10">ODDS</div>
    }

    useEffect(() => {
        if (incremented) setTimeout(() => setIncremented(false), 300)
    }, [incremented])

    const increment = (i) => {
        setIncremented(true)
        actions.setStake((betPlacing.stake ? Number(betPlacing.stake) : 0) + presetStakeSettings.mobileStakesSettings[i])
    }

    const incrementNotAuth = (i) => {
        setIncremented(true)
        actions.setStake((betPlacing.stake ? Number(betPlacing.stake) : 0) + i)
    }

    return (
        oneClick
            ? null
            :
            <div
                // style={history.location.pathname.includes('exchange-game-page') && {position: 'fixed'}}
                className={`BetPlacing-container__wrapper ${history.location.pathname.includes('exchange-game-page') ? 'fixedPosition' : ''}  ${(messages && messages.length > 0 && notifications !== false) ? "notifications" : ''} `}>
                <div className={`BetPlacing-container ${showConfirm ? "confirm" : ""}`}>
                <div className={`BetPlacing ${betPlacing?.isBack ? 'BetPlacing--back' : 'BetPlacing--lay'}`} style={styles}>
                    <div className="BetPlacing__row">
                        <div className={`BetPlacing__col ${betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE' ? 'BetPlacing__col--small' : ''}`}>
                            {renderOddTitle()}
                            <div className="BetPlacing__row">
                                <div className="BetPlacing__odd">
                                    {renderOdd()}
                                </div>
                            </div>
                        </div>
                        <div className={`BetPlacing__col ${betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE' || true ? 'BetPlacing__col--large' : ''}`}>
                            <div className="BetPlacing__row f10">
                                <div>STAKE</div>
                                <div>Max Mkt: {getCurrencyFormat(betPlacing?.maxWinPerMarket) || '5,00,000'}</div>
                            </div>
                            <div className="BetPlacing__row">
                                <div className="BetPlacing__stake">
                                    <IonInput type="number" inputmode="decimal"
                                        className={`BetPlacing__input BetPlacing__odd__input BetPlacing__odd__input--rounded ${incremented ? 'incremented' : ''}`}
                                        placeholder={`Max: ${getCurrencyFormat(betPlacing?.maxBet) || ''}`} value={betPlacing?.stake}
                                        onIonChange={(e) => {
                                            if(auth) {
                                                actions.setStake(String(e.target.value).replace(/^([0-9]*[.,][0-9]{2})([0-9]+)$/, '$1'))
                                            } else {
                                                if(Number(e.target.value) > 500000) {
                                                    actions.setStake(String(500000).replace(/^([0-9]*[.,][0-9]{2})([0-9]+)$/, '$1'))
                                                } else {
                                                    actions.setStake(String(e.target.value).replace(/^([0-9]*[.,][0-9]{2})([0-9]+)$/, '$1'))
                                                }
                                            } }
                                        }
                                    ></IonInput>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="BetPlacing__row BetPlacing__row--actions">
                        <IonButton className="BetPlacing__addstake" shape="round" mode="md" onClick={() => auth ? increment(0): incrementNotAuth(200)}>
                            +{presetStakeSettings && presetStakeSettings.mobileStakesSettings ? getCurrencyFormat(presetStakeSettings.mobileStakesSettings[0]) : 200}
                        </IonButton>
                        <IonButton className="BetPlacing__addstake" shape="round" mode="md" onClick={() => auth ? increment(1): incrementNotAuth(1000)}>
                            +{presetStakeSettings && presetStakeSettings.mobileStakesSettings ? getCurrencyFormat(presetStakeSettings.mobileStakesSettings[1]) : "1.000"}
                        </IonButton>
                        <IonButton className="BetPlacing__addstake" shape="round" mode="md" onClick={() => auth ? increment(2): incrementNotAuth(2000)}>
                            +{presetStakeSettings && presetStakeSettings.mobileStakesSettings ? getCurrencyFormat(presetStakeSettings.mobileStakesSettings[2]) : "2.000"}
                        </IonButton>
                    </div>
                    {(betPlacing.priceActual !== betPlacing.price && (betPlacing.mtype == 'MATCH_ODDS_SB' || betPlacing.mtype == 'BOOKMAKER' || betPlacing.mtype == 'INNINGS_RUNS')) &&
                        <div className="BetPlacing__row BetPlacing__row-oddchanged">The Odds of your selection have changed</div>
                    }
                    <div className="BetPlacing__row BetPlacing__row--actions">
                        <div className="BetPlacing__col">
                            <IonButton
                                className="BetPlacing__btn BetPlacing__btn--cancel"
                                expand="full"
                                mode="md"
                                shape="round"
                                type="reset"
                                onClick={cancel}
                            >
                                Cancel
                            </IonButton>
                        </div>
                        <div className="BetPlacing__col">
                            {auth ? (
                                <button
                                className="BetPlacing__btn BetPlacing__btn--place nb"
                                expand="full"
                                mode="md"
                                disabled={Number(betPlacing.stake) <= 0 || Number(betPlacing.priceActual) < 1.01 || isLoading}
                                type="submit"
                                onClick={submit}
                            >
                                <div className="BetPlacing__col">
                                    <div>Place Bet</div>
                                    <div className="BetPlacing__profit">{profit()}</div>
                                </div>
                                <div className="BetPlacing__delay">
                                    <i className="apl-icon-info-stopwatch" title="delay"></i>
                                    {betPlacing.delay}s
                                </div>
                            </button>
                            ) : (
                                <button
                                className="BetPlacing__btn BetPlacing__btn--place nb"
                                expand="full"
                                mode="md"
                                type="submit"
                                onClick={() => {actions.setLoginNew(true)}}
                                >
                                    <div className="BetPlacing__col">
                                        <div>Log In</div>

                                    </div>

                                </button>
                            )}
                        </div>
                    </div>
                    <div className="BetPlacing__row BetPlacing__row--actions">
                        <div>Confirm bet before placing</div>
                        <IonToggle className="oneClickToggle" checked={betPlacing.confirmation} onIonChange={() => actions.setConfirmation(!betPlacing.confirmation)} />
                    </div>
                </div>
                
                {/* {showConfirm &&
                  <div className="BetPlacing-confirm">
                    <div className="BetPlacing-confirm__pop-up">
                        <div className="BetPlacing-confirm__pop-up__name">
                            Are you sure you want to place your bet?
                        </div>
                        <div className="BetPlacing-confirm__pop-up__buttons">
                            <div className="BetPlacing-confirm__pop-up__buttons-cancel" onClick={() => {setShowConfirm(false); cancel() }}>
                                Cancel
                            </div>
                            <button
                              type="submit"
                              onClick={() => submitHandler(true)}
                              className="BetPlacing-confirm__pop-up__buttons-submit"
                              expand="full"
                              mode="md"
                            >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">Confirm</span>
                            </button>
                        </div>
                    </div>
                 </div>
                } */}
            </div>
            </div>
    );
}

export default connectBetPlacing()(BetPlacing)
