import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestPromotions } from '../../store/promotions/promotions.thunks'

export const connectPromotions = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      promotions: state.promotions,
      auth: state.auth
      // inplayPopular: state.inplayPopular
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestPromotions
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}