import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router"
import Slider from "react-slick";
import { setLoginNew } from '../../../store/LoginNew/loginNew.slice'
import './MostPopularSlider.scss'

const MostPopularSlider = ({banners}) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover:false,
  };

  let bannerList = banners.map((item) => {
    return (<div className='mostPopularSlider-item' key={item.index} >
      <div className="mostPopularSlider-item__img"
        style={{ backgroundImage: `url(${item.image})`, backgroundColor: item.backgroundColour}}
        onClick={() => {
          if(auth) {
            history.push(item.redirect)
          } else {
            dispatch(setLoginNew(true))
          }
          }
        }></div>
    </div>)
  })

  return (
    <Slider {...settings}>
      {bannerList}
    </Slider>
  );
}


export default MostPopularSlider