import "./SicboCards.scss"
import React from 'react'
import { connectSicbo } from '../../Sicbo.connect'

import onePointDice from "../../../../../assets/images/exchangeGames/sicbo/one-point-dice.svg"
import twoPointDice from "../../../../../assets/images/exchangeGames/sicbo/two-point-dice.svg"
import threePointDice from "../../../../../assets/images/exchangeGames/sicbo/three-point-dice.svg"
import fourPointDice from "../../../../../assets/images/exchangeGames/sicbo/four-point-dice.svg"
import fivePointDice from "../../../../../assets/images/exchangeGames/sicbo/five-point-dice.svg"
import sixPointDice from "../../../../../assets/images/exchangeGames/sicbo/six-point-dice.svg"

const sicboCards = ({ exchangeGames}) => {

  const cardSrc = (card) => {
    if (card === '1') {
      return onePointDice
    }
    if (card === '2') {
      return twoPointDice
    }
    if (card === '3') {
      return threePointDice
    }
    if (card === '4') {
      return fourPointDice
    }
    if (card === '5') {
      return fivePointDice
    }
    if (card === '6') {
      return sixPointDice
    }
  }

  return (
    <div className="sicboCardsContent">
      <div className="sicboCardsContent-total">
        {exchangeGames && exchangeGames[0]?.indexCard[0] && <img className="sicboCardsContent-total__card" src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[0])} alt=""/>}
        {exchangeGames && exchangeGames[0]?.indexCard[1] && <img className="sicboCardsContent-total__card" src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[1])} alt=""/>}
        {exchangeGames && exchangeGames[0]?.indexCard[2] && <img className="sicboCardsContent-total__card" src={cardSrc(exchangeGames && exchangeGames[0]?.indexCard[2])} alt=""/>}
      </div>
    </div>
  )
}


export default connectSicbo()(sicboCards)
