import React from 'react';
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./Trio.scss"
import { useSelector } from 'react-redux';
import { connectTrio } from './Trio.connect';
import { useState, useEffect } from 'react';
// import BetPlacing from '../../BetPlacing/BetPlacing';
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
import LastResults from '../helpers/LastResults/LastResults';
import CardsInfo from '../helpers/CardsInfo/CardsInfo';

const Trio = ({
  betPlacing,
  actions,
  exposure,
  headerHeight,
  auth,
  exchangeGames,
  elementsParams
 }) => {

  const dataOdds = useSelector((state) => state.sportEvent.sportData)

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth


  const exposurePnl = (id) => {
    if(auth) {
      const objFinder =
      exposure?.pnlBySelection[
        Object.keys(exposure?.pnlBySelection).filter((item) =>
          item.includes(id)
        )
      ]?.pnl
      return (
        <div
          className={
            objFinder > 0
              ? 'exposure-box-modern exposure-modern--pos'
              : 'exposure-box-modern exposure-modern--neg'
          }
        >
          {exposure?.pnlBySelection[
            Object.keys(exposure?.pnlBySelection).filter((item) =>
              item.includes(id)
            )
          ]?.pnl}
        </div>
      )
    }
  }

  const exposureTrue = (id) => {
    if(auth) {
      const objFinder =
      exposure?.pnlBySelection[
        Object.keys(exposure?.pnlBySelection).filter((item) =>
          item.includes(id)
        )
      ]?.pnl
      return objFinder
    }
  }

  useEffect(() => {
      const timer = setIntervalAsync(async() => {
        await actions
            .requestExchangeGames('67610')
            .catch((e) => console.log('requestInplayPopular error = ', e))
      }, GET_INPLAY_POPULAR_INTERVAL)
      return () => clearIntervalAsync(timer)

  }, [])

  // const renderBetPlacing = (runner) => {
  //   if (betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }

  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }
  return (
    <>
      <CardsInfo />
        <div className='queenWrapper'>
          {/* <div className="gc-game-title">
              <div className="game-title">Trio</div>
              <div className="trioGame-round-id">#{exchangeGames && exchangeGames[0]?.roundId}</div>
          </div> */}
          <div className="trio-table">
             {/* <>{width < 1024 ? renderBetPlacing() : ""}</> */}
             <div class="trio-table__body">
                        <div class="trio-table__row-margin first-row">
                            <div class="trio-table__row trio-table__body-row">

                              {/*<div class="trio-table__item trio-table__body-item">*/}
                              {/*      <div class="trio-table__item-header">*/}
                              {/*          <span className='trio-table__item-header__betname'>21 No</span>*/}
                              {/*          { width < 1024 ?*/}
                              {/*          <div className='exposure'>*/}
                              {/*            {exposurePnl(dataOdds[0].runners[0].id)}*/}
                              {/*          </div>*/}
                              {/*          : null}*/}
                              {/*      </div>*/}
                              {/*      <div */}
                              {/*        style={exposureTrue(dataOdds[0].runners[0].id) && width > 1024*/}
                              {/*          ? {height: '100px'}*/}
                              {/*          : {} }*/}
                              {/*        class="trio-table__item-bets-wrapper">*/}
                              {/*          <div class={`trio-table__item-bet-cell ${dataOdds[0]?.status ===  'SUSPENDED' ? */}
                              {/*            'backSuspended' : 'bet-back'}`}*/}
                              {/*              onClick={*/}
                              {/*              dataOdds[0].isBettable*/}
                              {/*                ? () => select(dataOdds[0], dataOdds[0]?.runners[0], true)*/}
                              {/*                : null*/}
                              {/*            }*/}
                              {/*            >*/}
                              {/*              <span class="trio-table__item-bet-cell-price">*/}
                              {/*                {dataOdds[0]?.runners[0]?.back[0]?.price || 0}*/}
                              {/*              </span>*/}
                              {/*          </div>*/}
                              {/*          <div class={`trio-table__item-bet-cell ${dataOdds[0]?.status ===  'SUSPENDED' ? */}
                              {/*            'laySuspended' : 'bet-lay'}`}*/}
                              {/*              onClick={*/}
                              {/*              dataOdds[0].isBettable*/}
                              {/*                ? () => select(dataOdds[0], dataOdds[0]?.runners[0], false)*/}
                              {/*                : null*/}
                              {/*            }*/}
                              {/*            >*/}
                              {/*              <span class="trio-table__item-bet-cell-price">*/}
                              {/*                {dataOdds[0]?.runners[0]?.lay[0]?.price || 0}*/}
                              {/*              </span>*/}
                              {/*          </div>*/}
                              {/*          { width > 1024 ?*/}
                              {/*          <div className='exposure'>*/}
                              {/*            {exposurePnl(dataOdds[0].runners[0].id)}*/}
                              {/*          </div>*/}
                              {/*          : null}*/}
                              {/*      </div>*/}
                              {/*  </div>*/}

                                <div class="trio-table__item trio-table__body-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__betname'>21 Yes</span>
                                        { width < 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[0]?.runners[1]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                    <div
                                      style={exposureTrue(dataOdds[0].runners[1].id) && width > 1024
                                        ? {height: '100px'}
                                        : {} }
                                      class="trio-table__item-bets-wrapper">
                                        <div class={`trio-table__item-bet-cell ${dataOdds[0]?.status ===  'SUSPENDED' ? 
                                          'backSuspended' : 'bet-back'}
                                            ${exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[0].isBettable
                                              ? () => select(dataOdds[0], dataOdds[0]?.runners[1], true)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[0]?.runners[1]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div class={`trio-table__item-bet-cell 
                                        ${dataOdds[0]?.status ===  'SUSPENDED' ? 'laySuspended' : 'bet-lay'}
                                        ${exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === "WINNER" ? "winner" : "" }`
                                        }
                                            onClick={
                                            dataOdds[0].isBettable
                                              ? () => select(dataOdds[0], dataOdds[0]?.runners[1], false)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[0]?.runners[1]?.lay[0]?.price}
                                            </span>
                                        </div>
                                        { width > 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[0]?.runners[1]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                </div>
                                <div class="trio-table__item trio-table__body-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__betname long'>3 Card Judgement(1,2,4)</span>
                                        { width < 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[1]?.runners[0]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                    <div
                                      style={exposureTrue(dataOdds[1].runners[0].id) && width > 1024
                                        ? {height: '100px'}
                                        : {} }
                                      class="trio-table__item-bets-wrapper">
                                      <div class={`trio-table__item-bet-cell ${dataOdds[1]?.status ===  'SUSPENDED' ?
                                          'backSuspended' : 'bet-back'}
                                            ${exchangeGames && exchangeGames[1]?.marketRunner[0]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[1].isBettable
                                              ? () => select(dataOdds[1], dataOdds[1]?.runners[0], true)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[1]?.runners[0]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div class={`trio-table__item-bet-cell ${dataOdds[1]?.status ===  'SUSPENDED' ?
                                          'laySuspended' : 'bet-lay'}
                                            ${exchangeGames && exchangeGames[1]?.marketRunner[0]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[1].isBettable
                                              ? () => select(dataOdds[1], dataOdds[1]?.runners[0], false)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[1]?.runners[0]?.lay[0]?.price}
                                            </span>
                                        </div>
                                        { width > 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[1]?.runners[0]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                </div>
                                <div class="trio-table__item trio-table__body-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__betname long'>3 Card Judgement(J,Q,K)</span>
                                        { width < 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[2]?.runners[0]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                    <div
                                      style={exposureTrue(dataOdds[2].runners[0].id) && width > 1024
                                        ? {height: '100px'}
                                        : {} }
                                      class="trio-table__item-bets-wrapper">
                                      <div class={`trio-table__item-bet-cell ${dataOdds[2]?.status ===  'SUSPENDED' ?
                                          'backSuspended' : 'bet-back'}
                                            ${exchangeGames && exchangeGames[2]?.marketRunner[0]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[2].isBettable
                                              ? () => select(dataOdds[2], dataOdds[2]?.runners[0], true)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[2]?.runners[0]?.back[0]?.price || 0}
                                            </span>
                                        </div>
                                        <div class={`trio-table__item-bet-cell ${dataOdds[2]?.status ===  'SUSPENDED' ?
                                          'laySuspended' : 'bet-lay'}
                                            ${exchangeGames && exchangeGames[2]?.marketRunner[0]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[2].isBettable
                                              ? () => select(dataOdds[2], dataOdds[2]?.runners[0], false)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[2]?.runners[0]?.lay[0]?.price || 0}
                                            </span>
                                        </div>
                                        { width > 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[2]?.runners[0]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="trio-table__row-margin">
                            <div class="trio-table__row trio-table__body-row">

                                <div class="trio-table__item trio-table__body-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__betname'>Two Red Only</span>
                                        { width < 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[3]?.runners[0]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                    <div
                                      style={exposureTrue(dataOdds[3].runners[0].id) && width > 1024
                                        ? {height: '100px'}
                                        : {} }
                                      class="trio-table__item-bets-wrapper">
                                      <div class={`trio-table__item-bet-cell ${dataOdds[3]?.status ===  'SUSPENDED' ?
                                          'backSuspended' : 'bet-back'}
                                            ${exchangeGames && exchangeGames[3]?.marketRunner[0]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[3].isBettable
                                              ? () => select(dataOdds[3], dataOdds[3]?.runners[0], true)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[3]?.runners[0]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div class={`trio-table__item-bet-cell ${dataOdds[3]?.status ===  'SUSPENDED' ?
                                          'laySuspended' : 'bet-lay'}
                                            ${exchangeGames && exchangeGames[3]?.marketRunner[0]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[3].isBettable
                                              ? () => select(dataOdds[3], dataOdds[3]?.runners[0], false)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[3]?.runners[0]?.lay[0]?.price}
                                            </span>
                                        </div>
                                        { width > 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[3]?.runners[0]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                </div>
                                <div class="trio-table__item trio-table__body-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__betname'>Two Black Only</span>
                                        { width < 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[3]?.runners[1]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                    <div
                                      style={exposureTrue(dataOdds[3].runners[1].id) && width > 1024
                                        ? {height: '100px'}
                                        : {} }
                                      class="trio-table__item-bets-wrapper">
                                      <div class={`trio-table__item-bet-cell ${dataOdds[3]?.status ===  'SUSPENDED' ?
                                          'backSuspended' : 'bet-back'}
                                            ${exchangeGames && exchangeGames[3]?.marketRunner[1]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[3].isBettable
                                              ? () => select(dataOdds[3], dataOdds[3]?.runners[1], true)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[3]?.runners[1]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div class={`trio-table__item-bet-cell ${dataOdds[3]?.status ===  'SUSPENDED' ?
                                          'laySuspended' : 'bet-lay'}
                                            ${exchangeGames && exchangeGames[3]?.marketRunner[1]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[3].isBettable
                                              ? () => select(dataOdds[3], dataOdds[3]?.runners[1], false)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[3]?.runners[1]?.lay[0]?.price}
                                            </span>
                                        </div>
                                        { width > 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[3]?.runners[1]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                </div>
                                <div class="trio-table__item trio-table__body-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__betname'>Two Odd Only</span>
                                        { width < 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[4]?.runners[0]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                    <div
                                      style={exposureTrue(dataOdds[4].runners[0].id) && width > 1024
                                        ? {height: '100px'}
                                        : {} }
                                      class="trio-table__item-bets-wrapper">
                                      <div class={`trio-table__item-bet-cell ${dataOdds[4]?.status ===  'SUSPENDED' ?
                                          'backSuspended' : 'bet-back'}
                                            ${exchangeGames && exchangeGames[4]?.marketRunner[0]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[4].isBettable
                                              ? () => select(dataOdds[4], dataOdds[4]?.runners[0], true)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[4]?.runners[0]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div class={`trio-table__item-bet-cell ${dataOdds[4]?.status ===  'SUSPENDED' ?
                                          'laySuspended' : 'bet-lay'}
                                            ${exchangeGames && exchangeGames[4]?.marketRunner[0]?.status === "WINNER" ? "winner" : ""}
                                            `}
                                            onClick={
                                            dataOdds[4].isBettable
                                              ? () => select(dataOdds[4], dataOdds[4]?.runners[0], false)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[4]?.runners[0]?.lay[0]?.price}
                                            </span>
                                        </div>
                                        { width > 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[4]?.runners[0]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                </div>
                                <div class="trio-table__item trio-table__body-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__betname'>Two Even Only</span>
                                        { width < 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[4]?.runners[1]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                    <div
                                      style={exposureTrue(dataOdds[4].runners[1].id) && width > 1024
                                        ? {height: '100px'}
                                        : {} }
                                      class="trio-table__item-bets-wrapper">
                                      <div class={`trio-table__item-bet-cell ${dataOdds[4]?.status ===  'SUSPENDED' ?
                                          'backSuspended' : 'bet-back'}
                                            ${exchangeGames && exchangeGames[4]?.marketRunner[1]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[4].isBettable
                                              ? () => select(dataOdds[4], dataOdds[4]?.runners[1], true)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[4]?.runners[1]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div class={`trio-table__item-bet-cell ${dataOdds[4]?.status ===  'SUSPENDED' ?
                                          'laySuspended' : 'bet-lay'}
                                            ${exchangeGames && exchangeGames[4]?.marketRunner[1]?.status === "WINNER" ? "winner" : ""}
                                          `}
                                            onClick={
                                            dataOdds[4].isBettable
                                              ? () => select(dataOdds[4], dataOdds[4]?.runners[1], false)
                                              : null
                                          }
                                          >
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[4]?.runners[1]?.lay[0]?.price}
                                            </span>
                                        </div>
                                        { width > 1024 ?
                                        <div className='exposure'>
                                          {exposurePnl(dataOdds[4]?.runners[1]?.id)}
                                        </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="trio-table_footer">
                        <div class="trio-table__row-margin">
                            <div class="trio-table__row trio-table__footer-row">
                                <div class="trio-table__item trio-table__footer-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__descr'>Pair</span>
                                    </div>
                                    <div class={`trio-table__item-bets-wrapper ${dataOdds[5]?.status ===  'SUSPENDED' ? 'footerSuspended-bets-wrapper' : 'footer-bets-wrapper'}
                                      ${exchangeGames && exchangeGames[5]?.marketRunner[0]?.status === "WINNER" ? "winner" : ""}
                                    `}
                                      onClick={
                                      dataOdds[5].isBettable
                                        ? () => select(dataOdds[5], dataOdds[5]?.runners[0], true)
                                        : null
                                    }>
                                      <div class="trio-table__item-bet-cell footer-bet">
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[5]?.runners[0]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div>
                                          {exposurePnl(dataOdds[5]?.runners[0]?.id)}
                                        </div>
                                    </div>
                                </div>
                                <div class="trio-table__item trio-table__footer-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__descr'>Flush</span>
                                    </div>
                                    <div class={`trio-table__item-bets-wrapper ${dataOdds[5]?.status ===  'SUSPENDED' ? 'footerSuspended-bets-wrapper' : 'footer-bets-wrapper'}
                                     ${exchangeGames && exchangeGames[5]?.marketRunner[1]?.status === "WINNER" ? "winner" : ""}
                                    `}
                                    onClick={
                                      dataOdds[5].isBettable
                                        ? () => select(dataOdds[5], dataOdds[5]?.runners[1], true)
                                        : null
                                    }>
                                      <div class="trio-table__item-bet-cell footer-bet">
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[5]?.runners[1]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div>
                                          {exposurePnl(dataOdds[5]?.runners[1]?.id)}
                                        </div>
                                    </div>
                                </div>
                                <div class="trio-table__item trio-table__footer-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__descr'>Straight</span>
                                    </div>
                                    <div class={`trio-table__item-bets-wrapper ${dataOdds[5]?.status ===  'SUSPENDED' ? 'footerSuspended-bets-wrapper' : 'footer-bets-wrapper'}
                                    ${exchangeGames && exchangeGames[5]?.marketRunner[2]?.status === "WINNER" ? "winner" : ""}
                                    `}
                                    onClick={
                                      dataOdds[5].isBettable
                                        ? () => select(dataOdds[5], dataOdds[5]?.runners[2], true)
                                        : null
                                    }>
                                      <div class="trio-table__item-bet-cell footer-bet">
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[5]?.runners[2]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div>
                                        {exposurePnl(dataOdds[5]?.runners[2]?.id)}
                                        </div>
                                    </div>
                                </div>
                                <div class="trio-table__item trio-table__footer-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__descr'>Trio</span>
                                    </div>
                                    <div class={`trio-table__item-bets-wrapper ${dataOdds[5]?.status ===  'SUSPENDED' ? 'footerSuspended-bets-wrapper' : 'footer-bets-wrapper'}
                                    ${exchangeGames && exchangeGames[5]?.marketRunner[3]?.status === "WINNER" ? "winner" : ""}
                                    `}
                                    onClick={
                                      dataOdds[5].isBettable
                                        ? () => select(dataOdds[5], dataOdds[5]?.runners[3], true)
                                        : null
                                    }>
                                      <div class="trio-table__item-bet-cell footer-bet">
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[5]?.runners[3]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div>
                                        {exposurePnl(dataOdds[5]?.runners[3]?.id)}
                                        </div>
                                    </div>
                                </div>
                                <div class="trio-table__item trio-table__footer-item">
                                    <div class="trio-table__item-header">
                                        <span className='trio-table__item-header__descr'>Straight Flush</span>
                                    </div>
                                    <div class={`trio-table__item-bets-wrapper ${dataOdds[5]?.status ===  'SUSPENDED' ? 'footerSuspended-bets-wrapper' : 'footer-bets-wrapper'}
                                    ${exchangeGames && exchangeGames[5]?.marketRunner[4]?.status === "WINNER" ? "winner" : ""}
                                    `}
                                    onClick={
                                      dataOdds[5].isBettable
                                        ? () => select(dataOdds[5], dataOdds[5]?.runners[4], true)
                                        : null
                                    }>
                                        <div class="trio-table__item-bet-cell footer-bet">
                                            <span class="trio-table__item-bet-cell-price">
                                              {dataOdds[5]?.runners[4]?.back[0]?.price}
                                            </span>
                                        </div>
                                        <div>
                                        {exposurePnl(dataOdds[5]?.runners[4]?.id)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>
          <LastResults/>
          </div>

      </>
    )
}

export default connectTrio()(Trio)
