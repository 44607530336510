// import { colorChip } from './index'
import React from 'react'

const Chip = ({ bet }) => {
  // console.log('bet', bet)

  const colorChip = {
    C100: '#FBC02D',    //yellow
    C500: '#00AF3C',    //green
    C1000: '#0E72BD',   //blue
    C5000: '#C30000',   //red
    C5001: '#444444',   //black
  }

  let fillColor

  if (bet >= 1 && bet < 101) {
    fillColor = colorChip.C100
  } else if (bet >= 101 && bet < 501) {
    fillColor = colorChip.C500
  } else if (bet >= 501 && bet < 1001) {
    fillColor = colorChip.C1000
  } else if (bet >= 1001 && bet < 5001) {
    fillColor = colorChip.C5000
  } else if (bet >= 5001) {
    fillColor = colorChip.C5001
  }

  return (
    <svg width="40" height="40" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_8195_18476)">
        <path d="M31.4334 23.2289H28.061V19.8456C28.061 19.3622 27.5793 18.8789 27.0975 18.8789H26.134C25.592 18.8789 25.1704 19.3622 25.1704 19.8456V23.2289H21.798C21.256 23.2289 20.8345 23.7123 20.8345 24.1956V25.1623C20.8345 25.706 21.256 26.1289 21.798 26.1289H25.1704V29.5123C25.1704 30.056 25.592 30.4789 26.134 30.4789H27.0975C27.5793 30.4789 28.061 30.056 28.061 29.5123V26.1289H31.4334C31.9152 26.1289 32.397 25.706 32.397 25.1623V24.1956C32.397 23.7123 31.9152 23.2289 31.4334 23.2289Z" fill={fillColor} />
        <g filter="url(#filter1_d_8195_18476)">
          <ellipse cx="26.5" cy="24.5601" rx="18.5601" ry="18.5" transform="rotate(90 26.5 24.5601)" fill={fillColor} />
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5006 43.1201C25.3148 43.1201 24.155 43.0082 23.0312 42.7943V35.6288C24.1265 35.974 25.2919 36.1601 26.5006 36.1601C27.7089 36.1601 28.8739 35.9742 29.9688 35.6293V42.7945C28.8454 43.0083 27.686 43.1201 26.5006 43.1201ZM29.9688 13.4909C28.8739 13.146 27.7089 12.96 26.5006 12.96C25.2919 12.96 24.1265 13.1461 23.0312 13.4913V6.32584C24.1535 6.11222 25.3117 6.0003 26.4959 6H26.5054C27.6891 6.0003 28.8469 6.11213 29.9688 6.3256V13.4909Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M42.5213 33.8375C41.9282 34.8679 41.2516 35.8198 40.5049 36.6893L34.32 33.1069C35.1657 32.3279 35.9091 31.4083 36.5135 30.358C37.1176 29.3083 37.5396 28.2032 37.7893 27.0796L43.9741 30.662C43.5969 31.7447 43.1139 32.8077 42.5213 33.8375ZM18.6788 16.0103C17.8337 16.7891 17.0908 17.7082 16.4867 18.7579C15.8823 19.8082 15.4602 20.9139 15.2105 22.0382L9.02471 18.4552C9.37681 17.444 9.82127 16.4499 10.3611 15.4841L10.5982 15.0721C11.1615 14.121 11.7968 13.2384 12.4931 12.4274L18.6788 16.0103Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M42.5213 15.2844C43.1042 16.2973 43.5811 17.3424 43.9555 18.4068L37.777 21.9855C37.5259 20.8807 37.1078 19.7944 36.5135 18.7617C35.8993 17.6944 35.1415 16.762 34.2788 15.975L40.4682 12.39C41.2295 13.271 41.9185 14.2371 42.5213 15.2844ZM15.1985 27.0268C15.4465 28.1699 15.8725 29.2944 16.4867 30.3617C17.081 31.3944 17.8097 32.3008 18.6378 33.0714L12.4565 36.6518C12.0518 36.1775 11.6679 35.6789 11.307 35.1565L9.76008 32.4685C9.47305 31.8584 9.22201 31.2392 9.00628 30.6135L15.1985 27.0268Z" fill="white" />
        <path d="M26.5004 37.4127C19.4325 37.4127 13.698 31.6637 13.698 24.566C13.698 17.4682 19.4325 11.7192 26.5004 11.7192C33.5683 11.7192 39.3028 17.4682 39.3028 24.566C39.3028 31.6637 33.5683 37.4127 26.5004 37.4127Z" fill={fillColor} stroke="#CFCFCF" stroke-width="1.74308" />
        <path d="M12.3907 24.566C12.3907 32.3817 18.7065 38.72 26.5004 38.72C34.2943 38.72 40.6101 32.3817 40.6101 24.566C40.6101 16.7502 34.2943 10.4119 26.5004 10.4119C18.7065 10.4119 12.3907 16.7502 12.3907 24.566Z" stroke="white" stroke-width="0.871542" />
        <mask id="path-8-inside-1_8195_18476" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5 43.124C36.7173 43.124 45 34.8144 45 24.564C45 14.3135 36.7173 6.00391 26.5 6.00391C16.2827 6.00391 8 14.3135 8 24.564C8 34.8144 16.2827 43.124 26.5 43.124ZM26.5 39.091C34.4961 39.091 40.9782 32.5878 40.9782 24.5657C40.9782 16.5436 34.4961 10.0405 26.5 10.0405C18.5038 10.0405 12.0217 16.5436 12.0217 24.5657C12.0217 32.5878 18.5038 39.091 26.5 39.091Z" />
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5 43.124C36.7173 43.124 45 34.8144 45 24.564C45 14.3135 36.7173 6.00391 26.5 6.00391C16.2827 6.00391 8 14.3135 8 24.564C8 34.8144 16.2827 43.124 26.5 43.124ZM26.5 39.091C34.4961 39.091 40.9782 32.5878 40.9782 24.5657C40.9782 16.5436 34.4961 10.0405 26.5 10.0405C18.5038 10.0405 12.0217 16.5436 12.0217 24.5657C12.0217 32.5878 18.5038 39.091 26.5 39.091Z" fill="url(#paint0_linear_8195_18476)" style={{ mixBlendMode: 'overlay' }} />
        <path d="M44.229 24.564C44.229 34.391 36.2891 42.3531 26.5 42.3531V43.895C37.1454 43.895 45.771 35.2379 45.771 24.564H44.229ZM26.5 6.77489C36.2891 6.77489 44.229 14.737 44.229 24.564H45.771C45.771 13.8901 37.1454 5.23293 26.5 5.23293V6.77489ZM8.77098 24.564C8.77098 14.737 16.7109 6.77489 26.5 6.77489V5.23293C15.8546 5.23293 7.22902 13.8901 7.22902 24.564H8.77098ZM26.5 42.3531C16.7109 42.3531 8.77098 34.391 8.77098 24.564H7.22902C7.22902 35.2379 15.8546 43.895 26.5 43.895V42.3531ZM40.2073 24.5657C40.2073 32.1644 34.0679 38.32 26.5 38.32V39.862C34.9243 39.862 41.7492 33.0113 41.7492 24.5657H40.2073ZM26.5 10.8114C34.0679 10.8114 40.2073 16.9671 40.2073 24.5657H41.7492C41.7492 16.1202 34.9243 9.26948 26.5 9.26948V10.8114ZM12.7927 24.5657C12.7927 16.9671 18.932 10.8114 26.5 10.8114V9.26948C18.0757 9.26948 11.2507 16.1202 11.2507 24.5657H12.7927ZM26.5 38.32C18.932 38.32 12.7927 32.1644 12.7927 24.5657H11.2507C11.2507 33.0113 18.0757 39.862 26.5 39.862V38.32Z" fill="url(#paint1_linear_8195_18476)" mask="url(#path-8-inside-1_8195_18476)" />
      </g>
      <defs>
        <filter id="filter0_d_8195_18476" x="0" y="0" width="53" height="53.125" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8195_18476" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8195_18476" result="shape" />
        </filter>
        <filter id="filter1_d_8195_18476" x="3.54545" y="1.54545" width="45.9091" height="46.0302" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="2.22727" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.188235 0 0 0 0 0.188235 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8195_18476" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8195_18476" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_8195_18476" x1="26.5" y1="6.00391" x2="26.5" y2="43.124" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0.2" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_8195_18476" x1="26.5" y1="6.00391" x2="26.5" y2="43.124" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0.2" />
          <stop offset="1" stop-color="white" stop-opacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Chip
