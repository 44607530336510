import React from 'react'

import Trail from './images/Trail.jpeg'
import Sequence from './images/Sequence.jpeg'
import PureSequence from './images/PureSequence.jpeg'
import Pair from './images/Pair.jpeg'
import Color from './images/Color.jpeg'
import HighCard from './images/HighCard.jpeg'

const LiveTeenpattiRules = () => {
  return (
    <div className="rulesContainer">
      <h2>GAME OBJECTIVES</h2>
      <p>
        In this version of Teenpatti, a total of six cards are put on the table
        one by one by the dealer. User can place bet(s) only before the round
        starts. The player with the better hand wins.
      </p>

      <h2>GAME RULES</h2>
      <p>
        The game is played with a single 52-card deck (excluding Jokers), cards
        are shuffled after each game round. Any number of players can
        participate in a single game simultaneously.
      </p>
      <h3>Ranking of the hands</h3>
      <p>
        Aces are ranked the highest and 2's are the lowest. The objective of the
        game is to have the best 3-card hand and to maximise the pot before the
        showdown. The categories are ranked as follows (from high to low):
      </p>

      <ul>
        <li>
          Trail (three of a kind): Three cards of the same rank. Three aces are
          the highest and three 2’s are the lowest.
        </li>
        <img src={Trail} alt="Trail" />
        <li>
          Pure Sequence (Straight Flush): Three consecutive cards of the same
          suit..
        </li>
        <img src={PureSequence} alt="PureSequence" />
        <li>
          Sequence (Straight): Three consecutive cards not all in the same suit.
        </li>
        <img src={Sequence} alt="Sequence" />
        <li>
          Color (Flush): Three cards of the same suit that are not in sequence.
          When comparing two colors, first compare the highest card. If these
          are equal, compare the second and if these are equal compare the
          lowest. Highest flush is A-K-J and the lowest flush is 5-3-2.
        </li>
        <img src={Color} alt="Color" />
        <li>
          Pair (two of a kind): Two cards of the same rank. Between two pairs,
          the one with the higher value is the winner. If the pairs are of equal
          value then the kicker card determines the winner. The highest pair is
          A-A-K and the lowest is 2-2-3.
        </li>
        <img src={Pair} alt="Pair" />
        <li>
          High Card: A hand in which the three cards are not in sequence, not
          all the same suit and no two cards have the same rank. If two players
          share a common high card, the next highest card is used to determine
          the winner. The best high card hand would be an AKJ of different suits
          and the worst is 5-3-2.
        </li>
        <img src={HighCard} alt="HighCard" />
      </ul>

      <h3>Betting Instructions</h3>
      <ul>
        <li>
          If a game round is in progress when you enter the game, please wait
          for the next one, and then, place your bets.
        </li>
        <li>After the timer reaches 0, the game round begins.</li>
        <li>
          To place a bet, click on the box, enter the stake amount and submit by
          clicking on Place Bet button.
        </li>
        <li>
          You can place several bets on different runners in different markets
          simultaneously.
        </li>
        <li>
          The timer in the game window shows how much time you have left to
          place your bets.
        </li>
        <li>
          Winnings are paid for the winning bets at the end of each game round.
        </li>
        <li>To skip a turn, simply do not place any bets.</li>
      </ul>

      <h3>Main Bets</h3>
      <ol>
        <li>PLAYER A</li>
        <li>PLAYER B</li>
      </ol>

      <h3>Side Bets</h3>
      <p>The player can also place a bet on one of the following side bets:</p>
      <ul>
        <li>Player A+ - Predict if player A will get a pair or better</li>
        <li>Player B+ - Predict if player B will get a pair or better</li>
      </ul>

      <h2>PAYOUTS</h2>
      <p>Payout depends on the type of bet placed.</p>

      <table
        className="rulesTable"
        style={{ width: '50%', margin: '15px auto' }}
      >
        <tbody>
          <tr style={{ fontWeight: 'bold', background: '#c8c8c8' }}>
            <th>Hands</th> <th>Payout</th>
          </tr>

          <tr>
            <th>PAIR</th> <th>1: 1</th>
          </tr>

          <tr>
            <th>FLUSH</th> <th>1: 4</th>
          </tr>

          <tr>
            <th>STRAIGHT</th> <th>1: 6</th>
          </tr>

          <tr>
            <th>TRIO</th> <th>1: 25</th>
          </tr>

          <tr>
            <th>STRAIGHT FLUSH</th> <th>1: 35</th>
          </tr>
        </tbody>
      </table>
      <p>In the event of a tie, bets are refunded.</p>
    </div>
  )
}

export default LiveTeenpattiRules
