import "./DragonTigerPL.scss";
import React, { useEffect, useState } from 'react'

const DragonTigerPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {};
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="dragon-tiger-betting">
        <div className="dragon-tiger-betting__games">
          <div className="dragon-tiger-betting__games-item">
            <span
              className={`dragon-tiger-betting__games-item-title ${roundMarkets[0].marketRunner[0].status === 'WINNER' ? 'winnerCup':''}`}>
              Dragon
            </span>
            <div className="dragon-tiger-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[0]?.cards.map( card =>
                <img className="dragon-tiger-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
          <div className="dragon-tiger-betting__games-item">
            <span
              className={`dragon-tiger-betting__games-item-title ${roundMarkets[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}`}>
                Tiger
            </span>
            <div className="dragon-tiger-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[1]?.cards.map( card =>
                <img className="dragon-tiger-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
        </div>
        {winnerData && <div className="dragon-tiger-betting__info-column">
          <div className="dragon-tiger-betting__info-row">
            <div className="dragon-tiger-betting__info-row-title">
              <span>Winner: </span>
            </div>
            <div className="dragon-tiger-betting__info-row-results">
               <span>&nbsp;&nbsp;{winnerData['Match odds']  || 'No Winner'}</span>
            </div>
          </div>
          <div className="dragon-tiger-betting__info-row">
            <div className="dragon-tiger-betting__info-row-title">
              <span>Odd/Even: </span>
            </div>
            <div className="dragon-tiger-betting__info-row-results">
              <span>
                &nbsp; {winnerData['Dragon Odd-Even'].replace('ragon -', ': ')} | {winnerData['Tiger Odd-Even'].replace('iger -', ': ')}
              </span>
            </div>
          </div>
          <div className="dragon-tiger-betting__info-row">
            <div className="dragon-tiger-betting__info-row-title">
              <span>Color:</span>
            </div>
            <div className="dragon-tiger-betting__info-row-results">
              <span className="dragon-tiger-betting__info-row-results">
                {winnerData['Dragon Colour'].replace('ragon -', ': ')} | {winnerData['Dragon Colour'].replace('iger -', ': ')}
              </span>
            </div>
          </div>
          <div className="dragon-tiger-betting__info-row">
            <div className="dragon-tiger-betting__info-row-title">
              <span>Suit:</span>
            </div>
            <div className="dragon-tiger-betting__info-row-results">
              <span className="dragon-tiger-betting__info-row-results">
               {winnerData['Dragon Suit'].replace('ragon -', ': ')} | {winnerData['Dragon Suit'].replace('iger -', ': ')}
              </span>
            </div>
          </div>
        </div>}
      </div>

    );
};

export default DragonTigerPL;

