import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestBetbyWidgetStart, requestBetbyWidgetEnd,  } from '../../store/betbyWidget/betbyWidget.thunks';
import { setUrlForSportbook } from '../../store/betbyWidget/urlForSportbook.slice';

export const connectBetbyWidget = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      betbyWidgetLive: state.betbyWidgetLive,
      urlSportbook: state.urlSportbook,
      auth: state.auth
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestBetbyWidgetStart,
      requestBetbyWidgetEnd,
      setUrlForSportbook,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
