import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {getTransferStatementError, getTransferStatementSuccess} from './transferStatement.slice'

export const requestTransferStatementSuccess = (memberCode) => async (dispatch) => {
    const currentTime = (new Date()).getTime();
    const lastEightDayTime = currentTime - (14 * 24 * 60 * 60 * 1000);

    try {
        const response = await http.get(`/account/${memberCode}/reports/transfer?from=${lastEightDayTime}&to=${currentTime}`)
        if (response.data.success) {
            dispatch(getTransferStatementSuccess(response.data.result))
        }
        return response
    } catch (e) {
        dispatch(getTransferStatementError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}
