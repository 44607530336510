import "./MatkaLastResult.scss";
import React from "react";

const MatkaLastResult = ({data}) => {
    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };
    const renderCards = () => {
        const cards = data && data?.marketId?.indexCard;

        if (Array.isArray(cards) && cards.length > 0) {
            return cards.map(card =>
        <img key={card} className="matka-last-result__player-cards-card"
             src={cardSrc(card)} alt={card} />);
        }
        return <img className="matka-last-result__player-cards-card"
                src={cardSrc()} alt="back" />;
    };

    return (
      <div className="matka-last-result">
        <div className="matka-last-result__player">
          <div className="matka-last-result__player-cards">
            {renderCards()}
          </div>
        </div>
      </div>
    );
};
export default MatkaLastResult;
