import httpAuth from '../../services/httpAuth'
import jwt from 'jsonwebtoken'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  setMarket,
  setRunner,
  setIsBack,
  setPrice,
  setDelay,
  setStake,
  setMaxBet,
  setMaxWinPerMarket,
  setMtype,
  setMinBet,
  setEventTypeId,
  setEventId,
  setCompetitionId,
  setPriceActual,
  setMinMaxAll,
  setIsConfirmBetPopUpOpen
} from './betPlacing.slice'
import { getAuthSuccess } from '../auth/auth.slice'
import { setAuthDataSuccess } from '../auth/authData.slice'
import { ID_TOKEN, TOKEN_EXP } from '../../config/app.config'
import { setScrollPosition } from '../elementsParams/elementsParams.slice'

export const requestBetPlacingStart = (marketId, runnerId, setAll = false ) => async (dispatch, getState) => {
  const auth = getState().auth;
  if(auth) {
    try {
      const response = await httpAuth.post('/exchange/order/settings/',  { marketId, runnerId })
      // if ( setAll && response.data.result) {
      //   console.log("setAll")
      //   dispatch(setMinMaxAll({
      //     maxBet: response.data.result.maxBet,
      //     maxWinPerMarket: response.data.result.maxWinPerMarket,
      //     marketId: response.data.result.marketId
      //   }))
      //   return
      // }
      if (setAll) {return response}
      if (response.data.result) {
        dispatch(setMaxBet(response.data.result.maxBet))
        dispatch(setMaxWinPerMarket(response.data.result.maxWinPerMarket))
        dispatch(setMtype(response.data.result.mType))
        dispatch(setMinBet(response.data.result.minBet))
        dispatch(setEventTypeId(response.data.result.eventTypeId))
        dispatch(setEventId(response.data.result.eventId))
        dispatch(setCompetitionId(response.data.result.competitionId))
      }

    } catch (e) {
      dispatch(setMarket(null))
      dispatch(setRunner(null))
      dispatch(setIsBack(null))
      dispatch(setPrice(null))
      dispatch(setPriceActual(null))
      dispatch(setDelay(null))
      dispatch(setStake(null))
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg;
    }
  } else {
    console.log("not auth so betSettings not called")
  }
}
// export const requestBetPlacingStartAll = (markets) => async (dispatch) => {
//       try {
//         const res = await Promise.allSettled(markets.map(item =>  dispatch(requestBetPlacingStart(item.id,  item.runners[0].id, true))));
//         console.log("res", res)
//         }
//         catch (e) {
//           console.log("ERRRRR", e)
//       }
// }

export const requestSetOrder = (betPlacing) => async (dispatch, getState) => {
  try {
    const response = await httpAuth.post('/exchange/order/', [
      {
        side: betPlacing.isBack ? 0 : 1,
        price: betPlacing.priceActual,
        selectionId: betPlacing.runner,
        btype: betPlacing.btype,
        oddsType: betPlacing.oddsType,
        eventTypeId: betPlacing.eventTypeId,
        marketId: betPlacing.market,
        totalSize: Number(betPlacing.stake),
        betSlipRef: betPlacing.betSlipRef,
        fromOneClick: betPlacing.fromOneClick ? 1 : 0,
        betDelay: betPlacing.delay,
        line: betPlacing.line,
      }
    ],
      {
        headers: {
          'x-user-id': getState().user.memberCode,
          // temporary hard-coded
          'x-app-version': '4.0.19.2',
          'x-client': 'mobile',
          'x-client-id': '451012928.1605029998',
          'x-client-info': 'e883e9a15db99de2fdd0579c576a0693',
          'x-log-timing': true,
          'x-xid': '6b76ff17-70d2-b5fe-2373-08f62ee312af',
        }
      }
    )
    if (response.headers['authorization']) {
      const decoded = jwt.decode(response.headers['authorization'], { complete: true })
      dispatch(getAuthSuccess(response.headers['authorization']))

      dispatch(setAuthDataSuccess(decoded))
      localStorage.setItem(ID_TOKEN, response.headers['authorization'])
      localStorage.setItem(TOKEN_EXP, decoded.payload.exp)
    }
    dispatch(setMarket(null))
    dispatch(setRunner(null))
    dispatch(setPrice(null))
    dispatch(setPriceActual(null))
    dispatch(setDelay(null))
    dispatch(setStake(null))
    return response
  } catch (e) {
    dispatch(setMarket(null))
    dispatch(setRunner(null))
    dispatch(setPrice(null))
    dispatch(setPriceActual(null))
    dispatch(setDelay(null))
    dispatch(setStake(null))
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestCashOut = (marketId) => async (dispatch, getState) => {
  try {
    const response = await httpAuth.get(`/exchange/order/cashout?marketId=${marketId}`,{
      headers: {
        'x-app-version': '4.0.19.2',
        'x-client': 'mobile',
        'x-client-id': '451012928.1605029998',
        'x-client-info': 'e883e9a15db99de2fdd0579c576a0693',
        'x-log-timing': true,
        'x-xid': '6b76ff17-70d2-b5fe-2373-08f62ee312af',
      }
    })
    if (response.data.success) {
      // dispatch(getSportLadderSuccess(response.data.result))
      if (response.headers['authorization']) {
        const decoded = jwt.decode(response.headers['authorization'], { complete: true })
        dispatch(getAuthSuccess(response.headers['authorization']))

        dispatch(setAuthDataSuccess(decoded))
        localStorage.setItem(ID_TOKEN, response.headers['authorization'])
        localStorage.setItem(TOKEN_EXP, decoded.payload.exp)
      }
      console.log(response.data.result);
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
    // dispatch(getSportLadderError())
  }
}

export const requestCancelOrder = (orderId) => async (dispatch) => {
  try {
    const response = await httpAuth.delete(`/exchange/order/${orderId}`)
    if (response.data.success) return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const requestCancelAllBets = () => async (dispatch) => {
  try {
    const response = await httpAuth.delete(`/exchange/order`)
    if (response.data.success) return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

//
export const isConfirmBetPopUpOpenThunk = (open) => async (dispatch) => {
  if (open) {
    dispatch(setIsConfirmBetPopUpOpen(open))
  }
  if (!open) {
    dispatch(setIsConfirmBetPopUpOpen(false))
  }
}
//
