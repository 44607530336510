import { createSlice } from '@reduxjs/toolkit'

export const betHistorySlice = createSlice({
    name: 'betHistory',
    initialState: null,
    reducers: {
        getBetHistorySuccess: (state, action) => action.payload,
        getBetHistoryError: (state) => state,
    },
})

export const { getBetHistorySuccess, getBetHistoryError } = betHistorySlice.actions

export default betHistorySlice.reducer
