import React from 'react'
import { useSelector } from 'react-redux'
import "./LiveTeenpattiLastResult.scss"
import trophy from '../../../../../assets/images/TrophyTeenPattiTest.svg'

const LiveTeenpattiLastResult = ({data}) => {
  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth
  
  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const renderCombination = () => {
    const winner = data && data?.marketId && data?.marketId?.marketRunner.filter(runner => runner?.status === "WINNER")[0]
    if (winner /*&& winner?.resDesc*/) {
      return (
        <div className='LiveTeenpatti-combination'>
          <span>{winner?.resDesc || "HIGH CARD"}</span>
        </div>
      )
    }
  }

  return (
    <div className="LiveTeenpatti-results">


    <div
      className={
        data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
          ? 'LiveTeenpattiLastRes__results-cards-isWinner'
          : 'LiveTeenpattiLastRes__results-cards'
      }
    >
      <div className="LiveTeenpattiLastRes__results-name">Player A</div>
      {width >=1024 ? (
        <>
          <div className='LiveTeenpattiLastRes__results-cards__list'>
          {data &&
            data?.marketId?.marketRunner[0].cards.map((item) => (
              <img
                className="LiveTeenpattiLastRes__results-cards-card"
                src={cardSrc(item)}
              />
            ))}
          </div>
          {data && data?.marketId?.marketRunner[0]?.status === 'WINNER' && (
            <img className='trophy' src={trophy} alt="win" />
          )}
        </>
        ) : (
          <>
            {data &&
              data?.marketId?.marketRunner[0].cards.map((item) => (
                <img
                  className="LiveTeenpattiLastRes__results-cards-card"
                  src={cardSrc(item)}
                />
              ))}
          </>
        )
      }

    </div>
    <div
      className={
        data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
          ? 'LiveTeenpattiLastRes__results-cards-isWinner'
          : 'LiveTeenpattiLastRes__results-cards'
      }
    >
      <div className="LiveTeenpattiLastRes__results-name">Player B</div>
      {width >=1024 ? (
        <>
          <div className='LiveTeenpattiLastRes__results-cards__list'>
            {data &&
              data?.marketId?.marketRunner[1].cards.map((item) => (
                <img
                  className="LiveTeenpattiLastRes__results-cards-card"
                  src={cardSrc(item)}
                />
            ))}
          </div>
          {data && data?.marketId?.marketRunner[1]?.status === 'WINNER' && (
            <img className='trophy' src={trophy} alt="win" />
          )}
        </>
        ) : (
          <>
            {data &&
              data?.marketId?.marketRunner[1].cards.map((item) => (
                <img
                  className="LiveTeenpattiLastRes__results-cards-card"
                  src={cardSrc(item)}
                />
              ))}
          </>
        )
      }

    </div>
      {renderCombination()}
  </div>
  )
}
export default LiveTeenpattiLastResult
