import { createSlice } from '@reduxjs/toolkit'

export const authDataSlice = createSlice({
    name: 'authData',
    initialState: null,
    reducers: {
        setAuthDataSuccess: (state, action) => action.payload,
        setAuthDataError: (state) => null,
    },
})

export const {
    setAuthDataSuccess,
    setAuthDataError,
} = authDataSlice.actions

export default authDataSlice.reducer
