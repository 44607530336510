import React, { useEffect, useRef, useState } from 'react'
import "./RoundTimer.scss"

const RoundTimer = ({timer, coords}) => {

  return (
    <div className={`gameresult__gametimer`} style={{right: coords}}>
      <span>00:{(timer < 10 && "0")}{timer}</span>
    </div>
  )
}

export default RoundTimer
