import { createSlice } from '@reduxjs/toolkit'

export const exchangeGamesAllSlice = createSlice({
    name: 'exchangeGamesAll',
    initialState: null,
    reducers: {
        getExchangeGamesAllSuccess: (state, action) => action.payload,
        getExchangeGamesAllError: (state) => state,
    },
})

export const {
    getExchangeGamesAllSuccess,
    getExchangeGamesAllError,
} = exchangeGamesAllSlice.actions

export default exchangeGamesAllSlice.reducer
