import './RulesPopUpSportEvents.scss'
import CloseCross from '../../../../assets/images/closeCross.svg'

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { setRulesPopUp } from '../../../../store/rulesPopUp/rulesPopUp.slice'
import { requestRulesPage } from '../../../../store/rulesPage/rulesPage.thunks'
import { createPortal } from "react-dom";

const modalRoot = document.querySelector("#modal-root");

const RulesPopUpSportEvents = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { eventTypeId, competitionId } = useParams()
  const eventId = history.location.pathname.substring(
    history.location.pathname.lastIndexOf('/') + 1
  )

  const scrollPosition = useSelector(
    (state) => state.elementsParams.scrollPosition
  )
  const rulesPage = useSelector((state) => state.rulesPage)
  // console.log(rulesPage)
  const { isOpen } = useSelector((state) => state.rulesPopUp)

  function createMarkup(info) {
    return { __html: info.replaceAll('\\n', '') }
  }

  const isNavigationID = useSelector(
    (state) => state.navigationHeaderMenu.navigationID
  )
  // console.log(isNavigationID)
  useEffect(() => {
    if (history.location.pathname.indexOf('event-page') !== -1) {
      dispatch(requestRulesPage(eventTypeId, eventId))
    }
  }, [isOpen])

  const [show, setShow] = useState(false)
  const [showFancy, setShowFancy] = useState(false)

  const handleToggle = () => {
    setShow(!show)
  }
  const handleToggleFancy = () => {
    setShowFancy(!showFancy)
  }
  // console.log('rulesPage[0]?.startTime', rulesPage && rulesPage[0]?.startTime)
  const generalTime = new Date(rulesPage && rulesPage[0]?.startTime)
  // console.log('generalTime', generalTime)
  const fancyTime = new Date(rulesPage && rulesPage[1]?.startTime)

  return createPortal(
    <div className={`rules-popupSportEvents`}>
      <div
        className={`rules-popupSportEvents__background`}
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            // setRulesPopUp(false)
            dispatch(setRulesPopUp(false))
          }
        }}
        // style={{ top: scrollPosition + 'px' }}
      >
        <div className={`rules-popupSportEvents__wrapper`}>
          <div className={`rules-popupSportEvents__header`}>
            <div className={`rules-popupSportEvents__header-text`}>
              <span className={`rules-popupSportEvents__rules-button-text`}>
                Rules
              </span>
            </div>
            <div
              className={`rules-popupSportEvents__close-button`}
              onClick={() => {
                // setRulesPopUp(!rulesPopUp)
                dispatch(setRulesPopUp(false))
              }}
            >
              <img src={CloseCross} alt="" />
            </div>
          </div>

          <div className={`rules-popupSportEvents__content`}>
            {eventTypeId == 2 ||
              eventTypeId == 3 ||
              (eventTypeId == 4 && rulesPage && (
                <>
                  {
                    <div class="dropdown">
                      <div
                        className="rulesTitle"
                        onClick={() => handleToggle()}
                      >
                        <span
                          className={`${
                            !show
                              ? 'rules-accordion__icon'
                              : 'rules-accordion__iconRot'
                          }`}
                        ></span>
                        General Rules
                      </div>
                      <div className={`${!show ? 'notShow' : 'show'}`}>
                        {show ? (
                          <>
                            <div class="rules-time">
                              <div class="rules-time__title">
                                Event Start Time
                              </div>
                              <div class="rules-time__date">
                                <span>
                                  {generalTime
                                    .toLocaleString()
                                    .slice(0, 10)
                                    .replaceAll('.', '/')}
                                </span>
                                &nbsp;
                                <span>
                                  {generalTime
                                    .toLocaleString()
                                    .slice(11, 20)
                                    .split(':', 2)
                                    .join(':')}
                                </span>
                              </div>
                            </div>
                            <div
                              className="rulesContainer"
                              dangerouslySetInnerHTML={createMarkup(
                                rulesPage[0].rules
                              )}
                            ></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  }
                </>
              ))}
            {eventTypeId == 2 ||
              eventTypeId == 3 ||
              (eventTypeId == 4 && rulesPage && (
                <>
                  {
                    <div class="dropdownFancy">
                      <div
                        className="rulesTitle"
                        onClick={() => handleToggleFancy()}
                      >
                        <span
                          className={`${
                            !showFancy
                              ? 'rules-accordion__icon'
                              : 'rules-accordion__iconRot'
                          }`}
                        ></span>
                        Fancy Rules
                      </div>
                      <div
                        className={`${!showFancy ? 'notShow' : 'showFancy'}`}
                      >
                        {showFancy ? (
                          <>
                            <div class="rules-time">
                              <div class="rules-time__title">
                                Event Start Time
                              </div>
                              <div class="rules-time__date">
                                <span>
                                  {fancyTime
                                    .toLocaleString()
                                    .slice(0, 10)
                                    .replaceAll('.', '/')}
                                </span>
                                &nbsp;
                                <span>
                                  {fancyTime
                                    .toLocaleString()
                                    .slice(11, 20)
                                    .split(':', 2)
                                    .join(':')}
                                </span>
                              </div>
                            </div>
                            <div
                              className="rulesContainer"
                              dangerouslySetInnerHTML={createMarkup(
                                rulesPage[1].rules
                              )}
                            ></div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  }
                </>
              ))}
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  )
}

export default RulesPopUpSportEvents
