import "./SevenUpDownResult.scss";
import React from "react";

const SevenUpDownLastResult = ({data}) => {

    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };

    return (
      <div className="seven-up-down-last-result">
        <div className="seven-up-down-last-result__player">
          <div
            className={"seven-up-down-last-result__player-cards"}>
            <img
              className="seven-up-down-last-result__player-cards-card"
              src={cardSrc(data && data?.marketId?.indexCard[0])}
              alt="card"
            />
          </div>
        </div>
      </div>
    );
};
export default SevenUpDownLastResult;
