import "./CasinoWar.scss";
import React, {useEffect, useState} from "react";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
// import BetPlacing from "../../BetPlacing/BetPlacing";
import CardsInfo from "../helpers/CardsInfo/CardsInfo";
import {GET_INPLAY_POPULAR_INTERVAL} from "../../../config/app.config";
import LastResults from "../helpers/LastResults/LastResults";
import {connectCasinoWar} from "./CasinoWar.connect";
import {useParams} from "react-router-dom";

const CasinoWar = ({exchangeGames, sportEvent, betPlacing, actions, exposure, headerHeight, auth, elementsParams}) => {
    const dataOdd = sportEvent.sportData;
  const {eventId} = useParams();

  // window width
      const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  //get price
    const getMarketPrice = (marketIndex, runnersIndex, side = "back") => {
        if (dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex][side][0] &&
      dataOdd[marketIndex].runners[runnersIndex][side][0].price) {

            return dataOdd[marketIndex].runners[runnersIndex][side][0].price;
        }
    };
  //end get price

  // get exposure
    const getExposure = (marketIndex, runnerIndex) => {
      if(auth) {
        const id = sportEvent.sportData[marketIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex].id;
        let objFinder = exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl;
        if (objFinder) {
          return (
            <div className={objFinder > 0
              ? "casino-war-exposure-box-modern casino-war-exposure-modern--pos"
              : "casino-war-exposure-box-modern casino-war-exposure-modern--neg"}
            >{objFinder}</div>
          );
        }
      } else return
    };
  // end get exposure

  // Show betslip
    // const renderBetPlacing = () => {
    //     if (betPlacing.runner && width < 1024) {
    //         return <BetPlacing headerHeight={headerHeight}/>;
    //     }
    //     return null;
    // };
  // End Show betslip

  // Bet placing
    const select = (dataOdd, item, isBack) => {
        const runner = {name: item.name, id: item.id};
        const oddData = isBack
      ? {...item.back[0], runner}
      : {...item.lay[0], runner};
        const marketId = dataOdd && dataOdd.id;
        const runnerId = oddData && oddData.runner && oddData.runner.id;
        if (!marketId || !runnerId) return;
        actions.setMarket(marketId);
        actions.setRunner(runnerId);
        actions.setRunnerName(oddData && oddData.runner && oddData.runner.name);
        actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name);
        actions.setIsBack(isBack);
        actions.setPrice(oddData && oddData.price);
        actions.setPriceActual(oddData && oddData.price);
        actions.setDelay(dataOdd && dataOdd.betDelay);
        actions.setMtype(dataOdd && dataOdd.mtype);
        actions.setBtype(dataOdd && dataOdd.btype);
        actions.setLine(oddData && oddData.line);
        actions.setFromOneClick(false);
        actions.setStake(null);

        actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {
      })
      .catch((err) => console.log("err = ", err));
    };
    const oddPush = (marketIndex, runnerIndex, isBack = true) => {
        return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
        );
    };
  // End Bet placing

  // lock market
    const resultStatus = (marketIndex, runner) => {
        if (exchangeGames && exchangeGames[marketIndex]?.marketRunner[runner]?.status === "WINNER") {
            return "lock-ui winner";
        }
        return !dataOdd[marketIndex].isBettable && "lock-ui";
    };
  // end lock market

  // request Exchange Game data
    useEffect(() => {
        const timer = setIntervalAsync(async () => {
          await actions
                .requestExchangeGames(eventId)
                .catch((e) => console.log("requestInplayPopular error = ", e));
        }, GET_INPLAY_POPULAR_INTERVAL);
        return () => clearIntervalAsync(timer);

    }, []);
  // end  request Exchange Game data

    const renderSell = (market, runner) => {
        return <div className={`casino-war__body-players-item-info-sell
         ${resultStatus(market, runner)}
          ${getExposure(market, runner) ? "isExposure" : ""}`}
                 onClick={() => {oddPush(market, runner);}}>
                  <span className="casino-war__body-players-item-info-sell-price">
                    {getMarketPrice(market, runner)}
                  </span>
                  {width >= 1024 && getExposure(market, runner)}
               </div>;
    };

    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };

    return (
    <>
      <CardsInfo />
      <div className="casino-war">
        {/* <>{width < 1024 ? renderBetPlacing() : ""}</> */}
        <div className="casino-war__body">
          <div className="casino-war__body-cards">
            <div className="casino-war__body-cards-item-empty"></div>
            <div className="casino-war__body-cards-row">
              <div className="casino-war__body-cards-item">
                <img className="casino-war__body-cards-item-card" src={cardSrc(exchangeGames && exchangeGames[0]?.cards[0])}/>
                <span>1</span>
              </div>
              <div className="casino-war__body-cards-item">
                <img className="casino-war__body-cards-item-card" src={cardSrc(exchangeGames && exchangeGames[0]?.cards[1])}/>
                <span>2</span>
              </div>
              <div className="casino-war__body-cards-item">
                <img className="casino-war__body-cards-item-card" src={cardSrc(exchangeGames && exchangeGames[0]?.cards[2])}/>
                <span>3</span>
              </div>
              <div className="casino-war__body-cards-item">
                <img className="casino-war__body-cards-item-card" src={cardSrc(exchangeGames && exchangeGames[0]?.cards[3])}/>
                <span>4</span>
              </div>
              <div className="casino-war__body-cards-item">
                <img className="casino-war__body-cards-item-card" src={cardSrc(exchangeGames && exchangeGames[0]?.cards[4])}/>
                <span>5</span>
              </div>
              <div className="casino-war__body-cards-item">
                <img className="casino-war__body-cards-item-card" src={cardSrc(exchangeGames && exchangeGames[0]?.cards[5])}/>
                <span>6</span>
              </div>
            </div>
          </div>
          {width < 1024 &&
            <div className="casino-war__body-players">
              <div className="casino-war__body-players-item">
                <div className="casino-war__body-players-item-name">
                  <span>Player 1</span>
                  {getExposure(0, 0)}
                </div>
                {renderSell(0,0)}
              </div>
              <div className="casino-war__body-players-item">
                <div className="casino-war__body-players-item-name">
                  <span>Player 2</span>
                  {getExposure(1, 0)}
                </div>
                {renderSell(1,0)}
              </div>
              <div className="casino-war__body-players-item">
                <div className="casino-war__body-players-item-name">
                  <span>Player 3</span>
                  {getExposure(2, 0)}
                </div>
                {renderSell(2,0)}
              </div>
              <div className="casino-war__body-players-item">
                <div className="casino-war__body-players-item-name">
                  <span>Player 4</span>
                  {getExposure(3, 0)}
                </div>
                {renderSell(3,0)}
              </div>
              <div className="casino-war__body-players-item">
                <div className="casino-war__body-players-item-name">
                  <span>Player 5</span>
                  {getExposure(4, 0)}
                </div>
                {renderSell(4,0)}
              </div>
              <div className="casino-war__body-players-item">
                <div className="casino-war__body-players-item-name">
                  <span>Player 6</span>
                  {getExposure(5, 0)}
                </div>
                {renderSell(5,0)}
              </div>
            </div>
          }
          {width >= 1024 &&
            <div className="casino-war__body-players">
              <div className="casino-war__body-players-item">
                <div className="casino-war__body-players-item-name">
                  <span>Winner</span>
                </div>
                <div className="casino-war__body-players-item-sells-wrapper">
                  {dataOdd && dataOdd.map((runner, index) =>
                    <div key={runner.id + index} className="casino-war__body-players-item-sell-wrapper">
                      {renderSell(index, 0)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
        </div>
        <LastResults/>
      </div>
    </>
    );
};

export default connectCasinoWar()(CasinoWar);
