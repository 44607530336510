import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestServerTime } from '../../store/timeSettings/timeSettings.thunks'
import { setTimeZoneName } from '../../store/timeSettings/timeSettings.slice'

export const connectTimeSettings = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      timeZoneName: state.timeSettings.timeZoneName,
      serverTime: state.timeSettings.serverTime,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setTimeZoneName,
      requestServerTime,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
