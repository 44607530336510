import React from 'react'
import "./SicboLastResult.scss"
import onePointDice from "../../../../../assets/images/exchangeGames/sicbo/one-point-dice.svg"
import twoPointDice from "../../../../../assets/images/exchangeGames/sicbo/two-point-dice.svg"
import threePointDice from "../../../../../assets/images/exchangeGames/sicbo/three-point-dice.svg"
import fourPointDice from "../../../../../assets/images/exchangeGames/sicbo/four-point-dice.svg"
import fivePointDice from "../../../../../assets/images/exchangeGames/sicbo/five-point-dice.svg"
import sixPointDice from "../../../../../assets/images/exchangeGames/sicbo/six-point-dice.svg"

const SicboLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card === '1') {
      return onePointDice
    }
    if (card === '2') {
      return twoPointDice
    }
    if (card === '3') {
      return threePointDice
    }
    if (card === '4') {
      return fourPointDice
    }
    if (card === '5') {
      return fivePointDice
    }
    if (card === '6') {
      return sixPointDice
    }
  }

  // console.log('data pop up sicbo', data)

  return (
    <div className='popUpSicbo-content__results-cards'>
      {data && data?.marketId?.indexCard[0] && <img className="popUpSicbo-content__card" src={cardSrc(data && data?.marketId?.indexCard[0])} alt=""/>}
      {data && data?.marketId?.indexCard[1] && <img className="popUpSicbo-content__card" src={cardSrc(data && data?.marketId?.indexCard[1])} alt=""/>}
      {data && data?.marketId?.indexCard[2] && <img className="popUpSicbo-content__card" src={cardSrc(data && data?.marketId?.indexCard[2])} alt=""/>}

    </div>
  )
}

export default SicboLastResult
