import React from 'react'
import "./Poker20LastResult.scss"

const Poker20LastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const getWinnerPattern = (data) => {
    const winner = data.marketId.marketRunner.filter((runner) => {
      return runner.status === 'WINNER';
    })
    if (winner.length > 0) {
      return winner[0].patternCode || winner[0].resDesc || '';
    } else {
      return '';
    }
  }

  return (
    <>
      <div className={`pop-upLastResult-content__results-poker20-items-wrapper`}>
        <div className="pop-upLastResult-content__results-poker20-item">
          <span className="pop-upLastResult-content__results-name">Player A</span>
          <div
            className={
              data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
                ? 'pop-upLastResult-content__results-cards isWinner'
                : 'pop-upLastResult-content__results-cards'
            }
          >
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0].cards[0])}
              alt=""
            />
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[0].cards[1])}
              alt=""
            />
          </div>
        </div>
        <div className="pop-upLastResult-content__results-poker20-item">
          <span className="pop-upLastResult-content__results-name">Player B</span>
          <div
            className={
              data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
                ? 'pop-upLastResult-content__results-cards isWinner'
                : 'pop-upLastResult-content__results-cards'
            }
          >
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1].cards[0])}
              alt=""
            />
            <img
              className="pop-upLastResult-content__results-cards-card"
              src={cardSrc(data && data?.marketId?.marketRunner[1].cards[1])}
              alt=""
            />
          </div>
        </div>
        <div className="pop-upLastResult-content__results-poker20-item poker20-item-board">
          <span className="pop-upLastResult-content__results-name">Board</span>
          <div className={`pop-upLastResult-content__results-cards pop-upLastResult-content__poker20-board-cards`}
          >
            <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[0])} alt="" />
            <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[1])} alt="" />
            <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[2])} alt="" />
            <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[3])} alt="" />
            <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[4])} alt="" />
          </div>
        </div>
      </div>
      <div className="pop-upLastResult-content__poker20-winner-pattern">
        <span>{getWinnerPattern(data)}</span>
      </div>
    </>
  )
}
export default Poker20LastResult
