import "./Poker20PL.scss";
import React, { useEffect, useState } from 'react'

const Poker20PL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {};
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
         if (market.marketHeader !== 'Match Odds' && runner.status === 'WINNER' && runner.name !== 'No Winner') {
           winObj.others = `${winObj.others ? winObj.others + ' |' : ''} ${market.marketHeader.slice(7, 8)}: ${runner.name}`;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="poker-20-20-betting">
        <div className="poker-20-20-betting__games">
          <div className="poker-20-20-betting__games-item">
            <span className={`poker-20-20-betting__games-item-title ${roundMarkets[0].marketRunner[0].status === 'WINNER' ? 'winnerCup':''}`} >
              Player A
            </span>
            <div className="poker-20-20-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[0].cards.map(card => <img className="poker-20-20-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
          <div className="poker-20-20-betting__games-item">
            <span className={`poker-20-20-betting__games-item-title ${roundMarkets[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}`} >
                Player B
            </span>
            <div className="poker-20-20-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[1].cards.map(card => <img className="poker-20-20-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
          <div className="poker-20-20-betting__games-item">
            <span className="poker-20-20-betting__games-item-title">
              Board
            </span>
            <div className="poker-20-20-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].indexCard.map(card => <img className="poker-20-20-betting__games-item-cards-card" src={cardSrc(card)} alt={card} key={card}/> )}
            </div>
          </div>
        </div>
        <div className="poker-20-20-betting__info-column">
          <div className="poker-20-20-betting__info-row">
            <div className="poker-20-20-betting__info-row-title">
              <span>Winner:</span>
            </div>
            <div className="poker-20-20-betting__info-row-results">
              <span>{winnerData && winnerData['Match Odds']  || 'No Winner' }</span>
            </div>
          </div>
          <div className="poker-20-20-betting__info-row">
            <div className="poker-20-20-betting__info-row-title">
              <span>Other:</span>
            </div>
            <div className="poker-20-20-betting__info-row-results">
              <span>{winnerData &&  winnerData['others']  || 'No Winner' }</span>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Poker20PL;

