 import { createSlice } from '@reduxjs/toolkit'
 
 export const slotCasinoPopUpSlice = createSlice({
   name: 'slotCasinoPopUp',
   initialState: false,
   reducers: {
     setSlotCasinoPopUp: (state, action) => action.payload,
   },
 })
 
 export const { setSlotCasinoPopUp } = slotCasinoPopUpSlice.actions
 
 export default slotCasinoPopUpSlice.reducer