import './SportMarketStandardPopUp.scss'
import React from 'react';
import { createPortal } from "react-dom";
import closeIcon from '../../../assets/images/closeCross.svg'

const modalRoot = document.querySelector("#modal-root");

const SportMarketStandardPopUp = ({onDidDismiss, title}) => {
  return createPortal(
    <div className="sportMarketStandartPop-up">
      <div className="sportMarketStandartPop-up_wrapper"
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            onDidDismiss(false)
          }
        }}>
        <div className="sportMarketStandartPop-up">
          <div className="sportMarketStandartPop-up-content">
            <div className='sportMarketStandartPop-up-content-closeIcon'
              onClick={(e) => {onDidDismiss(false)}}>
              <img src={closeIcon} alt="cross icon" />
            </div>
            <div className="sportMarketStandartPop-up-content_title">
              {title}</div>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  )
}

export default SportMarketStandardPopUp