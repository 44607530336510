import http from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getMessageError, setMessageSuccess } from './message.slice'

export const requestMessage = (memberCode) => async (dispatch) => {
  if(memberCode) {
    try {
      const response = await http.get(
        `/account/${memberCode}/notification/message`
      )
      if (response.data.success) {
        dispatch(setMessageSuccess(response.data.result))
      }
      return response
    } catch (e) {
      dispatch(getMessageError())
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }
}
