export const getPlacingText = (bet, res) => {
    const matched = res.data.result?.result?.placed?.[0].sizeMatched
    const stake = Number(bet.stake)
    const odds =  res.data.result?.result?.placed?.[0].averagePriceMatched //bet.priceActual
    const runner = bet.runnerName
    const type = bet.isBack ? 'Back' : 'Lay'
    const isFancy = bet.marketType == "INNINGS_RUNS" && bet.marketBettingType == "LINE"

    const getPriseFor_SB = (marketId, price ) => {
        return (marketId && marketId.slice(marketId.length - 3) === '_SB' ? ((price - 1) * 100).toFixed(2).replace('.00', '') : price);
    }
    
    const priceOdds = getPriseFor_SB(bet.market, odds)

    
    if ((matched || matched === 0) && stake) {
        if (isFancy) {
            return `Bet Matched. ${bet.line} runs - ${runner} - ${bet.isBack ? 'Yes' : 'No'} for ${stake} @ ${odds}`
        } else {
            if (stake - matched == 0) return `Bet Matched. ${type} - ${runner} - ${stake} at odds ${priceOdds}`
            else if (stake - matched == stake) return `Bet Unmatched. ${type} - ${runner} - ${stake} at odds ${priceOdds}`
            else if (stake - matched > 0) return `Bet Partially Matched. ${type} - ${runner} - ${stake} at odds ${priceOdds}`
        }
    }
    return ''
}
