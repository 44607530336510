import React from 'react'
import { useHistory } from 'react-router'
import './EventTypeBanners.scss'
import { connectEventTypeBanners } from './EventTypeBanners.connect'

const EventTypeBanners = ({inplayPopular, eventType}) => {
    const history = useHistory()

    let eventTypeBanner = ''
    if (inplayPopular?.eventTypeBanners) {
        eventTypeBanner =  inplayPopular.eventTypeBanners.map((itemBanner, index) => {
            if (itemBanner.eventId == eventType) {
                 return (
                <div key={index} className={`promo-banner`} style={{backgroundColor: itemBanner.backgroundColour}} onClick={() => history.push(itemBanner.redirect)}>
                    <div className="promo-banner-body" style={{ backgroundImage: `url(${itemBanner.image})` }}></div>
                </div>
            )
            } else return null
           
        })
    }

    return (
       <>
       {eventTypeBanner}
       </>
    )
}

export default connectEventTypeBanners()(EventTypeBanners)