import React, { useState, useEffect } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { IonContent, IonPage, IonHeader, IonLabel } from '@ionic/react'
import './Scorecard.scss'
import { connectScorecard } from './Scorecard.connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import { useHistory, useParams } from "react-router-dom";
import {
  GET_STATS_INTERVAL,
} from '../../config/app.config';
import { getFormattedDate, timeOpens } from '../../helpers/date.helper'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';

const tabs = [
  {
    name: "homeTeam",
  },
  {
    name: "awayTeam",
  },
]

const Scorecard = ({ sportEventStats, actions, sportData, raceOdds, timeSettings, elementsParams }) => {
  const { eventTypeId, eventId } = useParams();
  const history = useHistory()

  const [activeTab, setActiveTab] = useState(0);
  const [contentScrolled, setContentScrolled] = useState(false);
  const [style, setStyle] = useState({});

  const isRacing = eventTypeId == 7 || eventTypeId == 4339
  const startTime = (isRacing) ? raceOdds?.[`${eventTypeId} ${eventId}`]?.start : sportData?.['0']?.start
  const now = Date.now()
  const startDate = new Date(startTime)
  const formattedOpenDate = getFormattedDate(startTime, timeSettings.timeZoneName)

  //get window with
      const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
.clientWidth
  //end get window with

  const onScroll = e => {
    if (e.detail.deltaY < 0 || e.detail.scrollTop <= 120) {
      if (contentScrolled) setContentScrolled(false)
    }
    else if (e.detail.deltaY > 0) {
      if (!contentScrolled && e.detail.scrollTop > 120) setContentScrolled(true)
    }
  }

  useEffect(() => {
    if (history.location.pathname.indexOf(`/scorecard/${eventTypeId}/${eventId}`) !== -1) actions.requestSportStats(eventTypeId, eventId)
    const mainTimer = setIntervalAsync(async() => {
      if (history.location.pathname.indexOf(`/scorecard/${eventTypeId}/${eventId}`) !== -1) await actions.requestSportStats(eventTypeId, eventId)
    }, GET_STATS_INTERVAL)
    return () => clearIntervalAsync(mainTimer);
  }, [eventTypeId, eventId, history.location.pathname])

  const renderTabs = () => {
    if (sportEventStats?.scorecard?.homeTeam && sportEventStats?.scorecard?.awayTeam) return [
      {
        label: sportEventStats.scorecard.homeTeam.name,
        setTab: () => setActiveTab(0)
      },
      {
        label: sportEventStats.scorecard.awayTeam.name,
        setTab: () => setActiveTab(1)
      },
    ]
    return null
  }

  const team = sportEventStats?.scorecard?.[tabs[activeTab].name] || {}

  const renderBatsmanTable = () => {
    if (team.batsmens?.stats) return team.batsmens.stats.map((item) => {
      return <tr key={item.id}>
        <td>
          <div>
            <b>
              <span className="Scorecard__name">
                {item.name}
                {item.isWK && <span> ✝</span>}
                {item.isCap && <span> (c)</span>}
              </span>
            </b>
          </div>
          <div>{item.howOut}</div>
        </td>
        <td><b>{item.runs}</b></td>
        <td>{item.balls}</td>
        <td>{item.fours} / {item.sixes}</td>
        <td>{item.strikes?.toFixed(2)}</td>
      </tr>
    })
    return null
  }

  const renderNames = (names) => {
    let isFirst = true
    return names.map((item) => {
      let add = ', '
      if (isFirst) {
        add = ''
        isFirst = false
      }
      return <span key={item.name}>
        {add}
        {item.name}
        {item.isWK && <span> ✝</span>}
        {item.isCap && <span> (c)</span>}
      </span>
    })
  }

  const renderFallOfWickets = (fallOfWickets) => {
    let isFirst = true
    return team.fallOfWickets.map((item) => {
      let add = ', '
      if (isFirst) {
        add = ''
        isFirst = false
      }
      return item.batsman ? <span key={item.batsman.id}>
        {add}
        {item.number} - {item.runs}
        &nbsp;({item.batsman.name}, {item.ballCode})
      </span> : null
    })
  }
  const renderBatsmen = () => {
    if (team.batsmens && team.batsmens.stats?.length > 0) return <div className="Scorecard__table">
      <div className="Scorecard__table__wrapper batsmens">
        <table>
          <thead>
            <tr>
              <th>BATSMEN</th>
              <th>R</th>
              <th>B</th>
              <th>4s/6s</th>
              <th>SR</th>
            </tr>
          </thead>
          <tbody>
            {renderBatsmanTable()}
          </tbody>
        </table>

        {team.batsmens.extras &&
          <div className="Scorecard__extras">
            <span className="Scorecard__extras__title">Extras: </span>
            <span>{team.batsmens.extras.legByes + team.batsmens.extras.byes + team.batsmens.extras.wides + team.batsmens.extras.noBalls} ( </span>
            {team.batsmens.extras.byes ? <span>{team.batsmens.extras.byes}b </span> : ''}
            {team.batsmens.extras.legByes ? <span>{team.batsmens.extras.legByes}lb </span> : ''}
            {team.batsmens.extras.wides ? <span>{team.batsmens.extras.wides}w </span> : ''}
            {team.batsmens.extras.noBalls ? <span>{team.batsmens.extras.noBalls}nb </span> : ''}
            <span>)</span>
          </div>
        }

        {team.batsmens.total &&
          <div className="Scorecard__extras">
            <span className="Scorecard__extras__title">Total: </span>
            <span>{team.batsmens.total.score} - {team.batsmens.total.wickets}</span>
            <span> ({team.batsmens.total.overs}.{team.batsmens.total.balls} Overs, RR: {team.batsmens.total.runRate})</span>
          </div>
        }

        {team.batsmens.yetToBat && team.batsmens.yetToBat.length > 0 &&
          <div className="Scorecard__extras">
            <span className="Scorecard__extras__title">Yet to bat: </span>
            {renderNames(team.batsmens.yetToBat)}
          </div>
        }

        {team.batsmens.didNotBat && team.batsmens.didNotBat.length > 0 &&
          <div className="Scorecard__extras">
            <span className="Scorecard__extras__title">Did not bat: </span>
            {renderNames(team.batsmens.didNotBat)}
          </div>
        }

        {team.fallOfWickets && team.fallOfWickets.length > 0 &&
          <div className="Scorecard__extras">
            <span className="Scorecard__extras__title">Fall of wickets: </span>
            {renderFallOfWickets()}
          </div>
        }
      </div>
    </div>

    if (team.batsmens?.yetToBat?.length > 0) return <div className="Scorecard__table">
      <table>
        <thead>
          <tr>
            <th>LINEUP</th>
          </tr>
        </thead>
        <tbody>
          {team.batsmens.yetToBat.map((item) => {
            return <tr key={item.id}>
              <td>
                <div>
                  <b>
                    <span className="Scorecard__name">
                      {item.name}
                      {item.isWK && <span> ✝</span>}
                      {item.isCap && <span> (c)</span>}
                    </span>
                  </b>
                </div>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>

    return null
  }

  const renderBowlers = () => {
    if (team.bowlers) return team.bowlers.map((item) => {
      return <tr key={item.id}>
        <td><b><span className="Scorecard__name">{item.name}</span></b></td>
        <td><b>{item.overs}</b></td>
        <td><b>{item.runs}</b></td>
        <td><b>{item.wickets}</b></td>
        <td>{item.wides} / {item.noBalls}</td>
        <td>{item.fours} / {item.sixes}</td>
        <td>{item.dotBalls}</td>
        <td>{item.economy?.toFixed(2)}</td>
      </tr>
    })
    return null
  }
  const renderBowling = () => {
    if (team.bowlers && team.bowlers.length > 0) return <div className="Scorecard__table">
      <div className="Scorecard__table__wrapper bowlers">
        <table>
          <thead>
            <tr>
              <th>BOWLING</th>
              <th>O</th>
              <th>R</th>
              <th>W</th>
              <th>WD/NB</th>
              <th>4s/6s</th>
              <th>DOTS</th>
              <th>ECON</th>
            </tr>
          </thead>
          <tbody>
            {renderBowlers()}
          </tbody>
        </table>
      </div>
    </div>
    return null
  }

  // scorecardTopInfo logic
  const teamsScore = () => sportEventStats?.stats?.teams
    ? <div className="scorecardTopInfo__teamsScore">
      {sportEventStats?.stats?.teams.map((item, index) => <div className="scorecardTopInfo__teamsScore-item" key={index}>{item.score}</div>)}
    </div>
    : null

  const eventName = () => {
    if (sportEventStats?.scorecard) {
      return `${sportEventStats.scorecard.awayTeam?.name} v ${sportEventStats.scorecard.homeTeam?.name}`
    }
    else {
      return "No data"
    }
  }

  const eventStartTime = () => {
    if (startTime > now) {
      return <div className="scorecardTopInfo__eventStartTime">
        {formattedOpenDate.date} {formattedOpenDate.time} {timeOpens(startDate, true)}
      </div>
    }
    return null
  }

  const eventStats = () => {
    if (sportEventStats?.stats?.battingTeam) {
      const bt = sportEventStats.stats.battingTeam

      let scoreWicketsMessage = bt.score;
      10 !== bt.wickets && (scoreWicketsMessage += "-" + bt.wickets)

      let r = bt.overs ? bt.overs : 0
      let s = bt.balls ? bt.balls : 0
      const currentOversBalls = r + "." + s

      return <div className="scorecardTopInfo__eventStats">{bt.name}: {scoreWicketsMessage} ({currentOversBalls} Ovs)</div>
    }
    return null
  }

  const statuses = {
    "InPlay": "In play",
    "BetweenInnings": "Innings Break",
    "Review": "REVIEW",
    "Drinks": "Drinks",
    "Rain": "Rain",
    "BadLight": "Bad light stopped play",
    "Injury": "Injury timeout",
    "Other": "Delay",
  }

  const tossWinnerData = () => {
    const e = sportEventStats.stats
    let a = e.status,
      i = {
        message: "",
        statusMessage: "",
        statusStyle: "Review" === a ? "review" : ""
      }

    if ("BetweenInnings" === a || 2 === e.currentInning) {
      if ("Completed" !== a) {
        if (null !== e.battingTeam.requiredRuns || e.battingTeam.target) {
          let r = {
            team: e.battingTeam.name,
            requiredScore: null !== e.battingTeam.requiredRuns ? e.battingTeam.requiredRuns : e.battingTeam.target
          }
          /*i.message = (e.battingTeam.name + ' need ' + r.requiredScore + ' runs',
            (null !== e.battingTeam.requiredRuns && e.battingTeam.requiredRuns > 0 && e.remainingBalls <= 60 && (i.message += " from " + e.remainingBalls + " balls")))*/
          i.message = e.battingTeam.name + ' need ' + r.requiredScore + ' runs'
          null !== e.battingTeam.requiredRuns && e.battingTeam.requiredRuns > 0 && e.remainingBalls <= 60 && (i.message += " from " + e.remainingBalls + " balls")
        }
      } else e.result && "Won" === e.result.type && (i.message = 'Match won by ' + e.result.winnerName);
    } else e.tossWinner && e.tossWinner.name && e.tossWinner.decision && (i.message = e.tossWinner.name + " " + (e.tossWinner.decision === 'optToBowl' ? "opt to bowl" : "opt to bat"))

    return (statuses.hasOwnProperty(a) && (i.statusMessage = statuses[a] + (i.message.length ? " - " : "")),
    e.adjustedOvers && "Completed" !== a && i.message.length && (i.message += ' - reduced to ' + (e.oversPerInning || 0) + ' overs'),
      i)
  }

  const tossWinner = () => {
    if (sportEventStats?.stats?.tossWinner?.name) {
      const data = tossWinnerData()
      // return <div className="EventHeader__details__additional">{sportStats.stats.tossWinner.name} {sportStats.stats.tossWinner.decision}</div>
      return <div className="scorecardTopInfo__tossWinner"><span className={`${data.statusStyle}`}>{data.statusMessage}</span> {data.message}</div>
    }
  }

  const altName = () => raceOdds?.[`${eventTypeId} ${eventId}`]?.altName ? <div className="scorecardTopInfo__altName">{raceOdds[`${eventTypeId} ${eventId}`].altName}</div> : null
  // End  scorecardTopInfo logic

  return (
    <IonPage className="ion-page-scorecard">
      {
        width > 1024
        ?
          <div className={`scorecardTopInfo`}>
            <div className={`scorecardTopInfo__inner-wrapper`}>
              <div  className={`scorecardTopInfo__back-button-title`}>
                <div className={`scorecardTopInfo__back-button`} onClick={() => {history.goBack()}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="#ffffff"/>
                  </svg>
                </div>
                <div>
                  {teamsScore()}
                  <h2 className={`scorecardTopInfo__title`}>{eventName()}</h2>
                </div>
              </div>
              <div className={`scorecardTopInfo__content-additional`}>
                {eventStartTime()}
                {eventStats()}
                {tossWinner()}
                {altName()}
              </div>
            </div>
            {
              renderTabs() &&
              <div className="ion-toolbar__wrapper toolbar__tabs" style={{"z-index": 999}}>
                <div className="toolbar__tabs__bar" slot="bottom">
                  {
                    renderTabs().map((tab, index) => tab &&
                      <div className={`toolbar__tabs__bar__tab ${activeTab == index ? 'active' : ''}`} onClick={tab.setTab} key={index}>
                        <IonLabel>{tab.label}</IonLabel>
                        {tab.icon}
                      </div>)
                  }
                </div>
              </div>
            }
          </div>
        :
          <IonHeader className="onscrollHeader" style={style}>
            <Toolbar alterTitle={'Scorecard'} eventHeader={!!sportEventStats} tabs={renderTabs()} contentScrolled={contentScrolled} activeTab={activeTab} setStyle={setStyle} />
          </IonHeader>
      }
      <div className="Scorecard" fullscreen onIonScroll={onScroll} scrollEvents={true}>
        {renderBatsmen()}
        {renderBowling()}
      </div>
      {/*<FooterDesktop/>*/}
    </IonPage>
  )
}

export default connectScorecard()(Scorecard)
