export const getCurrencyFormat = (value) => {
  const result = new Intl.NumberFormat('en-IN', { currency: 'INR',}).format(value)
  const resultForZero = new Intl.NumberFormat('en-IN', { currency: 'INR', minimumFractionDigits: 2 }).format(value)
  
  if (value === 0) {
    return resultForZero
  } else return result
}
export const getCurrencyFormatWithZeroAfterDot = (value) => {
  const result = new Intl.NumberFormat('en-IN', { currency: 'INR', trailingZeroDisplay: 'auto', minimumFractionDigits: 2 }).format(value)
  return result
}
export const getCurrencyFormatWithoutZeroAfterDot = (value) => {
  const result = new Intl.NumberFormat('en-IN', { currency: 'INR', trailingZeroDisplay: 'auto', minimumFractionDigits: 0 }).format(value)
  return result
}

