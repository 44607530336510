import React from "react";
import "./AndarBaharLastResult.scss";

const AndarBaharLastResult = ({data}) => {

    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };
    const renderCards = (runner) => {
        const cards = data && data?.marketId?.marketRunner[runner]?.cards;

        if (Array.isArray(cards) && cards.length > 0) {
            return cards.map(card =>
        <img key={card} className="andar-bahar-last-result__player-cards-card"
             src={cardSrc(card)} alt={card} />);
        }
        return <img className="andar-bahar-last-result__player-cards-card"
                src={cardSrc()} alt="back" />;
    };

    return (
      <div className="andar-bahar-last-result">
        <div className="andar-bahar-last-result__player">
          <div className={`andar-bahar-last-result__player-title ${data?.marketId?.marketRunner[0]?.status === "WINNER" ? "isWinner" : ""}`}>
            Low Cards
          </div>
          <div className="andar-bahar-last-result__player-cards">
            {renderCards(0)}
          </div>
        </div>
        <div className="andar-bahar-last-result__player">
          <div className={`andar-bahar-last-result__player-title ${data?.marketId?.marketRunner[1]?.status === "WINNER" ? "isWinner" : ""}`}>
            High Cards
          </div>
          <div className="andar-bahar-last-result__player-cards">
            {renderCards(1)}
          </div>
        </div>
        <div className="andar-bahar-last-result__player">
          <div className="andar-bahar-last-result__player-title">Joker</div>
          <div className="andar-bahar-last-result__player-cards">
            <img className="andar-bahar-last-result__player-cards-card" src={cardSrc(data && data?.marketId?.indexCard[0])} alt="back" />
          </div>
        </div>
      </div>
    );
};
export default AndarBaharLastResult;
