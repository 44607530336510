import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestInplayPopular } from '../../store/inplayPopular/inplayPopular.thunks'
import { requestPromotions } from '../../store/promotions/promotions.thunks'
import { getInplayPopularSuccess } from '../../store/inplayPopular/inplayPopular.slice'
import { getPromotionsSuccess } from '../../store/promotions/promotions.slice'
import { requestOrderCount } from '../../store/orderCount/orderCount.thunks'
import { setLegalAgePopoverShow } from '../../store/modals/legalAgePopover.slice'
import { setKycPopoverShow } from '../../store/modals/kycPopover.slice'
import { setMoreRulesPopoverShow } from '../../store/modals/moreRulesPopover.slice'
import { requestSportPageEvent } from '../../store/sportPageEvent/sportPageEvent.thunks'
import { requestSportPageAllEvents } from '../../store/sportPageAllEvents/sportPageAllEvents.thunks'
import { requestSportData, requestSportDataMarkets } from '../../store/sportEvent/sportEvent.thunks'

export const connectFavouritesPage = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      user: state.user,
      lastActivityDate: state.balance.lastActivityDate,
      inplayPopular: state.inplayPopular,
      promotions: state.promotions,
      legalAgePopoverShow: state.legalAgePopoverShow,
      kycPopoverShow: state.kycPopoverShow,
      moreRulesPopoverShow: state.moreRulesPopoverShow,
      balanceSettings: state.balanceSettings,
      betPlacing: state.betPlacing,
      orderCount: state.orderCount,
      timeSettings: state.timeSettings,
      sportPageAllEvents: state.sportPageAllEvents,
      elementsParams: state.elementsParams,
      sportData: state.sportEvent.sportData,
      sportDataMarkets: state.sportEvent.sportDataMarkets,
      balance: state.balance,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestOrderCount,
      requestInplayPopular,
      requestPromotions,
      requestSportPageEvent,
      requestSportPageAllEvents,
      requestSportData,
      requestSportDataMarkets,
      setLegalAgePopoverShow,
      setKycPopoverShow,
      setMoreRulesPopoverShow,
      getInplayPopularSuccess,
      getPromotionsSuccess,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
