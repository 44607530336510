import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { setSearchResults, resetSearchResults } from './search.slice'

export const requestSearchResults = (value) => async (dispatch, getState) => {
  try {
    if (value.length > 2) {
        const result = [];
        const menu = getState().menu;
        if (menu)
        menu.forEach(menuItem => {
            menuItem.children
                .forEach(eventGroup => {
                    eventGroup.children
                    .filter(event => event.name.toLowerCase().indexOf(value?.toLowerCase()) !== -1 && result.push(event))
                })
        })
        dispatch(setSearchResults(undefined))
        dispatch(setSearchResults(result))
        return true
    }

  } catch (e) {
    dispatch(resetSearchResults())
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
}
