import React from 'react'
import "./SixPlayerPokerLastResult.scss"

const SixPlayerPokerLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const getWinnerPattern = (data) => {
    const winner = data.marketId.marketRunner.filter((runner) => {
      return runner.status === 'WINNER';
    })
    if (winner.length > 0) {
      return winner[0].patternCode || winner[0].resDesc || '';
    } else {
      return '';
    }
  }

  return (
    <>
      <div className="pop-upLastResult-content__results-poker6">
      <div className="pop-upLastResult-content__results-poker6-item">
        <span className="pop-upLastResult-content__results-namee">Player A</span>
        <div
          className='pop-upLastResult-content__results-cards'>
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[0])}
            alt=""
          />
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[1])}
            alt=""
          />
        </div>
      </div>
      <div className="pop-upLastResult-content__results-poker6-item">
        <span className="pop-upLastResult-content__results-namee">Player B</span>
        <div
          className='pop-upLastResult-content__results-cards'>
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[0])}
            alt=""
          />
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[1])}
            alt=""
          />
        </div>
      </div>
      <div className="pop-upLastResult-content__results-poker6-item">
        <span className="pop-upLastResult-content__results-namee">Player C</span>
        <div
          className='pop-upLastResult-content__results-cards'>
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[2]?.cards[0])}
            alt=""
          />
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[2]?.cards[1])}
            alt=""
          />
        </div>
      </div>
      <div className="pop-upLastResult-content__results-poker6-item">
        <span className="pop-upLastResult-content__results-namee">Player D</span>
        <div
          className='pop-upLastResult-content__results-cards'>
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[3]?.cards[0])}
            alt=""
          />
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[3]?.cards[1])}
            alt=""
          />
        </div>
      </div>
      <div className="pop-upLastResult-content__results-poker6-item">
        <span className="pop-upLastResult-content__results-namee">Player E</span>
        <div
          className='pop-upLastResult-content__results-cards'>
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[4]?.cards[0])}
            alt=""
          />
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[4]?.cards[1])}
            alt=""
          />
        </div>
      </div>
      <div className="pop-upLastResult-content__results-poker6-item">
        <span className="pop-upLastResult-content__results-namee">Player F</span>
        <div
          className='pop-upLastResult-content__results-cards'>
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[5]?.cards[0])}
            alt=""
          />
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[5]?.cards[1])}
            alt=""
          />
        </div>
      </div>
      </div>

      <div className="pop-upLastResult-content__results-poker6-item poker6-item-board">
        <span className="pop-upLastResult-content__results-namee">Board</span>
        <div className={`pop-upLastResult-content__results-cards pop-upLastResult-content__poker6-board-cards`}
        >
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[0])} alt="" />
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[1])} alt="" />
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[2])} alt="" />
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[3])} alt="" />
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[4])} alt="" />
        </div>
      </div>

      <div
        className='poker6Result'>
        {data && getWinnerPattern(data) &&
          <div className='poker6Result-content'>
            <span>{getWinnerPattern(data)}</span>
          </div>
        }
      </div>

    </>
  )
}
export default SixPlayerPokerLastResult
