import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestLogout } from '../../store/auth/auth.thunks'
import { setLegalAgePopoverShow } from '../../store/modals/legalAgePopover.slice'

export const connectLegalAgePopover = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestLogout,
      setLegalAgePopoverShow,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
