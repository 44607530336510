import React from 'react'
import "./BollywoodCasinoLastResult.scss"

const BollywoodCasinoLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const getWinnerPattern = (data) => {
    const winner = data.marketId.marketRunner.filter((runner) => {
      return runner.status === 'WINNER';
    })
    if (winner.length > 0) {
      return winner[0].name || winner[0].resDesc || '';
    } else {
      return '';
    }
  }


  return (
    <div className="BollywoodCasinoLastRes__results">
      <div className='BollywoodCasinoLastRes__results-cards-one'>
        {/*<img className="BollywoodCasinoLastRes__results-cards-cup" src={cardSrc(data && data?.marketId?.indexCard[0])}/>*/}
        <img className="BollywoodCasinoLastRes__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[0])}/>
      </div>
      <div className="BollywoodCasinoLastRes__results-winner-pattern">
        <span>{getWinnerPattern(data)}</span>
      </div>
  </div>
  )
}
export default BollywoodCasinoLastResult
