import React  from 'react'
import { useSelector } from 'react-redux'
import "./CasinoMeterLastResult.scss"
import * as cards from '../helpers'

const CasinoMeterLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const getCardNumber = (card) => {
    return card.substring(1)
  }

  const getSumOfCards = (cards)  => {
    let sum = 0;
    for (const card of cards) {
      sum += parseInt(getCardNumber(card));
    }
    return sum;
  }

  // const runnerLowCards = data.marketId.indexCard.includes("S9") ? [...data.marketId.marketRunner[0].cards, 'S9'] : data.marketId.marketRunner[0].cards
  // const runnerHighCards = data.marketId.indexCard.includes("S10") ? [...data.marketId.marketRunner[1].cards, 'S10'] : data.marketId.marketRunner[1].cards

  const runnerLowCards = data.marketId.marketRunner[0].cards
  const runnerHighCards = data.marketId.marketRunner[1].cards

  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth
  
  return (
    <div className="casino-meter-LR-wrapper">
      <div className='casino-meter-LR'>
        <div className="pop-upLastResult-content__results-CasinoMeter-item">
          <div className={`pop-upLastResult-content__results-name ${data && data.marketId.indexCard.length <= 0 && data?.marketId?.marketRunner[0]?.status === 'WINNER'
            ? 'pop-upLastResult-content__results-cards-isWinner winner-CM'
            : ''} `}
          >
            Low cards
          </div>
          <div
            className={'pop-upLastResult-content__results-cards cm-card-wrapper'}
          >
            {data && data?.marketId?.marketRunner[0].cards.length ? data?.marketId?.marketRunner[0].cards.map(item => {
                return (
                  <img
                    className="pop-upLastResult-content__results-cards-card"
                    src={cardSrc(item)}
                    alt=""
                  />
                )
              })
              :
              <img className="pop-upLastResult-content__results-cards-card" src={cardSrc()} alt="" />
            }





            {width >= 1024 && (
              <div className='casino-meter__score-wrapper'>
                <div className='casino-meter__score-wrapper-score'>Total {data.marketId && getSumOfCards(runnerLowCards)}</div>
                {/*<div className='casino-meter__score-wrapper-pos'>Low position: {data.marketId.marketRunner[0].resDesc.split('_')[0] === "WON"*/}
                {/*                    ? data.marketId.marketRunner[0].resDesc.split('_')[1]*/}
                {/*                    : -data.marketId.marketRunner[0].resDesc.split('_')[1]}*/}
                {/*</div>*/}
              </div>
            )}
          </div>
          {width < 1024 && (
            <div className='casino-meter__score-wrapper'>
              <div className='casino-meter__score-wrapper-score'>Total {data.marketId && getSumOfCards(runnerLowCards)}</div>
              {/*<div className='casino-meter__score-wrapper-pos'>Low position: {data.marketId.marketRunner[0].resDesc.split('_')[0] === "WON"*/}
              {/*                  ? data.marketId.marketRunner[0].resDesc.split('_')[1]*/}
              {/*                  : -data.marketId.marketRunner[0].resDesc.split('_')[1]}*/}
              {/*</div>*/}
            </div>
          )}
        </div>

        <div className="pop-upLastResult-content__results-CasinoMeter-item">
          <div className={`pop-upLastResult-content__results-name ${data && data.marketId.indexCard.length <= 0 && data?.marketId?.marketRunner[1]?.status === 'WINNER'
            ? 'pop-upLastResult-content__results-cards-isWinner winner-CM'
            : ''} `}
          >
            High cards
          </div>
          <div
            className={'pop-upLastResult-content__results-cards'}
          >
            {data && data?.marketId?.marketRunner[1].cards.length ? data?.marketId?.marketRunner[1].cards.map(item => {
                return (
                  <img
                    className="pop-upLastResult-content__results-cards-card"
                    src={cardSrc(item)}
                    alt=""
                  />
                )
              })
              :
              <img className="pop-upLastResult-content__results-cards-card" src={cardSrc()} alt="" />
            }


            {width >= 1024 && (

              <div className='casino-meter__score-wrapper'>
                <div className='casino-meter__score-wrapper-score'>Total {data.marketId && getSumOfCards(runnerHighCards)}</div>
                {/*<div className='casino-meter__score-wrapper-pos'>High position: {data.marketId.marketRunner[1].resDesc.split('_')[0] === "WON"*/}
                {/*                    ? data.marketId.marketRunner[1].resDesc.split('_')[1]*/}
                {/*                    : -data.marketId.marketRunner[1].resDesc.split('_')[1]*/}
                {/*                  }</div>*/}
              </div>
            )}
          </div>
          {width < 1024 && (
            <div className='casino-meter__score-wrapper'>
              <div className='casino-meter__score-wrapper-score'>Total {data.marketId && getSumOfCards(runnerHighCards)}</div>
              {/*<div className='casino-meter__score-wrapper-pos'>High position: {data.marketId.marketRunner[1].resDesc.split('_')[0] === "WON"*/}
              {/*                    ? data.marketId.marketRunner[1].resDesc.split('_')[1]*/}
              {/*                    : -data.marketId.marketRunner[1].resDesc.split('_')[1]*/}
              {/*                  }</div>*/}
            </div>
          )}
        </div>
      </div>
      {(data.marketId.indexCard.includes("S9") || data.marketId.indexCard.includes('S10'))  &&
        <div className="casino-meter__index-cards">
        {data.marketId.indexCard.includes('S9') && (
          <img src={cards['OS9']} alt="card S9" className="pop-upLastResult-content__results-cards-card" />
        )}
        {data.marketId.indexCard.includes('S10') && (
          <img src={cards['OS10']} alt="card S10" className="pop-upLastResult-content__results-cards-card" />
        )}
      </div>}
    </div>
  )
}
export default CasinoMeterLastResult
