import React from 'react'
import poker1 from './images/poker1.png'
import poker2 from './images/poker2.png'
import poker3 from './images/poker3.png'
import poker4 from './images/poker4.png'
import poker5 from './images/poker5.png'
import poker6 from './images/poker6.png'
import poker7 from './images/poker7.png'
import poker8 from './images/poker8.png'
import poker9 from './images/poker9.png'
import poker10 from './images/poker10.png'

const Poker2020Rules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          The objective is to get best card combination out of six Players in
          the end of the game.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <div>
          <p>
            Each Player are dealt two cards as their personal hand (hole cards),
            after which there is a round of betting. Three board cards are dealt
            simultaneously (called the flop) and another round of betting
            occurs. The next two board cards are dealt one at a time, with a
            round of betting after each card. The board cards are community
            cards, and a player can use any five-card combination from among the
            board and personal cards. A player can even use all of the board
            cards and no personal cards to form a hand ("play the board")
          </p>
        </div>
      </div>
      <div>
        <h3>Betting Instructions</h3>
        <div>
          <ul>
            <li>
              If a game round is in progress when you enter the game, please
              wait for the next one, and then, place your bets.
            </li>
            <li>After the timer reaches 0, the game round begins.</li>
            <li>
              To place a bet, click on the box, enter the stake amount and
              submit by clicking on Place Bet button.
            </li>
            <li>
              You can place several bets on different runners in different
              markets simultaneously.
            </li>
            <li>
              The timer in the game window shows how much time you have left to
              place your bets.
            </li>
            <li>
              Winnings are paid for the winning bets at the end of each game
              round.
            </li>
            <li>To skip a turn, simply do not place any bets.</li>
          </ul>
        </div>
      </div>
      <div>
        <h4>Cards combinations</h4>
        <div>
          <p>
            <strong>High Card</strong> - Any hand that does not form any of the
            below-mentioned combinations. The Hand with the highest card wins.
          </p>
          <div>
            <img src={poker1} alt="poker2020" />
          </div>
        </div>
        <div>
          <p>
            <strong>Pair</strong>- Any two cards of the same rank (two aces, two
            kings and etc.) If several Hands have a Pair, the Hand with the
            higher Pair wins. If several Hands have the same rank pair, the Hand
            with the higher kicker wins.
          </p>
          <div>
            <img src={poker2} alt="poker2020" />
          </div>
        </div>
        <div>
          <p>
            <strong>Two Pair</strong>- Any two cards of the same rank together
            with another two cards of the same rank. If several Positions have a
            Two pair Hand, the Hand with the higher pairs wins. If two players
            have the same rank pairs, the Hand with the higher kicker wins.
          </p>
          <div>
            <img src={poker3} alt="poker2020" />
          </div>
        </div>
        <div>
          <p>
            <strong>Three of a kind</strong>- Any three cards of the same rank.
            If several Hands have Three of a kind, the Hand with the higher
            Three of a kind wins. If two Hands have the same Three of a kind,
            the Hand with the higher fourth or/and fifth card wins
          </p>
          <div>
            <img src={poker4} alt="poker2020" />
          </div>
        </div>
        <div>
          <p>
            <strong>Straight</strong>- Any five consecutive cards of different
            suits. Aces can count as either a low card (A-2-3-4-5) or a high
            (10-J-Q-K-A).
          </p>
          <div>
            <img src={poker5} alt="poker2020" />
          </div>
        </div>
        <div>
          <p>
            <strong>Flush</strong>- Any non-consecutive five (5) cards of the
            same suit. If several Hands have a Flush, the Hand with the higher
            pocket card(s) used to form the best hand wins.
          </p>
          <div>
            <img src={poker6} alt="poker2020" />
          </div>
        </div>
        <div>
          <p>
            <strong>Full House</strong>- Any three cards of the same rank
            together with any two cards of the same rank. If several Hands have
            a Full House, the Hand with the highest Three of a kind wins. If
            several Hands have the same Three of a kind, the Hand with the
            higher Pair wins.
          </p>
          <div>
            <img src={poker7} alt="poker2020" />
          </div>
        </div>
        <div>
          <p>
            <strong>Four of a kind</strong>- Any four cards of the same rank. If
            several Hands have Four of a kind, the Hand with the higher Four of
            a kind wins. If several Hands have the same Four of a kind, the Hand
            with a higher kicker wins.
          </p>
          <div>
            <img src={poker8} alt="poker2020" />
          </div>
        </div>
        <div>
          <p>
            <strong>Straight Flush</strong>- Any straight with all five cards of
            the same suit. If several Hands have a Straight Flush, the Hand with
            the higher Straight Flush wins.
          </p>
          <div>
            <img src={poker9} alt="poker2020" />
          </div>
        </div>
        <div>
          <p>
            <strong>Royal Flush</strong>- A straight from Ten to Ace with all
            five (5) cards of the same suit. This is the strongest poker hand.
          </p>
          <div>
            <img src={poker10} alt="poker2020" />
          </div>
          <p>
            Two hands that are identical, card for card, are tied since the
            suits have no relative rank in Poker. In such a case, the tied
            players split the pot. Note that if two hands contain the same high
            pair, then the ranking of the next card in the hands determines
            which one wins. For example: 9, 9, 7, 4, 2 beats 9, 9, 5, 3, 2.
            Likewise, two hands that have identical pairs would be decided by
            the fifth card. For example: Q, Q, 6, 6, J beats Q, Q, 6, 6, 10.
          </p>
        </div>
      </div>
      <div>
        <h3>Main Bets</h3>
        <ol>
          <li>Winner A</li>
          <li>Winner B</li>
        </ol>
      </div>
      <div>
        <h3>Side Bets</h3>
        <ol>
          <li>One Pair</li>
          <li>Two Pair</li>
          <li>Three of a Kind</li>
          <li>Straight</li>
          <li>Flush</li>
          <li>Full House</li>
          <li>Four of a kind</li>
          <li>Straight Flush</li>
        </ol>
      </div>
      <div>
        <h3>PAYOUTS</h3>
        <div>
          <table>
            <tbody>
              <tr>
                <th>One Pair</th>
                <th>1: 1</th>
              </tr>
              <tr>
                <th>Two Pair</th>
                <th>1: 2.75</th>
              </tr>
              <tr>
                <th>Three of a Kind</th>
                <th>1: 15</th>
              </tr>
              <tr>
                <th>Straight</th>
                <th>1: 15</th>
              </tr>
              <tr>
                <th>Flush</th>
                <th>1: 25</th>
              </tr>
              <tr>
                <th>Full House</th>
                <th>1: 28</th>
              </tr>
              <tr>
                <th>Four of a Kind</th>
                <th>1: 250</th>
              </tr>
              <tr>
                <th>Straight Flush</th>
                <th>1: 500</th>
              </tr>
            </tbody>
          </table>
          <p>
            Please note that any malfunction voids the game round and all
            eventual payouts for the round. Bets will be returned.
          </p>
        </div>
      </div>
    </div>
  )
}
export default Poker2020Rules
