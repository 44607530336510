import React from 'react'

import trioBettingHistory from './TrioRulesImages/trioBettingHistory.png'
import trioLastResults from './TrioRulesImages/trioLastResults.png'
import trioLastResultsPopUp from './TrioRulesImages/trioLastResultsPopUp.png'
import trioRoundDetailes from './TrioRulesImages/trioRoundDetailes.png'

const TrioRules = () => {
  return (
    <div className="rulesContainer">
      <h2>GAME OBJECTIVES</h2>
      <p>
        The game of Bollywood Casino is a game of three cards. The objective is
        to predict that if the total value of three cards will be greater then
        or less then 21.
      </p>

      <h2>GAME RULES</h2>
      <ul>
        <li>When the game round starts, the markets are opened for Betting.</li>
        <li>After the markets are suspended, dealer deals three cards.</li>
        <li>The winnings are paid to users according to their bets.</li>
      </ul>
      <h3>Betting Instructions</h3>
      <ul>
        <li>
          If a game round is in progress when you enter the game, please wait
          for the next one, and then, place your bets.
        </li>
        <li>After the timer reaches 0, the game round begins.</li>
        <li>
          To place a bet, click on the box, enter the stake amount and submit by
          clicking on Place Bet button.
        </li>
        <li>
          You can place several bets on different runners in different markets
          simultaneously.
        </li>
        <li>
          The timer in the game window shows how much time you have left to
          place your bets.
        </li>
        <li>
          Winnings are paid for the winning bets at the end of each game round.
        </li>
        <li>To skip a turn, simply do not place any bets.</li>
      </ul>

      <h3>Main Bets</h3>
      <ol>
        <li>21 Yes</li>
      </ol>

      <h3>Side Bets</h3>
      <ol>
        <li>
          3 Card Judgement(1,2,4) - Predict if one or more cards out of three
          will be an Ace, 2 or a 4.
        </li>
        <li>
          3 Card Judgement(J,Q,K) - Predict if one or more cards out of three
          will be a Jack, Queen or a King.
        </li>
        <li>
          Two Red Only - Predict if there will be two Red cards only in the game
          (not more not less).
        </li>
        <li>
          Two Black Only - Predict if there will be two Black cards only in the
          game (not more not less).
        </li>
        <li>
          Two Odd Only - Predict if there will be two Odd cards only in the game
          (not more not less).
        </li>
        <li>
          Two Even Only - Predict if there will be two Even cards only in the
          game (not more not less).
        </li>
        <li>Pair - Predict if the cards will form a Pair combination.</li>
        <li>Flush - Predict if the cards will form a Flush combination.</li>
        <li>
          Straight - Predict if the cards will form a Straight combination.
        </li>
        <li>Trio - Predict if the cards will form a Trio combination.</li>
        <li>
          Straight Flush - Predict if the cards will form a Straight Flush
          combination.
        </li>
      </ol>

      {/* <h2>LAST RESULTS</h2>
      <p>Last 9 round results can be found at the bottom</p>
      <img src={trioLastResults} alt="trioLastResults" />
      <p>Detailed last result pop up</p>
      <img src={trioLastResultsPopUp} alt="trioLastResultsPopUp" />

      <h2>BETTING HISTORY</h2>
      <p>
        Betting history menu returns the list of all bets made in all of the
        games.
      </p>
      <img src={trioBettingHistory} alt="trioBettingHistory" />
      <p>
        Detailed view of each played round is accessible by clicking on an entry
        from the list
      </p>
      <img src={trioRoundDetailes} alt="trioRoundDetailes" /> */}
    </div>
  )
}
export default TrioRules
