import "./RoulettePL.scss";
import React, { useEffect, useState } from 'react'

const RoulettePL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {};
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
       });
     });
   }
   setWinnerData(winObj)
  }

  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

    return (
      <div className="roulette-betting">
        <div className="roulette-betting__games">
          <div className={`roulette__block roulette__block__${winnerData && winnerData['Color']}`}>
            {roundMarkets && roundMarkets[0].indexCard[0] }
          </div>
        </div>
        <div className="roulette-betting__info-column">
          <div className="roulette-betting__info-row">
            <div className="roulette-betting__info-row-title">
              <span>Number:</span>
            </div>
            <div className="roulette-betting__info-row-results">
              <span>{winnerData && winnerData['Match Odds'] }</span>
            </div>
          </div>
          <div className="roulette-betting__info-row">
            <div className="roulette-betting__info-row-title">
              <span>Color:</span>
            </div>
            <div className="roulette-betting__info-row-results">
              <span>{winnerData &&  winnerData['Color'] }</span>
            </div>
          </div>
          <div className="roulette-betting__info-row">
            <div className="roulette-betting__info-row-title">
              <span>Odd Even:</span>
            </div>
            <div className="roulette-betting__info-row-results">
              <span>{winnerData &&  winnerData['Odd Even'] }</span>
            </div>
          </div>
          <div className="roulette-betting__info-row">
            <div className="roulette-betting__info-row-title">
              <span>High Low:</span>
            </div>
            <div className="roulette-betting__info-row-results">
              <span>{winnerData &&  winnerData['High Low'].slice(0,4) }</span>
            </div>
          </div>
          <div className="roulette-betting__info-row">
            <div className="roulette-betting__info-row-title">
              <span>Dozens:</span>
            </div>
            <div className="roulette-betting__info-row-results">
              <span>{winnerData &&  winnerData['Dozens'].slice(0,3)}</span>
            </div>
          </div>
          <div className="roulette-betting__info-row">
            <div className="roulette-betting__info-row-title">
              <span>Column:</span>
            </div>
            <div className="roulette-betting__info-row-results">
              <span>{winnerData &&  winnerData['Column'].slice(0,3)}</span>
            </div>
          </div>
        </div>
      </div>


    );
};

export default RoulettePL;

