import { createSlice } from '@reduxjs/toolkit'

export const bonusWageringInfoSlice = createSlice({
    name: 'bonusWageringInfo',
    initialState: null,
    reducers: {
        getBonusWageringInfoSuccess: (state, action) => action.payload,
        getBonusWageringInfoError: (state) => state,
    },
})

export const { getBonusWageringInfoSuccess, getBonusWageringInfoError } = bonusWageringInfoSlice.actions

export default bonusWageringInfoSlice.reducer
