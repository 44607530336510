import { createSlice } from '@reduxjs/toolkit'

export const slotsPopUp = createSlice({
  name: 'slotsPopUp',
  initialState: {
    isPopUpOpen: false,
  },
  reducers: {
    setIsPopUpOpen: (state, action) => ({ ...state, isPopUpOpen: action.payload }),
  },
})

export const { 
  setIsPopUpOpen, 
} = slotsPopUp.actions

export default slotsPopUp.reducer
