/*
 * Request user authorization
 */
import httpAuth from "../../services/httpAuth";
import getErrorMessage from "../../helpers/getErrorMessage.helper";
import jwt from "jsonwebtoken";
import { getAuthSuccess, getAuthError, logoutSuccess } from "./auth.slice";
import { setAuthDataSuccess, setAuthDataError } from "./authData.slice";
import { resetUserData, setUserDataSuccess } from "../user/user.slice";
import { userLoggedOut } from "../user/login.slice";
import { ID_TOKEN, USER_DATA, TOKEN_EXP } from "../../config/app.config";
import { menuController } from "@ionic/core";
import { setB2C } from "../B2C/b2c.slice";
import { CONFIG } from "../../config/api.config";
import { toastr } from "react-redux-toastr";

// update token if user places a new bet
const updateToken = (token) => async (dispatch) => {
  try {
    if (!token) throw new Error("Response has no token");

    const decoded = jwt.decode(token, { complete: true });
    dispatch(getAuthSuccess(token));

    dispatch(setAuthDataSuccess(decoded));
    localStorage.setItem(ID_TOKEN, token);
    localStorage.setItem(TOKEN_EXP, decoded.payload.exp);
  } catch (e) {
    dispatch(getAuthError());
    dispatch(setAuthDataError());
    // const errorMsg = getErrorMessage(e)
    // throw errorMsg
  }
};

export const requestAuth = (data) => async (dispatch) => {
  try {
    const response = await httpAuth.post("/auth/b2b/login", data);
    if (response?.data?.success) {
      const userData = response?.data?.result;
      if (userData?.settings?.oneClickSettings?.active) userData.settings.oneClickSettings.active = false;

      dispatch(setUserDataSuccess(userData));
      localStorage.setItem(USER_DATA, JSON.stringify(userData));
      dispatch(
        setB2C(response?.data?.result.isB2C || false)
      );
      localStorage.setItem("B2C", response?.data?.result?.isB2C || false);
      localStorage.setItem("isLogin", true );

      try {
        var loginName = userData.loginName;

        if (loginName.includes(".")) {
          loginName = loginName.split(".")[1];
        }

        if (loginName.includes("_")) {
          loginName = loginName.split("_")[1];
        }

        if (CONFIG.appName === "LOTUS 365" || window.document.location.hostname.includes('localhost')) {
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "p8qbzn7b",
            name: loginName, // Full name
            user_id: userData.memberCode
          };
          window.Intercom("update");
        } else {
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "wtcglvc8",
            name: loginName, // Full name
            user_id: userData.memberCode
          };
          window.Intercom("update");
        }


      } catch (e) {
        console.log(e);
      }

      try {
        // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

        // To set unique user id in your system when it is available
        window.fcWidget.setExternalId(userData.loginName.includes(".") ? userData.loginName.split(".")[1] : userData.loginName);

        // To set user name
        // window.fcWidget.user.setFirstName('John')

        // To set user properties
        window.fcWidget.user.setProperties({
          plan: "Pro",                 // meta property 1
          status: "Active"                // meta property 2
        });
      } catch (e) {
      }



      if (!response.headers["authorization"]) throw new Error("Response has no token");
      dispatch(updateToken(response.headers["authorization"]));
    }
    if (localStorage.getItem("page") && localStorage.getItem("page").includes("sportsbook")) {
      // localStorage.setItem('page', '/default-page')
      // localStorage.removeItem('page')
      window.location.reload();
    }
    return response;
  } catch (e) {
    dispatch(getAuthError());
    //TODO: handle this
    const errorMsg = getErrorMessage(e);
    throw errorMsg;
  }
};

export const requestLogout = () => async (dispatch) => {
  try {
    menuController.close();

    dispatch(userLoggedOut());
    dispatch(logoutSuccess());
    dispatch(resetUserData());
    dispatch(setAuthDataError());

    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(TOKEN_EXP);
    localStorage.removeItem(USER_DATA);
    localStorage.removeItem("B2C");
    window.location.reload();
  } catch (e) {
    dispatch(getAuthError());
  }
};

export const requestContinueSession = (data) => async (dispatch) => {
  try {
    const response = await httpAuth.post("/auth/keep-alive", data);
    if (response.headers["authorization"]) {
      dispatch(updateToken(response.headers["authorization"]));
    }
    // await httpAuth.post('/timing', response.headers)
    return response;
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
};
