import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { BASE_DEV_URL } from '../../config/api.config'
import { ID_TOKEN } from '../../config/app.config'
import axios from 'axios'
import Card from './Card'
import { IonContent, IonHeader, IonPage, useIonViewDidEnter } from "@ionic/react";
import Toolbar from '../../components/Toolbar/Toolbar'
import './index.css'

const width = window.innerWidth

// const info = [
//   {
//     BONUS_ID: 2307161949134290,
//     PROMO_CODE: 'TST_V_5',
//     AMOUNT: 400,
//     ISSUE_DATE: '2023-07-16T19:49:13.429',
//     ACTIVATION_DATE: null,
//     EXPIRY_DATE: null,
//     REWARD_DATE: null,
//     COMMENT: 'bonus',
//     STS_ID: 10,
//     wagered: {},
//     promoDetails: {
//       promoCode: 'UNIC100',
//       promoType: 'DEPOSIT',
//       tagLine: 'Get 100% UPTO ₹3000 Bonus (Min Deposit 1000)',
//       'short-info': 'Play for win, only on Uniconbet',
//       wageredCap: 60000,
//       perMarketCap: 3000,
//       minDeposit: 1000,
//       wageringMultiplier: 20,
//       evolutionMultiplier: 0.05,
//       evolutionPerMarketMultiplier: 1,
//       teenpattiMultiplier: 0.05,
//       teenpattiPerMarketMultiplier: 1,
//       exchangeMultiplier: 1,
//       exchangePerMarketMultiplier: 1,
//       fancyMultiplier: 0,
//       fancyPerMarketMultiplier: 0,
//       validity: 7,
//       promoIcon:
//         'https://www.veloceinternational.com/wp-content/uploads/2021/07/image1-11.jpeg',

//       promoImageUrl: 'https://lotusbookgaming.com/gstatic/B2C/aurapromo1.jpg',
//       info: '',
//     },
//   },
//   {
//     BONUS_ID: 2307161949263000,
//     PROMO_CODE: 'TST_V_5',
//     AMOUNT: 600,
//     ISSUE_DATE: '2023-07-16T19:49:26.3',
//     ACTIVATION_DATE: '2023-07-16T19:55:39.099',
//     EXPIRY_DATE: '2023-07-23T19:55:39',
//     REWARD_DATE: null,
//     COMMENT: 'bonus',
//     STS_ID: 20,
//     wagered: {
//       loginName: '120000000',
//       memberCode: 'T1010101M0E',
//       balance: 0.91,
//       txnNo: 8,
//       bonusAmount: 365,
//       promoCode: 'TST_V_5',
//       wageredCap: 4500,
//       bonusTxnDate: '2023-05-28T17:57:20.13',
//       bonusStatus: 20,
//       avgOdds: 0,
//       sportsWagered: 1095,
//       casinoWagered: 0,
//       teenpattiWagered: 0,
//       totalWagered: 1095,
//     },
//     promoDetails: {
//       promoCode: 'UNIC100',
//       promoType: 'DEPOSIT',
//       tagLine: 'Get 100% UPTO ₹3000 Bonus (Min Deposit 1000)',
//       'short-info': 'Play for win, only on Uniconbet',
//       wageredCap: 60000,
//       perMarketCap: 3000,
//       minDeposit: 1000,
//       wageringMultiplier: 20,
//       evolutionMultiplier: 0.05,
//       evolutionPerMarketMultiplier: 1,
//       teenpattiMultiplier: 0.05,
//       teenpattiPerMarketMultiplier: 1,
//       exchangeMultiplier: 1,
//       exchangePerMarketMultiplier: 1,
//       fancyMultiplier: 0,
//       fancyPerMarketMultiplier: 0,
//       validity: 7,
//       promoIcon:
//         'https://www.veloceinternational.com/wp-content/uploads/2021/07/image1-11.jpeg',

//       promoImageUrl: 'https://lotusbookgaming.com/gstatic/B2C/aurapromo1.jpg',
//       info: '',
//     },
//   },
//   {
//     BONUS_ID: 2307161949263000,
//     PROMO_CODE: 'TST_V_5',
//     AMOUNT: 600,
//     ISSUE_DATE: '2023-07-16T19:49:26.3',
//     ACTIVATION_DATE: '2023-07-16T19:55:39.099',
//     EXPIRY_DATE: '2023-07-23T19:55:39',
//     REWARD_DATE: null,
//     COMMENT: 'bonus',
//     STS_ID: 30,
//     wagered: {
//       loginName: '120000000',
//       memberCode: 'T1010101M0E',
//       balance: 0.91,
//       txnNo: 8,
//       bonusAmount: 365,
//       promoCode: 'TST_V_5',
//       wageredCap: 4500,
//       bonusTxnDate: '2023-05-28T17:57:20.13',
//       bonusStatus: 20,
//       avgOdds: 0,
//       sportsWagered: 1095,
//       casinoWagered: 0,
//       teenpattiWagered: 0,
//       totalWagered: 1095,
//     },
//     promoDetails: {
//       promoCode: 'UNIC100',
//       promoType: 'DEPOSIT',
//       tagLine: 'Get 100% UPTO ₹3000 Bonus (Min Deposit 1000)',
//       'short-info': 'Play for win, only on Uniconbet',
//       wageredCap: 60000,
//       perMarketCap: 3000,
//       minDeposit: 1000,
//       wageringMultiplier: 20,
//       evolutionMultiplier: 0.05,
//       evolutionPerMarketMultiplier: 1,
//       teenpattiMultiplier: 0.05,
//       teenpattiPerMarketMultiplier: 1,
//       exchangeMultiplier: 1,
//       exchangePerMarketMultiplier: 1,
//       fancyMultiplier: 0,
//       fancyPerMarketMultiplier: 0,
//       validity: 7,
//       promoIcon:
//         'https://www.veloceinternational.com/wp-content/uploads/2021/07/image1-11.jpeg',
//       promoImageUrl: 'https://lotusbookgaming.com/gstatic/B2C/aurapromo1.jpg',
//       info: '',
//     },
//   },
// ]

export default function Bonuses() {
  const history = useHistory()
  const [bonuses, setBonuses] = useState([])
  const [activeBonuses, setActiveBonuses] = useState([])
  const [awaitingBonuses, setAwaitingBonuses] = useState([])
  const [expiredBonuses, setExpiredBonuses] = useState([])

  const gotoEvent = () => {
    console.log('clicked')

    history.push('/')
  }

  useIonViewDidEnter(() => {
    const token = localStorage.getItem(`${ID_TOKEN}`)

    console.log('mounted')
    token &&
      axios
        .get(`${BASE_DEV_URL}/account/bonuses?bonusType=all`, {
          headers: {
            Authorization: token,
          },
        })
        .then(function (response) {
          console.log(response)
          setBonuses(response.data.result)
          response.data.result.length !== 0 &&
          setActiveBonuses(response.data.result.filter(a => a.STS_ID === 20))
          setExpiredBonuses(response.data.result.filter(a => a.STS_ID === 30))
          setAwaitingBonuses(response.data.result.filter(a => a.STS_ID === 10))
            // response.data.result.map((bonus) => {
            //   if (bonus.STS_ID === 20) {
            //     setActiveBonuses((prev) => [...prev, bonus])
            //   } else if (bonus.STS_ID === 30) {
            //     setExpiredBonuses((prev) => [...prev, bonus])
            //   } else if (bonus.STS_ID === 10) {
            //     setAwaitingBonuses((prev) => [...prev, bonus])
            //   }
            // })
        })
        .catch(function (error) {
          console.log(error)
        })
    // info.map((bonus) => {
    //   if (bonus.STS_ID === 20) {
    //     setActiveBonuses((prev) => [...prev, bonus])
    //   } else if (bonus.STS_ID === 30) {
    //     setExpiredBonuses((prev) => [...prev, bonus])
    //   } else if (bonus.STS_ID === 10) {
    //     setAwaitingBonuses((prev) => [...prev, bonus])
    //   }
    // })
  })

  return (
    <IonPage>
      <div className="lg:hidden">
        <IonHeader>
          <Toolbar />
        </IonHeader>
      </div>
      <IonContent>
        <div className="bonuses">
          {/* <img
        className="h-24"
        src="https://img.freepik.com/free-psd/cartoon-eid-gift-box_187299-9018.jpg?w=1800&t=st=1689583554~exp=1689584154~hmac=4baa8b0a7483818150c0245ff234736299fccc1159c84a085e3d0556020fcc1c"
      /> */}
          {/* <div className="flex flex-col bg-gradient-to-r from-blue-400 to-emerald-400 gap-2 rounded-lg p-4 "> */}
          <div className="bonuses-wrapp">
            <div className="bonuses-conteiner">
              {/* <svg
              onClick={gotoEvent}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg> */}
              <span className="bonuses-text">
                Your Bonuses
              </span>
            </div>

            {/* <span className="flex-grow text-center text-green-500 text-4xl font-bold tracking-wider">
          ₹400
        </span>
        <span className="flex-grow text-center text-slate-400 text-lg font-bold tracking-wider">
          Total rewards
        </span> */}
          </div>
          {/* <span className="flex-grow  text-slate-800 text-lg font-semibold tracking-wider">
        Active bonus
      </span> */}
          <div className="bonuses-deposit">
            {activeBonuses?.map((x, index) => (
              <Card
                expired={false}
                key={index}
                activeBonusAvailable={activeBonuses.length !== 0}
                x={x}
              />
            ))}
            {/* </div>
          
          <div className="flex flex-wrap w-full gap-2 px-4 "> */}
            {awaitingBonuses?.map((x, index) => (
              <Card
                expired={false}
                key={index}
                activeBonusAvailable={activeBonuses.length !== 0}
                x={x}
              />
            ))}
            {/* </div>
       
          <div className="flex flex-wrap w-full gap-2 px-4 "> */}
            {expiredBonuses?.map((x, index) => (
              <Card
                expired={true}
                key={index}
                activeBonusAvailable={activeBonuses.length !== 0}
                x={x}
              />
            ))}
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
