import './Login-popover.scss'
import { IonButton, IonImg, IonPopover } from '@ionic/react'
import React from 'react'

const LoginPopover = ({
  title,
  content,
  footer,
  image,
  open,
  onDidDismiss,
}) => {
  return (
    <IonPopover isOpen={open} onDidDismiss={onDidDismiss}>
      <div className="login-popover__content-wrapper">
        <div className="login-popover__content-header">
          <IonImg className="login-popover__header-logo" src={image} />
          <span>{title}</span>
        </div>
        <div
          className="login-popover__content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
      {footer && (
        <div className="login-popover__footer">
          <IonButton
            mode="md"
            onClick={() =>
              window.open('https://www.cyberpatrol.com/', '_system')
            }
            class="login-popover__left-btn"
          >
            Cyberpatrol
          </IonButton>
          <IonButton
            mode="md"
            onClick={() =>
              window.open('https://www.cybersitter.com/', '_system')
            }
            class="login-popover__right-btn"
          >
            Cybersitter
          </IonButton>
        </div>
      )}
    </IonPopover>
  )
}

export default LoginPopover
