import { createSlice } from '@reduxjs/toolkit'

export const roundMarketsSlice = createSlice({
    name: 'roundMarkets',
    initialState: null,
    reducers: {
        getRoundMarketsSuccess: (state, action) => action.payload,
        getRoundMarketsError: (state) => state,
    },
})

export const { getRoundMarketsSuccess, getRoundMarketsError } = roundMarketsSlice.actions

export default roundMarketsSlice.reducer
