import React from 'react'

import threecardpuresequence from './images/3cardpuresequence.png'
import threecardsequence from './images/3cardsequence.png'
import threeofakind from './images/3ofakind.png'
import fourcardcolor from './images/4cardcolor.png'
import fourcardpuresequence from './images/4cardpuresequence.png'
import fourcardsequence from './images/4cardsequence.png'
import fourofakind from './images/4ofakind.png'
import fourofakindonecolor from './images/4ofakindonecolor.png'

const TwoCardTeenpattiRules = () => {
  return (
    <div className="rulesContainer">
      <h2>GAME OBJECTIVES</h2>
      <p>
        The objective of 2 Card Teenpatti is to predict that which player will
        have the better combination of cards according to the rules of the game.
        It is played with regular 52 card deck.
      </p>
      <p>
        Please note that combinations rankings in 2 Card Teenpatti defers from
        the one of normal Teenpatti rankings.
      </p>
      <h2>GAME RULES</h2>
      <h3>Main:</h3>
      <p>
        In case of consecutive cards, the third card is to be considered in
        ascending order only. For example:
      </p>
      <ul>
        <li>
          if the first two cards are king & ace then the third card is 2, so it
          becomes: k, A & 2 (which is not sequence).
        </li>
        <li>
          If the first two cards are 2 & 3, then third card is 4, so it becomes
          2,3,4 (it will not be 1,2,3).
        </li>
        <li>The sequence in order from 1st to last is listed below:</li>
        <table
          className="rulesTable"
          style={{ width: '50%', margin: '15px auto' }}
        >
          <tbody>
            <tr>
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>Queen & King</span>
                  <span>1st</span>
                </div>
              </th>{' '}
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>7 & 8</span>
                  <span>7st</span>
                </div>
              </th>
            </tr>
            <tr>
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>Ace & 2</span>
                  <span>2nd</span>
                </div>
              </th>{' '}
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>6 & 7</span>
                  <span>68th</span>
                </div>
              </th>
            </tr>
            <tr>
              <th>
                <div className="twoCardTeenpattiTable">
                  {' '}
                  <span>Jack & Queen</span>
                  <span>3rd</span>
                </div>
              </th>{' '}
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>5 & 6</span>
                  <span>9th</span>
                </div>
              </th>
            </tr>
            <tr>
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>10 & Jack</span>
                  <span>4th</span>
                </div>
              </th>{' '}
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>4 & 5 </span>
                  <span>10th</span>
                </div>
              </th>
            </tr>
            <tr>
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>9 & 10</span>
                  <span>5th</span>
                </div>
              </th>{' '}
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>3 & 4 </span>
                  <span>11th</span>
                </div>
              </th>
            </tr>
            <tr>
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>8 & 9</span>
                  <span>6th</span>
                </div>
              </th>{' '}
              <th>
                <div className="twoCardTeenpattiTable">
                  <span>2 & 3 </span>
                  <span>12th</span>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
        <li>
          If it is alternative cards eg. 6 & 8. Or 2 & 4 Or Q & A. This type of
          alternative game will not be considered as a sequence.
        </li>
        <li>If it comes 4 & 4 this will be considered as a trio of 4.</li>
        <li>
          Another example is Ace & Ace, which will be considered as trio of Ace.
        </li>
        <li>Best combination of games in order of 1st to last:</li>
        <ul>
          <p>→ Pure sequence: 1st best combination</p>
          <p>→ Trio (3 of a kind): 2nd best combination</p>
          <p>→ Sequence (straight): 3rd best combination</p>
          <p>→ colour (suits): 4th best combination</p>
        </ul>
        <li>After that, all the games will be valued of higher card.</li>
      </ul>
      <h3>Mini Baccarat:</h3>
      <ul>
        <li>It is a comparison between the last digit of total of both.</li>
        <li>The sides Value of cards for baccarat is:</li>
        <ul>
          <li>Ace = one point</li>
          <li>2 = 2 point</li>
          <li>3 = 3 point</li>
          <li>4 = 4 point</li>
          <li>5 = 5 point</li>
          <li>6 = 6 point</li>
          <li>7 = 7 point</li>
          <li>8 = 8 point</li>
          <li>9 = 9 point</li>
          <li>10 = 0 point</li>
          <li>Jack = 0 point</li>
          <li>Queen = 0 point</li>
          <li>King = 0 point</li>
        </ul>
        <li>Total of two card can be ranged between 0 to 18.</li>
        <li>
          If total is in single digit then the same will be considered as
          Baccarat value
        </li>
        <li>
          If the total is of double digit, then the last digit will be
          considered as Baccarat value. Higher value Baccarat will win.
        </li>
        <li>
          If Baccarat value of both the sides are equal, then both sides will
          lose their bets.
        </li>
      </ul>

      <h3>Total:</h3>
      <ul>
        <li>Total of 2 cards value</li>
        <li>Value of each cards:</li>
        <ul>
          <li>Ace = 1 point</li>
          <li>2 = 2 point</li>
          <li>3 = 3 point</li>
          <li>4 = 4 point</li>
          <li>5 = 5 point</li>
          <li>6 = 6 point</li>
          <li>7 = 7 point</li>
          <li>8 = 8 point</li>
          <li>9 = 9 point</li>
          <li>10 = 0 point</li>
          <li>Jack = 11 point</li>
          <li>Queen = 12 point</li>
          <li>King = 13 point</li>
        </ul>
      </ul>

      <h3>Color Plus:</h3>

      <ul>
        <li>
          It contains seven circumstances to bet on simultaneously, however you
          can only win prize money on the item which has the higher rate.
        </li>
        <li>The seven outcomes on which you can bet are listed below:</li>
        <ul>
          <li>3 card sequence</li>
          <img src={threecardsequence} alt="threecardsequence" />
          <li>3 of a kind</li>
          <img src={threeofakind} alt="threeofakind" />
          <li>3 card pure sequence</li>
          <img src={threecardpuresequence} alt="threecardpuresequence" />
          <li>4 card color</li>
          <img src={fourcardcolor} alt="fourcardcolor" />
          <li>4 card sequence</li>
          <img src={fourcardsequence} alt="fourcardsequence" />
          <li>4 card pure sequence</li>
          <img src={fourcardpuresequence} alt="fourcardpuresequence" />
          <li>4 of a kind</li>
          <img src={fourofakind} alt="fourofakind" />
        </ul>
        <li>If your card is</li>
        <img src={fourofakindonecolor} alt="fourofakindonecolor" />
        <li>
          Here you will win prize in case there is a 4 card pure sequence
          only...! Hence they will not receive the prize of:
        </li>
        <ul>
          <li>3 card sequence</li>
          <li>4 card sequence</li>
          <li>4 card color</li>
          <li>3 card pure sequence</li>
        </ul>
        <li>
          If the cards are: King of Spades, King of Clubs, King of Diamonds,
          King of Hearts. In this instance you will only receive the prize of 4
          of a kind, therefore you will not win prize of 3 of a kind.
        </li>
        <li>
          You will only be able to win one prize, the one which is the most
          beneficial to you.
        </li>
      </ul>

      <h3>Betting Instructions</h3>
      <ul>
        <li>
          If a game round is in progress when you enter the game, please wait
          for the next one, and then, place your bets.
        </li>
        <li>After the timer reaches 0, the game round begins.</li>
        <li>
          To place a bet, click on the box, enter the stake amount and submit by
          clicking on Place Bet button.
        </li>
        <li>
          You can place several bets on different runners in different markets
          simultaneously.
        </li>
        <li>
          The timer in the game window shows how much time you have left to
          place your bets.
        </li>
        <li>
          Winnings are paid for the winning bets at the end of each game round.
        </li>
        <li>To skip a turn, simply do not place any bets.</li>
      </ul>
      <h3>Main Bets</h3>
      <ol>
        <li>Player A</li>
        <li>Player B</li>
      </ol>
      <h3>Side Bets</h3>
      <ol>
        <li>Mini Baccarat A</li>
        <li>Mini Baccarat B</li>
        <li>Total A</li>
        <li>Total B</li>
        <li>Color Plus</li>
      </ol>
    </div>
  )
}

export default TwoCardTeenpattiRules
