import { createSlice } from '@reduxjs/toolkit'
 
 export const bannerPopUpSlice = createSlice({
   name: 'bannerPopUp',
   initialState: false,
   reducers: {
     setBannerPopUp: (state, action) => action.payload,
   },
 })
 
 export const { setBannerPopUp } = bannerPopUpSlice.actions
 
 export default bannerPopUpSlice.reducer