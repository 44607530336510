import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getAccountReportError, getAccountReportSuccess } from './account-report.slice'


export const requestAccountReport = (memberCode, fromTo={fromDate: '', toDate: ''}) => async (dispatch) => {

    const currentTime = (new Date()).getTime();
    const lastEightDayTime = currentTime - (8 * 24 * 60 * 60 * 1000);
    try {
        if(fromTo.fromDate !== '' && fromTo.toDate !=='') {
            const response = await httpAuth.get(`/account/${memberCode}/reports/pnl?from=${fromTo.fromDate}&to=${fromTo.toDate}`)
            if (response.data.success) {
                dispatch(getAccountReportSuccess(response.data.result))
            }
            return response
        }
        else {
            const response = await httpAuth.get(`/account/${memberCode}/reports/pnl?from=${lastEightDayTime}&to=${currentTime}`)
            if (response.data.success) {
                dispatch(getAccountReportSuccess(response.data.result))
            }
            return response
        }

    } catch (e) {
        dispatch(getAccountReportError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}
