import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestSportbookStart, requestSportbookEnd } from '../../store/sportbookLive/sportbookLive.thunks';
import { setUrlForSportbook } from '../../store/betbyWidget/urlForSportbook.slice';

export const connectSportbook = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      sportbookLive: state.sportbookLive,
      urlSportbook: state.urlSportbook,
      elementsParams: state.elementsParams,
      B2C: state.B2C,
      auth: state.auth
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSportbookStart,
      requestSportbookEnd,
      setUrlForSportbook,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
