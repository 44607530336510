import React, { useEffect, useState } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./SixPlayerPoker.scss"
import { connectSixPlayerPoker } from './SixPlayerPoker.connect'
import CardsInfo from '../helpers/CardsInfo/CardsInfo'
import LastResults from '../helpers/LastResults/LastResults'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
// import BetPlacing from '../../BetPlacing/BetPlacing'
import { useParams } from 'react-router'
import WinnerCup from '../../../assets/images/TrophyTeenPattiTest.svg'


const SixPlayerPoker = ({exchangeGames, sportEvent, betPlacing, actions, exposure, activeTab, headerHeight, auth, elementsParams}) => {
 
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const {eventId} = useParams()

  const [hands, setHands] = useState(true)

  // sport event data
  const dataOdd = sportEvent.sportData
  // end sport event data

  // request Exchange Game data

  useEffect(() => {
    const timer = setIntervalAsync(async() => {
      await actions
              .requestExchangeGames(eventId)
              .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)
  }, [])
  // End request Exchange Game data

  // Show betslip
  // const renderBetPlacing = (runner) => {
  //   if (
  //     betPlacing.runner && width < 1024
  //   ) {
  //     return <BetPlacing headerHeight={headerHeight} />
  //   }
  //   return null
  // }
  // End Show betslip

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  // console.log('123', dataOdd);

  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing

  //get market price
  const getMarketPrice = (marketIndex, runnersIndex) => {
      return (
        dataOdd &&
        dataOdd[marketIndex] &&
        dataOdd[marketIndex].runners[runnersIndex] &&
        dataOdd[marketIndex].runners[runnersIndex].back[0] &&
        dataOdd[marketIndex].runners[runnersIndex].back[0].price
      )
  }
  //end get market price

  // get exposure
  const exposurePnl = (id) => {
    if(auth) {
      const objFinder =
      exposure?.pnlBySelection[
        Object.keys(exposure?.pnlBySelection).filter((item) =>
          item.includes(id)
        )
      ]?.pnl
      if (exchangeGames && exchangeGames[0]?.marketRunner[0]?.status === "WINNER" ||
          exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === "WINNER"){
            return null
          } else{
            return (
              <div
                className={
                  objFinder > 0
                    ? 'exposure-box-sixPlayerPoker exposure-sixPlayerPoker--pos'
                    : 'exposure-box-sixPlayerPoker exposure-sixPlayerPoker--neg'
                }
              >
                {exposure?.pnlBySelection[
                  Object.keys(exposure?.pnlBySelection).filter((item) =>
                    item.includes(id)
                  )
                ]?.pnl}
              </div>
      )}
    } else return
  }
  // end get exposure

  const winnerCup = (i, index) => {
    if(exchangeGames && exchangeGames[0]?.marketRunner[i]?.status === 'WINNER'){
      return (
        <img src={WinnerCup} alt="" />
      )
    } else {
      return (
        <>
          {dataOdd && dataOdd[0]?.runners[index]?.back[0]?.price || 0 || 0}
        </>
      )
    }
  }

  // lock market
  const resultStatus = (marketIndex) => {
    return !dataOdd[marketIndex].isBettable && 'lock-ui'
  }
  // end lock market

  return (
    <>
      <CardsInfo/>
      {/*{*/}
      {/*  width < 1024*/}
      {/*    ?*/}
      {/*    <div className="gc-game-title">*/}
      {/*      <div className="game-title">POKER 20-20</div>*/}
      {/*      <div className="game-round-id">*/}
      {/*        #{exchangeGames && exchangeGames[0]?.roundId}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    :*/}
      {/*    ""*/}
      {/*}*/}

      <div className={`SixPlayerPokerWrapper ${eventId === "67566" ? "virtual" : ""}  ${activeTab === 0 ? "fullHeight" : ""}`}>

        <div className='SixPlayerPoker-table'>
          {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
        {hands ?
          <>
          {width < 1024 && <div className='SixPlayerPoker-table-options'>
            <div
            style={eventId === "67566"
            ? {background: '#417A3C'}
            : {}}
            className='SixPlayerPoker-table-options-hands'
            onClick = {() => setHands(true)}>Hands</div>
            <div
            style={eventId === "67566"
            ? {color: '#5AA06B'}
            : {}}
            className='SixPlayerPoker-table-options-patterns'
            onClick = {() => setHands(false)}>Patterns</div>
          </div>}

          <div className='SixPlayerPoker-table-cardsContent'>

            {/* Player A */}
            <div className='SixPlayerPoker-table-cardsContent-cardsBox'>
              <div
              style={eventId === "67566" && width < 1024
              ? {color: '#fff'}
              : {}}
              className='SixPlayerPoker-table-cardsContent-cardsBox-title'>Player A</div>
              <div className={`SixPlayerPoker-table-cardsContent-cardsBox-wrapper ${dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[0]?.status === 'WINNER'
              ? ''
              : dataOdd[0]?.status === 'SUSPENDED'
              ? 'boxSuspended'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[0], true)
                  : null
              }>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-cards'>
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[0]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0])} alt="" />
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[0]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1])} alt="" />
                </div>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds'>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-price'>
                    {/* {dataOdd && dataOdd[0]?.runners[0]?.back[0]?.price || 0}
                    <img src={WinnerCup} alt="" /> */}
                    {winnerCup(0, 0)}
                  </div>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-exp'>
                    {exposurePnl(dataOdd && dataOdd[0]?.runners[0]?.id)}
                  </div>
                </div>
              </div>
            </div>

            {/* Player B */}
            <div className='SixPlayerPoker-table-cardsContent-cardsBox'>
              <div
              style={eventId === "67566" && width < 1024
              ? {color: '#fff'}
              : {}}
              className='SixPlayerPoker-table-cardsContent-cardsBox-title'>Player B</div>
              <div className={`SixPlayerPoker-table-cardsContent-cardsBox-wrapper ${dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === 'WINNER'
              ? ''
              : dataOdd[0]?.status === 'SUSPENDED'
              ? 'boxSuspended'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[1], true)
                  : null
              }>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-cards'>
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ?{opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0])} alt="" />
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1])} alt="" />
                </div>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds'>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-price'>
                    {/* {dataOdd && dataOdd[0]?.runners[1]?.back[0]?.price || 0} */}
                    {winnerCup(1, 1)}
                  </div>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-exp'>
                    {exposurePnl(dataOdd && dataOdd[0]?.runners[1]?.id)}
                  </div>
                </div>
              </div>
            </div>
            {/* Player C */}
            <div className='SixPlayerPoker-table-cardsContent-cardsBox'>
              <div
              style={eventId === "67566" && width < 1024
              ? {color: '#fff'}
              : {}}
              className='SixPlayerPoker-table-cardsContent-cardsBox-title'>Player C</div>
              <div className={`SixPlayerPoker-table-cardsContent-cardsBox-wrapper ${dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[2]?.status === 'WINNER'
              ? ''
              : dataOdd[0]?.status === 'SUSPENDED'
              ? 'boxSuspended'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[2], true)
                  : null
              }>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-cards'>
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[2]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[2]?.cards[0])} alt="" />
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[2]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[2]?.cards[1])} alt="" />
                </div>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds'>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-price'>
                    {/* {dataOdd && dataOdd[0]?.runners[2]?.back[0]?.price || 0} */}
                    {winnerCup(2, 2)}
                  </div>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-exp'>
                    {exposurePnl(dataOdd && dataOdd[0]?.runners[2]?.id)}
                  </div>
                </div>
              </div>
            </div>

             {/* Player D */}
             <div className='SixPlayerPoker-table-cardsContent-cardsBox'>
              <div
              style={eventId === "67566" && width < 1024
              ? {color: '#fff'}
              : {}}
              className='SixPlayerPoker-table-cardsContent-cardsBox-title'>Player D</div>
              <div className={`SixPlayerPoker-table-cardsContent-cardsBox-wrapper ${dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[3]?.status === 'WINNER'
              ? ''
              : dataOdd[0]?.status === 'SUSPENDED'
              ? 'boxSuspended'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[3], true)
                  : null
              }>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-cards'>
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[3]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[3]?.cards[0])} alt="" />
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[3]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[3]?.cards[1])} alt="" />
                </div>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds'>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-price'>
                    {/* {dataOdd && dataOdd[0]?.runners[3]?.back[0]?.price || 0} */}
                    {winnerCup(3, 3)}
                  </div>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-exp'>
                    {exposurePnl(dataOdd && dataOdd[0]?.runners[3]?.id)}
                  </div>
                </div>
              </div>
            </div>

             {/* Player E */}
             <div className='SixPlayerPoker-table-cardsContent-cardsBox'>
              <div
              style={eventId === "67566" && width < 1024
              ? {color: '#fff'}
              : {}}
              className='SixPlayerPoker-table-cardsContent-cardsBox-title'>Player E</div>
              <div className={`SixPlayerPoker-table-cardsContent-cardsBox-wrapper ${dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[4]?.status === 'WINNER'
              ? ''
              : dataOdd[0]?.status === 'SUSPENDED'
              ? 'boxSuspended'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[4], true)
                  : null
              }>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-cards'>
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[4]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[4]?.cards[0])} alt="" />
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[4]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[4]?.cards[1])} alt="" />
                </div>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds'>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-price'>
                    {/* {dataOdd && dataOdd[0]?.runners[4]?.back[0]?.price || 0} */}
                    {winnerCup(4, 4)}
                  </div>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-exp'>
                    {exposurePnl(dataOdd && dataOdd[0]?.runners[4]?.id)}
                  </div>
                </div>
              </div>
            </div>

             {/* Player F */}
             <div className='SixPlayerPoker-table-cardsContent-cardsBox'>
              <div
              style={eventId === "67566" && width < 1024
              ? {color: '#fff'}
              : {}}
              className='SixPlayerPoker-table-cardsContent-cardsBox-title'>Player F</div>
              <div className={`SixPlayerPoker-table-cardsContent-cardsBox-wrapper ${dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[5]?.status === 'WINNER'
              ? ''
              : dataOdd[0]?.status === 'SUSPENDED'
              ? 'boxSuspended'
              : ''}`}
              onClick={
                dataOdd[0]?.isBettable
                  ? () => select(dataOdd[0], dataOdd[0]?.runners[5], true)
                  : null
              }>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-cards'>
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[5]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[5]?.cards[0])} alt="" />
                  <img
                    style={dataOdd[0]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[0]?.marketRunner[5]?.status === 'WINNER'
                    ? {}
                    : dataOdd[0]?.status === 'SUSPENDED'
                    ? {opacity: '0.3'}
                    : {}}
                    className='SixPlayerPokerCard'
                    src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[5]?.cards[1])} alt="" />
                </div>
                <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds'>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-price'>
                    {/* {dataOdd && dataOdd[0]?.runners[5]?.back[0]?.price || 0} */}
                    {winnerCup(5, 5)}
                  </div>
                  <div className='SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-exp'>
                    {exposurePnl(dataOdd && dataOdd[0]?.runners[5]?.id)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {width >= 1024 &&
          <>
          <div className='SixPlayerPoker-table-patterns'>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[0], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[0]?.name}
                </div>
                {width < 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[0]?.id)}
                </div>}
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[0]?.back[0]?.price}
                {width > 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[0]?.id)}
                </div>}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[1], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[1]?.name}
                </div>
                {width < 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[1]?.id)}
                </div>}
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[1]?.back[0]?.price}
                {width > 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[1]?.id)}
                </div>}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[2], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[2]?.name}
                </div>
                {width < 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[2]?.id)}
                </div>}
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[2]?.back[0]?.price}
                {width > 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[2]?.id)}
                </div>}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[3], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[3]?.name}
                </div>
                {width < 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[3]?.id)}
                </div>}
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[3]?.back[0]?.price}
                {width > 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[3]?.id)}
                </div>}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[4], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[4]?.name}
                </div>
                {width < 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[4]?.id)}
                </div>}
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[4]?.back[0]?.price}
                {width > 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[4]?.id)}
                </div>}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[5], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[5]?.name}
                </div>
                {width < 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[5]?.id)}
                </div>}
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[5]?.back[0]?.price}
                {width > 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[5]?.id)}
                </div>}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[6], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[6]?.name}
                </div>
                {width < 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[6]?.id)}
                </div>}
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[6]?.back[0]?.price}
                {width > 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[6]?.id)}
                </div>}
              </div>
            </div>

            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[7], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[7]?.name}
                </div>
                {width < 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[7]?.id)}
                </div>}
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[7]?.back[0]?.price}
                {width > 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[7]?.id)}
                </div>}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[8], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[8]?.name}
                </div>
                {width < 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[8]?.id)}
                </div>}
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[8]?.back[0]?.price}
                {width > 1024 && <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[8]?.id)}
                </div>}
              </div>
            </div>
          </div>
          </>}
         </>
         :
         <>
          <div className='SixPlayerPoker-table-options'>
            <div
            style={eventId === "67566"
            ? {color: '#5AA06B'}
            : {}}
            className='SixPlayerPoker-table-options-patterns'
            onClick = {() => setHands(true)}>Hands</div>
            <div
            style={eventId === "67566"
            ? {background: '#417A3C'}
            : {}}
            className='SixPlayerPoker-table-options-hands'
            onClick = {() => setHands(false)}>Patterns</div>
          </div>

          <div className='SixPlayerPoker-table-patterns'>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[0], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[0]?.name}
                </div>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[0]?.id)}
                </div>
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[0]?.back[0]?.price}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[1], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[1]?.name}
                </div>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[1]?.id)}
                </div>
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[1]?.back[0]?.price}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[2], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[2]?.name}
                </div>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[2]?.id)}
                </div>
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[2]?.back[0]?.price}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[3], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[3]?.name}
                </div>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[3]?.id)}
                </div>
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[3]?.back[0]?.price}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[4], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[4]?.name}
                </div>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[4]?.id)}
                </div>
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[4]?.back[0]?.price}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[5], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[5]?.name}
                </div>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[5]?.id)}
                </div>
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[5]?.back[0]?.price}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[6], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[6]?.name}
                </div>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[6]?.id)}
                </div>
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[6]?.back[0]?.price}
              </div>
            </div>

            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[7], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[7]?.name}
                </div>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[7]?.id)}
                </div>
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[7]?.back[0]?.price}
              </div>
            </div>
            <div className={`SixPlayerPoker-table-patterns-box ${dataOdd && dataOdd[1]?.status === 'SUSPENDED' 
            ? 'boxSuspended'
            : ''}`}
            onClick={
              dataOdd[1]?.isBettable
                ? () => select(dataOdd[1], dataOdd[1]?.runners[8], true)
                : null
            }>
              <div className='SixPlayerPoker-table-patterns-box-nameExp'>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-name'>
                  {dataOdd && dataOdd[1]?.runners[8]?.name}
                </div>
                <div className='SixPlayerPoker-table-patterns-box-nameExp-exp'>
                  {exposurePnl(dataOdd && dataOdd[1]?.runners[8]?.id)}
                </div>
              </div>
              <div className='SixPlayerPoker-table-patterns-box-odds'>
                {dataOdd && dataOdd[1]?.runners[8]?.back[0]?.price}
              </div>
            </div>
          </div>
          </>}
        </div>
        <LastResults/>
      </div>
    </>
  )
}

export default connectSixPlayerPoker()(SixPlayerPoker)
