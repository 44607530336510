import {requestOpenBets, requestOpenBetsWithDate, requestPastBets} from "../../store/openBets/openBets.thunks";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

export const connectMyOpenBets = (wrappedProps) => (component) => {
    function mapStateToProps (state) {
        return {
            openBets: state.openBets,
            timeSettings: state.timeSettings,
            elementsParams: state.elementsParams
        };
    }


    function mapDispatchToProps (dispatch) {
        const actions = {
            requestOpenBets,
            requestOpenBetsWithDate,
            requestPastBets,
        };

        return {actions: bindActionCreators(actions, dispatch)};
    }

    return connect(mapStateToProps, mapDispatchToProps)(component);
};

// import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
// import { requestInplayPopular } from '../../store/inplayPopular/inplayPopular.thunks'
// import { requestPromotions } from '../../store/promotions/promotions.thunks'
// import { getInplayPopularSuccess } from '../../store/inplayPopular/inplayPopular.slice'
// import { getPromotionsSuccess } from '../../store/promotions/promotions.slice'
// import { requestOrderCount } from '../../store/orderCount/orderCount.thunks'
// import { setLegalAgePopoverShow } from '../../store/modals/legalAgePopover.slice'
// import { setKycPopoverShow } from '../../store/modals/kycPopover.slice'
// import { setMoreRulesPopoverShow } from '../../store/modals/moreRulesPopover.slice'
//
// export const connectMyOpenBets = (wrappedProps) => (component) => {
//   function mapStateToProps(state) {
//     return {
//       lastActivityDate: state.balance.lastActivityDate,
//       inplayPopular: state.inplayPopular,
//       promotions: state.promotions,
//       legalAgePopoverShow: state.legalAgePopoverShow,
//       kycPopoverShow: state.kycPopoverShow,
//       moreRulesPopoverShow: state.moreRulesPopoverShow,
//       balanceSettings: state.balanceSettings,
//       betPlacing: state.betPlacing,
//       orderCount: state.orderCount,
//       timeSettings: state.timeSettings,
//       openBets: state.openBets,
//     }
//   }
//
//   function mapDispatchToProps(dispatch) {
//     const actions = {
//       requestOrderCount,
//       requestInplayPopular,
//       requestPromotions,
//       setLegalAgePopoverShow,
//       setKycPopoverShow,
//       setMoreRulesPopoverShow,
//       getInplayPopularSuccess,
//       getPromotionsSuccess,
//     }
//
//     return { actions: bindActionCreators(actions, dispatch) }
//   }
//
//   return connect(mapStateToProps, mapDispatchToProps)(component)
// }
