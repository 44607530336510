import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { connectBollywoodCasino } from './BollywoodCasino.connect';
import BetPlacing from '../../BetPlacing/BetPlacing';
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
import LastResults from '../helpers/LastResults/LastResults';
import CardsInfo from '../helpers/CardsInfo/CardsInfo';

import Heart from '../../../assets/images/exchangeGames/bollywoodCasino/heart.svg'
import HeartSusp from '../../../assets/images/exchangeGames/bollywoodCasino/heartSusp.svg'
import Diamond from '../../../assets/images/exchangeGames/bollywoodCasino/diamond.svg'
import DiamondSusp from '../../../assets/images/exchangeGames/bollywoodCasino/diamondSusp.svg'
import Club from '../../../assets/images/exchangeGames/bollywoodCasino/club.svg'
import Spade from '../../../assets/images/exchangeGames/bollywoodCasino/spade.svg'
import cardJ from '../../../assets/images/exchangeGames/bollywoodCasino/cardJ.svg'
import cardQ from '../../../assets/images/exchangeGames/bollywoodCasino/cardQ.svg'
import cardK from '../../../assets/images/exchangeGames/bollywoodCasino/cardK.svg'
import cardA from '../../../assets/images/exchangeGames/bollywoodCasino/cardA.svg'
import cardAsusp from '../../../assets/images/exchangeGames/bollywoodCasino/cardAsusp.svg'
import cardKsusp from '../../../assets/images/exchangeGames/bollywoodCasino/cardKsusp.svg'
import cardQsusp from '../../../assets/images/exchangeGames/bollywoodCasino/cardQsusp.svg'
import cardJsusp from '../../../assets/images/exchangeGames/bollywoodCasino/cardJsusp.svg'
import trophy from '../../../assets/images/TrophyTeenPattiTest.svg'
import "./BollywoodCasino.scss"


const BollywoodCasino = ({
  betPlacing,
  actions,
  exchangeGames,
  exposure,
  headerHeight,
  auth,
  elementsParams
 }) => {

  const {eventId} = useParams()

  const dataOdds = useSelector((state) => state.sportEvent.sportData)

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const exposurePnl = (id) => {
    if(auth) {
      const objFinder =
      exposure?.pnlBySelection[
        Object.keys(exposure?.pnlBySelection).filter((item) =>
          item.includes(id)
        )
      ]?.pnl
      return (
        <div
          className={
            objFinder > 0
              ? 'exposure-box-bollywood exposure-bollywood--pos'
              : 'exposure-box-bollywood exposure-bollywood--neg'
          }
        >
          {exposure?.pnlBySelection[
            Object.keys(exposure?.pnlBySelection).filter((item) =>
              item.includes(id)
            )
          ]?.pnl}
        </div>
      )
    } else return
  }

  const exposurePnlSp = (id) => {
    if(auth) {
      const objFinder =
      exposure?.pnlBySelection[
        Object.keys(exposure?.pnlBySelection).filter((item) =>
          item.includes(id)
        )
      ]?.pnl
      return (
        <div
          className={
            objFinder > 0
              ? 'exposure-box-bollywoodSp exposure-bollywood--pos'
              : 'exposure-box-bollywoodSp exposure-bollywood--neg'
          }
        >
          {exposure?.pnlBySelection[
            Object.keys(exposure?.pnlBySelection).filter((item) =>
              item.includes(id)
            )
          ]?.pnl}
        </div>
      )
    } else return
  }

  const exposureTrue = (id) => {
    if(auth) {
      const exp =
      exposure?.pnlBySelection[
        Object.keys(exposure?.pnlBySelection).filter((item) =>
          item.includes(id)
        )
      ]?.pnl
      return (
        exp
      )
    } else return
  }

  useEffect(() => {
      const timer = setIntervalAsync(async () => {
        await actions
                .requestExchangeGames(eventId)
                .catch((e) => console.log('requestInplayPopular error = ', e))
      }, GET_INPLAY_POPULAR_INTERVAL)
      return () => clearIntervalAsync(timer)
  }, [])

  const winnerCard = (card) => {
    return (
      <div className='cardWinner'>
        <div className='cardWinner-name'>{card}</div>
        <img className='cardWinner-heart' src={Heart} alt="" />
        <img className='cardWinner-diamond' src={Diamond} alt="" />
        <img className='cardWinner-club' src={Club} alt="" />
        <img className='cardWinner-spade' src={Spade} alt="" />
        <img className='cardWinner-trophy'src={trophy} alt="" />
      </div>
    )
  }



  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    // console.log('222222222222222222', oddData)
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    // console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }
  // console.log(betPlacing)


  return (
    <>
      <CardsInfo />
        <div className='BollywoodCasinoWrapper'>
          <div className='BollywoodCasino-table'>

            {/* row1 */}
            <div className='BollywoodCasino-table__row BollywoodCasino-table__row-items-wrapper'>

              {/* Don */}
              <div className='BollywoodCasino-table__row-item'>
                <div className='BollywoodCasino-table__row-item__title'>
                  A.&nbsp; {dataOdds && dataOdds[0]?.runners[0]?.name}
                </div>
                <div
                style={exposureTrue((dataOdds && dataOdds[0]?.runners[0]?.id) || exposureTrue(dataOdds && dataOdds[0]?.runners[0]?.id) === 0) && width < 1024
                  ? {height: '75px'}
                  : {}}
                className={`BollywoodCasino-table__row-item__box  ${ exchangeGames && exchangeGames[0]?.marketRunner[0]?.status === 'WINNER' 
                ? 'winnerBox'
                : ''}`}>
                  <div className={`BollywoodCasino-table__row-item__box__back ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'backSuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[0], true)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__back__price'>
                      {dataOdds && dataOdds[0]?.runners[0]?.back[0]?.price}
                    </span>
                  </div>
                  <div className={`BollywoodCasino-table__row-item__box__lay ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'laySuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[0], false)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__lay__price'>
                      {dataOdds && dataOdds[0]?.runners[0]?.lay[0]?.price}
                    </span>
                  </div>
                </div>
                <div className='exposure-box-bollywood'>
                  {exposurePnl(dataOdds && dataOdds[0]?.runners[0]?.id)}
                </div>
              </div>

              {/* Amar Akbar Anthony */}
              <div className='BollywoodCasino-table__row-item'>
                <div className='BollywoodCasino-table__row-item__title'>
                 B.&nbsp; {dataOdds && dataOdds[0]?.runners[1]?.name}
                </div>
                <div
                  style={exposureTrue((dataOdds && dataOdds[0]?.runners[1]?.id) || exposureTrue(dataOdds && dataOdds[0]?.runners[1]?.id) === 0) && width < 1024
                  ? {height: '75px'}
                  : {}}
                className={`BollywoodCasino-table__row-item__box ${ exchangeGames && exchangeGames[0]?.marketRunner[1]?.status === 'WINNER' 
                ? 'winnerBox'
                : ''}`}>
                  <div className={`BollywoodCasino-table__row-item__box__back ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'backSuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[1], true)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__back__price'>
                      {dataOdds && dataOdds[0]?.runners[1]?.back[0]?.price}
                    </span>
                  </div>
                  <div className={`BollywoodCasino-table__row-item__box__lay ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'laySuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[1], false)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__back__price'>
                      {dataOdds && dataOdds[0]?.runners[1]?.lay[0]?.price}
                    </span>
                  </div>
                </div>
                <div className='exposure-box-bollywood'>
                  {exposurePnl(dataOdds && dataOdds[0]?.runners[1]?.id)}
                </div>
              </div>

              {/* row2 */}


              {/* Sahib Bibi aur Gulam */}
              <div className='BollywoodCasino-table__row-item'>
                <div className='BollywoodCasino-table__row-item__title'>
                  C.&nbsp; {dataOdds && dataOdds[0]?.runners[2]?.name}
                </div>
                <div
                  style={exposureTrue((dataOdds && dataOdds[0]?.runners[2]?.id) || exposureTrue(dataOdds && dataOdds[0]?.runners[2]?.id) === 0) && width < 1024
                  ? {height: '75px'}
                  : {}}
                className={`BollywoodCasino-table__row-item__box ${ exchangeGames && exchangeGames[0]?.marketRunner[2]?.status === 'WINNER' 
                ? 'winnerBox'
                : ''}`}>
                  <div className={`BollywoodCasino-table__row-item__box__back ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'backSuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[2], true)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__back__price'>
                      {dataOdds && dataOdds[0]?.runners[2]?.back[0]?.price}
                    </span>
                  </div>
                  <div className={`BollywoodCasino-table__row-item__box__lay ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'laySuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[2], false)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__lay__price'>
                      {dataOdds && dataOdds[0]?.runners[2]?.lay[0]?.price}
                    </span>
                  </div>
                </div>
                <div className='exposure-box-bollywood'>
                  {exposurePnl(dataOdds && dataOdds[0]?.runners[2]?.id)}
                </div>
              </div>

              {/* Dharam Veer */}
              <div className='BollywoodCasino-table__row-item'>
                <div className='BollywoodCasino-table__row-item__title'>
                  D.&nbsp; {dataOdds && dataOdds[0]?.runners[3]?.name}
                </div>
                <div
                  style={exposureTrue((dataOdds && dataOdds[0]?.runners[3]?.id) || exposureTrue(dataOdds && dataOdds[0]?.runners[3]?.id) === 0) && width < 1024
                  ? {height: '75px'}
                  : {}}
                className={`BollywoodCasino-table__row-item__box ${ exchangeGames && exchangeGames[0]?.marketRunner[3]?.status === 'WINNER' 
                ? 'winnerBox'
                : ''}`}>
                  <div className={`BollywoodCasino-table__row-item__box__back ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'backSuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[3], true)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__back__price'>
                      {dataOdds && dataOdds[0]?.runners[3]?.back[0]?.price}
                    </span>
                  </div>
                  <div className={`BollywoodCasino-table__row-item__box__lay ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'laySuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[3], false)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__back__price'>
                      {dataOdds && dataOdds[0]?.runners[3]?.lay[0]?.price}
                    </span>
                  </div>
                </div>
                <div className='exposure-box-bollywood'>
                  {exposurePnl(dataOdds && dataOdds[0]?.runners[3]?.id)}
                </div>
              </div>

              {/* row3 */}


              {/* Kis Kisko Pyaar Karoon */}
              <div className='BollywoodCasino-table__row-item'>
                <div className='BollywoodCasino-table__row-item__title'>
                  E.&nbsp; {dataOdds && dataOdds[0]?.runners[4]?.name}
                </div>
                <div
                  style={exposureTrue((dataOdds && dataOdds[0]?.runners[4]?.id) || exposureTrue(dataOdds && dataOdds[0]?.runners[4]?.id) === 0) && width < 1024
                  ? {height: '75px'}
                  : {}}
                className={`BollywoodCasino-table__row-item__box ${ exchangeGames && exchangeGames[0]?.marketRunner[4]?.status === 'WINNER' 
                ? 'winnerBox'
                : ''}`}>
                  <div className={`BollywoodCasino-table__row-item__box__back ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'backSuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[4], true)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__back__price'>
                      {dataOdds && dataOdds[0]?.runners[4]?.back[0]?.price}
                    </span>
                  </div>
                  <div className={`BollywoodCasino-table__row-item__box__lay ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'laySuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[4], false)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__lay__price'>
                      {dataOdds && dataOdds[0]?.runners[4]?.lay[0]?.price}
                    </span>
                  </div>
                </div>
                <div className='exposure-box-bollywood'>
                  {exposurePnl(dataOdds && dataOdds[0]?.runners[4]?.id)}
                </div>
              </div>

              {/* Ghulam */}
              <div className='BollywoodCasino-table__row-item'>
                <div className='BollywoodCasino-table__row-item__title'>
                  F.&nbsp; {dataOdds && dataOdds[0]?.runners[5]?.name}
                </div>
                <div
                  style={exposureTrue((dataOdds && dataOdds[0]?.runners[5]?.id) || exposureTrue(dataOdds && dataOdds[0]?.runners[5]?.id) === 0) && width < 1024
                  ? {height: '75px'}
                  : {}}
                className={`BollywoodCasino-table__row-item__box ${ exchangeGames && exchangeGames[0]?.marketRunner[5]?.status === 'WINNER' 
                ? 'winnerBox'
                : ''}`}>
                  <div className={`BollywoodCasino-table__row-item__box__back ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'backSuspended'
                  : ''}`}
                    onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[5], true)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__back__price'>
                      {dataOdds && dataOdds[0]?.runners[5]?.back[0]?.price}
                    </span>
                  </div>
                  <div className={`BollywoodCasino-table__row-item__box__lay ${dataOdds[0]?.status === 'SUSPENDED' 
                  ? 'laySuspended'
                  : ''}`}
                  onClick={
                    dataOdds[0]?.isBettable
                      ? () => select(dataOdds[0], dataOdds[0]?.runners[5], false)
                      : null
                  }>
                    <span className='BollywoodCasino-table__row-item__box__back__price'>
                      {dataOdds && dataOdds[0]?.runners[5]?.lay[0]?.price}
                    </span>
                  </div>
                </div>
                <div className='exposure-box-bollywood'>
                  {exposurePnl(dataOdds && dataOdds[0]?.runners[5]?.id)}
                </div>
              </div>
            </div>

            {/* row4 */}
            <div className='BollywoodCasino-table__row'>

              <div className='BollywoodCasino-table__row__specialItem'>
                <div className='BollywoodCasino-table__row__specialItem__title'>Pair</div>

                {/* K-Q */}
                <div className='BollywoodCasino-table__row__specialItem__flex'>
                  <div className={`BollywoodCasino-table__row__specialItem__box  ${dataOdds[1]?.status === 'SUSPENDED' &&  exchangeGames && exchangeGames[1]?.marketRunner[0]?.status === 'WINNER'
                  ? 'winnerBoxPlus'
                  : exposureTrue(dataOdds && dataOdds[1]?.runners[0]?.id) 
                    && dataOdds[1]?.status === 'SUSPENDED'
                  ? 'boxSuspendedExp' 
                  : dataOdds[1]?.status === 'SUSPENDED'
                  ? 'boxSuspended'
                  : ''}`}
                    onClick={
                    dataOdds[1]?.isBettable
                      ? () => select(dataOdds[1], dataOdds[1]?.runners[0], true)
                      : null
                  }>
                    <div className='BollywoodCasino-table__row__specialItem__box__content'>
                      <div className='BollywoodCasino-table__row__specialItem__box__content-name'>K - Q</div>
                      <div
                      // style ={exchangeGames && exchangeGames[1]?.marketRunner[0]?.status === 'WINNER'
                      // ? {color: '#29516A'}
                      // : {}}
                      className='BollywoodCasino-table__row__specialItem__box__content-odds'>
                        <span>{dataOdds && dataOdds[1]?.runners[0]?.back[0]?.price}</span>
                        <div className='exposure-box-bollywoodSp'>
                          {exposurePnlSp(dataOdds && dataOdds[1]?.runners[0]?.id)}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* J-A */}
                  <div className={`BollywoodCasino-table__row__specialItem__box ${dataOdds[1]?.status === 'SUSPENDED' &&  exchangeGames && exchangeGames[1]?.marketRunner[1]?.status === 'WINNER'
                  ? 'winnerBoxPlus'
                  : exposureTrue(dataOdds && dataOdds[1]?.runners[1]?.id) 
                    && dataOdds[1]?.status === 'SUSPENDED'
                  ? 'boxSuspendedExp'  
                  : dataOdds[1]?.status === 'SUSPENDED'
                  ? 'boxSuspended'
                  : ''}`}
                    onClick={
                    dataOdds[1]?.isBettable
                      ? () => select(dataOdds[1], dataOdds[1]?.runners[1], true)
                      : null
                  }>
                    <div className='BollywoodCasino-table__row__specialItem__box__content'>
                      <div className='BollywoodCasino-table__row__specialItem__box__content-name'>J - A</div>
                      <div
                        // style ={exchangeGames && exchangeGames[1]?.marketRunner[1]?.status === 'WINNER'
                        // ? {color: '#29516A'}
                        // : {}}
                        className='BollywoodCasino-table__row__specialItem__box__content-odds'>
                        <span>{dataOdds && dataOdds[1]?.runners[1]?.back[0]?.price}</span>
                        <div className='exposure-box-bollywoodSp'>
                          {exposurePnlSp(dataOdds && dataOdds[1]?.runners[1]?.id)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='BollywoodCasino-table__row__specialItem'>
                <div className='BollywoodCasino-table__row__specialItem__title'>Color</div>

                <div className='BollywoodCasino-table__row__specialItem__flex'>

                  {/* Red */}
                  <div className={`BollywoodCasino-table__row__specialItem__box ${dataOdds[1]?.status === 'SUSPENDED' &&  exchangeGames && exchangeGames[2]?.marketRunner[0]?.status === 'WINNER'
                  ? 'winnerBoxPlus'
                  : exposureTrue(dataOdds && dataOdds[2]?.runners[0]?.id) 
                    && dataOdds[2]?.status === 'SUSPENDED'
                  ? 'boxSuspendedExp'  
                  : dataOdds[1]?.status === 'SUSPENDED'
                  ? 'boxSuspended'
                  : ''}`}
                    onClick={
                    dataOdds[2]?.isBettable
                      ? () => select(dataOdds[2], dataOdds[2]?.runners[0], true)
                      : null
                  }>
                    <div className='BollywoodCasino-table__row__specialItem__box__content'>
                      <div className='BollywoodCasino-table__row__specialItem__box__content-name'>
                        <img className='BollywoodCasino-table__row__specialItem__box__content-name-img' src={dataOdds[1]?.status === 'SUSPENDED' &&  exchangeGames && exchangeGames[2]?.marketRunner[0]?.status === 'WINNER'
                        ? Heart
                        : dataOdds[2]?.status === 'SUSPENDED'
                        ? HeartSusp
                        : Heart} alt="" />
                        <img className='BollywoodCasino-table__row__specialItem__box__content-name-img'  src={dataOdds[1]?.status === 'SUSPENDED' &&  exchangeGames && exchangeGames[2]?.marketRunner[0]?.status === 'WINNER'
                        ? Diamond
                        : dataOdds[2]?.status === 'SUSPENDED'
                        ? DiamondSusp
                        : Diamond}  alt="" />
                      </div>
                      <div
                        // style ={exchangeGames && exchangeGames[2]?.marketRunner[0]?.status === 'WINNER'
                        // ? {color: '#29516A'}
                        // : {}}
                        className='BollywoodCasino-table__row__specialItem__box__content-odds'>
                        <span>{dataOdds && dataOdds[2]?.runners[0]?.back[0]?.price}</span>
                        <div className='exposure-box-bollywoodSp'>
                          {exposurePnlSp(dataOdds && dataOdds[2]?.runners[0]?.id)}
                        </div>
                      </div>

                    </div>
                  </div>

                  {/* Black */}
                  <div className={`BollywoodCasino-table__row__specialItem__box ${dataOdds[1]?.status === 'SUSPENDED' &&  exchangeGames && exchangeGames[2]?.marketRunner[1]?.status === 'WINNER'
                  ? 'winnerBoxPlus'
                  : exposureTrue(dataOdds && dataOdds[2]?.runners[1]?.id) 
                    && dataOdds[2]?.status === 'SUSPENDED'
                  ? 'boxSuspendedExp'  
                  : dataOdds[2]?.status === 'SUSPENDED'
                  ? 'boxSuspended'
                  : ''}`}
                    onClick={
                    dataOdds[2]?.isBettable
                      ? () => select(dataOdds[2], dataOdds[2]?.runners[1], true)
                      : null
                  }>
                    <div className='BollywoodCasino-table__row__specialItem__box__content'>
                      <div className='BollywoodCasino-table__row__specialItem__box__content-name'>
                        <img className='BollywoodCasino-table__row__specialItem__box__content-name-img'  src={Spade} alt="" />
                        <img className='BollywoodCasino-table__row__specialItem__box__content-name-img'  src={Club} alt="" />
                      </div>
                      <div
                        // style ={exchangeGames && exchangeGames[2]?.marketRunner[1]?.status === 'WINNER'
                        // ? {color: '#29516A'}
                        // : {}}
                        className='BollywoodCasino-table__row__specialItem__box__content-odds'>
                        <span>{dataOdds && dataOdds[2]?.runners[1]?.back[0]?.price}</span>
                        <div className='exposure-box-bollywoodSp'>
                          {exposurePnlSp(dataOdds && dataOdds[2]?.runners[1]?.id)}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>



            {/* row5 */}

            <div className='BollywoodCasino-table__cards'>Cards</div>
            <div className='BollywoodCasino-table__row'>


              <div className='BollywoodCasino-table__row__specialItem'>

                {/* J */}
                <div className='BollywoodCasino-table__row__specialItem__flex'>
                  <div
                  // style={exposureTrue(dataOdds && dataOdds[3]?.runners[0]?.id) && width < 1024
                  // ? {height:'108px'}
                  // : {}}
                  className={`BollywoodCasino-table__row__specialItem__boxCard ${dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[0]?.status === 'WINNER'
                  ? ''
                  : dataOdds[3]?.status === 'SUSPENDED'
                  ? 'boxSuspendedCard' 
                  : ''}`}
                    onClick={
                    dataOdds[3]?.isBettable
                      ? () => select(dataOdds[3], dataOdds[3]?.runners[0], true)
                      : null
                  }>
                    <div className='BollywoodCasino-table__row__specialItem__boxCard__content'>
                      <div className='BollywoodCasino-table__row__specialItem__boxCard__content-card'>
                        {dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[0]?.status === 'WINNER' && width < 1024
                        ? winnerCard('J')
                        : dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[0]?.status === 'WINNER'
                        ? <img src={cardJ} alt="" />
                        : <img className='BollywoodCasino-table__row__specialItem__boxCard__content-card-img'  src={dataOdds[3]?.status === 'SUSPENDED'
                        ? cardJsusp
                        : cardJ} alt="" />}
                      </div>
                      <div className='BollywoodCasino-table__row__specialItem__boxCard__content-odds'>
                        <span>{dataOdds && dataOdds[3]?.runners[0]?.back[0]?.price}</span>
                        <div
                          style={{top: '7px'}}
                          className={`exposure-box-bollywood ${(exchangeGames && exchangeGames[3]?.marketRunner[0]?.status === 'WINNER') || ((dataOdds[3]?.status === 'SUSPENDED') && width < 1024)  ? "hide-exposure" : ""}`}>
                          {exposurePnl(dataOdds && dataOdds[3]?.runners[0]?.id)}
                        </div>
                        {exchangeGames && exchangeGames[3]?.marketRunner[0]?.status === 'WINNER' && width > 1024 &&
                        <span className='BollywoodCasino-table__row__specialItem__boxCard__cup'>
                          <img src={trophy} alt="" />
                        </span> }
                      </div>
                    </div>
                  </div>

                  {/* Q */}
                  <div
                   // style={exposureTrue(dataOdds && dataOdds[3]?.runners[1]?.id) && width < 1024
                   //  ? {height:'108px'}
                   //  : {}}
                  className={`BollywoodCasino-table__row__specialItem__boxCard ${dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[1]?.status === 'WINNER'
                  ? ''
                  : dataOdds[3]?.status === 'SUSPENDED'
                  ? 'boxSuspendedCard' 
                  : ''}`}
                    onClick={
                    dataOdds[3]?.isBettable
                      ? () => select(dataOdds[3], dataOdds[3]?.runners[1], true)
                      : null
                  }>
                    <div className='BollywoodCasino-table__row__specialItem__boxCard__content'>
                        <div className='BollywoodCasino-table__row__specialItem__boxCard__content-card'>
                        {dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[1]?.status === 'WINNER' && width < 1024
                        ? winnerCard('Q')
                        : dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[1]?.status === 'WINNER'
                        ? <img src={cardQ} alt="" />
                        :<img className='BollywoodCasino-table__row__specialItem__boxCard__content-card-img'  src={dataOdds[3]?.status === 'SUSPENDED'
                        ? cardQsusp
                        : cardQ} alt="" />}
                      </div>
                      <div className='BollywoodCasino-table__row__specialItem__boxCard__content-odds'>
                        <span>{dataOdds && dataOdds[3]?.runners[1]?.back[0]?.price}</span>
                        <div
                          style={{top: '7px'}}
                          className={`exposure-box-bollywood ${(exchangeGames && exchangeGames[3]?.marketRunner[1]?.status === 'WINNER') || ((dataOdds[3]?.status === 'SUSPENDED') && width < 1024) ? "hide-exposure" : ""}`}>
                          {exposurePnl(dataOdds && dataOdds[3]?.runners[1]?.id)}
                        </div>
                        {exchangeGames && exchangeGames[3]?.marketRunner[1]?.status === 'WINNER' && width > 1024 &&
                        <span className='BollywoodCasino-table__row__specialItem__boxCard__cup'>
                          <img src={trophy} alt="" />
                        </span>}
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className='BollywoodCasino-table__row__specialItem'>

                <div className='BollywoodCasino-table__row__specialItem__flex'>

                  {/* K */}
                  <div
                   // style={exposureTrue(dataOdds && dataOdds[3]?.runners[2]?.id) && width < 1024
                   //  ? {height:'108px'}
                   //  : {}}
                  className={`BollywoodCasino-table__row__specialItem__boxCard ${dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[2]?.status === 'WINNER'
                  ? ''
                  : dataOdds[3]?.status === 'SUSPENDED' 
                  ? 'boxSuspendedCard' 
                  : ''}`}
                    onClick={
                    dataOdds[3]?.isBettable
                      ? () => select(dataOdds[3], dataOdds[3]?.runners[2], true)
                      : null
                  }>
                    <div className='BollywoodCasino-table__row__specialItem__boxCard__content'>
                      <div className='BollywoodCasino-table__row__specialItem__boxCard__content-card'>
                        {dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[2]?.status === 'WINNER' && width < 1024
                        ? winnerCard('K')
                        : dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[2]?.status === 'WINNER'
                        ? <img src={cardK} alt="" />
                        : <img className='BollywoodCasino-table__row__specialItem__boxCard__content-card-img'  src={dataOdds[3]?.status === 'SUSPENDED'
                        ? cardKsusp
                        : cardK} alt="" />}
                      </div>
                      <div className='BollywoodCasino-table__row__specialItem__boxCard__content-odds'>
                        <span>{dataOdds && dataOdds[3]?.runners[2]?.back[0]?.price}</span>
                        <div
                          style={{top: '7px'}}
                          className={`exposure-box-bollywood ${(exchangeGames && exchangeGames[3]?.marketRunner[2]?.status === 'WINNER') || ((dataOdds[3]?.status === 'SUSPENDED') && width < 1024)  ? "hide-exposure" : ""}`}>
                          {exposurePnl(dataOdds && dataOdds[3]?.runners[2]?.id)}
                        </div>
                        {exchangeGames && exchangeGames[3]?.marketRunner[2]?.status === 'WINNER' && width > 1024 &&
                        <span className='BollywoodCasino-table__row__specialItem__boxCard__cup'>
                          <img src={trophy} alt="" />
                        </span>}
                      </div>

                    </div>
                  </div>

                  {/* A */}
                  <div
                  // style={exposureTrue(dataOdds && dataOdds[3]?.runners[3]?.id) && width < 1024
                  // ? {height:'108px'}
                  // : {}}
                  className={`BollywoodCasino-table__row__specialItem__boxCard ${dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[3]?.status === 'WINNER'
                  ? ''
                  : dataOdds[3]?.status === 'SUSPENDED' 
                  ? 'boxSuspendedCard' 
                  : ''}`}
                    onClick={
                    dataOdds[3]?.isBettable
                      ? () => select(dataOdds[3], dataOdds[3]?.runners[3], true)
                      : null
                  }>
                    <div className='BollywoodCasino-table__row__specialItem__boxCard__content'>
                      <div className='BollywoodCasino-table__row__specialItem__boxCard__content-card'>
                        {dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[3]?.status === 'WINNER' && width < 1024
                        ? winnerCard('A')
                        : dataOdds[3]?.status === 'SUSPENDED' && exchangeGames && exchangeGames[3]?.marketRunner[3]?.status === 'WINNER'
                        ? <img src={cardA} alt="" />
                        : <img className='BollywoodCasino-table__row__specialItem__boxCard__content-card-img'  src={dataOdds[3]?.status === 'SUSPENDED'
                        ? cardAsusp
                        : cardA} alt="" />}
                      </div>
                      <div className='BollywoodCasino-table__row__specialItem__boxCard__content-odds'>
                        <span>{dataOdds && dataOdds[3]?.runners[3]?.back[0]?.price}</span>
                        <div
                          style={{top: '7px'}}
                          className={`exposure-box-bollywood ${(exchangeGames && exchangeGames[3]?.marketRunner[3]?.status === 'WINNER') || ((dataOdds[3]?.status === 'SUSPENDED') && width < 1024) ? "hide-exposure" : ""}`}>
                          {exposurePnl(dataOdds && dataOdds[3]?.runners[3]?.id)}
                        </div>
                        {exchangeGames && exchangeGames[3]?.marketRunner[3]?.status === 'WINNER' && width > 1024 &&
                        <span className='BollywoodCasino-table__row__specialItem__boxCard__cup'>
                          <img src={trophy} alt="" />
                        </span>}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <LastResults/>
        </div>

      </>
    )
}

export default connectBollywoodCasino()(BollywoodCasino)
