import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestCompetition } from '../../store/competitions/competitions.thunks';
import { requestMenuEvent } from '../../store/menuEvent/menuEvent.thunks';

export const connectCompetition = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      menu: state.menu,
      competition: state.competition,
      timeSettings: state.timeSettings,
      elementsParams: state.elementsParams

    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestCompetition,
      requestMenuEvent
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
