import { createSlice } from '@reduxjs/toolkit'

/*
 * @Used to recognize a deliberate logout
 * To hard reset the Store
 */
export const loginSlice = createSlice({
  name: 'loggedOut',
  initialState: false,
  reducers: {
    userLoggedOut: (state) => true,
  },
})

export const { userLoggedOut } = loginSlice.actions

export const selectUser = (state) => state

export default loginSlice.reducer
