import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getInplayPopularError, getInplayPopularSuccess } from './inplayPopular.slice'


export const requestInplayPopular = () => async (dispatch, getState) => {
    const checkAuth = getState().auth
    if(checkAuth) {
        try {
            const response = await httpAuth.get(`/exchange/odds/inPlayAndPopularEvents`)
            //const response = await axios.get(`https://89383249-2f56-4a28-b7cf-2bc8402ce7ed.mock.pstmn.io/api/exchange/odds/inPlayAndPopularEvents`)
            if (response.data.success) {
                dispatch(getInplayPopularSuccess(response.data.result))
            }
            return response
        } catch (e) {
            dispatch(getInplayPopularError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    } else {
        try {
            const response = await httpNotAuth.get(`/exchange/odds/inPlayAndPopularEvents`)
            //const response = await axios.get(`https://89383249-2f56-4a28-b7cf-2bc8402ce7ed.mock.pstmn.io/api/exchange/odds/inPlayAndPopularEvents`)
            if (response.data.success) {
                dispatch(getInplayPopularSuccess(response.data.result))
            }
            return response
        } catch (e) {
            dispatch(getInplayPopularError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }
    
}

