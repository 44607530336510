import React from 'react'
import './TwoCardTPCards.scss'
import { connectTwoCardTeenPatti } from '../../TwoCardTeenPatti.connect'

const TwoCardTPCards = ({ cards, exchangeGames }) => {

  const cardSrc = (card) => {

    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  const getSumOfCards = (market, runner) => {
    const cards = exchangeGames && exchangeGames[market]?.marketRunner[runner]?.cards;
    return Array.isArray(cards) &&
      cards.reduce((sum, card) => sum += +card.substr(1, card.length), 0);
  };

  return (
    <div className="teenpatti2card-players teenpatti2card-live-cards">
      <div className="block-player">
        <div className="block-player-title">
          <span>Player A</span>
          <span className="block-player-title-score-slash"> | </span>
          <span className="block-player-title-score"> {getSumOfCards(0, 0)}</span>
        </div>
        <div className="cards">
          <img
            src={cardSrc(
              exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0]
            )}
          />
          <img
            src={cardSrc(
              exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1]
            )}
          />
        </div>
      </div>
      <div className="block-player">
        <div className="cards">
          <img
            src={cardSrc(
              exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0]
            )}
          />
          <img
            src={cardSrc(
              exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1]
            )}
          />
        </div>
        <div className="block-player-title">
          <span>Player B</span>
          <span className="block-player-title-score-slash"> | </span>
          <span className="block-player-title-score"> {getSumOfCards(0, 1)}</span>
        </div>
      </div>
    </div>
  )
}

export default connectTwoCardTeenPatti()(TwoCardTPCards)
