import { createSlice } from '@reduxjs/toolkit'

export const slotsGamesSearchSlice = createSlice({
    name: 'slotsGamesSearch',
    initialState: null,
    reducers: {
        getSlotsGamesSearchSuccess: (state, action) => action.payload,
        getSlotsGamesSearchError: (state) => state,
    },
})

export const {
    getSlotsGamesSearchSuccess,
    getSlotsGamesSearchError,
} = slotsGamesSearchSlice.actions

export default slotsGamesSearchSlice.reducer
