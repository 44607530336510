import httpAuth from '../../services/httpAuth'
// import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
  getForgotPasswordSuccess,
  getForgotPasswordError,
} from './forgotPassword.slice'

// export const postNumberSignUp = (memberCode) => async (dispatch) => {
//   try {
//     const response = await http.get(`/account/${memberCode}/balance-settings`)
//     if (response.data.success) {
//       dispatch(getSignUpSuccess(response.data.result))
//     }
//     return response
//   } catch (e) {
//     dispatch(getSignUpError())
//     const errorMsg = getErrorMessage(e)
//     throw errorMsg
//   }
// }
let token 

export const postNumberForgotPassword = (data) => async (dispatch) => {
  try {
    const response = await httpAuth.post('/auth/forgotPassword', data)
    if (response.data.success) {

      dispatch(getForgotPasswordSuccess(response.data.success))
    }
    return response
  } catch (e) {
    dispatch(getForgotPasswordError())
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const resendForgotPasswordOTP = (data) => async (dispatch) => {
  try {
    const response = await httpAuth.post('/auth/resendOtp', data)
    if (response.data.success) {
      console.log(response.data.success);
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}

export const verifyForgotPasswordOTP = (data) => async (dispatch) => {
  try {
    const response = await httpAuth.post('/auth/verifyforgotPassword', data)
    if (response.data.success) {
      console.log(response.data.success);
      console.log(response);
      token = response.headers.authorization
    }
    return response
  } catch (e) {
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}
