import { createSlice } from '@reduxjs/toolkit'

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState: {
    isDetailedPromo: false,
    isTermCondition: false,
    promoInfo: null,
    promoListStore: null
  },
  reducers: {
    getSignUpSuccess: (state, action) => action.payload,
    getSignUpError: (state) => state,
    setIsDetailedPromo: (state, action) => ({ ...state, isDetailedPromo: action.payload }),
    setIsTermCondition: (state, action) => ({ ...state, isTermCondition: action.payload }),
    setPromoInfo: (state, action) => ({ ...state, promoInfo: action.payload }),
    setPromoListStore: (state, action) => ({ ...state, promoListStore: action.payload }),


  },
})

export const {
  getSignUpSuccess,
  getSignUpError,
  setIsDetailedPromo,
  setPromoInfo,
  setIsTermCondition,
  setPromoListStore
} = signUpSlice.actions

export default signUpSlice.reducer
