import React, { useEffect }  from "react";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import {connectBaccarat} from "./Baccarat.connect";
import BetPlacing from "../../BetPlacing/BetPlacing";
import {GET_INPLAY_POPULAR_INTERVAL} from "../../../config/app.config";
import LastResults from "../helpers/LastResults/LastResults";
import CardsInfo from "../helpers/CardsInfo/CardsInfo";
import "./Baccarat.scss";

const Baccarat = ({
  sportEvent,
  betPlacing,
  actions,
  exchangeGames,
  exposure,
  headerHeight,
  auth,
  elementsParams
 }) => {
    const dataOdd = sportEvent.sportData;

    const cardSrc = (card) => {
        if (card) {
            return `https://stream.fawk.app/assets/smallCards/${card}.png`;
        } else {
            return "https://stream.fawk.app/assets/smallCards/BACK.png";
        }
    };
  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  //get price
    const getMarketPrice = (marketIndex, runnersIndex) => {
        if (dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0].price) {

            return dataOdd[marketIndex].runners[runnersIndex].back[0].price;
        }
    };
  //end get price
  // get exposure
    const getExposure = (marketIndex, runnerIndex) => {
       if(auth) {
        const id = sportEvent.sportData[marketIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex].id;
        let objFinder = exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item?.includes(id))]?.pnl;
    // let objFinder = 200;
        return (
          <div
            className={
              objFinder > 0
                ? "baccarat-exposure-box-modern baccarat-exposure-modern--pos"
                : "baccarat-exposure-box-modern baccarat-exposure-modern--neg"
            }
          >
            {objFinder}
          </div>
        );
       } else return
    };
  // end get exposure
  // Show betslip

  // End Show betslip
  // Bet placing
    const select = (dataOdd, item, isBack) => {
        const runner = {name: item.name, id: item.id};
        const oddData = isBack
      ? {...item.back[0], runner}
      : {...item.lay[0], runner};
        const marketId = dataOdd && dataOdd.id;
        const runnerId = oddData && oddData.runner && oddData.runner.id;
        if (!marketId || !runnerId) return;
        actions.setMarket(marketId);
        actions.setRunner(runnerId);
        actions.setRunnerName(oddData && oddData.runner && oddData.runner.name);
        actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name);
        actions.setIsBack(isBack);
        actions.setPrice(oddData && oddData.price);
        actions.setPriceActual(oddData && oddData.price);
        actions.setDelay(dataOdd && dataOdd.betDelay);
        actions.setMtype(dataOdd && dataOdd.mtype);
        actions.setBtype(dataOdd && dataOdd.btype);
        actions.setLine(oddData && oddData.line);
        actions.setFromOneClick(false);
        actions.setStake(null);

        actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log("err = ", err));
    };
    const oddPush = (marketIndex, runnerIndex, isBack = true) => {
        return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
        );
    };
  // End Bet placing
  // lock market
    const resultStatus = (marketIndex, runner) => {
        if (exchangeGames && exchangeGames[marketIndex]?.marketRunner[runner]?.status === "WINNER") {
            return "lock-ui winner";
        }
        return !dataOdd[marketIndex].isBettable && "lock-ui";
    };
  // end lock market

    useEffect(() => {
        const timer = setIntervalAsync(async () => {
          await actions
                  .requestExchangeGames("92038")
                  .catch((e) => console.log("requestInplayPopular error = ", e));
        }, GET_INPLAY_POPULAR_INTERVAL);
        return () => clearIntervalAsync(timer);

    }, []);

    return (
    <>
      <CardsInfo />
        <div className="baccarat">
          <div className="baccarat-table">

            <div className="baccarat-table__header">
              <div className={`baccarat-table__header-item ${resultStatus(4, 0)}`} onClick={() => {oddPush(4,0);}}>
                <span className="baccarat-table__header-item-name">Perfect Pair</span>
                <p className="baccarat-table__header-item-price">
                  {getMarketPrice(4,0)}
                  <span>:1</span>
                </p>
                {getExposure(4,0)}
              </div>
              <div className={`baccarat-table__header-item small ${resultStatus(6, 0)}`} onClick={() => {oddPush(6,0);}}>
                <span className="baccarat-table__header-item-name">Big</span>
                <p className="baccarat-table__header-item-price">
                  {getMarketPrice(6,0)}
                  <span>:1</span>
                </p>
                {getExposure(6,0)}
              </div>
              <div className={`baccarat-table__header-item small ${resultStatus(5, 0)}`} onClick={() => {oddPush(5,0);}}>
                <span className="baccarat-table__header-item-name">Small</span>
                <p className="baccarat-table__header-item-price">
                  {getMarketPrice(5,0)}
                  <span>:1</span>
                </p>
                {getExposure(5,0)}
              </div>
              <div className={`baccarat-table__header-item ${resultStatus(3, 0)}`} onClick={() => {oddPush(3,0);}}>
                <span className="baccarat-table__header-item-name">Either Pair</span>
                <p className="baccarat-table__header-item-price">
                  {getMarketPrice(3,0)}
                  <span>:1</span>
                </p>
                {getExposure(3,0)}
              </div>
            </div>
            <div className="baccarat-table__body">
              <div className="baccarat-table__body-players-wrapper">
                 <div className="baccarat-table__body-player-wrapper">
                   <div className={`baccarat-table__body-player player ${resultStatus(0, 0)}`} onClick={() => {oddPush(0,0);}}>
                     <div className="baccarat-table__body-player-name-price">
                      <span className="baccarat-table__body-name">PLAYER</span>
                      <p className="baccarat-table__body-price">
                        {getMarketPrice(0,0)}
                      </p>
                    </div>
                     <div className="baccarat-table__cards baccarat-table__player-cards">
                       {exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[2] &&
                         <img className="baccarat-table__card-rotated"
                              src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[2])} alt="" />
                       }
                       <img src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[0])} alt="" />
                       <img src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards[1])} alt="" />
                     </div>
                     {getExposure(0,0)}
                   </div>
                   <div className={`baccarat-table__body-player player-pair ${resultStatus(1, 0)}`} onClick={() => {oddPush(1,0);}}>
                      <span className="baccarat-table__body-name">P PAIR</span>
                      <p className="baccarat-table__body-price">
                        {getMarketPrice(1,0)}:1
                      </p>
                     {getExposure(1,0)}
                   </div>
                 </div>
                 <div className="baccarat-table__body-banker-wrapper">
                   <div className={`baccarat-table__body-player banker ${resultStatus(0, 1)}`} onClick={() => {oddPush(0,1);}}>
                  <div className="baccarat-table__body-player-name-price">
                     <span className="baccarat-table__body-name">BANKER</span>
                     <p className="baccarat-table__body-price">
                       {getMarketPrice(0,1)}
                     </p>
                  </div>
                  <div class="baccarat-table__cards baccarat-table__banker-cards">
                     <img src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[0])} alt="" />
                    <img src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[1])} alt="" />
                    {exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[2] &&
                      <img className="baccarat-table__card-rotated"
                           src={cardSrc(exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards[2])} alt="" />
                    }
                  </div>
                  {getExposure(0,1)}
               </div>
                   <div className={`baccarat-table__body-player banker-pair ${resultStatus(2, 0)}`} onClick={() => {oddPush(2,0);}}>
                  <span className="baccarat-table__body-name">B PAIR</span>
                  <p className="baccarat-table__body-price">
                    {getMarketPrice(2,0)}:1
                  </p>
                  {getExposure(2,0)}
                </div>
                 </div>
                 <div class={`baccarat-table__body-tie-wrapper ${resultStatus(0, 2)}`} onClick={() => {oddPush(0,2);}}>
             <div class="baccarat-table__tie-name-price">
               <span class="baccarat-table__body-name">TIE</span>
               <p class="baccarat-table__body-price">
                 {getMarketPrice(0,2)}:1
               </p>
             </div>
              {getExposure(0,2)}
            </div>
              </div>
             <LastResults/>
           </div>
          </div>
        </div>
      </>
    );
};

export default connectBaccarat()(Baccarat);
