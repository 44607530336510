import './inplay.scss'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IonItemGroup } from '@ionic/react'
import InPlayPopularItemList from '../InPlayItemList/inPlayPopularItemList'
import leftSideMenuMapper from '../../helpers/getLeftSideMenuMapper.helper'
import InPlayPopularHeader from '../InPlayPopularHeader/inplay-poplular-header'
import CasinoGridList from '../CasinoGridList/CasinoGridList'
import ExchangeGamesGridList from '../ExchangeGamesGridList/ExchangeGamesGridList'
import BetbyWidget from '../BetbyWidget/BetbyWidget'
import BetbyWidgetNotAuth from '../BetbyWidget-NotAuth/BetbyWidget-notAuth'
import { useHistory } from 'react-router'
import PopularGamesGridList from '../PopularGamesGridList/PopularGamesGridList'

const InPlayPopular = ({
  inPlayEvents,
  balanceSettings,
  timeSettings,
  type,
  displayedBack,
  setDisplayedBack,
  betPlacing,
  orderCount,
  pathname,
  promotions,
}) => {
  const [inPlayEventsMap, setInPlayEventsMap] = useState(new Map())
  const history = useHistory()
  const auth = useSelector((state) => state.auth)

  const getListsItems = () => {
    return Object.entries(leftSideMenuMapper)
      .filter(([key, value]) => !!inPlayEventsMap.get(value.id))
      .map(([key, value]) => {
        switch (value.id) {
          case 77777:
            return (
              history.location.pathname !== '/favouritesPage' && (
                <div key={value.id}>
                  {/* {auth ? (
                    <BetbyWidget pathname={pathname} />
                  ) : (
                     <BetbyWidgetNotAuth startBetByWidget={startBetByWidget} pathname={pathname} />
                  )} */}
                  <CasinoGridList
                    key={value.id}
                    type={type}
                    itemHeaderData={value}
                    itemsData={inPlayEventsMap.get(value.id)}
                  />
                </div>
              )
            )

          case 1444001:
            return history.location.pathname !== '/favouritesPage' ? (
              <ExchangeGamesGridList
                key={value.id}
                type={type}
                itemHeaderData={value}
                itemsData={inPlayEventsMap.get(value.id)}
              />
            ) : (
              <InPlayPopularItemList
                type={type}
                timeSettings={timeSettings}
                betPlacing={betPlacing}
                key={value.id}
                displayedBack={displayedBack}
                setDisplayedBack={setDisplayedBack}
                itemHeaderData={value}
                balanceSetting={balanceSettings && balanceSettings[value.id]}
                itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
              />
            )
          case 1111111:
            return history.location.pathname !== '/favouritesPage' ? (
              <PopularGamesGridList
                key={value.id}
                type={type}
                itemHeaderData={value}
                itemsData={inPlayEventsMap.get(value.id)}
              />
            ) : (
              <InPlayPopularItemList
                type={type}
                timeSettings={timeSettings}
                betPlacing={betPlacing}
                key={value.id}
                displayedBack={displayedBack}
                setDisplayedBack={setDisplayedBack}
                itemHeaderData={value}
                balanceSetting={balanceSettings && balanceSettings[value.id]}
                itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
              />
            )

          default:
            return (
              <InPlayPopularItemList
                type={type}
                timeSettings={timeSettings}
                betPlacing={betPlacing}
                key={value.id}
                displayedBack={displayedBack}
                setDisplayedBack={setDisplayedBack}
                itemHeaderData={value}
                balanceSetting={balanceSettings && balanceSettings[value.id]}
                itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
              />
            )
        }
      })
  }

  const updateInPlayEventsMap = () => {
    if (!inPlayEvents) {
      return
    }

    inPlayEventsMap.clear()
    inPlayEvents.forEach((e) => {
      const evId = Number(e.eventTypeId)
      const value = inPlayEventsMap.get(evId)
      if (value) {
        value.push(e)
      } else {
        inPlayEventsMap.set(evId, [e])
      }
    })
    if (promotions && promotions?.popularBanners) {
      promotions.popularBanners.forEach((e) => {
        const evId = 1111111;
        const value = inPlayEventsMap.get(evId)
        if (value) {
          value.push(e)
        } else {
          inPlayEventsMap.set(evId, [e])
        }
      })
    }
    setInPlayEventsMap(inPlayEventsMap)
  }

  useEffect(() => {
    updateInPlayEventsMap()
  })

  return (
    <IonItemGroup className="inplay-content">
      <InPlayPopularHeader type={type} orderCount={orderCount} />

      {getListsItems()}
    </IonItemGroup>
  )
}

export default InPlayPopular
