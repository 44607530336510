import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestRaceOdds } from '../../store/raceOdds/raceOdds.thunks';
import { requestRaceExposure } from '../../store/racePage/racePage.thunks';

export const connectRaceOdds = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      raceOdds: state.raceOdds,
      lastActivityDate: state.balance.lastActivityDate,
      betPlacing: state.betPlacing,
      timeSettings: state.timeSettings,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestRaceOdds,
      requestRaceExposure,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}

export const connectRaceEventDropdown = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      timeSettings: state.timeSettings,
      betPlacing: state.betPlacing
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
