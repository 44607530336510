import React from 'react'

const DragonTigerRules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          Dragon Tiger's objective is to predict who has the higher card –
          Dragon or Tiger. Two cards are dealt by the Dealer, one to the Dragon
          and one to the Tiger. The user can bet on which one will be higher.
          There are also additional markets on the individual cards. It is
          played with Six decks of cards that are stored in a shuffling machine.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <ul>
          <li>Game round starts when Betting Timer reaches 0.</li>
          <li>
            A single card is dealt by the Dealer to Dragon and Tiger each. The
            side with Higher Card wins.
          </li>
          <li>
            Card value from the lowest to the highest is as follows: Ace with
            value 1, being the lowest and followed by 2 and so on, and King the
            highest (A-2-3-4-5-6-7-8-9-10-J-Q-K)
          </li>
          <li>
            If cards for the Dragon and Tiger are equal both in value and suit,
            it's a Suited Tie.
          </li>
        </ul>
        <div>
          <h3>Betting Instructions</h3>
          <ul>
            <li>
              If a game round is in progress when you enter the game, please
              wait for the next one, and then, place your bets.
            </li>
            <li>After the timer reaches 0, the game round begins.</li>
            <li>
              To place a bet, click on the box, enter the stake amount and
              submit by clicking on Place Bet button.
            </li>
            <li>
              You can place several bets on different runners in different
              markets simultaneously.
            </li>
            <li>
              The timer shows how much time you have left to place your bets.
            </li>
            <li>
              Winnings are paid for the winning bets at the end of each game
              round.
            </li>
            <li>To skip a turn, simply do not place any bets.</li>
          </ul>
        </div>
        <div>
          <h3>Main Bets</h3>
          <ol>
            <li>Dragon</li>
            <li>Tiger</li>
            <li>Tie</li>
            <li>Suited Tie</li>
          </ol>
        </div>
        <div>
          <h3>Side Bets</h3>
          <ol>
            <li>Dragon-Even</li>
            <li>Dragon-Odd</li>
            <li>Tiger-Even</li>
            <li>Tiger-Odd</li>
            <li>Dragon-Red</li>
            <li>Dragon-Black</li>
            <li>Tiger-Red</li>
            <li>Tiger-Black</li>
            <li>Dragon-Spade</li>
            <li>Dragon-Heart</li>
            <li>Dragon-Club</li>
            <li>Dragon-Diamond</li>
            <li>Tiger-Spade</li>
            <li>Tiger-Heart</li>
            <li>Tiger-Club</li>
            <li>Tiger-Diamond</li>
          </ol>
        </div>
        <div>
          <h4>Payout</h4>
          <p class="game-rules__pop-up-about">
            Payout depends on the type of bet placed.
          </p>
          <table>
            <tbody>
              <tr>
                <th>Dragon</th>
                <th>1: 0.98</th>
              </tr>
              <tr>
                <th>Tiger</th>
                <th>1: 0.98</th>
              </tr>
              <tr>
                <th>Tie</th>
                <th>1: 10</th>
              </tr>
              <tr>
                <th>Suited Tie</th>
                <th>1: 49</th>
              </tr>
              <tr>
                <th>Dragon-Even</th>
                <th>1: 1.1</th>
              </tr>
              <tr>
                <th>Dragon-Odd</th>
                <th>1: 0.8</th>
              </tr>
              <tr>
                <th>Tiger-Even</th>
                <th>1: 1.1</th>
              </tr>
              <tr>
                <th>Tiger-Odd</th>
                <th>1: 0.8</th>
              </tr>
              <tr>
                <th>Dragon-Red</th>
                <th>1: 0.95</th>
              </tr>
              <tr>
                <th>Dragon-Black</th>
                <th>1: 0.95</th>
              </tr>
              <tr>
                <th>Tiger-Red</th>
                <th>1: 0.95</th>
              </tr>
              <tr>
                <th>Tiger-Black</th>
                <th>1: 0.95</th>
              </tr>
              <tr>
                <th>Dragon-Spade</th>
                <th>1: 2.75</th>
              </tr>
              <tr>
                <th>Dragon-Heart</th>
                <th>1: 2.75</th>
              </tr>
              <tr>
                <th>Dragon-Diamond</th>
                <th>1: 2.75</th>
              </tr>
              <tr>
                <th>Dragon-Club</th>
                <th>1: 2.75</th>
              </tr>
              <tr>
                <th>Tiger-Spade</th>
                <th>1: 2.75</th>
              </tr>
              <tr>
                <th>Tiger-Heart</th>
                <th>1: 2.75</th>
              </tr>
              <tr>
                <th>Tiger-Club</th>
                <th>1: 2.75</th>
              </tr>
              <tr>
                <th>Tiger-Diamond</th>
                <th>1: 2.75</th>
              </tr>
            </tbody>
          </table>
          <p>
            Please note that any malfunction voids the game round and all
            eventual payouts for the round. Bets will be returned.
          </p>
        </div>
      </div>
    </div>
  )
}
export default DragonTigerRules
