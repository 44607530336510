import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import {
    getExposureError,
    getExposureSuccess,
    getOrdersError,
    getOrdersSuccess,
} from './eventPage.slice'

export const requestSportStart = (eventId) => async (dispatch, getState) => {

    // console.log(" !! requestSportStart")
    try {
        // twitter-config - in another reqest if news tab opened
        const userId = getState().user.memberCode;

        if(userId) {
            httpAuth.get(`/exposure/pnl/${userId}/event/${eventId}`)
                .then(res => {
                    if (res.data.success) {
                        dispatch(getExposureSuccess(res.data.result))
                    }
                })
            httpAuth.get(`/account/${userId}/order-count?eventId=${eventId}`)
                .then(res => {
                    if (res.data.success) {
                        dispatch(getOrdersSuccess(res.data.result))
                    }
                })
        }
        return
    } catch (e) {
        dispatch(getExposureError())
        dispatch(getOrdersError())
        // const errorMsg = getErrorMessage(e)
        // throw errorMsg
    }
}
