import { createSlice } from '@reduxjs/toolkit'

export const SearchSlice = createSlice({
    name: 'search',
    initialState: {
        value: '',
        results: [],
        openSearch: false
    },
    reducers: {
        setSearchResults: (state, action) => {
            return { ...state, results: action.payload }
        },
        setSearchValue: (state, action) => {
            return { ...state, value: action.payload }
        },
        resetSearchResults: (state, action) => {
            return { ...state, results: [] }
        },
        setOpenSearch: (state, action) => {
            return { ...state, openSearch: action.payload }
        },
    },
})

export const {
    setSearchResults,
    resetSearchResults,
    setSearchValue,
    setOpenSearch,
} = SearchSlice.actions

export default SearchSlice.reducer;