import React, {useEffect, useState} from "react";
import { tsToDate } from '../../helpers/date.helper'
import SportMarketStandard from '../SportMarketStandard/SportMarketStandard'
import { connectUpcomingRacesAccordion } from './UpcomingRacesAccordion.connect'
import './UpcomingRacesAccordion.scss'

const UpcomingRacesAccordion = ({item, index, timeSettings, betPlacing, showHeader = false, showJockeyName = false, isAccordionOpen, setIsAccordionOpen}) => {

  const [allRunnerSuspend, setAllRunnerSuspend] = useState(false)

  // get all suspend
  useEffect(() => {
    if (!item?.runners?.filter((runner) => runner.status !== "SUSPENDED").length >= 1 ) {
      setAllRunnerSuspend(true)
    }
  }, [item])
  // end get all suspend

  // slice first 3 runners
  const slicedItem = {...item, runners: item.runners.slice(0, 3)}

  // country flag
  let flag = slicedItem.event.countryCode?.toLowerCase()

  // console.log('slicedItem?.event', slicedItem?.event)


  return (
    <div key={slicedItem?.id + index} className="race-page__content-upcoming-races-table-row">
      <div className="race-page__content-upcoming-races-table-row-header f32"
           onClick={() => isAccordionOpen === slicedItem?.id ? setIsAccordionOpen(null) : setIsAccordionOpen(slicedItem?.id)}
      >
        <div className={`flag ${flag}`}></div>
        <div className="race-page__content-upcoming-races-table-row-header-text">
          <span>{slicedItem?.event.name.replace("${HH:mm}","")}</span>
          <span>{(tsToDate(Date.parse(slicedItem?.event.openDate), timeSettings?.timeZoneName).split(" ")[1]).split(":", 2).join(":") }</span>
        </div>
        <div className="race-page__content-upcoming-races-table-row-header-arrowContainer">
          <span className={`race-page__content-upcoming-races-table-row-header-arrow ${isAccordionOpen === slicedItem?.id ? "active" : ""}`}></span>
        </div>
      </div>
      <div className={`race-page__content-upcoming-races-table-row-inner ${isAccordionOpen === slicedItem?.id ? "open" : ""}`}>
        <div className="race-page__content-rase-odds-table-header">
          <p>Top 3 of {item.runners.length} runners</p>
          <div>
            <span>Back</span>
            <span>Lay</span>
          </div>
        </div>
        <div className={`race-page__content-upcoming-races-table-row-items-wrapper ${allRunnerSuspend === true ? "all-suspended" : ""}`} >
          {allRunnerSuspend === true && <div className="race-page__content-all-suspend-cover"><span>SUSPENDED</span></div>}
          {<SportMarketStandard data={slicedItem} key={slicedItem?.id} betPlacing={betPlacing} showHeader={showHeader} showJockeyName={showJockeyName} />}
        </div>
      </div>
    </div>
  )  
}

export default connectUpcomingRacesAccordion()(UpcomingRacesAccordion);
