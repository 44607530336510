import React from 'react'
import { useSelector } from 'react-redux'


export const MarketSicbo = ({getWinner, footerCell,  imageDiceType, textContent, resultStatus, oddPush, marketIndex, runnerIndex, getMarketPrice, getExposure, textContentNums}) => {

  // window width
  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth
  // end window width

  // return market layout
  if ((textContent === "SMALL") || (textContent === "BIG")) {
    return (
      <div
        className={`sicbo-table__body-td ${((textContent === "SMALL" && "small-td") || (textContent === "BIG" && "big-td"))} ${resultStatus(marketIndex)} ${getWinner(marketIndex, runnerIndex)}`}
        onClick={() => oddPush(marketIndex, runnerIndex)}
      >
        <p>{textContent}<span>{textContentNums}</span></p>
        {getExposure && getExposure(marketIndex, runnerIndex)}
      </div>
    )
  }

  if((textContent === "ODD") || (textContent === "EVEN")) {
    return (
      <div
        className={`sicbo-table__body-td  ${((textContent === "ODD" && "odd-td") || (textContent === "EVEN" && "even-td")) } ${resultStatus(marketIndex)} ${getWinner(marketIndex, runnerIndex)}`}
        onClick={() => oddPush(marketIndex, runnerIndex)}
      >
        <p className="vertical-text">{textContent}</p>
        {getExposure && getExposure(marketIndex, runnerIndex)}
      </div>
    )
  }

  if(imageDiceType) {
    return (
      <div
        className={`sicbo-table__body-td ${resultStatus(marketIndex)} ${getWinner(marketIndex, runnerIndex)}`}
        onClick={() => oddPush(marketIndex, runnerIndex)}
      >
        <div className="sicbo-table__body-dice-wrapper">
          <img className="sicbo-table__body-dice-icon" src={imageDiceType} alt="" />
        </div>
        <div className="sicbo-table__body-dice-wrapper">
          <img className="sicbo-table__body-dice-icon" src={imageDiceType} alt="" />
        </div>
        <div className="sicbo-table__body-dice-wrapper">
          <img className="sicbo-table__body-dice-icon" src={imageDiceType} alt="" />
        </div>
        {getExposure && getExposure(marketIndex, runnerIndex)}
      </div>
    )
  }

  if(textContent === "ANY") {
    return (
      <div
        className={`sicbo-table__body-td any-triple-td ${resultStatus(marketIndex)} ${getWinner(marketIndex, runnerIndex)}`}
        onClick={() => oddPush(marketIndex, runnerIndex)}
      >
        <p>ANY <span>TRIPLE</span></p>
        {
          width < 1024 ? <p className={`sicbo-table__body-td-exposure`}>{getMarketPrice(marketIndex, runnerIndex)}:1</p> : ""
        }
        {getExposure && getExposure(marketIndex, runnerIndex)}
      </div>
    )
  }

  if(footerCell) {
    return (
      <div
        className={`sicbo-table__body-td footer-td ${resultStatus(marketIndex)} ${getWinner(marketIndex, runnerIndex)}`}
        onClick={() => oddPush(marketIndex, runnerIndex)}
      >
        <p>{textContent}<span>{getMarketPrice(marketIndex, runnerIndex)}:1</span></p>
        {getExposure && getExposure(marketIndex, runnerIndex)}
      </div>
    )
  }

  return (null)
  // End return market layout
}
