import "./AccountStatementPage.scss";
import { IonButton, IonContent, IonDatetime, IonHeader, IonItem, IonLabel, IonPage } from '@ionic/react'
import React, { useEffect, useState } from "react";
import AccountStatementDetailsTable from "../../components/AccountStatementDetailsTable/AccountStatementDetailsTable";
import AccountStatementTable from "../../components/AccountStatementTable/AccountStatementTable";
import Toolbar from "../../components/Toolbar/Toolbar";
import arrowGrean from "../../assets/images/arrow-grean.svg";
import arrowRed from "../../assets/images/arrow-red.svg";
import { connectAccountStatementPage } from "./AccountStatementPage.connect";
import { useHistory } from "react-router";
import DatePicker from "react-date-picker";
import "react-calendar/dist/Calendar.css";
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import { getCurrencyFormat } from "../../helpers/getCurrencyFormat.helper";

const AccountStatementPage = ({ accountStatement, user, actions, elementsParams }) => {
  const history = useHistory();
  const [filterOpen, setfilterOpen] = useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [selectPNLItem, setSelectPNLItem] = useState(null);
  const [detailsPnlData, setDetailsPnlData] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [choosenFrom, setChoosenFrom] = useState(false)
  const [choosenTo, setChoosenTo] = useState(false)
  const [onOpen, setOnOpen] = useState(false)

  const dateValidation = (from, to) => {
    if (from > to) return setValidationError("'To' date must be greater than 'From' date.");
    else if (setValidationError) setValidationError("");
    if (new Date().setHours(23, 59, 59, 999) < to) return setValidationError("'To' date must not be greater than today's date.");
    else if (setValidationError) setValidationError("");

    if (!validationError) {
      actions
        .requestAccountStatementSuccess(user.memberCode, Date.parse(selectedDateFrom), Date.parse(selectedDateTo))
        .catch((e) => console.log("getWithTimeLimit = ", e));
    }
  };

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  useEffect(() => {
    if (user && user?.memberCode && selectPNLItem && selectPNLItem.description.market && selectPNLItem?.providerId === 7) {
      actions
        .requestBetHistorySuccess(selectPNLItem.description.market)
        .then((data) => setDetailsPnlData(data.data.result))
        .catch((e) => console.log("requestBetHistoryError = ", e));
      return;
    }
    if (user && user?.memberCode && selectPNLItem && selectPNLItem.description.market) {
      const DateFrom = selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0);
      const DateTo = selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999);
      actions
        .requestOrderListSuccess(user.memberCode, "settled", selectPNLItem.description.market, DateFrom, DateTo)
        .then((data) => setDetailsPnlData(data.data.result))
        .catch((e) => console.log("requestOrderListError = ", e));
      return;
    }
  }, [selectPNLItem]);

  useEffect(() => {
    const DateTo = selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999);
    const DateFrom = selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0);
    if (history.location.pathname.indexOf("/account_statement") !== -1) actions.requestAccountStatementSuccess(user.memberCode, DateFrom, DateTo);

  }, []);

  const getWithTimeLimit = () => {
    setSelectPNLItem(null);
    const DateFrom = selectedDateFrom && new Date(selectedDateFrom).setHours(0, 0, 0, 0);
    const DateTo = selectedDateTo && new Date(selectedDateTo).setHours(23, 59, 59, 999);
    dateValidation(DateFrom, DateTo);
  };

  let todayDate = new Date()

  const getMonth = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      month: '2-digit',
    })}`
  }

  const getDate = (date) => {
    return `${date.toLocaleDateString('en-GB', {
      day: '2-digit',
    })}`
  }

  const displayAccountStatement = () => {
    return accountStatement && accountStatement.data && accountStatement.data.map((value, index) => {
      let dateItem = new Date(value.date);


      const fetchPNLData = (providerId = 1) => {
        switch (providerId) {
          case 1: {
            user && actions
              .requestOrderListSuccess(user.memberCode, "settled", value.description.market)
              .then((data) => goToDetailsPage(data.data.result))
              .catch((e) => console.log("requestOrderListError = ", e));

            break;
          }

          case 7: {
            actions
              .requestBetHistorySuccess(value.description.market)
              .then(() => goToDetailsPage())
              .catch((e) => console.log("requestBetHistoryError = ", e));

            break;
          }
        }
      };

      const selectPNLItem = () => {
        fetchPNLData(value.providerId);
      };
      const goToDetailsPage = (data) => {
        console.log("value", data);
        history.push({
          pathname: "/account_statement_details",
          state: { itemData: data[0] },
        });
      };
      return (
        <div className="account-statement-page__list-row" key={index}>
          <div className="account-statement-page__content-group">
            <div className="account-statement-page__item">
              <div>
                {value.action === "TRANSFER_UP" || value.action === "TRANSFER_DOWN" || value.action === "Credit" || value.action === "OPEN"
                  ? <div className="account-statement-page__item__remark">{value.actionDisplayName}</div>
                  : <div className="account-statement-page__item__remark"
                    onClick={() => selectPNLItem()}>{value.description !== null && value.description.eventName}</div>}
                <div className="account-statement-page__item__right-side account-statement-page__item__balanse">
                  Bal. {getCurrencyFormat(value.balance)}
                </div>
              </div>
              <div>
                <div className="account-statement-page__item__title">Date</div>
                <div className="account-statement-page__item__right-side data">
                  <span className="date">
                    {`${("0" + dateItem.getDate()).slice(-2)}/${("0" + (dateItem.getMonth() + 1)).slice(-2)}/${dateItem.getFullYear()}`}
                  </span>
                  <span className="time">
                    {`${dateItem.getHours() < 10 ? ("0" + dateItem.getHours()).slice(-2) : dateItem.getHours()}:${dateItem.getMinutes() < 10 ? ("0" + dateItem.getMinutes()).slice(-2) : dateItem.getMinutes()}:${dateItem.getSeconds() < 10 ? ("0" + dateItem.getSeconds()).slice(-2) : dateItem.getSeconds()}`}
                  </span>
                </div>
              </div>

              <div>
                <div className="account-statement-page__item__title">Description</div>
                <div className="account-statement-page__item__right-side">
                  {value.actionDisplayName}
                </div>
              </div>
              <div>
                <div className="account-statement-page__item__title">P&L</div>
                <div className={`account-statement-page__item__right-side ${value.pnl >= 0 ? "green" : "red"}`}>
                  {value.pnl >= 0
                    ? <img src={arrowGrean} />
                    : <img src={arrowRed} />}
                  {getCurrencyFormat(value.pnl)}
                </div>
              </div>

              <div>
                <div className="account-statement-page__item__title">Credit Limit</div>
                <div className="account-statement-page__item__right-side">
                  {value.creditLimit ? getCurrencyFormat(value.creditLimit) : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  if (width < 1024) {
    return (
      <IonPage>
        <IonHeader>
          <Toolbar />
        </IonHeader>
        <IonContent className="account-statement-page" fullscreen>
          <div className="page-title-wrapper">
            <h2 className="page-title">Account Statement</h2>
          </div>
          <div className="account-statement-page__filter-content">
            <div>
              <button onClick={() => {
                setfilterOpen((prevValue) => !prevValue)
                // setChoosenFrom(false)
                // setChoosenTo(false)
              }}
                className={`filter-button ${filterOpen && "filter-button--active"}`}>Filter
              </button>
            </div>

            {/* {filterOpen && <div className="account-statement-page__filter-data-piker">

              <IonItem>
                <IonLabel position="floating">Date from:</IonLabel>
                <IonDatetime displayFormat="MM /DD/YYYY" id="befg1" value={selectedDateFrom}
                             onIonChange={e => setSelectedDateFrom(e.detail.value)}></IonDatetime>
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Date to:</IonLabel>
                <IonDatetime displayFormat="MM/DD/YYYY" id="befg2" value={selectedDateTo}
                             onIonChange={e => setSelectedDateTo(e.detail.value)}></IonDatetime>
              </IonItem>

              <IonButton size="large" onClick={() => setfilterOpen((prevValue) => !prevValue)}>
                Apply
              </IonButton>
            </div>} */}


            {filterOpen &&
              <div className="account-statement-page__filter-content">
                <div className="account-statement-page__filters-date-input"
                  // style={{marginRight: "15px"}}
                  onClick={() => {
                    // setChoosenFrom(true)
                  }}>
                  <p style={choosenFrom
                    ? { fontSize: '12px', transition: 'linear 0.2s' }
                    : {}}>Date from:</p>
                  <div>
                    <DatePicker
                      onChange={setSelectedDateFrom}
                      value={selectedDateFrom}
                      locale={'en-US'}
                      showLeadingZeros={true}
                      clearIcon={null}
                      calendarIcon={null}
                      format={'dd/MM/yyyy'}
                      dayPlaceholder={getDate(todayDate)}
                      monthPlaceholder={getMonth(todayDate)}
                      yearPlaceholder={todayDate.getFullYear()}
                    />
                  </div>
                </div>
                <div className="account-statement-page__filters-date-input"
                  // style={{marginRight: "15px"}}
                  onClick={() => {
                    // setChoosenTo(true)
                  }}>
                  <p style={choosenTo
                    ? { fontSize: '12px', transition: 'linear 0.2s' }
                    : {}}>Date to:</p>
                  <div>
                    <DatePicker
                      onChange={setSelectedDateTo}
                      value={selectedDateTo}
                      locale={'en-US'}
                      showLeadingZeros={true}
                      clearIcon={null}
                      calendarIcon={null}
                      format={'dd/MM/yyyy'}
                      dayPlaceholder={getDate(todayDate)}
                      monthPlaceholder={getMonth(todayDate)}
                      yearPlaceholder={todayDate.getFullYear()}
                    />
                  </div>
                </div>
                <button className="account-statement-page__date-search-button"
                  // disabled={!(selectedDateFrom && selectedDateTo)}
                  onClick={() => {
                    getWithTimeLimit()
                    setfilterOpen((prevValue) => !prevValue)
                  }}
                >
                  <span>Apply</span>
                </button>
                {validationError && (<div className="account-statement-page__filter-content-error">{validationError}</div>)}
              </div>}


          </div>

          {
            (accountStatement && !accountStatement.data) ?
              <div style={{ marginLeft: '25px' }} className="account-statement-page__content">
                <div className="account-statement-page__content--empty">
                  No data to display. Try changing filters and search again
                </div>
              </div>
              :
              (!filterOpen && <div className="account-statement-page__content">
                {displayAccountStatement()}
              </div>)
          }
          {/*{width < 1024 && <FooterDesktop />}*/}
        </IonContent>
      </IonPage>
    );
  }
  return (
    // <IonPage>
    //   <IonHeader>
    //     <Toolbar />
    //   </IonHeader>
    <div className="account-statement-page" fullscreen>
      <div className="account-statement-page-wrapper">
        <div className="page-title-wrapper account-statement-page-title-wrapper">
          <h2 className="page-title account-statement-page-title">Account Statement</h2>
        </div>
        <div className="account-statement-page__filter-content">
          <div className="account-statement-page__filters-date-input" style={{ marginRight: "15px" }}>
            <p>From</p>
            <div>
              <DatePicker
                onChange={setSelectedDateFrom}
                value={selectedDateFrom}
                locale={'en-US'}
                showLeadingZeros={true}
                clearIcon={null}
                calendarIcon={null}
                format={'dd/MM/yyyy'}
                dayPlaceholder={getDate(todayDate)}
                monthPlaceholder={getMonth(todayDate)}
                yearPlaceholder={todayDate.getFullYear()}
              />
            </div>
          </div>
          <div className="account-statement-page__filters-date-input" style={{ marginRight: "15px" }}>
            <p>To</p>
            <div>
              <DatePicker
                onChange={setSelectedDateTo}
                value={selectedDateTo}
                locale={'en-US'}
                showLeadingZeros={true}
                clearIcon={null}
                calendarIcon={null}
                format={'dd/MM/yyyy'}
                dayPlaceholder={getDate(todayDate)}
                monthPlaceholder={getMonth(todayDate)}
                yearPlaceholder={todayDate.getFullYear()}
              />
            </div>
          </div>
          <button className="account-statement-page__date-search-button"
            // disabled={!(selectedDateFrom && selectedDateTo)}
            onClick={getWithTimeLimit}
          >
            <span>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.8023 11.8409L9.59762 8.623C10.4216 7.67732 10.8731 6.48745 10.8731 5.24874C10.8731 2.35463 8.4342 0 5.43654 0C2.43888 0 0 2.35463 0 5.24874C0 8.14286 2.43888 10.4975 5.43654 10.4975C6.5619 10.4975 7.63432 10.1698 8.5512 9.5477L11.7803 12.7901C11.9152 12.9254 12.0968 13 12.2913 13C12.4754 13 12.6501 12.9322 12.7827 12.809C13.0645 12.5472 13.0735 12.1132 12.8023 11.8409ZM5.43654 1.36924C7.65229 1.36924 9.45485 3.10954 9.45485 5.24874C9.45485 7.38795 7.65229 9.12825 5.43654 9.12825C3.2208 9.12825 1.41823 7.38795 1.41823 5.24874C1.41823 3.10954 3.2208 1.36924 5.43654 1.36924Z"
                  fill="white" />
              </svg>
            </span>
            <span>Search</span>
          </button>
          {validationError && (<div className="account-statement-page__filter-content-error">{validationError}</div>)}
        </div>
        <div className="account-statement-page__content">
          {
            selectPNLItem && (
              <AccountStatementDetailsTable
                accountStatement={accountStatement}
                selectPNLItem={selectPNLItem}
                detailsPnlData={detailsPnlData}
                goBack={() => setSelectPNLItem(null)}
              />)
          }
          {
            !selectPNLItem &&
            <AccountStatementTable
              accountStatement={accountStatement}
              setSelectPNLItem={item => setSelectPNLItem(item)}
            />
          }
        </div>
      </div>
    </div>
    // </IonPage>
  );
};

export default connectAccountStatementPage()(AccountStatementPage);
