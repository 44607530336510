import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetSearchResults, setSearchValue } from '../../store/search/search.slice';
import { requestSearchResults } from '../../store/search/search.thunks';
import { setNotifications, requestSaveOneClickSettings } from '../../store/user/user.thunks';
import { requestLogout } from '../../store/auth/auth.thunks'
import { setIsNavigationOpen, setIsNavigationID, setIsNavigationName, setIsNavigationReset, setIsAnimatedMenu, setIsMoreDetailed } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice';


export const connectLoaderContent = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      balance: state.balance,
      messages: state.message,
      notifications: state.user.settings.notifications,
      oneClick: state.user.settings.oneClickSettings,
      user: state.user,
      menu: state.menu,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      requestSaveOneClickSettings,
      requestSearchResults, 
      setSearchValue,
      resetSearchResults,
      setNotifications,
      requestLogout,
      setIsNavigationOpen,
      setIsNavigationID,
      setIsNavigationName,
      setIsNavigationReset,
      setIsAnimatedMenu,
      setIsMoreDetailed,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
