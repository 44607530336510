import { IonButton, IonPopover, IonItem, IonLabel, IonCheckbox } from '@ionic/react'
import React, { useState } from 'react'
import { connectKycPopover } from './KycPopover.connect'

const KycPopover = ({
  open,
  actions
}) => {
  const handleYes = async () => {
    actions.setKycPopoverShow(false)
  }

  const [checked, setChecked] = useState(true);

  return (
    <IonPopover isOpen={open} backdropDismiss={false} cssClass="MoreRulesPopover">
      <div className="MoreRulesPopover__content">
        <div className="MoreRulesPopover__title">KYC</div>
        <div className="MoreRulesPopover__text">We are committed to the highest level of security, hence require all our members to provide us with certain documentation in order to validate their accounts.</div>
        <div className="MoreRulesPopover__text">Please note that the identification process shall be complete before any withdrawal of funds can take place.</div>
        <div className="MoreRulesPopover__subtitle">Why do I need to provide documentation?</div>
        <div className="MoreRulesPopover__text">There are several reasons:</div>
        <ul>
          <li className="MoreRulesPopover__text">We are committed to providing a socially responsible platform for online gaming. All of our members must be 18 or older and we are bound by our licensing agreement to verify this.</li>
          <li className="MoreRulesPopover__text">It is in our interests to guarantee maximum security on all transactions.</li>
          <li className="MoreRulesPopover__text">Our payment processors and licensing agreement require that our policies are in line with international banking standards. A proven business relationship with each and every member is mandatory for the protection of all parties.</li>
          <li className="MoreRulesPopover__text">By ensuring that your account details are absolutely correct, the inconvenience of 'missing payments' can be avoided. It can take weeks (and sometimes months) to trace, recall and resend using the correct information. This lengthy process also results in additional fees from our processors.</li>
        </ul>
        <div className="MoreRulesPopover__subtitle">WHAT DOCUMENTS DO I NEED TO PROVIDE?</div>
        <div className="MoreRulesPopover__text">PROOF OF ID:</div>
        <div className="MoreRulesPopover__text">A color copy of a valid government issued form of ID (Driver's License, Passport, State ID or Military ID)</div>
        <div className="MoreRulesPopover__text">PROOF OF ADDRESS:</div>
        <div className="MoreRulesPopover__text">A copy of a recent utility bill showing your address</div>
        <div className="MoreRulesPopover__text">Note: If your government ID shows your address, you do not need to provide further proof of address.</div>
        <div className="MoreRulesPopover__text">Additional documentation might be required as the company sees fit.</div>
        <div className="MoreRulesPopover__subtitle">When do I need to provide these documents?</div>
        <div className="MoreRulesPopover__text">Please provide these at your earliest possible convenience to avoid any delays in processing your transactions. Documents must be received before any withdrawals are executed. Under special circumstances we may require the documents before further activity (deposits and wagering) can take place on your account</div>
        <div className="MoreRulesPopover__text">Please understand, if we do not have the required documents on file, your pending withdrawals will be cancelled and credited back to your account. You will be notified when this happens.</div>
        <div className="MoreRulesPopover__subtitle">How can I send you these documents?</div>
        <div className="MoreRulesPopover__text">Please scan your documents, or take a high quality digital camera picture, save the images as jpegs, then <a className="MoreRulesPopover__link" href="mailto:kyc@gemexch.bet">submit the files here</a>.</div>
        <div className="MoreRulesPopover__subtitle">How do I know my documents are safe with you?</div>
        <div className="MoreRulesPopover__text">All files are protected with the highest level of encryption at every step of the review process. All documentation received is treated with the utmost respect and confidentiality.</div>
        <div className="MoreRulesPopover__text">We’d like to thank you for your cooperation in helping us make our platform a safer place to play. As always, if you have any questions about this policy, or anything else, don’t hesitate to contact us using the <a className="MoreRulesPopover__link" href="mailto:contact@gemexch.bet">contact us here</a>.</div>
        <IonItem className="MoreRulesPopover__item">
          <IonCheckbox checked={checked} onIonChange={e => setChecked(e.detail.checked)} color="success" mode="md" />
          <IonLabel className={`MoreRulesPopover__label ${checked ? '' : 'MoreRulesPopover__label--disabled'}`}>Agree</IonLabel>
        </IonItem>
      </div>
      <IonButton
        onClick={handleYes}
        class="MoreRulesPopover__btn"
        mode="md"
        disabled={!checked}
      >
        Accept and Continue
      </IonButton>
    </IonPopover>
  )
}

export default connectKycPopover()(KycPopover)
