import { createSlice } from '@reduxjs/toolkit'

export const raceOddsSlice = createSlice({
  name: 'raceOdds',
  initialState: null,
  reducers: {
    getOddsSuccess: (state, action) => {
      return {
        ...state,
        [`${action.payload.eventTypeId} ${action.payload.groupById}`]: action.payload
      }
    },
    getOddsError: (state) => state,
  },
})

export const { getOddsSuccess, getOddsError } = raceOddsSlice.actions

export default raceOddsSlice.reducer