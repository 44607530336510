import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestChangePassword } from '../../store/user/user.thunks'

export const connectDepositTimer = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      user: state.user,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      // requestChangePassword,
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
