import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setIsAnimatedMenu,
  setIsNavigationID,
  setIsNavigationName,
  setIsNavigationOpen, setIsNavigationReset,
} from '../../../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setLoginNew } from '../../../../store/LoginNew/loginNew.slice'

export const connectSportButton = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      auth: state.auth,
      elementsParams: state.elementsParams,
      menu: state.menu,
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setIsNavigationOpen,
      setIsNavigationID,
      setIsNavigationName,
      setIsNavigationReset,
      setIsAnimatedMenu,
      setLoginNew
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
