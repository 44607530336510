import { createSlice } from '@reduxjs/toolkit'

export const moreRulesPopoverSlice = createSlice({
    name: 'moreRulesPopoverShow',
    initialState: null,
    reducers: {
        setMoreRulesPopoverShow: (state, action) => action.payload,
    },
})

export const {
    setMoreRulesPopoverShow,
} = moreRulesPopoverSlice.actions

export default moreRulesPopoverSlice.reducer
