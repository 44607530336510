import React from 'react'
import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage } from '@ionic/react'
import './MyMarkets.scss'
import { connectMyMarkets } from './MyMarkets.connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import leftSideMenuMapper from '../../helpers/getLeftSideMenuMapper.helper'
import { useHistory } from "react-router-dom";

const MyMarkets = ({ openBets }) => {
  const history = useHistory()
  const prepareMarkets = () => {
    if (openBets.matched && openBets.unmatched) {
      const markets = [...Object.values(openBets.matched), ...Object.values(openBets.unmatched)];
      markets.sort((function (a, b) {
        if (a.betPlacedDate < b.betPlacedDate) {
          return 1
        }
        if (a.betPlacedDate > b.betPlacedDate) {
          return -1
        }
        return 0
      }))

      if (markets.length) {
        return markets.map(market => {
          const menuHelperItem = Object.entries(leftSideMenuMapper).map(([key, value]) => value)
            .find(menuItemWrapper => market.eventTypeId == menuItemWrapper.id);
          return <IonItem className="MyMarket__list__item" key={market.name} onClick={() => history.push(`/event-page/${market.eventTypeId}/${market.eventId}`)}>
            <span className={`MyMarket__list__item__icon eventIcon ${menuHelperItem?.name.replace(/\s/g, '')}`}>
              { (!menuHelperItem?.isSvg && menuHelperItem?.img) || ''}
            </span>
            <IonLabel className="MyMarket__list__item__label">{market.name}</IonLabel>
          </IonItem>
        })
      }
      else {
        return <IonLabel className="MyMarket__list__empty">You don't have any markets</IonLabel>
      }
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="MyMarket" fullscreen>
        {openBets
          ?
          <IonList className="MyMarket__list">
            <IonListHeader className="MyMarket__list__header">
              My Markets
            </IonListHeader>
            {prepareMarkets()}
          </IonList>
          : <LoaderContent />
        }
      </IonContent>
    </IonPage >
  )
}

export default connectMyMarkets()(MyMarkets)
