import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
// import { requestRacingPageData } from '../../store/racingPage/racingPage.thunks'

export const connectUpcomingRacesAccordion = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      timeSettings: state.timeSettings,
      betPlacing: state.betPlacing,
    }
  }

  // function mapDispatchToProps(dispatch) {
  //   const actions = {
  //     requestRacingPageData
  //   }
  //
  //   return { actions: bindActionCreators(actions, dispatch) }
  // }


  // return connect(mapStateToProps, mapDispatchToProps)(component)
  return connect(mapStateToProps)(component)
}
