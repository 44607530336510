import { createSlice } from '@reduxjs/toolkit'

export const elementsParamsSlice = createSlice({
  name: 'elementsParams',
  initialState: {headerHeight: null, mainContainerHeight: null, scrollPosition: null, clientWidth: null},

  reducers: {
    setHeaderHeight: (state, action) => ({...state, headerHeight:  action.payload}),
    setMainContainerHeight: (state, action) => ({...state, mainContainerHeight:  action.payload}),
    setScrollPosition: (state, action) => ({...state, scrollPosition:  action.payload}),
    setClientWidth: (state, action) => ({...state, clientWidth:  action.payload}),
  }
})

export const {
  setHeaderHeight,
  setMainContainerHeight,
  setScrollPosition,
  setClientWidth,
} = elementsParamsSlice.actions

export default elementsParamsSlice.reducer
