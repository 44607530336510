import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {requestTransferStatementSuccess} from "../../store/transferStatement/transferStatement.thunks";

export const connectTransferStatementPage = (wrappedProps) => (component) => {
    function mapStateToProps(state) {
        return {
            transferStatement: state.transferStatement,
            user: state.user,
            elementsParams: state.elementsParams
        }
    }

    function mapDispatchToProps(dispatch) {
        const actions = {
            requestTransferStatementSuccess
        }

        return { actions: bindActionCreators(actions, dispatch) }
    }

    return connect(mapStateToProps, mapDispatchToProps)(component)
}
