import React, { useEffect } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import "./styles/index.scss"
import CardsInfo from '../helpers/CardsInfo/CardsInfo'
import LastResults from '../helpers/LastResults/LastResults'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../../config/app.config'
// import BetPlacing from '../../BetPlacing/BetPlacing'
import spades from "../../../assets/images/exchangeGames/race20/spades.svg"
import hearts from "../../../assets/images/exchangeGames/race20/hearts.svg"
import clubs from "../../../assets/images/exchangeGames/race20/clubs.svg"
import diamonds from "../../../assets/images/exchangeGames/race20/diamonds.svg"
import { connectRace20 } from './Race20.connect'

const Race20 = ({exchangeGames, sportEvent, betPlacing, actions, exposure, eventId, lastResultsExch, headerHeight, auth, elementsParams}) => {

  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  // sport event data
  const dataOdd = sportEvent.sportData
  // end sport event data

  // request Exchange Game data
  
  useEffect(() => {
    const timer = setIntervalAsync(async() => {
      await actions
              .requestExchangeGames(eventId)
              .catch((e) => console.log('requestInplayPopular error = ', e))
    }, GET_INPLAY_POPULAR_INTERVAL)
    return () => clearIntervalAsync(timer)
  }, [])
  // End request Exchange Game data

  // Show betslip
  // const renderBetPlacing = () => {
  //   if (betPlacing.runner && width < 1024) {
  //     return <BetPlacing headerHeight={headerHeight}/>
  //   }
  //   return null
  // }
  // End Show betslip

  // Bet placing
  const select = (dataOdd, item, isBack) => {
    const runner = { name: item.name, id: item.id }
    const oddData = isBack
      ? { ...item.back[0], runner }
      : { ...item.lay[0], runner }
    const marketId = dataOdd && dataOdd.id
    const runnerId = oddData && oddData.runner && oddData.runner.id
    console.log(marketId, runnerId)
    if (!marketId || !runnerId) return
    actions.setMarket(marketId)
    actions.setRunner(runnerId)
    actions.setRunnerName(oddData && oddData.runner && oddData.runner.name)
    actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name)
    actions.setIsBack(isBack)
    actions.setPrice(oddData && oddData.price)
    actions.setPriceActual(oddData && oddData.price)
    actions.setDelay(dataOdd && dataOdd.betDelay)
    actions.setMtype(dataOdd && dataOdd.mtype)
    actions.setBtype(dataOdd && dataOdd.btype)
    actions.setLine(oddData && oddData.line)
    actions.setFromOneClick(false)
    actions.setStake(null)

    actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {})
      .catch((err) => console.log('err = ', err))
  }

  const oddPush = (marketIndex, runnerIndex, isBack = true) => {
    return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
    )
  }
  // End Bet placing

  //get market price
  const getMarketPrice = (marketIndex, runnersIndex, back = true) => {
    if(!back) {
      return (
        dataOdd &&
        dataOdd[marketIndex] &&
        dataOdd[marketIndex].runners[runnersIndex] &&
        dataOdd[marketIndex].runners[runnersIndex].lay[0] &&
        dataOdd[marketIndex].runners[runnersIndex].lay[0].price
      )
    }
    return (
      dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0].price
    )
  }
  //end get market price

  // get exposure
  const getExposure = (marketIndex, runnerIndex) => {
    if(auth) {
      let id = dataOdd[marketIndex].runners[runnerIndex].id
      let objFinder =  exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl

      return (
        <div
          className={objFinder > 0 ? 'exposure-box-modern exposure-modern--pos' : 'exposure-box-modern exposure-modern--neg'}
        >
          {objFinder && objFinder}
        </div>
      )
    } else return
  }
  // end get exposure

  // lock market
  const marketStatus = (marketIndex) => {
    return (!dataOdd[marketIndex].isBettable && 'lock-ui')
  }
  // end lock market

  // get winner
  const getWinner = (marketIndex, runnerIndex) => {
    return exchangeGames &&
      exchangeGames[marketIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex] &&
      exchangeGames[marketIndex]?.marketRunner[runnerIndex]?.status
  }
  // get winner


  return (
    <>
      <CardsInfo/>
      <div className="race20-table" >
        {/* <>{ width < 1024 ? renderBetPlacing() : '' }</> */}
        <div className={`race20-table__player-item ${getWinner(0,0)}`}>
          <div className="race20-table__player-card-exposure">
            <div className="race20-table__player-card">
              <img src={spades} alt=""/>
            </div>
            {getExposure(0,0)}
          </div>
          <div className="race20-table__player-bets">
            <div className={`race20-table__player-bets-back ${marketStatus(0)}`} onClick={() => {oddPush(0,0, true)}}>
              <span>{getMarketPrice(0,0,true)}</span>
            </div>
            <div className={`race20-table__player-bets-lay ${marketStatus(0)}`} onClick={() => {oddPush(0,0, false)}}>
              <span>{getMarketPrice(0,0,false)}</span>
            </div>
          </div>
        </div>
        <div className={`race20-table__player-item ${getWinner(0,1)}`}>
          <div className="race20-table__player-card-exposure">
            <div className="race20-table__player-card">
              <img src={hearts} alt=""/>
            </div>
            {getExposure(0,1)}
          </div>
          <div className="race20-table__player-bets">
            <div className={`race20-table__player-bets-back ${marketStatus(0)}`} onClick={() => {oddPush(0,1, true)}}>
              <span>{getMarketPrice(0,1,true)}</span>
            </div>
            <div className={`race20-table__player-bets-lay ${marketStatus(0)}`} onClick={() => {oddPush(0,1, false)}}>
              <span>{getMarketPrice(0,1,false)}</span>
            </div>
          </div>
        </div>
        <div className={`race20-table__player-item ${getWinner(0,2)}`}>
          <div className="race20-table__player-card-exposure">
            <div className="race20-table__player-card">
              <img src={clubs} alt=""/>
            </div>
            {getExposure(0,2)}
          </div>
          <div className="race20-table__player-bets">
            <div className={`race20-table__player-bets-back ${marketStatus(0)}`} onClick={() => {oddPush(0,2, true)}}>
              <span>{getMarketPrice(0,2,true)}</span>
            </div>
            <div className={`race20-table__player-bets-lay ${marketStatus(0)}`} onClick={() => {oddPush(0,2, false)}}>
              <span>{getMarketPrice(0,2,false)}</span>
            </div>
          </div>
        </div>
        <div className={`race20-table__player-item ${getWinner(0,3)}`}>
          <div className="race20-table__player-card-exposure">
            <div className="race20-table__player-card">
              <img src={diamonds} alt=""/>
            </div>
            {getExposure(0,3)}
          </div>
          <div className="race20-table__player-bets">
            <div className={`race20-table__player-bets-back ${marketStatus(0)}`} onClick={() => {oddPush(0,3, true)}}>
              <span>{getMarketPrice(0,3,true)}</span>
            </div>
            <div className={`race20-table__player-bets-lay ${marketStatus(0)}`} onClick={() => {oddPush(0,3, false)}}>
              <span>{getMarketPrice(0,3,false)}</span>
            </div>
          </div>
        </div>
      </div>
      <LastResults/>
    </>
  )
}

export default connectRace20()(Race20)
