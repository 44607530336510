import { createSlice } from '@reduxjs/toolkit'

export const casinoLiveSlice = createSlice({
  name: 'casinoLive',
  initialState: null,
  reducers: {
    getCasinoLiveSuccess: (state, action) => action.payload,
    getCasinoLiveError: (state) => null,
  },
})

export const { getCasinoLiveSuccess, getCasinoLiveError } = casinoLiveSlice.actions

export default casinoLiveSlice.reducer
