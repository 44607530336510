import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setSportLadderShow, setSportLadderTitle, setSportLadderId } from '../../store/sportEvent/sportEvent.slice'
import { requestSportLadder } from '../../store/sportEvent/sportEvent.thunks'
import { requestBetPlacingStart, requestCashOut } from "../../store/betPlacing/betPlacing.thunks";
import { setShowBetPlacingPopover, setBetPlacingPopoverStage, setDelay, setIsCashOut, setCashOutId } from '../../store/betPlacing/betPlacing.slice';

export const connectSportMarketStandard = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      exposure: state.eventPage.exposure,
      elementsParams: state.elementsParams
    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setSportLadderShow,
      setSportLadderTitle,
      setSportLadderId,
      requestSportLadder,
      requestBetPlacingStart,
      requestCashOut,
      setShowBetPlacingPopover,
      setBetPlacingPopoverStage,
      setDelay,
      setIsCashOut,
      setCashOutId

    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
