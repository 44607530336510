import { createSlice } from '@reduxjs/toolkit'

export const slotsGamesSlice = createSlice({
    name: 'slotsGames',
    initialState: 
    {
        mainGames: null,
        allSlots: null,
        fishingGames: null,
        bingoGames: null,
        virtualGames: null,
        multiplayerGames: null,
        scratchGames: null,
        slotsGamesButtons: null,
        quickButtonSlots: ''

    },
    reducers: {
        getSlotsGamesSuccess: (state, action) => action.payload,
        getSlotsGamesError: (state) => state,

        getSlotsGamesIDSuccess: (state, action) => ({...state, slotsGamesID: action.payload}),
        getSlotsGamesIDError: (state) => state,

        getSlotsGamesButtonsSuccess: (state, action) => ({...state, slotsGamesButtons: action.payload}),
        getSlotsGamesButtonsError: (state) => state,

        mainGames: (state, action) => ({...state, mainGames: action.payload}),
        allSlots: (state, action) => ({...state, allSlots: action.payload}),
        fishingGames: (state, action) => ({...state, fishingGames: action.payload}),
        bingoGames: (state, action) => ({...state, bingoGames: action.payload}),
        multiplayerGames: (state, action) => ({...state, multiplayerGames: action.payload}),
        virtualGames: (state, action) => ({...state, virtualGames: action.payload}),
        scratchGames: (state, action) => ({...state, scratchGames: action.payload}),
        setQuickButtonSlots: (state, action) => ({...state, quickButtonSlots: action.payload}),

    },
})

export const {
    getSlotsGamesSuccess,
    getSlotsGamesError,
    getSlotsGamesIDSuccess,
    getSlotsGamesIDError,
    getSlotsGamesButtonsSuccess,
    getSlotsGamesButtonsError,
    mainGames,
    allSlots,
    fishingGames,
    bingoGames,
    multiplayerGames,
    virtualGames,
    scratchGames,
    setQuickButtonSlots,
} = slotsGamesSlice.actions

export default slotsGamesSlice.reducer
