import React, { useEffect, useState, useRef } from 'react'
import { IonContent, IonPage, IonHeader,  } from '@ionic/react'
import { connectLiveCasinoLobby } from './LiveCasinoLobby.connect'
import { useHistory, useParams } from 'react-router-dom'
import './LiveCasinoLobby.scss'
import LobbyTopImg from '../../assets/images/liveCasinoLobby/livecasinoLobby.svg'
import LobbyLines from '../../assets/images/liveCasinoLobby/bgLinesCasino.jpg'
import LobbyLine from '../../assets/images/liveCasinoLobby/bgLineCasino.svg'
import LobbyMobileTopImg from '../../assets/images/liveCasinoLobby/LiveCasinoMobileTop.png'
import Toolbar from '../../components/Toolbar/Toolbar'
import mobileBgImg from '../../assets/images/exchange_games_bg_mobile.jpg'

import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';
import { wifi } from 'ionicons/icons'
import SlotsCasinoPopUp from '../../components/SlotsCasinoPopup'


const LiveCasinoLobby = ({ inplayPopular, actions, liveCasino, liveCasinoSearch, auth, elementsParams, quickButtonSlots, slotCasinoPopUp }) => {
  const [seeAllGames, setSeeAllGames] = useState(false)
  const [seeTopGames, setSeeTopGames] = useState(false)
  const [seeLiveGames, setSeeLiveGames] = useState(false)
  const [seeTableGames, setSeeTableGames] = useState(false)
  const [seePokerGames, setSeePokerGames] = useState(false)
  const [seeBlackjackGames, setSeeBlackjackGames] = useState(false)
  const [seeBaccaratGames, setSeeBaccaratGames] = useState(false)
  const [seeRouletteGames, setSeeRouletteGames] = useState(false)
  const [seeShowsGames, setSeeShowsGames] = useState(false)
  const [seeVirtualGames, setSeeVirtualGames] = useState(false)
  const [searchOn, setSearchOn] = useState(false)
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const history = useHistory()

  const wrapper = useRef(null);
  const menu = useRef(null);

  const handleClick = (ref) => {
    ref && ref.current && ref.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth'});
  };

  const handleClickRef = (id) => {
    let ref
    if (id === '11') ref = allgames
    if (id === '12') ref = topgames
    if (id === '13') ref = tablegames
    if (id === '14') ref = livegames
    if (id === '15') ref = pokergames
    if (id === '16') ref = blackjackgames
    if (id === '17') ref = baccaratgames
    if (id === '18') ref = roulettegames
    if (id === '19') ref = showsgames
    if (id === '20') ref = virtualgames
    if (id === 'livecasino-lobby') return


   if(ref){ 
    ref && ref.current && ref.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }
  }

  useEffect (() => {
    if (quickButtonSlots){
      setTimeout (() => {
        handleClickRef(`${quickButtonSlots}`)
        actions.setQuickButtonSlots('')
      }, 1000)
    }
  }, [])

    const allgames = useRef(null);
    const topgames = useRef(null);
    const livegames = useRef(null);
    const tablegames = useRef(null);
    const pokergames = useRef(null);
    const blackjackgames = useRef(null);
    const baccaratgames = useRef(null);
    const roulettegames = useRef(null);
    const showsgames = useRef(null);
    const virtualgames = useRef(null);
    const gamesAll = useRef(null);

  useEffect(() => {
    actions.requestLiveCasinoLobby()
  }, [])

  const onOpenGame = (id) => {
    if(auth) {
      actions.requestLiveCasinoID(id)
      history.push(`/live_casino_slot/${id}`)
    } else {
      actions.setLoginNew(true)
    }
  }

  const onSearchGames = (e) => {
    console.log('e', e)
    if(e.length >= 4){
      setSearchOn(true)
      actions.requestLiveCasinoSearch(e)
      console.log('aaa', actions.requestLiveCasinoSearch(e));
    } else {
      setSearchOn(false)
    }
  }

  // const checkImgSrc = (src, text) => {
  //   const img = new Image();
  //   img.src = src;
  //   img.onload = function () { return src }
  //   img.onerror = function () {
  //     return (
  //       <div className='altBackgroundCasino'>{text}</div>
  //     )
  //   }
  // }



  return (
    <>
    {slotCasinoPopUp && <SlotsCasinoPopUp/>}
    <div ref={wrapper} className='liveCasinoLobbyWrapper'></div>
      {width < 1 ? (
        <IonPage>
          <IonContent>
            <div></div>
          </IonContent>
        </IonPage>
      ) : (
        <IonPage>
          {width < 1024 ?
          <IonHeader>
            <Toolbar/>
          </IonHeader>
          :
          <></>}
          <IonContent>
            <div className="bgCasino">
              <div className="liveCasino-header">
                {width < 1024 ?
                <img
                  className="liveCasino-header__img"
                  src={LobbyMobileTopImg}
                  alt=""
                />
                :
                  <img
                  className="liveCasino-header__img"
                  src={LobbyTopImg}
                  alt=""
                />}
                {/* <div className='lava_cont'> */}

              <div class="lamp">
                <div class="lava">
                <div className="liveCasino-header__text">
                  <div className="liveCasino-header__text-name animate-charcter ">
                    Live Casino
                  </div>
                  <div className="liveCasino-header__text-credo">
                    Play. Win. Enjoy.
                  </div>
                </div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob"></div>
                  <div class="blob2">
                    <svg xlinkHref="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="url('#myGradient')" viewBox="0 0 310 350">
                    <path d="M156.4,339.5c31.8-2.5,59.4-26.8,80.2-48.5c28.3-29.5,40.5-47,56.1-85.1c14-34.3,20.7-75.6,2.3-111  c-18.1-34.8-55.7-58-90.4-72.3c-11.7-4.8-24.1-8.8-36.8-11.5l-0.9-0.9l-0.6,0.6c-27.7-5.8-56.6-6-82.4,3c-38.8,13.6-64,48.8-66.8,90.3c-3,43.9,17.8,88.3,33.7,128.8c5.3,13.5,10.4,27.1,14.9,40.9C77.5,309.9,111,343,156.4,339.5z"/>
                    <defs>
                      <linearGradient id="myGradient" gradientTransform="rotate(90)">
                        <stop offset="0%"  stopColor="#eeaeca" />
                        <stop offset="10%" stopColor="#d99cce" />
                        <stop offset="24%" stopColor="#de86e9" />
                        <stop offset="47%" stopColor="#c996e9" />
                        <stop offset="68%" stopColor="#a5aee9" />
                        <stop offset="100%" stopColor="#94bbe9" />

                      </linearGradient>
                    </defs>
                    {/* <circle cx="50" cy="50" r="25" fill="url('#myGradient')" /> */}
                    </svg>
                  </div>
                  <div class="blob3">
                    <svg xlinkHref="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="url('#myGradient')" viewBox="0 0 310 350">
                    <path d="M156.4,339.5c31.8-2.5,59.4-26.8,80.2-48.5c28.3-29.5,40.5-47,56.1-85.1c14-34.3,20.7-75.6,2.3-111  c-18.1-34.8-55.7-58-90.4-72.3c-11.7-4.8-24.1-8.8-36.8-11.5l-0.9-0.9l-0.6,0.6c-27.7-5.8-56.6-6-82.4,3c-38.8,13.6-64,48.8-66.8,90.3c-3,43.9,17.8,88.3,33.7,128.8c5.3,13.5,10.4,27.1,14.9,40.9C77.5,309.9,111,343,156.4,339.5z"/>
                    <defs>
                      <linearGradient id="myGradient" gradientTransform="rotate(90)">
                        <stop offset="0%"  stopColor="#eeaeca" />
                        <stop offset="10%" stopColor="#d99cce" />
                        <stop offset="24%" stopColor="#de86e9" />
                        <stop offset="47%" stopColor="#c996e9" />
                        <stop offset="68%" stopColor="#a5aee9" />
                        <stop offset="100%" stopColor="#94bbe9" />

                      </linearGradient>
                    </defs>
                    {/* <circle cx="50" cy="50" r="25" fill="url('#myGradient')" /> */}
                    </svg>
                  </div>

                {/* </div> */}
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
              <defs>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                  <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                  <feBlend in="SourceGraphic" in2="goo" />
                </filter>
              </defs>
            </svg>
                </div>
                {/* <img
                  className="liveCasino-header__img"
                  src={LobbyTopImg}
                  alt=""
                />*/}
                {/* {width < 1024 ?
                <div className="liveCasino-header__text">
                  <div className="liveCasino-header__text-name">
                    Live Casino
                  </div>
                  <div className="liveCasino-header__text-credo">
                    Play. Win. Enjoy.
                  </div>
                </div>
                :
                <></>} */}
              </div>

              <div className={`liveCasino-content ${width < 1024 ? 'mobile' : ''}`}>

                <div className="liveCasino-content__menu">
                  {width < 1024 ?
                  <>
                    <div style={{
                        margin: '8px 10px 8px 10px',
                        display: 'flex',
                        justifyContent: 'end' }}>
                      <div className="inputContainerMobile-live">
                        <input
                          placeholder="SEARCH GAMES"
                          className="inputContainerMobile-live__input"
                          name="search"
                          autoComplete="off"
                          onChange={(e) => onSearchGames(e.target.value)}
                        />
                      </div>
                    </div>
                  <div className="liveCasino-content__menu-filerMobile">


                    <div className="liveCasino-content__menu-filerMobile-column">
                      <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => handleClick(allgames)}>All games</div>
                      <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => handleClick(topgames)}>Top games</div>
                      <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => handleClick(livegames)}>Live games</div>
                      <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => handleClick(tablegames)}>Table games</div>
                      <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => handleClick(pokergames)}>Poker</div>
                    </div>

                    <div className="liveCasino-content__menu-filerMobile-column">
                      <div className="liveCasino-content__menu-filerMobile-column-item"
                      onClick={() => handleClick(blackjackgames)}>Blackjak</div>
                      <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => handleClick(baccaratgames)}>Baccarat</div>
                      <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => handleClick(roulettegames)}>roulette</div>
                      <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => handleClick(showsgames)}>Games Shows</div>
                      <div className="liveCasino-content__menu-filerMobile-column-item" onClick={() => handleClick(virtualgames)}>Virtual</div>
                    </div>
                  </div>
                  </>
                  :
                  <>
                  <div className="liveCasino-content__menu-filter">
                    <div className="liveCasino-content__menu-filter-row">
                      <div className="a" onClick={() => handleClick(allgames)}>
                        <span>ALL GAMES</span>
                        <div className="liquid"></div>
                      </div>
                      <div className="a" onClick={() => handleClick(topgames)}>
                        <span>TOP GAMES</span>
                        <div className="liquid"></div>
                      </div>
                      <div className="a" onClick={() => handleClick(livegames)}>
                        <span>LIVE GAMES</span>
                        <div className="liquid"></div>
                      </div>
                      <div className="a" onClick={() => handleClick(tablegames)}>
                        <span>TABLE GAMES</span>
                        <div className="liquid"></div>
                      </div>
                      <div className="inputContainer">
                        <input
                          placeholder="SEARCH GAMES"
                          className="inputContainer__input"
                          name="search"
                          autoComplete="off"
                          onChange={(e) => onSearchGames(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="liveCasino-content__menu-filter">
                    <div className="liveCasino-content__menu-filter-row">
                      {/* <div className="liveCasino-content__menu-filter-row__button">
                        All Games
                      </div>
                      <div className="liveCasino-content__menu-filter-row__button">
                        Top Games
                      </div>
                      <div className="liveCasino-content__menu-filter-row__button">
                        Live Games
                      </div>
                      <div className="liveCasino-content__menu-filter-row__button">
                        Table Games
                      </div> */}
                      {/* <input
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          marginBottom: '20px',
                        }}
                        className="liveCasino-content__menu-filter-row__search"
                      /> */}

                      <div className="liveCasino-content__menu-filter-row__button" onClick={() => handleClick(pokergames)}>
                        Poker
                      </div>

                      <div className="liveCasino-content__menu-filter-row__button" onClick={() => handleClick(blackjackgames)}>
                        Blackjack
                      </div>
                      <div className="liveCasino-content__menu-filter-row__button" onClick={() => handleClick(baccaratgames)}>
                        Baccarat
                      </div>

                      <div className="a" onClick={() => handleClick(roulettegames)}>
                        <span>Roulette</span>
                        <div className="liquid"></div>
                      </div>

                      <div className="liveCasino-content__menu-filter-row__button" onClick={() => handleClick(showsgames)}>
                        Games Shows
                      </div>
                      <div className="liveCasino-content__menu-filter-row__button" onClick={() => handleClick(virtualgames)}>
                        Virtual
                      </div>
                    </div>
                  </div>
                  </>}


                  <div className="liveCasino-content__menu-games">
                    <div className="liveCasino-content__menu-games__allgames">

                    {!searchOn ?
                    <>
                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={allgames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          All games
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                          onClick = {() => {
                            setSeeAllGames(!seeAllGames)
                            actions.requestLiveCasino('11')
                          }}
                        >
                          {seeAllGames ? 'See less' : 'See all'}
                        </div>
                      </div>

                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div
                        style={seeAllGames
                          ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                          : {}}
                        className="liveCasino-content__menu-games__allgames-items">
                          {!seeAllGames ?
                          liveCasino && liveCasino[0]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                  <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.allGames ? liveCasino?.allGames?.map((item) => {
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[0]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}

                      </div>
                      </div>

                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span  ref={topgames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          Top games
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeeTopGames(!seeTopGames)
                          actions.requestLiveCasino('12')
                        }}>
                          {seeTopGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div
                        style={seeTopGames
                          ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                          : {}}
                        className="liveCasino-content__menu-games__allgames-items">
                          {!seeAllGames ?
                          liveCasino && liveCasino[1]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.topGames ? liveCasino?.topGames?.map((item) => {
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[1]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                  <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                      </div>

                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={livegames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          Live games
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeeLiveGames(!seeLiveGames)
                          actions.requestLiveCasino('14')
                        }}>
                          {seeLiveGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div
                        style={seeLiveGames
                          ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                          : {}}
                        className="liveCasino-content__menu-games__allgames-items">
                        {!seeLiveGames ?
                          liveCasino && liveCasino[2]?.games?.map((item) => {
                            // console.log('livecasino2', liveCasino[2])
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.liveGames ? liveCasino?.liveGames?.map((item) => {


                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[2]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                      </div>

                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={tablegames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          Table games
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeeTableGames(!seeTableGames)
                          actions.requestLiveCasino('13')
                        }}>
                          {seeTableGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div className="liveCasino-content__menu-games__allgames-items"
                      style={seeTableGames
                        ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                        : {}}>
                        {!seeTableGames ?
                          liveCasino && liveCasino[3]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.tableGames ? liveCasino?.tableGames?.map((item) => {
                              console.log('pizda', liveCasino?.tableGames);
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[3]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                      </div>

                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={pokergames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          {' '}
                          Poker
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeePokerGames(!seePokerGames)
                          actions.requestLiveCasino('15')
                        }}>
                          {seePokerGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div className="liveCasino-content__menu-games__allgames-items"
                      style={seePokerGames
                        ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                        : {}}>
                        {!seePokerGames ?
                          liveCasino && liveCasino[4]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.pokerGames ? liveCasino?.pokerGames?.map((item) => {
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[4]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                             <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                      </div>

                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={blackjackgames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          Blackjack
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeeBlackjackGames(!seeBlackjackGames)
                          actions.requestLiveCasino('16')
                        }}>
                          {seeBlackjackGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div className="liveCasino-content__menu-games__allgames-items"
                      style={seeBlackjackGames
                        ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                        : {}}>
                        {!seeBlackjackGames ?
                          liveCasino && liveCasino[5]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.blackjackGames ? liveCasino?.blackjackGames?.map((item) => {
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[5]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                      </div>

                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={baccaratgames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          Baccarat
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeeBaccaratGames(!seeBaccaratGames)
                          actions.requestLiveCasino('17')
                        }}>
                          {seeBaccaratGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div className="liveCasino-content__menu-games__allgames-items"
                      style={seeBaccaratGames
                        ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                        : {}}>
                        {!seeBaccaratGames ?
                          liveCasino && liveCasino[6]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.baccaratGames ? liveCasino?.baccaratGames?.map((item) => {
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[6]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                      </div>

                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={roulettegames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          Roulette
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeeRouletteGames(!seeRouletteGames)
                          actions.requestLiveCasino('18')
                        }}>
                          {seeRouletteGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div className="liveCasino-content__menu-games__allgames-items"
                      style={seeRouletteGames
                        ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                        : {}}>
                        {!seeRouletteGames ?
                          liveCasino && liveCasino[7]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.rouletteGames ? liveCasino?.rouletteGames?.map((item) => {
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div style={!item?.image? {display: 'none'} : {}} className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[7]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                      </div>

                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={showsgames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          Games shows
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeeShowsGames(!seeShowsGames)
                          actions.requestLiveCasino('19')
                        }}>
                          {seeShowsGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div className="liveCasino-content__menu-games__allgames-items"
                      style={seeShowsGames
                        ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                        : {}}>
                        {!seeShowsGames ?
                          liveCasino && liveCasino[8]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.showsGames ? liveCasino?.showsGames?.map((item) => {
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[8]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                      </div>

                      <div className="liveCasino-content__menu-games__allgames-title">
                        <span ref={virtualgames} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                          Virtual
                        </div>
                        <div className="liveCasino-content__menu-games__allgames-title-seeAll"
                        onClick = {() => {
                          setSeeVirtualGames(!seeVirtualGames)
                          actions.requestLiveCasino('20')
                        }}>
                          {seeVirtualGames ? 'See less' : 'See all'}
                        </div>
                      </div>
                      <div style={{width: '100%', overflowX: 'auto'}}>
                      <div className="liveCasino-content__menu-games__allgames-items"
                      style={seeVirtualGames
                        ? {height:'100%', flexDirection: 'row', justifyContent: 'space-around'}
                        : {}}>
                        {!seeVirtualGames ?
                          liveCasino && liveCasino[9]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item">
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                  <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })
                          :
                          liveCasino && liveCasino?.virtualGames ? liveCasino?.virtualGames?.map((item) => {
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item">
                                <div className='altBackgroundCasino' onClick = {() => onOpenGame(item?.id)}>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                  <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })
                          :
                          liveCasino && liveCasino[9]?.games?.map((item) => {
                            return (
                            <div className="liveCasino-content__menu-games__allgames-items-item" onClick = {() => onOpenGame(item?.ID)}>
                              <div className='altBackgroundCasino'>
                                 <span>{item?.Trans?.en}</span>
                                <img
                                  src={item?.ImageURL}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.ID)}/>
                                  <div  className='img-gamename'>{item?.Trans?.en}</div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                      </div>

                      <div
                        style={{visibility: 'hidden', marginBottom: '150px'}}
                        className="liveCasino-content__menu-games__allgames-title">
                        <span ref={gamesAll} className="liveCasino-content__menu-games__allgames-title-ref"></span>
                        <div className="liveCasino-content__menu-games__allgames-title-name">
                        </div>
                      </div>
                      </>

                      :
                      <>
                      <div className="liveCasino-content__menu-games__allgames-title">
                        <div
                          className="liveCasino-content__menu-games__allgames-title-name">
                          Games
                        </div>
                      </div>
                      <div
                        style={{height:'100%', marginBottom:'150px', flexDirection: 'row', justifyContent: 'space-around'}}
                        className="liveCasino-content__menu-games__allgames-items">
                          {liveCasinoSearch && liveCasinoSearch?.map((item) => {
                              return (
                              <div className="liveCasino-content__menu-games__allgames-items-item" onClick = {() => onOpenGame(item?.id)}>
                                <div className='altBackgroundCasino'>
                                 <span>{item?.name}</span>
                                <img
                                  src={item?.image}
                                  alt=" "
                                  onClick = {() => onOpenGame(item?.id)}/>
                                <div  className='img-gamename'>{item?.name}</div>
                              </div>
                              </div>
                            )
                          })}
                      </div>
                      </>

                    }

                    </div>
                    </div>
                </div>
              </div>
              {width > 1024 && <FooterDesktop />}
            </div>
          </IonContent>
         </IonPage>
      )}
    </>
  )
}

export default connectLiveCasinoLobby()(LiveCasinoLobby)
