import { createSlice } from '@reduxjs/toolkit'

export const timeSettingsSlice = createSlice({
  name: 'timeSettings',
  initialState: {
    timeZoneName: 'device',
  },
  reducers: {
    setTimeZoneName: (state, action) => ({ ...state, timeZoneName: action.payload }),
    setServerTimeSuccess: (state, action) => ({ ...state, serverTime: action.payload }),
  },
})

export const {
  setTimeZoneName,
  setServerTimeSuccess,
} = timeSettingsSlice.actions

export default timeSettingsSlice.reducer
