import "./LiveTeenpattiPL.scss";
import React, { useEffect, useState } from 'react'

const LiveTeenpattiPL = ({roundMarkets}) => {
 let [winnerData, setWinnerData] = useState(null);

 const getWinner = () => {
   let winObj = {
     winners: '',
     pairPlus: '',
   };
   if (roundMarkets.length > 0) {
     roundMarkets.forEach((market) => {
       market.marketRunner.forEach((runner) => {
         if (runner.status === 'WINNER') {
           winObj[market.marketHeader] = runner.name;
         }
         if (market.marketHeader === 'Pair Plus' && runner.status === 'WINNER') {
           winObj.pairPlus = winObj?.pairPlus?.length <= 3 ?
             `${runner.name.slice(7, 8)} : ${runner.resDesc[0] + runner.resDesc.toLowerCase().slice(1)}`
             :
             `${winObj.pairPlus} | ${runner.name.slice(7, 8)} : ${runner.resDesc[0] + runner.resDesc.toLowerCase().slice(1)}`;
         }
       });
     });
   }
   setWinnerData(winObj)
  }
  useEffect(() => {
    getWinner()
  }, [roundMarkets]);

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`;
    } else {
      return "https://stream.fawk.app/assets/smallCards/BACK.png";
    }
  };

    return (
      <div className="live-teenpatti-betting">
        <div className="live-teenpatti-betting__games">
          <div className="live-teenpatti-betting__games-item">
            <span
              className={`live-teenpatti-betting__games-item-title ${roundMarkets[0].marketRunner[0].status === 'WINNER' ? 'winnerCup':''}`}>
              Player A
            </span>
            <div className="live-teenpatti-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[0]?.cards.map( card =>
                <img className="live-teenpatti-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
          <div className="live-teenpatti-betting__games-item">
            <span
              className={`live-teenpatti-betting__games-item-title second ${roundMarkets[0].marketRunner[1].status === 'WINNER' ? 'winnerCup':''}`}>
                Player B
            </span>
            <div className="live-teenpatti-betting__games-item-cards">
              {roundMarkets && roundMarkets[0].marketRunner[1]?.cards.map( card =>
                <img className="live-teenpatti-betting__games-item-cards-card"
                     key={card}
                     src={cardSrc(card)}
                     alt={card}
                />
              )}
            </div>
          </div>
        </div>
        {winnerData && <div className="live-teenpatti-betting__info-column">
          <div className="live-teenpatti-betting__info-row">
            <div className="live-teenpatti-betting__info-row-title">
              <span>Winner:</span>
            </div>
            <div className="live-teenpatti-betting__info-row-results">
              <span>{winnerData['Match odds'] || 'No Winner'}</span>
            </div>
          </div>
          <div className="live-teenpatti-betting__info-row">
            <div className="live-teenpatti-betting__info-row-title">
              <span>Pair Plus:</span>
            </div>
            <div className="live-teenpatti-betting__info-row-results">
              <span>{winnerData['pairPlus'] || 'No Winner'}</span>
            </div>
          </div>
        </div>}
      </div>

    );
};

export default LiveTeenpattiPL;

