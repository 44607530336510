import React from 'react'
import './Race20Cards.scss'
import { connectRace20 } from '../../Race20.connect'

import whiteSpades from "../../../../../assets/images/exchangeGames/race20/small-white-spades.svg"
import whiteHearts from "../../../../../assets/images/exchangeGames/race20/small-white-hearts.svg"
import whiteClubs from "../../../../../assets/images/exchangeGames/race20/small-white-clubs.svg"
import whiteDiamonds from "../../../../../assets/images/exchangeGames/race20/small-white-diamonds.svg"

const Race20Cards = ({ exchangeGames }) => {

  const cardSrc = (card) => {
    if (card) {
      return <img key={card} src={`https://stream.fawk.app/assets/smallCards/${card}.png`} alt="" />
    } else {
      return <img src="`https://stream.fawk.app/assets/smallCards/BACK.png`" alt="" />
    }
  }

  const getCardNumber = (card) => {
    return card.substr(1, card.length);
  }

  const getSumOfCards = (cards) => {
    let sum = 0;
    for (const card of cards) {
      sum += parseInt(getCardNumber(card));
    }
    return sum;
  }


  return (
    <div className="race20CardsContent">
      <div className="race20CardsContent__players-wrapper">
        <div className="race20CardsContent__player">
          <div className="race20CardsContent__player-white-icon">
            <img src={whiteSpades} alt="" />
          </div>
          <div className="race20CardsContent__player-cards">
            {exchangeGames && exchangeGames[0]?.marketRunner[0]?.cards.map((item) => cardSrc(item))}
          </div>
        </div>
        <div className="race20CardsContent__player">
          <div className="race20CardsContent__player-white-icon">
            <img src={whiteHearts} alt="" />
          </div>
          <div className="race20CardsContent__player-cards">
            {exchangeGames && exchangeGames[0]?.marketRunner[1]?.cards.map((item) => cardSrc(item))}
          </div>
        </div>
        <div className="race20CardsContent__player">
          <div className="race20CardsContent__player-white-icon">
            <img src={whiteClubs} alt="" />
          </div>
          <div className="race20CardsContent__player-cards">
            {exchangeGames && exchangeGames[0]?.marketRunner[2]?.cards.map((item) =>  cardSrc(item))}
          </div>
        </div>
        <div className="race20CardsContent__player">
          <div className="race20CardsContent__player-white-icon">
            <img src={whiteDiamonds} alt="" />
          </div>
          <div className="race20CardsContent__player-cards">
            {exchangeGames && exchangeGames[0]?.marketRunner[3]?.cards.map((item) => cardSrc(item))}
          </div>
        </div>
      </div>
      <div className="race20CardsContent__total-card-points-wrapper">
        <div className="race20CardsContent__total-card-wrapper">
          <span>Total Cards: </span>
          <span>{exchangeGames && exchangeGames[0]?.indexCard.length}</span>
        </div>
        <div className="race20CardsContent__total-points-wrapper">
          <span>Total Points: </span>
          <span>{exchangeGames && exchangeGames[0]?.indexCard && getSumOfCards(exchangeGames[0]?.indexCard)}</span>
        </div>
      </div>
    </div>
  )
}

export default connectRace20()(Race20Cards)
