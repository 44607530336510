import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { setBtype, setDelay, setEventName, setFromOneClick, setIsBack, setLine, setMarket, setMtype, setPrice, setPriceActual, setRunner, setRunnerName, setStake} from "../../../store/betPlacing/betPlacing.slice";
import {requestBetPlacingStart} from "../../../store/betPlacing/betPlacing.thunks";
import {requestExchangeGames} from "../../../store/exchangeGames/exchangeGames.thunks";

export const connectAndarBahar = (wrappedProps) => (component) => {
    function mapStateToProps (state) {
        return {
            sportEvent: state.sportEvent,
            betPlacing: state.betPlacing,
            presetStakeSettings: state.user.settings.presetStakeSettings,
            oneClick: state.user.settings.oneClickSettings.active,
            exchangeGames: state.exchangeGames,
            exposure: state.eventPage.exposure,
            headerHeight: state.elementsParams.headerHeight,
            auth: state.auth,
            elementsParams: state.elementsParams
        };
    }

    function mapDispatchToProps (dispatch) {
        const actions = {
            setMarket,
            setRunner,
            setRunnerName,
            setEventName,
            setIsBack,
            setPrice,
            setPriceActual,
            setDelay,
            setStake,
            setMtype,
            setBtype,
            setLine,
            setFromOneClick,
            requestBetPlacingStart,
            requestExchangeGames,
        };

        return {actions: bindActionCreators(actions, dispatch)};
    }

    return connect(mapStateToProps, mapDispatchToProps)(component);
};
