import {getOpenBetsError, getOpenBetsSuccess} from "./openBets.slice";
import getErrorMessage from "../../helpers/getErrorMessage.helper";
import http from "../../services/httpAuth";

export const requestOpenBets = (eventId) => async (dispatch, getState) => {
    try {
        const userId = getState().user.memberCode;
        const eventParam = eventId ? `&eventId=${eventId}` : "";
        const response = await http.get(`/account/${userId}/order-list?orderStatus=open${eventParam}`);
        if (response.data.success) {
            dispatch(getOpenBetsSuccess(response.data.result));
        }
        return response;
    } catch (e) {
        dispatch(getOpenBetsError());
        const errorMsg = getErrorMessage(e);
        throw errorMsg;
    }
};

export const requestOpenBetsWithDate = ( from, to, eventId) => async (dispatch, getState) => {
    try {
        const userId = getState().user.memberCode;
        const eventParam = eventId ? `&eventId=${eventId}` : "";
        const dateParam = from && to ? `&from=${from}&to=${to}` : "";
        const response = await http.get(`/account/${userId}/order-list?orderStatus=open${eventParam}${dateParam}`);
        if (response.data.success) {
            dispatch(getOpenBetsSuccess(response.data.result));
        }
        return response;
    } catch (e) {
        dispatch(getOpenBetsError());
        const errorMsg = getErrorMessage(e);
        throw errorMsg;
    }
};

export const requestPastBets = (from, to, eventId) => async (dispatch, getState) => {
    try {
        const userId = getState().user.memberCode;
        const eventParam = eventId ? `&eventId=${eventId}` : "";
        const dateParam = from && to ? `&from=${from}&to=${to}` : "";
        const response = await http.get(`/account/${userId}/order-list?orderStatus=settled${dateParam}${eventParam}`);
        // if (response.data.success) {
        //     dispatch(getOpenBetsSuccess(response.data.result));
        // }
        return response;
    } catch (e) {
        dispatch(getOpenBetsError());
        const errorMsg = getErrorMessage(e);
        throw errorMsg;
    }
};
