import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getExchangeGamesSuccess, getExchangeGamesError} from './exchangeGames.slice'

export const requestExchangeGames = (gameId) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    if(checkAuth) {
        try {
            const response = await httpAuth.get(`/exchange-games/odds/${gameId}`)
           if (response.data.success) {
                dispatch(getExchangeGamesSuccess(response.data.result))
            }
            return response
        } catch (e) {
            dispatch(getExchangeGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    } else {
        try {
            const response = await httpNotAuth.get(`/exchange-games/odds/${gameId}`)
           if (response.data.success) {
                dispatch(getExchangeGamesSuccess(response.data.result))
            }
            return response
        } catch (e) {
            dispatch(getExchangeGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }

}

