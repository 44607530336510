import httpAuth from '../../services/httpAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getBetHistoryError, getBetHistorySuccess } from './bet-history.slice'

export const requestBetHistorySuccess = (sessionId) => async (dispatch) => {
    try {
        const response = await httpAuth.get(`/casino/live/session/bet-history?sessionId=${sessionId}`)
        if (response.data.success) {
            dispatch(getBetHistorySuccess(response.data.result))
        }
        return response
    } catch (e) {
        dispatch(getBetHistoryError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}
