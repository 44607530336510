import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { IonPage, IonContent, IonHeader, IonSpinner } from "@ionic/react";
import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import axios from "axios";
import QRCode from "qrcode";
import * as Sentry from "@sentry/react";
import Slider from "react-slick";
import { FileUploader } from "react-drag-drop-files";
import Toolbar from "../../components/Toolbar/Toolbar";
import getErrorMessage from "../../helpers/getErrorMessage.helper";
import PopUPHelp from "./HelpPopUp";
import DetailsPopUp from "./DetailsPopUp";
import FooterDesktop from "../../components/FooterDesktop/FooterDesktop";
import { connectDeposit } from "./Deposit.connect";
import { BASE_DEV_URL, CONFIG } from "../../config/api.config";
import { setReq } from "../../store/deposit/deposit.slice";
import { getCurrencyFormat } from "../../helpers/getCurrencyFormat.helper";
import upiLogo from "../../assets/images/depositPageIcons/upi_logo.png";

import phonePeLogo from "../../assets/images/depositPageIcons/phonepe.png";
import googlePayLogo from "../../assets/images/depositPageIcons/google-pay.png";
import payTmLogo from "../../assets/images/depositPageIcons/paytm.png";
import bhimLogo from "../../assets/images/depositPageIcons/bhim.png";
import dashedBorder from "../../assets/images/deposit/dashedBorder.svg";
import depositSlide1 from "../../assets/images/deposit/depositSlide1.svg";
import depositSlide2 from "../../assets/images/deposit/depositSlide2.svg";
import depositSlide3 from "../../assets/images/deposit/depositSlide3.svg";
import depositSlide4 from "../../assets/images/deposit/depositSlide4.svg";
import BonusConditionModal from "../../components/BonusModels/bonusmodels";
import "./Deposit.scss";

const Deposit = ({ user, actions, elementsParams, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();


  const [depositMethod, setDepositMethod] = useState("");
  const [depositAmount, setDepositAmount] = useState(0);
  const [accountInfo, setAccountInfo] = useState();
  let token = localStorage.getItem("id_token");
  const [UTRinput, setUTRinput] = useState();
  const [commentInput, setCommentInput] = useState("");
  const [isRulesAccept, setIsRulesAccept] = useState(true);
  const [detailedMethod, setDetailedMethod] = useState();
  const [gateway, setGateway] = useState();
  const [openPopUpHelp, setOpenPopUpHelp] = useState(false);
  const [min_max, setMin_max] = useState({ min: "", max: "" });
  const [message, setMessage] = useState("");
  const [messageUTR, setMessageUTR] = useState("");
  const [messageCard, setMessageCard] = useState("");
  const [messageCardInfo, setMessageCardInfo] = useState("");
  const [cardNumber, setCardNumber] = useState();
  const [dateInput, setDateInput] = useState({ month: "", year: "" });
  const [cvv, setCvv] = useState();
  const [chosenPromo, setChosenPromo] = useState(null);
  const [tempPromo, setTempPromo] = useState(null);
  const [promoList, setPromoList] = useState(null);
  const [userPgDetails, setUserPgDetailsMethod] = useState();
  const [showDedails, setshowDedails] = useState(false);
  const [showBonusConditions, setShowBonusConditions] = useState(false);
  const [bonusConditions, setBonusConditions] = useState("");
  const [detailsPromo, setDetailsPromo] = useState(null);
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [isSwipe, setIsSwipe] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const swipeHandler = () => {
    setIsSwipe(true);
    setTimeout(() => setIsSwipe(false), 500);
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          autoplaySpeed: 6000,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  const paymentGateways = async (method) => {
    token = localStorage.getItem("id_token");
    return await axios
      .get(BASE_DEV_URL + "/payments/userid/payment-gateways", {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((response) => {
        if (response.data.result) {
          setAccountInfo(response.data.result);
          try {
            if (response.data.result?.length > 0) {
              detailetGateway(response.data.result?.sort((a, b) => a.index - b.index)[0]);
            }
          } catch (e) {
            console.log(e);
          }

        } else {
          paymentGateways();
        }
      })
      .catch((e) => {
        setTimeout(() => {
          paymentGateways();
        }, 1500);
      });
  };

  const detailetGateway = async (item) => {
    return await axios
      .get(BASE_DEV_URL + `/payments/userid/gateway?gatewayId=${item.id}`, {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((response) => setDetailedMethod(response.data.result))
      .then(setDepositMethod(item))
      .catch((e) => {
      });
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    toastr.success("Copied", text);
  };

  const preparedDepositAmount = Number(depositAmount).toFixed(2) * 1;

  const checkCardInputs = () => {
    if (cvv && dateInput?.month && dateInput?.year && cardNumber && !messageCardInfo && !messageCard) {
      return true;
    }
    return false;
  };

  const data = {
    amount: `${preparedDepositAmount}`,
    utr: UTRinput,
    comment: commentInput,
    gatewayId: detailedMethod?.id,
    acNo: detailedMethod?.account?.acNo,
    promoCode: chosenPromo?.promoCode
  };
  const dataKhelocard = {
    cardNo: cardNumber,
    cardCvv: cvv,
    cardExpiry: `${dateInput.month.toString().slice(-2)}/${dateInput.year.toString().slice(-2)}`,
    gatewayId: detailedMethod?.id,
    promoCode: "",
    amount: `${preparedDepositAmount}`
  };

  const depositRequest = async () => {
    try {
      if (depositMethod.isManual) {
        if (depositMethod.name === "Khelocard" && checkCardInputs() && isRulesAccept && depositAmount && !message && !messageUTR) {
          setIsRulesAccept(false);
          return await axios
            .post(BASE_DEV_URL + "/payments/userId/khelocard-checkout", dataKhelocard, {
              headers: {
                Authorization: `${token}`
              }
            })
            .then((response) => {
              window.fbq('track', 'Purchase');
              if (response?.data?.success) {
                toastr.success("Success", "Payment successful.");
              }
              setTimeout(() => history.push("/default-page"), 500);
            })
            .catch((error) => {
              toastr.error("Error", error?.response?.data?.result);
              const errorMsg = getErrorMessage(error);
              throw errorMsg;
            });
        }
      } else {
        if (isRulesAccept && depositAmount) {
          return await axios
            .post(BASE_DEV_URL + "/pg/initiate_payment", {
              pg: depositMethod.name,
              firstname: "Abcd abcd",
              email: "abc@abcd.com",
              phone: user.loginName.split(".").length === 2 ? user.loginName.split(".")[1].replace("_", "") : user.loginName.replace("_", ""),
              amount: depositAmount,
              gatewayId: depositMethod.id,
              promoCode: chosenPromo?.promoCode
            }, {
              headers: {
                Authorization: `${token}`
              }
            })
            .then((response) => {

              if (response.data.success) {
                try {
                  if (window.location.search) {
                    let s = "=";
                    const queryString = window.location.search;
                    const parameters = new URLSearchParams(queryString);
                    parameters.forEach((value, key, urlParam) => {
                      if (value === "WATER") {
                        window.fbq('track', 'Purchase');
                      }
                    });
                  }
                } catch (e) {
                  console.log(e);
                }
                dispatch(setReq({ result: response.data.result, depositMethod }));
                if (response.data.result.qrCode && response.data.result.qrCode !== "") {
                  console.log("result with no qr", response.data.result);
                  setTimeout(() => history.push("/depositTimerQr"), 500);
                } else {
                  localStorage.setItem(response.data.result?.transactionId, JSON.stringify({
                    result: response.data.result,
                    depositMethod
                  }));
                  window.location.replace(response.data.result?.redirectURL);
                }
              } else {
                toastr.error("Error", response?.data?.result);
              }


            }).catch((error) => toastr.error("Error", error?.response?.data?.result));
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      toastr.error("Error", getErrorMessage(e));
      console.log(e);
    }

    if (imgFile !== null) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const formData = new FormData();
        formData.append("file", imgFile, imgFile.name);
        formData.append("amount", `${preparedDepositAmount}`);
        formData.append("utr", UTRinput);
        formData.append("comment", commentInput);
        formData.append("gatewayId", detailedMethod?.id);
        formData.append("acNo", detailedMethod?.account?.acNo);
        if (chosenPromo?.promoCode)
          formData.append("promoCode", chosenPromo?.promoCode);
        try {
          if (depositMethod.isManual) {
            if (isRulesAccept && UTRinput && depositAmount && !message && !messageUTR) {
              setIsLoading(true);
              return await axios
                .post(BASE_DEV_URL + "/payments/userid/deposit-request-form", formData, {
                  headers: {
                    Authorization: `${token}`
                  }
                })
                .then((response) => {
                  window.fbq('track', 'Purchase');
                  setIsLoading(false);
                  dispatch(setReq({ result: response.data.result, depositMethod }));
                  setTimeout(() => history.push("/depositTimer"), 500);
                  if (response.data.success)
                    setImgFile(null);
                })
                .catch((error) => {
                  setIsLoading(false);
                  toastr.error("Error", error?.response?.data?.result);
                });
            }
          }
        } catch (e) {
          Sentry.captureException(e);
          toastr.error("Error", getErrorMessage(e));
          console.log(e);
        }
      };
      reader.readAsDataURL(imgFile);
    } else {
      if (depositMethod.isManual && isRulesAccept && UTRinput && depositAmount && !message && !messageUTR) {
        return await axios
          .post(BASE_DEV_URL + "/payments/userid/deposit-request", data, {
            headers: {
              Authorization: `${token}`
            }
          })
          .then((response) => {
            window.fbq('track', 'Purchase');
            dispatch(setReq({ result: response.data.result, depositMethod }));
            // console.log(response)
            setTimeout(() => history.push("/depositTimer"), 500);
          })
          .catch((error) => {
            toastr.error("Error", error?.response?.data?.result);
          });
      }
    }


  };

  useEffect(() => {
    if (accountInfo && depositMethod) {
      const res = accountInfo.filter(item => item.id === depositMethod.id);
      setMin_max({ min: res[0].min, max: res[0].max });
      try {
        setDepositAmount(0);
      } catch (e) {
        console.log(e);
      }
    } else setMin_max({});
  }, [depositMethod]);

  useEffect(() => {
    try {
      setDepositAmount(0);
      onValidationAmount(0);
    } catch (e) {
      console.log(e);
    }
  }, [min_max.min]);

  const onValidationAmount = (e) => {
    try {
      e = e.replace(/^0+/, "");
    } catch (e) {
      console.log(e);
    }
    if (e < 0) {
      setDepositAmount(e);
      setMessage("Amount can't be negative");
    } else if (e < +min_max.min) {
      setDepositAmount(e);
      setMessage(`Amount can't be less than ${min_max.min}`);
    } else if (e > +min_max.max) {
      setDepositAmount(e);
      setMessage(`Amount can't be more than ${min_max.max}`);
    } else {
      setDepositAmount(e);
      setMessage("");
    }
    try {

      if (chosenPromo && chosenPromo.minDeposit) {
        if (e >= chosenPromo.minDeposit) {
          // do nothing.
        } else {
          setTempPromo(chosenPromo);
          setChosenPromo({});
        }
      }

      if (tempPromo && tempPromo.minDeposit) {
        if (e >= tempPromo.minDeposit) {
          setChosenPromo(tempPromo);
          setTempPromo({});
        } else {
          setChosenPromo({});
        }
      }
    } catch (err) {
    }
  };
  const onValidationUTR = (e) => {
    if (e.length <= 5) {
      setUTRinput(e);
      setMessageUTR("Enter valid UTR/RRN number");
    } else if (e.length > 12) {
      setUTRinput(e);
      setMessageUTR("Enter 12 digit UTR/RRN number");
    } else {
      setUTRinput(e);
      setMessageUTR(``);
    }
  };

  useIonViewWillLeave(() => {
    setDepositMethod("");
    setDepositAmount(0);
    setIsRulesAccept(false);
  });

  const dateValidation = (date) => {
    if (new Date(2000 + Number(date.year), date.month) < new Date()) {
      return setMessageCardInfo("The validity of your card has expired!");
    } else if (setMessageCardInfo) {
      setMessageCardInfo("");
    }
  };

  const cardsInputHandler = (e) => {
    if (e.target.name === "card" && e.target.value.replaceAll(" ", "").length <= 16) {
      const cardNum = (str) => str.length ? [str.slice(0, 4), ...cardNum(str.slice(4), 4)] : [];
      setCardNumber(cardNum(e.target.value.replaceAll(" ", "")).join(" "));
      e.target.value.replaceAll(" ", "").length < 16 ? setMessageCard("Invalid card number!") : setMessageCard("");
    }
    if (e.target.name === "month" || e.target.name === "year") {
      setDateInput({ ...dateInput, [e.target.name]: e.target.value.slice(-2) });
      dateValidation({ ...dateInput, [e.target.name]: e.target.value.slice(-2) });
    }
    if (e.target.name === "cvv") {
      setCvv(e.target.value.slice(0, 3));
      const svvReg = /^\d{3}$/g;
      svvReg.exec(e.target.value.slice(0, 3)) ? setMessageCardInfo("") : setMessageCardInfo("CVV code must be exactly 3 digits!");
    }
  };

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth;

  const promotionsType = async (method) => {
    await axios.get(BASE_DEV_URL + "/account/promotions?type=DEPOSIT", {
      headers: {
        Authorization: `${token}`
      }
    })
      .then(response => {
        if (response && response.data && response.data.success) {
          setPromoList(response?.data?.result);
          setChosenPromo(response?.data?.result[0]);
          setBonusConditions(response?.data?.result[0]?.info);
        }
      })
      .catch((e) => {
      });
  };

  useEffect(() => {

    promotionsType();
    if (width >= 1024) {
      token = localStorage.getItem("id_token");
      paymentGateways();
    }
  }, []);

  useIonViewWillEnter(() => {
    token = localStorage.getItem("id_token");
    paymentGateways();
  });

  //api Request For QrCode On Mobile
  const apiRequestForQrCodeOnMobile = (protocol) => {
    if (depositMethod.isUPI){
      return `https://api.gemexch.co/api/open/upi?vpa=${depositMethod.account.acNo}&vpaName=${depositMethod.account.name}&amount=${depositAmount}&protocol=${protocol}&tn=${user.memberCode}`;
    } else {
      return `https://api.gemexch.co/api/open/upi?vpa=${depositMethod.account.acNo}@${depositMethod.account.IFSC}.ifsc.npci&vpaName=${depositMethod.account.name}&amount=${depositAmount}&protocol=${protocol}&tn=${user.memberCode}`;
    }
  };
  //End api Request For QrCode On Mobile

  // QR Code generate With async/await
  const generateQR = async text => {
    try {
      setQrCodeImage(await QRCode.toDataURL(text));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    //TODO: add extra param to UPI using extra variable.
    if (depositMethod && depositMethod.isUPI && depositMethod.account.acNo && depositMethod.account.name) {
      let qrLinkTemp = `upi://pay?pa=${depositMethod.account.acNo}&pn=${depositMethod.account.name}&am=${depositAmount}&tn=${user.memberCode}&cu=INR`;
      if (qrLinkTemp) {
        generateQR(qrLinkTemp);
      }
    } else if (depositMethod && !depositMethod.isUPI && depositMethod.account.acNo && depositMethod.account.name){
      let qrLinkTemp = `upi://pay?pa=${depositMethod.account.acNo}@${depositMethod.account.IFSC}.ifsc.npci&pn=${depositMethod.account.name}&am=${depositAmount}&tn=${user.memberCode}&cu=INR`;
      if (qrLinkTemp) {
        generateQR(qrLinkTemp);
      }
    }
  }, [depositMethod, depositAmount]);
  // END QR Code generate With async/await

  return (
    <IonPage className={openPopUpHelp && "scrollOff ion-page-invisible"}>
      {width < 1024 ? (
        <>
          <IonHeader>
            <Toolbar />
          </IonHeader>
          <IonContent className="ChangePassword" fullscreen>
            <div className="deposit-desktop">
              <div className="deposit-desktop__leftpage">
                {depositMethod && depositMethod.isManual && depositMethod?.name !== "Khelocard" ? (
                  <div className="wallet-depositInfo__desctop">
                    <div className="deposit-titlehelp">
                      <span className="page-title">Payment Methods</span>
                    </div>
                    <div className="walletInfo">
                      <span className="walletInfo__no">Wallet No</span>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      Account: {detailedMethod?.account?.acNo}
                    </span>
                        <svg
                          onClick={() => copyText(detailedMethod?.account?.acNo)}
                          className="walletInfo__item-img ggg"
                          width="20"
                          height="22"
                          viewBox="0 0 16 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      IFSC: {detailedMethod?.account?.IFSC}
                    </span>
                        <svg
                          onClick={() => copyText(detailedMethod?.account?.IFSC)}
                          className="walletInfo__item-img ggg"
                          width="20"
                          height="22"
                          viewBox="0 0 16 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      Account Name:{" "}
                      <span className="walletInfo__item-title">
                        {detailedMethod?.account?.name}
                      </span>
                    </span>
                        <svg
                          onClick={() => copyText(detailedMethod?.account?.name)}
                          className="walletInfo__item-img ggg"
                          width="20"
                          height="22"
                          viewBox="0 0 16 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      Bank Name:{" "}
                      <span className="walletInfo__item-title">
                        {detailedMethod?.account?.bankName}
                      </span>
                    </span>
                        <svg
                          onClick={() =>
                            copyText(detailedMethod?.account?.bankName)
                          }
                          className="walletInfo__item-img ggg"
                          width="20"
                          height="22"
                          viewBox="0 0 16 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                    </div>
                    <div className="depositInfo">
                      <div className="walletInfo__no">
                        Please use the above account details to deposit your amount.
                        Once transaction is successful, enter the transaction
                        details below.{" "}
                      </div>
                      <div className="walletInfo__no noLast">
                        {" "}
                        Please allow us 1 minute to verify and approve the
                        transanction, Post which amount will be credited to your
                        wallet.
                      </div>
                    </div>
                    {" "}
                  </div>
                ) : null}
                {!depositMethod ? (
                  <div className="noPayment__desctop">
                    <div className="payments__noPayments-subtitle">
                      Please select a payment method to proceed
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="deposit-desktop__rightpage">
                <div className="deposit top-cards_wrapper">
                  <div className="deposit-titlehelp">
                    <span className="page-title">Payment Options</span>

                  </div>

                  <div
                    className={`payments__container ${depositMethod && depositMethod.isManual && depositMethod?.name === "Khelocard" ? "payments__container-khelocard" : ""}`}>
                    <div className="payments__row">
                      {accountInfo
                        ? accountInfo.sort((a, b) => a.index - b.index).map((item) => {
                          return (
                            <>
                              {item.id === "WHATSAPP" &&
                                <>
                                  <div
                                    key={item.id}
                                    className={
                                      (depositMethod?.id == item.id ? "payments__row-whatsapp__item active" : "payments__row-whatsapp__item")
                                    }
                                    onClick={() => {
                                      detailetGateway(item);
                                      setIsRulesAccept(true);
                                      window.open(`${CONFIG.whatsappLink}`)
                                    }}
                                  />
                                </>
                              }
                              {
                                item.id !== "WHATSAPP" && <div
                                  key={item.id}
                                  className={
                                    (item.id == "WHATSAPP" ? "payments__row-whatsapp__item" : "payments__row-item") + (depositMethod?.id == item.id ? " active" : "")
                                  }
                                  onClick={() => {
                                    detailetGateway(item);
                                    setIsRulesAccept(true);
                                  }}
                                >
                                  <div className="payments__row-item-radio">
                                    {depositMethod?.id === item?.id ? (
                                      <div className="selectedRadio"></div>
                                    ) : (
                                      <div className="unSelectedRadio"></div>
                                    )}
                                  </div>
                                  <div className="payments__row-item-logo">
                                    <img
                                      className="payments__row-item-logo-img"
                                      src={item.logo}
                                      alt={item.name}
                                    />
                                    <span className="payments__row-item-logo-title">
                                {item.name}
                              </span>
                                  </div>
                                  {
                                    item && item?.promo &&
                                    <span className="payments__row-item-depositMethod-promo">{item.promo}</span>
                                  }
                                </div>
                              }
                            </>
                          );
                        })
                        : null}
                    </div>
                  </div>
                  {depositMethod && depositMethod.isManual &&
                    <div className="page-subtitle notes">
                      <p>1. Deposit money only in the below available accounts to get the fastest credits and avoid
                        possible delays.</p>
                      <p>2. Deposits made 45 minutes after the account removal from the site are valid & will be added
                        to their wallets.</p>
                      <p>3. Site is not responsible for money deposited to Old, Inactive or Closed accounts.</p>
                      <p>4. After deposit, add your UTR and amount to receive balance. </p>
                      <p>5. NEFT receiving time varies from 40 minutes to 2 hours. </p>
                    </div>
                  }
                  {depositMethod && !depositMethod.isManual && depositMethod.id !== "WHATSAPP" &&
                    <div className="page-subtitle notes"><p> **Always start instant payments by submitting amount
                      below.</p></div>
                  }
                </div>


                {/*Message for khelocard installs*/}
                {depositMethod && depositMethod.isManual && depositMethod?.name === "Khelocard" ? (
                  <div className="depositInfo">
                    <div className="walletInfo__no">
                      Don't have an account with khelocard, <a target="_blank" href="https://khelocard.com/login">Click
                      here</a>
                    </div>
                  </div>
                ) : null}

                {/*Message for manual deposit info and instruction*/}
                {depositMethod && depositMethod.isManual && depositMethod?.name !== "Khelocard" ? (
                  <div className="wallet-depositInfo">
                    <div
                      className={`walletInfo ${(depositMethod && (depositMethod.isUPI || depositMethod.name.includes("QR")) && qrCodeImage) ? "walletInfo__with-qrCode" : ""}`}>
                      <div className={`walletInfo__items-wrapper`}>
                        {/*<span className="walletInfo__no">Wallet No</span>*/}
                        <div className="deposit__title">Payment Details</div>
                        {
                          depositMethod && (depositMethod.isUPI || depositMethod.name.includes("QR")) && qrCodeImage &&
                          <div className={`wallet-depositInfo__qrCodeImage-wrapper`}>
                            <img className={`wallet-depositInfo__qrCodeImage`} src={qrCodeImage} alt="qrCodeImage" />
                          </div>
                        }
                        <div className="walletInfo__item">
                      <span className="walletInfo__item-title">
                        {(depositMethod && depositMethod.isUPI) ? "UPI ID" : "Account:"} {depositMethod.isUPI && <br />}
                        <span  onClick={() => copyText(detailedMethod?.account?.acNo)} className="walletInfo__item-title-inner"> {detailedMethod?.account?.acNo}</span>
                      </span>
                          <svg
                            onClick={() => copyText(detailedMethod?.account?.acNo)}
                            className="walletInfo__item-img ggg"
                            width="16"
                            height="18"
                            viewBox="0 0 14 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                          </svg>
                        </div>
                        {
                          depositMethod && !depositMethod.isUPI &&
                          <div className="walletInfo__item">
                      <span className="walletInfo__item-title">
                        IFSC:
                        <span onClick={() => copyText(detailedMethod?.account?.IFSC)} className="walletInfo__item-title-inner"> {detailedMethod?.account?.IFSC}</span>
                      </span>
                            <svg
                              onClick={() => copyText(detailedMethod?.account?.IFSC)}
                              className="walletInfo__item-img ggg"
                              width="16"
                              height="18"
                              viewBox="0 0 14 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                            </svg>
                          </div>
                        }
                        <div className="walletInfo__item">
                        <span className="walletInfo__item-title">
                        Account Name:{" "} {depositMethod.isUPI && <br />}
                          <span onClick={() => copyText(detailedMethod?.account?.name)} className="walletInfo__item-title-inner">
                          {detailedMethod?.account?.name}
                        </span>
                      </span>
                          <svg
                            onClick={() => copyText(detailedMethod?.account?.name)}
                            className="walletInfo__item-img ggg"
                            width="16"
                            height="18"
                            viewBox="0 0 14 16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                          </svg>
                        </div>
                        {
                          depositMethod && !depositMethod.isUPI &&
                          <div className="walletInfo__item">
                        <span className="walletInfo__item-title">
                          Bank Name:{" "}
                          <span onClick={() =>
                            copyText(detailedMethod?.account?.bankName)
                          } className="walletInfo__item-title-inner">
                          {detailedMethod?.account?.bankName}
                        </span>
                      </span>
                            <svg
                              onClick={() =>
                                copyText(detailedMethod?.account?.bankName)
                              }
                              className="walletInfo__item-img ggg"
                              width="16"
                              height="18"
                              viewBox="0 0 14 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                            </svg>
                          </div>
                        }

                      </div>

                    </div>
                  </div>
                ) : null}

                {/*Message for No Payment method selected*/}
                {!depositMethod ? (
                  <div className={"deposit"}>
                    <div className="noPayment" style={{ padding: "16px" }}>
                      <div className="payments__noPayments-subtitle">
                        Please select a payment method to proceed
                      </div>
                    </div>
                  </div>
                ) : null}

                {/*WHATSAPP button*/}
                {depositMethod && depositMethod.id === "WHATSAPP" ? (
                  <>
                    <div
                      className={`deposit`}>
                      <div className="signUpNew_whattsUp">
                        <div className="signUpNew_whattsUp_title" style={{ color: "black" }}>1) One To One Chat.
                        </div>
                        <div className="signUpNew_whattsUp_title" style={{ color: "black" }}>2) All Payment Method Option Available Upi, Phonepay, Gpay, Hawala, Paytm.
                        </div>
                        <div className="signUpNew_whattsUp_title" style={{ color: "black" }}>3) Ultra Fast Withdrawal Process within 20 minutes Guaranteed.
                        </div>
                        <div className="signUpNew_whattsUp_title" style={{ color: "black" }}>4) 24 Hour Deposit Available & Special Bonus Time To Time On Whatsapp.
                        </div>
                        {/*<div className="signUpNew_whattsUp_title" style={{ color: "black" }}>5) No Tension Of Pending*/}
                        {/*  Transaction & Inactive Account.*/}
                        {/*</div>*/}
                        {/*<div className="signUpNew_whattsUp_title" style={{ color: "black" }}>6) All Clients are*/}
                        {/*  Requested To Shift On Whatsapp For Smooth Service.*/}
                        {/*</div>*/}
                        <div className="signUpNew_whattsUp_button" onClick={() => window.open(`${CONFIG.whatsappLink}`)}>
                          <div className="signUpNew_whattsUp_button-icon"></div>
                          <div className="signUpNew_whattsUp_button-number">WHATSAPP NOW</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}


                {/*message for manual View*/}
                {depositMethod && depositMethod.id !== "WHATSAPP" ? (
                  <>
                    <div
                      className={`deposit ${depositMethod && depositMethod.isManual && depositMethod?.name === "Khelocard" ? "deposit__amount-khelocard" : ""}`}>
                      <div className="deposit__title">
                        Amount*
                      </div>
                      <div className="deposit__amountInput">
                        <input
                          className="deposit__amountInput-input "
                          type="number"
                          defaultValue={depositAmount}
                          value={depositAmount}
                          onChange={(e) =>
                            onValidationAmount(
                              e.target.value > gateway ? gateway : e.target.value
                            )
                          }
                        />
                        <span className="deposit__amountInput-currency">INR</span>
                      </div>
                      <div
                        className="deposit__min-max">{getCurrencyFormat(min_max.min)}-{getCurrencyFormat(min_max.max)}</div>
                      <div className="deposit__amountButtons">
                        {width >= 1024 ? (
                          <>
                            <div className="deposit__amountButtons-row">
                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 100)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+100</span>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 500)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+500</span>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 1000)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+1,000</span>
                              </button>
                            </div>

                            <div className="deposit__amountButtons-row">
                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 5000)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+5,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 10000)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+10,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 50000)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+50,000</span>
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="deposit__amountButtons-item"
                              onClick={() =>
                                onValidationAmount(+depositAmount + 100)
                              }
                            >
                              +100
                            </div>
                            <div
                              className="deposit__amountButtons-item"
                              onClick={() =>
                                onValidationAmount(+depositAmount + 500)
                              }
                            >
                              +500
                            </div>
                            <div
                              className="deposit__amountButtons-item"
                              onClick={() =>
                                onValidationAmount(+depositAmount + 1000)
                              }
                            >
                              +1,000
                            </div>
                            <div
                              className="deposit__amountButtons-item"
                              onClick={() =>
                                onValidationAmount(+depositAmount + 5000)
                              }
                            >
                              +5,000
                            </div>
                            <div
                              className="deposit__amountButtons-item"
                              onClick={() =>
                                onValidationAmount(+depositAmount + 10000)
                              }
                            >
                              +10,000
                            </div>
                            <div
                              className="deposit__amountButtons-item"
                              onClick={() =>
                                onValidationAmount(+depositAmount + 50000)
                              }
                            >
                              +50,000
                            </div>
                          </>
                        )}
                      </div>
                      <div className="validation-message khelocard">{message}</div>
                    </div>
                  </>
                ) : null}

                {
                  depositMethod && (depositMethod.isUPI || depositMethod.name.includes("QR")) && qrCodeImage &&
                  <div>
                    <div className={`deposit__title deposit__icons-title`}>Pay Using
                      <div className={`wallet-depositInfo__icons-wrapper`}>
                        <div className={`wallet-depositInfo__icon-box`}>
                          <a href={apiRequestForQrCodeOnMobile("phonepe")}>
                            <div className={`wallet-depositInfo__icon-inner`}>
                              <img src={phonePeLogo} alt="phonePeLogo" />
                            </div>
                            <span>Phone Pe</span>
                          </a>
                        </div>
                        <div className={`wallet-depositInfo__icon-box`}>
                          <a href={apiRequestForQrCodeOnMobile("tez")}>
                            <div className={`wallet-depositInfo__icon-inner`}>
                              <img src={googlePayLogo} alt="googlePayLogo" />
                            </div>
                            <span>Google Pay</span>
                          </a>
                        </div>
                        <div className={`wallet-depositInfo__icon-box`}
                             onClick={() => apiRequestForQrCodeOnMobile("paytm")}>
                          <a href={apiRequestForQrCodeOnMobile("paytm")}>
                            <div className={`wallet-depositInfo__icon-inner`}>
                              <img src={payTmLogo} alt="payTmLogo" />
                            </div>
                            <span>Pay TM</span>
                          </a>
                        </div>
                        <div className={`wallet-depositInfo__icon-box`}>
                          <a href={apiRequestForQrCodeOnMobile("upi")}>
                            <div className={`wallet-depositInfo__icon-inner`}>
                              <img src={bhimLogo} alt="bhimLogo" />
                            </div>
                            <span>BHIM</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                }

                {/*UTR inputBox*/}
                {depositMethod && depositMethod.isManual && depositMethod?.name !== "Khelocard" ? (
                  <>
                    <div className="deposit">
                      <div className="deposit__title">
                        Unique Transaction Reference*
                      </div>
                      <div className="deposit__amountInput inputMargin">
                        <input
                          className="deposit__amountInput-input"
                          type="number"
                          placeholder="6 to 12 Digit UTR Number"
                          onChange={(e) => onValidationUTR(e.target.value)}
                        />
                        {messageUTR && <div className="validation-message khelocard">{messageUTR}</div>}
                      </div>
                    </div>
                  </>
                ) : null}

                {depositMethod && depositMethod.isManual && depositMethod?.name !== "Khelocard" ? (
                  <>
                    <div className="deposit">
                      <div className="deposit__title">
                        Upload your payment slip below
                      </div>
                      <FileUploader
                        handleChange={f => setImgFile(f)}
                        // types={["CSV"]}
                        name="file"
                        classes="imageUploader"
                      />

                    </div>
                  </>
                ) : null}

                {/*Khelocard implementation*/}
                {depositMethod && depositMethod.isManual && depositMethod?.name === "Khelocard" ? (
                  <>
                    <div className="deposit  deposit-khelocard">
                      <div className="deposit__amountInput">
                        <div className="deposit__expiresInput-title">Card number</div>
                        <input
                          className="deposit__amountInput-input"
                          type="text"
                          name="card"
                          value={cardNumber}
                          placeholder="Enter your card number"
                          onChange={(e) => cardsInputHandler(e)}
                        />
                      </div>
                      <div className="validation-message khelocard">{messageCard}</div>
                    </div>
                    <div className="deposit deposit-khelocard">
                      <div className="deposit-cards-input">
                        <div className="deposit__amountInput deposit__expiresInput">
                          <div className="deposit__expiresInput-title">Expires</div>
                          <div>
                            <input
                              className="deposit__amountInput-input "
                              type="number"
                              name="month"
                              value={dateInput?.month}
                              placeholder="MM"
                              onChange={(e) => cardsInputHandler(e)}
                            />
                            <span className="deposit__expiresInput-item">/</span>
                            <input
                              className="deposit__amountInput-input "
                              type="number"
                              name="year"
                              value={dateInput?.year}
                              placeholder="YY"
                              onChange={(e) => cardsInputHandler(e)}
                            />
                          </div>
                        </div>
                        <div className="deposit__amountInput deposit__cvvInput">
                          <div className="deposit__cvvInput-title">CVV</div>
                          <input
                            className="deposit__amountInput-input "
                            type="password"
                            name="cvv"
                            value={cvv}
                            placeholder="xxx"
                            onChange={(e) => cardsInputHandler(e)}
                          />
                        </div>
                      </div>
                      <div className="validation-message khelocard">{messageCardInfo}</div>
                    </div>
                  </>
                ) : null}

                {promoList && promoList.length > 0 && depositMethod.id !== "WHATSAPP" &&
                  (<>
                    <div className="deposit">
                      <div className="deposit__title">Available Offers*</div>

                      {
                        promoList.map((item, index) =>
                          <div key={index + item?.promoCode}
                               className={`deposit-promocode  ${chosenPromo?.promoCode === item?.promoCode ? "active" : ""}`}>
                            <div
                              onClick={() => item?.promoCode === chosenPromo?.promoCode ? setChosenPromo({}) : setChosenPromo(item)}
                              className="deposit-promocode-select">
                              <div className="deposit-promocode-select-button">
                                <div
                                  className={chosenPromo?.promoCode === item?.promoCode ? "deposit-promocode-select-button-circle-active" : "deposit-promocode-select-button-circle"}></div>
                              </div>
                              <div className="deposit-promocode-select-gray">{item["short-info"]}</div>
                              <div className="deposit-promocode-tagline">{item?.tagLine}</div>
                              <div>
                           <span onClick={(e) => {
                             e.stopPropagation();
                             setDetailsPromo(item?.info);
                             setshowDedails(true);
                           }} className="deposit-promocode-select-details">
                             (Terms and conditions)
                           </span>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </>)
                }
                {depositMethod && depositMethod.id !== "WHATSAPP" ? (
                  <div
                    className={`rules-button ${(depositMethod && depositMethod.isUPI) ? "rules-button-UPI" : ""}`}
                    style={{ padding: "0px 16px" }}>
                    <div className="rulesAccept">
                      <div
                        className={`rulesAccept__checkboxD ${
                          isRulesAccept ? "rulesAccept__checkbox_checked" : ""
                        }`}
                        id="confirm"
                        onClick={() => setIsRulesAccept(!isRulesAccept)}
                      />
                      <label htmlFor="confirm"></label>
                      <span className="rulesAccept__text">
                    I have read and agree with&nbsp;
                        <span
                          className="rulesAccept__text-underline"
                          onClick={() => history.push("/termsOfWithdrowalPayment")}
                        >
                      the terms of payment and withdrawal policy.
                    </span>
                  </span>
                    </div>

                    {width >= 1024 ? (
                      <div className="submitBtnD">
                        <button
                          type="button"
                          onClick={() => depositRequest()}
                          className={`
                          ${
                            (depositMethod.isManual ? (isRulesAccept && (depositMethod?.name !== "Khelocard" ? (UTRinput && !messageUTR) : checkCardInputs()) && depositAmount && !message) : (isRulesAccept && depositAmount && !message))
                              ? "submitValidBtn"
                              : "depositNotValidBtn"
                          }`}
                        >
                          {(depositMethod.isManual ? (isRulesAccept && (depositMethod?.name !== "Khelocard" ? (UTRinput && !messageUTR) : checkCardInputs()) && depositAmount && !message) : (isRulesAccept && depositAmount && !message)) ? (
                            <>
                              <span className="shadow"></span>
                              <span className="edge"></span>
                              <span
                                className="front">Proceed {(promoList && promoList?.length) > 0 ? (chosenPromo && chosenPromo?.promoCode) ? "" : "without bonus!" : ""}</span>
                            </>
                          ) : (
                            <div className="dep-submitNew__text">Proceed
                              {(promoList && promoList.length > 0) ? (chosenPromo && chosenPromo?.promoCode) ? "" : "without bonus!" : ""}</div>
                          )}
                        </button>
                      </div>
                    ) : (
                      <>
                        <button
                          className={
                            (depositMethod.isManual ? (isRulesAccept && (depositMethod?.name !== "Khelocard" ? (UTRinput && !messageUTR && !isLoading) : checkCardInputs()) && depositAmount && !message) : (isRulesAccept && depositAmount && !message))
                              ? "depositSubmit"
                              : "depositNotValidBtn"
                          }
                          onClick={() => depositRequest()}
                          type="button"
                        >
                          Submit {(promoList && promoList?.length) > 0 ? (chosenPromo && chosenPromo.promoCode) ? "" : "without bonus!" : ""}
                          {isLoading &&
                            <IonSpinner name="lines" style={{ position: "absolute", top: "4px", right: "32%" }} />}
                        </button>
                      </>

                    )}
                    {
                      depositMethod && depositMethod.isUPI && width < 1024 &&
                      <div className={`wallet-depositInfo__bottom-upi-logo`}>
                        <span>Powered by</span>
                        <img src={upiLogo} alt="upiLogo " />
                      </div>
                    }
                  </div>
                ) : null}
                {depositMethod && depositMethod.isManual && depositMethod?.name !== "Khelocard" &&
                  <div className="depositNotes">

                    <div className="depositNotes-title">How to deposit through IMPS (English)</div>
                    <video className="elementor-video"
                           src="https://lotusbookgaming.com/lotus365/AB6.mp4"
                           width="100%" controls playsInline controlsList="nodownload" />
                    <div className="depositNotes-title">How to deposit through IMPS (Hindi)</div>
                    <video className="elementor-video"
                           src="https://lotusbookgaming.com/lotus365/AB6.mp4"
                           width="100%" controls playsInline controlsList="nodownload" />
                    <div className="depositNotes-title">Notes</div>
                    <div className="depositNotes-content">
                      <img className="depositNotes-content-img" src={dashedBorder} alt="" />
                      <div className="depositNotes-content-text">Send your deposit amount on given Bank account</div>
                      <div className="depositNotes-content-text">Copy and Enter the 12 digit UTR Number</div>
                      <div style={{ marginTop: "19px" }}>
                        <Slider
                          className="deposit-content-infoSlides"
                          onSwipe={swipeHandler} {...settings}>
                          <img src={depositSlide1} alt="" />
                          <img src={depositSlide2} alt="" />
                          <img src={depositSlide3} alt="" />
                          <img src={depositSlide4} alt="" />
                        </Slider>
                      </div>
                      <div className="depositNotes-content-text__submit">Submit the form & Receive credits instantly.
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          </IonContent>
        </>
      ) : (
        <>
          <div style={{ height: "135px" }}></div>
          <div className="ChangePassword" fullscreen>
            <div className="deposit-desktop">
              <div className="deposit-desktop__leftpage">
                <div className="deposit-titlehelp">
                  <span className="page-title">Deposit</span>
                </div>
                {depositMethod && depositMethod.isManual && depositMethod?.name !== "Khelocard" ? (
                  <div className="wallet-depositInfo__desctop">
                    <div className="walletInfo">
                      <span className="walletInfo__no">Wallet No</span>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      Account: {detailedMethod?.account?.acNo}
                    </span>
                        <svg
                          onClick={() => copyText(detailedMethod?.account?.acNo)}
                          className="walletInfo__item-img ggg"
                          width="20"
                          height="22"
                          viewBox="0 0 16 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      IFSC: {detailedMethod?.account?.IFSC}
                    </span>
                        <svg
                          onClick={() => copyText(detailedMethod?.account?.IFSC)}
                          className="walletInfo__item-img ggg"
                          width="20"
                          height="22"
                          viewBox="0 0 16 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      Account Name:{" "}
                      <span className="walletInfo__item-title">
                        {detailedMethod?.account?.name}
                      </span>
                    </span>
                        <svg
                          onClick={() => copyText(detailedMethod?.account?.name)}
                          className="walletInfo__item-img ggg"
                          width="20"
                          height="22"
                          viewBox="0 0 16 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      Bank Name:{" "}
                      <span className="walletInfo__item-title">
                        {detailedMethod?.account?.bankName}
                      </span>
                    </span>
                        <svg
                          onClick={() =>
                            copyText(detailedMethod?.account?.bankName)
                          }
                          className="walletInfo__item-img ggg"
                          width="20"
                          height="22"
                          viewBox="0 0 16 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                    </div>
                    {
                      depositMethod && (depositMethod.isUPI || depositMethod.name.includes("QR")) && qrCodeImage &&
                      <div className={`wallet-depositInfo__qrCodeImage-icons-wrapper`}>
                        <div className={`wallet-depositInfo__qrCodeImage-wrapper`}>
                          <img className={`wallet-depositInfo__qrCodeImage`} src={qrCodeImage} alt="qrCodeImage" />
                        </div>
                        <div className={`wallet-depositInfo__icons-wrapper`}>
                          <div className={`wallet-depositInfo__icon-box`}>
                            <div className={`wallet-depositInfo__icon-inner`}>
                              <img src={phonePeLogo} alt="phonePeLogo" />
                            </div>
                            <span>Phone Pe</span>
                          </div>
                          <div className={`wallet-depositInfo__icon-box`}>
                            <div className={`wallet-depositInfo__icon-inner`}>
                              <img src={googlePayLogo} alt="googlePayLogo" />
                            </div>
                            <span>Google Pay</span>
                          </div>
                          <div className={`wallet-depositInfo__icon-box`}>
                            <div className={`wallet-depositInfo__icon-inner`}>
                              <img src={payTmLogo} alt="payTmLogo" />
                            </div>
                            <span>Pay TM</span>
                          </div>
                          <div className={`wallet-depositInfo__icon-box`}>
                            <div className={`wallet-depositInfo__icon-inner`}>
                              <img src={bhimLogo} alt="bhimLogo" />
                            </div>
                            <span>BHIM</span>
                          </div>
                        </div>
                      </div>
                    }
                    {
                      depositMethod && depositMethod.isUPI &&
                      <div className={`wallet-depositInfo__bottom-upi-logo`}>
                        <span>Powered by</span>
                        <img src={upiLogo} alt="upiLogo " />
                      </div>
                    }

                  </div>
                ) : null}
                {depositMethod && depositMethod.isManual && <div className="depositNotes">
                  <div className="depositNotes-title">Notes</div>
                  <div className="depositNotes-content">
                    <img className="depositNotes-content-img" src={dashedBorder} alt="" />
                    <div className="depositNotes-content-text">Make Payment with Payment methods</div>
                    <div className="depositNotes-content-text">Copy and Enter the 12 digit UTR Number</div>
                    <div style={{ marginTop: "19px" }}>
                      <Slider
                        className="deposit-content-infoSlides"
                        onSwipe={swipeHandler} {...settings}>
                        <img src={depositSlide1} alt="" />
                        <img src={depositSlide2} alt="" />
                        <img src={depositSlide3} alt="" />
                        <img src={depositSlide4} alt="" />
                      </Slider>
                    </div>
                    <div className="depositNotes-content-text__submit">Submit the form</div>
                  </div>
                </div>}
                {!depositMethod ? (
                  <div className="noPayment__desctop">
                    <div className="payments__noPayments-subtitle">
                      Please select a payment method to proceed
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="deposit-desktop__rightpage">
                <div
                  className={`payments__container ${depositMethod && depositMethod.isManual && depositMethod?.name === "Khelocard" ? "payments__container-khelocard" : ""}`}>
                  <div className="payments__row">
                    {accountInfo
                      ? accountInfo.sort((a, b) => a.index - b.index).map((item) => {
                        return (
                          <>
                            {item.id === "WHATSAPP" &&
                              <>
                                <div
                                  key={item.id}
                                  className={
                                    (depositMethod?.id == item.id ? "payments__row-whatsapp__item active" : "payments__row-whatsapp__item")
                                  }
                                  onClick={() => {
                                    detailetGateway(item);
                                    setIsRulesAccept(true);
                                    window.open(`${CONFIG.whatsappLink}`)
                                  }}
                                />
                              </>
                            }
                            {item.id !== "WHATSAPP" &&
                              <div
                                key={item.id}
                                className={
                                  depositMethod?.id == item.id
                                    ? "payments__row-item active"
                                    : " payments__row-item"
                                }
                                onClick={() => detailetGateway(item)}
                              >
                                <div className="payments__row-item-radio">
                                  {depositMethod?.id === item?.id ? (
                                    <div className="selectedRadio"></div>
                                  ) : (
                                    <div className="unSelectedRadio"></div>
                                  )}
                                </div>
                                <div className="payments__row-item-logo">
                                  <img
                                    className="payments__row-item-logo-img"
                                    src={item.logo}
                                    alt={item.name}
                                  />
                                  <span className="payments__row-item-logo-title">
                              {item.name}
                            </span>
                                </div>
                                {
                                  item && item?.promo &&
                                  <span className="payments__row-item-depositMethod-promo">{item.promo}</span>
                                }
                              </div>
                            }
                          </>
                        );
                      })
                      : null}
                  </div>
                </div>

                {/*Message for khelocard installs*/}
                {depositMethod && depositMethod.isManual && depositMethod?.name === "Khelocard" ? (
                  <div className="depositInfo">
                    <div className="walletInfo__no">
                      Don't have an account with khelocard, <a target="_blank" href="https://khelocard.com/login">Click
                      here</a>
                    </div>
                  </div>
                ) : null}

                {/*Message for manual deposit info and instruction*/}
                {depositMethod && depositMethod.isManual && depositMethod?.name !== "Khelocard" ? (
                  <div className="wallet-depositInfo">
                    <div className="walletInfo">
                      <span className="walletInfo__no">Wallet No</span>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      Account: {detailedMethod?.account?.acNo}
                    </span>
                        <svg
                          onClick={() => copyText(detailedMethod?.account?.acNo)}
                          className="walletInfo__item-img ggg"
                          width="16"
                          height="18"
                          viewBox="0 0 14 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                      <div className="walletInfo__item">
                    <span className="walletInfo__item-title">
                      IFSC: {detailedMethod?.account?.IFSC}
                    </span>
                        <svg
                          onClick={() => copyText(detailedMethod?.account?.IFSC)}
                          className="walletInfo__item-img ggg"
                          width="16"
                          height="18"
                          viewBox="0 0 14 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                      <div className="walletInfo__item">
                    <span className="walletInfo__no">
                      Account Name:{" "}
                      <span className="walletInfo__item-title">
                        {detailedMethod?.account?.name}
                      </span>
                    </span>
                        <svg
                          onClick={() => copyText(detailedMethod?.account?.name)}
                          className="walletInfo__item-img ggg"
                          width="16"
                          height="18"
                          viewBox="0 0 14 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                      <div className="walletInfo__item">
                    <span className="walletInfo__no">
                      Bank Name:{" "}
                      <span className="walletInfo__item-title">
                        {detailedMethod?.account?.bankName}
                      </span>
                    </span>
                        <svg
                          onClick={() =>
                            copyText(detailedMethod?.account?.bankName)
                          }
                          className="walletInfo__item-img ggg"
                          width="16"
                          height="18"
                          viewBox="0 0 14 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5607 2.06066L11.9393 0.439344C11.658 0.158039 11.2765 2.08024e-06 10.8787 0L5.5 0C4.67156 0 4 0.671562 4 1.5V3H1.5C0.671562 3 0 3.67156 0 4.5V14.5C0 15.3284 0.671562 16 1.5 16H8.5C9.32844 16 10 15.3284 10 14.5V13H12.5C13.3284 13 14 12.3284 14 11.5V3.12131C14 2.72349 13.842 2.34196 13.5607 2.06066ZM8.3125 14.5H1.6875C1.63777 14.5 1.59008 14.4802 1.55492 14.4451C1.51975 14.4099 1.5 14.3622 1.5 14.3125V4.6875C1.5 4.63777 1.51975 4.59008 1.55492 4.55492C1.59008 4.51975 1.63777 4.5 1.6875 4.5H4V11.5C4 12.3284 4.67156 13 5.5 13H8.5V14.3125C8.5 14.3622 8.48025 14.4099 8.44508 14.4451C8.40992 14.4802 8.36223 14.5 8.3125 14.5ZM12.3125 11.5H5.6875C5.63777 11.5 5.59008 11.4802 5.55492 11.4451C5.51975 11.4099 5.5 11.3622 5.5 11.3125V1.6875C5.5 1.63777 5.51975 1.59008 5.55492 1.55492C5.59008 1.51975 5.63777 1.5 5.6875 1.5H9V4.25C9 4.66422 9.33578 5 9.75 5H12.5V11.3125C12.5 11.3622 12.4802 11.4099 12.4451 11.4451C12.4099 11.4802 12.3622 11.5 12.3125 11.5ZM12.5 3.5H10.5V1.5H10.801C10.8507 1.5 10.8984 1.51975 10.9336 1.55491L12.4451 3.06641C12.4625 3.08382 12.4763 3.10449 12.4857 3.12724C12.4952 3.14999 12.5 3.17438 12.5 3.199V3.5Z" />
                        </svg>
                      </div>
                    </div>
                    <div className="depositInfo">
                      <div className="walletInfo__no">
                        Please use the above account details to deposit your amount.
                        Once transaction is successful, enter the <strong>amount</strong> and <strong>12 digit
                        UTR </strong>
                        details below.{" "}
                      </div>
                      <div className="walletInfo__no noLast">
                        {" "}
                        Please allow us 1 minute to verify and approve the
                        transaction, Post which amount will be credited to your
                        wallet.
                      </div>
                    </div>
                    {" "}
                  </div>
                ) : null}

                {/*Message for No Payment method selected*/}
                {!depositMethod ? (
                  <div className="noPayment">
                    <div className="payments__noPayments-subtitle">
                      Please select a payment method to proceed
                    </div>
                  </div>
                ) : null}

                {/*WHATSAPP button*/}
                {depositMethod && depositMethod.id === "WHATSAPP" ? (
                  <>
                    <div
                      className={`deposit`}>
                      <div className="signUpNew_whattsUp">
                        <div className="signUpNew_whattsUp_title" style={{ color: "black" }}>1) One To One Chat
                        </div>
                        <div className="signUpNew_whattsUp_title" style={{ color: "black" }}>2) All Payment Method Option Available Upi, Phonepay, Gpay, Hawala, Paytm
                        </div>
                        <div className="signUpNew_whattsUp_title" style={{ color: "black" }}>3) Ultra Fast Withdrawal Process within 20 minutes Guaranteed
                        </div>
                        <div className="signUpNew_whattsUp_title" style={{ color: "black" }}>4) 24 Hour Deposit Available & Special Bonus Time To Time On Whatsapp
                        </div>
                        {/*<div className="signUpNew_whattsUp_title" style={{ color: "black" }}>5) No Tension Of Pending*/}
                        {/*  Transaction & Inactive Account.*/}
                        {/*</div>*/}
                        {/*<div className="signUpNew_whattsUp_title" style={{ color: "black" }}>6) All Clients are*/}
                        {/*  Requested To Shift On Whatsapp For Smooth Service.*/}
                        {/*</div>*/}
                        <div className="signUpNew_whattsUp_button" onClick={() => window.open(`${CONFIG.whatsappLink}`)}>
                          <div className="signUpNew_whattsUp_button-icon"></div>
                          <div className="signUpNew_whattsUp_button-number">WHATSAPP NOW</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {/*message for manual View*/}
                {depositMethod && depositMethod.id !== "WHATSAPP" ? (
                  <>
                    <div
                      className={`deposit ${depositMethod && depositMethod.isManual && depositMethod?.name === "Khelocard" ? "deposit__amount-khelocard" : ""}`}>
                      <div className="deposit__title">
                        Amount*
                      </div>
                      <div className="deposit__amountInput">
                        <input
                          className="deposit__amountInput-input "
                          type="number"
                          defaultValue={depositAmount}
                          value={depositAmount}
                          placeholder="0"
                          onChange={(e) =>
                            onValidationAmount(
                              e.target.value > gateway ? gateway : e.target.value
                            )
                          }
                        />
                        <span className="deposit__amountInput-currency">INR</span>
                      </div>
                      <div
                        className="deposit__min-max">{getCurrencyFormat(min_max.min)}-{getCurrencyFormat(min_max.max)}</div>
                      <div className="deposit__amountButtons">
                        {width >= 1024 ? (
                          <>
                            <div className="deposit__amountButtons-row">
                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 100)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+100</span>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 500)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+500</span>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 1000)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+1,000</span>
                              </button>
                            </div>
                            <div className="deposit__amountButtons-row">
                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 5000)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+5,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 10000)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+10,000</span>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 50000)
                                }
                                className="pushable"
                              >
                                <span className="shadow"></span>
                                <span className="edge refreshBtn"></span>
                                <span className="front refreshBtn">+50,000</span>
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="deposit__amountButtons-row">
                              <div
                                className="deposit__amountButtons-item"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 100)
                                }
                              >
                                +100
                              </div>
                              <div
                                className="deposit__amountButtons-item"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 500)
                                }
                              >
                                +500
                              </div>
                              <div
                                className="deposit__amountButtons-item"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 1000)
                                }
                              >
                                +1,000
                              </div>
                            </div>
                            <div className="deposit__amountButtons-row">
                              <div
                                className="deposit__amountButtons-item"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 5000)
                                }
                              >
                                +5,000
                              </div>
                              <div
                                className="deposit__amountButtons-item"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 10000)
                                }
                              >
                                +10,000
                              </div>
                              <div
                                className="deposit__amountButtons-item"
                                onClick={() =>
                                  onValidationAmount(+depositAmount + 50000)
                                }
                              >
                                +50,000
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="validation-message khelocard">{message}</div>
                    </div>
                  </>
                ) : null}

                {/*UTR inputBox*/}
                {depositMethod && depositMethod.isManual && depositMethod?.name !== "Khelocard" ? (
                  <>
                    <div className="deposit">
                      <div className="deposit__title">
                        Unique Transaction Reference*
                      </div>
                      <div className="deposit__amountInput inputMargin">
                        <input
                          className="deposit__amountInput-input"
                          type="number"
                          placeholder="6 to 12 Digit UTR Number"
                          onChange={(e) => onValidationUTR(e.target.value)}
                        />
                        {messageUTR && <div className="validation-message khelocard">{messageUTR}</div>}
                      </div>
                    </div>
                  </>
                ) : null}
                {depositMethod && depositMethod.isManual && depositMethod?.name !== "Khelocard" ? (
                  <>
                    <div className="deposit">
                      <div className="deposit__title">
                        Upload your photo below
                      </div>
                      <div className="deposit__amountInput inputMargin">
                        <FileUploader
                          handleChange={f => setImgFile(f)}
                          // types={["CSV"]}
                          name="file"
                          classes="imageUploader"
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                {/*Khelocard implementation*/}
                {depositMethod && depositMethod.isManual && depositMethod?.name === "Khelocard" ? (
                  <>
                    <div className="deposit  deposit-khelocard">
                      <div className="deposit__amountInput">
                        <div className="deposit__expiresInput-title">Card number</div>
                        <input
                          className="deposit__amountInput-input"
                          type="text"
                          name="card"
                          value={cardNumber}
                          placeholder="Enter your card number"
                          onChange={(e) => cardsInputHandler(e)}
                        />
                      </div>
                      <div className="validation-message khelocard">{messageCard}</div>
                    </div>
                    <div className="deposit deposit-khelocard">
                      <div className="deposit-cards-input">
                        <div className="deposit__amountInput deposit__expiresInput">
                          <div className="deposit__expiresInput-title">Expires</div>
                          <div>
                            <input
                              className="deposit__amountInput-input "
                              type="number"
                              name="month"
                              value={dateInput?.month}
                              placeholder="MM"
                              onChange={(e) => cardsInputHandler(e)}
                            />
                            <span className="deposit__expiresInput-item">/</span>
                            <input
                              className="deposit__amountInput-input "
                              type="number"
                              name="year"
                              value={dateInput?.year}
                              placeholder="YY"
                              onChange={(e) => cardsInputHandler(e)}
                            />
                          </div>
                        </div>
                        <div className="deposit__amountInput deposit__cvvInput">
                          <div className="deposit__cvvInput-title">CVV</div>
                          <input
                            className="deposit__amountInput-input "
                            type="password"
                            name="cvv"
                            value={cvv}
                            placeholder="xxx"
                            onChange={(e) => cardsInputHandler(e)}
                          />
                        </div>
                      </div>
                      <div className="validation-message khelocard">{messageCardInfo}</div>
                    </div>
                  </>
                ) : null}

                {promoList && promoList.length > 0 && depositMethod.id !== "WHATSAPP" &&
                  (<>
                    <div className="deposit">
                      <div className="deposit__title">Available Offers*</div>
                    </div>
                    {
                      promoList.map((item, index) =>
                        <div key={index + item?.promoCode}
                             className={`deposit-promocode  ${chosenPromo?.promoCode === item?.promoCode ? "active" : ""}`}>
                          <div
                            onClick={() => item?.promoCode === chosenPromo?.promoCode ? setChosenPromo({}) : setChosenPromo(item)}
                            className="deposit-promocode-select">
                            <div className="deposit-promocode-select-button">
                              <div
                                className={chosenPromo?.promoCode === item?.promoCode ? "deposit-promocode-select-button-circle-active" : "deposit-promocode-select-button-circle"}></div>
                            </div>
                            <div className="deposit-promocode-select-gray">{item["short-info"]}</div>
                            <div>{item?.tagLine}</div>
                            <div>
                         <span onClick={(e) => {
                           e.stopPropagation();
                           setDetailsPromo(item?.info);
                           setshowDedails(true);
                         }} className="deposit-promocode-select-details">
                             Details
                         </span>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </>)
                }
                {depositMethod && depositMethod.id !== "WHATSAPP" ? (
                  <div className="rules-button">
                    <div className="rulesAccept">
                      <div
                        className={`rulesAccept__checkboxD ${
                          isRulesAccept ? "rulesAccept__checkbox_checked" : ""
                        }`}
                        id="confirm"
                        onClick={() => setIsRulesAccept(!isRulesAccept)}
                      />
                      <label htmlFor="confirm"></label>
                      <span className="rulesAccept__text">
                    I have read and agree with&nbsp;
                        <span
                          className="rulesAccept__text-underline"
                          onClick={() => history.push("/termsOfWithdrowalPayment")}
                        >
                      the terms of payment and withdrawal policy.
                    </span>
                  </span>
                    </div>

                    {width >= 1024 ? (
                      <div className="submitBtnD">
                        <button
                          type="button"
                          onClick={() => depositRequest()}
                          className={`
                          ${
                            (depositMethod.isManual ? (isRulesAccept && (depositMethod?.name !== "Khelocard" ? (UTRinput && !messageUTR && !isLoading) : checkCardInputs()) && depositAmount && !message) : (isRulesAccept && depositAmount && !message))
                              ? "submitValidBtn"
                              : "depositNotValidBtn"
                          }`}
                        >
                          {isLoading &&
                            <IonSpinner name="lines" style={{ position: "absolute", top: "4px", right: "32%" }} />}
                          {(depositMethod.isManual ? (isRulesAccept && (depositMethod?.name !== "Khelocard" ? (UTRinput && !messageUTR && !isLoading) : checkCardInputs()) && depositAmount && !message) : (isRulesAccept && depositAmount && !message)) ? (
                            <>
                              <span className="shadow"></span>
                              <span className="edge"></span>
                              <span
                                className="front">Proceed {(promoList && promoList?.length) > 0 ? (chosenPromo && chosenPromo.promoCode) ? "" : "without bonus!" : ""}</span>
                            </>
                          ) : (
                            <div className="dep-submitNew__text">Proceed
                              {(promoList && promoList.length > 0) ? (chosenPromo && chosenPromo.promoCode) ? "" : "without bonus!" : ""}</div>
                          )}
                        </button>
                      </div>
                    ) : (
                      <button
                        className={
                          (depositMethod.isManual ? (isRulesAccept && (depositMethod?.name !== "Khelocard" ? (UTRinput && !messageUTR) : checkCardInputs()) && depositAmount && !message) : (isRulesAccept && depositAmount && !message))
                            ? "depositSubmit"
                            : "depositNotValidBtn"
                        }
                        onClick={() => depositRequest()}
                        type="button"
                      >
                        Submit {(promoList && promoList?.length) > 0 ? (chosenPromo && chosenPromo.promoCode) ? "" : "without bonus!" : ""}
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <FooterDesktop />
          </div>
        </>
      )}


      <PopUPHelp
        open={openPopUpHelp}
        onDidDismiss={() => setOpenPopUpHelp(false)}
      />
      {showBonusConditions &&
        <BonusConditionModal onDismissed={setShowBonusConditions} promo={chosenPromo && chosenPromo.info}
                             onAgree={depositRequest} />}
      {showDedails &&
        <DetailsPopUp
          promo={detailsPromo}
          onDidDismiss={() => {
            setDetailsPromo(null);
            setshowDedails(false);
          }}
        />}
    </IonPage>
  );
};

export default connectDeposit()(Deposit);
