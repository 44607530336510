import React from 'react'
import "./TwoCardTPLastResult.scss"

const TwoCardTPLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }
  const descriptionResult = () => {
      if (data && data.rName === 'A'){
        return data?.marketId?.marketRunner[0]?.resDesc
      } else{
        return data?.marketId?.marketRunner[1]?.resDesc
      }
      if (data && data.rName === 'D') {
        return "DRAW"
      }
  }

  return (
  <>
    <div
    style={{justifyContent: 'space-around', marginBottom: "30px"}}
    className="teenpatti2card-results tptWrapper">
    <div
      className={
        data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
          ? 'twoCardLastRes__results-cards-isWinner'
          : 'twoCardLastRes__results-cards'
      }
    >
      <div className="twoCardLastRes__results-name">Player A</div>
      {data &&
        data?.marketId?.marketRunner[0].cards.map((item) => (
          <img
            className="twoCardLastRes__results-cards-card"
            src={cardSrc(item)}
          />
        ))}
    </div>

    <div
      className={
        data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
          ? 'twoCardLastRes__results-cards-isWinner'
          : 'twoCardLastRes__results-cards'
      }
    >
      <div className="twoCardLastRes__results-name">Player B</div>
      {data &&
        data?.marketId?.marketRunner[1].cards.map((item) => (
          <img
            className="twoCardLastRes__results-cards-card"
            src={cardSrc(item)}
          />
        ))}
    </div>
  </div>
  <div className='popUpTPT-content__results-description'>
    <div className='popUpTPT-content__results-description-text'>{descriptionResult()}</div>
  </div>
</>
  )
}
export default TwoCardTPLastResult
