import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import './casinoGridList.scss'
import { IonList, IonListHeader } from "@ionic/react";
import { useHistory } from 'react-router';
import { headerType } from '../../helpers/InPlayPopular.helper';
import EventTypeBanners from '../EventTypeBanners/EventTypeBanners';
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'

const CasinoGridList = ({ itemHeaderData, itemsData, type }) => {
    const history = useHistory();
    const auth = useSelector(
        (state) => state.auth
    )
    const dispatch = useDispatch()
    const getBanner = () => {
        if (headerType['INPLAY'] == type) {
             return <EventTypeBanners eventType={itemHeaderData.id} />
        } else return null
    }

    const renderHeader = () => {
        return <IonListHeader className="inplay-item-list-casino__header">
            <div className="iinplay-item-list-casino__header-content">
                <div className="inplay-item-list-casino__header-logo">
                    <i className={`inplay-content__logo-icon inplay-content__logo-icon--casino inplay-color-icon`} />
                    {itemHeaderData.name}
                </div>
            </div>
        </IonListHeader>
    }

    return (
        <IonList className="inplay-item-list_casino">
            {getBanner()}
            {renderHeader()}
            <div className='casino-content'>
                {itemsData && itemsData.map((item, index) => {
                    const gotoEvent = () => {
                        if(auth) {
                            if (item.eventTypeId == '77777')  {
                                history.push(`/casino_live/${item.event.id}/${item.eventType}`)
                            } else {
                                history.push(`/event-page/${item.eventTypeId}/${item.event.id}`)
                            }
                        } else {
                            dispatch(setLoginNew(true))
                        }
                    }
                    return (
                        <div className='casino-item' key={index} onClick={gotoEvent} style={{backgroundColor: `${item.backgroundColour}`}}>
                            <div className="casino-img-body" style={{
                                backgroundImage: `url(${item.image || itemHeaderData.banner})`
                            }}>
                                
                            </div>
                        </div>
                    )
                })}

            </div>
        </IonList>

    )
}

export default CasinoGridList
