import './DetailsPopUp.scss'
import React from 'react'
import { createPortal } from "react-dom";

const modalRoot = document.querySelector("#modal-root");

const DetailsPopUp = ({promo, onDidDismiss }) => {

  function createMarkup() {
    return { __html: promo.replaceAll('\\n', '') }
  }

  return createPortal(
    <>
      {promo ? (
        <div className="promo-details-pop-up">
          <div className="promo-details-pop-up-main">
            <div onClick={() => onDidDismiss()} className="promo-details-pop-up-main-close"></div>
            <div className="promo-details-pop-up-main-content">
              {/*<div className='promo-details-pop-up-main-img'>*/}
              {/*  <img src={promoBonus} alt='promoBonus'></img>*/}
              {/*</div>*/}
              <div dangerouslySetInnerHTML={createMarkup()} />
            </div>
          </div>
        </div>
      ) : <></>}
    </>, modalRoot
    )
}

export default DetailsPopUp
