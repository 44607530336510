import "./PastBetsTable.scss";
import React from "react";
import { tsToDate } from "../../helpers/date.helper";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const PastBetsTable = ({timeSettings, bets, title}) => {
    return (
    <section className="PastBetsTable">
      <div className="PastBetsTable__table">
        <ul className="PastBetsTable__table-header">
          <li>Settled</li>
          <li>Description</li>
          <li>Type</li>
          <li>Odds</li>
          <li>Stake</li>
          <li className="text-right">Profit/Loss</li>
          <li className="text-right">Status</li>
        </ul>
        <div className="PastBetsTable__table-body">
          {bets ? bets.map((item, index) =>
            <ul className="PastBetsTable__table-body-row" key={item?.marketSettledDate + index}>
              <li className="PastBetsTable__table-body-row-date">
                <span>
                  {/*{*/}
                  {/*  tsToDate(item?.marketSettledDate, timeSettings?.timeZoneName).split(" ")[0]*/}
                  {/*    .split("/")*/}
                  {/*    .map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem)*/}
                  {/*    .join("/")*/}
                  {/*}*/}
                  {new Date(item?.marketSettledDate).toLocaleDateString('en-GB', {year: 'numeric',month: '2-digit',day: '2-digit',})}
                </span>
                <span className="text-color">
                  {tsToDate(item?.marketSettledDate, timeSettings?.timeZoneName).split(" ")[1]}
                </span>
              </li>
              <li className="PastBetsTable__table-body-row-description">
                <p className="PastBetsTable__table-body-row-description-name">{item?.eventName}</p>
                <p><span className="text-bold">{item?.selectionName} - </span> <span className="text-color">{item?.marketName}</span></p>
                <p>
                  <span>Bet ID {item?.apolloBetId} | </span>
                  {/*<span>Matched {tsToDate(item?.betPlacedDate, timeSettings?.timeZoneName)}</span>         */}
                  <span>
                    Matched
                    {new Date(item?.betPlacedDate).toLocaleDateString('en-GB', {year: 'numeric',month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit',second: '2-digit'}).replace(',', '')}
                  </span>
                </p>
              </li>
              <li className="PastBetsTable__table-body-row-info">{item?.side === 0 ? "Back" : "Lay"}</li>
              <li className="PastBetsTable__table-body-row-info">{item?.averagePrice.toFixed(2)}</li>
              <li className="PastBetsTable__table-body-row-info">{getCurrencyFormatWithZeroAfterDot(item?.size)}</li>
              <li className={`PastBetsTable__table-body-row-profit ${item?.profitLoss > 0 ? "green" : "red"}`}>
                {getCurrencyFormatWithZeroAfterDot(item?.profitLoss)}
              </li>
              <li className={`PastBetsTable__table-body-row-profit ${item?.outcome === "WON" ? "green" : "red"}`}>
                {item?.outcome}
              </li>
            </ul>,
          ) : (
            <ul className="PastBetsTable__table-body-no-bets">
              <li>
                You have no <span className="lowercase">{title} Bets</span>
              </li>
            </ul>
          )}

        </div>
      </div>
    </section>
    );
};

export default PastBetsTable;
