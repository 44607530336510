import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {requestAccountStatementSuccess} from "../../store/accountStatement/accountStatement.thunks";
import {requestBetHistorySuccess} from "../../store/betHistory/bet-history.thunks";
import {requestOrderListSuccess} from "../../store/orderList/order-list.thunks";
import {requestBonusStatementSuccess} from "../../store/bonusStatement/bonusStatement.thunks";


export const connectBonusStatementPage = (wrappedProps) => (component) => {
    function mapStateToProps (state) {
        return {
            accountStatement: state.accountStatement,
            user: state.user,
            bonusStatement: state.bonusStatement,
            elementsParams: state.elementsParams
        };
    }

    function mapDispatchToProps (dispatch) {
        const actions = {
            requestAccountStatementSuccess,
            requestOrderListSuccess,
            requestBetHistorySuccess,
            requestBonusStatementSuccess,

        };

        return {actions: bindActionCreators(actions, dispatch)};
    }

    return connect(mapStateToProps, mapDispatchToProps)(component);
};
