import React from 'react'
import "./TrioLastResult.scss"

const TrioLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  return (
      <div
        className='popUpTrio-content__results-cards'>
        <img
          className="popUpTrio-content__results-cards-card"
          src={cardSrc(data && data?.marketId?.indexCard[0])}
          alt=""
        />
        <img
          className="popUpTrio-content__results-cards-card"
          src={cardSrc(data && data?.marketId?.indexCard[1])}
          alt=""
        />
        <img
          className="popUpTrio-content__results-cards-card"
          src={cardSrc(data && data?.marketId?.indexCard[2])}
          alt=""
        />
      </div>
  )
}
export default TrioLastResult
