import { createSlice } from '@reduxjs/toolkit'

/*
 * Slice for a single report
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState: null,
  reducers: {
    // getAuthSuccess: (state, action) => action.payload,
    getAuthSuccess: (state, action) => action.payload,
    getAuthError: (state) => null,
    logoutSuccess: (state) => null,
  },
})

export const { getAuthSuccess, getAuthError, logoutSuccess } = authSlice.actions

export default authSlice.reducer
