import React from 'react'

const AndarBaharRules = () => {
  return (
    <div className="rulesContainer">
      <div>
        <h3>GAME OBJECTIVES</h3>
        <p>
          Players need to predict whether a card with same value as of Joker
          card of that round will be drawn on Andar or Bahar side.
        </p>
      </div>
      <div>
        <h3>GAME RULES</h3>
        <div>
          <ul>
            <li>
              To begin a game round the Dealer deals the first card which is
              commonly known as the "Joker Card".
            </li>
            <li>
              After that the dealer deals a single card to each side: A card
              will be first drawn for Bahar and then for Andar and then for
              Bahar again and so forth until a card with the same value as of
              Joker card (regardless of the suit) will be drawn on one of the
              sides.
            </li>
            <li>
              Once this matching card is drawn on either side the round ends.
            </li>
          </ul>
        </div>
        <p>
          The game is played with one deck of cards that are shuffled either by
          the dealer or shuffling machine before each round. The deck is cut
          after the shuffle and the dealer reveals the Joker card.
        </p>
        <br />
        <div>
          <h3>Betting Instructions</h3>
          <ul>
            <li>
              If a game round is in progress when you enter the game, please
              wait for the next one, and then, place your bets.
            </li>
            <li>After the timer reaches 0, the game round begins.</li>
            <li>
              To place a bet, click on the box, enter the stake amount and
              submit by clicking on Place Bet button.
            </li>
            <li>
              You can place several bets on different runners in different
              markets simultaneously.
            </li>
            <li>
              The timer shows how much time you have left to place your bets.
            </li>
            <li>
              Winnings are paid for the winning bets at the end of each game
              round.
            </li>
            <li>To skip a turn, simply do not place any bets.</li>
          </ul>
        </div>
        <div>
          <h3>Main Bets</h3>
          <ol>
            <li>Andar</li>
            <li>Bahar</li>
          </ol>
        </div>
        <div>
          <h4>Cards combinations</h4>
          <table>
            <thead>
              <tr>
                <th>BET</th>
                <th>BACK</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>ANDAR</th>
                <th>1: 0.98</th>
              </tr>
              <tr>
                <th>BAHAR</th>
                <th>1: 0.98 (If First card Bahar winner {`=>`} 1: 0.25)</th>
              </tr>
            </tbody>
          </table>
          <p>
            Please note that any malfunction voids the game round and all
            eventual payouts for the round. Bets will be returned.
          </p>
        </div>
      </div>
    </div>
  )
}
export default AndarBaharRules
