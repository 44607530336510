import React from 'react'
import "./PokerLastResult.scss"

const PokerLastResult = ({data}) => {

  const cardSrc = (card) => {
    if (card) {
      return `https://stream.fawk.app/assets/smallCards/${card}.png`
    } else {
      return `https://stream.fawk.app/assets/smallCards/BACK.png`
    }
  }

  return (
    <div className="pop-upLastResult-content__results-poker20">
      <div className="pop-upLastResult-content__results-poker20-item">
        <span className="pop-upLastResult-content__results-name">Player A</span>
        <div
          className={
            data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
              ? 'pop-upLastResult-content__results-cards-isWinner-poker'
              : 'pop-upLastResult-content__results-cards'
          }
        >
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[0])}
            alt=""
          />
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[0]?.cards[1])}
            alt=""
          />
        </div>
      </div>

      <div className="pop-upLastResult-content__results-poker20-item">
        <span className="pop-upLastResult-content__results-name">Player B</span>
        <div
          className={
            data && data?.marketId?.marketRunner[1]?.status === 'WINNER'
              ? 'pop-upLastResult-content__results-cards-isWinner-poker'
              : 'pop-upLastResult-content__results-cards'
          }
        >
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[0])}
            alt=""
          />
          <img
            className="pop-upLastResult-content__results-cards-card"
            src={cardSrc(data && data?.marketId?.marketRunner[1]?.cards[1])}
            alt=""
          />
        </div>
      </div>

      <div className="pop-upLastResult-content__results-poker20-item poker20-item-board">
        <span className="pop-upLastResult-content__results-name">Board</span>
        <div className={`pop-upLastResult-content__results-cards pop-upLastResult-content__poker20-board-cards`}
        >
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[0])} alt="" />
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[1])} alt="" />
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[2])} alt="" />
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[3])} alt="" />
          <img className="pop-upLastResult-content__results-cards-card" src={cardSrc(data && data?.marketId?.indexCard[4])} alt="" />
        </div>
      </div>

      <div className='pokerResult'>
          <div className='pokerResult-content'>
            {data && data?.marketId?.marketRunner[0]?.status === 'WINNER'
            ? data && data?.marketId?.marketRunner[0]?.patternCode
            : data && data?.marketId?.marketRunner[1]?.patternCode}
          </div>
      </div>

    </div>
  )
}
export default PokerLastResult
