import "./AccountStatementDetailsTable.scss";
import React, {useEffect, useState} from "react";
import {connectAccountStatementDetailsTable} from "./connectAccountStatementDetailsTable";
import { tsToDate } from "../../helpers/date.helper";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const AccountStatementDetailsTable = ({detailsPnlData, selectPNLItem, goBack, accountStatement, timeSettings, orderList, betHistory}) => {
    const [itemDetails, setItemDetails] = useState([]);

    useEffect(() => {
        if (detailsPnlData?.length === 0) return;
        // setItemDetails(detailsPnlData[0].providerId === 1 ? orderList : betHistory );
        setItemDetails(detailsPnlData);
    }, [detailsPnlData]);

    const getBackSubtotal = () => {
        if (!itemDetails || !itemDetails?.length || itemDetails?.length <= 0)
         {
          return 0;
        } else {


        let sum = 0;
        itemDetails.forEach(item => {
            if (item?.side === 0) {
                sum += (item?.profitLoss || 0);
            }
        });
        return sum;
      }
    };
    const getLaySubtotal = () => {
      if (!itemDetails || !itemDetails?.length || itemDetails?.length <= 0)
      {
       return 0;
     } else {

        let sum = 0;
        itemDetails.forEach(item => {
            if (item?.side === 1) {
                sum += (item?.profitLoss || 0);
            }
        });
        return sum;
      }
    };
    const getNetMarketTotal = () => {
        if (itemDetails?.length <= 0) return 0;

        return (getBackSubtotal() + getLaySubtotal()) - (selectPNLItem?.commission || 0);
    };

    return (
    <section className="AccountStatementDetailsTable">
      <div className="AccountStatementDetailsTable__table">
        <h6 className="AccountStatementDetailsTable__table-title">Total P&L:&nbsp;
          <span className={accountStatement?.totalPnl >= 0 ? "green" : "red"}>{getCurrencyFormatWithZeroAfterDot(accountStatement?.totalPnl)}</span>
        </h6>
        <p> <span onClick={goBack} className="text-bold">Account Statement</span>
         {detailsPnlData && <span> `{'>'}` {detailsPnlData[0]?.eventName} - {detailsPnlData[0]?.marketName}</span>}
        </p>
        <ul className="AccountStatementDetailsTable__table-header">
          <li>Placed</li>
          <li>Selection</li>
          <li>Bet ID</li>
          <li>Type</li>
          <li>Odds</li>
          <li>Stake</li>
          <li className="info-right">Profit/Loss</li>
          <li className="info-right">Status</li>
        </ul>
        <div className="AccountStatementDetailsTable__table-body">
          {itemDetails?.length >= 1 && itemDetails?.map((item, index) =>
                  <ul className="AccountStatementDetailsTable__table-body-row" key={item?.lastSettledDate + index}>
                    <li>
                      {/*<span className="text-bold">{tsToDate(item?.betPlacedDate, timeSettings?.timeZoneName).split(" ")[0]} </span>*/}
                      <span  className="text-bold">
                        {tsToDate(item?.betPlacedDate, timeSettings?.timeZoneName)
                        .split(" ")[0]
                        .split("/")
                        .map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem)
                        .join("/")}
                      </span>
                      <span className="text-color">| {tsToDate(item?.betPlacedDate, timeSettings?.timeZoneName).split(" ")[1]}</span>
                    </li>
                    <li>{item?.selectionName}</li>
                    <li>{item?.apolloBetId}</li>
                    <li>{item?.side === 0 ? "Back" : "Lay"}</li>
                    <li>{item?.averagePrice.toFixed(3)}</li>
                    <li>{getCurrencyFormatWithZeroAfterDot(item?.size)}</li>
                    <li className={`info-right ${item?.profitLoss >= 0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item?.profitLoss)}</li>
                    <li className={`info-right ${item?.outcome === "WON" ? "green" : "red"}`}>{item?.outcome}</li>
                  </ul>,
          )}
        </div>
        <div className="AccountStatementDetailsTable__info">
          <ul className="AccountStatementDetailsTable__info-wrapper">
            <li>
              <span className="AccountStatementDetailsTable__info-title">Back subtotal:</span>
              <span className={`AccountStatementDetailsTable__info-price ${getBackSubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(getBackSubtotal())}
              </span>
            </li>
            <li>
              <span className="AccountStatementDetailsTable__info-title">Lay subtotal</span>
              <span className={`AccountStatementDetailsTable__info-price ${getLaySubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                 {getCurrencyFormatWithZeroAfterDot(getLaySubtotal())}
              </span>
            </li>
            <li>
              <span className="AccountStatementDetailsTable__info-title">Market subtotal:</span>
              <span className={`AccountStatementDetailsTable__info-price ${((getBackSubtotal() + getLaySubtotal()).toFixed(2)) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(getBackSubtotal() + getLaySubtotal())}
              </span>
            </li>
            <li>
              <span className="AccountStatementDetailsTable__info-title">Commission:</span>
              <span className={`AccountStatementDetailsTable__info-price`}>
                {getCurrencyFormatWithZeroAfterDot(selectPNLItem?.commission) || "0.00"}
              </span>
            </li>
            <li>
              <span className="AccountStatementDetailsTable__info-title">Net Market Total:</span>
              <span className={`AccountStatementDetailsTable__info-price AccountStatementDetailsTable__info-price-with-border ${getNetMarketTotal().toFixed(2) < 0 ? "negative-value" : ""}`} >
                {getCurrencyFormatWithZeroAfterDot(getNetMarketTotal())}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
    );
};

export default connectAccountStatementDetailsTable()(AccountStatementDetailsTable);
