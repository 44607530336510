import "./Matka.scss";
import {useEffect, useState} from "react";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
// import BetPlacing from "../../BetPlacing/BetPlacing";
import CardsInfo from "../helpers/CardsInfo/CardsInfo";
import {GET_INPLAY_POPULAR_INTERVAL} from "../../../config/app.config";
import LastResults from "../helpers/LastResults/LastResults";
import React from "react";
import {connectMatka} from "./Matka.connect";
import {useParams} from "react-router-dom";

const Matka = ({sportEvent, betPlacing, actions, exchangeGames, exposure, activeTab, headerHeight, auth, elementsParams}) => {
    const [activeTabMarket, setActiveTabMarket] = useState("SINGLE");

    const dataOdd = sportEvent.sportData;
    const {eventId} = useParams();

  // window width
  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth
  // end window width

  //get price
    const getMarketPrice = (marketIndex, runnersIndex) => {
        if (dataOdd &&
      dataOdd[marketIndex] &&
      dataOdd[marketIndex].runners[runnersIndex] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0] &&
      dataOdd[marketIndex].runners[runnersIndex].back[0].price) {

            return dataOdd[marketIndex].runners[runnersIndex].back[0].price;
        }
    };
  //end get price

  // get exposure
    const getExposure = (marketIndex, runnerIndex) => {
      if(auth) {
        const id = sportEvent.sportData[marketIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex] &&
          sportEvent.sportData[marketIndex].runners[runnerIndex].id;
        let objFinder = exposure?.pnlBySelection[Object.keys(exposure?.pnlBySelection).filter((item) => item.includes(id))]?.pnl;

        if (!objFinder) {
            return (
              <div className="matka-exposure-box-modern"></div>
            );
        }
        return (
          <div className={ objFinder > 0
                ? "matka-exposure-box-modern matka-exposure-modern--pos"
                : "matka-exposure-box-modern matka-exposure-modern--neg"}
          >
            {objFinder}
          </div>
        );
      } else return
    };
  // end get exposure
  // Show betslip
    // const renderBetPlacing = () => {
    //     if (betPlacing.runner && width < 1024) {
    //         return <BetPlacing headerHeight={headerHeight}/>;
    //     }
    //     return null;
    // };
  // End Show betslip
  // Bet placing
    const select = (dataOdd, item, isBack) => {
        const runner = {name: item.name, id: item.id};
        const oddData = isBack
      ? {...item.back[0], runner}
      : {...item.lay[0], runner};
        const marketId = dataOdd && dataOdd.id;
        const runnerId = oddData && oddData.runner && oddData.runner.id;
        if (!marketId || !runnerId) return;
        actions.setMarket(marketId);
        actions.setRunner(runnerId);
        actions.setRunnerName(oddData && oddData.runner && oddData.runner.name);
        actions.setEventName(dataOdd && dataOdd.event && dataOdd.event.name);
        actions.setIsBack(isBack);
        actions.setPrice(oddData && oddData.price);
        actions.setPriceActual(oddData && oddData.price);
        actions.setDelay(dataOdd && dataOdd.betDelay);
        actions.setMtype(dataOdd && dataOdd.mtype);
        actions.setBtype(dataOdd && dataOdd.btype);
        actions.setLine(oddData && oddData.line);
        actions.setFromOneClick(false);
        actions.setStake(null);

        actions
      .requestBetPlacingStart(marketId, runnerId)
      .then((response) => {
      })
      .catch((err) => console.log("err = ", err));
    };
    const oddPush = (marketIndex, runnerIndex, isBack = true) => {
        return (
      dataOdd[marketIndex].isBettable
        ? select(dataOdd[marketIndex], dataOdd[marketIndex]?.runners[runnerIndex], isBack)
        : null
        );
    };
  // End Bet placing
  // lock market
    const resultStatus = (marketIndex, runner) => {
        if (exchangeGames && exchangeGames[marketIndex]?.marketRunner[runner]?.status === "WINNER") {
            return "lock-ui winner";
        }
        return !dataOdd[marketIndex].isBettable && "lock-ui";
    };
  // end lock market

  useEffect(() => {
        const timer = setIntervalAsync(async() => {
          await actions
                    .requestExchangeGames(eventId)
                    .catch((e) => console.log("requestInplayPopular error = ", e));
        }, GET_INPLAY_POPULAR_INTERVAL);
        return () => clearIntervalAsync(timer);

  }, []);

    const renderSells = (market) => {
        return(
        <ul className="matka-table__body-sells">
          <div className="matka-table__body-price">
            {getMarketPrice(market, 0)}
          </div>
          {dataOdd[market].runners.map((runner, index) =>
            <li
              className={`matka-table__body-sells-item ${resultStatus(market, index)}`}
              key={runner.id}
              onClick={() => oddPush(market, index)}
            >
              <span>{runner.name}</span>
              {getExposure(market, index)}
            </li>,
          )}
        </ul>
        );
    };

    return (
    <>
      <CardsInfo />
      <div className={`matka ${eventId === "92036" ? "virtual" : ""}  ${activeTab === 0 ? "fullHeight" : ""}`}>
        {/* <>{width < 1024 ? renderBetPlacing() : ""}</> */}
        <div className="matka-table">
          <div className="matka-table__header">
            <ul className="matka-table__header-row">
              <li onClick={() => setActiveTabMarket("SINGLE")} className={`matka-table__header-item ${activeTabMarket === "SINGLE" ? "active" : "" }`}>SINGLE</li>
              <li onClick={() => setActiveTabMarket("SP")} className={`matka-table__header-item ${activeTabMarket === "SP" ? "active" : "" }`}>SP</li>
              <li onClick={() => setActiveTabMarket("DP")} className={`matka-table__header-item ${activeTabMarket === "DP" ? "active" : "" }`}>DP</li>
              <li onClick={() => setActiveTabMarket("TRIO")} className={`matka-table__header-item ${activeTabMarket === "TRIO" ? "active" : "" }`}>TRIO</li>
            </ul>
          </div>
          <div className="matka-table__body">
            {activeTabMarket === "SINGLE" &&
              <div className="matka-table__body-player matka-table__body-player-single">
                <div className="matka-table__body-player-sells">
                  {renderSells(0)}
                </div>
                <div className="matka-table__body-player-buttons">
                  <div className="matka-table__body-price">
                    {getMarketPrice(1, 0)}
                  </div>

                  <div className={`matka-table__body-player-buttons-item ${resultStatus(2, 1)}`}
                       onClick={() => oddPush(2, 1)}
                  >
                    <span className="matka-table__body-player-buttons-name">LINE 1</span>
                    <span className="matka-table__body-player-buttons-item-title"> 1 | 2 | 3 | 4 | 5 </span>
                    {getExposure(2, 1)}
                  </div>

                  <div className={`matka-table__body-player-buttons-item ${resultStatus(1, 0)}`}
                       onClick={() => oddPush(1, 0)}
                  >
                    <span className="matka-table__body-player-buttons-item-name">ODD</span>
                    <span className="matka-table__body-player-buttons-item-title"> 1 | 3 | 5 | 7 | 9 </span>
                    {getExposure(1, 0)}
                  </div>

                  <div className={`matka-table__body-player-buttons-item ${resultStatus(2, 0)}`}
                       onClick={() => oddPush(2, 0)}
                  >
                    <span className="matka-table__body-player-buttons-item-name">LINE 2</span>
                    <span className="matka-table__body-player-buttons-item-title"> 6 | 7 | 8 | 9 | 0 </span>
                    {getExposure(2, 0)}
                  </div>

                  <div className={`matka-table__body-player-buttons-item ${resultStatus(1, 1)}`}
                       onClick={() => oddPush(1, 1)}
                  >
                    <span className="matka-table__body-player-buttons-item-name">EVEN</span>
                    <span className="matka-table__body-player-buttons-item-title"> 2 | 4 | 6 | 8 | 0 </span>
                    {getExposure(1, 1)}
                  </div>
                </div>
              </div>
            }
            {activeTabMarket === "SP" &&
              <div className="matka-table__body-player matka-table__body-player-sp">
                <div className="matka-table__body-sp-sells">
                  {renderSells(3)}
                </div>
                <div className="matka-table__body-player-buttons">
                  <div className={`matka-table__body-player-buttons-item ${resultStatus(4, 0)}`}
                       onClick={() => oddPush(4, 0)}
                  >
                    <span className="matka-table__body-player-buttons-item-name">SP ALL</span>
                    {getExposure(4, 0)}
                  </div>
                </div>
              </div>
            }
            {activeTabMarket === "DP" &&
              <div className="matka-table__body-player matka-table__body-player-dp">
                <div className="matka-table__body-sp-sells">
                  {renderSells(5)}
                </div>
                <div className="matka-table__body-player-buttons">
                  <div className={`matka-table__body-player-buttons-item ${resultStatus(6, 0)}`}
                       onClick={() => oddPush(6, 0)}
                  >
                    <span className="matka-table__body-player-buttons-item-name">DP ALL</span>
                    {getExposure(6, 0)}
                  </div>
                </div>
              </div>
            }
            {activeTabMarket === "TRIO" &&
              <div className="matka-table__body-player matka-table__body-player-trio">
                <div className="matka-table__body-player-buttons">
                  <div className="matka-table__body-price">
                    {getMarketPrice(7, 0)}
                  </div>
                  <div className={`matka-table__body-player-buttons-item ${resultStatus(7, 0)}`}
                       onClick={() => oddPush(7, 0)}
                  >
                    <span className="matka-table__body-player-buttons-item-name">ALL TRIO</span>
                    {getExposure(7, 0)}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <LastResults />
      </div>
    </>
    );
};

export default connectMatka()(Matka);
