import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { setSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice'

export const connectLeftSideMenu = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      menu: state.menu,
      auth: state.auth,
      version: state.version

    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setLoginNew,
      setSlotCasinoPopUp
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
