import './RulesPopUp.scss'

import CloseCross from '../../../../assets/images/closeCross.svg'

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import TrioRules from './GameRules/TrioRules/TrioRules'
import RouletteRules from './GameRules/RouletteRules/RouletteRules'
import QueenRules from './GameRules/QueenRules/QueenRules'
import TeenpattiTestRules from './GameRules/TeenpattiTestRules/TeenpattiTestRules'
import LiveTeenpattiRules from './GameRules/LiveTeenpattiRules/LiveTeenpattiRules'
import TheTrapRules from './GameRules/TheTrapRules/TheTrapRules'
import TwoCardTeenpattiRules from './GameRules/TwoCardTeenpattiRules/TwoCardTeenpattiRules'
import SevenUpDownRules from './GameRules/SevenUpDownRules/SevenUpDownRules'
import DragonTigerRules from './GameRules/DragonTiger/DragonTigerRules'
import SixPlayerPokerRules from './GameRules/SixPlayerPoker/SixPlayerPokerRules'
import Race2020Rules from './GameRules/Race2020/Race2020Rules'
import SicBoRules from './GameRules/SicBoRules/SicBoRules'
import AmarAkbarAnthonyRules from './GameRules/AmarAkbarAnthonyRules/AmarAkbarAnthonyRules'
import MulfisRules from './GameRules/MulfisRules/MulfisRules'
import AndarBaharRules from './GameRules/AndarBaharRules/AndarBaharRules'
import ThirtyTwoCardRules from './GameRules/ThirtyTwoCardRules/ThirtyTwoCardRules'
import HighLowRules from './GameRules/HighLowRules/HighLowRules'
import MatkaRules from './GameRules/MatkaRules/MatkaRules'
import Poker2020Rules from './GameRules/Poker2020Rules/Poker2020Rules'
import TeenpattiT20Rules from './GameRules/TeenpattiT20Rules/TeenpattiT20Rules'
import CasinoMeterRules from './GameRules/CasinoMeterRules/CasinoMeterRules'
import PokerRules from './GameRules/PokerRules/PokerRules'
import CasinoWarRules from './GameRules/CasinoWarRules/CasinoWarRules'
import BollywoodRules from './GameRules/BollywoodRules/BollywoodRules'
import BaccaratRules from './GameRules/BaccaratRules/BaccaratRules'
import { setRulesPopUp } from '../../../../store/rulesPopUp/rulesPopUp.slice'
import { requestRulesPage } from '../../../../store/rulesPage/rulesPage.thunks'

import arrowIcon from './reshot-icon-arrow-down-CXRGBZP93T.svg'
import { IonBackdrop, IonPopover } from '@ionic/react'
import { createPortal } from "react-dom";

const modalRoot = document.querySelector("#modal-root");

const RulesPopUp = ({}) => {
  // const { eventId } = useParams()
  // const popUpWrapperHeight = useSelector((state) => state.elementsParams.mainContainerHeight) + 'px'


  const dispatch = useDispatch()
  const history = useHistory()
  const { eventTypeId, competitionId } = useParams()
  const eventId = history.location.pathname.substring(
    history.location.pathname.lastIndexOf('/') + 1
  )
  // const eventTypeId = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)
  // console.log(history.location.pathname.slice(history.location.pathname.indexOf('/') + 1, history.location.pathname.index('/')));
  // const eventId =
  // console.log('rrr',eventTypeId);
  const scrollPosition = useSelector(
    (state) => state.elementsParams.scrollPosition
  )
  const rulesPage = useSelector((state) => state.rulesPage)
  // console.log(rulesPage)
  const { isOpen } = useSelector((state) => state.rulesPopUp)

  function createMarkup(info) {
    return { __html: info.replaceAll('\\n', '') }
  }
  // useEffect(() => {
  //   // window.scrollTo({top: 0})
  //   ref.current.
  // }, []);

  const isNavigationID = useSelector(
    (state) => state.navigationHeaderMenu.navigationID
  )
  console.log(isNavigationID)
  useEffect(() => {
    if (history.location.pathname.indexOf('event-page') !== -1) {
      dispatch(requestRulesPage(eventTypeId, eventId))
    }
  }, [isOpen])

  const [show, setShow] = useState(false)
  const [showFancy, setShowFancy] = useState(false)

  const handleToggle = () => {
    setShow(!show)
  }
  const handleToggleFancy = () => {
    setShowFancy(!showFancy)
  }

  return createPortal(

    <div className={`rules-popup`}>
      <div
        className={`rules-popup__background`}
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            // setRulesPopUp(false)
            dispatch(setRulesPopUp(false))
          }
        }}
        // style={{ top: scrollPosition + 'px' }}
        >
        <div className={`rules-popup__wrapper`}>
          <div className={`rules-popup__header`}>
            <div className={`rules-popup__header-text`}>
              <div className={`rules-popup__rules-button-icon`}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <path
                    d="M7 0C3.1402 0 0 3.14024 0 7.00004C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7.00004C14 3.14024 10.8598 0 7 0ZM7 12.7273C3.84194 12.7273 1.27273 10.1581 1.27273 7.00004C1.27273 3.84202 3.84194 1.27273 7 1.27273C10.1581 1.27273 12.7273 3.84202 12.7273 7.00004C12.7273 10.1581 10.158 12.7273 7 12.7273Z"
                    fill="black"
                    />
                  <path
                    d="M7.00017 2.96875C6.5324 2.96875 6.15186 3.34955 6.15186 3.81762C6.15186 4.28526 6.5324 4.66572 7.00017 4.66572C7.46794 4.66572 7.84849 4.28526 7.84849 3.81762C7.84849 3.34955 7.46794 2.96875 7.00017 2.96875Z"
                    fill="black"
                    />
                  <path
                    d="M7.00013 5.93945C6.64869 5.93945 6.36377 6.22437 6.36377 6.57582V10.394C6.36377 10.7454 6.64869 11.0304 7.00013 11.0304C7.35158 11.0304 7.6365 10.7454 7.6365 10.394V6.57582C7.6365 6.22437 7.35158 5.93945 7.00013 5.93945Z"
                    fill="black"
                    />
                </svg>
              </div>
              <span className={`rules-popup__rules-button-text`}>Rules</span>
            </div>
            <div
              className={`rules-popup__close-button`}
              onClick={() => {
                // setRulesPopUp(!rulesPopUp)
                dispatch(setRulesPopUp(false))
              }}
              >
              <img src={CloseCross} alt="" />
            </div>
          </div>

          <div className={`rules-popup__content`}>
            {eventId && eventId === '67610' && <TrioRules />}
            {eventId && eventId === '67620' && <QueenRules />}
            {eventId && eventId === '67630' && <TeenpattiTestRules />}
            {eventId && eventId === '56767' && <LiveTeenpattiRules />}
            {eventId && eventId === '67680' && <TheTrapRules />}
            {eventId && eventId === '67660' && <TwoCardTeenpattiRules />}
            {eventId && (eventId === '98792' || eventId === '98788') && (
              <RouletteRules />
              )}
            {eventId && (eventId === '98793' || eventId === '98789') && (
              <SevenUpDownRules />
              )}
            {eventId && (eventId === '98794' || eventId === '98790') && (
              <DragonTigerRules />
              )}
            {eventId && (eventId === '67566' || eventId === '67565') && (
              <SixPlayerPokerRules />
              )}
            {eventId && eventId === '90100' && <Race2020Rules />}
            {eventId && eventId === '98566' && <SicBoRules />}
            {eventId && (eventId === '98791' || eventId === '98795') && (
              <AmarAkbarAnthonyRules />
              )}
            {eventId && eventId === '67600' && <MulfisRules />}
            {eventId && (eventId === '87564' || eventId === '87565') && (
              <AndarBaharRules />
              )}
            {eventId && (eventId === '56966' || eventId === '56967') && (
              <ThirtyTwoCardRules />
              )}
            {eventId && (eventId === '56969' || eventId === '56968') && (
              <HighLowRules />
              )}
            {eventId && (eventId === '92036' || eventId === '92037') && (
              <MatkaRules />
              )}
            {eventId && (eventId === '67563' || eventId === '67567') && (
              <Poker2020Rules />
              )}
            {eventId && (eventId === '56768' || eventId === '56769') && (
              <TeenpattiT20Rules />
              )}
            {eventId && eventId === '67575' && <CasinoMeterRules />}
            {eventId && eventId === '67564' && <PokerRules />}
            {eventId && eventId === '67580' && <CasinoWarRules />}
            {eventId && eventId === '67570' && <BollywoodRules />}
            {eventId && eventId === '92038' && <BaccaratRules />}

            {eventTypeId == 2 ||
              eventTypeId == 3 ||
              (eventTypeId == 4 && rulesPage && (
                <>
                  {!showFancy && (
                    <div class="dropdown">
                      <div
                        className="rulesTitle"
                        onClick={() => handleToggle()}
                        >
                        <img
                          className={` ${!show ? 'arrowIcon' : 'arrowIconRot'}`}
                          src={arrowIcon}
                          alt="arrowIcon"
                          />
                        General Rules
                      </div>
                      <div className={`${!show ? 'notShow' : 'show'}`}>
                        {show ? (
                          <div
                          className="rulesContainer"
                          dangerouslySetInnerHTML={createMarkup(
                            rulesPage[0].rules
                            )}
                            ></div>
                            ) : null}
                      </div>
                    </div>
                  )}
                </>
              ))}
            {eventTypeId == 2 ||
              eventTypeId == 3 ||
              (eventTypeId == 4 && rulesPage && (
                <>
                  {!show && (
                    <div class="dropdownFancy">
                      <div
                        className="rulesTitle"
                        onClick={() => handleToggleFancy()}
                        >
                        <img
                          className={` ${
                            !showFancy ? 'arrowIcon' : 'arrowIconRot'
                          }`}
                          src={arrowIcon}
                          alt="arrowIcon"
                          />
                        Fancy Rules
                      </div>
                      <div
                        className={`${!showFancy ? 'notShow' : 'showFancy'}`}
                        >
                        {showFancy ? (
                          <div
                          className="rulesContainer"
                          dangerouslySetInnerHTML={createMarkup(
                            rulesPage[1].rules
                            )}
                            ></div>
                            ) : null}
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  )
}

export default RulesPopUp
